import React, { useState, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';



function SelectNoOfEmployees(props) {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState({});

    const options = { data: [{ "id": "1", "label": "1-10" }, { "id": "2", "label": "10-50" }, { "id": "3", "label": "50-500" }, { "id": "4", "label": "500-1000" }] };

    const onChange = (e, val) => {
        setValue(val || {});
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: val && val.label
            }
        }, val);
    };

    return (<>
        <Autocomplete
            {...props}
            options={options}
            onChange={onChange}
            onBlur={props.onBlur}
            selectKey="label"
            labelKey="label"
            makeFocus={props.makeFocus}
            renderOption={(option) => (
                <React.Fragment>
                    {option.label}
                </React.Fragment>
            )}

        />
    </>);
}


const mapStateToProps = createStructuredSelector({

});

function mapDispatchToProps(dispatch) {
    return {
        // getNoOfEmployees: data => dispatch(getNumberOfEmployeesListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectNoOfEmployees,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.noOfEmployees) === JSON.stringify(nextProps.noOfEmployees) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);