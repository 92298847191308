/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import NoEntityFound from './NoEntityFound';
import { AuthState } from '@aws-amplify/ui-components';
import SignIn from 'containers/Authentication/SignIn';
import ForgotPassword from './ForgotPassword';
import ConfirmSignIn from './ConfirmSignIn';
import { Loader } from 'components';
import ResetPassword from './ResetPassword';
import SignUp from 'containers/Authentication/SignUp';
import ConfirmSignUp from 'containers/Authentication/ConfirmSignUp';
import SetupMfa from './setupMfa';
import ConfirmEmail from './ConfirmEmail';
// import SetupMfa from './SetupMfa';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: "576px",
    flex: 1,
    position: "relative"
  },
  selectOrg: {
    // margin: theme.spacing(0, -3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
    }
  }
}));

function AuthScreens(props = {}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    authState,
    setAuthState,
    user,
    setUser,
    loading,
    setLoading,
    checkUser,
    nextAuthState,
  } = props;
  const [password, setPassword] = useState();
  return (<div className={classes.root}>
    {loading && <Loader componentCenter />}
    {(authState === AuthState.SignUp) && <SignUp
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
      setPassword={setPassword}
    />}
    {(authState === AuthState.ConfirmSignUp) && <ConfirmSignUp
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
      user={user}
      password={password}
    />}
    {((!authState || authState === AuthState.SignIn)) && <SignIn
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
      setPassword={setPassword}
    />}
    {(authState === AuthState.ConfirmSignIn) && <ConfirmSignIn
      user={user}
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
      password={password}
    />}
    {(authState === AuthState.VerifyingAttributes) && <ConfirmEmail
      user={user}
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
      password={password}
      nextAuthState={nextAuthState}
    />}
    {(authState === AuthState.SettingMFA) && <SetupMfa
      user={user}
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
      checkUser={checkUser}
    />}
    {(authState === AuthState.ResetPassword) && <ResetPassword
      user={user}
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
    />}
    {(authState === AuthState.ForgotPassword) && <ForgotPassword
      setUser={setUser}
      setAuthState={setAuthState}
      loading={loading}
      setLoading={setLoading}
    />}
  </div>);
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return {
    // dispatch actions and from props;
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(AuthScreens);
