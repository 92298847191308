import React, { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
window.moment = moment;
// import 'moment-timezone' ;

/* eslint-disable react/prop-types */
function StyledDate(props = {}) {
  const { date, format, timeZoneDiff } = props;
  // moment.utc(date).utcOffset(timeZoneDiff).format(format)
  let d = date ? moment.utc(date).format(format) : '';
  if (timeZoneDiff) d = date ? moment.utc(date).utcOffset(timeZoneDiff).format(format) : '';
  return <>{d}</>;
}

StyledDate.defaultProps = {
  // format: 'MMMM Do YYYY, h:mm:ss a',
  format: 'MMMM Do YYYY',
  // timeZoneDiff: new Date().getTimezoneOffset(),
  timeZoneDiff: null,
}
StyledDate.propTypes = {
  format: PropTypes.string,
  date: PropTypes.any,
};


export default memo(StyledDate);
