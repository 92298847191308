import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
// import useCommonStyles from 'styles';
import LogoSrc from 'assets/images/logo/Truly_Logo_Dark.png'

const useStyles = makeStyles(theme => ({
  root: props => ({
    padding: theme.spacing(props.padding),
  }),
}));

function Logo(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { width, height } = props;
  return (<div className={classes.root}>
    <img alt="Truly" src={LogoSrc} width={width} height={height} />
  </div>);
}

Logo.defaultProps = {
  padding: 1,
  width: 175,
  height: undefined,
};

Logo.propTypes = {
  padding: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(Logo);
