import { call, put, select, takeLatest, takeLeading, /*takeLeading*/ } from 'redux-saga/effects';
import { request, requestWithErrorFeedback, requestWithFeedback } from 'utils/request';
import {
  // resetContactsAction,
  getContactListSetDataAction,
  getContactDetailsSetDataAction,
  addUpdateContactSetDataAction,
  addContactBankAccountSetDataAction,
  addUpdateContactNoteSetDataAction,
  addContactCardSetDataAction,
  linkContactSetDataAction,
  getContactPaymentListSetDataAction,
  getContactDetailsAction,
  deleteContactNoteSetDataAction,
  deleteContactSetDataAction,
  getSchedulePaymentListSetDataAction,
  getContactNotesSetDataAction,
  getContactBankAccountsSetDataAction,
  deleteSchedulePaymentSetDataAction,
  contactPaymentDetailSetDataAction,
  getContactSchedulePaymentDetailsSetDataAction,
  getContactListAction,
  ibanValidationSetDataAction,
  contactFullListSetDataAction,
  updateContactBankAccountSetDataAction,
  resendContactBankDetailRequestSetDataAction,
  archiveContactBankAccountSetDataAction,
} from './contactActions';
import {
  // RESET_CONTACTS,
  GET_CONTACT_LIST,
  GET_CONTACT_DETAILS,
  ADD_UPDATE_CONTACT,
  ADD_CONTACT_BANK_ACCOUNT,
  ADD_UPDATE_CONTACT_NOTE,
  ADD_CONTACT_CARD,
  LINK_CONTACT,
  GET_CONTACT_PAYMENTS,
  DELETE_CONTACT_NOTE,
  DELETE_CONTACT,
  GET_CONTACT_SCHEDULE_PAYMENT_LIST,
  GET_CONTACT_NOTES,
  GET_CONTACT_BANK_ACCOUNTS,
  DELETE_SCHEDULE_PAYMENT,
  CONTACT_PAYMENT_DETAIL,
  GET_CONTACT_SCHEDULE_PAYMENT_DETAILS,
  IBAN_VALIDATION,
  CONTACT_FULL_LIST,
  UPDATE_CONTACT_BANK_ACCOUNT,
  RESEND_CONTACT_BANK_DETAIL_REQUEST,
  ARCHIVE_CONTACT_BANK_ACCOUNT,
} from './contactConstants';

import { recordPerPageWeb } from 'utils/constant';
import {
  CONTACT_BASE_API_URL, SCHEDULED_PAYMENT_BASE_API_URL, SCHEDULED_BASE_API_URL, BANK_TRANSFER_BASE_URL, CONTACT_BASE_API_URL_V2
} from 'config/apiUrls';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { validateLinkState } from 'store/User/userSelectors';
import { getRequestBankHeaders } from 'utils/common';

const CONTACT_URL = `${CONTACT_BASE_API_URL}/contacts`;
const CONTACT_URL_V2 = `${CONTACT_BASE_API_URL_V2}/contacts`;
const SCHEDULED_URL = `${SCHEDULED_BASE_API_URL}/scheduled-payments`;
const BANK_ACCOUNT_URL = `${BANK_TRANSFER_BASE_URL}/bank-accounts`;
export default function* contactsSaga() {
  // add all sagas here
  yield takeLatest(ARCHIVE_CONTACT_BANK_ACCOUNT, archiveContactBankAccountSaga);
  yield takeLatest(RESEND_CONTACT_BANK_DETAIL_REQUEST, resendContactBankDetailRequestSaga);
  yield takeLatest(UPDATE_CONTACT_BANK_ACCOUNT, updateContactBankAccountSaga);
  yield takeLatest(GET_CONTACT_SCHEDULE_PAYMENT_DETAILS, getContactSchedulePaymentDetailsSaga);
  yield takeLeading(GET_CONTACT_BANK_ACCOUNTS, getContactBankAccountsSaga);
  yield takeLatest(GET_CONTACT_NOTES, getContactNotesSaga);
  yield takeLatest(DELETE_CONTACT, deleteContactSaga);
  yield takeLatest(DELETE_CONTACT_NOTE, deleteContactNoteSaga);
  yield takeLatest(GET_CONTACT_PAYMENTS, getContactPaymentListSaga);
  yield takeLatest(LINK_CONTACT, linkContactSaga);
  yield takeLatest(ADD_UPDATE_CONTACT_NOTE, addUpdateContactNoteSaga);
  yield takeLatest(ADD_CONTACT_BANK_ACCOUNT, addContactBankAccountSaga);
  yield takeLatest(ADD_UPDATE_CONTACT, addUpdateContactSaga);
  yield takeLatest(GET_CONTACT_DETAILS, contactDetailsSaga);
  yield takeLatest(GET_CONTACT_LIST, contactListSaga);
  yield takeLatest(ADD_CONTACT_CARD, addContactCardSaga);
  yield takeLatest(GET_CONTACT_SCHEDULE_PAYMENT_LIST, contactSchedulePaymentListSaga);
  yield takeLatest(DELETE_SCHEDULE_PAYMENT, deleteSchedulePaymentSaga);
  yield takeLatest(CONTACT_PAYMENT_DETAIL, contactPaymentDetailSaga);
  yield takeLatest(IBAN_VALIDATION, validateIbanSaga);
  yield takeLatest(CONTACT_FULL_LIST, contactFullListSaga);
}

export function* contactListSaga(args = {}) {

  const { endPoint = "", isRecentContacts, page = 0 } = args.data || {};
  const skip = page * recordPerPageWeb;
  const requestUrl = `${CONTACT_URL}${endPoint ? `${endPoint}&` : '?'}sort_by=sort_name&sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  const recentContactsUrl2 = `${CONTACT_URL}?sort_by=last_payment_at&limit=3&skip=0`;
  try {
    const result = yield call(request, requestUrl);
    if (isRecentContacts) {
      const recentContacts = yield call(request, recentContactsUrl2);
      result.recentContacts = recentContacts.data.filter(p => p.last_payment_at);
    }
    yield put(getContactListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}

export function* contactSchedulePaymentListSaga(args = {}) {
  const { endPoint = "", page = 0, id } = args.data || {};
  const skip = page * recordPerPageWeb;

  const requestUrl = `${CONTACT_URL}/${id}/scheduled-payments${endPoint ? `${endPoint}&` : '?'}sort_by=sort_name&sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page || 1}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getSchedulePaymentListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}


export function* contactDetailsSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${id}`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl);
    yield put(getContactDetailsSetDataAction(result));
    // if (result.data && result.data.contact_id) cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addUpdateContactSaga({ data }) {
  const { body, formdata, cb = () => null, } = data;
  let { id } = data;
  const method = (id ? 'PUT' : 'POST');
  let requestUrl = `${CONTACT_URL}/${id || ''}`;
  if (method === 'POST') {
    requestUrl = `${CONTACT_URL_V2}`
  }
  const options = { method, body };
  const uploadImageOptions = { method: 'POST', formdata };
  let result = {};
  try {
    if (Object.keys(body).length) {
      result = yield call(requestWithErrorFeedback, requestUrl, options);
      id = (result.data && result.data.contact_id) || null;
    }
    if ((id) && formdata) {
      result = yield call(requestWithErrorFeedback, `${CONTACT_URL}/${id}/image`, uploadImageOptions);
      id = (result.data && result.data.contact_id) || null;
    }
    yield put(addUpdateContactSetDataAction(result));
    if (result.data && result.data.contact_id) cb(result.data.contact_id);
  } catch (error) {
    console.error(error);
  } finally {
    if (id) {
      yield put(getContactListAction());
      yield put(getContactDetailsAction({ id, cb: () => cb(result.data.contact_id) }));
    }
  }
}

export function* addContactBankAccountSaga(args = {}) {
  const { id, body, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${id}/bank-accounts`;
  const options = { method: "POST", body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(addContactBankAccountSetDataAction(result));
    // if (result && result.data && result.data.bank_account_id) cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addUpdateContactNoteSaga(args = {}) {
  const { id, noteId, body, cb = () => null } = args.data || {};
  let endpoint = noteId ? `/${noteId}` : "";
  const requestUrl = `${CONTACT_URL}/${id}/notes${endpoint}`;
  const options = { method: noteId ? "PUT" : "POST", body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(addUpdateContactNoteSetDataAction(result));
    // if (result && result.data && result.data.note_id) cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addContactCardSaga(args = {}) {
  // const { id } = args.data || {};
  const requestUrl = CONTACT_URL;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl);
    yield put(addContactCardSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* linkContactSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${id}/link`;
  const options = { method: "POST" };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(linkContactSetDataAction(result));
    // if (result && result.data && result.data.contact_id) cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getContactPaymentListSaga(args = {}) {
  const { id, page = 0, endPoint = "" } = args.data || {};
  const skip = page * recordPerPageWeb;
  const requestUrl = `${CONTACT_URL}/${id}/payments${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getContactPaymentListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteContactNoteSaga(args = {}) {
  const { id, noteId, cb = () => null } = args.data || {};
  const options = { method: "DELETE" };
  const requestUrl = `${CONTACT_URL}/${id}/notes/${noteId || ''}`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(deleteContactNoteSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* deleteContactSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${id}`;
  const options = { method: 'DELETE' };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(deleteContactSetDataAction(result));
    // if (result && result.data && result.data.contact_id) cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getContactNotesSaga(args = {}) {
  const { id } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${id}/notes`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getContactNotesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getContactBankAccountsSaga(args = {}) {
  const { id, cb = () => null, urlParams = '' } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${id}/bank-accounts${urlParams}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getContactBankAccountsSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* deleteSchedulePaymentSaga(args = {}) {
  const { /*contactId,*/ eventScheduleId, cb = () => null } = args.data || {};

  const requestUrl = `${SCHEDULED_PAYMENT_BASE_API_URL}/scheduled-payments/${eventScheduleId}`;
  try {
    const options = { method: "DELETE" };
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(deleteSchedulePaymentSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* contactPaymentDetailSaga(args = {}) {
  const { contactId, paymentId, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${contactId}/payments/${paymentId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(contactPaymentDetailSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getContactSchedulePaymentDetailsSaga(args = {}) {
  const { eventScheduleId, cb = () => null } = args.data || {};
  const requestUrl = `${SCHEDULED_URL}/${eventScheduleId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getContactSchedulePaymentDetailsSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* validateIbanSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${BANK_ACCOUNT_URL}/validate-iban`;
  let tokenData = yield select(validateLinkState());
  let headers = null;
  if (tokenData?.data?.link_token) {
    headers = getRequestBankHeaders(tokenData);
  }
  const options = { method: "POST", body };
  if (headers) {
    options.headers = {...headers}
  }
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(ibanValidationSetDataAction(result.data));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
export function* contactFullListSaga(args = {}) {

  const requestUrl = `${CONTACT_URL}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(contactFullListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* updateContactBankAccountSaga(args = {}) {
  const { contactId, bankAccountId, body, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${contactId}/bank-accounts/${bankAccountId}`;
  const options = { method: 'PUT', body}
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(updateContactBankAccountSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* resendContactBankDetailRequestSaga(args = {}) {
  const { contactId, bankAccountId, body, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${contactId}/bank-accounts/${bankAccountId}/resend-bank-detail-request`;
  const options = { method: 'POST', body}
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(resendContactBankDetailRequestSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* archiveContactBankAccountSaga(args = {}) {
  const { contactId, bankAccountId, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_URL}/${contactId}/bank-accounts/${bankAccountId}/archive`;
  const options = { method: 'PUT'}
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    yield put(archiveContactBankAccountSetDataAction(result));
    styledSnackbar.success('Bank account archived')
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
