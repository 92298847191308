export const RESET_VERIFICATION = 'Verification/RESET_VERIFICATION';

export const ACCEPT_CONTROLLING_PARTY_CONSENT = 'Verification/ACCEPT_CONTROLLING_PARTY_CONSENT';
export const ACCEPT_CONTROLLING_PARTY_CONSENT_SET_DATA = 'Verification/ACCEPT_CONTROLLING_PARTY_CONSENT_SET_DATA';

export const ACCEPT_CONFIDENTIALITY_AGREEMENT = 'Verification/ACCEPT_CONFIDENTIALITY_AGREEMENT';
export const ACCEPT_CONFIDENTIALITY_AGREEMENT_SET_DATA = 'Verification/ACCEPT_CONFIDENTIALITY_AGREEMENT_SET_DATA';

export const REFRESH_ID_VERIFICATION_TOKEN = 'Verification/REFRESH_ID_VERIFICATION_TOKEN';
export const REFRESH_ID_VERIFICATION_TOKEN_SET_DATA = 'Verification/REFRESH_ID_VERIFICATION_TOKEN_SET_DATA';

export const PERSONAL_DETAILS_FROM_ID_VERIFICATION = 'Verification/PERSONAL_DETAILS_FROM_ID_VERIFICATION';
export const PERSONAL_DETAILS_FROM_ID_VERIFICATION_SET_DATA = 'Verification/PERSONAL_DETAILS_FROM_ID_VERIFICATION_SET_DATA';

export const GET_PERSONAL_DETAILS_PULLING = 'Verification/GET_PERSONAL_DETAILS_PULLING';
export const GET_PERSONAL_DETAILS = 'Verification/GET_PERSONAL_DETAILS';
export const GET_PERSONAL_DETAILS_SET_DATA = 'Verification/GET_PERSONAL_DETAILS_SET_DATA';

export const UPDATE_PERSONAL_DETAILS = 'Verification/UPDATE_PERSONAL_DETAILS';
export const UPDATE_PERSONAL_DETAILS_SET_DATA = 'Verification/UPDATE_PERSONAL_DETAILS_SET_DATA';

export const UPLOAD_VERIFICATION_DOCUMENT = 'Verification/UPLOAD_VERIFICATION_DOCUMENT';
export const UPLOAD_VERIFICATION_DOCUMENT_SET_DATA = 'Verification/UPLOAD_VERIFICATION_DOCUMENT_SET_DATA';

export const CREATE_PASSWORD = 'Verification/CREATE_PASSWORD';
export const CREATE_PASSWORD_SET_DATA = 'Verification/CREATE_PASSWORD_SET_DATA';

export const ACCEPT_INVITE = 'Verification/ACCEPT_INVITE';
export const ACCEPT_INVITE_SET_DATA = 'Verification/ACCEPT_INVITE_SET_DATA';

export const DEPRECATED_GET_TEAM_MEMBER_DETAILS = 'Verification/DEPRECATED_GET_TEAM_MEMBER_DETAILS';
export const DEPRECATED_GET_TEAM_MEMBER_DETAILS_SET_DATA = 'Verification/DEPRECATED_GET_TEAM_MEMBER_DETAILS_SET_DATA';
