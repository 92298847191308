
import { defineMessages } from 'react-intl';

export const scope = 'src.components.signup';

export default defineMessages({
    passwordInfo: {
        id: `${scope}.passwordInfo`,
        defaultMessage: 'Must include 1 uppercase letter, lowercase letter, number and special character, and be at least 8 characters in length.',
    },
    aCapitalLetter: {
        id: `${scope}.aCapitalLetter`,
        defaultMessage: 'A capital letter'
    },
    aSpecialCharacter: {
        id: `${scope}.aSpecialCharacter`,
        defaultMessage: 'A special character'
    },
    atLeast8CharacterLong: {
        id: `${scope}.atLeast8CharacterLong`,
        defaultMessage: 'At least 8 characters long'
    },
    aLowerCaseLetter: {
        id: `${scope}.aLowerCaseLetter`,
        defaultMessage: 'A lower case letter'
    },
    aNumber: {
        id: `${scope}.aNumber`,
        defaultMessage: 'A number'
    },
});
