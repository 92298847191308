import { call, put, takeLatest, takeLeading, delay, all } from 'redux-saga/effects';
import { request, requestWithErrorFeedback, requestWithFeedback } from 'utils/request';
import {
  // resetRegisterAction,
  addCustomerIntroductionSetDataAction,
  getCustomerDetailsSetDataAction,
  addCustomerBusinessBriefSetDataAction,
  saveBusinessBriefSetDataAction,
  saveBusinessStructureSetDataAction,
  saveTermConditionSetDataAction,
  saveBusinessCategoriesSetDataAction,
  saveBusinessIndustriesSetDataAction,
  saveBusinessCountriesSetDataAction,
  saveBusinessUseCasesSetDataAction,
  addCustomerContactDetailsSetDataAction,
  saveBusinessSourceOfFundingSetDataAction,
  verifyPersonalDetailSetDataAction,
  addBeneficialOwnerSetDataAction,
  signupSetDataAction,
  getTermAndConditionSetDataAction,
  saveConfidentialityAgreementSetDataAction,
  deleteBeneficialOwnerSetDataAction,
  acceptConfidentialAgreementSetDataAction,
  resendInvitationLinkSetDataAction,
  inviteAsTeamMemberSetDataAction,
  saveControllingPartySetDataAction,
  acceptAccountAgreementSetDataAction,
  submitRegistrationExtraInfoSetDataAction,
  addCustomerBusinessDetailSetDataAction,
  addBusinessGlobalPositionSetDataAction,
  reGeneratePdfSetDataAction,
  documentUploadSetDataAction,
  saveControllingPartyWithBeneficialOwnerSetDataAction,
  markIdVerificationStepSetDataAction
} from './registerActions';
import {
  // RESET_REGISTER,
  ADD_CUSTOMER_INTRODUCTION,
  GET_CUSTOMER_DETAILS,
  ADD_CUSTOMER_BUSINESS_BRIEF,
  SAVE_BUSINESS_BRIEF,
  SAVE_BUSINESS_STRUCTURE,
  SAVE_TERM_CONDITION,
  SAVE_BUSINESS_CATEGORIES,
  SAVE_BUSINESS_INDUSTRIES,
  SAVE_BUSINESS_COUNTRIES, SAVE_BUSINESS_USE_CASES,
  ADD_CUSTOMER_CONTACT_DETAILS,
  SAVE_BUSINESS_SOURCE_OF_FUNDING,
  VERIFY_PERSONAL_DETAIL,
  ADD_BENEFICIAL_OWNER,
  SIGNUP,
  GET_TERM_AND_CONDITION,
  SAVE_CONFIDENTIALITY_AGREEMENT,
  DELETE_BENEFICIAL_OWNER,
  ACCEPT_CONFIDENTIAL_AGREEMENT,
  RESEND_INVITATION_LINK,
  INVITE_AS_TEAM_MEMBER,
  SAVE_CONTROLLING_PARTY,
  ACCEPT_ACCOUNT_AGREEMENT, SUBMIT_REGISTRATION_EXTRA_INFO,
  ADD_CUSTOMER_BUSINESS_DETAIL,
  ADD_BUSINESS_GLOBAL_POSITION,
  RE_GENERATE_PDF,
  DOCUMENT_UPLOAD,
  SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER,
  MARK_ID_VERIFICATION_STEP
} from './registerConstants';

import {
  REFERENCES_BASE_API_URL,
  REGISTER_BASE_API_URL,

} from 'config/apiUrls';
import { setEntityId } from 'utils/auth';
// import { removeNullKeys } from 'utils/common';

const REGISTER_URL = `${REGISTER_BASE_API_URL}/registrations`;
const SIGNUP_URL = `${REGISTER_BASE_API_URL}/signup`;
const TERMS_URL = `${REFERENCES_BASE_API_URL}/tnc`;

export default function* registerSaga() {
  yield takeLatest(ADD_CUSTOMER_INTRODUCTION, addCustomerIntroductionSaga);
  yield takeLeading(GET_CUSTOMER_DETAILS, getCustomerDetailsSaga);
  yield takeLatest(SAVE_BUSINESS_BRIEF, saveBusinessBriefSaga);
  yield takeLatest(ADD_CUSTOMER_BUSINESS_BRIEF, addCustomerBusinessBriefSaga);
  yield takeLatest(SAVE_BUSINESS_STRUCTURE, saveBusinessStructureSaga);
  yield takeLatest(SAVE_TERM_CONDITION, saveTermConditionSaga);
  yield takeLatest(SAVE_BUSINESS_CATEGORIES, saveBusinessCategoriesSaga);
  yield takeLatest(SAVE_BUSINESS_INDUSTRIES, saveBusinessIndustriesSaga);
  yield takeLatest(SAVE_BUSINESS_USE_CASES, saveBusinessUseCasesSaga);
  yield takeLatest(SAVE_BUSINESS_COUNTRIES, saveBusinessCountriesSaga);
  yield takeLatest(ADD_CUSTOMER_CONTACT_DETAILS, addCustomerContactDetailsSaga);
  yield takeLatest(SAVE_BUSINESS_SOURCE_OF_FUNDING, saveBusinessSourceOfFundingSaga);
  yield takeLatest(VERIFY_PERSONAL_DETAIL, verifyPersonalDetailSaga);
  yield takeLatest(ADD_BENEFICIAL_OWNER, addBeneficialOwnerSaga);
  yield takeLatest(GET_TERM_AND_CONDITION, getTermAndConditionSaga);
  yield takeLatest(SAVE_CONFIDENTIALITY_AGREEMENT, saveConfidentialityAgreementSaga);
  yield takeLatest(DELETE_BENEFICIAL_OWNER, deleteBeneficialOwnerSaga);
  yield takeLatest(ACCEPT_CONFIDENTIAL_AGREEMENT, acceptConfidentialAgreementSaga);
  yield takeLatest(SIGNUP, signupSaga);
  yield takeLatest(RESEND_INVITATION_LINK, resendInvitationLinkSaga);
  yield takeLatest(INVITE_AS_TEAM_MEMBER, inviteAsTeamMemberSaga);
  yield takeLatest(SAVE_CONTROLLING_PARTY, saveControllingPartySaga);
  yield takeLatest(ACCEPT_ACCOUNT_AGREEMENT, acceptAccountAgreementSaga);
  yield takeLatest(SUBMIT_REGISTRATION_EXTRA_INFO, submitRegistrationExtraInfoSaga);
  yield takeLatest(ADD_CUSTOMER_BUSINESS_DETAIL, addCustomerBusinessDetailSaga);
  yield takeLeading(ADD_BUSINESS_GLOBAL_POSITION, addBusinessGlobalPositionSaga);
  yield takeLatest(RE_GENERATE_PDF, reGeneratePdfSaga);
  yield takeLatest(DOCUMENT_UPLOAD, documentUploadSaga);
  yield takeLatest(SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER, saveControllingPartyWithBeneficialOwnerSaga);
  yield takeLatest(MARK_ID_VERIFICATION_STEP, markIdVerificationStepSaga);
  // add all sagas here
}

export function* addCustomerIntroductionSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/customer-introduction`;

  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    if (result.ok && result.data && result.data.entity_id) {
      const setid = yield call(setEntityId, result.data.entity_id);
      setid && cb();
    }
    yield put(addCustomerIntroductionSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}


export function* getCustomerDetailsSaga(args = {}) {
  const { currentStep, cb = () => null } = args.data || {};
  const requestUrl = `${REGISTER_URL}/customer-details`;
  try {
    const result = yield call(request, requestUrl);
    if (result.ok && result.data.entity_id) {
      setEntityId(result.data.entity_id);
    }
    yield put(getCustomerDetailsSetDataAction(result, currentStep));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
export function* addCustomerBusinessBriefSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/customer-business-brief`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(addCustomerBusinessBriefSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessBriefSaga(args = {}) {
  const { cb = () => null, body } = args.data || {};
  const requestUrl = `${REGISTER_URL}/save-business-brief`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessBriefSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessStructureSaga(args = {}) {
  const { cb = () => null, body } = args.data || {};
  const requestUrl = `${REGISTER_URL}/business-structure`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessStructureSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveTermConditionSaga(args = {}) {
  const { cb = () => null, body } = args.data || {};
  const requestUrl = `${REGISTER_URL}/terms-and-conditions`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveTermConditionSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessCategoriesSaga(args = {}) {
  const { cb = () => null, body } = args.data || {};
  const requestUrl = `${REGISTER_URL}/business-categories`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessCategoriesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessIndustriesSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/industries`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessIndustriesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessUseCasesSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/business-use-cases`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessUseCasesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessCountriesSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/business-countries`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessCountriesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* addCustomerContactDetailsSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/customer-contact-details`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(addCustomerContactDetailsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveBusinessSourceOfFundingSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/business-source-of-funds`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveBusinessSourceOfFundingSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* verifyPersonalDetailSaga(args = {}) {
  const { body, cb = () => null, primaryBody, controllingPartyBody = {} } = args.data || {};

  const requestArray = [];

  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/beneficial-owners`;

  const primaryOptions = { method: 'POST', body: primaryBody };
  const primaryRequestUrl = `${REGISTER_URL}/verify-personal-details`;

  const controllingPartyOptions = { method: 'PUT', body: controllingPartyBody };
  const requestControllingPartyUrl = `${REGISTER_URL}/controlling-party`;


  if (primaryBody && Object.keys(primaryBody).length > 0) {
    requestArray.push(call(requestWithErrorFeedback, primaryRequestUrl, primaryOptions));
  }
  if (body && body.entity_persons) {
    requestArray.push(call(requestWithErrorFeedback, requestUrl, options));
  }
  if (controllingPartyBody && controllingPartyBody.entity_person_ids && controllingPartyBody.entity_person_ids.length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestControllingPartyUrl, controllingPartyOptions));
  }

  try {
    const result = yield all(requestArray);
    const checkResponse = result.filter((i, index) => i && !i.ok && index !== (result.length - 1));

    if (checkResponse.length === 0) {
      cb();
    }
    yield put(verifyPersonalDetailSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* addBeneficialOwnerSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};

  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/beneficial-owners`;

  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(addBeneficialOwnerSetDataAction(result));
    result.ok && cb(result && result.data && result.data.entity_person_id ? result.data.entity_person_id : undefined);
  } catch (error) {
    console.error(error);
  }
}

export function* signupSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${SIGNUP_URL}`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(signupSetDataAction(result));
    if (result.ok && result.data.redirect_url) {
      window.open(result.data.redirect_url, "_self");
    }
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
export function* getTermAndConditionSaga(args = {}) {
  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${TERMS_URL}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getTermAndConditionSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveConfidentialityAgreementSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(saveConfidentialityAgreementSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteBeneficialOwnerSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${REGISTER_URL}/beneficial-owners/${id}`;
  const options = { method: 'DELETE' };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(deleteBeneficialOwnerSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* acceptConfidentialAgreementSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${REGISTER_URL}/confidential-agreement`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(acceptConfidentialAgreementSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* resendInvitationLinkSaga(args = {}) {
  const { id } = args.data || {};
  const requestUrl = `${REGISTER_URL}/beneficial-owners/${id}/resend-invite`;
  const options = { method: 'POST' };
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);

    yield put(resendInvitationLinkSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* inviteAsTeamMemberSaga(args = {}) {
  const { id, } = args.data || {};
  const requestUrl = `${REGISTER_URL}/${id}/invite-as-team-member`;
  try {
    const result = yield call(requestWithFeedback, requestUrl);

    yield put(inviteAsTeamMemberSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveControllingPartySaga(args = {}) {
  const { cb = () => null, body } = args.data || {};
  const requestUrl = `${REGISTER_URL}/controlling-party`;
  const options = { method: 'PUT', body };

  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(saveControllingPartySetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* acceptAccountAgreementSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const options = { method: 'PUT', body };
  const requestUrl = `${REGISTER_URL}/accept-account-agreement`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);

    yield put(acceptAccountAgreementSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* submitRegistrationExtraInfoSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${REGISTER_URL}/extra-registration-details`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(submitRegistrationExtraInfoSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addCustomerBusinessDetailSaga(args = {}) {
  const { cb = () => null, body = {} } = args.data || {};

  const { briefBody = {}, industryBody = {}, structureBody = {}, primaryBody = {}, stackHolderBody = {}, controllingParty = {} } = body;
  const requestArray = [];

  const requestBriefUrl = `${REGISTER_URL}/customer-business-brief`;
  const requestIndustryUrl = `${REGISTER_URL}/industries`;
  const requestStructureUrl = `${REGISTER_URL}/business-structure`;
  const requestVerifyPersonalDetailsUrl = `${REGISTER_URL}/verify-personal-details`;
  const requestUrlBeneficialUrl = `${REGISTER_URL}/beneficial-owners`;
  const requestControllingPartyUrl = `${REGISTER_URL}/controlling-party`;

  const briefOptions = { method: 'POST', body: briefBody };
  const structureOptions = { method: 'POST', body: structureBody };
  const industryOptions = { method: 'POST', body: industryBody };
  const primaryOptions = { method: 'POST', body: primaryBody };
  const beneficialOption = { method: 'POST', body: stackHolderBody };
  const controllingPartyOptions = { method: 'PUT', body: controllingParty };

  if (Object.keys(briefBody).length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestBriefUrl, briefOptions));
  }
  if (Object.keys(industryBody).length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestIndustryUrl, industryOptions));
  }
  if (Object.keys(structureBody).length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestStructureUrl, structureOptions));
  }
  if (primaryBody && Object.keys(primaryBody).length) {
    requestArray.push(call(requestWithErrorFeedback, requestVerifyPersonalDetailsUrl, primaryOptions));
  }
  if (stackHolderBody && stackHolderBody.entity_persons && stackHolderBody.entity_persons.length === 0) {
    requestArray.push(call(requestWithErrorFeedback, requestUrlBeneficialUrl, beneficialOption));
  }
  if (controllingParty && controllingParty.entity_person_ids && controllingParty.entity_person_ids.length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestControllingPartyUrl, controllingPartyOptions));
  }

  if (requestArray.length > 0) {
    requestArray.push(delay(1000));
  }

  try {
    const result = yield all(requestArray);
    const checkResponse = result.filter((i, index) => i && !i.ok && index !== (result.length - 1));
    if (checkResponse.length === 0) {
      cb();
    }
    yield put(addCustomerBusinessDetailSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* addBusinessGlobalPositionSaga(args = {}) {
  const { cb = () => null, body = {} } = args.data || {};
  const { bodyCustomerDetail = {}, bodyCountry = {}, } = body;
  const requestArray = [];

  const requestCustomerDetail = `${REGISTER_URL}/customer-contact-details`;
  const requestCountryUrl = `${REGISTER_URL}/business-countries`;

  const customerDetailOptions = { method: 'POST', body: bodyCustomerDetail };
  const countryOptions = { method: 'POST', body: bodyCountry };

  if (Object.keys(bodyCustomerDetail).length > 0 && Object.keys(bodyCustomerDetail).length) {
    requestArray.push(call(requestWithErrorFeedback, requestCustomerDetail, customerDetailOptions));
  }
  if (Object.keys(bodyCountry).length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestCountryUrl, countryOptions));
  }

  try {
    const result = yield all(requestArray);

    const checkResponse = result.filter((i, index) => i && !i.ok && index !== (result.length - 1));

    if (checkResponse.length === 0) {

      cb();
    }
    yield put(addBusinessGlobalPositionSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* reGeneratePdfSaga(args = {}) {
  const { cb = () => null, body = {} } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${REGISTER_URL}/generate-account-agreement`;
  try {
    const result = yield call(request, requestUrl, options);
    yield put(reGeneratePdfSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* documentUploadSaga(args = {}) {
  const { formData, cb = () => null } = args.data || {};
  const requestUrl = `${REGISTER_URL}/business-document`;

  const options = {
    method: 'POST', formdata: formData, headers: {
      'Content-type': "multipart/form-data; boundary=----WebKitFormBoundarytJGA9aZWTfLbA6oP"
    }
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(documentUploadSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* saveControllingPartyWithBeneficialOwnerSaga(args = {}) {

  const { cb = () => null, body, beneficialOwnerBody } = args.data || {};
  const requestArray = [];

  const beneficialOptions = { method: 'POST', body: beneficialOwnerBody };
  const beneficialRequestUrl = `${REGISTER_URL}/beneficial-owners`;

  const requestUrl = `${REGISTER_URL}/controlling-party`;
  const options = { method: 'PUT', body };

  if (beneficialOwnerBody && Object.keys(beneficialOwnerBody).length > 0) {
    requestArray.push(call(requestWithErrorFeedback, beneficialRequestUrl, beneficialOptions));
  }
  if (body && body.entity_person_ids.length > 0) {
    requestArray.push(call(requestWithErrorFeedback, requestUrl, options));
  }
  try {
    const result = yield all(requestArray);
    const checkResponse = result.filter((i, index) => i && !i.ok && index !== (result.length - 1));
    yield put(saveControllingPartyWithBeneficialOwnerSetDataAction(result));
    if (checkResponse && checkResponse.length === 0) {
      cb();
    }
  } catch (error) {
    console.error(error);
  }
}

export function* markIdVerificationStepSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const options = { method: 'PUT' };
  const requestUrl = `${REGISTER_URL}/update-id-verification-step`;
  try {
    const result = yield call(request, requestUrl, options);
    yield put(markIdVerificationStepSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
