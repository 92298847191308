import { call, put, takeLatest } from 'redux-saga/effects';
import { request, } from 'utils/request';
import { preSendPaymentSetDataAction, sendPaymentSetDataAction } from './sendPaymentActions';
import { PRE_SEND_PAYMENT, SEND_PAYMENT_NEW } from './sendPaymentConstants';
import {
  PAYMENTS_BASE_API_URL,
} from 'config/apiUrls';

// const SEND_PAYMENT_URL = `${ SEND_PAYMENT_BASE_API_URL }/sendpayment`;
const PAYMENTS_URL = `${PAYMENTS_BASE_API_URL}/send-payments`;

export default function* sendPaymentSaga() {
  // add all sagas here
  yield takeLatest(PRE_SEND_PAYMENT, preSendPaymentSaga);
  yield takeLatest(SEND_PAYMENT_NEW, send_PaymentSaga);
}

export function* preSendPaymentSaga(args = {}) {
  const { body } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}/pre-send-payment`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(preSendPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* send_PaymentSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    if (result && result.data && result.data.payment_id && !result.data.is_draft) {
      cb();
    }
    yield put(sendPaymentSetDataAction(result));

  } catch (error) {
    console.error(error);
  }
}
