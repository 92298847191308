import React, { memo, useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button,
  // TextField,
  Typography } from "components/ui";
import { Link } from "react-router-dom";
import { styledSnackbar } from "containers/Common/Snackbar";
import { Auth } from 'aws-amplify';
import OtpInput from "components/OtpInput";

const useStyles = makeStyles((theme) => ({
  txt: {
    textAlign: "left",
  },
  centerTxt: {
    textAlign: "left",
    margin: theme.spacing(1, 0, 2.8, 0)
  },
  linkText: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    textDecoration: "underline",
  },
  resendCodeTxt: {
    padding: theme.spacing(1, 0),
    display: "block",
    textAlign: 'center',
    margin: theme.spacing(5, 0, 2, 0)
  },
  resendCodeLink: {
    textDecoration: "underline",

  },
  otpDiv: {
    display: "flex",
    justifyContent: "center",
  }
}));

function VerifySmsCode(props = {}) {
  const { handleVerifySmsCode, setLoading, loading, code, onChange, setStep, user } = props;
  const classes = useStyles();

  const [mobile, setMobile] = useState("");

  useEffect(() => {
    getUserAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code?.length === 6) {
      handleVerifySmsCode()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const getExactAttributeValue = (arr = [], name) => {
    const attr = arr.find(i => i.Name === name);
    if (attr) {
      return attr.Value;
    }
    return;
  };

  const getUserAttributes = async () => {
    const at = await Auth.userAttributes(user);
    const mobile = getExactAttributeValue(at, 'custom:mobile') || getExactAttributeValue(at, 'phone_number') || '';
    if (mobile)
      setMobile(mobile);
  };


  const resendConfirmationCode = async (e = {}) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttribute("phone_number");
      styledSnackbar.success("Code sent");
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (<form onSubmit={handleVerifySmsCode} autoComplete="off">
    <Grid
      container
      spacing={3}
      item
      direction="column"
    >
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.txt} >Enter Verification Code</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="grey500" className={classes.centerTxt}>Please enter the confirmation code you received via SMS at ***-***-**{mobile.substring(mobile.length - 2, mobile.length) || "**"}. </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* <TextField
          type="text"
          id="code"
          name="code"
          value={code}
          placeholder="Enter 6-digit Code"
          required
          errorText="Required"
          onChange={onChange}
          makeFocus
        /> */}
        <div className={classes.otpDiv}>
          <OtpInput
            // type="text"
            id="code"
            name="code"
            value={code}
            // label="Enter 6-digit Code"
            required
            // errorText=""
            onChange={onChange}
          />
        </div>
        <Typography className={classes.resendCodeTxt} variant="caption" color="grey700">
          <Link className={classes.resendCodeLink} href="#" onClick={resendConfirmationCode}>Resend SMS</Link>
        </Typography>
      </Grid>

      <Grid container item spacing={3}>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            title="Back"
            onClick={(e) => setStep(4)}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            title="Continue"
            disabled={code?.length !== 6 || loading}
          />
        </Grid>
      </Grid>
    </Grid>
  </form>
  )
}

export default memo(VerifySmsCode)