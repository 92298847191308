

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { makeStyles, IconButton, Collapse } from '@material-ui/core';
import { Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import messages from 'components/intl/AccountDataIntl';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { styledSnackbar } from 'containers/Common/Snackbar';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(1),
    flexDirection: 'column',
    borderRadius: theme.spacing(0.75),
    border: '2pt solid rgba(0, 0, 0, 0.12)'
  },
  subItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItem: 'center',
    marginTop: theme.spacing(2),


  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row'
  },
  titleDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(0.5)
  },

  underLine: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  iconButton: {
    fontSize: theme.spacing(2.5),
  },
  swiftTitleDiv: {
    marginTop: theme.spacing(1.5),
  },
  fixedWidth: {
    width: '70%',
  },
  valueTxt: {
    textAlign: 'end'
  },
}));

function AccountRoutingData(props) {
  const { data } = props; const classes = useStyles();

  const routingData = data.display_routing_details || []

  const [checked, setChecked] = useState(false);

  const expandToggle = () => {
    setChecked(!checked);
  };


  const copyData = () => {

    const getLabelWithSpaces = (label = "") => {
      let str = `${label}`
      const labelLength = label.length;
      const spacesToAdd = 18 - labelLength;
      for (let i = 0; i < spacesToAdd; i++) {
        str = `${str} `
      }
      return str;
    }

    const copyString = routingData.reduce((accum, curr) => {
      let r =  `${accum}
      ${curr.header}:`
      let s = curr.fields.reduce((ac, cu) => {
          return `${ac}
          ${getLabelWithSpaces(cu.label)}: ${cu.value}`
      }, r)
      s = `${s}
      `
      return s
  }, `Routing Details:
  `)

    navigator.clipboard.writeText(copyString);
    styledSnackbar.success("Copied");
  };

  if (Object.keys(data).length === 0) {
    return null;
  }

  return (

    <div className={classes.root}>
      <div className={classes.titleDiv} >
        <span onClick={expandToggle} >
          {<Typography variant="subtitle2" className={classes.underLine} ><FormattedMessage {...messages.routingDetails} /></Typography>}
        </span>
        <div className={classes.rowDirection} >
          <IconButton onClick={copyData} size="small" aria-label="copy">
            <FileCopyIcon className={classes.iconButton} />
          </IconButton>
          <IconButton onClick={() => { expandToggle() }} size="small" aria-label="copy">
            {checked ? <ExpandLessIcon className={classes.iconButton} /> : <ExpandMoreIcon className={classes.iconButton} />}
          </IconButton>
        </div>
      </div>
      <Collapse in={checked}>
        <br />
        {routingData.map(r => (<div>
          <div className={classes.titleDiv} >
            <Typography variant="subtitle2" color="grey600">{r.header}:</Typography>
          </div>
          {r.fields.map(i => (<div>
            <div className={classes.subItem} >
              <Typography className={classes.fixedWidth} variant="body2" color="grey300" >{i.label}</Typography>
              <Typography className={classes.valueTxt} variant="subtitle1" color="grey300" >{i.value}</Typography>
            </div>
          </div>))}
        </div>))}
      </Collapse>
    </div >
  );
}
AccountRoutingData.defaultProps = {
  data: {}
}
AccountRoutingData.propTypes = {
  data: PropTypes.any,
};

export default memo(AccountRoutingData);
