import React, { memo } from 'react';
// import moment from 'moment';
import PropTypes from 'prop-types';
import { TextField } from './ui';
import { makeStyles } from '@material-ui/core';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import 'moment-timezone' ;



const useStyles = makeStyles((theme) => ({
    dateTextFieldRoot: {
        display: 'flex',
        flex: 1
    },
    input: {
        '& .input[type="date"]::-webkit-calendar-picker-indicator ': {
            position: 'absolute',
            left: 0
        },
        '& .input::-webkit-datetime-edit': {
            position: 'relative',
            left: '15px'
        }

        // input::-webkit-datetime-edit-fields-wrapper {
        //   position: relative;
        //   left: 15px;
        // }
    }
}));


function StyledDateTextField(props = {}) {
    const { onChange = () => null, name, id, value } = props;
    const classes = useStyles();
    return (<>
        <div className={classes.dateTextFieldRoot} >
            <TextField
                type="date"
                id={id}
                name={name}
                value={value}
                className={classes.input}
                inputProps={classes.input}
                InputProps={{
                    classes: { input: classes.input },

                }}
                onChange={onChange}
                {...props}
            />

        </div>

    </>);
}

StyledDateTextField.defaultProps = {
    // format: 'MMMM Do YYYY, h:mm:ss a',
    format: 'MMMM Do YYYY',
    timeZoneDiff: new Date().getTimezoneOffset(),
}
StyledDateTextField.propTypes = {
    format: PropTypes.string,
    date: PropTypes.any,
};


export default memo(StyledDateTextField);
