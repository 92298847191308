
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    main: {
        // margin: theme.spacing(1, 2.5),
        marginBottom: theme.spacing(2),
    },
    skeleton: (props) => ({
        borderRadius: theme.spacing(1),
        height: props.props.type === "normal" ? theme.spacing(7) : '62px',
        width: 'auto',
        flex: 1,
        margin: theme.spacing(1),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            // margin: theme.spacing(1),
            margin: theme.spacing(1),
            height: props.props.type === "normal" ? theme.spacing(7) : '62px',
            width: props.props.type === "normal" ? theme.spacing(22) : 'auto',
            flex: 1,
        },

    }),

}));

export default function IndustryListSkeleton(props = {}) {
    const { arraySize = 9, type = "normal" } = props;

    const classes = useStyles({ props });

    return (<>
        <Grid container justifyContent="center" >
            {[...Array(arraySize)].map((i, ind) => (<Grid key={ind} item xs={type === "normal" ? "6" : "12"} md={type === "normal" ? "6" : "9"} >
                <Skeleton className={classes.skeleton} variant="rect" />
            </Grid>))}
        </Grid>
    </>);
}