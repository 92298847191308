import produce from 'immer';
import {
  RESET_TEAM_MEMBER,
  GET_TEAM_MEMBERS, GET_TEAM_MEMBERS_SET_DATA,
  ADD_UPDATE_TEAM_MEMBER, ADD_UPDATE_TEAM_MEMBER_SET_DATA,
  GET_TEAM_MEMBER_DETAILS, GET_TEAM_MEMBER_DETAILS_SET_DATA,
  SELF_UPDATE_TEAM_MEMBER, SELF_UPDATE_TEAM_MEMBER_SET_DATA,
  GET_SELF_TEAM_MEMBER_DETAILS, GET_SELF_TEAM_MEMBER_DETAILS_SET_DATA,
  GENERATE_PASSWORD, GENERATE_PASSWORD_SET_DATA,
  CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT, CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT_SET_DATA,
  DELETE_TEAM_MEMBER, DELETE_TEAM_MEMBER_SET_DATA,
  SEND_INVITATION_LINK_SET_DATA, SEND_INVITATION_LINK,
  UPDATE_TEAM_MEMBER_ACCESS_SET_DATA, UPDATE_TEAM_MEMBER_ACCESS,
  ID_VERIFICATION_RECEIVED, ID_VERIFICATION_RECEIVED_SET_DATA,
  SUBMIT_ID_VERIFICATION_CONSENT, SUBMIT_ID_VERIFICATION_CONSENT_SET_DATA,
  SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS, SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS_SET_DATA,
  SUBMIT_ID_VERIFICATION_AGREEMENT, SUBMIT_ID_VERIFICATION_AGREEMENT_SET_DATA,
  GET_SELF_USER_DETAILS, GET_SELF_USER_DETAILS_SET_DATA, GET_BERBIX_TOKEN, GET_BERBIX_TOKEN_SET_DATA,
  CHECK_TEAM_MEMBER_EXIST, CHECK_TEAM_MEMBER_EXIST_SET_DATA
} from './teamMemberConstants';

export const initialState = {

  checkTeamMemberExistState: { data: {}, loading: false, error: '' },
  getBerbixTokenState: { data: {}, loading: false, error: '' },
  getSelfUserDetailsState: { data: {}, loading: false, error: '' },
  submitIdVerificationAgreementState: { data: {}, loading: false, error: '' },
  submitIdVerificationAcceptedTermAndConditionsState: { data: {}, loading: false, error: '' },
  submitIdVerificationConsentState: { data: {}, loading: false, error: '' },
  idVerificationReceivedState: { data: {}, loading: false, error: '' },
  updateTeamMemberAccessState: { data: {}, loading: false, error: '' },
  deleteTeamMemberState: { data: {}, loading: false, error: '' },
  sendInvitationLinkState: { data: {}, loading: false, error: '' },
  createTeamMemberSmartLinkAccountState: { data: {}, loading: false, error: '' },
  generatePasswordState: { data: {}, loading: false, error: '' },
  getSelfTeamMemberDetailsState: { data: {}, loading: false, error: '', status: '' },
  selfUpdateTeamMemberState: { data: {}, loading: false, error: '' },
  getTeamMemberDetailsState: { data: {}, loading: false, error: '' },
  addUpdateTeamMemberState: { data: {}, loading: false, error: '' },
  getTeamMembersState: { data: [], previousData: [], loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const teamMemberReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHECK_TEAM_MEMBER_EXIST:
        draft.checkTeamMemberExistState = {
          ...draft.checkTeamMemberExistState,
          loading: true, message: '', error: '',
        };
        break;
      case CHECK_TEAM_MEMBER_EXIST_SET_DATA:
        draft.checkTeamMemberExistState = {
          ...draft.checkTeamMemberExistState,
          ...action.data, loading: false,
        };
        break;
      case GET_BERBIX_TOKEN:
        draft.getBerbixTokenState = {
          ...draft.getBerbixTokenState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_BERBIX_TOKEN_SET_DATA:
        draft.getBerbixTokenState = {
          ...draft.getBerbixTokenState,
          ...action.data, loading: false,
        };
        break;
      case GET_SELF_USER_DETAILS:
        draft.getSelfUserDetailsState = {
          ...draft.getSelfUserDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_SELF_USER_DETAILS_SET_DATA:
        draft.getSelfUserDetailsState = {
          ...draft.getSelfUserDetailsState,
          ...action.data, loading: false,
        };
        break;
      case SUBMIT_ID_VERIFICATION_AGREEMENT:
        draft.submitIdVerificationAgreementState = {
          ...draft.submitIdVerificationAgreementState,
          loading: true, message: '', error: '',
        };
        break;
      case SUBMIT_ID_VERIFICATION_AGREEMENT_SET_DATA:
        draft.submitIdVerificationAgreementState = {
          ...draft.submitIdVerificationAgreementState,
          ...action.data, loading: false,
        };
        break;
      case SUBMIT_ID_VERIFICATION_CONSENT:
        draft.submitIdVerificationConsentState = {
          ...draft.submitIdVerificationConsentState,
          loading: true, message: '', error: '',
        };
        break;
      case SUBMIT_ID_VERIFICATION_CONSENT_SET_DATA:
        draft.submitIdVerificationConsentState = {
          ...draft.submitIdVerificationConsentState,
          ...action.data, loading: false,
        };
        break;
      case SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS:
        draft.submitIdVerificationAcceptedTermAndConditionsState = {
          ...draft.submitIdVerificationAcceptedTermAndConditionsState,
          loading: true, message: '', error: '',
        };
        break;
      case SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS_SET_DATA:
        draft.submitIdVerificationAcceptedTermAndConditionsState = {
          ...draft.submitIdVerificationAcceptedTermAndConditionsState,
          ...action.data, loading: false,
        };
        break;
      case ID_VERIFICATION_RECEIVED:
        draft.idVerificationReceivedState = {
          ...draft.idVerificationReceivedState,
          loading: true, message: '', error: '',
        };
        break;
      case ID_VERIFICATION_RECEIVED_SET_DATA:
        draft.idVerificationReceivedState = {
          ...draft.idVerificationReceivedState,
          ...action.data, loading: false,
        };
        break;
      case DELETE_TEAM_MEMBER:
        draft.deleteTeamMemberState = {
          ...draft.deleteTeamMemberState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_TEAM_MEMBER_SET_DATA:
        draft.deleteTeamMemberState = {
          ...draft.deleteTeamMemberState,
          ...action.data, loading: false,
        };
        break;
      case SEND_INVITATION_LINK:
        draft.sendInvitationLinkState = {
          ...draft.sendInvitationLinkState,
          loading: true, message: '', error: '',
        };
        break;
      case SEND_INVITATION_LINK_SET_DATA:
        draft.sendInvitationLinkState = {
          ...draft.sendInvitationLinkState,
          ...action.data, loading: false,
        };
        break;
      case CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT:
        draft.createTeamMemberSmartLinkAccountState = {
          ...draft.createTeamMemberSmartLinkAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT_SET_DATA:
        draft.createTeamMemberSmartLinkAccountState = {
          ...draft.createTeamMemberSmartLinkAccountState,
          ...action.data, loading: false,
        };
        break;
      case GENERATE_PASSWORD:
        draft.generatePasswordState = {
          ...draft.generatePasswordState,
          loading: true, message: '', error: '',
        };
        break;
      case GENERATE_PASSWORD_SET_DATA:
        draft.generatePasswordState = {
          ...draft.generatePasswordState,
          ...action.data, loading: false,
        };
        break;
      case GET_SELF_TEAM_MEMBER_DETAILS:
        draft.getSelfTeamMemberDetailsState = {
          ...draft.getSelfTeamMemberDetailsState,
          loading: true, message: '', error: '', status: ""
        };
        break;
      case GET_SELF_TEAM_MEMBER_DETAILS_SET_DATA:
        draft.getSelfTeamMemberDetailsState = {
          ...draft.getSelfTeamMemberDetailsState,
          ...action.data, loading: false, status: action.data.status
        };
        break;
      case SELF_UPDATE_TEAM_MEMBER:
        draft.selfUpdateTeamMemberState = {
          ...draft.selfUpdateTeamMemberState,
          loading: true, message: '', error: '',
        };
        break;
      case SELF_UPDATE_TEAM_MEMBER_SET_DATA:
        draft.selfUpdateTeamMemberState = {
          ...draft.selfUpdateTeamMemberState,
          ...action.data, loading: false,
        };
        break;
      case GET_TEAM_MEMBER_DETAILS:
        draft.getTeamMemberDetailsState = {
          ...draft.getTeamMemberDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_TEAM_MEMBER_DETAILS_SET_DATA:
        draft.getTeamMemberDetailsState = {
          ...draft.getTeamMemberDetailsState,
          ...action.data, loading: false,
        };
        break;
      case ADD_UPDATE_TEAM_MEMBER:
        draft.addUpdateTeamMemberState = {
          ...draft.addUpdateTeamMemberState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_UPDATE_TEAM_MEMBER_SET_DATA:
        draft.addUpdateTeamMemberState = {
          ...draft.addUpdateTeamMemberState,
          ...action.data, loading: false,
        };
        break;
      case UPDATE_TEAM_MEMBER_ACCESS:
        draft.updateTeamMemberAccessState = {
          ...draft.updateTeamMemberAccessState,
          loading: true, message: '', error: ''
        };
        break;
      case UPDATE_TEAM_MEMBER_ACCESS_SET_DATA:
        draft.updateTeamMemberAccessState = {
          ...draft.updateTeamMemberAccessState,
          loading: false,
        };
        break;
      case GET_TEAM_MEMBERS:
        draft.getTeamMembersState = {
          ...draft.getTeamMembersState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_TEAM_MEMBERS_SET_DATA:
        const apiData = action.data;
        let previousData = state.getTeamMembersState.previousData;
        var NewData = {};

        if (action && action.page > 0) {
          NewData.data = previousData.concat(apiData.data);
        } else {
          NewData.data = apiData.data;
        }

        draft.getTeamMembersState = {
          ...draft.getTeamMembersState,
          ...NewData,
          previousData: NewData.data,
          loading: false, count: action.data.count || 0
        };
        break;
      case RESET_TEAM_MEMBER:
        draft = initialState;
        break;
    }
  });

export default teamMemberReducer;
