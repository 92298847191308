import { defineMessages } from 'react-intl';

export const scope = 'app.components.registration_agreement';

export default defineMessages({
    creationOfBeneficialOwners: {
        id: `${scope}.creationOfBeneficialOwners`,
        defaultMessage: 'CERTIFICATION OF BENEFICIAL OWNERS',
    },
    detailsOfBusiness: {
        id: `${scope}.detailsOfBusiness`,
        defaultMessage: 'DETAILS OF THE BUSINESS :',
    },
    fullName: {
        id: `${scope}.fullName`,
        defaultMessage: `Entity's Full Legal Name : `,
    },

    textId: {
        id: `${scope}.textId`,
        defaultMessage: `Entity's Tax ID Number (EIN) : `,
    },
    streetAddress: {
        id: `${scope}.streetAddress`,
        defaultMessage: `Entity's Street Address (No P.O. Boxes Permitted) :`,
    },
    city: {
        id: `${scope}.city`,
        defaultMessage: 'City : ',
    },
    state: {
        id: `${scope}.state`,
        defaultMessage: 'State : ',
    },
    zipCode: {
        id: `${scope}.zipCode`,
        defaultMessage: 'Zip/Postal Code : ',
    },


    relationShip: {
        id: `${scope}.relationShip`,
        defaultMessage: 'Full Legal Name of Individual Establishing Relationship :',
    },
    beneficialOwners: {
        id: `${scope}.beneficialOwners`,
        defaultMessage: 'BENEFICIAL OWNERS :',
    },
    ownerDescription: {
        id: `${scope}.ownerDescription`,
        defaultMessage: `Information for individual(s), if any, who, directly or
        indirectly, through any contract arrangement, understanding,
        relationship, or otherwise owns 25% or more of the equity
        interests of the legal entity listed above.`,
    },
    ownershipPer: {
        id: `${scope}.ownershipPer`,
        defaultMessage: 'Percentage of Ownership Interest :',
    },
    lastName: {
        id: `${scope}.lastName`,
        defaultMessage: 'Last Name :',
    },


    middleName: {
        id: `${scope}.middleName`,
        defaultMessage: 'Middle Initial : ',
    },
    firstName: {
        id: `${scope}.firstName`,
        defaultMessage: 'First Name : ',
    },
    address: {
        id: `${scope}.address`,
        defaultMessage: `Address :`,
    },
    country: {
        id: `${scope}.country`,
        defaultMessage: 'Country :',
    },
    dob: {
        id: `${scope}.dob`,
        defaultMessage: 'Date of Birth (MM/DD/YYYY) :',
    },

    ssn: {
        id: `${scope}.ssn`,
        defaultMessage: 'SSN (For U.S. Persons) : ',
    },
    nonUsa: {
        id: `${scope}.nonUsa`,
        defaultMessage: 'For Non-U.S. Persons:',
    },
    gov: {
        id: `${scope}.gov`,
        defaultMessage: 'Government-Issued ID Number (e.g. Passport) :',
    },
    dateExpiration: {
        id: `${scope}.dateExpiration`,
        defaultMessage: 'Date of Expiration : ',
    },
    countryOfIssuance: {
        id: `${scope}.countryOfIssuance`,
        defaultMessage: 'Country of Issuance :',
    },

    controllingParties: {
        id: `${scope}.controllingParties`,
        defaultMessage: 'CONTROLLING PARTIES',
    },
    controllingPartiesInfo: {
        id: `${scope}.controllingPartiesInfo`,
        defaultMessage: 'Controlling Party Information',
    },
    controllingPartiesDes: {
        id: `${scope}.controllingPartiesDes`,
        defaultMessage: `Information for an individual with significant responsibility
        for managing or directing the entity, including, an executive
        officer or senior manager (e.g., Chief Executive Officer, Chief
        Financial Officer, Chief Operating Officer, Managing Member,
        General Partner, President, Executive Vice President,
        Treasurer); or Any other individual who regularly performs
        similar functions`,
    },
    acknowledgement: {
        id: `${scope}.acknowledgement`,
        defaultMessage: 'ACKNOWLEDGEMENT',
    },
    iHerebyCertify: {
        id: `${scope}.iHerebyCertify`,
        defaultMessage: ' I hereby certify, to the best of my knowledge, that the information provided above is complete and correct.',
    },
    nameOfIndividual: {
        id: `${scope}.nameOfIndividual`,
        defaultMessage: 'Name of Individual Establishing Relationship:',
    },
    position: {
        id: `${scope}.position`,
        defaultMessage: 'Position Title of Controlling Party',
    },
});
