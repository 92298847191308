const CONTACT_NOTES = {
    code: "CONTACT_NOTES",
    name: "Contact Notes",
    path: "",
    functions: {
        CONTACT_NOTES_LIST: {
            code: "CONTACT_NOTES_LIST",
            name: "Contact Notes List",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_LIST.path}`,
            getFullPath: (contactId) => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_LIST.path}`.replace(
                ":contactId",
                contactId
            ),
        },
        CONTACT_NOTES_UPDATE: {
            code: "CONTACT_NOTES_UPDATE",
            name: "Contact Notes Update",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_UPDATE.path}`,
            getFullPath: (contactId, noteId) => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_UPDATE.path}`.replace(
                ":contactId",
                contactId
            ).replace(
                ":noteId",
                noteId
            ),
        },
        CONTACT_NOTES_DELETE: {
            code: "CONTACT_NOTES_DELETE",
            name: "Contact Notes Delete",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_DELETE.path}`,
            getFullPath: (contactId, noteId) => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_DELETE.path}`.replace(
                ":contactId",
                contactId
            ).replace(
                ":noteId",
                noteId
            ),
        },
        CONTACT_NOTES_CREATE: {
            code: "CONTACT_NOTES_CREATE",
            name: "Contact Notes Create",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_CREATE.path}`,
            getFullPath: (contactId, noteId) => `${CONTACT_NOTES.path}${CONTACT_NOTES.functions.CONTACT_NOTES_CREATE.path}`.replace(
                ":contactId",
                contactId
            ).replace(
                ":noteId",
                noteId
            ),
        },
    },


};

export default CONTACT_NOTES;
