import React, { memo } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button, Typography } from "components/ui";
import MfaSuccess from 'assets/images/MfaSuccess.png'

const useStyles = makeStyles((theme) => ({
  intro: {
    textAlign: "center",
  },
}));

function MfaSetSuccess(props = {}) {
  const { onFinalContinue } = props;
  const classes = useStyles();
  return (<div>
    <Grid
      container
      spacing={3}
      item
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.intro}>
          <img alt="mfa" src={MfaSuccess} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.intro}>
          <Typography variant="h5">Your Account is now Secured with Two-Factor Authentication</Typography>
        </div>
      </Grid>
      {/* <Grid item xs={12}>
            <div className={classes.intro}>
              <Typography variant="body1" color="grey700">
                We’ll ask for a code upon login to verify that the user is you. 
              </Typography>
            </div>
          </Grid> */}
      <Grid item xs={12} />
      <Grid item xs={12} />
      {/* <Grid item xs={12}>
            <div className={classes.centerContent}>
              {user.preferredMFA === 'NOMFA' && <Typography variant="subtitle1">MFA Disabled</Typography>}
              {user.preferredMFA === 'SMS_MFA' && <Typography variant="subtitle1">MFA Preference set to SMS</Typography>}
              {user.preferredMFA === 'SOFTWARE_TOKEN_MFA' && <Typography variant="subtitle1">MFA Preference set to TOTP (Time-based One-Time Password)</Typography>}
            </div>
          </Grid> */}
      <Grid item xs={12}>
        <Button
          color="secondary"
          title="Continue to Truly Financial"
          onClick={onFinalContinue}
        />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  </div>);
};

export default memo(MfaSetSuccess)