// import { makeStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { Collapse, IconButton, makeStyles } from '@material-ui/core';
import intl from 'components/intl/RegistrationHeaderIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCustomerDetailsState } from 'store/Register/registerSelectors';
import { getCustomerDetailsAction } from 'store/Register/registerActions';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import { Typography } from 'components/ui';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import { FormattedMessage } from 'react-intl';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
// const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;

const BUSINESS_BRIEF = REGISTRATION_STEP.BUSINESS_BRIEF;
const BUSINESS_ADDRESS = REGISTRATION_STEP.BUSINESS_ADDRESS;
// const BUSINESS_INDUSTRIES = REGISTRATION_STEP.BUSINESS_INDUSTRIES;

const BUSINESS_USE_CASES = REGISTRATION_STEP.BUSINESS_USE_CASES;
// const BUSINESS_COUNTRIES = REGISTRATION_STEP.BUSINESS_COUNTRIES;
const BUSINESS_SOURCE_OF_FUNDS = REGISTRATION_STEP.BUSINESS_SOURCE_OF_FUNDS;

const BUSINESS_STRUCTURE = REGISTRATION_STEP.BUSINESS_STRUCTURE;
const PERSONAL_DETAILS = REGISTRATION_STEP.PERSONAL_DETAILS;
// const BENEFICIAL_OWNERS = REGISTRATION_STEP.BENEFICIAL_OWNERS;
const CONTROLLING_PARTY = REGISTRATION_STEP.CONTROLLING_PARTY;

// const TERMS_AND_CONDITIONS = REGISTRATION_STEP.TERMS_AND_CONDITIONS;
const ID_VERIFICATION = REGISTRATION_STEP.ID_VERIFICATION;
const ACCOUNT_AGREEMENT = REGISTRATION_STEP.ACCOUNT_AGREEMENT;

const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,

        flexDirection: 'column',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(2),
        },
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 0, 0, 11.5),
            position: 'fixed',
        }

    },

    mainStepTxt: {
        color: theme.palette.grey600,
        margin: theme.spacing(0, 0, 1, 0)

    },

    stepTxtSelected: {
        color: theme.palette.accent.main,
        margin: theme.spacing(0, 0, 1, 0)
    },
    stepTxtDisable: {
        color: theme.palette.grey200,
        margin: theme.spacing(0, 0, 1, 0)
    },
    titleTxt: {
        color: theme.palette.grey700,
        cursor: 'pointer',
        margin: theme.spacing(0, 0, 0, 1.2)
    },
    titleTxtDisable: {
        color: theme.palette.grey200,
        cursor: 'pointer',
        margin: theme.spacing(0, 0, 0, 1.2)
    },
    selectedTitleTxt: {
        color: theme.palette.accent.main,
        cursor: 'pointer',
        margin: theme.spacing(0, 0, 0, 1.2)
    },
    subTitleTxt: {
        color: theme.palette.grey600,
        cursor: 'pointer',
        margin: theme.spacing(1.2, 0, 0, 6.2)
    },
    subTitleDisableTxt: {
        color: theme.palette.grey200,
        cursor: 'pointer',
        margin: theme.spacing(1.2, 0, 0, 6.2)
    },
    subSelectedTitleTxt: {
        color: theme.palette.accent.main,
        cursor: 'pointer',
        margin: theme.spacing(1.2, 0, 0, 6.2)
    },
    ListItemDiv: {
        display: 'flex',

    },
    eachDivComplete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    eachDivMargin: {
        display: 'flex',
        margin: theme.spacing(0, 0, 0, 12),
        alignItems: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 0, 0, 0),
        }
    },

    eachDivMarginOptions: {
        display: 'flex',
        alignItems: 'center',
    },

    eachDivMarginComplete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 0, 12),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 0, 0, 0),
        }
    },

    eachDivMarginCompleteDrawerOp: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 2, 3),
    },

    roundDiv: ({
        padding: '0px !important'
    }),

    rightIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    rightIconDrawer: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        borderRadius: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    disableRoundDiv: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    iconDisable: {
        color: '#DADADA',
        fontSize: theme.spacing(3),
        alignSelf: 'center'
    },
    icon: {
        color: theme.palette.accent.main,
        fontSize: theme.spacing(3),
        alignSelf: 'center'
    },
    completeStepColorDrawer: {
        color: theme.palette.secondary.main,
    },
    stepTxt: {
        color: theme.palette.primary[600]
    },
    checkIcon: {
        fontSize: theme.spacing(3),
        color: theme.palette.secondary[700]
    },
    rootItem: {
        display: 'flex',
        margin: theme.spacing(1.2, 0, 3, 0),
        flexDirection: 'column'
    }
}));

function RegistrationSideMenu(props = {}) {


    const { currentStep, changeStep, data } = props;
    const { mainStep = 0, prs_main_confidential_agreement_isStepComplete = false,
        prs_main_business_brief_isStepComplete = false,
        prs_main_business_categories_isStepComplete = false,
        prs_main_business_address_isStepComplete = false,
        prs_main_terms_and_conditions_isStepComplete = false,
        hideOwnershipPercentage = null,
        completed_steps = [],
        // prs_main_business_industries_isStepComplete = false,
    } = data;

    const classes = useStyles();
    const isAllStepComplete = data && data.completed_steps && data.completed_steps.length === 15 && data.mainStep === "ACCOUNT_AGREEMENT" ? true : false;

    const onStepPress = newStep => {
        if (data && data.completed_steps && data.completed_steps.length > 0) {
            changeStep && changeStep(newStep);
        }

    };

    return (
        <>
            <div className={classes.root} >

                <div className={classes.rootItem} >
                    <Typography variant="overline" className={(mainStep === CONFIDENTIAL_AGREEMENT || prs_main_confidential_agreement_isStepComplete) ? (mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete) ? classes.mainStepTxt : classes.stepTxtSelected : classes.stepTxtDisable}  ><FormattedMessage {...intl.step1} /></Typography>
                    <div className={classes.ListItemDiv}>
                        <div className={classes.eachDivMarginOptions} onClick={isAllStepComplete ? () => null : () => onStepPress(
                            data && data.completed_steps && data.completed_steps.length > 0 && data.completed_steps.includes(CONFIDENTIAL_AGREEMENT) ? CUSTOMER_INTRODUCTION : CONFIDENTIAL_AGREEMENT)}>
                            <IconButton disableRipple className={classes.roundDiv} >
                                {mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete ? <CheckCircleIcon className={classes.checkIcon} /> : <SentimentSatisfiedIcon className={mainStep === CONFIDENTIAL_AGREEMENT ? classes.icon : classes.iconDisable} />}
                            </IconButton>
                            {
                                <div className={classes.txtDiv}>
                                    {/* {(mainStep === CUSTOMER_INTRODUCTION || !prs_main_confidential_agreement_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                    <Typography variant={"subtitle1"} className={(mainStep === CONFIDENTIAL_AGREEMENT || prs_main_confidential_agreement_isStepComplete) ? (mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete) ? classes.titleTxt : classes.selectedTitleTxt : classes.titleTxtDisable} ><FormattedMessage {...intl.introduction} /> </Typography>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={classes.rootItem} >
                    <Typography variant="overline" className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete) ? classes.mainStepTxt : classes.stepTxtSelected : classes.stepTxtDisable}  ><FormattedMessage {...intl.step2} /></Typography>
                    <div className={classes.ListItemDiv}  >
                        <div className={classes.eachDivMarginOptions} onClick={isAllStepComplete ? () => null : () => onStepPress(BUSINESS_BRIEF)}>
                            <IconButton disableRipple className={classes.roundDiv} >
                                {mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete ? <CheckCircleIcon className={classes.checkIcon} /> : <FolderOutlinedIcon className={mainStep === BUSINESS_BRIEF ? classes.icon : classes.iconDisable} />}
                            </IconButton>
                            {
                                <div className={classes.txtDiv}>

                                    <Typography variant={"subtitle1"} className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete) ? classes.titleTxt : classes.selectedTitleTxt : classes.titleTxtDisable} ><FormattedMessage {...intl.businessDetail} /> </Typography>
                                </div>
                            }
                        </div>
                    </div>
                    <Collapse in={mainStep === BUSINESS_BRIEF}>
                        <div onClick={() => onStepPress(BUSINESS_BRIEF)}>
                            <Typography variant={"body2"} className={(currentStep === BUSINESS_BRIEF || completed_steps.includes(BUSINESS_BRIEF)) ? (currentStep !== BUSINESS_BRIEF && completed_steps.includes(BUSINESS_BRIEF)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessBrief} /> </Typography>
                        </div>
                        <div onClick={() => onStepPress(BUSINESS_ADDRESS)}>
                            <Typography variant={"body2"} className={(currentStep === BUSINESS_ADDRESS || completed_steps.includes(BUSINESS_ADDRESS)) ? (currentStep !== BUSINESS_ADDRESS && completed_steps.includes(BUSINESS_ADDRESS)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessLocated} /> </Typography>
                        </div>
                        {/* <div onClick={() => onStepPress(BUSINESS_INDUSTRIES)}>
                            <Typography variant={"body2"} className={(currentStep === BUSINESS_INDUSTRIES || completed_steps.includes(BUSINESS_INDUSTRIES)) ? (currentStep !== BUSINESS_INDUSTRIES && completed_steps.includes(BUSINESS_INDUSTRIES)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleTxt} ><FormattedMessage {...intl.businessIndustries} /> </Typography>
                        </div> */}
                    </Collapse>
                </div>

                <div className={classes.rootItem} >
                    <Typography variant="overline" className={(mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) ? (mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete) ? classes.mainStepTxt : classes.stepTxtSelected : classes.stepTxtDisable}  ><FormattedMessage {...intl.step3} /></Typography>
                    <div className={classes.ListItemDiv} >
                        <div className={classes.eachDivMarginOptions} onClick={isAllStepComplete ? () => null : () => onStepPress(BUSINESS_USE_CASES)}>
                            <IconButton disableRipple className={classes.roundDiv} >
                                {mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete ? <CheckCircleIcon className={classes.checkIcon} /> : <CreateOutlinedIcon className={mainStep === BUSINESS_USE_CASES ? classes.icon : classes.iconDisable} />}
                            </IconButton>
                            {
                                <div className={classes.txtDiv}>
                                    {/* {(mainStep === BUSINESS_USE_CASES || !prs_main_business_categories_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                    <Typography variant={"subtitle1"} className={(mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) ? (mainStep !== BUSINESS_USE_CASES && prs_main_business_brief_isStepComplete) ? classes.titleTxt : classes.selectedTitleTxt : classes.titleTxtDisable} ><FormattedMessage {...intl.tailorProfile} /> </Typography>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        <Collapse in={mainStep === BUSINESS_USE_CASES}>
                            <div onClick={() => onStepPress(BUSINESS_USE_CASES)}>
                                <Typography variant={"body2"} className={(currentStep === BUSINESS_USE_CASES || completed_steps.includes(BUSINESS_USE_CASES)) ? (currentStep !== BUSINESS_USE_CASES && completed_steps.includes(BUSINESS_USE_CASES)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessUseCase} /> </Typography>
                            </div>
                            {/* <div onClick={() => onStepPress(BUSINESS_COUNTRIES)}>
                                <Typography variant={"body2"} className={(currentStep === BUSINESS_COUNTRIES || completed_steps.includes(BUSINESS_COUNTRIES)) ? (currentStep !== BUSINESS_COUNTRIES && completed_steps.includes(BUSINESS_COUNTRIES)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessCountry} /> </Typography>
                            </div> */}
                            <div onClick={() => onStepPress(BUSINESS_SOURCE_OF_FUNDS)}>
                                <Typography variant={"body2"} className={(currentStep === BUSINESS_SOURCE_OF_FUNDS || completed_steps.includes(BUSINESS_SOURCE_OF_FUNDS)) ? (currentStep !== BUSINESS_SOURCE_OF_FUNDS && completed_steps.includes(BUSINESS_SOURCE_OF_FUNDS)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessFunds} /> </Typography>
                            </div>
                        </Collapse>
                    }

                </div>

                {
                    !hideOwnershipPercentage &&
                    <div className={classes.rootItem}>
                        <Typography variant="overline" className={(mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) ? (mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete) ? classes.mainStepTxt : classes.stepTxtSelected : classes.stepTxtDisable}  ><FormattedMessage {...intl.step4} /></Typography>
                        <div className={classes.ListItemDiv}>
                            <div className={classes.eachDivMarginOptions} onClick={isAllStepComplete ? () => null : () => onStepPress(PERSONAL_DETAILS)}>
                                <IconButton disableRipple className={classes.roundDiv}>
                                    {mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete ? <CheckCircleIcon className={classes.checkIcon} /> : <BusinessOutlinedIcon className={mainStep === BUSINESS_STRUCTURE ? classes.icon : classes.iconDisable} />}
                                </IconButton>
                                {
                                    <div className={classes.txtDiv}>
                                        {/* {(mainStep === BUSINESS_STRUCTURE || !prs_main_business_address_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                        <Typography variant={"subtitle1"} className={(mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) ? (mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete) ? classes.titleTxt : classes.selectedTitleTxt : classes.titleTxtDisable}><FormattedMessage {...intl.businessStructure} /> </Typography>
                                    </div>
                                }
                            </div>
                        </div>
                        <Collapse in={mainStep === BUSINESS_STRUCTURE
                        }>
                            {
                                !data.skipControllingParty && <div onClick={() => onStepPress(CONTROLLING_PARTY)}>
                                    <Typography variant={"body2"} className={(currentStep === CONTROLLING_PARTY || completed_steps.includes(CONTROLLING_PARTY)) ? (currentStep !== CONTROLLING_PARTY && completed_steps.includes(CONTROLLING_PARTY)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.selectControlling} /> </Typography>
                                </div>
                            }
                        </Collapse>

                    </div>
                }


                <div className={classes.rootItem}>
                    <Typography variant="overline" className={(mainStep === ID_VERIFICATION || prs_main_terms_and_conditions_isStepComplete) ? (mainStep !== ID_VERIFICATION && prs_main_terms_and_conditions_isStepComplete) ? classes.mainStepTxt : classes.stepTxtSelected : classes.stepTxtDisable}  >{hideOwnershipPercentage ? <FormattedMessage {...intl.step4} /> : <FormattedMessage {...intl.step5} />}</Typography>
                    <div className={classes.ListItemDiv}>
                        <div className={classes.eachDivMarginOptions} onClick={completed_steps.includes(CONTROLLING_PARTY) ? () => onStepPress(ID_VERIFICATION) : () => null}>
                            <IconButton disableRipple className={classes.roundDiv}>
                                {mainStep !== ID_VERIFICATION && prs_main_terms_and_conditions_isStepComplete ? <CheckCircleIcon className={classes.checkIcon} /> : <AssignmentTurnedInOutlinedIcon className={mainStep === ID_VERIFICATION ? classes.icon : classes.iconDisable} />}
                            </IconButton>
                            {
                                <div className={classes.txtDiv}>
                                    <Typography variant={"subtitle1"} className={(mainStep === ID_VERIFICATION || prs_main_terms_and_conditions_isStepComplete) ? (mainStep !== ID_VERIFICATION && prs_main_terms_and_conditions_isStepComplete) ? classes.titleTxt : classes.selectedTitleTxt : classes.titleTxtDisable} ><FormattedMessage {...intl.validation} /> </Typography>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        <Collapse in={mainStep === ID_VERIFICATION}>
                            {/* <div onClick={() => onStepPress(ID_VERIFICATION)}>
                                <Typography variant={"body2"} className={(currentStep === ID_VERIFICATION || completed_steps.includes(ID_VERIFICATION)) ? (currentStep !== ID_VERIFICATION && completed_steps.includes(ID_VERIFICATION)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.termAndCondition} /> </Typography>
                            </div> */}
                            <div onClick={completed_steps.includes(CONTROLLING_PARTY) ? () => onStepPress(ID_VERIFICATION) : () => null}>
                                <Typography variant={"body2"} className={(currentStep === ID_VERIFICATION || completed_steps.includes(ID_VERIFICATION)) ? (currentStep !== ID_VERIFICATION && completed_steps.includes(ID_VERIFICATION)) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.idVerification} /> </Typography>
                            </div>
                            <div onClick={completed_steps.includes(ID_VERIFICATION) && completed_steps.includes(ID_VERIFICATION) ? () => onStepPress(ACCOUNT_AGREEMENT) : () => null}>
                                <Typography variant={"body2"} className={(currentStep === ACCOUNT_AGREEMENT || data.account_agreement_accepted) ? (currentStep !== ACCOUNT_AGREEMENT && data.account_agreement_accepted) ? classes.subTitleTxt : classes.subSelectedTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.informationReview} /> </Typography>
                            </div>
                        </Collapse>
                    }
                </div>

            </div>
        </>
    );
}

const mapStateToProps = () => {
    return createStructuredSelector({
        customerDetailsState: getCustomerDetailsState(),
    });
};

function mapDispatchToProps(dispatch) {
    return {
        getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(RegistrationSideMenu);
