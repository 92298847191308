import React from 'react';
import TermsAndConditions from 'containers/Legal/TermsAndConditions';
import PublicPageLayout from 'layouts/publicPageLayout';

function TermsAndConditionsPage(props = {}) {
  return (<PublicPageLayout>
      <TermsAndConditions />
  </PublicPageLayout>);
}

export default TermsAndConditionsPage;
