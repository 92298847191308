import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Menu, MenuItem, useTheme } from '@material-ui/core';
// import useCommonStyles from 'styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import intl from 'components/intl/HelperMenuIntl';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'components/ui';
// import CallIcon from '@material-ui/icons/Call';
import HelpIcon from '@material-ui/icons/Help';
// import BugReportIcon from '@material-ui/icons/BugReport';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CreateIcon from '@material-ui/icons/Create';
import { openLiveChat } from 'utils/hubspot';

const useStyles = makeStyles(theme => ({
    root: {

    },
    helpIcon: {
        margin: theme.spacing(0, 0, 0, 0),
        padding: '0px !important',
        color: theme.palette.primary.contrastText,
    },
    row: {
        display: 'flex',
        alignItems: 'center'
    },
    rootMenu: {
        zIndex: `3147483648 !important`,
        '& .MuiList-padding': {
            padding: theme.spacing(1, 0, 2, 0),

        },
    },
    icon: {
        fontSize: theme.spacing(2.3),
        color: theme.palette.secondary700,
        margin: theme.spacing(0, 1.3, 0, 0)
    },
    underline: {
        textDecoration: 'underline'
    },
    col: {
        display: 'flex',
        flexDirection: 'column'
    },
    navLink: {
        textDecoration: 'underline',
        color: theme.palette.hyperlink.main,
    },
    nonMenu: {
        margin: theme.spacing(0, 1, 1, 1.95)
    },
    deveined: {
        margin: theme.spacing(1, 0, 2, 0)
    },
    iconAndText: {
        display: 'flex',
    }
}));

function RegHelperMenu(props = {}) {
    // const commonClasses = useCommonStyles(props);

    const classes = useStyles(props);
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const openChat = () => {
        openLiveChat();
        handleClose();
    };

    // const openFeedback = () => {
    //     window?.Usersnap?.logEvent('feedback_event');
    //     handleClose();
    // }

    return (
        <>
            <div className={classes.root}>
                <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    id="helpIcon"
                    onClick={handleClick}
                    className={classes.helpIcon}
                >
                    <HelpOutlineIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.rootMenu}
                    PaperProps={{
                        style: {
                            width: theme.spacing(30),
                        },
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MenuItem
                        className={classes.row}
                        onClick={handleClose}
                        id="guideFaq"
                    >
                        <HelpIcon className={classes.icon} />
                        <Typography variant="body2" color="grey800">
                            <FormattedMessage {...intl.faq} />
                        </Typography>
                    </MenuItem>

                    <MenuItem
                        className={classes.row}
                        onClick={openChat}
                    >
                        <QuestionAnswerIcon className={classes.icon} />
                        <div className={classes.col}>
                            <Typography variant="body2" color="grey800">
                                <FormattedMessage {...intl.liveChat} />
                            </Typography>
                            <Typography variant="caption" color="grey600">
                                <FormattedMessage {...intl.time} />
                            </Typography>
                        </div>
                    </MenuItem>


                    <MenuItem
                        className={classes.row}
                        onClick={() => {

                            handleClose();
                        }}
                    >
                        <CreateIcon className={classes.icon} />
                        <Typography variant="body2" color="grey800">
                            <FormattedMessage {...intl.contactForm} />
                        </Typography>
                    </MenuItem>

                    {/* <MenuItem
                        className={classes.row}
                        onClick={openFeedback}
                    >
                        <BugReportIcon className={classes.icon} />
                        <div className={classes.col}>
                        <Typography variant="body2" color="grey800">
                            <FormattedMessage {...intl.reportBug} />
                        </Typography>
                        </div>
                    </MenuItem> */}


                </Menu>
            </div>
        </>
    );
}

RegHelperMenu.defaultProps = {
    test: " HelperMenu component ",
};

RegHelperMenu.propTypes = {
    test: PropTypes.string,
};

export default memo(RegHelperMenu);
