
export const RESET_CONTACTS = 'Contacts/RESET_CONTACTS';

export const GET_CONTACT_LIST = 'Contacts/GET_CONTACT_LIST';
export const GET_CONTACT_LIST_SET_DATA = 'Contacts/GET_CONTACT_LIST_SET_DATA';
export const GET_CONTACT_LIST_REFRESH = 'Contacts/GET_CONTACT_LIST_REFRESH';

export const GET_CONTACT_DETAILS = 'Contacts/GET_CONTACT_DETAILS';
export const GET_CONTACT_DETAILS_SET_DATA = 'Contacts/GET_CONTACT_DETAILS_SET_DATA';

export const ADD_UPDATE_CONTACT = 'Contacts/ADD_UPDATE_CONTACT';
export const ADD_UPDATE_CONTACT_SET_DATA = 'Contacts/ADD_UPDATE_CONTACT_SET_DATA';

export const ADD_CONTACT_BANK_ACCOUNT = 'Contacts/ADD_CONTACT_BANK_ACCOUNT';
export const ADD_BANK_ACCOUNT_SET_DATA = 'Contacts/ADD_BANK_ACCOUNT_SET_DATA';

export const ADD_UPDATE_CONTACT_NOTE = 'Contacts/ADD_UPDATE_CONTACT_NOTE';
export const ADD_UPDATE_CONTACT_NOTE_SET_DATA = 'Contacts/ADD_UPDATE_CONTACT_NOTE_SET_DATA';

export const ADD_CONTACT_CARD = 'Contacts/ADD_CONTACT_CARD';
export const ADD_CONTACT_CARD_SET_DATA = 'Contacts/ADD_CONTACT_CARD_SET_DATA';

export const LINK_CONTACT = 'Contacts/LINK_CONTACT';
export const LINK_CONTACT_SET_DATA = 'Contacts/LINK_CONTACT_SET_DATA';

export const GET_CONTACT_PAYMENTS = 'Contacts/GET_CONTACT_PAYMENTS';
export const SET_GET_CONTACT_PAYMENTS_RESPONSE = 'Contacts/SET_GET_CONTACT_PAYMENTS_RESPONSE';

export const DELETE_CONTACT_NOTE = 'Contact/DELETE_CONTACT_NOTE';
export const DELETE_CONTACT_NOTE_SET_DATA = 'Contact/DELETE_CONTACT_NOTE_SET_DATA';

export const DELETE_CONTACT = 'Contact/DELETE_CONTACT';
export const DELETE_CONTACT_SET_DATA = 'Contact/DELETE_CONTACT_SET_DATA';

export const GET_CONTACT_SCHEDULE_PAYMENT_LIST = 'Contacts/GET_CONTACT_SCHEDULE_PAYMENT_LIST';
export const GET_CONTACT_SCHEDULE_PAYMENT_LIST_SET_DATA = 'Contacts/GET_CONTACT_SCHEDULE_PAYMENT_LIST_SET_DATA';
export const GET_CONTACT_SCHEDULE_PAYMENT_LIST_RESET_DATA = 'Contact/GET_CONTACT_SCHEDULE_PAYMENT_LIST_RESET_DATA';

export const GET_CONTACT_PAYMENT_LIST = 'Contact/GET_CONTACT_PAYMENT_LIST';
export const GET_CONTACT_PAYMENT_LIST_SET_DATA = 'Contact/GET_CONTACT_PAYMENT_LIST_SET_DATA';
export const GET_CONTACT_PAYMENT_LIST_RESET_DATA = 'Contact/GET_CONTACT_PAYMENT_LIST_RESET_DATA';

export const GET_CONTACT_NOTES = 'Contact/GET_CONTACT_NOTES';
export const GET_CONTACT_NOTES_SET_DATA = 'Contact/GET_CONTACT_NOTES_SET_DATA';
export const GET_CONTACT_NOTES_RESET_DATA = 'Contact/GET_CONTACT_NOTES_RESET_DATA';

export const GET_CONTACT_BANK_ACCOUNTS = 'Contact/GET_CONTACT_BANK_ACCOUNTS';
export const GET_CONTACT_BANK_ACCOUNTS_SET_DATA = 'Contact/GET_CONTACT_BANK_ACCOUNTS_SET_DATA';
export const GET_CONTACT_BANK_ACCOUNTS_RESET_DATA = 'Contact/GET_CONTACT_BANK_ACCOUNTS_RESET_DATA';

export const DELETE_SCHEDULE_PAYMENT = 'Contact/DELETE_SCHEDULE_PAYMENT';
export const DELETE_SCHEDULE_PAYMENT_SET_DATA = 'Contact/DELETE_SCHEDULE_PAYMENT_SET_DATA';

export const CONTACT_PAYMENT_DETAIL = 'Contact/CONTACT_PAYMENT_DETAIL';
export const CONTACT_PAYMENT_DETAIL_SET_DATA = 'Contact/CONTACT_PAYMENT_DETAIL_SET_DATA';


export const GET_CONTACT_SCHEDULE_PAYMENT_DETAILS = 'Contact/GET_CONTACT_SCHEDULE_PAYMENT_DETAILS';
export const GET_CONTACT_SCHEDULE_PAYMENT_DETAILS_SET_DATA = 'Contact/GET_CONTACT_SCHEDULE_PAYMENT_DETAILS_SET_DATA';

export const IBAN_VALIDATION = 'Contact/IBAN_VALIDATION';
export const IBAN_VALIDATION_SET_DATA = 'Contact/IBAN_VALIDATION_SET_DATA';
export const CONTACT_FULL_LIST = 'Contact/CONTACT_FULL_LIST';
export const CONTACT_FULL_LIST_SET_DATA = 'Contact/CONTACT_FULL_LIST_SET_DATA';

export const UPDATE_CONTACT_BANK_ACCOUNT = 'Contact/UPDATE_CONTACT_BANK_ACCOUNT';
export const UPDATE_CONTACT_BANK_ACCOUNT_SET_DATA = 'Contact/UPDATE_CONTACT_BANK_ACCOUNT_SET_DATA';

export const RESEND_CONTACT_BANK_DETAIL_REQUEST = 'Contact/RESEND_CONTACT_BANK_DETAIL_REQUEST';
export const RESEND_CONTACT_BANK_DETAIL_REQUEST_SET_DATA = 'Contact/RESEND_CONTACT_BANK_DETAIL_REQUEST_SET_DATA';

export const ARCHIVE_CONTACT_BANK_ACCOUNT = 'Contact/ARCHIVE_CONTACT_BANK_ACCOUNT';
export const ARCHIVE_CONTACT_BANK_ACCOUNT_SET_DATA = 'Contact/ARCHIVE_CONTACT_BANK_ACCOUNT_SET_DATA';
