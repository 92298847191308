export const RESET_REGISTER = 'Register/RESET_REGISTER';

export const ADD_CUSTOMER_INTRODUCTION = 'Register/ADD_CUSTOMER_INTRODUCTION';
export const ADD_CUSTOMER_INTRODUCTION_SET_DATA = 'Register/ADD_CUSTOMER_INTRODUCTION_SET_DATA';

export const GET_CUSTOMER_DETAILS = 'Register/GET_CUSTOMER_DETAILS';
export const GET_CUSTOMER_DETAILS_SET_DATA = 'Register/GET_CUSTOMER_DETAILS_SET_DATA';

export const UPDATE_STEPS = 'Register/UPDATE_STEPS';

export const ADD_CUSTOMER_BUSINESS_BRIEF = 'Register/ADD_CUSTOMER_BUSINESS_BRIEF';
export const ADD_CUSTOMER_BUSINESS_BRIEF_SET_DATA = 'Register/ADD_CUSTOMER_BUSINESS_BRIEF_SET_DATA';

export const SAVE_BUSINESS_BRIEF = 'Register/SAVE_BUSINESS_BRIEF';
export const SAVE_BUSINESS_BRIEF_SET_DATA = 'Register/SAVE_BUSINESS_BRIEF_SET_DATA';

export const SAVE_BUSINESS_STRUCTURE = 'Register/SAVE_BUSINESS_STRUCTURE';
export const SAVE_BUSINESS_STRUCTURE_SET_DATA = 'Register/SAVE_BUSINESS_STRUCTURE_SET_DATA';

export const SAVE_TERM_CONDITION = 'Register/SAVE_TERM_CONDITION';
export const SAVE_TERM_CONDITION_SET_DATA = 'Register/SAVE_TERM_CONDITION_SET_DATA';

export const SAVE_BUSINESS_CATEGORIES = 'Register/SAVE_BUSINESS_CATEGORIES';
export const SAVE_BUSINESS_CATEGORIES_SET_DATA = 'Register/SAVE_BUSINESS_CATEGORIES_SET_DATA';

export const SAVE_BUSINESS_INDUSTRIES = 'Register/SAVE_BUSINESS_INDUSTRIES';
export const SAVE_BUSINESS_INDUSTRIES_SET_DATA = 'Register/SAVE_BUSINESS_INDUSTRIES_SET_DATA';

export const SAVE_BUSINESS_USE_CASES = 'Register/SAVE_BUSINESS_USE_CASES';
export const SAVE_BUSINESS_USE_CASES_SET_DATA = 'Register/SAVE_BUSINESS_USE_CASES_SET_DATA';

export const SAVE_BUSINESS_COUNTRIES = 'Register/SAVE_BUSINESS_COUNTRIES';
export const SAVE_BUSINESS_COUNTRIES_SET_DATA = 'Register/SAVE_BUSINESS_COUNTRIES_SET_DATA';
export const ADD_CUSTOMER_CONTACT_DETAILS = 'Register/ADD_CUSTOMER_CONTACT_DETAILS';
export const ADD_CUSTOMER_CONTACT_DETAILS_SET_DATA = 'Register/ADD_CUSTOMER_CONTACT_DETAILS_SET_DATA';

export const SAVE_BUSINESS_SOURCE_OF_FUNDING = 'Register/SAVE_BUSINESS_SOURCE_OF_FUNDING';
export const SAVE_BUSINESS_SOURCE_OF_FUNDING_SET_DATA = 'Register/SAVE_BUSINESS_SOURCE_OF_FUNDING_SET_DATA';

export const VERIFY_PERSONAL_DETAIL = 'Register/VERIFY_PERSONAL_DETAIL';
export const VERIFY_PERSONAL_DETAIL_SET_DATA = 'Register/VERIFY_PERSONAL_DETAIL_SET_DATA';

export const ADD_BENEFICIAL_OWNER = 'Register/ADD_BENEFICIAL_OWNER';
export const ADD_BENEFICIAL_OWNER_SET_DATA = 'Register/ADD_BENEFICIAL_OWNER_SET_DATA';

export const SIGNUP = 'Register/SIGNUP';
export const SIGNUP_SET_DATA = 'Register/SIGNUP_SET_DATA';
export const GET_TERM_AND_CONDITION = 'Register/GET_TERM_AND_CONDITION';
export const GET_TERM_AND_CONDITION_SET_DATA = 'Register/GET_TERM_AND_CONDITION_SET_DATA';

export const SAVE_CONFIDENTIALITY_AGREEMENT = 'Register/SAVE_CONFIDENTIALITY_AGREEMENT';
export const SAVE_CONFIDENTIALITY_AGREEMENT_SET_DATA = 'Register/SAVE_CONFIDENTIALITY_AGREEMENT_SET_DATA';

export const DELETE_BENEFICIAL_OWNER = 'Register/DELETE_BENEFICIAL_OWNER';
export const DELETE_BENEFICIAL_OWNER_SET_DATA = 'Register/DELETE_BENEFICIAL_OWNER_SET_DATA';

export const ACCEPT_CONFIDENTIAL_AGREEMENT = 'Register/ACCEPT_CONFIDENTIAL_AGREEMENT';
export const ACCEPT_CONFIDENTIAL_AGREEMENT_SET_DATA = 'Register/ACCEPT_CONFIDENTIAL_AGREEMENT_SET_DATA';

export const RESEND_INVITATION_LINK = 'Register/RESEND_INVITATION_LINK';
export const RESEND_INVITATION_LINK_SET_DATA = 'Register/RESEND_INVITATION_LINK_SET_DATA';

export const INVITE_AS_TEAM_MEMBER = 'Register/INVITE_AS_TEAM_MEMBER';
export const INVITE_AS_TEAM_MEMBER_SET_DATA = 'Register/INVITE_AS_TEAM_MEMBER_SET_DATA';

export const SAVE_CONTROLLING_PARTY = 'Register/SAVE_CONTROLLING_PARTY';
export const SAVE_CONTROLLING_PARTY_SET_DATA = 'Register/SAVE_CONTROLLING_PARTY_SET_DATA';

export const ACCEPT_ACCOUNT_AGREEMENT = 'Register/ACCEPT_ACCOUNT_AGREEMENT';
export const ACCEPT_ACCOUNT_AGREEMENT_SET_DATA = 'Register/ACCEPT_ACCOUNT_AGREEMENT_SET_DATA';

export const TEMP_SAVE_REGISTRATION_DATA_ACTION = 'Register/TEMP_SAVE_REGISTRATION_DATA_ACTION';

export const SUBMIT_REGISTRATION_EXTRA_INFO = 'Register/SUBMIT_REGISTRATION_EXTRA_INFO';
export const SUBMIT_REGISTRATION_EXTRA_INFO_SET_DATA = 'Register/SUBMIT_REGISTRATION_EXTRA_INFO_SET_DATA';
export const ADD_CUSTOMER_BUSINESS_DETAIL = 'Register/ADD_CUSTOMER_BUSINESS_DETAIL';
export const ADD_CUSTOMER_BUSINESS_DETAIL_SET_DATA = 'Register/ADD_CUSTOMER_BUSINESS_DETAIL_SET_DATA';

export const ADD_BUSINESS_GLOBAL_POSITION = 'Register/ADD_BUSINESS_GLOBAL_POSITION';
export const ADD_BUSINESS_GLOBAL_POSITION_SET_DATA = 'Register/ADD_BUSINESS_GLOBAL_POSITION_SET_DATA';

export const RE_GENERATE_PDF = 'Register/RE_GENERATE_PDF';
export const RE_GENERATE_PDF_SET_DATA = 'Register/RE_GENERATE_PDF_SET_DATA';

export const DOCUMENT_UPLOAD = 'Register/DOCUMENT_UPLOAD';
export const DOCUMENT_UPLOAD_SET_DATA = 'Register/DOCUMENT_UPLOAD_SET_DATA';

export const SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER = 'Register/SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER';
export const SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER_SET_DATA = 'Register/SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER_SET_DATA';

export const MARK_ID_VERIFICATION_STEP = 'Register/MARK_ID_VERIFICATION_STEP';
export const MARK_ID_VERIFICATION_STEP_SET_DATA = 'Register/MARK_ID_VERIFICATION_STEP_SET_DATA';
