import React from 'react';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import success from 'assets/images/check2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  infoDiv: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 2),
    },
    maxWidth: theme.spacing(88),
    textAlign: "center",
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    background: theme.palette.background.paper,
    boxShadow: "0px 0px 10px rgba(49, 49, 56, 0.03)",
  },
  subHeading: {
    margin: theme.spacing(3, 0)
  },
  image: {
    marginBottom: theme.spacing(5)
  }
}));

function BankDetailSubmitted (props = {}) {
    const classes = useStyles();
    return (<div className={classes.root}>
        <div className={classes.infoDiv}>
            <div className={classes.image}><img alt="Success" src={success} /></div>
            <Typography variant="h6">Bank Details Saved</Typography>
            <Typography variant="body2">
                {/* The requesting party will see a masked version of your bank details, e.g. (*1234), to maintain the privacy of your sensitive information.  */}
                The requesting party will be notified by email and will be able to send payments to the bank account you have submitted. 
            </Typography>
        </div>
    </div>);
}

export default memo(BankDetailSubmitted);