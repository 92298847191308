import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Loader, StyledIframe } from 'components';
import alloy from '@alloyidentity/web-sdk';
import { ONBOARDING_STEPS } from '@truly-financial/common/consts/entity-person.consts';
import { getPersonalDetailsPullingAction } from 'store/Verification/verificationActions';
const {
    ID_VERIFICATION,
} = ONBOARDING_STEPS;

alloy.close = () => {};

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        backgroundColor: 'white',
        height: `calc(100vh - ${theme.spacing(11)}px)`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
}));

function AlloyVerify(props = {}) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true);
    const [alloyPublicUrl, setAlloyPublicUrl] = useState('');
    const { isModal = false, getPersonalDetails = () => null, getPersonalDetailsPulling, journeyApplicationToken, onComplete = () => null, onDisplay = () => null } = props;

    useEffect(() => {
        if (journeyApplicationToken) {
            initAlloy();
        }
        // let interval = setInterval(getPersonalDetails, 5000);
        let interval = setInterval(() => {
            getPersonalDetailsPulling({
                cb: checkData,
            });
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journeyApplicationToken]);

    const checkData = (data) => {
        const completed_onboarding_steps = data?.data?.completed_onboarding_steps || [];
        if (completed_onboarding_steps.includes(ID_VERIFICATION)) {
            getPersonalDetails();
        }
    };

    const callback = (data) => {
        console.info('callback: ', data);
        onComplete();
    };

    const initAlloy = async () => {
        const alloyInitParams = {
            key: process.env.REACT_APP_ALLOY_SDK_KEY, //sdk key
            production: true,
            journeyApplicationToken: journeyApplicationToken, // Token of an application. ONLY IF USING A JOURNEY
            journeyToken: process.env.REACT_APP_ALLOY_JOURNEY_TOKEN,
            isNext: true,
            showHeader: false
        };
        await alloy.init(alloyInitParams);
        const url = await alloy.getPublicUrl();
        setAlloyPublicUrl(url);
        // openAlloy();
    };
    // eslint-disable-next-line no-unused-vars
    const openAlloy = async () => {
        await alloy.open(callback, "renderAlloy");
        if (isModal) {
            document.getElementById("alloy").style.width = "100%";
            document.getElementById("alloyIframe").style.boxShadow = "none";
        }
        document.getElementById("alloyIframe").onload = function () {
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }
        onDisplay();
    };

    const onLoadIframe = () => {
        setTimeout(() => {
            // if (isModal) {
            //     document.getElementById("alloy").style.width = "100%";
            //     document.getElementById("alloyIframe").style.boxShadow = "none";
            // }
            // document.getElementById("alloyIframe").onload = function () {
            //     setTimeout(() => {
            //         setIsLoading(false);
            //     }, 5000);
            // }
            setIsLoading(false);
            onDisplay();
        }, 10000);
    }

    return (<div id="renderAlloy" className={classes.root}>
        {isLoading && <Loader />}
        <StyledIframe loading={false} src={alloyPublicUrl} style={isModal ? {} :{ width: 'calc(100vw - 231px)'}} onLoad={onLoadIframe} />
    </div>);
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
    return {
        getPersonalDetailsPulling: data => dispatch(getPersonalDetailsPullingAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(AlloyVerify);
