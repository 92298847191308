/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { getEntityListAction } from "store/User/userActions";
import { getEntitiesState } from "store/User/userSelectors";
import {
  backupAndRemoveEntityId,
  getEntityId,
  logout,
  setEntityId,
} from "utils/auth";
import {
  Avatar,
  CircularProgress,
  makeStyles,
  MenuItem,
  Select,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { StyledDialog, StyledError } from "components";
import { Icon, Typography } from "components/ui";
import StoreIcon from "@material-ui/icons/Store";
import { useHistory } from "react-router";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import intl from "containers/Common/intl/SelectEntityIntl";
import { FormattedMessage } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { getCustomerDetailsSetDataAction } from "store/Register/registerActions";
import { TRULY_ACL } from "acl/truly-acl";
import { Can } from "acl/Acl";

const useStyles = makeStyles((theme) => ({
  rootDiv: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#424242",
    alignItems: "center",
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    minHeight: theme.spacing(6.5),
    // borderBottom: `1px solid ${theme.palette.grey200}`,
    filter: `drop-shadow(0px 3px 0px ${theme.palette.grey300})`,
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
      {
        paddingRight: theme.spacing(2.5),
      },
    "& .MuiFilledInput-input": {
      padding: "0px !important",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0,0,0,0)",
      color: theme.palette.primary.contrastText,
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: undefined,
    },
  },
  root: {
    "& .MuiSelect-root": {
      paddingTop: `${theme.spacing(0.875)}px !important`,
      paddingBottom: `${theme.spacing(0.25)}px !important`,
    },
    "& .MuiSelect-iconFilled": {
      color: theme.palette.grey200,
    },
  },
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.tertiary_one200,
  },
  avatarOptions: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.tertiary_one200,
  },
  plusIconDiv: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  plusIcon: {
    color: theme.palette.grey500,
  },
  textInput: {
    color: "white",
  },
  companyTxt: {
    whiteSpace: "initial",
    display: "inline-block",
    wordBreak: "break-word",
    paddingRight: theme.spacing(3),
  },
  dropdownStyle: {
    width: "20%",
  },
  expandIcon: {
    position: "absolute",
    top: "0",
    right: "0",
    display: "inline-block",
    width: "24px",
    height: "24px",
    pointerEvents: "none",
    color: "rgb(160, 160, 160)",
  },
  circularProgressRoot: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  renderValueDiv: {
    paddingLeft: theme.spacing(1),
  },
  switchAccTxt: {
    margin: theme.spacing(0, 0, 1, 2),
  },
  arrow: {
    color: theme.palette.grey300,
    fontSize: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  optionRoot: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
  },
  addBusinessRootDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: theme.spacing(1, 0, 0, 0),
  },
  logoutRootDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  divider: {
    margin: theme.spacing(3, 1, 2, 1),
  },
}));

function SelectEntity(props = {}) {
  const { entities, getEntities, onCloseCallBack, resetCustomerDetails } =
    props;
  const [selectedEntity, setSelectedEntity] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState();
  const [logoutOpen, setLogoutOpen] = useState(false);
  const classes = useStyles(props);
  const ref = useRef(null);
  let mainPageUrl = window.location.href;
  const history = useHistory();

  useEffect(() => {
    if (!Array.isArray(entities.data) || !entities.data.length) {
      getEntities();
    }
  }, [getEntities]);

  useEffect(() => {
    if (!open) {
      onCloseCallBack();
    }
  }, [onCloseCallBack, open]);

  useEffect(() => {
    if (entities && entities.isDataUpdated && entities.data) {
      const storedEntity = getEntityId();
      const sE = entities.data.find((i) => i.entity_id === storedEntity) || {};
      onEntityChange(sE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  // eslint-disable-next-line no-unused-vars

  const onEntityChange = (value) => {
    if (value && value.entity_id) {
      setSelectedEntity(value);
      const storedEntity = getEntityId();
      if (storedEntity !== value.entity_id) {
        setEntityId(value.entity_id);
        let splittedVal = mainPageUrl.split("/");
        history.push(`/${splittedVal[3]}`);
      }
    }
  };
  const onAddBusiness = () => {
    backupAndRemoveEntityId();
    resetCustomerDetails();
    setTimeout(() => {
      history.push("/complete-registration");
    }, 500);
  };
  const onLogout = () => {
    onToggle();
  };
  const onNoPress = () => {
    onToggle();
  };
  const onYesPress = async () => {
    await logout();
    onToggle();
    // styledSnackbar.info('You have been logged out successfully!');
    history.push("/login");
    // history.push("/logout");
  };

  const onToggle = () => {
    setLogoutOpen(!logoutOpen);
  };

  const LoaderIcon = () => (
    <CircularProgress
      classes={{ root: classes.circularProgressRoot }}
      color="inherit"
      size={20}
    />
  );
  // const ExpandMore = () => (<ExpandMoreIcon classes={classes.expandIcon} size={20} />);

  const RenderValue = ({ option, selected, from }) => {
    return (
      <>
        <div className={classes.optionRoot}>
          <div className={classes.row}>
            <div>
              <Avatar
                className={classes.avatar}
                alt="dp"
                src={option.image_url}
              >
                {" "}
                <Icon color="primary" fontSize="small" component={StoreIcon} />
              </Avatar>
            </div>
            <div>
              <Typography className={classes.companyTxt}>
                {option.company_name}
              </Typography>
            </div>
          </div>
          <div>
            {from === "option" && (
              <div>
                <ArrowForwardIosIcon className={classes.arrow} />
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classes.rootDiv}>
      <Select
        id={props.id}
        name={"entity_id"}
        ref={ref}
        // inputProps={{
        //     classes: {
        //         icon: classes.icon,
        //     },
        // }}
        // startAdornment={(
        //     <Avatar
        //         className={classes.avatar}
        //         alt="dp"
        //         src={selectedEntity.image_url}
        //     > <Icon color="primary" fontSize="medium" component={StoreIcon} /></Avatar>
        // )}
        IconComponent={entities.loading ? LoaderIcon : ExpandMoreIcon}
        placeholder=""
        onChange={(e) => {
          const { value } = e.target || {};
          if (value === "add_business") {
            onAddBusiness();
          } else if (value === "logout") {
            onLogout();
          } else {
            onEntityChange(value);
            // window.location && window.location.reload();
            if (window?.location?.href) window.location.href = "/"
          }
        }}
        value={selectedEntity}
        className={props.disabled ? classes.disabledRoot : classes.root}
        variant="filled"
        size="small"
        fullWidth
        disableUnderline
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          classes: { paper: classes.dropdownStyle },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          anchorEl: ref.current,
          disableEnforceFocus: true,
        }}
        style={{
          borderRadius: 6,
        }}
        renderValue={(option) => (
          <div className={classes.renderValueDiv}>
            <RenderValue option={option} from="value" selected />
          </div>
        )}
      >
        {!entities.error ? (
          Array.isArray(entities.data) &&
          entities.data.map((option) => (
          
              <MenuItem key={option.entity_id} value={option}>
                <RenderValue option={option} from="option" />
              </MenuItem>
          ))
        ) : (
          <MenuItem disabled key={2312313123} value={""}>
            <StyledError data={entities} />
          </MenuItem>
        )}

        
          <MenuItem key={"add_business"} value="add_business">
            <Can
                I={TRULY_ACL.HOME.functions.ADD_BUSINESS.code}
                a={TRULY_ACL.HOME.code}
              >
              <div className={classes.addBusinessRootDiv}>
                <div className={classes.plusIconDiv}>
                  <AddIcon className={classes.plusIcon} fontSize="medium" />
                </div>
                <div>
                  <Typography color="grey600" className={classes.companyTxt}>
                    <FormattedMessage {...intl.addBusiness} />
                  </Typography>
                </div>
              </div>
            </Can>
          </MenuItem>

        <Divider className={classes.divider} />

        <MenuItem key={"logout"} value={"logout"}>
          <div className={classes.logoutRootDiv}>
            <div className={classes.plusIconDiv}>
              <ExitToAppIcon className={classes.plusIcon} fontSize="medium" />
            </div>
            <div>
              <Typography color="grey600" className={classes.companyTxt}>
                <FormattedMessage {...intl.logout} />
              </Typography>
            </div>
          </div>
        </MenuItem>
      </Select>

      <StyledDialog
        open={logoutOpen}
        noFullScreen
        disableBackdropClick={false}
        backBtn={false}
        type="confirm"
        message="Log out of Truly Financial?"
        title="Logout"
        btnOneTitle="Cancel"
        btnOneAction={onNoPress}
        btnTwoTitle="Log out"
        btnTwoAction={onYesPress}
        header={false}
        onClose={onNoPress}
        footer={true}
        closeIcon
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  entities: getEntitiesState(),
});

function mapDispatchToProps(dispatch) {
  return {
    getEntities: (data) => dispatch(getEntityListAction(data)),
    resetCustomerDetails: (data) =>
      dispatch(getCustomerDetailsSetDataAction(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SelectEntity);
