/* eslint-disable no-unused-vars */
// import { makeStyles } from '@material-ui/core';
import React, { memo, useEffect, useState } from 'react';
import { IconButton, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import intl from 'containers/OnBoarding/intl/OnBoardingIntl';
import { useIntl } from 'react-intl';
import SearchIcon from '@material-ui/icons/Search';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Chip, Typography } from 'components/ui';
import CancelIcon from '@material-ui/icons/Cancel';
import { flag } from 'utils/flag';
import { groupRegionWiseCountry } from 'utils/common';

const useStyles = makeStyles(theme => ({
    rootSearch: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: theme.spacing(4, 0, 0)
    },
    autoRoot: {
        flex: 1,
        margin: theme.spacing(0, 0, 1.3, 0),
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 12, 1.3, 12)
        }

    },

    autocomplete: {
        backgroundColor: theme.palette.grey50,
        height: theme.spacing(6),
        padding: theme.spacing(0, 2),
        '& .MuiFormControl-fullWidth': {
            flex: 1,
            height: theme.spacing(6),
        },
        borderRadius: theme.spacing(0.6),
        alignItems: 'center',
        display: 'flex'
    },
    input: {
        '& .MuiInput-root': {
            height: 'inherit'
        },
        '& .MuiFormLabel-root': {
            ...theme.typography.subtitle1,
            color: theme.palette.grey500,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: 'inherit',
            textAlign: 'left'
        },
        '&::placeholder': {
            ...theme.typography.subtitle1,
            color: theme.palette.grey500
        },
    },
    listMainDiv: {
        margin: theme.spacing(3, 0, 0, 0)
    },
    continentalTxt: {
        marginBottom: theme.spacing(1),
        flex: 1
    },
    divider: {
        backgroundColor: theme.palette.primary.main
    },
    tagDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: theme.spacing(0, 1, 1, 0),

    },
    tag: {
        marginTop: '1.5px'
    },
    chipClassName: {
        [theme.breakpoints.down('md')]: {
            // maxWidth: theme.spacing(18.75),
        },
    },
    closeIcon: {
        padding: theme.spacing(0),
        marginRight: theme.spacing(0.25),
        marginLeft: theme.spacing(0.5),
    },
    icon: {
        color: theme.palette.grey400,
        fontSize: '16px'
    },
    minDiv: {
        margin: theme.spacing(2, 0),
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap'
    },
    flagImg:
        { height: theme.spacing(1.5), width: theme.spacing(2), marginRight: theme.spacing(1), filter: `drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))` },
    countryDiv: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        flexWrap: 'wrap',
        marginTop: theme.spacing(3),
        justifyContent: 'center'
    }

}));

function TypicallyDoBusiness(props = {}) {

    const { countries, onChangeHandleCountry, preSelectedCountryObj = {}, selectedCountryArray } = props;

    const classes = useStyles();
    const intlStr = useIntl();
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [list, setList] = useState({});

    useEffect(() => {

        if (preSelectedCountryObj && Object.keys(selectedCountry).length === 0) {
            setList(preSelectedCountryObj);
        }
        if (selectedCountryArray.length > 0) {
            setSelectedCountry(selectedCountryArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preSelectedCountryObj, selectedCountryArray]);

    const data = countries.data || [];

    const onChangeCountry = (e, value) => {
        setSelectedCountry(value);
        onChangeHandleCountry && onChangeHandleCountry(value);
        let data = groupRegionWiseCountry(value);
        setList(data);
    };
    const onDeleteBtnPress = (index) => {
        let selectedCountryTemp = [...selectedCountry];
        if (selectedCountryTemp[index]) {
            let removeFromSelectedCountry = selectedCountryTemp.filter((item) => (item !== selectedCountryTemp[index]));
            setSelectedCountry(removeFromSelectedCountry);
            onChangeHandleCountry && onChangeHandleCountry(removeFromSelectedCountry);
        }
    };

    return (<div className={classes.rootSearch}>
        <div className={classes.autoRoot} >
            <Autocomplete
                multiple
                freeSolo
                value={selectedCountry}
                options={data}
                className={classes.autocomplete}
                getOptionLabel={(option) => option.country_name}
                disableClearable
                filterSelectedOptions
                onChange={onChangeCountry}
                renderOption={(option) => (
                    <React.Fragment>
                        <img className={classes.flagImg} src={flag(option.country_code)} alt="item" />
                        {option.country_name}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className={classes.input}
                        placeholder={`${intlStr.formatMessage(intl.onBoardingSearch)}`}
                        InputProps={{
                            ...params.InputProps,
                            classes: { input: classes.input },

                            disableUnderline: true,
                            startAdornment: (
                                <SearchIcon />
                            )
                        }}
                    />
                )}
            />
        </div>
        <div className={classes.countryDiv} >
            {
                Array.isArray(selectedCountry) && selectedCountry.map((item, index) => {
                    return (
                        <>
                            <div key={index} className={classes.tagDiv}>
                                <Chip className={classes.chipClassName} color={"#D2E8F1"} size="medium" label={(
                                    <>
                                        <img className={classes.flagImg} src={flag(item.country_code)} alt="item" />
                                        <Typography
                                            color={"grey700"}
                                            variant="caption"
                                            className={classes.tag}
                                        >{item.country_name}</Typography>
                                        {
                                            <IconButton className={classes.closeIcon} onClick={() => { onDeleteBtnPress(index); }} >
                                                <CancelIcon className={classes.icon} />
                                            </IconButton>
                                        }
                                    </>
                                )} />
                            </div>
                        </>
                    );
                })
            }
        </div>


    </div>);
}

const mapStateToProps = (s, p) => {
    return createStructuredSelector({
        // allCountries: getCountryListState(),
        // destinationCountries: getDestinationCountryListState(),
        // countries: p.onlyDestination ? getDestinationCountryListState() : getCountryListState(),
    });
};

function mapDispatchToProps(dispatch) {
    return {
        // getCountries: data => dispatch(getCountryListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(TypicallyDoBusiness,
    (prevProps, nextProps) => (
        // (JSON.stringify(prevProps.allCountries) === JSON.stringify(nextProps.allCountries) &&
        (JSON.stringify(prevProps.countries) === JSON.stringify(nextProps.countries) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus &&
            prevProps.disabled === nextProps.disabled &&
            JSON.stringify(prevProps.preSelectedCountry) === JSON.stringify(nextProps.preSelectedCountry) &&
            JSON.stringify(prevProps.selectedCountryArray) === JSON.stringify(nextProps.selectedCountryArray)
        )
    )
);
