import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/TeamMembers/intl/TeamMembersIntl';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import completeId from 'assets/images/completeId.png';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        backgroundColor: 'white',
        height: `calc(100vh - ${theme.spacing(11)}px)`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    img: {
        width: theme.spacing(30.25),
        height: theme.spacing(30.25)
    },
    title: {
        marginTop: theme.spacing(8)
    },
    subTitle: {
        marginTop: theme.spacing(2)
    },
    continueBtn: {
        marginTop: theme.spacing(7),
    },
    secondBtn: {
        marginTop: theme.spacing(8),
    }
}));

function VerificationSubmitted(props = {}) {
    const classes = useStyles();
    const { data, showLoginBtn = true } = props;
    const teamMemberData = data || {};
    const location = useLocation();
    const firstName = (teamMemberData && teamMemberData.entity_person_first_name) || "";
    const history = useHistory();
    const { isDialog } = queryString.parse(location.search);

    // eslint-disable-next-line no-unused-vars
    const onCloseWindow = () => {
        // window.open('/', '_self', '');
        window.close();
    };
    const onGoToTruly = () => {
        history.push('/login');
    };
    return (<div className={classes.root}>

        <img src={completeId} alt="img" className={classes.img} />
        <div>
            <Typography variant="titleMobile" color="grey700" className={classes.title} ><FormattedMessage {...intl.submittedVerificationResidentialTitle} />  {firstName}. </Typography>
        </div>
        {showLoginBtn ? <Typography variant="body1" color="grey700" className={classes.subTitle} >{isDialog ? <FormattedMessage {...intl.submittedVerificationResidentialSubtileTitleDialog} /> : <FormattedMessage {...intl.submittedVerificationResidentialSubtileTitle} />} </Typography>
            : <div><Typography variant="body1" color="grey500" className={classes.subTitle} ><FormattedMessage {...intl.submittedVerificationStackHolderApproval} /> </Typography>
                <Typography variant="body1" color="grey500"  ><FormattedMessage {...intl.submittedVerificationStackHolderApprovalBreak} /> </Typography></div>
        }{
            showLoginBtn && !isDialog &&
            <div>
                {/* <Button
                    title={"Close Window"}
                    className={classes.continueBtn}
                    variant={"outlined"}
                    color={"primary"}
                    onClick={onCloseWindow}
                /> */}
                <Button
                    title={"Go to Truly Financial"}
                    className={classes.secondBtn}

                    onClick={onGoToTruly}
                />
            </div>
        }

    </div>);
}



export default memo(VerificationSubmitted);
