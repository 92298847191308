import React, { useState, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getJurisdictionListAction } from 'store/Reference/referenceActions';
// import { getJurisdictionListState } from 'store/Reference/referenceSelectors';


function SelectJurisdiction(props) {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState({});
    const { jurisdictionList, getJurisdictionList, propsjurisdictionList,
        // propsGetJurisdictionList,
        countryCode } = props;
    const options = propsjurisdictionList || jurisdictionList;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (countryCode) {
            getJurisdictionList({ countryCode: countryCode });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    const onChange = (e, val) => {
        setValue(val || {});
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: val && val.state_code
            }
        }, val);
    };


    return (<>
        <Autocomplete
            {...props}
            getOptions={() => null}
            options={options}
            onChange={onChange}
            onBlur={props.onBlur}
            selectKey="state_code"
            labelKey="state_name"
            makeFocus={props.makeFocus}
            helperText={props.helperText}
            renderOption={(option) => (
                <React.Fragment>
                    {option.state_name}
                </React.Fragment>
            )}
        />
    </>);
}


// const mapStateToProps = createStructuredSelector({
//     jurisdictionList: getJurisdictionListState(),
// });

const mapStateToProps = (s, p) => {
    const jl = s.references.getJurisdictionListState;
    let jurisdictionList = jl;
    if (p.countryCode === 'CA' && ['CORPORATION'].includes(p.companyType)) {
        jurisdictionList = {
            ...jl,
            data: jl?.data?.filter(i => i.state_code !== 'UI') || []
        }
    }
    return { jurisdictionList };
}

function mapDispatchToProps(dispatch) {
    return {
        getJurisdictionList: data => dispatch(getJurisdictionListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectJurisdiction,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.jurisdictionList) === JSON.stringify(nextProps.jurisdictionList) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);