import { styledSnackbar } from "containers/Common/Snackbar";
import PublicRoutes from "routes/public";
import {
  getAccessToken, getEntityId,
  getTempAccessToken,
  // getLastAuthUser,
  // getRegistrationToken,
  logout,
} from "./auth";
import { getHeader, getUrlParamByName } from "./common";
import { parseResponse } from "./parser";
// const REACT_APP_ENV = (process.env && process.env.REACT_APP_ENV);
function parseJSON(response = {}) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return (typeof response.json === 'function' && response.json().then(data => ({ ...data, status: response.status, ok: response.ok }))) || null;
}

function checkStatus(response) {
  if (response.status === 401) {
    logout();
    styledSnackbar.error('Session Expired, Please Login to continue');
    setTimeout(() => {
      const pathname = window.location.pathname;
      const isPublic = PublicRoutes.some(i => i.path === pathname);
      if (!isPublic) {
        window.location.reload();
      } else {
        window.location.href = '/login';
      }
    }, 300);
    // styledSnackbar.error((response.error && response.error.message) || 'Unauthorized');
    return response;
  }

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 403) {
    // styledSnackbar.error((response.error && response.error.message) || 'Access Denied');
    styledSnackbar.error('Access Denied');
    return response;
  }

  if (response.status >= 500) {
    styledSnackbar.error('Oops, Something broken!');
    return response;
  }

  return response;

  // const error = new Error(response.statusText);
  // error.response = response;
  // throw error;
}

function checkResponse(response, feedback = {}) {
  const parsedResponse = parseResponse(response);
  if (feedback.successFeedback && !parsedResponse.error && parsedResponse.message) {
    styledSnackbar.success(parsedResponse.message);
  } else if (feedback.errorFeedback && parsedResponse.error) {
    styledSnackbar.error(parsedResponse.error.message);
  }
  return parsedResponse;
}

export function requestWithFeedback(url, options = {}) {
  return request(url, options, { successFeedback: true, errorFeedback: true });
}

export function requestWithSuccessFeedback(url, options = {}) {
  return request(url, options, { successFeedback: true, errorFeedback: false });
}

export function requestWithErrorFeedback(url, options = {}) {
  return request(url, options, { successFeedback: false, errorFeedback: true });
}



// var tokenRefreshTimeout = null;

export function request(url, options = {}, feedback = {}) {
  const headers = {
    Accept: 'application/json',
    'Authorization': getUrlParamByName('token') || getTempAccessToken() || getAccessToken(),
    // 'X-Entity-Id': getEntityId() || null,
    ...getHeader('X-Entity-Id', getEntityId),
  };

  options.headers = {
    'Content-type': 'application/json; charset=utf-8',
    ...headers,
    ...options.headers
  };

  if (options.body && typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }

  if (options.formdata) {
    const formdata = options.formdata;
    const filedata = new FormData();
    const keys = Object.keys(options.formdata);

    keys.forEach(item => {
      formdata[item] && filedata.append(item, formdata[item]);
    });
    // filedata.append("", formdata.file, 'test.png')
    options.method = 'post';
    options.body = filedata;
    options.headers = headers;

  }

  // if (REACT_APP_ENV === 'production') {
  //   options.credentials = 'include';
  // }
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(response => checkResponse(response, feedback)).catch(function (error) {
      styledSnackbar.error(error.toString());
      const parsedResponse = parseResponse(error);
      return parsedResponse;
    });
}


export function requestWithOutErrorFeedback(url, options = {}, feedback = {}) {
  const headers = {
    Accept: 'application/json',
    'Authorization': getAccessToken(),
    // 'X-Entity-Id': getEntityId() || null,
    ...getHeader('X-Entity-Id', getEntityId),
  };

  options.headers = {
    'Content-type': 'application/json; charset=utf-8',
    ...headers,
    ...options.headers
  };

  if (options.body && typeof options.body !== 'string') {
    options.body = JSON.stringify(options.body);
  }

  if (options.formdata) {
    const formdata = options.formdata;
    const filedata = new FormData();
    const keys = Object.keys(options.formdata);

    keys.forEach(item => {
      formdata[item] && filedata.append(item, formdata[item]);
    });
    // filedata.append("", formdata.file, 'test.png')
    options.method = 'post';
    options.body = filedata;
    options.headers = headers;

  }

  // if (REACT_APP_ENV === 'production') {
  //   options.credentials = 'include';
  // }
  return fetch(url, options)
}