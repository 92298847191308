/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeStyles } from '@material-ui/core/styles';
import { removeTagAction } from 'store/Tag/tagActions';
import { getTagsState, addTagsState } from 'store/Tag/tagSelectors';
import { Chip, IconButton } from '@material-ui/core';
import { Typography } from 'components/ui';
import AddTag from './AddTag';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    lastElement: {
        width: '-webkit-fill-available',
    },
    link: {
        color: '#0075E1'
    },
    addTag: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    addDiv: {
        flexGrow: 1,
        marginLeft: theme.spacing(2)
    }
}));

function Tag(props = {}) {
    const classes = useStyles();
    const { removeTag,
        tagConstants = {}, noLabel,
        defaultTags = [], onSuccess = () => null,
        paymentId, tagComponent,
    } = props;

    const [showAddTag, setShowAddTag] = useState(false);

    const addTagHandler = () => {
        setShowAddTag((d) => !d);
    }

    const deleteAndAddTagHandler = () => {
        removeTag({
            tagId: defaultTags.length ? defaultTags[0].tag_id : '',
            tagAssociateType: tagConstants.tagAssociateType,
            tagAssociateId: tagConstants.tagAssociateId,
            cb: () => onSuccess({cb:addTagHandler}),
        });
    }

    if (!defaultTags.length && !showAddTag) {
        return tagComponent ? tagComponent : <Typography variant="body2">
        <a className={classes.link} href="#" onClick={addTagHandler}>Tag This Payment</a>
    </Typography>
    } else if(showAddTag) {
    return (<div className={classes.addTag}>
        <div className={classes.addDiv}>
            <AddTag noLabel={noLabel} paymentId={paymentId} defaultTags={defaultTags} onSuccess={onSuccess} tagConstants={tagConstants} />
        </div>
        <div>
            <IconButton onClick={addTagHandler} size="small" aria-label="close">
                <CloseIcon className={classes.iconButton} />
            </IconButton>
        </div>
    </div>)
    } else if (defaultTags[0]){
        return  <Chip
        variant="outlined"
        style={{backgroundColor:defaultTags[0].tag_color, color: 'black'}}
        label={defaultTags[0].tag_name}
        size="small"
        onDelete={deleteAndAddTagHandler}
    />;
    }

};

const mapStateToProps = createStructuredSelector({
    tags: getTagsState(),
    addTagsState: addTagsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        removeTag: data => dispatch(removeTagAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo)(Tag);
