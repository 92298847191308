// import { makeStyles } from '@material-ui/core';
import React, { memo, useEffect } from 'react';
import { Grid, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import intl from 'containers/OnBoarding/intl/RegistrationStatusIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import completeReg from 'assets/images/completeReg.png';
import reg_hold from 'assets/images/reg_hold.png';
import { Button, Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'utils/hooks';
import { useHistory } from 'react-router';
import { getCustomerDetailsState } from 'store/Register/registerSelectors';
import { getCustomerDetailsAction, updateStepAction } from 'store/Register/registerActions';
import { Loader } from 'components';
import { getAccessToken } from 'utils/auth';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GetUserCountryName from './GetUserCountryName';
import { getMainStepFromStep } from 'store/Register/registerParser';

const useStyles = makeStyles(theme => ({

    rootGrid: {
        flex: 1,
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(70deg, #F3CFB3 5%, #8CDFBA 90%);'
    },
    rootGridHold: {
        flex: 1,
        minHeight: '100vh',

    },
    insideRoot: {
        flex: 1,
        borderTopLeftRadius: theme.spacing(3),
        borderTopRightRadius: theme.spacing(3),
        backgroundColor: 'white',
        margin: theme.spacing(5, 18.75, 0, 18.75),
        padding: theme.spacing(2),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(2),
        },
        // [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
        //     padding: theme.spacing(2)
        // },
        display: 'flex',
        alignItems: 'center ',
        flexDirection: 'column',
        width: "-webkit-fill-available"
    },
    completeReg: {
        width: theme.spacing(64.125),
        height: theme.spacing(42),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            width: theme.spacing(32.1),
            height: theme.spacing(21),
        }
    },
    holdReg: {
        width: theme.spacing(30),
        height: theme.spacing(30),
        margin: theme.spacing(15, 0, 0, 0)
    },
    title: {
        margin: theme.spacing(3, 0, 2, 0)
    },
    subTitle: {
        margin: theme.spacing(0, 0, 3, 0),
        textAlign: 'center'
    },
    align: {
        margin: theme.spacing(0, 4)
    },
    holdSubtitle: {
        margin: theme.spacing(6, 0, 0, 0),
        textAlign: 'center'
    },
    lastSubTitle: {
        margin: theme.spacing(3, 0, 6.2, 0),
        textAlign: 'center'
    },
    btnDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    iconBtnOne: {
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(2),
        height: theme.spacing(5),
        width: theme.spacing(5)
    },
    iconBtnTow: {
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        height: theme.spacing(5),
        width: theme.spacing(5)
    },
    icon: {
        fontSize: theme.spacing(2.5)
    },
    iconTwo: {
        fontSize: theme.spacing(3)
    },
    center: {
        textAlign: 'center'
    },
    lastTxt: {
        textAlign: 'center',
        margin: theme.spacing(3, 0, 0, 0)
    }
}));

function RegistrationOnHold(props = {}) {

    const {
        customerDetailsState,
        getCustomerDetails,
        updateStep
    } = props;
    const customerDetailsStateData = customerDetailsState.data || {};
    const classes = useStyles();
    let query = useQuery();
    const t = query.get('token');
    const name = query.get('n') || (customerDetailsStateData.prs_user && customerDetailsStateData.entity_person_first_name);
    const history = useHistory();
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const iSAllStepCompleted = customerDetailsStateData.isAllStepsCompleted;


    useEffect(() => {
        getCustomerDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const continueToTruly = () => {
        if (t !== getAccessToken()) {
            history.push(`/home`);
        } else {
            history.push(`/login`);
        }
    };

    if (customerDetailsState.loading) {
        return (<Loader />);
    }
    const onBack = () => {
        var currentStep = "CUSTOMER_INTRODUCTION";
        const mainStep = getMainStepFromStep(currentStep);
        updateStep({
            data: {
                ...customerDetailsStateData,
                currentStep,
                mainStep,
                slideStepDirection: 'left',
            }
        });
        history.push('/complete-registration?s=CUSTOMER_INTRODUCTION');
    };


    return (
        <>
            {
                iSAllStepCompleted ?
                    <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.rootGrid} >

                        <img src={completeReg} alt="Complete Registration Img" className={classes.completeReg} />

                        <Typography color="grey700" variant={match ? "h5" : "h2"} className={classes.title} > <span><FormattedMessage {...intl.statusCompleteTitle} />{name ? `, ${name || ''}` : ''}.</span> </Typography>
                        <div className={classes.subTitle} >
                            <Typography color="grey700" variant={"body1"}> <FormattedMessage {...intl.statusCompleteSubTitle} /></Typography>
                        </div>
                        <Grid>
                            <Button size="small"
                                onClick={continueToTruly}
                            >
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.btnTitle} />
                                </div>
                            </Button>
                        </Grid>


                    </Grid> :
                    <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.rootGridHold} >
                        <Grid className={classes.insideRoot} >
                            <img src={reg_hold} alt="Hold Registration Img" className={classes.holdReg} />

                            <Typography color="grey700" variant={match ? "h6" : "titleMobile"} className={classes.holdSubtitle} > <FormattedMessage {...intl.statusHoldTitle} /> <GetUserCountryName data={customerDetailsStateData} /> <FormattedMessage {...intl.statusHoldTitleHalf} /> </Typography>

                            {/* <Typography className={classes.holdSubtitle} color="grey700" variant={"body1"}>  <FormattedMessage {...intl.statusHoldSubTitle} /></Typography> */}
                            <Typography color="grey700" className={classes.lastTxt} variant={"body1"} ><FormattedMessage {...intl.statusHoldSubTitleBreak} /></Typography>

                            <Typography color="grey700" className={classes.lastSubTitle} variant={"body1"} ><FormattedMessage {...intl.statusHoldSubTitleThree} /></Typography>
                            <div className={classes.btnDiv} >
                                <IconButton onClick={onBack} className={classes.iconBtnOne} >
                                    <ChevronLeftIcon className={classes.iconTwo} color="primary" />
                                </IconButton>
                                {/* <IconButton onClick={onClose} className={classes.iconBtnTow} >
                                    <ClearIcon className={classes.icon} color="primary" />
                                </IconButton> */}

                            </div>
                        </Grid>

                    </Grid>
            }
        </>

    );
}

const mapStateToProps = () => {
    return createStructuredSelector({
        customerDetailsState: getCustomerDetailsState(),

    });
};

function mapDispatchToProps(dispatch) {
    return {
        getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
        updateStep: (data) => dispatch(updateStepAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(RegistrationOnHold);
