import {
  RESET_EVENT_SCHEDULER,
  CREATE_EVENT, CREATE_EVENT_SET_DATA,
  DELETE_EVENT, DELETE_EVENT_SET_DATA,
} from './eventSchedulerConstants';

export function actionResetEventScheduler() {
  return { type: RESET_EVENT_SCHEDULER };
}

export function createEventAction(data) {
  return { type: CREATE_EVENT, data };
}
export function createEventSetDataAction(data) {
  return { type: CREATE_EVENT_SET_DATA, data };
}

export function deleteEventAction(data) {
  return { type: DELETE_EVENT, data };
}
export function deleteEventSetDataAction(data) {
  return { type: DELETE_EVENT_SET_DATA, data };
}
