import moment from "moment";
import { removeNullKeys } from "utils/common";

export const sendPaymentParser = (data = {}) => {
    const { cb, body = {} } = data;
    const { endsOn, end_date } = body;
    var endDate = end_date;
    if (!endDate && endsOn === 2) {
        const currentYear = new Date().getFullYear();
        endDate = moment(new Date(currentYear, 11, 31)).format("YYYY-MM-DD");
    }

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            amount: body.amount,
            amount_currency_code: body.amount_currency_code,
            contact_id: body.contact_id,
            payee_instrument_id: body.payee_instrument_id,
            payer_instrument_id: body.payer_instrument_id,
            note: body.note,
            purpose_code: body.purpose_code,
            is_recurring: body.is_recurring,
            event_occurances: body.event_occurances || null,
            event_schedule_recurring_type_id: body.event_schedule_recurring_type_id,
            end_date: endDate ? new Date(endDate).toISOString() : null,
            separation_count: body.separation_count,
            day_of_week: body.day_of_week,
            day_of_month: body.day_of_month,
            month_of_year: body.month_of_year,
            payment_at: body.payment_at ? new Date(body.payment_at).toISOString() : null,
            invoice_number: body.invoice_number,
            invoice_date: body.invoice_date,
        }),
    };

    return parsedData;
};