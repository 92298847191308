const ACCOUNT = {
    code: "ACCOUNT",
    name: "Account",
    path: "/accounts",
    functions: {
        LIST_ACCOUNTS: {
            code: "LIST_ACCOUNTS",
            name: "Account List",
            isPage: true,
            component: 'AccountListPage',
            path: "",
            getRoutePath: () => `${ACCOUNT.path}${ACCOUNT.functions.LIST_ACCOUNTS.path}`,
            getFullPath: () => `${ACCOUNT.path}${ACCOUNT.functions.LIST_ACCOUNTS.path}`,
            preview: {
                    code: "DETAILS_ACCOUNTS",
                    name: "Account Details",
                    isPage: true,
                    component: 'AccountListPage',
                    path: "/:id",
                    getRoutePath: () => [`${ACCOUNT.path}${ACCOUNT.functions.LIST_ACCOUNTS.path}`,`${ACCOUNT.path}${ACCOUNT.functions.DETAILS_ACCOUNTS.path}`],
                    getFullPath: (id) => `${ACCOUNT.path}${ACCOUNT.functions.DETAILS_ACCOUNTS.path}`.replace(
                        ":id",
                        id
                    )
            }
        },
        DETAILS_ACCOUNTS: {
            code: "DETAILS_ACCOUNTS",
            name: "Account Details",
            isPage: "",
            component: 'AccountListPage',
            path: "/:id",
            getRoutePath: () => `${ACCOUNT.path}${ACCOUNT.functions.DETAILS_ACCOUNTS.path}`,
            getFullPath: (id) => `${ACCOUNT.path}${ACCOUNT.functions.DETAILS_ACCOUNTS.path}`.replace(
                ":id",
                id
            ),
        },
        ACCOUNT_AVAILABLE_CURRENCIES: {
            code: "ACCOUNT_AVAILABLE_CURRENCIES",
            name: "Account available currencies",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT.path}${ACCOUNT.functions.ACCOUNT_AVAILABLE_CURRENCIES.path}`,
            getFullPath: () => `${ACCOUNT.path}${ACCOUNT.functions.ACCOUNT_AVAILABLE_CURRENCIES.path}`,
        },
    },
};

export default ACCOUNT;
