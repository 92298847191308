import {
  RESET_ACCOUNTS,
  GET_ACCOUNT_LIST, GET_ACCOUNT_LIST_SET_DATA,
  GET_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS_SET_DATA,
  ACCOUNT_PAYMENT_TRANSFER, ACCOUNT_PAYMENT_TRANSFER_SET_DATA,
  CREATE_CHECK_IN_ACCOUNT, CREATE_CHECK_IN_ACCOUNT_SET_DATA,
  ACCOUNT_PAYMENT_LIST, ACCOUNT_PAYMENT_LIST_SET_DATA,
  ACCOUNT_PAYMENT_DETAIL, ACCOUNT_PAYMENT_DETAIL_SET_DATA,
  GET_ACCOUNT_CURRENCIES, GET_ACCOUNT_CURRENCIES_SET_DATA,
  ACCOUNT_PAYMENT_LIST_RESET_DATA,
  GET_CHECK_IN_ACCOUNT_LIST,
  GET_CHECK_IN_ACCOUNT_LIST_SET_DATA,
  GET_SMART_LINK_ACCOUNT_LIST,
  GET_SMART_LINK_ACCOUNT_LIST_SET_DATA,
  GET_SMART_LINK_CARD_LIST,
  GET_SMART_LINK_CARD_LIST_SET_DATA,
  RESET_SMART_LINK_CARD_LIST,
  GET_SMART_LINK_ACCOUNT_DETAILS, GET_SMART_LINK_ACCOUNT_DETAILS_SET_DATA,
  GET_SMART_LINK_ACCOUNT_PAYMENTS, GET_SMART_LINK_ACCOUNT_PAYMENTS_SET_DATA, RESET_SMART_LINK_ACCOUNT_PAYMENTS,
  ACTIVATE_PHYSICAL_CARD, ACTIVATE_PHYSICAL_CARD_SET_DATA,
  GET_CARD_SECURE_DETAILS, GET_CARD_SECURE_DETAILS_SET_DATA,
  GET_ACCOUNT_LIST_RESET_DATA,
  CARD_CHANGE_PIN,
  CARD_CHANGE_PIN_SET_DATA,
  CARD_LOCK,
  CARD_LOCK_SET_DATA,
  SMART_LINK_ACC_LOCK, SMART_LINK_ACC_LOCK_SET_DATA,
  GET_ACCOUNT_SETTING, GET_ACCOUNT_SETTING_SET_DATA,
  SAVE_ACCOUNT_SETTING, SAVE_ACCOUNT_SETTING_SET_DATA,
  GET_ACCOUNT_TEAM_MEMBERS, GET_ACCOUNT_TEAM_MEMBERS_SET_DATA, GET_TEAM_MEMBER_DETAIL, GET_TEAM_MEMBER_DETAIL_SET_DATA,
  ADD_UPDATE_TEAM_MEMBER, ADD_UPDATE_TEAM_MEMBER_SET_DATA, CREATE_SMART_LINK_ACC, CREATE_SMART_LINK_ACC_SET_DATA,
  GET_CARD_DETAILS, GET_CARD_DETAILS_SET_DATA, ISSUE_SMARTLINK_CARDS, ISSUE_SMARTLINK_CARDS_SET_DATA,
  GET_CARD_PAYMENT_DETAIL, GET_CARD_PAYMENT_DETAIL_SET_DATA, UPDATE_CARD_NICK_NAME, UPDATE_CARD_NICK_NAME_SET_DATA,
  PRE_QUICK_TRANSFER, PRE_QUICK_TRANSFER_SET_DATA, QUICK_TRANSFER, QUICK_TRANSFER_SET_DATA,
  GET_LINK_ACC_LIST, GET_LINK_ACC_LIST_SET_DATA,
  GET_EXTERNAL_ACCOUNTS, GET_EXTERNAL_ACCOUNTS_SET_DATA,
  ADD_EXTERNAL_ACCOUNT, ADD_EXTERNAL_ACCOUNT_SET_DATA,
  GET_EXTERNAL_ACC_DETAILS, GET_EXTERNAL_ACC_DETAILS_SET_DATA,
  REMOVE_EXTERNAL_ACCOUNT, REMOVE_EXTERNAL_ACCOUNT_SET_DATA,
  GET_EXTERNAL_ACC_PAYMENT_LIST, GET_EXTERNAL_ACC_PAYMENT_LIST_SET_DATA,
  GET_EXTERNAL_ACC_BANK_TRANSACTIONS, GET_EXTERNAL_ACC_BANK_TRANSACTIONS_SET_DATA,
  GET_EXTERNAL_ACCOUNT_BALANCE, GET_EXTERNAL_ACCOUNT_BALANCE_SET_DATA,
  LINK_EXTERNAL_ACCOUNT, LINK_EXTERNAL_ACCOUNT_SET_DATA,
  UNLINK_EXTERNAL_ACCOUNT, UNLINK_EXTERNAL_ACCOUNT_SET_DATA,
  GET_GLOBAL_ACCOUNT_LIST, GET_GLOBAL_ACCOUNT_LIST_SET_DATA, GET_GLOBAL_ACCOUNT_LIST_REFRESH,
  CSV_GET_PAYMENT_LIST, CSV_GET_PAYMENT_LIST_SET_DATA, RESET_ACCOUNT_TRANSFER_FORM_DATA, QUICK_TRANSFER_DATA,
  SUBMIT_PAD_VERIFICATION, SUBMIT_PAD_VERIFICATION_SET_DATA, RE_AUTHORIZED_EXTERNAL_ACC, RE_AUTHORIZED_EXTERNAL_ACC_SET_DATA

} from './accountConstants';

import { addExternalAccount, cardSecureDetailsParser, checkingAccountDetailParser, checkingAccountParser, externalAccBankTransitionParser, externalAccountListParser, getAccTeamMemberListParser, getCardPaymentDetailParser, getExternalAccDetailsParser, getSmartLinkCardPaymentListParser, getTeamMemberDetailParser, globalAccountListParser, preQuickTransferParser, smartLinkAccountDetailsParser, smartLinkAccountParser, smartLinkAccountsCardListParser, updateSmartlinkAccountSettingsParser } from './accountParser';

export function resetAccountAction() {
  return { type: RESET_ACCOUNTS };
}

export function resetAccountPaymentAction() {
  return { type: ACCOUNT_PAYMENT_LIST_RESET_DATA };
}
export function resetAccountListAction() {
  return { type: GET_ACCOUNT_LIST_RESET_DATA };
}
export function getAccountListAction(data) {
  return { type: GET_ACCOUNT_LIST, data };
}
export function getAccountListSetDataAction(data, country_code, currenciesArray) {
  return { type: GET_ACCOUNT_LIST_SET_DATA, data: checkingAccountParser(data, country_code, currenciesArray) };
}

export function getAccountDetailsAction(data) {
  return { type: GET_ACCOUNT_DETAILS, data };
}
export function getAccountDetailsSetDataAction(data, countryCode, currenciesArray) {
  return { type: GET_ACCOUNT_DETAILS_SET_DATA, data: checkingAccountDetailParser(data, countryCode, currenciesArray) };
}

export function accountPaymentTransferAction(data) {
  return { type: ACCOUNT_PAYMENT_TRANSFER, data };
}
export function accountPaymentTransferSetDataAction(data) {
  return { type: ACCOUNT_PAYMENT_TRANSFER_SET_DATA, data };
}

export function createCheckInAccountAction(data) {
  return { type: CREATE_CHECK_IN_ACCOUNT, data };
}
export function createCheckInAccountSetDataAction(data) {
  return { type: CREATE_CHECK_IN_ACCOUNT_SET_DATA, data };
}

export function accountPaymentListAction(data) {
  return { type: ACCOUNT_PAYMENT_LIST, data };
}
export function accountPaymentListSetDataAction(data, page) {

  return { type: ACCOUNT_PAYMENT_LIST_SET_DATA, data: getSmartLinkCardPaymentListParser(data), page };
}
export function accountPaymentDetailAction(data) {
  return { type: ACCOUNT_PAYMENT_DETAIL, data };
}
export function accountPaymentDetailSetDataAction(data) {
  return { type: ACCOUNT_PAYMENT_DETAIL_SET_DATA, data };
}
export function getAccountCurrenciesAction(data) {
  return { type: GET_ACCOUNT_CURRENCIES, data };
}
export function getAccountCurrenciesSetDataAction(data) {
  return { type: GET_ACCOUNT_CURRENCIES_SET_DATA, data };
}

export function getCheckInAccountListAction(data) {
  return { type: GET_CHECK_IN_ACCOUNT_LIST, data };
}
export function getCheckInAccountListSetDataAction(data) {
  return { type: GET_CHECK_IN_ACCOUNT_LIST_SET_DATA, data };
}

export function getSmartLinkAccountAction(data) {
  return { type: GET_SMART_LINK_ACCOUNT_LIST, data };
}
export function getSmartLinkAccountSetDataAction(data) {
  return { type: GET_SMART_LINK_ACCOUNT_LIST_SET_DATA, data: smartLinkAccountParser(data) };
}

export function getSmartLinkCardListAction(data) {

  return { type: GET_SMART_LINK_CARD_LIST, data };
}
export function getSmartLinkCardListSetDataAction(data) {
  return { type: GET_SMART_LINK_CARD_LIST_SET_DATA, data: smartLinkAccountsCardListParser(data) };
}
export function resetSmartLinkCardList() {

  return { type: RESET_SMART_LINK_CARD_LIST };
}
export function getSmartLinkAccountDetailsAction(data) {
  return { type: GET_SMART_LINK_ACCOUNT_DETAILS, data };
}
export function getSmartLinkAccountDetailsSetDataAction(data, selectedEntity) {
  return { type: GET_SMART_LINK_ACCOUNT_DETAILS_SET_DATA, data: smartLinkAccountDetailsParser(data, selectedEntity) };
}

export function getSmartLinkAccountPaymentsAction(data) {
  return { type: GET_SMART_LINK_ACCOUNT_PAYMENTS, data };
}
export function getSmartLinkAccountPaymentsSetDataAction(data, page) {
  return { type: GET_SMART_LINK_ACCOUNT_PAYMENTS_SET_DATA, data: getSmartLinkCardPaymentListParser(data), page };
}
export function resetSmartLinkAccountPaymentsAction(data) {
  return { type: RESET_SMART_LINK_ACCOUNT_PAYMENTS, data };
}

export function activatePhysicalCardAction(data) {
  return { type: ACTIVATE_PHYSICAL_CARD, data };
}
export function activatePhysicalCardSetDataAction(data) {
  return { type: ACTIVATE_PHYSICAL_CARD_SET_DATA, data };
}

export function getCardSecureDetailsAction(data) {
  return { type: GET_CARD_SECURE_DETAILS, data };
}
export function getCardSecureDetailsSetDataAction(data) {
  return { type: GET_CARD_SECURE_DETAILS_SET_DATA, data: cardSecureDetailsParser(data) };
}

export function cardChangePinAction(data) {
  return { type: CARD_CHANGE_PIN, data };
}
export function cardChangePinSetDataAction(data) {
  return { type: CARD_CHANGE_PIN_SET_DATA, data };
}

export function cardLockAction(data) {
  return { type: CARD_LOCK, data };
}
export function cardLockSetDataAction(data) {
  return { type: CARD_LOCK_SET_DATA, data };
}

export function smartLinkAccLockAction(data) {
  return { type: SMART_LINK_ACC_LOCK, data };
}
export function smartLinkAccLockSetDataAction(data) {
  return { type: SMART_LINK_ACC_LOCK_SET_DATA, data };
}

export function getAccountSettingAction(data) {
  return { type: GET_ACCOUNT_SETTING, data };
}
export function getAccountSettingSetDataAction(data) {
  return { type: GET_ACCOUNT_SETTING_SET_DATA, data };
}

export function saveAccountSettingAction(data) {
  return { type: SAVE_ACCOUNT_SETTING, data: updateSmartlinkAccountSettingsParser(data) };
}
export function saveAccountSettingSetDataAction(data) {
  return { type: SAVE_ACCOUNT_SETTING_SET_DATA, data };
}

export function getAccountTeamMembersAction(data) {
  return { type: GET_ACCOUNT_TEAM_MEMBERS, data };
}
export function getAccountTeamMembersSetDataAction(data) {
  return { type: GET_ACCOUNT_TEAM_MEMBERS_SET_DATA, data: getAccTeamMemberListParser(data) };
}

export function getTeamMemberDetailAction(data) {
  return { type: GET_TEAM_MEMBER_DETAIL, data };
}
export function getTeamMemberDetailSetDataAction(data) {
  return { type: GET_TEAM_MEMBER_DETAIL_SET_DATA, data: getTeamMemberDetailParser(data) };
}

export function addUpdateTeamMemberAction(data) {
  return { type: ADD_UPDATE_TEAM_MEMBER, data };
}
export function addUpdateTeamMemberSetDataAction(data) {
  return { type: ADD_UPDATE_TEAM_MEMBER_SET_DATA, data };
}

export function createSmartLinkAccAction(data) {
  return { type: CREATE_SMART_LINK_ACC, data };
}
export function createSmartLinkAccSetDataAction(data) {
  return { type: CREATE_SMART_LINK_ACC_SET_DATA, data };
}

export function getCardDetailsAction(data) {
  return { type: GET_CARD_DETAILS, data };
}
export function getCardDetailsSetDataAction(data) {
  return { type: GET_CARD_DETAILS_SET_DATA, data };
}

export function issueSmartlinkCardsAction(data) {
  return { type: ISSUE_SMARTLINK_CARDS, data };
}
export function issueSmartlinkCardsSetDataAction(data) {
  return { type: ISSUE_SMARTLINK_CARDS_SET_DATA, data };
}

export function getCardPaymentDetailAction(data) {
  return { type: GET_CARD_PAYMENT_DETAIL, data };
}
export function getCardPaymentDetailSetDataAction(data) {
  return { type: GET_CARD_PAYMENT_DETAIL_SET_DATA, data: getCardPaymentDetailParser(data) };
}

export function updateCardNickNameAction(data) {
  return { type: UPDATE_CARD_NICK_NAME, data };
}
export function updateCardNickNameSetDataAction(data) {
  return { type: UPDATE_CARD_NICK_NAME_SET_DATA, data };
}

export function preQuickTransferAction(data) {
  return { type: PRE_QUICK_TRANSFER, data };
}
export function preQuickTransferSetDataAction(data) {
  return { type: PRE_QUICK_TRANSFER_SET_DATA, data: preQuickTransferParser(data) };
}

export function quickTransferAction(data) {
  return { type: QUICK_TRANSFER, data };
}
export function quickTransferSetDataAction(data) {
  return { type: QUICK_TRANSFER_SET_DATA, data };
}

export function getLinkAccListAction(data) {
  return { type: GET_LINK_ACC_LIST, data };
}
export function getLinkAccListSetDataAction(data) {
  return { type: GET_LINK_ACC_LIST_SET_DATA, data };
}

export function getExternalAccountsAction(data) {
  return { type: GET_EXTERNAL_ACCOUNTS, data };
}
export function getExternalAccountsSetDataAction(data) {
  return { type: GET_EXTERNAL_ACCOUNTS_SET_DATA, data: externalAccountListParser(data) };
}

export function addExternalAccountAction(data) {
  return { type: ADD_EXTERNAL_ACCOUNT, data: addExternalAccount(data) };
}
export function addExternalAccountSetDataAction(data) {
  return { type: ADD_EXTERNAL_ACCOUNT_SET_DATA, data };
}

export function getExternalAccDetailsAction(data) {
  return { type: GET_EXTERNAL_ACC_DETAILS, data };
}
export function getExternalAccDetailsSetDataAction(data) {
  return { type: GET_EXTERNAL_ACC_DETAILS_SET_DATA, data: getExternalAccDetailsParser(data) };
}

export function removeExternalAccountAction(data) {
  return { type: REMOVE_EXTERNAL_ACCOUNT, data };
}
export function removeExternalAccountSetDataAction(data) {
  return { type: REMOVE_EXTERNAL_ACCOUNT_SET_DATA, data };
}

export function getExternalAccPaymentListAction(data) {
  return { type: GET_EXTERNAL_ACC_PAYMENT_LIST, data };
}
export function getExternalAccPaymentListSetDataAction(data) {
  return { type: GET_EXTERNAL_ACC_PAYMENT_LIST_SET_DATA, data: getSmartLinkCardPaymentListParser(data) };
}

export function getExternalAccBankTransactionsAction(data) {
  return { type: GET_EXTERNAL_ACC_BANK_TRANSACTIONS, data };
}
export function getExternalAccBankTransactionsSetDataAction(data) {
  return { type: GET_EXTERNAL_ACC_BANK_TRANSACTIONS_SET_DATA, data: externalAccBankTransitionParser(data) };
}

export function getExternalAccountBalanceAction(data) {
  return { type: GET_EXTERNAL_ACCOUNT_BALANCE, data };
}
export function getExternalAccountBalanceSetDataAction(data) {
  return { type: GET_EXTERNAL_ACCOUNT_BALANCE_SET_DATA, data };
}

export function linkExternalAccountAction(data) {
  return { type: LINK_EXTERNAL_ACCOUNT, data };
}
export function linkExternalAccountSetDataAction(data) {
  return { type: LINK_EXTERNAL_ACCOUNT_SET_DATA, data };
}

export function unlinkExternalAccountAction(data) {
  return { type: UNLINK_EXTERNAL_ACCOUNT, data };
}
export function unlinkExternalAccountSetDataAction(data) {
  return { type: UNLINK_EXTERNAL_ACCOUNT_SET_DATA, data };
}

export function getGlobalAccountListAction(data) {
  return { type: GET_GLOBAL_ACCOUNT_LIST, data };
}
export function getGlobalAccountListSetDataAction(data, country_code, currenciesArray) {
  return { type: GET_GLOBAL_ACCOUNT_LIST_SET_DATA, data: globalAccountListParser(data, country_code, currenciesArray) };
}

export function getGlobalAccountListRefreshAction(data) {
  return { type: GET_GLOBAL_ACCOUNT_LIST_REFRESH, data };
}
export function csvGetPaymentListAction(data) {
  return { type: CSV_GET_PAYMENT_LIST, data };
}
export function csvGetPaymentListSetDataAction(data) {
  return { type: CSV_GET_PAYMENT_LIST_SET_DATA, data };
}

export function resetAccountTransferFromData(data) {
  return { type: RESET_ACCOUNT_TRANSFER_FORM_DATA, data };
}
export function quickTransferFormData(data) {
  return { type: QUICK_TRANSFER_DATA, data };
}
export function submitPadVerificationAction(data) {
  return { type: SUBMIT_PAD_VERIFICATION, data };
}
export function submitPadVerificationSetDataAction(data) {
  return { type: SUBMIT_PAD_VERIFICATION_SET_DATA, data };
}

export function reAuthorizedExternalAccAction(data) {
  return { type: RE_AUTHORIZED_EXTERNAL_ACC, data: addExternalAccount(data) };
}
export function reAuthorizedExternalAccSetDataAction(data) {
  return { type: RE_AUTHORIZED_EXTERNAL_ACC_SET_DATA, data };
}
