const PAYMENT = {
    code: "PAYMENT",
    name: "Payment",
    path: "/payments",
    functions: {
        PAYMENTS_LIST: {
            code: "PAYMENTS_LIST",
            name: "Payment List",
            isPage: true,
            component: 'PaymentPage',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_LIST.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_LIST.path}`,
        },
        PAYMENTS_FILTER: {
            code: "PAYMENTS_FILTER",
            name: "Payment Filter",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_FILTER.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_FILTER.path}`,
        },
        PAYMENTS_SEARCH: {
            code: "PAYMENTS_SEARCH",
            name: "Payment Search",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_SEARCH.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_SEARCH.path}`,
        },
        PAYMENTS_DETAILS: {
            code: "PAYMENTS_DETAILS",
            name: "Payment Details",
            isPage: true,
            component: '',
            path: "/:paymentId",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_DETAILS.path}`,
            getFullPath: (paymentId) =>
                `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_DETAILS.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },
        PAYMENTS_DELETE: {
            code: "PAYMENTS_DELETE",
            name: "Payment Cancel",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_DELETE.path}`,
            getFullPath: (paymentId) =>
                `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_DELETE.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },
        SEND_PAYMENTS: {
            code: "SEND_PAYMENTS",
            name: "Send Payments",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.SEND_PAYMENTS.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.SEND_PAYMENTS.path}`,
        },
        RECEIVE_PAYMENTS: {
            code: "RECEIVE_PAYMENTS",
            name: "Receive Payment",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.RECEIVE_PAYMENTS.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.RECEIVE_PAYMENTS.path}`,
        },
        SCHEDULE_PAYMENTS: {
            code: "SCHEDULE_PAYMENTS",
            name: "Schedule Payment",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.SCHEDULE_PAYMENTS.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.SCHEDULE_PAYMENTS.path}`,
        },
        PAYMENT_DUPLICATE: {
            code: "PAYMENT_DUPLICATE",
            name: "Payment Duplicate",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENT_DUPLICATE.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENT_DUPLICATE.path}`,
        },
        PAYMENT_MAKE_RECURRING: {
            code: "PAYMENT_MAKE_RECURRING",
            name: "Payment make recurring",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENT_MAKE_RECURRING.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENT_MAKE_RECURRING.path}`,
        },
        PAYMENT_CANCEL: {
            code: "PAYMENT_CANCEL",
            name: "Payment cancel",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENT_CANCEL.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENT_CANCEL.path}`,
        },
        PAYMENTS_CREATE_TAG: {
            code: "PAYMENTS_CREATE_TAG",
            name: "Payment create tag",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_CREATE_TAG.path}`,
            getFullPath: () => `${PAYMENT.path}${PAYMENT.functions.PAYMENTS_CREATE_TAG.path}`,
        }  
    },
};

export default PAYMENT;
