/*
 * StyledText Messages
 *
 * This contains all the text for the StyledText component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.registrationHeaderIntl';

export default defineMessages({
    step: {
        id: `${scope}.step`,
        defaultMessage: 'Step',
    },
    step1: {
        id: `${scope}.step1`,
        defaultMessage: 'Step 1',
    },
    introduction: {
        id: `${scope}.introduction`,
        defaultMessage: 'Introduction',
    },

    step2: {
        id: `${scope}.ste2`,
        defaultMessage: 'Step 2',
    },
    businessDetail: {
        id: `${scope}.businessDetail`,
        defaultMessage: 'Business Details',
    },
    businessBrief: {
        id: `${scope}.businessBrief`,
        defaultMessage: 'Business Brief',
    },
    businessLocated: {
        id: `${scope}.businessLocated`,
        defaultMessage: 'Global Position',
    },
    businessIndustries: {
        id: `${scope}.businessIndustries`,
        defaultMessage: 'Business Industry',
    },


    step3: {
        id: `${scope}.step3`,
        defaultMessage: 'Step 3',
    },
    tailorProfile: {
        id: `${scope}.tailorProfile`,
        defaultMessage: 'Tailor Profile',
    },
    businessUseCase: {
        id: `${scope}.businessUseCase`,
        defaultMessage: 'Business Need',
    },
    businessCountry: {
        id: `${scope}.businessCountry`,
        defaultMessage: 'Global Reach',
    },
    businessFunds: {
        id: `${scope}.businessFunds`,
        defaultMessage: 'Source of Funds',
    },


    step4: {
        id: `${scope}.step4`,
        defaultMessage: 'Step 4',
    },
    businessStructure: {
        id: `${scope}.businessStructure`,
        defaultMessage: 'Ownership Structure',
    },
    personalDetail: {
        id: `${scope}.personalDetail`,
        defaultMessage: `Your Detail's`,
    },
    businessOwners: {
        id: `${scope}.businessOwners`,
        defaultMessage: 'Other Beneficial Owners & Directors',
    },
    selectControlling: {
        id: `${scope}.selectControlling`,
        defaultMessage: 'Controlling party',
    },

    step5: {
        id: `${scope}.step5`,
        defaultMessage: 'Step 5',
    },
    termAndCondition: {
        id: `${scope}.termAndCondition`,
        defaultMessage: 'Terms & Conditions',
    },
    validation: {
        id: `${scope}.validation`,
        defaultMessage: 'Validation',
    },
    idVerification: {
        id: `${scope}.idVerification`,
        defaultMessage: 'ID Verification',
    },
    informationReview: {
        id: `${scope}.informationReview`,
        defaultMessage: 'Information Review',
    },
});
