
import React, { memo } from 'react';
import { Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import { makeStyles, } from '@material-ui/core';
import notHaveDoc from 'assets/images/notHaveDoc.png';
import intl from 'containers/TeamMembers/intl/TeamMembersIntl';
import GoBack from 'containers/OnBoarding/GoBack';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(2),
        height: `calc(100vh - ${theme.appHeaderHeight})`,
    },
    transferImg: {
        margin: theme.spacing(5, 0, 1, 0),
        alignSelf: 'center',
        height: theme.spacing(29.375),
        width: theme.spacing(29.375),
    },
    titleTxt: {
        textAlign: 'center'
    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),
        textAlign: 'center'
    },
    btnDiv: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3, 0)

    }
}));
function NoDocInstructions(props = {}) {

    const { onGoBack = () => null } = props;

    const classes = useStyles();


    return (

        <div className={classes.root} >
            <img alt="notHaveDoc" src={notHaveDoc} className={classes.transferImg} />
            <Typography variant="subtitle1" color="grey700" className={classes.titleTxt}  ><FormattedMessage {...intl.idVerificationDontHaveTitle} /> </Typography>
            <Typography variant="caption" color="grey500" className={classes.subTitle}  ><FormattedMessage {...intl.idVerificationConfirmDetailsBody} /> </Typography>

            <div className={classes.btnDiv} >
                <GoBack
                    onClick={onGoBack}
                />
            </div>
        </div>


    )
};



export default memo(NoDocInstructions);