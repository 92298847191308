
import { defineMessages } from 'react-intl';

export const scope = 'app.components.transfe_rate';

export default defineMessages({
  creditAmount: {
    id: `${scope}.creditAmount`,
    defaultMessage: 'Deposit',
  },
  transferFundsCreditAmount: {
    id: `${scope}.creditAmount`,
    defaultMessage: 'Credit amount',
  },
  conversion: {
    id: `${scope}.conversion`,
    defaultMessage: 'Conversion',
  },
  debitAmount: {
    id: `${scope}.debitAmount`,
    defaultMessage: 'Debit amount',
  },
  rate: {
    id: `${scope}.rate`,
    defaultMessage: 'Exchange Rate',
  },
  fee: {
    id: `${scope}.fee`,
    defaultMessage: 'Fee',
  },
  eta: {
    id: `${scope}.eta`,
    defaultMessage: 'ETA',
  },
  sentOn: {
    id: `${scope}.sentOn`,
    defaultMessage: 'Sent On',
  },
  TransferInitiatedOn: {
    id: `${scope}.TransferInitiatedOn`,
    defaultMessage: 'Initiated on',
  },
  TransferInitiateOn: {
    id: `${scope}.TransferInitiatedOn`,
    defaultMessage: 'Initiate on',
  },
  TransferTag: {
    id: `${scope}.TransferTag`,
    defaultMessage: 'Payment Tag',
  },
  processingTime: {
    id: `${scope}.processingTime`,
    defaultMessage: 'Processing Time',
  },
  initiateBy: {
    id: `${scope}.initiateBy`,
    defaultMessage: 'Initiate By',
  },
  referenceNo: {
    id: `${scope}.referenceNo`,
    defaultMessage: 'Reference No',
  },
});
