import { Grid, makeStyles } from "@material-ui/core";
import OtpInput from "components/OtpInput";
import { Button,
  // TextField,
  Typography } from "components/ui";
import React,{ memo, useEffect } from "react";
const useStyles = makeStyles((theme) => ({
    txt: {
      textAlign: "left",
    },
    linkText: {
      margin: theme.spacing(2, 0),
      textAlign: "center",
      textDecoration: "underline",
    },
    otpDiv: {
      display: "flex",
      justifyContent: "center",
    }
  }));

function VerifyQrCode(props = {}) {
    const { handleVerifyQrToken, setStep, loading, code, onChange } = props;
    const classes = useStyles();

    useEffect(() => {
      if (code?.length === 6) {
        handleVerifyQrToken()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    return (<div>
      <form onSubmit={handleVerifyQrToken} autoComplete="off">
        <Grid
          container
          spacing={3}
          item
          direction="column"
        >
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.txt} >Enter Verification Code</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey500" className={classes.txt}>Check your authentication app (for example Google Authenticator or Duo Mobile) for the time-sensitive code and enter below.</Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <TextField
              type="text"
              id="code"
              name="code"
              value={code}
              placeholder="Enter 6-digit Code"
              required
              errorText="Required"
              onChange={onChange}
              makeFocus
            /> */}
             <div className={classes.otpDiv}>
              <OtpInput
                // type="text"
                id="code"
                name="code"
                value={code}
                // label="Enter 6-digit Code"
                required
                // errorText=""
                onChange={onChange}
              />
            </div>
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                title="Back"
                onClick={(e) => setStep(2)}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                title="Continue"
                disabled={code?.length !== 6 || loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>)
  }

  export default memo(VerifyQrCode)