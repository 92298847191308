import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { request, requestWithErrorFeedback } from 'utils/request';
import {
  // resetTagsAction,
  getTagsSetDataAction,
  addTagsSetDataAction,
  getTagsAction,
  removeTagSetDataAction,
} from './tagActions';
import {
  // RESET_TAGS,
  GET_TAGS,
  ADD_TAGS,
  REMOVE_TAG,
} from './tagConstants';

import {
  TAGS_BASE_API_URL,
} from 'config/apiUrls';

const TAGS_URL = `${ TAGS_BASE_API_URL }/tags`;

export default function* tagsSaga() {
  // add all sagas here
  yield takeLatest(REMOVE_TAG, removeTagSaga);
  yield takeLatest(ADD_TAGS, addTagsSaga);
  yield takeLeading(GET_TAGS, getTagsSaga);
}

export function* getTagsSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${TAGS_URL}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getTagsSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addTagsSaga(args = {}) {
  const { /*id, type,*/ body, cb = () => null } = args.data || {};
  // const requestUrl = `${TAGS_URL}/${type}/${id}/tag`;
  const requestUrl = `${TAGS_URL}`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(addTagsSetDataAction(result));
    yield put(getTagsAction());
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* removeTagSaga(args = {}) {
  const { tagId, tagAssociateType, tagAssociateId, cb = () => null } = args.data || {};
  const requestUrl = `${TAGS_URL}/${tagId}/associates/${tagAssociateType}/${tagAssociateId}`;
  try {
    const result = yield call(request, requestUrl, { method: 'DELETE'});
    yield put(removeTagSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
