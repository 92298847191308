/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Loader,
    // , OptionMenu
} from 'components';
import { FormattedMessage } from 'react-intl';
import intl from './intl/RegistrationCompleteDashboard';
import { Typography, Button } from 'components/ui';
import editCompany from 'assets/images/editCompany.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 4),
            backgroundColor: theme.palette.background.default,
        },
        backgroundColor: theme.palette.primary.contrastText,
        width: "100%",
        flex: 1,
        minHeight: `calc(100vh - ${theme.spacing(10)}px)`,


    },
    img: {
        width: theme.spacing(25.5),
        height: theme.spacing(22.5),
        marginTop: theme.spacing(12.125),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            marginTop: theme.spacing(8)
        },

    },
    title: {
        margin: theme.spacing(6, 0, 3, 0),
        textAlign: 'center'
    },
    subTitle: {
        margin: theme.spacing(0, 2, 0, 2),
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 22, 0, 22),
        },

        textAlign: 'center'
    },
    divider: {
        margin: theme.spacing(3, 0),
        width: '65%'
    },
    align: {
        display: "flex",
        alignItems: "center",
    },
    main: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(0, 1),
        [theme.breakpoints.up("md")]: {
            borderTopLeftRadius: theme.spacing(1.5),
            borderTopRightRadius: theme.spacing(1.5),
        },
        alignItems: 'center',

        backgroundColor: theme.palette.primary.contrastText,
        minHeight: `calc(100vh - ${theme.spacing(10)}px)`,

    },
    btnDiv: {
        marginBottom: theme.spacing(2),
    }
}));

function CompanyStatusEdit(props = {}) {
    const classes = useStyles();
    const { customerDetails, onResendLinkAction, /*onInviteAsTeamAction*/ } = props;
    const customerDetailData = customerDetails.data || {};
    const history = useHistory();

    if (!customerDetailData || customerDetails.loading) {
        return (<Loader componentCenter />);
    }

    const onEdit = () => {
        history.push('/complete-registration?s=CUSTOMER_INTRODUCTION');
    };

    return (
        <Grid container alignItems="center" direction="column" className={classes.root} >
            <div className={classes.main}>
                <img src={editCompany} alt="edit_img" className={classes.img} />
                <Typography variant="h6" className={classes.title} > <FormattedMessage {...intl.editCompanyTitle} /></Typography>
                <Typography variant="body1" color="grey500" className={classes.subTitle} > <FormattedMessage {...intl.editCompanySubTitle} /></Typography>
                <Divider className={classes.divider} />

                <Grid item md="4" className={classes.btnDiv}>
                    <Button size="small"
                        id="introBtn"
                        onClick={onEdit}
                        className={classes.btnDiv}
                        variant={"outlined"}
                    >
                        <div className={classes.align}>
                            <FormattedMessage {...intl.editCompanyBtnTitle} />
                            <NavigateNextIcon />
                        </div>
                    </Button>
                </Grid>
            </div>


        </Grid >
    );
}


export default memo(CompanyStatusEdit);
