
export const RESET_REFERENCES = 'References/RESET_REFERENCES';

export const GET_COUNTRY_LIST = 'References/GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_SET_DATA = 'References/GET_COUNTRY_LIST_SET_DATA';

export const GET_RELATIONSHIP_LIST = 'References/GET_RELATIONSHIP_LIST';
export const GET_RELATIONSHIP_LIST_SET_DATA = 'References/GET_RELATIONSHIP_LIST_SET_DATA';

export const GET_CURRENCY_LIST = 'References/GET_CURRENCY_LIST';
export const GET_CURRENCY_LIST_SET_DATA = 'References/GET_CURRENCY_LIST_SET_DATA';

export const GET_PURPOSE_LIST = 'References/GET_PURPOSE_LIST';
export const GET_PURPOSE_LIST_SET_DATA = 'References/GET_PURPOSE_LIST_SET_DATA';

export const GET_CURRENCY = 'References/GET_CURRENCY';
export const SET_GET_CURRENCY_RESPONSE = 'References/SET_GET_CURRENCY_RESPONSE';

export const SEARCH_LOCATION = 'Reference/SEARCH_LOCATION';
export const SEARCH_LOCATION_SET_DATA = 'Reference/SEARCH_LOCATION_SET_DATA';

export const GET_BANK_ACCOUNT_CONFIG = 'Reference/GET_BANK_ACCOUNT_CONFIG';
export const GET_BANK_ACCOUNT_CONFIG_SET_DATA = 'Reference/GET_BANK_ACCOUNT_CONFIG_SET_DATA';

export const GET_STATE_LIST = 'Reference/GET_STATE_LIST';
export const GET_STATE_LIST_SET_DATA = 'Reference/GET_STATE_LIST_SET_DATA';

export const GET_PAYMENT_STATUS = 'Reference/GET_PAYMENT_STATUS';
export const GET_PAYMENT_STATUS_SET_DATA = 'Reference/GET_PAYMENT_STATUS_SET_DATA';

export const GET_ROLE_LIST = 'Reference/GET_ROLE_LIST';
export const GET_ROLE_LIST_SET_DATA = 'Reference/GET_ROLE_LIST_SET_DATA';
export const GET_JURISDICTION_LIST = 'Reference/GET_JURISDICTION_LIST';
export const GET_JURISDICTION_LIST_SET_DATA = 'Reference/GET_JURISDICTION_LIST_SET_DATA';

export const GET_BUSINESS_TYPE_LIST = 'Reference/GET_BUSINESS_TYPE_LIST';
export const GET_BUSINESS_TYPE_LIST_SET_DATA = 'Reference/GET_BUSINESS_TYPE_LIST_SET_DATA';

export const GET_NUMBER_OF_EMPLOYEES_LIST = 'Reference/GET_NUMBER_OF_EMPLOYEES_LIST';
export const GET_NUMBER_OF_EMPLOYEES_LIST_SET_DATA = 'Reference/GET_NUMBER_OF_EMPLOYEES_LIST_SET_DATA';

export const GET_INDUSTRIES_LIST = "Reference/GET_INDUSTRIES_LIST";
export const GET_INDUSTRIES_LIST_SET_DATA = "Reference/GET_INDUSTRIES_LIST_SET_DATA";

export const GET_BUSINESS_BELONG_LIST = 'Reference/GET_BUSINESS_BELONG_LIST';
export const GET_BUSINESS_BELONG_LIST_SET_DATA = 'Reference/GET_BUSINESS_BELONG_LIST_SET_DATA';

export const GET_PRIMARY_USE_CASE_LIST = 'Reference/GET_PRIMARY_USE_CASE_LIST';
export const GET_PRIMARY_USE_CASE_LIST_SET_DATA = 'Reference/GET_PRIMARY_USE_CASE_LIST_SET_DATA';

export const GET_PRIMARY_SOURCE_OF_FUNDING_LIST = 'Reference/GET_PRIMARY_SOURCE_OF_FUNDING_LIST';
export const GET_PRIMARY_SOURCE_OF_FUNDING_LIST_SET_DATA = 'Reference/GET_PRIMARY_SOURCE_OF_FUNDING_LIST_SET_DATA';
export const GET_DOCUMENT_TYPE_LIST = 'Reference/GET_DOCUMENT_TYPE_LIST';
export const GET_DOCUMENT_TYPE_LIST_SET_DATA = 'Reference/GET_DOCUMENT_TYPE_LIST_SET_DATA';

export const GET_ACCESS_ROLE_LIST = 'Reference/GET_ACCESS_ROLE_LIST';
export const GET_ACCESS_ROLE_LIST_SET_DATA = 'Reference/GET_ACCESS_ROLE_LIST_SET_DATA';
