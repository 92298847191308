import produce from 'immer';
import {
  RESET_USER,
  GET_ENTITY_LIST, GET_ENTITY_LIST_SET_DATA,
  SET_SELECTED_ENTITY, SET_SELECTED_ENTITY_SET_DATA,
  UPDATE_USER_LOCAL_SETTINGS, UPDATE_USER_LOCAL_SETTINGS_SET_DATA,
  VALIDATE_LINK, VALIDATE_LINK_SET_DATA,
} from './userConstants';

export const initialState = {
  validateLinkState: { data: {}, loading: false, error: '' },
  updateUserLocalSettingsState: { data: {}, loading: false, error: '' },
  setSelectedEntityState: { data: {}, loading: false, error: '' },
  getEntitiesState: { data: [], loading: false, error: '', isLoaded: false, },
};

/* eslint-disable default-case, no-param-reassign */
const userReducer = (state = initialState, action) =>
produce(state, draft => {
  switch (action.type) {
      case VALIDATE_LINK:
        draft.validateLinkState = {
          ...draft.validateLinkState,
          loading: true, message: '', error: '',
        };
        break;
      case VALIDATE_LINK_SET_DATA:
        draft.validateLinkState = {
          ...draft.validateLinkState,
          ...action.data, loading: false,
        };
        break;
      case UPDATE_USER_LOCAL_SETTINGS:
        draft.updateUserLocalSettingsState = {
          ...draft.updateUserLocalSettingsState,
          loading: true, message: '', error: '',
        };
        break;
      case UPDATE_USER_LOCAL_SETTINGS_SET_DATA:
        draft.updateUserLocalSettingsState = {
          ...draft.updateUserLocalSettingsState,
          ...action.data, loading: false,
        };
        break;
      case SET_SELECTED_ENTITY:
        draft.setSelectedEntityState = {
          ...draft.setSelectedEntityState,
          ...action.data, loading: false,
        };
        break;
      case SET_SELECTED_ENTITY_SET_DATA:
        draft.setSelectedEntityState = {
          ...draft.setSelectedEntityState,
          ...action.data, loading: false,
        };
        break;
    case GET_ENTITY_LIST:
      draft.getEntitiesState = {
        ...draft.getEntitiesState,
        loading: true, message: '', error: '', data: [],
      };
      break;
    case GET_ENTITY_LIST_SET_DATA:
      draft.getEntitiesState = {
        ...draft.getEntitiesState,
        ...action.data, loading: false, isDataUpdated: true, isLoaded: true,
      };
      break;
    case RESET_USER:
      draft = initialState;
      break;
  }
});

export default userReducer;
