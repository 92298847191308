import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalRegisterState = state => state.register || {};
export const registerState = () =>
  createSelector(GlobalState, substate => substate.register);

export const addCustomerIntroductionState = () =>
  createSelector(globalRegisterState, substate => substate.addCustomerIntroductionState);

export const getCustomerDetailsState = () =>
  createSelector(globalRegisterState, substate => substate.getCustomerDetailsState);

export const addCustomerBusinessBriefState = () =>
  createSelector(globalRegisterState, substate => substate.addCustomerBusinessBriefState);

export const saveBusinessBriefState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessBriefState);

export const saveBusinessStructureState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessStructureState);

export const saveTermConditionState = () =>
  createSelector(globalRegisterState, substate => substate.saveTermConditionState);

export const saveBusinessCategoriesState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessCategoriesState);

export const saveBusinessIndustriesState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessIndustriesState);

export const saveBusinessUseCasesState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessUseCasesState);

export const saveBusinessCountriesState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessCountriesState);

export const addCustomerContactDetailsState = () =>
  createSelector(globalRegisterState, substate => substate.addCustomerContactDetailsState);

export const saveBusinessSourceOfFundingState = () =>
  createSelector(globalRegisterState, substate => substate.saveBusinessSourceOfFundingState);

export const verifyPersonalDetailState = () =>
  createSelector(globalRegisterState, substate => substate.verifyPersonalDetailState);

export const addBeneficialOwnerState = () =>
  createSelector(globalRegisterState, substate => substate.addBeneficialOwnerState);

export const signupState = () =>
  createSelector(globalRegisterState, substate => substate.signupState);
export const getTermAndConditionState = () =>
  createSelector(globalRegisterState, substate => substate.getTermAndConditionState);

export const saveConfidentialityAgreementState = () =>
  createSelector(globalRegisterState, substate => substate.saveConfidentialityAgreementState);

export const deleteBeneficialOwnerState = () =>
  createSelector(globalRegisterState, substate => substate.deleteBeneficialOwnerState);

export const acceptConfidentialAgreementState = () =>
  createSelector(globalRegisterState, substate => substate.acceptConfidentialAgreementState);

export const resendInvitationLinkState = () =>
  createSelector(globalRegisterState, substate => substate.resendInvitationLinkState);

export const inviteAsTeamMemberState = () =>
  createSelector(globalRegisterState, substate => substate.inviteAsTeamMemberState);

export const saveControllingPartyState = () =>
  createSelector(globalRegisterState, substate => substate.saveControllingPartyState);

export const acceptAccountAgreementState = () =>
  createSelector(globalRegisterState, substate => substate.acceptAccountAgreementState);

export const tempRegistrationDataState = () =>
  createSelector(globalRegisterState, substate => substate.tempRegistrationDataState);

export const submitRegistrationExtraInfoState = () =>
  createSelector(globalRegisterState, substate => substate.submitRegistrationExtraInfoState);
export const addCustomerBusinessDetailState = () =>
  createSelector(globalRegisterState, substate => substate.addCustomerBusinessDetailState);

export const addBusinessGlobalPositionState = () =>
  createSelector(globalRegisterState, substate => substate.addBusinessGlobalPositionState);

export const reGeneratePdfState = () =>
  createSelector(globalRegisterState, substate => substate.reGeneratePdfState);

export const documentUploadState = () =>
  createSelector(globalRegisterState, substate => substate.documentUploadState);

export const saveControllingPartyWithBeneficialOwnerState = () =>
  createSelector(globalRegisterState, substate => substate.saveControllingPartyWithBeneficialOwnerState);

export const markIdVerificationStepState = () =>
  createSelector(globalRegisterState, substate => substate.markIdVerificationStepState);
