import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Slide, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Typography } from './ui';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: "65vh",
    padding: 0,
    minWidth: "408px",
  },
  xsPaper: {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ExternalDialog(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(props.open ? true : false);
  const theme = useTheme();
  useEffect(() => {
    setOpen(props.open ? true : false);
  }, [props.open]);

  // const isXsScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    props.onClose();
  };

  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (<>
    {props.triggerComponent ? props.triggerComponent(onOpen) : null}
    {open && <Dialog
      TransitionComponent={Transition}
      open={open}
      fullScreen={xsScreen}
      onClose={onClose}
      disableEnforceFocus
      classes={{
        paper: xsScreen ? classes.xsPaper : classes.paper
      }}
    >
      <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{props.title}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
        {props.childComponent ? props.childComponent(onClose) : null}
    </Dialog>}
  </>);
}
// Specifies the default values for props:
ExternalDialog.defaultProps = {
  onClose: () => null,
};

ExternalDialog.propTypes = {
  open: PropTypes.bool,
};

export default memo(ExternalDialog);
