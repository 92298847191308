import React, { memo, useState } from 'react';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from 'components/ui';
import { OtpInput, ResendOtp } from 'components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getRequestDetailState } from 'store/ContactRequestBank/contactRequestBankSelectors';
import { resendOtpAction } from 'store/Otp/otpActions';
import { resendOtpState, sendOtpState } from 'store/Otp/otpSelectors';
import { getRequestBankHeaders } from 'utils/common';
import { validateLinkState } from 'store/User/userSelectors';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  main: {
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0 ,2)
      },
    textAlign: "center",
    padding: theme.spacing(0 ,8),
  },
  resendDiv: {
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0 ,2)
      },
    textAlign: "center",
    padding: theme.spacing(0 ,8, 2),
  },
  btnDiv: {
    flex: 1,
    margin: theme.spacing(1, 1),
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(0.4, 1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    border: "1px solid #CACACA",
    borderRadius: "8px",
  },
  input: {
    width: '100%',
    borderRadius: 6,
    color: theme.palette.primary.main,
    minHeight: theme.spacing(6),
    height: "100%",
    "& .MuiInputBase-root": {
        height: "100%",
        display: "flex",
        alignItems: "start"
    },
    '& .MuiFilledInput-multiline': {
        padding: `${theme.spacing(2)}px !important`,
        // alignItems: 'center'
    },
    '& .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
        paddingTop: `${theme.spacing(0.5)}px !important`,
        paddingBottom: `${theme.spacing(0.5)}px !important`
    }
    // margin: theme.spacing(0.5),
    // padding: theme.spacing(0)
    },
    pencilIcon: {
        color: theme.palette.grey500,
        fontSize: theme.spacing(3),
        margin: theme.spacing(0, 1.5, 0, 0)
    },
    noteDiv: {
        margin: theme.spacing(1, 0, 3),
    },
    noteLabel: {
        padding: theme.spacing(1, 0)
    },
    divider: {
        margin: theme.spacing(2.5, 0)
    },
    otpDiv: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(2, 2)
    }
}));

function ContactBankDetailsConfirm ( props = {}) {
    const { data, onContinue, onBack, bankFields = [], linkRes,
        requestDetails, sendOtpRes, resendOtpRes, resendOtp
    } = props;
    const otpRefId = resendOtpRes?.data?.otp_ref_id || sendOtpRes?.data?.otp_ref_id;
    // const otpRef = otpRefId.substr(otpRefId.length - 4);
    const [code, setCode] = useState('');
    const requestDetailsData = requestDetails.data || {}
    const {
        address_line_1 = '',
        address_line_2 = '',
        city = '',
        state_name = '',
        postal_code = '',
        country_code = '',

    } = data.address || {}
    const addressString = `${address_line_1} ${address_line_2},  ${city}, ${state_name} ${postal_code} ${country_code}`
    const classes = useStyles();
    const { bank_details = {}} = data;

    // eslint-disable-next-line no-unused-vars

    const onConfirm = () => {
        onContinue({
            otp: code,
            otp_ref_id: otpRefId,
        });
    }

    const onCodeChange = (e) => {
        const { value } = e.target;
        setCode(value);
    }

    const onResendCode = (resetTimer) => {
        resendOtp({
            otp_ref_id: otpRefId,
            cb: resetTimer,
            headers: {...getRequestBankHeaders(linkRes)}
        })
    };

    return (<div className={classes.root}>
        <div className={classes.paper}>
            <div className={classes.main}>
                {requestDetailsData.company_name && <Typography className={classes.info} variant="subtitle1" color="grey600">{requestDetailsData.company_name}</Typography>}
                <Typography variant="body2" color="grey600">
                    {requestDetailsData.entity_person_first_name && <span>{requestDetailsData.entity_person_first_name} </span>}
                    {requestDetailsData.entity_person_middle_name && <span>{requestDetailsData.entity_person_middle_name} </span>}
                    {requestDetailsData.entity_person_last_name && <span>{requestDetailsData.entity_person_last_name}</span>}
                </Typography>
                <Typography variant="body2" color="grey600">
                    {requestDetailsData.entity_person_dialing_code && <span>{requestDetailsData.entity_person_dialing_code} </span>}
                    {requestDetailsData.entity_person_mobile_masked && <span>{requestDetailsData.entity_person_mobile_masked}</span>}
                </Typography>
                <Typography variant="body2" color="grey600">
                    {requestDetailsData.entity_person_email}
                </Typography>
                <br />
                <Typography variant="body2" color="grey600">
                    {addressString}
                </Typography>
            </div>
            <Divider className={classes.divider} />
            <Grid container spacing={1}>
                {bankFields.map((b, index) => (<>
                    <Grid item xs={5}>
                        <Typography variant="" color="grey400">{b.display_name}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant={index === 0 ? "subtitle2" : ""} color={index === 0 ? "grey700" : "grey600"}>{bank_details[b.bank_account_field]}</Typography>
                    </Grid>
                </>))}
            </Grid>
        </div>
        <Typography variant="body2">
            To confirm the above information, enter the security code that we have sent to <span>{requestDetailsData.entity_person_dialing_code && <span>{requestDetailsData.entity_person_dialing_code} </span>}
                    {requestDetailsData.entity_person_mobile_masked && <span>{requestDetailsData.entity_person_mobile_masked}</span>} with reference # {otpRefId}</span>.
        </Typography>
        <div className={classes.otpDiv}>
            <OtpInput
              // type="text"
              id="code"
              name="code"
              value={code}
              // label="Enter 6-digit Code"
              required
              // errorText=""
              onChange={onCodeChange}
            />
        </div>
        <div className={classes.resendDiv}>
            <Typography variant="caption">No Code yet? <ResendOtp onResend={onResendCode} label="Resend" />
            </Typography>
        </div>
        <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
        >
            <div className={classes.btnDiv} >
                <Button
                    variant="outlined"
                    title={'Back'}
                    onClick={onBack}
                />
            </div>
            <div className={classes.btnDiv}>
                <Button
                    color='secondary'
                    title={'Confirm'}
                    onClick={onConfirm}
                />
            </div>
        </Grid>
    </div>)
}

const mapStateToProps = createStructuredSelector({
    // bankConfig: getBankAccountConfigState(),
    linkRes: validateLinkState(),
    requestDetails: getRequestDetailState(),
    sendOtpRes: sendOtpState(),
    resendOtpRes: resendOtpState(),
    bankFields: (s, p) => {
        const {bank_country_code, currency_code, payment_type } = p?.data?.bank_details;
        const bankConfig = s.references.getBankAccountConfigState || { data: [], loading: false, error: '' };
        const fields = bankConfig.data.filter(i => (i.bank_country_code === bank_country_code && i.currency_code === currency_code && i.payment_type === payment_type));
        const sortedFields = fields.sort((a, b) => a.display_order > b.display_order ? 1 : -1);
        sortedFields.forEach((f, i) => {
          if (f.bank_account_field === "account_holder_name") {
            sortedFields.unshift(sortedFields.splice(i, 1)[0])
          }
        });
        sortedFields.splice(sortedFields.length - 2, 0, { bank_account_field: 'currency_code', display_name: 'Currency'});
        return sortedFields;
    }
});


function mapDispatchToProps(dispatch) {
    return {
        resendOtp: data => dispatch(resendOtpAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
);

export default compose(
    withConnect,
    memo,
)(React.forwardRef(ContactBankDetailsConfirm));
