
import { defineMessages } from 'react-intl';

export const scope = 'src.components.RegistrationStatusIntl';

export default defineMessages({
    statusCompleteTitle: {
        id: `${scope}.statusCompleteTitle`,
        defaultMessage: 'Well done',
        description: 'label of registration complete title',
    },
    statusCompleteSubTitle: {
        id: `${scope}.statusCompleteSubTitle`,
        defaultMessage: `Thanks for filling out the application. Next, we'll verify your ID and get you all set up!`,
        description: 'label of registration complete Subtitle',
    },
    statusCompleteSubTitleBreak: {
        id: `${scope}.statusCompleteSubTitleBreak`,
        defaultMessage: 'and processing your information now.',
        description: 'label of registration complete Subtitle',
    },
    btnTitle: {
        id: `${scope}.btnTitle`,
        defaultMessage: 'Go to Truly Financial!',
        description: 'label of button',
    },
    statusHoldTitle: {
        id: `${scope}.statusHoldTitle`,
        defaultMessage: 'We Do Not Service',
        description: 'label of registration complete title',
    },
    statusHoldTitleHalf: {
        id: `${scope}.statusHoldTitleHalf`,
        defaultMessage: 'at This Time...',
        description: 'label of registration complete title',
    },
    statusHoldSubTitle: {
        id: `${scope}.statusHoldSubTitle`,
        defaultMessage: `Big Dreams Are Made of Small Pieces.`,
        description: 'label of registration complete Subtitle',
    },
    statusHoldSubTitleBreak: {
        id: `${scope}.statusHoldSubTitleBreak`,
        defaultMessage: `We’re working hard to increasing our serviceable country list. Check back later for updates.`,
        description: 'label of registration complete Subtitle',
    },
    statusHoldSubTitleThree: {
        id: `${scope}.statusHoldSubTitleThree`,
        defaultMessage: ` We hope to service you soon!`,
        description: 'label of registration complete Subtitle',
    },
});
