/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';

import { AuthState } from '@aws-amplify/ui-components';
import { Grid, Link, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';
import TextField from 'components/ui/TextField';


import { Auth } from 'aws-amplify';
import Button from 'components/ui/Button';
import { PasswordInput } from 'components';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { isMfaDeviceExpired, removeDeviceKey } from 'utils/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteCookieByName } from 'utils/common';

const useStyles = makeStyles((theme) => ({
  headings: {
    textAlign: "left",
    paddingBottom: theme.spacing(3),
  },
  subHeading: {
    margin: theme.spacing(1, 0),
  },
  forgotPasswordTxt: {
    padding: theme.spacing(1, 0),
    display: "block",
    textDecoration: "underline",
  },
  signupLink: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  submitButton: {
    margin: theme.spacing(-2, 0, 2, 0),
  },
  textInput: {
    backgroundColor: theme.palette.background.paper
  },
}));

function SignIn(props = {}) {
  const { setUser, setAuthState, setLoading,
    setPassword,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [formData, setFormData] = useState({});
  const disabled = !formData.email || !formData.password;
  const location = useLocation();
  const history = useHistory();
  if (location?.search?.search('token') >= 0) {
    history.push(location?.pathname || '/home')
  }

  useEffect(() => {
    setTimeout(() => {
      deleteCookieByName('hubspotutk');
      deleteCookieByName('messagesUtk');
    }, 2000);
  }, [])

  useEffect(() => {
    setPassword(formData.password);
  }, [formData.password, setPassword]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    singIn(formData);
  };

  const onChange = (e, val) => {
    const { name, value } = e.target || {};

    setFormData(formData => ({ ...formData, [name]: value }));
  };


  async function singIn(data) {
    setLoading(true);
    try {
      let user = await Auth.signIn(data.email, data.password);
      if (!['SOFTWARE_TOKEN_MFA', 'SMS_MFA'].includes(user.challengeName) && isMfaDeviceExpired()) {
        await removeDeviceKey();
        user = await Auth.signIn(data.email, data.password);
      }
      setUser(user);
    } catch (error) {
      console.error('error: ', error);
      if (error.code === "UserNotConfirmedException") {
        resendConfirmationCode(data.email);
        setAuthState(AuthState.ConfirmSignUp);
        setUser({ username: data.email });
        return;
      } else if (error.code === "PasswordResetRequiredException") {
        setAuthState(AuthState.ForgotPassword);
        return;
      }
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function resendConfirmationCode(username) {
    try {
      await Auth.resendSignUp(username);
    } catch (err) {
      styledSnackbar.error(err.message);
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h6" : "h3"}>Welcome Back</Typography>
            {/* <Typography color="grey300" variant={isXsScreen ? "subtitle1" : "h6"} className={classes.subHeading}>Login to your account
            </Typography> */}
            {/* <Typography color="grey300" variant={isXsScreen ? "subtitle1" : "h6"} className={classes.subHeading}>Don't have an account?&nbsp;
              <Link
                className={classes.signupLink}
                href="/signup" target="_self"
              // onClick={() => setAuthState(AuthState.SignUp)}
              >Sign Up</Link>
            </Typography> */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            id="email"
            name="email"
            value={formData.email}
            label="Email Address"
            required
            errorText=""
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            id="password"
            name="password"
            value={formData.password}
            label="Password"
            required
            errorText=""
            onChange={onChange}
          />
          <Typography className={classes.forgotPasswordTxt} variant="caption" color="grey700">
            <Link
              href="#"
              onClick={() => setAuthState(AuthState.ForgotPassword)}>Forgot Password?</Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            type="submit"
            title="Log In"
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </form>
  );
}


export default SignIn;

