import {
  RESET_USER,
  GET_ENTITY_LIST, GET_ENTITY_LIST_SET_DATA,
  SET_SELECTED_ENTITY, SET_SELECTED_ENTITY_SET_DATA,
  UPDATE_USER_LOCAL_SETTINGS, UPDATE_USER_LOCAL_SETTINGS_SET_DATA,
  VALIDATE_LINK, VALIDATE_LINK_SET_DATA,
} from './userConstants';

import {
  getEntitiesParser,
} from './userParser';

export function resetUserAction() {
  return { type: RESET_USER };
}

export function getEntityListAction(data) {
  return {type: GET_ENTITY_LIST, data };
}
export function getEntityListSetDataAction(data) {
  return { type: GET_ENTITY_LIST_SET_DATA, data: getEntitiesParser(data) };
}
export function setSelectedEntityAction(data) {
  return { type: SET_SELECTED_ENTITY, data };
}
export function setSelectedEntitySetDataAction(data) {
  return { type: SET_SELECTED_ENTITY_SET_DATA, data };
}

export function updateUserLocalSettingsAction(data) {
  return { type: UPDATE_USER_LOCAL_SETTINGS, data };
}
export function updateUserLocalSettingsSetDataAction(data) {
  return { type: UPDATE_USER_LOCAL_SETTINGS_SET_DATA, data };
}

export function validateLinkAction(data) {
  return { type: VALIDATE_LINK, data };
}
export function validateLinkSetDataAction(data) {
  return { type: VALIDATE_LINK_SET_DATA, data };
}
