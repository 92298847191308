const { getHubspotVisitorToken } = require("store/User/userApi");

const visitorIdentification = async (open = false) => {
    const res = await getHubspotVisitorToken();
    const { token="", entity_person_email="" } = res.data || {};
    if (token && entity_person_email) {
        window.hsConversationsSettings = {
          identificationEmail: entity_person_email,
          identificationToken: token,
        };
        loadOrOpen(open);
    } else {
        if (window.HubSpotConversations) {
            loadOrOpen(open);
          } else {
            window.hsConversationsOnReady = [
              () => {
                loadOrOpen(open);
              },
            ];
          }
    }
};

export const loadOrOpen = (open) => {
    if (open) {
        window.HubSpotConversations?.widget?.load({ widgetOpen: true });
        setTimeout(() => {
            window.HubSpotConversations?.widget?.open();
        }, 2500);
        const status = window.HubSpotConversations?.widget?.status();
        setTimeout(() => {
            if(!status.loaded && status.pending) {
                loadOrOpen(open);
            }
        }, 3000);
    } else {
        window.HubSpotConversations?.widget?.load({ widgetOpen: false });
    }

}

export const openLiveChat = (open = true) => {
    if (window.hsConversationsSettings?.identificationToken) {
        const status = window.HubSpotConversations?.widget?.status();
        if (status.loaded && open) {
            window.HubSpotConversations?.widget?.open();
        } else {
            loadOrOpen(open);
        }
    } else {
        visitorIdentification(open);
    }
}

 
export const removeLiveChat = () => {
    window.HubSpotConversations?.widget?.remove();
}

export const loadLiveChat = () => {
    visitorIdentification();
}