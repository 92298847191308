import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

export function useRefHeight() {
    const heightRef = useRef(null);
    const [height, setHeight] = useState();
    useEffect(() => {
        setHeight(heightRef && heightRef.current ? heightRef.current.getBoundingClientRect().height : 0);
    }, []);
    return [height, heightRef];
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
}