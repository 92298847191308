import { requestWithOutErrorFeedback } from "utils/request";
import { ANALYTICS_BASE_URL } from "config/apiUrls";
const ANALYTICS_URL = `${ANALYTICS_BASE_URL}/analytics/track`;
export const analyticsTrackApi = ({ event, tracking_data  = {} } = {}) => {
  return requestWithOutErrorFeedback(ANALYTICS_URL, {
    method: "POST",
    body: {
      event,
      tracking_data ,
    },
  });
};
