
import React, { memo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import { Icon, Typography } from './ui';
import { FormattedMessage } from 'react-intl';
import intl from './intl/RegistrationHeaderIntl';
import CheckIcon from '@material-ui/icons/Check';
import { IconButton, useTheme, useMediaQuery, LinearProgress, Menu } from '@material-ui/core';
// import { styledSnackbar } from 'containers/Common/Snackbar';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import Auth from "@aws-amplify/auth";
import { restoreAndRemoveBackupEntityId } from 'utils/auth';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
// import Drawer from '@material-ui/core/Drawer';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import Truly_Logo_Green from 'assets/images/Truly_Logo_Green_svg.svg';
import RegistrationSideMenu from './RegistrationSideMenu';
import RegHelperMenu from './RegHelperMenu';

const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
// const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;
const BUSINESS_BRIEF = REGISTRATION_STEP.BUSINESS_BRIEF;
const BUSINESS_USE_CASES = REGISTRATION_STEP.BUSINESS_USE_CASES;
const BUSINESS_STRUCTURE = REGISTRATION_STEP.BUSINESS_STRUCTURE;
const ID_VERIFICATION = REGISTRATION_STEP.ID_VERIFICATION;
const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'fixed',
        width: '100%',
        zIndex: '100',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            flexDirection: 'column-reverse',
        }
    },
    content: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        height: theme.regHeaderHeight,
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'center',
        // position: 'fixed',
        width: "-webkit-fill-available",
        // padding: theme.spacing(0, 4, 0, 2),
        // zIndex: '100',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
            borderRadius: theme.spacing(0, 0, 2, 2),
            padding: theme.spacing(0, 2),
            // position: 'inherit'
        }
    },
    menuIcon: {
        color: theme.palette.primary.contrastText
    },
    statusDiv: {
        // flexDirection: 'row',
        // display: 'flex',
        // alignItems: 'center',
        height: theme.spacing(1),
        // backgroundColor: theme.palette.primary[100],
        // [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
        //     marginTop: theme.appHeaderHeight,
        // }
    },
    progress: {
        height: theme.spacing(1),
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            height: theme.spacing(0.5),
        },
        backgroundColor: theme.palette.grey[100]
    },
    barColorSecondary: {
        background: `linear-gradient(90.6deg, #F3D1B3 0.38%, #8CDFBA 99.37%)`,
    },
    roundDiv: ({
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: theme.spacing(2),
        border: `${theme.spacing(0.25)}px solid ${theme.palette.accent.main}`,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    }),
    rightIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    rightIconDrawer: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    disableRoundDiv: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: theme.spacing(2),
        border: `${theme.spacing(0.25)}px solid #DADADA`,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    iconDisable: {
        color: '#DADADA',
        fontSize: theme.spacing(2.5),
        alignSelf: 'center'
    },
    icon: {
        color: theme.palette.accent.main,
        fontSize: theme.spacing(2.5),
        alignSelf: 'center'
    },
    eachDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    eachDivComplete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    eachDivMargin: {
        display: 'flex',
        margin: theme.spacing(0, 0, 0, 12),
        alignItems: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 0, 0, 0),
        }
    },
    eachDivMarginOptions: {
        display: 'flex',
        margin: theme.spacing(0, 0, 2, 3),
        alignItems: 'center',
    },
    eachDivMarginComplete: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 0, 12),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 0, 0, 0),
        }
    },
    eachDivMarginCompleteDrawerOp: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 2, 3),

    },
    stepTxt: {
        color: theme.palette.primary.contrastText
    },
    completeStepColor: {
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(1)
    },
    completeStepColorDrawer: {
        color: theme.palette.secondary.main,
    },
    txtDiv: {
        margin: theme.spacing(0, 0, 0, 2),
        display: 'flex',
        flexDirection: 'column'
    },

    lastThingDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    closeDiv: {
        textAlign: 'right',
    },
    closeButton: {
        margin: theme.spacing(2),
    },
    drawerPaper: {
        width: theme.drawerWidth,
        marginTop: `calc( ${theme.appHeaderHeight} + ${theme.spacing(1)}px)`,
        backgroundColor: theme.palette.primary.main,
        // height: `calc(100% - 80px)`,
        // height: `calc(100% - ${theme.appHeaderHeight})`,
        //height: `calc(100vh - ${theme.appHeaderHeight} - ${theme.spacing(1)}px)`,
        border: 0,
    },
    options: {
        display: 'flex',
        flex: 1,
        padding: theme.spacing(10, 0, 0, 0)
    },
    list: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            position: 'absolute',
            right: theme.spacing(3.7)
        }
    },
    menuButton: {
        padding: '0px !important',
        margin: theme.spacing(0, 0.5)
    },
    img: {
        alignSelf: 'center',
        height: theme.spacing(6),
        width: theme.spacing(14.875)
    }
}));

const mainStepProgress = { CUSTOMER_INTRODUCTION: 100, CONFIDENTIAL_AGREEMENT: 50, BUSINESS_BRIEF: 25, BUSINESS_CATEGORIES: 50, BUSINESS_ADDRESS: 75, BUSINESS_INDUSTRIES: 100, BUSINESS_USE_CASES: 33, BUSINESS_COUNTRIES: 66, BUSINESS_SOURCE_OF_FUNDS: 100, BUSINESS_STRUCTURE: 25, PERSONAL_DETAILS: 50, BENEFICIAL_OWNERS: 75, CONTROLLING_PARTY: 100, TERMS_AND_CONDITIONS: 100 };

function RegistrationHeader(props = {}) {
    // eslint-disable-next-line no-unused-vars
    const { progress = 0, currentStep, changeStep, data } = props;
    const { mainStep = 0, prs_main_confidential_agreement_isStepComplete = false, prs_main_business_brief_isStepComplete = false, prs_main_business_categories_isStepComplete = false, prs_main_business_address_isStepComplete = false,
        // prs_main_business_industries_isStepComplete = false,
        prs_main_terms_and_conditions_isStepComplete = false
    } = data;
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState('');

    const history = useHistory();
    const classes = useStyles({ props });
    const theme = useTheme();
    const mdUpMatch = useMediaQuery(theme.breakpoints.up('md'));
    // const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        getLoginUser();
    }, []);

    const getLoginUser = () => {

        Auth.currentAuthenticatedUser().then((res) => {
            setIsUserLoggedIn(true);
        }).catch((e) => {
            setIsUserLoggedIn(false);
        });

    };

    const onStepPress = newStep => {

        // if (newStep !== CUSTOMER_INTRODUCTION && !data.entity_id) {
        //     changeStep && changeStep(CONFIDENTIAL_AGREEMENT);
        //     styledSnackbar.error('Introduction step is required');
        //     return;
        // }
        handleClose();
        changeStep && changeStep(newStep);
    };
    const onClose = () => {
        restoreAndRemoveBackupEntityId();
        setTimeout(() => {
            history.push('/');
        }, 500);
    };
    // const toggleDrawer = () => {
    //     setOpen(!open);
    // };
    // const closeDrawer = () => {
    //     setOpen(false);
    // };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    // const container = window !== undefined ? () => window.document.body : undefined;

    // const drawerOption = () => {
    //     return (
    //         <div className={classes.options}>
    //             <List className={classes.list}>

    //                 <ListItem disableGutters className={classes.ListItemDiv} button href="">
    //                     <div className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (!prs_main_business_brief_isStepComplete || mainStep === BUSINESS_BRIEF) ? classes.eachDivMarginOptions : classes.eachDivMarginCompleteDrawerOp : classes.eachDivMarginOptions} onClick={() => onStepPress(
    //                         data && data.completed_steps && data.completed_steps.length > 0 && data.completed_steps.includes(CONFIDENTIAL_AGREEMENT) ? CUSTOMER_INTRODUCTION : CONFIDENTIAL_AGREEMENT)}>
    //                         <IconButton disableRipple className={(mainStep === CONFIDENTIAL_AGREEMENT || prs_main_confidential_agreement_isStepComplete) ? (mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete) ? classes.rightIconDrawer : classes.roundDiv : classes.disableRoundDiv} >
    //                             {mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <SentimentSatisfiedIcon className={mainStep === CONFIDENTIAL_AGREEMENT ? classes.icon : classes.iconDisable} />}
    //                         </IconButton>
    //                         {
    //                             <div className={classes.txtDiv}>
    //                                 {/* {(mainStep === CUSTOMER_INTRODUCTION || !prs_main_confidential_agreement_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
    //                                 <Typography variant={"subtitle1"} className={mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete ? classes.completeStepColorDrawer : classes.stepTxt} ><FormattedMessage {...intl.introduction} /> </Typography>
    //                             </div>
    //                         }
    //                     </div>
    //                 </ListItem>
    //                 <ListItem disableGutters className={classes.ListItemDiv} button href="">
    //                     <div className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (!prs_main_business_brief_isStepComplete || mainStep === BUSINESS_BRIEF) ? classes.eachDivMarginOptions : classes.eachDivMarginCompleteDrawerOp : classes.eachDivMarginOptions} onClick={() => onStepPress(BUSINESS_BRIEF)}>
    //                         <IconButton disableRipple className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete) ? classes.rightIconDrawer : classes.roundDiv : classes.disableRoundDiv} >
    //                             {mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <FolderOutlinedIcon className={mainStep === BUSINESS_BRIEF ? classes.icon : classes.iconDisable} />}

    //                         </IconButton>
    //                         {
    //                             <div className={classes.txtDiv}>
    //                                 {/* {(mainStep === BUSINESS_BRIEF || !prs_main_business_brief_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
    //                                 <Typography variant={"subtitle1"} className={mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete ? classes.completeStepColorDrawer : classes.stepTxt} ><FormattedMessage {...intl.businessBrief} /> </Typography>
    //                             </div>
    //                         }
    //                     </div>
    //                 </ListItem>
    //                 <ListItem disableGutters className={classes.ListItemDiv} button href="">
    //                     <div className={(mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) ? (!prs_main_business_categories_isStepComplete || mainStep === BUSINESS_USE_CASES) ? classes.eachDivMarginOptions : classes.eachDivMarginCompleteDrawerOp : classes.eachDivMarginOptions} onClick={() => onStepPress(BUSINESS_USE_CASES)}>
    //                         <IconButton disableRipple className={(mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) ? (mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete) ? classes.rightIconDrawer : classes.roundDiv : classes.disableRoundDiv}>
    //                             {mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <CreateOutlinedIcon className={mainStep === BUSINESS_USE_CASES ? classes.icon : classes.iconDisable} />}
    //                         </IconButton>
    //                         {
    //                             <div className={classes.txtDiv}>
    //                                 {/* {(mainStep === BUSINESS_USE_CASES || !prs_main_business_categories_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
    //                                 <Typography variant={"subtitle1"} className={mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete ? classes.completeStepColorDrawer : classes.stepTxt}><FormattedMessage {...intl.tailorProfile} /> </Typography>
    //                             </div>
    //                         }
    //                     </div>
    //                 </ListItem>
    //                 <ListItem disableGutters className={classes.ListItemDiv} button href="">
    //                     <div className={(mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) ? (!prs_main_business_address_isStepComplete || mainStep === BUSINESS_STRUCTURE) ? classes.eachDivMarginOptions : classes.eachDivMarginCompleteDrawerOp : classes.eachDivMarginOptions} onClick={() => onStepPress(BUSINESS_STRUCTURE)}>
    //                         <IconButton disableRipple className={(mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) ? (mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete) ? classes.rightIconDrawer : classes.roundDiv : classes.disableRoundDiv}>
    //                             {mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <BusinessOutlinedIcon className={mainStep === BUSINESS_STRUCTURE ? classes.icon : classes.iconDisable} />}
    //                         </IconButton>
    //                         {
    //                             <div className={classes.txtDiv}>
    //                                 {/* {(mainStep === BUSINESS_STRUCTURE || !prs_main_business_address_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
    //                                 <Typography variant={"subtitle1"} className={mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete ? classes.completeStepColorDrawer : classes.stepTxt}><FormattedMessage {...intl.businessStructure} /> </Typography>
    //                             </div>
    //                         }
    //                     </div>
    //                 </ListItem>
    //                 <ListItem disableGutters className={classes.ListItemDiv} button href="">
    //                     <div className={(mainStep === TERMS_AND_CONDITIONS || prs_main_terms_and_conditions_isStepComplete) ? (!prs_main_terms_and_conditions_isStepComplete || mainStep === TERMS_AND_CONDITIONS) ? classes.eachDivMarginOptions : classes.eachDivMarginCompleteDrawerOp : classes.eachDivMarginOptions} onClick={() => onStepPress(TERMS_AND_CONDITIONS)}>
    //                         <IconButton disableRipple className={(mainStep === TERMS_AND_CONDITIONS || prs_main_terms_and_conditions_isStepComplete) ? (mainStep !== TERMS_AND_CONDITIONS && prs_main_terms_and_conditions_isStepComplete) ? classes.rightIconDrawer : classes.roundDiv : classes.disableRoundDiv}>
    //                             {mainStep !== TERMS_AND_CONDITIONS && prs_main_terms_and_conditions_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <AssignmentTurnedInOutlinedIcon className={mainStep === TERMS_AND_CONDITIONS ? classes.icon : classes.iconDisable} />}
    //                         </IconButton>
    //                         {
    //                             <div className={prs_main_terms_and_conditions_isStepComplete ? mainStep === TERMS_AND_CONDITIONS ? classes.txtDiv : undefined : classes.txtDiv}>
    //                                 {/* {(mainStep === BUSINESS_STRUCTURE || !prs_main_business_address_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
    //                                 <Typography variant={"subtitle1"} className={mainStep !== TERMS_AND_CONDITIONS && prs_main_terms_and_conditions_isStepComplete ? classes.completeStepColorDrawer : classes.stepTxt}><FormattedMessage {...intl.termAndCondition} /> </Typography>
    //                             </div>
    //                         }
    //                     </div>
    //                 </ListItem>
    //             </List >
    //         </div >
    //     )

    // };
    const regSteps = () => {
        return (
            <>
                {
                    (mdUpMatch || mainStep === CONFIDENTIAL_AGREEMENT) &&
                    <div className={(mainStep === CONFIDENTIAL_AGREEMENT || prs_main_confidential_agreement_isStepComplete) ? (!prs_main_confidential_agreement_isStepComplete || mainStep === CONFIDENTIAL_AGREEMENT) ? classes.eachDiv : classes.eachDivComplete : classes.eachDiv} onClick={() => onStepPress(
                        data && data.completed_steps && data.completed_steps.length > 0 && data.completed_steps.includes(CONFIDENTIAL_AGREEMENT) ? CUSTOMER_INTRODUCTION : CONFIDENTIAL_AGREEMENT)}>
                        <IconButton disableRipple className={(mainStep === CONFIDENTIAL_AGREEMENT || prs_main_confidential_agreement_isStepComplete) ? (mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete) ? classes.rightIcon : classes.roundDiv : classes.disableRoundDiv} >
                            {mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <SentimentSatisfiedIcon className={mainStep === CONFIDENTIAL_AGREEMENT ? classes.icon : classes.iconDisable} />}
                        </IconButton>
                        {
                            (mainStep === CONFIDENTIAL_AGREEMENT || prs_main_confidential_agreement_isStepComplete) && <div className={prs_main_confidential_agreement_isStepComplete ? mainStep === CONFIDENTIAL_AGREEMENT ? classes.txtDiv : undefined : classes.txtDiv}>
                                {/* {(mainStep === CUSTOMER_INTRODUCTION || !prs_main_confidential_agreement_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                <Typography variant={prs_main_confidential_agreement_isStepComplete && mainStep !== CONFIDENTIAL_AGREEMENT ? "caption" : "subtitle1"} className={mainStep !== CONFIDENTIAL_AGREEMENT && prs_main_confidential_agreement_isStepComplete ? classes.completeStepColor : classes.stepTxt} ><FormattedMessage {...intl.introduction} /> </Typography>
                            </div>
                        }
                    </div>
                }
                {
                    (mdUpMatch || mainStep === BUSINESS_BRIEF) &&
                    <div className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (!prs_main_business_brief_isStepComplete || mainStep === BUSINESS_BRIEF) ? classes.eachDivMargin : classes.eachDivMarginComplete : classes.eachDivMargin} onClick={() => onStepPress(BUSINESS_BRIEF)}>
                        <IconButton disableRipple className={(mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) ? (mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete) ? classes.rightIcon : classes.roundDiv : classes.disableRoundDiv} >
                            {mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <FolderOutlinedIcon className={mainStep === BUSINESS_BRIEF ? classes.icon : classes.iconDisable} />}

                        </IconButton>
                        {
                            (mainStep === BUSINESS_BRIEF || prs_main_business_brief_isStepComplete) && <div className={prs_main_business_brief_isStepComplete ? mainStep === BUSINESS_BRIEF ? classes.txtDiv : undefined : classes.txtDiv}>
                                {/* {(mainStep === BUSINESS_BRIEF || !prs_main_business_brief_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                <Typography variant={prs_main_business_brief_isStepComplete && mainStep !== BUSINESS_BRIEF ? "caption" : "subtitle1"} className={mainStep !== BUSINESS_BRIEF && prs_main_business_brief_isStepComplete ? classes.completeStepColor : classes.stepTxt} ><FormattedMessage {...intl.businessBrief} /> </Typography>
                            </div>
                        }
                    </div>
                }
                {
                    (mdUpMatch || mainStep === BUSINESS_USE_CASES) &&
                    <div className={(mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) ? (!prs_main_business_categories_isStepComplete || mainStep === BUSINESS_USE_CASES) ? classes.eachDivMargin : classes.eachDivMarginComplete : classes.eachDivMargin} onClick={() => onStepPress(BUSINESS_USE_CASES)}>
                        <IconButton disableRipple className={(mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) ? (mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete) ? classes.rightIcon : classes.roundDiv : classes.disableRoundDiv}>
                            {mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <CreateOutlinedIcon className={mainStep === BUSINESS_USE_CASES ? classes.icon : classes.iconDisable} />}
                        </IconButton>
                        {
                            (mainStep === BUSINESS_USE_CASES || prs_main_business_categories_isStepComplete) && <div className={prs_main_business_categories_isStepComplete ? mainStep === BUSINESS_USE_CASES ? classes.txtDiv : undefined : classes.txtDiv}>
                                {/* {(mainStep === BUSINESS_USE_CASES || !prs_main_business_categories_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                <Typography variant={prs_main_business_categories_isStepComplete && mainStep !== BUSINESS_USE_CASES ? "caption" : "subtitle1"} className={mainStep !== BUSINESS_USE_CASES && prs_main_business_categories_isStepComplete ? classes.completeStepColor : classes.stepTxt}><FormattedMessage {...intl.tailorProfile} /> </Typography>
                            </div>
                        }
                    </div>
                }
                {
                    (mdUpMatch || mainStep === BUSINESS_STRUCTURE) &&
                    <div className={(mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) ? (!prs_main_business_address_isStepComplete || mainStep === BUSINESS_STRUCTURE) ? classes.eachDivMargin : classes.eachDivMarginComplete : classes.eachDivMargin} onClick={() => onStepPress(BUSINESS_STRUCTURE)}>
                        <IconButton disableRipple className={(mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) ? (mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete) ? classes.rightIcon : classes.roundDiv : classes.disableRoundDiv}>
                            {mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <BusinessOutlinedIcon className={mainStep === BUSINESS_STRUCTURE ? classes.icon : classes.iconDisable} />}
                        </IconButton>
                        {
                            (mainStep === BUSINESS_STRUCTURE || prs_main_business_address_isStepComplete) && <div className={prs_main_business_address_isStepComplete ? mainStep === BUSINESS_STRUCTURE ? classes.txtDiv : undefined : classes.txtDiv}>
                                {/* {(mainStep === BUSINESS_STRUCTURE || !prs_main_business_address_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                <Typography variant={prs_main_business_address_isStepComplete && mainStep !== BUSINESS_STRUCTURE ? "caption" : "subtitle1"} className={mainStep !== BUSINESS_STRUCTURE && prs_main_business_address_isStepComplete ? classes.completeStepColor : classes.stepTxt}><FormattedMessage {...intl.businessStructure} /> </Typography>
                            </div>
                        }
                    </div>
                }
                {
                    (mdUpMatch || mainStep === ID_VERIFICATION) &&
                    <div className={(mainStep === ID_VERIFICATION || prs_main_terms_and_conditions_isStepComplete) ? (!prs_main_terms_and_conditions_isStepComplete || mainStep === ID_VERIFICATION) ? classes.eachDivMargin : classes.eachDivMarginComplete : classes.eachDivMargin} onClick={() => onStepPress(ID_VERIFICATION)}>
                        <IconButton disableRipple className={(mainStep === ID_VERIFICATION || prs_main_terms_and_conditions_isStepComplete) ? (mainStep !== ID_VERIFICATION && prs_main_terms_and_conditions_isStepComplete) ? classes.rightIcon : classes.roundDiv : classes.disableRoundDiv}>
                            {mainStep !== ID_VERIFICATION && prs_main_terms_and_conditions_isStepComplete ? <CheckIcon fontSize="small" color="primary" /> : <AssignmentTurnedInOutlinedIcon className={mainStep === ID_VERIFICATION ? classes.icon : classes.iconDisable} />}
                        </IconButton>
                        {
                            (mainStep === ID_VERIFICATION || prs_main_terms_and_conditions_isStepComplete) && <div className={prs_main_terms_and_conditions_isStepComplete ? mainStep === ID_VERIFICATION ? classes.txtDiv : undefined : classes.txtDiv}>
                                {/* {(mainStep === BUSINESS_STRUCTURE || !prs_main_business_address_isStepComplete) && <Typography variant="caption" className={classes.stepTxt} ><FormattedMessage {...intl.step} /> {mainStep} / 4 </Typography>} */}
                                <Typography variant={prs_main_terms_and_conditions_isStepComplete && mainStep !== ID_VERIFICATION ? "caption" : "subtitle1"} className={mainStep !== ID_VERIFICATION && prs_main_terms_and_conditions_isStepComplete ? classes.completeStepColor : classes.stepTxt}><FormattedMessage {...intl.termAndCondition} /> </Typography>
                            </div>
                        }
                    </div>
                }
            </>
        )
    };


    return (
        <div className={classes.root} >
            <div className={classes.content}>
                {
                    mdUpMatch ? <img src={Truly_Logo_Green} className={classes.img} alt="truly logo" /> : regSteps()
                }



                <div className={classes.row}>
                    <RegHelperMenu />
                    {
                        !mdUpMatch &&
                        <IconButton
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleClick}
                            className={classes.menuButton}
                        >
                            <MenuIcon className={classes.menuIcon} />
                        </IconButton>
                    }
                    {
                        !mdUpMatch && isUserLoggedIn && <IconButton className={classes.menuButton} onClick={onClose}>
                            <Icon className={classes.menuIcon} component={CloseIcon} fontSize="medium" />
                        </IconButton>

                    }
                </div>


            </div>
            {/* {
                !mdUpMatch && <Drawer
                    container={container}
                    variant="temporary"
                    anchor={'right'}
                    open={open}
                    onClose={closeDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >

                    {drawerOption()}
                </Drawer>
            } */}

            {
                !mdUpMatch && <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.rootMenu}
                    PaperProps={{
                        style: {
                            width: theme.spacing(30),
                        },
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <RegistrationSideMenu
                        data={data}
                        changeStep={(newStep) => { handleClose(); changeStep(newStep, "header") }}
                        currentStep={data.currentStep}
                    />
                </Menu>
            }

            <div className={classes.statusDiv}>
                <LinearProgress classes={{
                    root: classes.progress,
                    barColorSecondary: classes.barColorSecondary
                    // }} variant="determinate" color="secondary" value={progress} />
                }} variant="determinate" color="secondary" value={mainStepProgress[currentStep]} />
            </div>
            {isUserLoggedIn && mdUpMatch ? <div className={classes.closeDiv}>
                <IconButton onClick={onClose} className={classes.closeButton} color="primary"><Icon component={CloseIcon} fontSize="medium" /></IconButton>
            </div> : null}
        </div>
    );
}

RegistrationHeader.propTypes = {};

export default memo(RegistrationHeader);
