const CONTACT_PAYMENTS = {
    code: "PAYMENTS",
    name: "Card Payments",
    path: "/card-payments",
    functions: {
        CONTACT_PAYMENTS_LIST: {
            code: "CONTACT_PAYMENTS_LIST",
            name: "Contact Payment List",
            isPage: true,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_LIST.path}`,
            getFullPath: (contactId) => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_LIST.path}`.replace(
                ":contactId",
                contactId
            ),
        },
        CONTACT_PAYMENTS_SEARCH: {
            code: "CONTACT_PAYMENTS_SEARCH",
            name: "Contact Payment Search",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_SEARCH.path}`,
            getFullPath: (contactId) => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_SEARCH.path}`.replace(
                ":contactId",
                contactId
            ),
        },
        CONTACT_PAYMENTS_DETAILS: {
            code: "CONTACT_PAYMENTS_DETAILS",
            name: "Contact Payment Details",
            isPage: true,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_DETAILS.path}`,
            getFullPath: (contactId, paymentId) =>
                `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_DETAILS.path}`.replace(
                    ":contactId",
                    contactId
                ).replace(":paymentId", paymentId),
        },
        CONTACT_PAYMENTS_FILTER: {
            code: "CONTACT_PAYMENTS_FILTER",
            name: "Contact Payment Filter",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_FILTER.path}`,
            getFullPath: (contactId) => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_FILTER.path}`.replace(
                ":contactId",
                contactId
            ),
        },
        CONTACT_PAYMENTS_DUPLICATE: {
            code: "CONTACT_PAYMENTS_DUPLICATE",
            name: "Contact Payment Duplicate",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_DUPLICATE.path}`,
            getFullPath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_DUPLICATE.path}`,
        },
        CONTACT_PAYMENTS_MAKE_RECURRING: {
            code: "CONTACT_PAYMENTS_MAKE_RECURRING",
            name: "Contact Payment make recurring",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_MAKE_RECURRING.path}`,
            getFullPath: () => `${CONTACT_PAYMENTS.path}${CONTACT_PAYMENTS.functions.CONTACT_PAYMENTS_MAKE_RECURRING.path}`,
        },
    },


};

export default CONTACT_PAYMENTS;
