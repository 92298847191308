import React, { memo } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from 'components/ui';
import PropTypes from 'prop-types';
import { StyledLink } from 'components/withStyledComp';
import { FormattedMessage } from 'react-intl';
import intl from './intl/AddPaymentMenuIntl';

function AddPaymentMenu(props = {}) {
    const { onMenuItemPress } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action) => {
        setAnchorEl(null);
        onMenuItemPress(action);
    };

    return (
        <div>
            <StyledLink href="#" onClick={(e) => { e.preventDefault(); handleClick(e) }}>
                <FormattedMessage {...intl.add} />
            </StyledLink>
            {Boolean(anchorEl) && <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => { handleClose("close") }}
                disableEnforceFocus
            >
                <MenuItem onClick={() => { handleClose("add acc") }}><Typography variant="body2"> <FormattedMessage {...intl.add} /></Typography></MenuItem>
                {/* <MenuItem disabled onClick={() => { handleClose("add card") }}><Typography variant="body2"></Typography> <FormattedMessage {...intl.card} /></MenuItem>
                <MenuItem disabled onClick={() => { handleClose("request") }}><Typography variant="body2"> <FormattedMessage {...intl.reqFromContact} /></Typography></MenuItem> */}
            </Menu>}
        </div>
    );
}
AddPaymentMenu.defaultProps = {
    onMenuItemPress: () => { },
}
AddPaymentMenu.propTypes = {
    onMenuItemPress: PropTypes.func,
};

export default memo(AddPaymentMenu);