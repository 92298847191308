
import { defineMessages } from 'react-intl';

export const scope = 'src.components.HelperMenuIntl';

export default defineMessages({
    faq: {
        id: `${scope}.faq`,
        defaultMessage: 'FAQs',
    },
    liveChat: {
        id: `${scope}.liveChat`,
        defaultMessage: 'Live Chat',
    },
    reportBug: {
        id: `${scope}.reportBug`,
        defaultMessage: 'Report a bug',
    },

    time: {
        id: `${scope}.time`,
        defaultMessage: 'Mon-Fri, 8am-8pm ET',
    },

    number: {
        id: `${scope}.number`,
        defaultMessage: '+1 (564) 273-2637',
    },
    callUs: {
        id: `${scope}.callUs`,
        defaultMessage: 'Call Us',
    },
    contactForm: {
        id: `${scope}.contactForm`,
        defaultMessage: 'Message Us',
    },
    productTour: {
        id: `${scope}.productTour`,
        defaultMessage: 'Product Tours',
    },
    fund: {
        id: `${scope}.fund`,
        defaultMessage: 'Deposit Funds',
    },
    send: {
        id: `${scope}.send`,
        defaultMessage: 'Send Payments',
    },
    link: {
        id: `${scope}.link`,
        defaultMessage: 'Manage Expenses',
    },
    create: {
        id: `${scope}.create`,
        defaultMessage: 'Link Stripe, Shopify, etc',
    },

});
