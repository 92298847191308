const REACT_APP_ENV = (process.env && process.env.REACT_APP_ENV);
const REACT_APP_API_URL = (process.env && process.env.REACT_APP_API_URL) || '';
const isLocalEnv = REACT_APP_ENV === 'local';
const REACT_APP_REFERENCES_API_URL = (process.env && process.env.REACT_APP_REFERENCES_API_URL) || '';
const LOCAL_API_GATEWAY = isLocalEnv ? '/' : '';

export const API_BASE_URL = REACT_APP_API_URL; //eslint-disable-line

//API GATEWAYS
const REFERENCES_API_GATEWAY = '/references-ws';
const USER_API_GATEWAY = `${LOCAL_API_GATEWAY || '/users-ws/'}`;
const CONTACT_API_GATEWAY = `${LOCAL_API_GATEWAY || '/contacts-ws/'}`;
const SEND_PAYMENT_API_GATEWAY = `${LOCAL_API_GATEWAY || '/send-payments-ws/'}`;
const ACCOUNT_API_GATEWAY = `${LOCAL_API_GATEWAY || '/accounts-ws/'}`;
const TAG_API_GATEWAY = `${LOCAL_API_GATEWAY || '/tags-ws/'}`;
const SCHEDULER_API_GATEWAY = `${LOCAL_API_GATEWAY || '/scheduler-ws/'}`;
const SCHEDULED_PAYMENTS_API_GATEWAY = `${LOCAL_API_GATEWAY || '/scheduled-payments-ws/'}`;
const CARD_MANAGEMENT_API_GATEWAY = `${LOCAL_API_GATEWAY || '/card-management-ws/'}`;
const TEAM_MEMBER_API_GATEWAY = `${LOCAL_API_GATEWAY || '/team-members-ws/'}`;
const PAYMENT_API_GATEWAY = `${LOCAL_API_GATEWAY || '/payments-ws/'}`;
const SCHEDULED_API_GATEWAY = `${LOCAL_API_GATEWAY || '/scheduled-payments-ws/'}`;
const REGISTER_API_GATEWAY = `${LOCAL_API_GATEWAY || '/registrations-ws/'}`;
const ACCOUNT_TRANSFER_GATEWAY = `${LOCAL_API_GATEWAY || '/account-transfers-ws/'}`;
const BANK_TRANSFER_GATEWAY = `${LOCAL_API_GATEWAY || '/bank-accounts-ws/'}`;
const HUBSPOT_GATEWAY = `${LOCAL_API_GATEWAY || '/hubspot-ws/'}`;
const ANALYTICS_GATEWAY = `${LOCAL_API_GATEWAY || '/analytics-ws/'}`;
const VERIFICATION_GATEWAY = `${LOCAL_API_GATEWAY || '/verification-ws/'}`;
const SENDPAYMENT_GATEWAY = `${LOCAL_API_GATEWAY || '/sendpayment-ws/'}`;
const LINKS_GATEWAY = `${LOCAL_API_GATEWAY || '/links-ws/'}`;
const OTP_GATEWAY = `${LOCAL_API_GATEWAY || '/otp-ws/'}`;
const BANK_DETAIL_REQUEST_GATEWAY = `${LOCAL_API_GATEWAY || '/bank-detail-request-ws/'}`;

// URLS
export const REFERENCES_BASE_API_URL = isLocalEnv ? `${REACT_APP_REFERENCES_API_URL}${REFERENCES_API_GATEWAY}` : `${API_BASE_URL}${REFERENCES_API_GATEWAY}`;
export const USER_BASE_API_URL = `${API_BASE_URL}${USER_API_GATEWAY}v1`;
export const CONTACT_BASE_API_URL = `${API_BASE_URL}${CONTACT_API_GATEWAY}v1`;
export const CONTACT_BASE_API_URL_V2 = `${API_BASE_URL}${CONTACT_API_GATEWAY}v2`;
export const PAYMENTS_BASE_API_URL = `${API_BASE_URL}${SEND_PAYMENT_API_GATEWAY}v1`;
export const PAYMENT_BASE_API_URL = `${API_BASE_URL}${PAYMENT_API_GATEWAY}v1`;
export const ACCOUNT_BASE_API_URL = `${API_BASE_URL}${ACCOUNT_API_GATEWAY}v1`;
export const TAGS_BASE_API_URL = `${API_BASE_URL}${TAG_API_GATEWAY}v1`;
export const EVENT_SCHEDULER_BASE_API_URL = `${API_BASE_URL}${SCHEDULER_API_GATEWAY}v1`;
export const SCHEDULED_PAYMENT_BASE_API_URL = `${API_BASE_URL}${SCHEDULED_PAYMENTS_API_GATEWAY}v1`;
export const CARD_MANAGEMENT_BASE_API_URL = `${API_BASE_URL}${CARD_MANAGEMENT_API_GATEWAY}v1`;
export const TEAM_MEMBER_BASE_API_URL = `${API_BASE_URL}${TEAM_MEMBER_API_GATEWAY}v1`;
export const SCHEDULED_BASE_API_URL = `${API_BASE_URL}${SCHEDULED_API_GATEWAY}v1`;
export const REGISTER_BASE_API_URL = `${API_BASE_URL}${REGISTER_API_GATEWAY}v1`;
export const ACCOUNT_TRANSFER_BASE_URL = `${API_BASE_URL}${ACCOUNT_TRANSFER_GATEWAY}v1`;
export const BANK_TRANSFER_BASE_URL = `${API_BASE_URL}${BANK_TRANSFER_GATEWAY}v1`;
export const HUBSPOT_BASE_URL = `${API_BASE_URL}${HUBSPOT_GATEWAY}v1`;
export const ANALYTICS_BASE_URL = `${API_BASE_URL}${ANALYTICS_GATEWAY}v1`;
export const VERIFICATION_BASE_API_URL = `${API_BASE_URL}${VERIFICATION_GATEWAY}v1`;
export const SEND_PAYMENT_BASE_API_URL = `${API_BASE_URL}${SENDPAYMENT_GATEWAY}v1`;
export const LINKS_BASE_API_URL = `${API_BASE_URL}${LINKS_GATEWAY}v1`;
export const OTP_BASE_API_URL = `${API_BASE_URL}${OTP_GATEWAY}v1`;
export const CONTACT_REQUEST_BANK_BASE_API_URL = `${API_BASE_URL}${BANK_DETAIL_REQUEST_GATEWAY}v1`;