/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import {
    makeStyles, // FormControlLabel,
    Grid} from "@material-ui/core";
import PropTypes from 'prop-types';

import {
    TextField,
} from "components/ui";
import SearchLocation from 'containers/Common/SearchLocation';
import SelectState from 'containers/Common/SelectState';
import SelectCountry from 'containers/Common/SelectCountry';

const useStyles = makeStyles((theme) => ({
    inputDiv: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
}));

function AddressInput(props = {}) {
    const classes = useStyles();
    const { data = {}, addressKey, onChange = () => null } = props;
    const [inputRefs, setInputRefs] = useState([]);
    const [isLocationSelected, setLocationSelected] = useState(false);
    const [address, setAddress] = useState({
      address_id: data.address_id || "",
          address_line_1: data.address_line_1 || "",
          address_line_2: data.address_line_2 || "",
          landmark: data.landmark || "",
          city: data.city || "",
          state_code: data.state_code || "",
          state_name: data.state_name || "",
          postal_code: data.postal_code || "",
          country_code: data.country_code || "",
    });

    // useEffect(() => {
    //   if (JSON.stringify(data) !== JSON.stringify(address)) {
    //     setAddress({
    //       address_id: data.address_id || "",
    //       address_line_1: data.address_line_1 || "",
    //       address_line_2: data.address_line_2 || "",
    //       landmark: data.landmark || "",
    //       city: data.city || "",
    //       state_code: data.state_code || "",
    //       state_name: data.state_name || "",
    //       postal_code: data.postal_code || "",
    //       country_code: data.country_code || "",
    //     })
    //   }
    // }, [data]);

    useEffect(() => {
        if (data.country_code && data.country_code !== address.country_code) {
            setAddress((d) => ({ ...d, country_code: data.country_code}))
        }
    }, [data.country_code])

    useEffect(() => {
      onChange(address);
    }, [address]);

    const onRefFocus = useCallback(async (textFieldName, textFieldId) => {
        if (textFieldName === "clear") {
            setInputRefs([]);
        } else {
            let refArray = [...inputRefs];
            refArray.push(textFieldId || textFieldName);
            setInputRefs((ar) => [...new Set([...ar, (textFieldId || textFieldName)])]);
        }
    }, []);

    const clearRefFocus = useCallback(() => {
        onRefFocus("clear");
    }, []);

    const onBlurSearLocation = useCallback(() => {
        setLocationSelected(true);
        onRefFocus("clear");
    }, []);


    const onAddressFieldChange = useCallback((e) => {
        const { name, value } = e.target || {};
        setAddress(formData => ({ ...formData, [name]: value }));
    }, []);

    const onAddressCountryFieldChange = useCallback((e) => {
        const { name, value } = e.target || {};
        // setAddress(formData => ({ ...formData, [name]: value, state_code: '', state_name: '' }));
        setAddress(formData => ({ ...formData, [name]: value }));
    }, []);

    const onLocationChange = useCallback((e, val = {}, reason) => {
        if (reason === 'input') {
            setAddress(d => ({
                ...d,
                address_line_1: val.address_line_1,
            }));
            return;
        }
        if (Object.keys(val).includes("address")) {
            setAddress(d => ({
                ...d,
                address_line_1: val.address_line_1,
                // address_line_2: val.route,
                city: val.city,
                state_name: val.state,
                state_code: val.state_code,
                country_code: val.country_code,
                postal_code: val.postal_code,
            }));
            setTimeout(() => {
                setAddress(d => ({
                    ...d,
                    state_name: val.state,
                    state_code: val.state_code,
                }));
            }, 300);
        }
        setLocationSelected(true);
    }, []);

    const onLocationInputChange = useCallback(() => {
        // setFormData(formData => ({ ...formData, [name]: value }));
    }, []);

    const onAddressStateChange = useCallback((e, v) => {
        onAddressFieldChange(e);
        onAddressFieldChange({ target: { id: 'state_name', name: 'state_name', value: v?.state_name || '' } })
    }, [onAddressFieldChange]);

    return (<div>
                <div className={classes.inputDiv} >
                    <SelectCountry
                        id={`${addressKey}_country_code`}
                        name="country_code"
                        value={address.country_code}
                        label={'Country'}
                        required
                        // errorText={addUpdateContactResp.error.country_code || defaultErrorText}
                        onChange={onAddressCountryFieldChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === `${addressKey}_country_code`}
                        onBlur={clearRefFocus}
                    />
                </div>
                <div className={classes.inputDiv} >
                    <SearchLocation
                        compId={addressKey}
                        countryCode={address.country_code}
                        id={`${addressKey}_address_line_1`}
                        name="address_line_1"
                        value={address.address_line_1}
                        label={'Street Address'}
                        required
                        // errorText={addUpdateContactResp.error.address_line_1 || defaultErrorText}
                        // error={addUpdateContactResp.error.address_line_1}
                        onChange={onLocationChange}
                        onInputChange={onLocationInputChange}
                        onBlur={onBlurSearLocation}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === `${addressKey}_address_line_1`}
                    />
                </div>

                <div className={classes.inputDiv}>
                    <TextField type="text"
                        id={`${addressKey}_address_line_2" name="address_line_2`}
                        value={address.address_line_2}
                        label={'Unit/Suite/Floor (optional)'}
                        onChange={onAddressFieldChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === `${addressKey}_address_line_2`}
                        onBlur={clearRefFocus}
                    />
                </div>
                {/* {isLocationSelected && <div className={classes.inputDiv} >
                        <TextField
                            type="text"
                            id={`${addressKey}_landmark`}
                            name="landmark"
                            value={address.landmark}
                            label={'Landmark'}
                            // required
                            // errorText={addUpdateContactResp.error.landmark || defaultErrorText}
                            // error={addUpdateContactResp.error.landmark}
                            onChange={onAddressFieldChange}
                            onFocusRef={onRefFocus}
                            makeFocus={inputRefs[0] === `${addressKey}_landmark`}
                            onBlur={clearRefFocus}
                        />
                    </div>} */}
                {isLocationSelected && <div className={classes.inputDiv} >
                    <TextField
                        type="text"
                        id={`${addressKey}_city`}
                        name="city"
                        value={address.city}
                        label={'City'}
                        required
                        // errorText={addUpdateContactResp.error.city || defaultErrorText}
                        // error={addUpdateContactResp.error.city}
                        onChange={onAddressFieldChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === `${addressKey}_city`}
                        onBlur={clearRefFocus}
                    />
                </div>
                }
                {isLocationSelected && <div className={classes.inputDiv}>
                    <Grid container spacing={2}>
                        <Grid xs={7} item>
                            <SelectState
                                id={`${addressKey}_state_code`}
                                name="state_code"
                                value={address.state_code}
                                valueObj={{ state_code: address.state_code, state_name: address.state_name }}
                                label={'State/Province'}
                                required
                                // errorText={addUpdateContactResp.error.state_code || defaultErrorText}
                                onChange={onAddressStateChange}
                                // onChange={(e, v) => {
                                //     onMailingAddressFieldChange(e)
                                //     onMailingAddressFieldChange({ target: { id: 'state_name', name: 'state_name', value: ((v && v.state_name) || e.target.value) || '' } })
                                // }}
                                countryCode={address.country_code}
                                inputDivClassName={classes.inputDiv}
                                onFocusRef={onRefFocus}
                                makeFocus={inputRefs[0] === `${addressKey}_state_code`}
                                onBlur={clearRefFocus}
                            />
                        </Grid>
                        <Grid xs={5} item>
                            <TextField
                                type="text"
                                id={`${addressKey}_postal_code`}
                                name="postal_code"
                                value={address.postal_code}
                                label={'ZIP/Postal Code'}
                                required
                                // errorText={addUpdateContactResp.error.postal_code || (!formData.postal_code ? defaultErrorText : 'Invalid Zip/Postal Code')}
                                // error={addUpdateContactResp.error.postal_code}
                                onChange={onAddressFieldChange}
                                inputProps={{
                                    pattern: '^[a-zA-Z0-9 -]{4,7}$',
                                    minlength: 4,
                                    maxlength: 7,
                                }}
                                onFocusRef={onRefFocus}
                                makeFocus={inputRefs[0] === `${addressKey}_postal_code`}
                                onBlur={clearRefFocus}
                            />
                        </Grid>
                    </Grid>
                </div>}
        </div>)
}

AddressInput.defaultProps = {
  test: " AddressInput component ",
};

AddressInput.propTypes = {
  test: PropTypes.string,
};

export default memo(AddressInput);
