/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/core";
import React, { memo, useEffect } from "react";
// import Logo from 'assets/images/logo/Truly_Logo_Light.png';
// import { Typography } from "components/ui";
import { getPublicId, getPublicToken, removePublicId, removePublicToken } from "utils/auth";
import { useHistory } from "react-router-dom";
import { Loader } from "components";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { compose } from "redux";
import { idVerificationReceivedAction } from "store/TeamMember/teamMemberActions";
import { getSelfTeamMemberDetailsState } from 'store/TeamMember/teamMemberSelectors';
import { getSelfTeamMemberDetailsAction } from 'store/TeamMember/teamMemberActions';
import { ENTITY_PERSON_TYPE } from "@truly-financial/common/consts/entity-person.consts";
import { removeLiveChat } from "utils/hubspot";
import IdVerificationSubmitted from "./Common/IdVerificationSubmitted";

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    minHeight: '100vh',
    textAlign: 'center',

  },
  logo: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    textAlign: 'left',
    borderRadius: '0px 0px 10px 10px',
    minHeight: theme.spacing(10)
  },
  txt: {
    marginTop: theme.spacing(10)
  },
  infoDiv: {
    textAlign: 'center',
    padding: theme.spacing(10, 1),
  },
}));

function IdVerificationStatus(props = {}) {

  const history = useHistory();
  const location = useLocation();
  const { teamMember, getTeamMemberDetails, idVerificationReceivedAction } = props;
  const { id = getPublicId(),
    token = getPublicToken(),
  } = queryString.parse(location.search);
  const { isDialog } = queryString.parse(location.search);

  useEffect(() => {
    if (id && token) {
      idVerificationReceivedAction({
        teamMemberId: id,
        token,
        cb: onSuccessVerificationReceived
      });
    }
    if (isDialog) {
      removeLiveChat();
    }
    return () => {
      removePublicToken();
      removePublicId();
    };
  }, []);

  useEffect(() => {
    if (!teamMember.isLoading && teamMember.data) {
      if (teamMember.data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT) {
        // setTimeout(() => {
        //   history.push(`/primary-user-verification?id=${id}&token=${token}&iSub=${+new Date()}&idSub=${+new Date()}${isDialog ? `&isDialog=1` : ""}`);
        // }, 5000);
      }
      else if (teamMember.data.role_id && !teamMember.data.is_smartlink_activation_required) {
        setTimeout(() => {
          history.push(`/activate-access?id=${id}&token=${token}&iSub=${+new Date()}&idSub=${+new Date()}${isDialog ? `&isDialog=1` : ""}`);
        }, 5000);
      }
      else if (teamMember.data.role_id && teamMember.data.is_smartlink_activation_required && teamMember.data.id_verification_link) {
        setTimeout(() => {
          history.push(`/activate-access-smartlink?id=${id}&token=${token}&iSub=${+new Date()}&idSub=${+new Date()}${isDialog ? `&isDialog=1` : ""}`);
        }, 5000);
      }
      else if (teamMember.data.role_id && teamMember.data.is_smartlink_activation_required && !teamMember.data.id_verification_link) {
        setTimeout(() => {
          history.push(`/activate-smartlink?id=${id}&token=${token}&iSub=${+new Date()}&idSub=${+new Date()}${isDialog ? `&isDialog=1` : ""}`);
        }, 5000);
      }
      else if (teamMember.data.entity_person_type === ENTITY_PERSON_TYPE.STAKEHOLDER && teamMember.data.is_controlling_party) {
        setTimeout(() => {
          history.push(`/controlling-party-authorization?id=${id}&token=${token}&iSub=${+new Date()}&idSub=${+new Date()}${isDialog ? `&isDialog=1` : ""}`);
        }, 5000);
      }
      else if (teamMember.data.entity_person_type === ENTITY_PERSON_TYPE.STAKEHOLDER && !teamMember.data.is_controlling_party) {
        setTimeout(() => {
          history.push(`/stakeholder-verification?id=${id}&token=${token}&iSub=${+new Date()}&idSub=${+new Date()}${isDialog ? `&isDialog=1` : ""}`);
        }, 5000);
      }
    }
  }, [teamMember]);

  const onSuccessVerificationReceived = () => {
    getTeamMemberDetails({ teamMemberId: id, token });
  };

  const classes = useStyles();
  return (<div className={classes.root}>
    {/* {
      !isDialog && <div className={classes.logo}>
        <img alt="Truly" src={Logo} width="120" />
      </div>
    } */}
    {teamMember?.data?.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ?
      <div className={classes.infoDiv}>
        <IdVerificationSubmitted data={teamMember.data} showLoginBtn={false} />
      </div>
      :
      <>
        {/* <Typography className={classes.txt} color="grey600" variant="body2">We’re validating your information now</Typography> */}
        {id && token && <Loader />}
      </>
    }

  </div>)
}

const mapStateToProps = createStructuredSelector({
  // selfUpdateTeamMemberState: selfUpdateTeamMemberState(),
  teamMember: getSelfTeamMemberDetailsState(),
});

function mapDispatchToProps(dispatch) {
  return {
    idVerificationReceivedAction: data => dispatch(idVerificationReceivedAction(data)),
    getTeamMemberDetails: data => dispatch(getSelfTeamMemberDetailsAction(data)),
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(IdVerificationStatus);
