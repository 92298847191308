/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button } from 'components/ui';
import React, { useState, memo, useEffect } from 'react';
import { Grid } from '@material-ui/core/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FormattedMessage } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPrimaryUseCaseListAction } from 'store/Reference/referenceActions';
import { getPrimaryUseCaseListState } from 'store/Reference/referenceSelectors';
import IndustryListSkeleton from '../Skeleton/IndustryListSkeleton';
import RegisterSquare from 'components/RegisterSquare';
import { saveBusinessUseCasesAction, tempSaveRegistrationDataAction } from 'store/Register/registerActions';
import { getCustomerDetailsState, saveBusinessUseCasesState, tempRegistrationDataState } from 'store/Register/registerSelectors';
import { Loader } from 'components';
import GoBack from '../GoBack';
import { ANALYTICS } from '@truly-financial/common/consts';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';


const useStyles = makeStyles(theme => ({
    root: {
    },
    content: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    subContent: {
        display: 'grid',
    },
    middleContent: {
        margin: theme.spacing(4, 0),
        width: 'inherit'

    },
    margin: {
        margin: theme.spacing(0, 0),
        width: '100%'
    },

    align: {
        display: 'flex',
        alignItems: 'center'
    },
    marginAuto: {
        margin: 'auto'
    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),

    },
    title: {
        margin: theme.spacing(1)
    },
    btnDiv: {
        flex: 1
    },
    lastMainGrid: {
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
        }
    }
}));

function BusinessUseCases(props = {}) {
    const { onContinue = () => null, onBackPress = () => null, tempRegistrationDataState, tempSaveRegistrationData, getCustomerDetailsState, getPrimaryUseCaseList, primaryUseCaseList, saveBusinessUseCasesState, saveBusinessUseCasesAction } = props;
    const classes = useStyles();
    const [source, setSource] = useState([]);
    const theme = useTheme();
    const [error, setError] = useState(false);
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const mdUpMatches = useMediaQuery(theme.breakpoints.up('md'));
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const isLoading = ((saveBusinessUseCasesState && saveBusinessUseCasesState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading));

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
        if (!primaryUseCaseList.data.length) {
            getPrimaryUseCaseList();
        }
        analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_PROFILE_PAGE_LOAD.event })
    }, []);

    useEffect(() => {
        if (source.length > 0) {
            const tempArray = [];
            source.forEach((item) => {
                if (item) {
                    let localSave = {};
                    localSave.business_use_case_id = item;
                    tempArray.push(localSave);
                }
            });

            tempSaveRegistrationData({
                prs_business_use_cases_data: tempArray,
            });
        }


    }, [source]);

    useEffect(() => {

        if (customerDetail && customerDetail.prs_business_use_cases_data && customerDetail.prs_business_use_cases_data.business_use_cases && customerDetail.prs_business_use_cases_data.business_use_cases.length > 0) {
            let idArray = [];
            customerDetail.prs_business_use_cases_data.business_use_cases.forEach((item) => {
                idArray.push(item.business_use_case_id);
            });
            setSource(idArray);

        }
        if ((tempRegistrationDataState && tempRegistrationDataState.prs_business_use_cases_data && tempRegistrationDataState.prs_business_use_cases_data.length > 0)) {
            let idArray = [];

            tempRegistrationDataState.prs_business_use_cases_data.forEach((item) => {
                idArray.push(item.business_use_case_id);
            });
            setSource(idArray);
        }
    }, [customerDetail.prs_business_use_cases_data]);

    const pushItem = (e) => {
        setError(false);
        if (source.includes(e)) {
            const temp = [...source];
            temp.splice(temp.indexOf(e), 1);
            setSource(temp);
        } else {
            setSource([...source, e]);
        }


    };

    const onSuccess = (arg = "onBoarding") => {
        onContinue(arg);
    };
    const onContinuePress = () => {
        if (!source.every(i => customerDetail.prs_business_use_cases_data.business_use_cases.find(j => j.business_use_case_id === i))) {
            if (source.length > 0) {
                let body = {
                    business_use_cases: source,
                };
                saveBusinessUseCasesAction({
                    body,
                    cb: onSuccess
                });

            } else {
                setError(true);
            }
        } else {
            onSuccess("header");
        }
        analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_PROFILE_CONTINUE_CLICK.event })

        let primaryUseCaseName = ``;

        primaryUseCaseList.data.forEach((primaryUseCase) => {
            if (source.includes(primaryUseCase.business_use_case_id)) {
                primaryUseCaseName = primaryUseCaseName === '' ? primaryUseCase.business_use_case_name : `${primaryUseCaseName}, ${primaryUseCase.business_use_case_name}`
            }
        })

        if (source && source.length && primaryUseCaseName) {

            let trackingProperties = ANALYTICS.EVENTS.TAILOR_PROFILE_FINANCIAL_NEED.tracking_properties;

            let tracking_data = {};
            trackingProperties.forEach((tP) => {

                return tracking_data[tP.key] = primaryUseCaseName;
            })
            analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_PROFILE_FINANCIAL_NEED.event, tracking_data })
        }
    };
    const checkItem = (e) => {
        if (source.includes(e)) {
            return true;
        } else {
            return false;
        }
    };

    return (<>
        <div className={classes.root}>

            <Grid container justifyContent="center" className={classes.content}>
                {
                    isLoading && <Loader />
                }
                <Grid item xs="11" md="8" className={classes.subContent}>
                    <div>
                        <Typography variant={smDownMatches ? "h6" : "h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep6Title} /></Typography>
                        <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep6Subtitle} /></Typography>
                    </div>
                    <Grid container justifyContent="center">
                        <Grid item md="12" className={classes.middleContent}>
                            <Grid container justifyContent="center" spacing={2} className={classes.margin}>
                                {primaryUseCaseList && !primaryUseCaseList.loading && primaryUseCaseList.data && primaryUseCaseList.data.length > 0 ?
                                    primaryUseCaseList.data.map((item) => (
                                        <Grid item xs="12" md="6" key={item.business_use_case_id}>
                                            <RegisterSquare img={item.business_use_case_image_url} onPressItem={() => { pushItem(item.business_use_case_id); }} id={item.business_use_case_id} title={item.business_use_case_name} checkItem={checkItem} />
                                        </Grid>
                                    )) : <IndustryListSkeleton type={mdUpMatches ? "normal" : "column"} arraySize={6} />
                                }
                                {
                                    error && <Typography variant="body2" color="error" ><FormattedMessage {...intl.onBoardingBusinessUseCaseError} /></Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lastMainGrid}>
                        <GoBack onClick={onBackPress} isLoading={isLoading} />
                        <Grid item className={classes.btnDiv} md="3">
                            <Button
                                className={classes.btnDiv}
                                disabled={isLoading || source.length < 1} size="small" onClick={onContinuePress}>
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep6ContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </>);
}

// export default BusinessUseCases;


const mapStateToProps = createStructuredSelector({
    primaryUseCaseList: getPrimaryUseCaseListState(),
    saveBusinessUseCasesState: saveBusinessUseCasesState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    tempRegistrationDataState: tempRegistrationDataState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getPrimaryUseCaseList: (data) => dispatch(getPrimaryUseCaseListAction(data)),
        saveBusinessUseCasesAction: (data) => dispatch(saveBusinessUseCasesAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(BusinessUseCases);