
import React, { memo, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loader, StyledError } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from './intl/RegistrationCompleteDashboard';
import { Button, Typography } from 'components/ui';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { acceptAccountAgreementAction, getCustomerDetailsAction, reGeneratePdfAction } from 'store/Register/registerActions';
import { acceptAccountAgreementState, reGeneratePdfState } from 'store/Register/registerSelectors';
// eslint-disable-next-line no-unused-vars
import { pdfjs } from 'react-pdf';
// import { ENTITY_STATUS } from '@truly-financial/common/consts/entity.consts';
import { GotoTrulyButton } from './GotoTrulyButton';
import { openLiveChat } from 'utils/hubspot';
import { Skeleton } from '@material-ui/lab';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4),
      backgroundColor: theme.palette.background.default,
    },
    backgroundColor: theme.palette.primary.contrastText,
    width: "100%",
    textAlign: "center",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: theme.spacing(1.5),
      borderTopRightRadius: theme.spacing(1.5),
    },

    backgroundColor: theme.palette.primary.contrastText,
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    // minHeight: `calc(100vh - 50px)`,
    // minHeight: `calc(100vh - 69px)`,
  },
  titleTxt: {
    marginTop: theme.spacing(8.25),
    marginBottom: theme.spacing(2),
  },
  info1: {
    padding: theme.spacing(4),
  },
  pdMainView: {
    backgroundColor: theme.palette.grey50,
    borderRadius: theme.spacing(1),
    width: "auto",
    height: '125vh',
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      height: '200vh',
    },
    //height: '75%',
    // flex: 1,
    margin: theme.spacing(4, 0),
    padding: theme.spacing(3, 3, 0, 3),
    display: "flex",
  },
  padSubView: {
    backgroundColor: "white",
    flex: 1,
    borderRadius: theme.spacing(1, 1, 0, 1),
  },
  iconColor: {
    color: `${theme.palette.grey500}`,
    "&.Mui-checked": {
      color: `${theme.palette.accent.main} !important`,
    },
  },
  btnDiv: {
    margin: theme.spacing(0, 1, 0, 1),
    padding: theme.spacing(0, 3),
  },
  correctionBtnDiv: {
    margin: theme.spacing(0, 1, 0, 1),
    padding: theme.spacing(0, 3),
    minWidth: theme.spacing(27)
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  lastGrid: {
    margin: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(2, "auto", 2, "auto"),
    },

    display: "flex",
  },
  iframePdf: {
    width: "100%",
    height: "100%",
  },
  mainWrapperDiv: {
    flex: 0.75,
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      width: "90%",
    },
    width: "55%",
    display: "flex",
    flexDirection: "column",
  },
  column: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  gotoTrulyBtn: {
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      paddingRight: theme.spacing(5),
    },

    paddingBottom: theme.spacing(5),
  },
  formGroup: {
    margin: theme.spacing(0, 3)
  },
  skeleton: {
    height: '100%',
    width: 'auto',
    borderRadius: theme.spacing(1),
  },
  skeletonDiv: {
    height: '60%',
    width: "80%",
    marginBottom: theme.spacing(2)
  }
}));
const initialState = {
  account_agreement_accepted: 0,
  account_agreement_accepted_by: null,
  account_agreement_timestamp: null,
  // account_agreement_pdf_url: ‘https:blah.pdf’,
};

function CompanyStatusUserReview(props = {}) {

  const { acceptAccountAgreement, acceptAccountAgreementState, customerDetails = {}, getCustomerDetails, reGeneratePdfState, reGeneratePdf, } = props;
  const classes = useStyles();
  const customerDetailData = customerDetails.data || {};
  const [formData, setFormData] = useState(initialState);
  const intlStr = useIntl();
  const customerDetailsData = customerDetails.data || {};

  const error = {
    error: { message: "Re-generate agreement" }
  };

  useEffect(() => {
    if (customerDetailsData.account_agreement_accepted) {
      setFormData(formData => ({ ...formData, account_agreement_accepted: customerDetailsData.account_agreement_accepted }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerDetailsData.entity_id && !customerDetailData.account_agreement_pdf_url) {
      onRetryPdfGenerate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetailsData.entity_id]);

  if (!customerDetailData || customerDetails.loading) {
    return (<Loader componentCenter />);
  }

  const onCheckBoxPress = (e) => {
    setFormData(formData => ({ ...formData, account_agreement_accepted: e.target.checked }));
  };

  const onContinue = () => {
    acceptAccountAgreement({
      cb: getCustomerDetails,
    });
  };

  const openChat = () => {
    openLiveChat();
  };
  const onSuccessPdf = () => {
    getCustomerDetails();
  };
  const onRetryPdfGenerate = () => {

    reGeneratePdf({ cb: onSuccessPdf });
  };

  return (
    <Paper elevation={0} className={classes.root}>
      {
        ((acceptAccountAgreementState && acceptAccountAgreementState.loading) || (reGeneratePdfState && reGeneratePdfState.loading)) && <Loader />
      }
      {customerDetails.loading || (!customerDetailData.account_agreement_pdf_url && !customerDetailData.entity_id) ?
        <div className={classes.skeletonDiv} >
          <Skeleton variant='react' className={classes.skeleton} />
        </div>
        :
        !customerDetailData.account_agreement_pdf_url && customerDetailData.entity_id && !customerDetails.loading && !reGeneratePdfState.loading ?

          <StyledError data={error} retry={onRetryPdfGenerate} />
          :
          <>
            <div className={classes.main}>
              <div className={classes.column}  >
                <div className={classes.mainWrapperDiv} >
                  <Typography className={classes.titleTxt} color="grey700" variant="h5"> <FormattedMessage {...intl.accountVeri} /> </Typography>
                  {customerDetailData.account_agreement_pdf_url && <Typography color="grey400" variant="subtitle1"> <FormattedMessage {...intl.accountVeriSubTitle} /> </Typography>}
                  {customerDetailData.account_agreement_pdf_url && <div className={classes.pdMainView}>
                    <div className={classes.padSubView}>
                      <iframe className={classes.iframePdf} title="Document" src={customerDetailData.account_agreement_pdf_url}></iframe>
                    </div>
                  </div>}
                  <FormGroup className={classes.formGroup}>
                    <FormControlLabel
                      disabled={customerDetailData.account_agreement_accepted && customerDetailData.account_agreement_timestamp}
                      // disabled={data.entity_person_type === "PRIMARY_CONTACT" ? true : false}
                      onChange={(e) => { onCheckBoxPress(e); }}
                      control={<Checkbox className={classes.iconColor} checked={formData.account_agreement_accepted} name={"account_agreement_accepted"} />} label={`${intlStr.formatMessage(intl.accountVeriBottomTxt)}`} />
                  </FormGroup>

                </div>
                <div className={classes.lastGrid}>
                  <Button size="small"
                    // startIcon={<ErrorOutlineIcon />}
                    variant="outlined"
                    className={classes.correctionBtnDiv}
                    onClick={openChat}
                  >
                    <div className={classes.row}>
                      <ErrorOutlineIcon /> &nbsp;
                      <div>
                        <FormattedMessage {...intl.accountAgreementCorrectionButton} />
                      </div>
                    </div>
                  </Button>

                  <Button size="small"
                    className={classes.btnDiv}
                    onClick={onContinue}
                    color="secondary"
                    disabled={!formData.account_agreement_accepted}
                  >
                    <div className={classes.row}>
                      <CheckIcon />
                      <div>
                        <FormattedMessage {...intl.accountAgreementAcceptButton} />
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
              <div className={classes.gotoTrulyBtn}>
                <GotoTrulyButton />
              </div>
            </div>
          </>
      }

    </Paper >
  );
}

const mapStateToProps = createStructuredSelector({
  acceptAccountAgreementState: acceptAccountAgreementState(),
  reGeneratePdfState: reGeneratePdfState(),
});

function mapDispatchToProps(dispatch) {
  return {
    acceptAccountAgreement: (data) => dispatch(acceptAccountAgreementAction(data)),
    getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
    reGeneratePdf: (data) => dispatch(reGeneratePdfAction(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(CompanyStatusUserReview);
