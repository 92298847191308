
import { defineMessages } from 'react-intl';

export const scope = 'app.components.welcomeDashBoard';

export default defineMessages({
    welcome: {
        id: `${scope}.welcome`,
        defaultMessage: 'Almost Done!',
    },
    pendingInfo: {
        id: `${scope}.welcome`,
        defaultMessage: 'Pending Information',
    },
    submitted: {
        id: `${scope}.submitted`,
        defaultMessage: 'Well Done!',
    },
    applicationRec: {
        id: `${scope}.applicationRec`,
        defaultMessage: 'Application Received!',
    },
    submittedSubTxtOne: {
        id: `${scope}.applicationRec`,
        defaultMessage: 'Application Received!',
    },
    submittedBodyText: {
        id: `${scope}.submittedBodyText`,
        defaultMessage: 'Some Quick Tips To Help Get You Started:',
    },
    submittedSubText: {
        id: `${scope}.submittedSubText`,
        defaultMessage: 'Your information under review, and we’ll email you further details in 1-2 business days.',
    },
    submittedInfoText1: {
        id: `${scope}.submittedInfoText1`,
        defaultMessage: 'We’ll email you further details in 1-2 business days. Explore our features while you wait!',
    },
    submittedInfoText2: {
        id: `${scope}.submittedInfoText2`,
        defaultMessage: 'In the meantime click any of our feature pages to learn more.',
    },
    verifiedInfoText1: {
        id: `${scope}.verifiedInfoText1`,
        defaultMessage: 'Click any of our feature pages to learn more.',
    },
    verifiedSubText: {
        id: `${scope}.verifiedSubText`,
        defaultMessage: 'Your application has been approved',
    },
    setupText: {
        id: `${scope}.setupText`,
        defaultMessage: 'Account set up is',
    },
    completeReg: {
        id: `${scope}.completeReg`,
        defaultMessage: 'Set Up Business Profile',
    },
    setupTextBreak: {
        id: `${scope}.setupTextBreak`,
        defaultMessage: 'Complete...',
    },
    description: {
        id: `${scope}.description`,
        defaultMessage: `The last outstanding action to approve this account is the validation of all Beneficial Owners' IDs. We have sent verification links to the listed individuals. If necessary, you may resend the link or copy it directly using the attached icons.`,
    },
    descriptionBreak1: {
        id: `${scope}.descriptionBreak1`,
        defaultMessage: 'your business account is the verification of your Beneficial Owners IDs',
    },
    descriptionBreak3: {
        id: `${scope}.descriptionBreak3`,
        defaultMessage: 'We have sent them links for this directly. If you need you can resend the verification link',
    },
    descriptionBreak4: {
        id: `${scope}.descriptionBreak4`,
        defaultMessage: 'manually, using the menu buttons...',
    },
    pendingDetails: {
        id: `${scope}.pendingDetails`,
        defaultMessage: 'Pending Verification',
    },
    details: {
        id: `${scope}.details`,
        defaultMessage: 'Details',
    },
    verificationPending: {
        id: `${scope}.verificationPending`,
        defaultMessage: 'ID Verification Pending',
    },
    verified: {
        id: `${scope}.verified`,
        defaultMessage: 'Verified',
    },
    reSend: {
        id: `${scope}.reSend`,
        defaultMessage: 'Resend Link',
    },
    copy: {
        id: `${scope}.copy`,
        defaultMessage: 'Copy Link URL',
    },
    invite: {
        id: `${scope}.invite`,
        defaultMessage: 'Invite as Team Member',
    },
    continue: {
        id: `${scope}.continue`,
        defaultMessage: 'Continue',
    },
    accountAgreementAcceptButton: {
        id: `${scope}.accountAgreementAcceptButton`,
        defaultMessage: 'Confirm',
    },
    accountAgreementCorrectionButton: {
        id: `${scope}.accountAgreementCorrectionButton`,
        defaultMessage: 'Report an Error',
    },
    accountVeri: {
        id: `${scope}.accountVeri`,
        defaultMessage: 'Confirm Your Owner Details',
    },
    accountVeriSubTitle: {
        id: `${scope}.accountVeriSubTitle`,
        defaultMessage: 'Double check that the following information is accurate',
    },
    accountAppr: {
        id: `${scope}.accountAppr`,
        defaultMessage: 'Welcome to Truly Financial!',
    },
    accountApprovedAndNextLogin: {
        id: `${scope}.accountApprovedAndNextLogin`,
        defaultMessage: 'We’ve Got You Covered',
    },
    accountApprovedAndNextLoginSubText: {
        id: `${scope}.accountApprovedAndNextLoginSubText`,
        defaultMessage: 'Looking for insights on how to use your account? Click a feature below to learn more!',
    },
    accountApprSubTitle: {
        id: `${scope}.accountApprSubTitle`,
        defaultMessage: 'Below is a generated PDF of the information you’ve shared with us for your own records.',
    },

    accountVeriBottomTxt: {
        id: `${scope}.accountVeriBottomTxt`,
        defaultMessage: 'The above information pertaining to the Beneficial Owners & Controlling Party is correct.',
    },
    editCompanyTitle: {
        id: `${scope}.editCompanyTitle`,
        defaultMessage: 'Edits To Your Application Are Required',
    },
    editCompanySubTitle: {
        id: `${scope}.editCompanySubTitle`,
        defaultMessage: 'You will have received an email from our team outlining the flagged changes or elaborations required of you. Please complete using the button below.',
    },
    editCompanyBtnTitle: {
        id: `${scope}.editCompanyBtnTitle`,
        defaultMessage: 'Review Your Application',
    },
    accountExtraInfoTitle: {
        id: `${scope}.accountExtraInfoTitle`,
        defaultMessage: 'A Quick Question For You...',
    },
    accountExtraInfoSubTitle: {
        id: `${scope}.accountExtraInfoSubTitle`,
        defaultMessage: 'Our operations team needs clarification on something before approving your account. Please fill out the following. ',
    },
});
