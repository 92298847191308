
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    fixedHeader: props => props.fixed && ({
        position: 'absolute',
        left: 0,
        right: 0,
        background: theme.palette.background.paper,
        marginRight: 'auto',
        marginLeft: 'auto',
        zIndex: 1000,
        marginTop: theme.spacing(-2),
        padding: theme.spacing(1, 3),
    }),
    wrapperDiv: props => !props.fixed && ({
        position: 'relative',
    }),
}));

function DialogSubHeader(props = {}) {
    const classes = useStyles(props);
    return (<div className={classes.wrapperDiv}>
        <div className={classes.fixedHeader}>
            <div ref={props.fixed ? props.innerRef : null} >
                {props.children}
            </div>
        </div>
    </div>);
}
DialogSubHeader.defaultProps = {
    fixed: true
};

DialogSubHeader.propTypes = {
    fixed: PropTypes.bool
};
export default memo(DialogSubHeader);

