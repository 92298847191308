import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import RequestSummary from './RequestSummary';
// import BankDetailSubmitted from './BankDetailSubmitted';
import { validateLinkAction } from 'store/User/userActions';
import { validateLinkState } from 'store/User/userSelectors';
import { getUrlParamByName } from 'utils/common';
import { Loader, StyledError } from 'components';

// import useCommonStyles from 'styles';

const useStyles = makeStyles(theme => ({
  root: {

  },
}));

function ContactRequestBankDetail(props = {}) {
  const { linkRes, validateLink } = props;
  const classes = useStyles(props);
  const linkId = getUrlParamByName('id');
  useEffect(() => {
    validateLink({
      id: linkId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (linkRes.loading) {
    return (<Loader />)
  }

  if (linkRes.error) {
    return (<StyledError data={linkRes} />)
  }

  return (<div className={classes.root}>
      <RequestSummary />
    </div>);
}

ContactRequestBankDetail.defaultProps = {
  test: " ContactRequestBankDetail container ",
};

ContactRequestBankDetail.propTypes = {
  test: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  linkRes: validateLinkState(),
});

const mapDispatchToProps = (dispatch) => ({
  validateLink: data => dispatch(validateLinkAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ContactRequestBankDetail);
