import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalVerificationState = state => state.verification || {};
export const verificationState = () =>
  createSelector(GlobalState, substate => substate.verification);

export const acceptControllingPartyConsentState = () =>
  createSelector(globalVerificationState, substate => substate.acceptControllingPartyConsentState);

export const acceptConfidentialityAgreementState = () =>
  createSelector(globalVerificationState, substate => substate.acceptConfidentialityAgreementState);

export const refreshIdVerificationTokenState = () =>
  createSelector(globalVerificationState, substate => substate.refreshIdVerificationTokenState);

export const personalDetailsFromIdVerificationState = () =>
  createSelector(globalVerificationState, substate => substate.personalDetailsFromIdVerificationState);

export const getPersonalDetailsState = () =>
  createSelector(globalVerificationState, substate => substate.getPersonalDetailsState);

export const updatePersonalDetailsState = () =>
  createSelector(globalVerificationState, substate => substate.updatePersonalDetailsState);

export const uploadVerificationDocumentState = () =>
  createSelector(globalVerificationState, substate => substate.uploadVerificationDocumentState);

export const createPasswordState = () =>
  createSelector(globalVerificationState, substate => substate.createPasswordState);

export const acceptInviteState = () =>
  createSelector(globalVerificationState, substate => substate.acceptInviteState);

export const deprecatedGetTeamMemberDetailsState = () =>
  createSelector(globalVerificationState, substate => substate.deprecatedGetTeamMemberDetailsState);
