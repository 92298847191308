import { call, put, takeLeading, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import {
  getEntityListAction,
  // resetUserAction,
  getEntityListSetDataAction, setSelectedEntityAction,
  updateUserLocalSettingsSetDataAction,
  validateLinkSetDataAction,
} from './userActions';
import {
  // RESET_USER,
  GET_ENTITY_LIST,
  UPDATE_USER_LOCAL_SETTINGS,
  VALIDATE_LINK,
} from './userConstants';

import {
  LINKS_BASE_API_URL,
  USER_BASE_API_URL,
} from 'config/apiUrls';
import { getEntityId, removeEntityId, setEntityId } from 'utils/auth';

const USER_URL = `${USER_BASE_API_URL}/users`;
const LINKS_URL = `${LINKS_BASE_API_URL}/links`;

export default function* userSaga() {
  // add all sagas here
  yield takeLatest(VALIDATE_LINK, validateLinkSaga);
  yield takeLatest(UPDATE_USER_LOCAL_SETTINGS, updateUserLocalSettingsSaga);
  yield takeLeading(GET_ENTITY_LIST, getEntityListSaga);
}

export function* getEntityListSaga(args = {}) {
  const requestUrl = `${USER_URL}/user-entities`;
  try {
    const result = yield call(request, requestUrl);
    if (Array.isArray(result.data) && !result.error) {
      const storedEntity = getEntityId();
      const selectedEntity = result.data.find(i => i.entity_id === storedEntity) || {};
      if (selectedEntity.entity_id) {
        setEntityId(selectedEntity.entity_id);
        yield put(setSelectedEntityAction({data: selectedEntity}));
      } else if(Array.isArray(result.data) && result.data.length === 1) {
        setEntityId(result.data[0].entity_id);
        yield put(setSelectedEntityAction({data: result.data[0]}));
      } else {
        removeEntityId();
        yield put(setSelectedEntityAction({data: {}}));
      }
    }
    
    yield put(getEntityListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}


export function* updateUserLocalSettingsSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${USER_URL}/user-entity-settings`;
  const options = {
    method: 'POST',
    body,
  }
  try {
    const result = yield call(request, requestUrl, options);
    yield put(updateUserLocalSettingsSetDataAction(result));
    yield put(getEntityListAction());
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* validateLinkSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${LINKS_URL}/validate?id=${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(validateLinkSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
