import RegistrationOnHold from 'containers/OnBoarding/RegistrationOnHold';
import React from 'react';
import { Helmet } from 'react-helmet-async';


function RegistrationOnHoldPage(props = {}) {

    return (
        <>
            <Helmet >
                <title>Registration-Status</title>
                <meta name="description" content="Registration status page" />
            </Helmet>
            <RegistrationOnHold />
        </>
    );

}

export default RegistrationOnHoldPage;