
import React, { memo, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { searchLocationAction, searchLocationSetDataAction } from 'store/Reference/referenceActions';
// import { searchLocationState } from 'store/Reference/referenceSelectors';
// import { initialState } from 'store/Reference/referenceReducer';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Typography } from 'components/ui';
import intl from 'containers/Common/intl/SearchLocationIntl';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: '#fafafa',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function SearchLocation(props = {}) {
  const classes = useStyles();
  const { compId = "ALL", location, countryCode, searchLocation, resetSearchLocation, onChange = () => null, onInputChange = () => null, value, onBlur, disabled } = props;
  const [keyUpTimeout, setKeyUpTimeout] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(value ? { address_line_1: value } : null);
  const [inputValue, setInputValue] = useState("");
  const [inputOnlyString, setInputOnlyString] = useState("");
  const [inputReason, setInputReason] = useState("");

  useEffect(() => {
    return () => {
      resetSearchLocation({ compId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSelectedLocation(value ? { address_line_1: value } : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // useEffect(() => {
  //   if (!inputValue) {
  //     resetSearchLocation();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.data, inputValue]);
  useEffect(() => {
    if (inputReason === 'input') {
      search(inputValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, inputReason, countryCode]);


  const search = (value) => {
    setKeyUpTimeout(clearTimeout(keyUpTimeout));
    const isValidStr = value && value.match(/([A-Za-z])/g);
    if (!isValidStr || !isValidStr[1]) return;
    setKeyUpTimeout(setTimeout(() => {
      searchLocation({ searchString: value, compId, countryCode: countryCode });
    }, 1000));
  };

  const onInputChangeHandler = (_e, val, reason) => {
    reason === 'input' && setInputOnlyString(val);
    setInputValue(val);
    setInputReason(reason);
    onInputChange(_e, val, reason);
    onChange(_e, { address_line_1: val }, reason);
  };
  const onChangeHandler = (_e, val, reason) => {
    if (val && val.fixedOption) {
      const op = { address_line_1: inputOnlyString }
      setSelectedLocation(op);
      onChange(_e, op || {});
      return;
    }
    const stateCodeSanitizer = val?.state_code ? val?.state_code?.replaceAll(/[^a-zA-Z0-9 ]/g, "")?.toUpperCase() : '';
    setSelectedLocation({...val, state_code: stateCodeSanitizer});
    onChange(_e, {...val, state_code: stateCodeSanitizer} || {});
  };

  return (<>
    <Autocomplete
      id="search-location"
      {...props}
      freeSolo
      autoComplete
      getOptionDisabled={(option) => (option.disabled)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.address_line_1)}
      value={selectedLocation}
      filterOptions={(x) => x}
      includeInputInList
      // filterSelectedOptions
      popupIcon={<></>}
      options={location}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      selectKey="address_line_1"
      labelKey="address_line_1"
      makeFocus={props.makeFocus}
      onClose={onBlur}
      disabled={disabled}
      loadingText={"Searching…"}
      renderOption={(option) => {
        const addr = option.description.match(/([^,]*),(.*)/);

        if (option.fixedOption && option.disabled) {
          return (<>
            <Typography variant="caption" >
              <FormattedMessage {...intl.searchHint} />
            </Typography>

          </>)
        }
        if (option.fixedOption && !option.disabled) {
          return (<>
            <span>
              <Typography variant="caption" >
                <FormattedMessage {...intl.searchHintNoResultsOrMatchHint} values={{
                  inputOnlyString,
                  b: chunks => <strong>{chunks}</strong>

                }} />  </Typography></span>
          </>)
        }
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <span style={{ fontWeight: 700 }}>
                {(addr && addr[1]) || option.description}
              </span>
              <Typography variant="body2" color="textSecondary">
                {addr && addr[2]}
              </Typography>
            </Grid>
          </Grid>)
      }}
    />
  </>)
};

// const mapStateToProps = createStructuredSelector({
//   location: searchLocationState(),
// });

const mapStateToProps = (s, p) => {
  const location = s.references.searchLocationState[p.compId || 'ALL'] || { data: [{ fixedOption: true, disabled: true, description: " " }], loading: false, error: '' };
  return { location };
}

function mapDispatchToProps(dispatch) {
  return {
    searchLocation: data => dispatch(searchLocationAction(data)),
    // resetSearchLocation: data => dispatch(searchLocationSetDataAction({ ...initialState.searchLocationState, reset: true })),
    resetSearchLocation: data => dispatch(searchLocationSetDataAction({ compId: data.compId, data: [{ fixedOption: true, disabled: true, description: " " }], loading: false, error: '', reset: true })),
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(SearchLocation,
  (prevProps, nextProps) => (
    (JSON.stringify(prevProps.location) === JSON.stringify(nextProps.location) &&
      prevProps.value === nextProps.value &&
      prevProps.error === nextProps.error &&
      prevProps.required === nextProps.required &&
      prevProps.makeFocus === nextProps.makeFocus &&
      prevProps.disabled === nextProps.disabled
    )
  )
);