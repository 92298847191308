/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from 'react';
// import PropTypes from 'prop-types';
import { IconButton, InputAdornment } from '@material-ui/core';
import { TextField } from './ui';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import useCommonStyles from 'styles';



function PasswordInput(props = {}) {
  // const commonClasses = useCommonStyles(props);
  // eslint-disable-next-line no-unused-vars
  const [showPassword, setShowPassword] = useState(false);
  
  const handleClickShowPassword =(e) => {
    setShowPassword(!showPassword)
  };
  
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (<>
    <TextField type={showPassword ? 'text': 'password'}
      {...props}
      InputProps={{
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>)
      }}
    />
  </>);
}

PasswordInput.defaultProps = {
};

PasswordInput.propTypes = {
};

export default memo(PasswordInput);
