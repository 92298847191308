/* eslint-disable react/no-string-refs */
import { IconButton } from '@material-ui/core';
import React, { useState, useEffect, memo } from 'react';
import { Button, Typography } from './ui';
import StyledDialog from 'components/StyledDialog';
import { makeStyles } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Webcam from "react-webcam";
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import { documentUploadAction, documentUploadSetDataAction } from 'store/Register/registerActions';
// import { documentUploadState } from 'store/Register/registerSelectors';
import Loader from './Loader';
// import { initialState } from 'store/Register/registerReducer';

const useStyles = makeStyles(theme => ({
    progressWrapper: {
        height: '40px',
        // width: '245px',
        width: '100%',
        float: 'left',
        overflow: 'hidden',
        // backgroundColor: 'rgb(245, 245, 245)',
        // color: '#fff',
        // fontFamily: 'Avenir',
        cursor: 'pointer',
        textAlign: 'center',
        border: '1px solid #D2E8F1',
        borderRadius: '100px',
    },
    progressBar: {
        // float: 'left',
        width: '0',
        height: '100%',
        // color: '#fff',
        textAlign: 'center',
        backgroundColor: '#D2E8F1',
        // WebkitBoxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
        // boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
        WebkitTransition: 'width .6s ease',
        Otransition: 'width .6s ease',
        transition: 'width .6s ease',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelButton: {
        marginTop: '-5px',
        WebkitAppearance: 'none',
        cursor: 'pointer',
        background: '0 0',
        border: 0,
        // float: 'left',
        fontSize: '21px',
        fontWeight: 700,
        lineHeight: 1,
        color: '#000',
        textShadow: '0 1px 0 #fff',
        filter: 'alpha(opacity=20)',
        opacity: '.2',
        padding: '8px',
    },
    centerFix: {
        paddingTop: theme.spacing(0.625)
    },
    cameraIcon: {
        // fontSize: theme.spacing(2.5),
        marginRight: theme.spacing(1.2)
    },
    btnDiv: {
        display: "flex",
        alignItems: "center",
        // border: "2px"
    },
    btnError: {
        display: "flex",
        alignItems: "center",
        border: `1px solid ${theme.palette.error.main}`
    },
    iconBtnOne: {
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',

        height: theme.spacing(5),
        width: theme.spacing(5)
    },
    iconBtnOneDisable: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        height: theme.spacing(5),
        width: theme.spacing(5),
        border: `2px solid ${theme.palette.grey400}`,

    },
    iconBtnTow: {
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        height: theme.spacing(5),
        width: theme.spacing(5),
        margin: theme.spacing(0, 2),
    },
    iconBtnTowDisable: {
        border: `2px solid ${theme.palette.grey400}`,
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        height: theme.spacing(5),
        width: theme.spacing(5),
        margin: theme.spacing(0, 2),
    },
    icon: {
        fontSize: theme.spacing(3),
        color: theme.palette.grey700
    },
    iconDisable: {
        fontSize: theme.spacing(3),
        color: theme.palette.gray500
    },
    webCameRoot: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: theme.spacing(50)
    },
    webCame: {
        // height: theme.spacing(60),
        // width: theme.spacing(60),
        margin: theme.spacing(0, 2)
    },
    btnDivLast: {
        display: 'flex',
        flex: 1,
        margin: theme.spacing(3, 0),
        alignItems: 'center',
        justifyContent: 'center'
    },
    capturedImg: {
        margin: theme.spacing(0, 2)
    },
    btnInsideDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    image: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: theme.spacing(6),
        marginRight: theme.spacing(1.2)
    },
    subtitle: {
        textAlign: 'center',
        margin: theme.spacing(3, 0, 0, 0),
    }
}));

function TakePhotoWebCame(props = {}) {
    const { name, onChange, value, btnName = "Upload File", documentUpload, documentUploadState, id, resetDocumentUpload, error, columnWise } = props;

    const [capturedImage, setCapturedImage] = useState(value);
    // const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [facingMode, setFacingMode] = useState("environment");
    // eslint-disable-next-line no-unused-vars
    // const [errorText, setErrorText] = useState('Required');

    const classes = useStyles(props);

    const webcamRef = React.useRef(null);


    const videoConstraints = {
        // width: 1280,
        // height: 720,
        // facingMode: "user"
        // facingMode: "environment"
    };

    useEffect(() => {
        if (value) {
            setCapturedImage(value);
        }
    }, [value, open]);


    const onAgain = () => {
        setCapturedImage("");
    };

    const onCapture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
        },

        [webcamRef]
    );
    const onSwitchCamera = React.useCallback(
        () => {
            setFacingMode((c) => c === 'environment' ? 'user' : 'environment')
        },
        []
    );
    const b64toBlob = (dataURI) => {

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    };

    useEffect(() => {

        if (documentUploadState && documentUploadState.data && Object.keys(documentUploadState.data).length > 0 && capturedImage) {
            onChange && onChange({ target: { name, value: `${id}.png`, eId: id, response: documentUploadState.data } });

            resetDocumentUpload();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUploadState]);

    const onSuccess = () => {

        if (documentUploadState && documentUploadState.data) {
            onToggle();
        }

    };

    const onSelect = async () => {
        if (capturedImage) {
            var blob = b64toBlob(capturedImage);
            var formData = {
                file: blob
            };
            documentUpload({ formData: formData, cb: onSuccess });
        }
    };

    const onToggle = () => {

        setOpen(!open);
    };
    const content = () => {
        return (
            <>
                {
                    documentUploadState && documentUploadState.loading && <Loader />
                }
                <Typography variant="subtitle1" className={classes.subtitle} > Take a photo and upload it </Typography>
                <div className={classes.webCameRoot} >
                    {
                        capturedImage ? <img className={classes.capturedImg} src={capturedImage} alt="user pic" /> :
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                className={classes.webCame}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    ...videoConstraints,
                                    facingMode
                                }}
                            />
                    }
                </div>

                <div className={classes.btnDivLast} >
                    <IconButton disabled={!capturedImage} onClick={onAgain} className={!capturedImage ? classes.iconBtnOneDisable : classes.iconBtnOne} >
                        <ReplayIcon className={!capturedImage ? classes.iconDisable : classes.icon} />
                    </IconButton>
                    <IconButton disabled={capturedImage} onClick={onSwitchCamera} className={capturedImage ? classes.iconBtnTowDisable : classes.iconBtnTow} >
                        <SwitchCameraIcon className={capturedImage ? classes.iconDisable : classes.icon} />
                    </IconButton>
                    <IconButton disabled={capturedImage} onClick={onCapture} className={capturedImage ? classes.iconBtnTowDisable : classes.iconBtnTow} >
                        <CameraAltIcon className={capturedImage ? classes.iconDisable : classes.icon} />
                    </IconButton>
                    <IconButton disabled={!capturedImage} onClick={onSelect} className={!capturedImage ? classes.iconBtnOneDisable : classes.iconBtnOne} >
                        <CheckIcon className={!capturedImage ? classes.iconDisable : classes.icon} />
                    </IconButton>
                </div>
            </>
        )
    };



    return (<div style={{ display: 'flex', flex: 1, width: columnWise ? '70%' : undefined }}>
        <div style={{ flex: 1 }}>
            <Button variant='outlined' onClick={onToggle} className={error ? classes.btnError : classes.btnDiv} >
                <div className={classes.btnInsideDiv} >
                    <CameraAltIcon className={classes.cameraIcon} />
                    <Typography variant="button"> {btnName} </Typography>
                </div>

            </Button>


            <StyledDialog
                title={"Take a photo"}
                header={true}
                backBtn={false}
                open={open}
                fullScreen={false}
                type="content"
                footer={false}
                disableBackdropClick={true}
                onClose={onToggle}
                padding
            >
                {content()}
            </StyledDialog>
        </div>
    </div>);
}

const mapStateToProps = createStructuredSelector({
    // someState: someState(),
    // documentUploadState: documentUploadState()
});

const mapDispatchToProps = (dispatch) => ({
    // someAction: data => dispatch(someAction(data)),
    // documentUpload: data => dispatch(documentUploadAction(data)),
    // resetDocumentUpload: data => dispatch(documentUploadSetDataAction(initialState.documentUploadState))

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(TakePhotoWebCame);


