
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getIndustriesListState } from 'store/Reference/referenceSelectors';
import { getIndustriesListAction } from 'store/Reference/referenceActions';

function SelectIndustry(props = {}) {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState({});
    const { industriesList, getIndustriesList, propsIndustry, propsGetIndustry } = props;

    useEffect(() => {

    }, []);
    const options = propsIndustry || industriesList;
    const getOptions = propsGetIndustry || getIndustriesList;

    const onChange = (e, val) => {
        setValue(val || {});
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: val && val
            }
        }, val);
    };

    return (<>
        <Autocomplete
            {...props}
            getOptions={getOptions}
            options={options}
            onChange={onChange}
            onBlur={props.onBlur}
            selectKey="industry_id"
            labelKey="industry_title"
            makeFocus={props.makeFocus}
            renderOption={(option) => (
                <React.Fragment>
                    {option.industry_title}
                </React.Fragment>
            )}
        />
    </>)
};


const mapStateToProps = createStructuredSelector({
    industriesList: getIndustriesListState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getIndustriesList: (data) => dispatch(getIndustriesListAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectIndustry,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.industriesList) === JSON.stringify(nextProps.role) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);