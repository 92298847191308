/* eslint-disable no-script-url */
import React, { useState } from 'react';

import { AuthState } from '@aws-amplify/ui-components';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';
import TextField from 'components/ui/TextField';
import { Auth } from 'aws-amplify';
import Button from 'components/ui/Button';
import { NewPasswordInput } from 'components';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { resetPasswordApi } from 'store/User/userApi';

const useStyles = makeStyles((theme) => ({
  headings: {
    textAlign: "left",
    paddingBottom: theme.spacing(4),
  },
  subHeading: {
    margin: theme.spacing(1, 0),
  },
  submitButton: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

function ForgotPassword(props = {}) {
  const { setUser, setAuthState, setLoading } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [formData, setFormData] = useState({});
  const [isPasswordSent, setPasswordSent] = useState(false);
  const [isTempPasswordSent, setTempPasswordSent] = useState(false);
  const [isNewPasswordSubmitted, setNewPasswordSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      const { data, error } = await resetPasswordApi(formData.email);
      if (data.continue_with_cognito) {
        forgotPassword(formData);
      } else {
        if (!error) {
          setTempPasswordSent(true);
        }

      }
    } catch (error) {
      console.error('error: ', error);
      styledSnackbar.error((error && error.message) || error);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    forgotPasswordSubmit(formData);
  }

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };


  async function forgotPassword(data) {
    setLoading(true);
    try {
      const user = await Auth.forgotPassword(data.email);
      setUser(user);
      setPasswordSent(true);
      setNewPasswordSubmitted(false);
    } catch (error) {
      console.error('error: ', error);
      setPasswordSent(false);
      setNewPasswordSubmitted(false);
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }
  async function forgotPasswordSubmit(data) {
    setLoading(true);
    try {
      const user = await Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword);
      setUser(user);
      setPasswordSent(true);
      setNewPasswordSubmitted(true);
      // AuthStateHandler(AuthState.ConfirmSignUp, user);
    } catch (error) {
      setNewPasswordSubmitted(false);
      console.error('error: ', error);
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const gotoLogin = () => {
    setAuthState(AuthState.SignIn);
  }

  const onBack = () => {
    setPasswordSent(false);
  }

  if (isPasswordSent && isNewPasswordSubmitted) {
    return (<Grid
      container
      spacing={5}
      item
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.headings}>
          <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Your Password has been Reset</Typography>
          <Typography color="grey300" variant="body1" className={classes.subHeading}>
            Click log in to continue.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.submitButton}
          color="secondary"
          title="Log In"
          onClick={gotoLogin}
        />
      </Grid>
    </Grid>);
  }
  if (isTempPasswordSent) {
    return (<Grid
      container
      spacing={5}
      item
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.headings}>
          <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>You've Got Mail!</Typography>
          <Typography color="grey300" variant="body1" className={classes.subHeading}>
            Login with temporary password sent on your email and set new password.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.submitButton}
          color="secondary"
          title="Log In"
          onClick={gotoLogin}
        />
      </Grid>
    </Grid>);
  }

  return (<>
    {!isPasswordSent ? <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Forgot Password?</Typography>
            <Typography color="grey300" variant="body1" className={classes.subHeading}>
              Enter the email address connected to your account. We'll send you instructions to reset your password.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            id="email"
            name="email"
            value={formData.email}
            label="Email Address"
            // placeholder="Email Address"
            required
            errorText="Required"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            type="submit"
            title="Send"
          />
          <Button
            variant="outlined"
            title="Back"
            onClick={gotoLogin}
          />
        </Grid>
      </Grid>
    </form> : <form onSubmit={handleForgotPasswordSubmit}>
      <Grid
        container
        spacing={4}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>You've Got Mail!</Typography>
            <Typography color="grey300" variant="body1" className={classes.subHeading}>
              A verification code has been emailed to you. Enter the code and set your new password below.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            id="code"
            name="code"
            value={formData.code}
            label="Verification Code"
            required
            errorText=""
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <NewPasswordInput
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            placeholder="New Password"
            required
            errorText=""
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            color="secondary"
            type="submit"
            title="Submit"
          />
          <Button
            variant="outlined"
            title="Back"
            onClick={onBack}
          />
        </Grid>
      </Grid>
    </form>}
  </>);
}


export default ForgotPassword;

