import { defineMessages } from 'react-intl';

export const scope = 'src.container.TeamMembers';

export default defineMessages({
  addATeamMember: {
    id: `${scope}.addATeamMember`,
    defaultMessage: '+ Add a Team Member',
    description: 'Add a Team Member'
  },
  addATeamMemberModal: {
    id: `${scope}.addATeamMemberModal`,
    defaultMessage: 'Add a Team Member',
    description: 'Add a Team Member'
  },
  teamMemberAddUpdatePhotoLabel: {
    id: `${scope}.teamMemberAddUpdatePhotoLabel`,
    defaultMessage: 'Team Member Photo (optional)',
    description: 'Team Member Photo (optional)'
  },
  teamMemberAddUpdateEmailLabel: {
    id: `${scope}.teamMemberAddUpdateEmailLabel`,
    defaultMessage: 'Work Email',
    description: 'Work Email'
  },
  nextButtonLabel: {
    id: `${scope}.nextButtonLabel`,
    defaultMessage: 'Next',
    description: 'Next'
  },
  confirmButtonLabel: {
    id: `${scope}.confirmButtonLabel`,
    defaultMessage: 'Confirm',
    description: 'Confirm'
  },
  noTeamMemberSelectedText: {
    id: `${scope}.noTeamMemberSelectedText`,
    defaultMessage: 'No Team Member selected',
    description: 'No Team Member selected heading',
  },
  noTeamMemberSelectedSubText: {
    id: `${scope}.noTeamMemberSelectedSubText`,
    defaultMessage: 'Select a Team Member to display their information here.',
    description: 'No Team Member selected sub heading',
  },
  SearchTeamMemberLabel: {
    id: `${scope}.SearchTeamMemberLabel`,
    defaultMessage: 'Search Team Member',
    description: 'Search Team Member',
  },
  previewBackButtonLabel: {
    id: `${scope}.previewBackButtonLabel`,
    defaultMessage: 'Team Member',
    description: 'Deselect Team Member',
  },
  tabAccounts: {
    id: `${scope}.tabAccounts`,
    defaultMessage: 'Accounts',
    description: 'Tab Accounts',
  },
  tabContacts: {
    id: `${scope}.tabContacts`,
    defaultMessage: 'Account Access',
    description: 'Tab Contacts',
  },
  smartLinkSettings: {
    id: `${scope}.smartLinkSettings`,
    defaultMessage: 'SmartSpend Settings',
    description: 'Tab Admin',
  },
  saveTeamMember: {
    id: `${scope}.saveTeamMember`,
    defaultMessage: 'Save',
    description: 'Save Team Member',
  },
  issueSmartLinkAccount: {
    id: `${scope}.issueSmartLinkAccount`,
    defaultMessage: 'Issue a SmartSpend Account',
    description: 'Issue a SmartSpend Account',
  },
  issue: {
    id: `${scope}.issue`,
    defaultMessage: 'Issue',
    description: 'Issue',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
    description: 'view',
  },
  issueSmartLinkAccountTxt: {
    id: `${scope}.issueSmartLinkAccountTxt`,
    defaultMessage: 'A business expense account for your team members that allow you to assign limits, see how money is being spent, and control card usage in an instant.',
    description: 'Issue a SmartSpend Account Text',
  },
  accessToAccounts: {
    id: `${scope}.accessToAccounts`,
    defaultMessage: 'Access to Accounts',
    description: 'Access to Accounts Label',
  },
  accessToAccountsTxt: {
    id: `${scope}.accessToAccountsTxt`,
    defaultMessage: 'Allow this team member to view all payments and accounts related to Proto Supply.',
    description: 'access To Accounts Txt',
  },
  viewOnly: {
    id: `${scope}.viewOnly`,
    defaultMessage: 'View Only',
    description: 'View Only',
  },
  viewOnlyTxt: {
    id: `${scope}.viewOnlyTxt`,
    defaultMessage: 'Allow this team member to view all contacts, plus view the transactions they initiated.',
    description: 'View Only Text',
  },
  teamMemberAccess: {
    id: `${scope}.teamMemberAccess`,
    defaultMessage: 'SmartSpend Only',
    description: 'View Only',
  },
  teamMemberAccessTxt: {
    id: `${scope}.teamMemberAccessTxt`,
    defaultMessage: 'Allow this team member to edit contact details, add notes, payment details, contacts, and view all transactions',
    description: 'View Only Text',
  },
  collaborate: {
    id: `${scope}.collaborate`,
    defaultMessage: 'Collaborate',
    description: 'collaborate',
  },
  collaborateTxt: {
    id: `${scope}.collaborateTxt`,
    defaultMessage: 'Allow this team member to access only their own SmartSpend account and transactions.',
    description: 'Collaborate Text',
  },
  disabledTeamMemberAccessTxt: {
    id: `${scope}.collaborateTxt`,
    defaultMessage: 'Issue SmartSpend account to enable this option.',
    description: 'Collaborate Text',
  },
  fullAccess: {
    id: `${scope}.fullAccess`,
    defaultMessage: 'Admin',
    description: 'Full Access',
  },
  fullAccessTxt: {
    id: `${scope}.fullAccessTxt`,
    defaultMessage: 'Allow this team member to have full access to all accounts, contacts, and to manage team members.',
    description: 'Full Access Text',
  },
  adminAccess: {
    id: `${scope}.adminAccess`,
    defaultMessage: 'Admin',
    description: 'Admin',
  },
  adminAccessTxt: {
    id: `${scope}.adminAccessTxt`,
    defaultMessage: 'Allow this team member to have full access to all accounts, contacts, and to manage team members.',
    description: 'Full Access Text',
  },
  member: {
    id: `${scope}.member`,
    defaultMessage: 'Member',
    description: 'Member',
  },
  memberTxt: {
    id: `${scope}.memberTxt`,
    defaultMessage: 'Members have restricted access based on permissions set in the Accounts and Contacts tab',
    description: 'Member Text',
  },
  superAdministrator: {
    id: `${scope}.superAdministrator`,
    defaultMessage: 'Super Administrator',
    description: 'Super Administrator',
  },
  superAdministratorTxt: {
    id: `${scope}.superAdministratorTxt`,
    defaultMessage: 'Super Administrators have full access to all accounts and features. Usually, only beneficial owners of a company need this level of access',
    description: 'Super Administrator Text',
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Payments',
    description: 'Payments',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Send',
    description: 'Send',
  },
  sendTxt: {
    id: `${scope}.sendTxt`,
    defaultMessage: 'Allow this team member to send payments to contacts.',
    description: 'Send Text',
  },
  receive: {
    id: `${scope}.receive`,
    defaultMessage: 'Receive',
    description: 'Receive',
  },
  receiveTxt: {
    id: `${scope}.receiveTxt`,
    defaultMessage: 'Allow this team member to receive and accept payments',
    description: 'Receive Text',
  },
  transfer: {
    id: `${scope}.transfer`,
    defaultMessage: 'Transfer',
    description: 'Transfer',
  },
  transferTxt: {
    id: `${scope}.transferTxt`,
    defaultMessage: 'Allow this team member to transfer between Truly Financial accounts and linked bank accounts',
    description: 'Transfer Text',
  },
  accountManagement: {
    id: `${scope}.accountManagement`,
    defaultMessage: 'Account Management',
    description: 'AccounT Management',
  },
  openAccounts: {
    id: `${scope}.openAccounts`,
    defaultMessage: 'Open Accounts',
    description: 'Open Accounts',
  },
  openAccountsTxt: {
    id: `${scope}.openAccountsTxt`,
    defaultMessage: 'Allow this team member to open additional Truly Financial checking and multi-currency accounts',
    description: 'Open Accounts Text',
  },
  deactivatingAccounts: {
    id: `${scope}.deactivatingAccounts`,
    defaultMessage: 'Deactivating Accounts',
    description: 'Deactivating Accounts',
  },
  deactivatingAccountsTxt: {
    id: `${scope}.deactivatingAccountsTxt`,
    defaultMessage: 'Allow this team member to deactivate Truly Financial multi-currency accounts',
    description: 'Deactivating Accounts Text',
  },
  linkingExternalAccounts: {
    id: `${scope}.linkingExternalAccounts`,
    defaultMessage: 'Linking External Accounts',
    description: 'Linking External Accounts',
  },
  linkingExternalAccountsTxt: {
    id: `${scope}.linkingExternalAccountsTxt`,
    defaultMessage: 'Allow this team member to link external bank accounts',
    description: 'Linking External Accounts Text',
  },
  smartlink: {
    id: `${scope}.smartlink`,
    defaultMessage: 'SmartSpend',
    description: 'SmartLink',
  },
  monthly_limit: {
    id: `${scope}.monthlyLimit`,
    defaultMessage: '/month Limit',
    description: 'monthly label',

  },
  smartlinkTxt: {
    id: `${scope}.smartlinkTxt`,
    defaultMessage: 'Issue an expense account to this team member',
    description: 'SmartLink Text',
  },
  debitingFromSelectLabel: {
    id: `${scope}.debitingFromSelectLabel`,
    defaultMessage: 'Debiting From',
    description: 'Debiting From',
  },
  secondaryDebitingAccountSelectLabel: {
    id: `${scope}.secondaryDebitingAccountSelectLabel`,
    defaultMessage: 'Secondary Debiting Account (optional)',
    description: 'Secondary Debiting Account (optional)',
  },
  nicknameAccountLabel: {
    id: `${scope}.nicknameAccountLabel`,
    defaultMessage: 'Nickname Account (optional)',
    description: 'Nickname Account (optional)',
  },
  monthlyLimit: {
    id: `${scope}.monthlyLimit`,
    defaultMessage: 'Monthly Limit',
    description: 'Monthly Limit',
  },
  transactionLimit: {
    id: `${scope}.transactionLimit`,
    defaultMessage: 'Transaction Limit',
    description: 'edit setting input label'
  },
  abilityToSendPayments: {
    id: `${scope}.abilityToSendPayments`,
    defaultMessage: 'Ability to Send Payments',
    description: 'Ability to Send Payments',
  },
  abilityToSendPaymentsTxt: {
    id: `${scope}.abilityToSendPaymentsTxt`,
    defaultMessage: 'Send payments to contacts using monthly limit',
    description: 'Ability to Send Payments Text',
  },
  eWalletCapabilities: {
    id: `${scope}.eWalletCapabilities`,
    defaultMessage: 'E-Wallet Capabilities',
    description: 'E-Wallet Capabilities',
  },
  eWalletCapabilitiesTxt: {
    id: `${scope}.eWalletCapabilitiesTxt`,
    defaultMessage: 'Apple Pay & Google Pay',
    description: 'Apple Pay & Google Pay',
  },
  issuePhysicalCard: {
    id: `${scope}.issuePhysicalCard`,
    defaultMessage: 'Issue Physical Card',
    description: 'Issue Physical Card',
  },
  issuePhysicalCardTxt: {
    id: `${scope}.issuePhysicalCardTxt`,
    defaultMessage: 'Will be sent by mail',
    description: 'Issue Physical Card Text',
  },
  dailyATMWithdrawlLimit: {
    id: `${scope}.dailyATMWithdrawlLimit`,
    defaultMessage: 'Daily ATM Withdrawl Limit',
    description: 'Daily ATM Withdrawl Limit',
  },
  noContactSelectedText: {
    id: `${scope}.noContactSelectedText`,
    defaultMessage: 'No Team Member selected',
    description: 'No contact selected heading',
  },
  noContactSelectedSubText: {
    id: `${scope}.noContactSelectedSubText`,
    defaultMessage: 'Select a Team-Member to display their information here.',
    description: 'No contact selected sub heading',
  },
  TeamMemberDeactivateTitle: {
    id: `${scope}.teamMemberDeactivateTitle`,
    defaultMessage: 'Deactivated',
    description: 'deactivated title',
  },
  TeamMemberDeactivateSubTitle: {
    id: `${scope}.TeamMemberDeactivateSubTitle`,
    defaultMessage: ' no longer has access to their account.',
    description: 'deactivated SubTitle',
  },
  TeamMemberDeactivateSubTitle2: {
    id: `${scope}.TeamMemberDeactivateSubTitle2`,
    defaultMessage: ' ’s account.',
    description: 'deactivated SubTitle',
  },

  TeamMemberPendingTitle: {
    id: `${scope}.TeamMemberPendingTitle`,
    defaultMessage: 'Pending',
    description: 'deactivated title',
  },
  TeamMemberPendingSubTitle: {
    id: `${scope}.TeamMemberPendingSubTitle`,
    defaultMessage: 'This team member still needs to activate their account.',
    description: 'deactivated SubTitle',
  },
  TeamMemberPendingStatusReSend: {
    id: `${scope}.TeamMemberPendingStatusReSend`,
    defaultMessage: ' Resend Invitation?',
    description: 'Resend button Title',
  },
  editContactMenuLabel: {
    id: `${scope}.editContactMenuLabel`,
    defaultMessage: 'Edit',
    description: 'preview contact menu item',
  },
  deactivateContactMenuLabel: {
    id: `${scope}.deactivateContactMenuLabel`,
    defaultMessage: 'Deactivate',
    description: 'preview contact menu item',
  },
  activeContactMenuLabel: {
    id: `${scope}.activeContactMenuLabel`,
    defaultMessage: 'Activate',
    description: 'preview contact menu item',
  },
  deactivateTeamMemberMenuTitle: {
    id: `${scope}.deactivateTeamMemberMenuTitle`,
    defaultMessage: 'Deactivate Team Member',
    description: 'preview contact menu item',
  },
  deactivateTeamMemberMenuSubTitle: {
    id: `${scope}.deactivateTeamMemberMenuSubTitle`,
    defaultMessage: 'Deactivating this team member will permanently remove their access to this account. Any non-initiated scheduled/recurring payments will be cancelled. SmartSpend and all associated cards will also be permanently deactivated.',
    description: 'preview contact menu item',
  },
  deactivateTeamMemberMenuWarningTxt: {
    id: `${scope}.deactivateTeamMemberMenuWarningTxt`,
    defaultMessage: 'Are you sure you would like to deactivate this Team Member?',
    description: 'preview contact menu item',
  },
  activateTeamMemberMenuTitle: {
    id: `${scope}.activateTeamMemberMenuTitle`,
    defaultMessage: 'Activate Team Member',
    description: 'preview contact menu item',
  },
  activateTeamMemberMenuSubTitle: {
    id: `${scope}.activateTeamMemberMenuSubTitle`,
    defaultMessage: 'Activate this Team Member to access account.',
    description: 'preview contact menu item',
  },
  activateTeamMemberMenuWarningTxt: {
    id: `${scope}.activateTeamMemberMenuWarningTxt`,
    defaultMessage: 'Are you sure you would like to activate this Team Member?',
    description: 'preview contact menu item',
  },
  accessLevel: {
    id: `${scope}.accessLevel`,
    defaultMessage: 'Access Level',
    description: 'Access label',
  },
  accessLevelSubTitle: {
    id: `${scope}.accessLevelSubTitle`,
    defaultMessage: 'Allow team member to view all payments and accounts.',
    description: 'Access label',
  },
  stackHolderIdVerificationTitle: {
    id: `${scope}.stackHolderIdVerificationTitle`,
    defaultMessage: 'Authorize Your',
    description: 'Stack holder Id Verification Title',
  },
  primaryIdVerificationTitle: {
    id: `${scope}.primaryIdVerificationTitle`,
    defaultMessage: 'Verify Your',
    description: 'Stack holder Id Verification Title',
  },
  stackHolderIdVerificationTitleBreak: {
    id: `${scope}.stackHolderIdVerificationTitleBreak`,
    defaultMessage: 'Truly Financial Account',
    description: 'Stack holder Id Verification Title',
  },
  stackHolderIdVerificationDesc: {
    id: `${scope}.stackHolderIdVerificationDesc`,
    defaultMessage: 'Someone has created an account on behalf of your business. We require you to verify your ID and confirm that the following statement is accurate and approved.',
    description: 'Stack holder Id Verification Description',
  },
  primaryIdVerificationDesc: {
    id: `${scope}.primaryIdVerificationDesc`,
    defaultMessage: 'We require you to verify your ID and provide confirmation that the following statement is accurate:',
    description: 'Stack holder Id Verification Description',
  },
  stackHolderIdVerificationTermCon: {
    id: `${scope}.stackHolderIdVerificationTermCon`,
    defaultMessage: 'I consent that',
    description: 'Stack holder Id Verification Description',
  },
  primaryIdVerificationTermCon: {
    id: `${scope}.primaryIdVerificationTermCon`,
    defaultMessage: 'I consent to the statement above.',
    description: 'Stack holder Id Verification Description ',
  },
  stackHolderIdVerificationTermConBreak: {
    id: `${scope}.stackHolderIdVerificationTermCon`,
    defaultMessage: 'is authorized to set up and make payments through Truly Financial on behalf of',
    description: 'Stack holder Id Verification Description',
  },
  stackHolderIdVerificationTermConBtn: {
    id: `${scope}.stackHolderIdVerificationTermConBtn`,
    defaultMessage: 'Continue',
    description: 'Stack holder Id Verification Button title',
  },
  stackHolderIdVerificationStep1Title: {
    id: `${scope}.stackHolderIdVerificationStep1Title`,
    defaultMessage: 'Authorize',
    description: 'Stack holder Id Verification title',
  },
  primaryContactIdVerificationStep1Title: {
    id: `${scope}.primaryContactIdVerificationStep1Title`,
    defaultMessage: 'Authorize Your Business',
    description: 'primary Id Verification title',
  },
  stackHolderIdVerificationStep1Des: {
    id: `${scope}.stackHolderIdVerificationStep1Des`,
    defaultMessage: 'Please complete the fields below.',
    description: 'Stack holder Id Verification title',
  },
  stackHolderIdVerificationStep1SubTitle: {
    id: `${scope}.stackHolderIdVerificationStep1SubTitle`,
    defaultMessage: 'from',
    description: 'Stack holder Id Verification title',
  },
  primaryVerificationStep1SubTitle: {
    id: `${scope}.primaryVerificationStep1SubTitle`,
    defaultMessage: 'For fraud prevention purposes Truly Financial needs a few details from you in order to verify your ID',
    description: 'Stack holder Id Verification title',
  },
  primaryVerificationStep1Caption: {
    id: `${scope}.primaryVerificationStep1Caption`,
    defaultMessage: ' Please complete the fields below to launch your account.',
    description: 'primary holder Id Verification caption',
  },

  stackHolderIdVerificationStep1SubTitleBreak: {
    id: `${scope}.stackHolderIdVerificationStep1SubTitle`,
    defaultMessage: 'has created an account with Truly Financial and needs a few details from you in order to verify.',
    description: 'Stack holder Id Verification title',
  },
  stackHolderIdVerificationResidentialAddress: {
    id: `${scope}.stackHolderIdVerificationResidentialAddress`,
    defaultMessage: 'Residential Address',
    description: 'Stack holder Id Verification title',
  },
  teamMemberIdVerificationStep1Title: {
    id: `${scope}.teamMemberIdVerificationStep1Title`,
    defaultMessage: ' Welcome to Truly Financial!',
    description: 'team member Id Verification title',
  },
  teamMemberIdVerificationStep1SubTitleBreak: {
    id: `${scope}.teamMemberIdVerificationStep1SubTitleBreak`,
    defaultMessage: 'Great news!',
    description: 'team member Id Verification title',
  },
  teamMemberIdVerificationStep1SubTitle: {
    id: `${scope}.teamMemberIdVerificationStep1SubTitle`,
    defaultMessage: 'has added you to their Truly Financial account.',
    description: 'team member Id Verification title',
  },
  submittedVerificationResidentialTitle: {
    id: `${scope}.stackHolderIdVerificationResidentialAddress`,
    defaultMessage: 'ID Verification Complete',
    description: 'Stack holder Id Verification title',
  },
  acceptInvitation: {
    id: `${scope}.acceptInvitation`,
    defaultMessage: 'You have been invited to join',
    description: 'Stack holder Id Verification title',
  },
  submittedVerificationInvitationAccept: {
    id: `${scope}.submittedVerificationResidentialSubTitle`,
    defaultMessage: 'You have successfully accepted the invitation',
    description: 'Stack holder Id Verification title',
  },
  idVerificationTeamMemberInitialsBodyTxt: {
    id: `${scope}.idVerificationTeamMemberInitialsBodyTxt`,
    defaultMessage: ' needs to verify your ID quickly before giving you access to their account.',
    description: 'IdVerification TeamMember Initials Body Text',
  },
  submittedVerificationResidentialSubtileTitle: {
    id: `${scope}.submittedVerificationResidentialSubtileTitle`,
    defaultMessage: `You may return to Truly Financial while we validate this information.`,
    description: 'Stack holder Id Verification title',
  },
  submittedVerificationResidentialSubtileTitleDialog: {
    id: `${scope}.submittedVerificationResidentialSubtileTitleDialog`,
    defaultMessage: `You may close this while we validate this information.`,
    description: 'Stack holder Id Verification title',
  },
  submittedVerificationStackHolderApproval: {
    id: `${scope}.submittedVerificationStackHolderApproval`,
    defaultMessage: `Your information has been received,`,
    description: 'Stack holder Id Verification title',
  },
  submittedVerificationStackHolderApprovalBreak: {
    id: `${scope}.submittedVerificationStackHolderApproval`,
    defaultMessage: `Thank you!`,
    description: 'Stack holder Id Verification title',
  },
  confidentialAgreementTitle: {
    id: `${scope}.confidentialAgreementTitle`,
    defaultMessage: `Before We Begin...`,
    description: 'Stack holder Id Verification title',
  },

  confidentialAgreementSubTitle: {
    id: `${scope}.confidentialAgreementSubTitle`,
    defaultMessage: 'U.S. Federal Law requires all financial institutions to obtain, verify, and record information identifying each person who opens an account.',
    description: 'label of onBoardingStep1',
  },
  confidentialAgreementContinue: {
    id: `${scope}.confidentialAgreementContinue`,
    defaultMessage: 'Continue',
    description: 'label of onBoardingStep1',
  },
  idVerificationPreInstructionBtnName: {
    id: `${scope}.idVerificationPreInstructionBtnName`,
    defaultMessage: 'Got It',
    description: 'label of onBoardingStep1',
  },
  TeamMemberIdVerificationInitialTitle: {
    id: `${scope}.TeamMemberIdVerificationInitialTitle`,
    defaultMessage: 'Activate your Account',
    description: 'title of TeamMember initial',
  },
  TeamMemberIdVerificationInitialBody1: {
    id: `${scope}.TeamMemberIdVerificationInitialBody1`,
    defaultMessage: 'Great news! Your employer',
    description: 'body of TeamMember initial',
  },
  TeamMemberIdVerificationInitialBody2: {
    id: `${scope}.TeamMemberIdVerificationInitialBody2`,
    defaultMessage: 'has created an account for you on Truly Financial.',
    description: 'body of TeamMember initial',
  },
  TeamMemberIdVerificationInitialBodyItem1: {
    id: `${scope}.TeamMemberIdVerificationInitialBodyItem1`,
    defaultMessage: 'Easily manage your work expenses using your Truly Visa Card',
    description: 'bodyItems of TeamMember initial',
  },
  TeamMemberIdVerificationInitialBodyItem2: {
    id: `${scope}.TeamMemberIdVerificationInitialBodyItem2`,
    defaultMessage: 'Create virtual cards to separate out expense categories',
    description: 'bodyItems of TeamMember initial',
  },
  TeamMemberIdVerificationInitialBodyItem3: {
    id: `${scope}.TeamMemberIdVerificationInitialBodyItem3`,
    defaultMessage: 'Send payments to vendors and suppliers!',
    description: 'bodyItems of TeamMember initial',
  },
  TeamMemberIdVerificationInitialCondition: {
    id: `${scope}.TeamMemberIdVerificationInitialCondition`,
    defaultMessage: ' I agree to the',
    description: 'bodyItems of TeamMember initial',
  },
  TeamMemberIdVerificationInitialConditionHalf: {
    id: `${scope}.TeamMemberIdVerificationInitialConditionHalf`,
    defaultMessage: 'of Truly Financial',
    description: 'bodyItems of TeamMember initial',
  },
  TeamMemberIdVerificationInitialConditions: {
    id: `${scope}.TeamMemberIdVerificationInitialConditions`,
    defaultMessage: 'Terms and Conditions',
    description: 'bodyItems of TeamMember initial',
  },
  TeamMemberIdVerificationInitialPrivacyPolicy: {
    id: `${scope}.TeamMemberIdVerificationInitialPrivacyPolicy`,
    defaultMessage: 'Privacy Policy',
    description: 'bodyItems of TeamMember initial',
  },
  idVerificationConfirmDetailsTitle: {
    id: `${scope}.idVerificationConfirmDetailsTitle`,
    defaultMessage: 'Confirm Your Details',
    description: 'confirm details Verification title',
  },
  idVerificationConfirmDetailsSubTitle: {
    id: `${scope}.idVerificationConfirmDetailsSubTitle`,
    defaultMessage: ', Confirm or update the details below to continue verification for Truly Financial account for ',
    description: 'confirm details Verification title',
  },
  idVerificationConfirmDetailsSubTitle2: {
    id: `${scope}.idVerificationConfirmDetailsSubTitle2`,
    defaultMessage: 'Please ensure the entered information is correct, and fill-in any outstanding, highlighted fields.',
    description: 'confirm details Verification title',
  },
  idVerificationDontHaveTitle: {
    id: `${scope}.idVerificationDontHaveTitle`,
    defaultMessage: 'Don’t Worry, We’ll Remind You',
    description: 'confirm details Verification title',
  },
  idVerificationConfirmDetailsBody: {
    id: `${scope}.idVerificationConfirmDetailsBody`,
    defaultMessage: 'You’ll need to upload proof of residency in order to complete your application. We’ll follow up with an email shortly incase this slips your mind.',
    description: 'confirm details Verification title',
  },
  idVerificationDontHaveDialogTitle: {
    id: `${scope}.idVerificationDontHaveDialogTitle`,
    defaultMessage: `Don't Have Document`,
    description: 'confirm details Verification title',
  },
});
