import React from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { selectSnackbar } from 'store/Snackbar/snackbarSelectors';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actionCloseSnackbar, actionShowSnackbar } from 'store/Snackbar/snackbarActions';
// import { store } from '../../app';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackBarBg: {

    "& .MuiAlert-filledSuccess": {
      backgroundColor: `${theme.palette.successGreen} !important`
    },
  }
}));

function ReduxSnackbar(props = {}) {
  const { show, message, severity } = props.selectSnackbar || {};
  const classes = useStyles();

  // const handleClick = () => {
  //   props.actionShowSnackbar();
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.actionCloseSnackbar();
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={show}
        autoHideDuration={5000}
        onClose={handleClose}
        className={classes.snackBarBg}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}


const mapStateToProps = createStructuredSelector({
  selectSnackbar: selectSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return {
    actionShowSnackbar: data => dispatch(actionShowSnackbar(data)),
    actionCloseSnackbar: data => dispatch(actionCloseSnackbar(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

function snackTrigger(data, store) {
  store.dispatch(actionCloseSnackbar(data));
  data && setTimeout(() => {
    store.dispatch(actionShowSnackbar(data));
  }, 100);
}

export function showSnackbar(data) {
  let store = null;
  if (!store) {
    import('store').then((app) => {
      store = app.store;
      snackTrigger(data, store);
    });
  } else {
    snackTrigger(data, store);
  }
  // store.dispatch(actionShowSnackbar(data))
}

export const styledSnackbar = {
  success: (message) => showSnackbar({ message, severity: 'success', color: 'primary' }),
  error: (message) => showSnackbar({ message, severity: 'error' }),
  warning: (message) => showSnackbar({ message, severity: 'warning' }),
  info: (message) => showSnackbar({ message, severity: 'info' }),
  close: () => showSnackbar(null),
};

export default compose(
  withConnect,
)(ReduxSnackbar);

