/* eslint-disable eqeqeq */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { AppBar, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
// import useCommonStyles from 'styles';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles((theme) => ({
  root: {},
  notificationHeight: {
    // height: "50px",
    backgroundColor: "#FFFFFF",
  },
  appBarNotification: {
    // color: "#FFFFFF",
    backgroundColor: "#F6CF51",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  notificationTxt: {
    // wordBreak: "break-all",
    wordBreak: "break-word",
  },
}));

function HeaderNotification(props = {}) {
  const { showNotification, updateUserLocalSettings } = props;
  const classes = useStyles(props);

  const setShowNotification = () => {
    updateUserLocalSettings({
     body: {
      hideNotification: 1
     }
    })
  }
  if (!showNotification) {
    return null
  }
  return (
    <AppBar
        color="secondary"
        position="sticky"
        className={classes.appBarNotification}
      >
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.notificationTxt} variant="body1" color="inherit">
            <strong>SmartSpend is temporarily unavailable.</strong> This maintenance period may take a few weeks and we will notify you promptly upon its return to service. Thank you for your patience.
          </Typography>
          <IconButton onClick={setShowNotification} edge="end" color="inherit">
            <CloseOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
  );
  // const commonClasses = useCommonStyles(props);
}

HeaderNotification.defaultProps = {
  test: " HeaderNotification component ",
};

HeaderNotification.propTypes = {
  test: PropTypes.string,
};

export default memo(HeaderNotification);
