/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { CircularProgress, makeStyles, Typography, useTheme, useMediaQuery } from '@material-ui/core';
// import useCommonStyles from 'styles';


const useStyles = makeStyles((theme) => ({
  idRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 12,
    bottom: 0,
    left: 0,
    right: 0,
    alignItem: "center",
  },
  pageCenter: {
    textAlign: "center",
    position: "fixed",
    right: 0,
    top: '50%',
    left: 0,
    zIndex: 900,

  },
  componentCenter: {
    textAlign: "center",
    position: "absolute",
    // position: "fixed",
    right: 0,
    top: '47%',
    // left: theme.drawerWidth,
    left: 0,
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      left: 0,
    },
    zIndex: 900,
  },

  loadingTxt: {
    margin: theme.spacing(4, 0, 0, 0)
  },
  root: {
    position: 'relative',
  },
  bottom: {
    position: 'absolute',
    // color: theme.palette.grey[200],
    color: theme.palette.secondary[200],
  },
  top: {
    color: theme.palette.secondary.main,
    animationDuration: '550ms',
    // position: 'absolute',
    // left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function Loader(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const { id, center, loadingTxt, componentCenter = false, } = props;
  const classes = useStyles(props);
  const [parentHeight, setParentHeight] = useState(0);
  const [parentWidth, setParentWidth] = useState(0);
  const [parentTop, setParentTop] = useState(0);
  const [parentLeft, setParentLeft] = useState(0);
  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (id) {
      const height = document.getElementById(id).clientHeight;
      const width = document.getElementById(id).clientWidth;
      const offsets = document.getElementById(id).getBoundingClientRect();
      const top = offsets.top;
      const left = offsets.left;
      setParentHeight(height);
      setParentWidth(width);
      setParentTop(top);
      setParentLeft(left);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<>
    {
      props.id && mdMatches ? <>
        <div
          className={classes.idRoot}
          style={{ height: parentHeight, marginLeft: parentLeft }}
        >
          <CircularProgress
            variant="determinate"
            className={classes.bottom}
            size={40}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.top}
            classes={{
              circle: classes.circle,
            }}
            size={40}
            thickness={4}
            {...props}
          />
          {
            loadingTxt && <Typography className={classes.loadingTxt} variant="subtitle1" color="grey700" >{loadingTxt}</Typography>
          }
        </div>
      </> :
        <div className={componentCenter ? classes.componentCenter : classes.pageCenter} >
          {/* <CircularProgress variant="determinate" value={100} color="secondary" /> */}
          <div className={classes.root}>
            <CircularProgress
              variant="determinate"
              className={classes.bottom}
              size={40}
              thickness={4}
              {...props}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              className={classes.top}
              classes={{
                circle: classes.circle,
              }}
              size={40}
              thickness={4}
              {...props}
            />
          </div>
          {
            loadingTxt && <Typography className={classes.loadingTxt} variant="subtitle1" color="grey700" >{loadingTxt}</Typography>
          }
        </div>
    }


  </>);
}

Loader.defaultProps = {
  // test: " Loader component ",
};

Loader.propTypes = {
  // test: PropTypes.string,
};

export default memo(Loader);
