import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, IconButton, makeStyles, Paper } from '@material-ui/core';
import { Icon, Typography } from 'components/ui';
import AddIcon from '@material-ui/icons/Add';
import StyledDate from 'components/StyledDate';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Loader } from 'components';

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    height: theme.spacing(20),
    width: theme.spacing(29.25),
    borderRadius: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: props.type === "Add" ? "#DBEDF5" : theme.palette.grey[50],
    alignItems: props.type === "Add" ? "center" : "none",
    justifyContent: props.type === "Add" ? "center" : "none",
    border:
      props.type === "Add"
        ? `1px solid ${theme.palette.accent[theme.palette.type]}`
        : "none",
    position: "relative",
  }),
  headingDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(1.5, 1, 1, 2),
    "& .MuiIconButton-root": {
      padding: 0,
      color: theme.palette.grey[300],
    },
  },
  bottomDiv: {
    margin: theme.spacing(0, 1),
    position: "absolute",
    bottom: theme.spacing(1.5),
  },
  bottomDivPreview: {
    position: "absolute",
    bottom: theme.spacing(2),
    width: "89%",
    background: theme.palette.background.paper,
    padding: theme.spacing(1, 0),
    margin: theme.spacing(-1, 0),
  },
  bottomSubDiv: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(0.5),
    alignItems: "center",
  },

  icon: {
    fontSize: theme.spacing(1.875),
    color: theme.palette.primary.contrastText,
  },

  plusIcon: {
    fontSize: theme.spacing(5),
    marginBottom: theme.spacing(1),
    color: theme.palette.accent[theme.palette.type],
  },
  addNewTxt: {
    color: theme.palette.accent[theme.palette.type],
  },
  clickableDiv: {
    cursor: "pointer",
  },
  noteDiv: {
    margin: theme.spacing(0, 2),
    height: theme.spacing(9),
    overflow: "hidden",
    color: theme.palette.grey[600],

    display: "-webkit-box",
    '-webkitLineClamp': 4,
    '-webkitBoxOrient': "vertical",
  },
  noteDivPreview: {
    margin: theme.spacing(2, 2, 6),
    height: theme.spacing(17.5),
    overflow: "auto",
  },
  createdByImage: {
    margin: theme.spacing(0, 1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  time: {
    color: theme.palette.grey[300],
  },
  createdByName: {
    color: theme.palette.grey[400],
  },
  dialogPadding: {
    paddingTop: theme.spacing(2.5),
  },
}));

function NoteBox(props = {}) {
  const classes = useStyles(props);
  const { data, type, onClickNote, onAddNew,
    onOptionMenuHandler, isPreview, addNewText, noteState = {}
  } = props;

  const onSelect = () => {
    onClickNote(data);
  };

  const onOptionMenu = (event) => {
    onOptionMenuHandler(event, data);
  };

  if (type === 'Add') {
    return (<Paper onClick={onAddNew} elevation={0} className={classes.paper}>
      <AddIcon className={classes.plusIcon} />
      <Typography className={classes.addNewTxt}>{addNewText}</Typography>
    </Paper>);
  }

  return (
    <Paper elevation={0} onClick={() => null} className={!isPreview ? classes.paper : undefined}>
      <div className={classes.headingDiv}>
        <Typography variant="subtitle2">
          <StyledDate date={data.created_at} format="DD MMM" /> &nbsp;
          <Typography className={classes.time} variant="caption"><StyledDate date={data.created_at} format="h:mm A" /></Typography>
        </Typography>
        {!isPreview &&
          <IconButton aria-controls="option-menu" aria-haspopup="true" onClick={onOptionMenu}>
            <Icon component={MoreVertIcon} />
          </IconButton>}
      </div>
      <div className={!isPreview ? classes.clickableDiv : undefined} onClick={onSelect}>
        <div className={isPreview ? classes.noteDivPreview : classes.noteDiv}>
          <Typography variant="body2">{data.note}</Typography>
        </div>
        <div className={!isPreview ? classes.bottomDiv : classes.bottomDivPreview}>
          <div className={classes.bottomSubDiv} >
            <Avatar className={classes.createdByImage} src={data.created_by_image_url} />
            <Typography className={classes.createdByName} variant="body2">{data.created_by_first_name} {data.created_by_last_name}</Typography>
          </div>
        </div>
      </div>
      {noteState && noteState.loading && <Loader />}
    </Paper>
  );
}
NoteBox.defaultProps = {
  data: {},
  onClickNote: () => null,
  onAddNew: () => null,
  onOptionMenuHandler: () => null,
  isPreview: false,
  addNewText: 'Add Note',
}

NoteBox.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  onSelect: PropTypes.func,
};

export default memo(NoteBox);
