// eslint-disable-next-line no-unused-vars
import React, { memo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Link, makeStyles } from '@material-ui/core';
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // position: "absolute",
    // left: 0,
    // right: 0,
    // bottom: "5%",
  },
  link: {
    color: theme.palette.hyperlink.main,
    cursor: "pointer",
  }
}));

function FaqLinkFooter(props = {}) {
  // const commonClasses = useCommonStyles(props);
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(props);
  // const { test } = props;
  return null;
  // return (<div className={classes.root}>
  //       For all other inquiries, visit our <Link id="guideFaq" className={classes.link}>FAQs Page</Link>
  //   </div>);
}

FaqLinkFooter.defaultProps = {
  test: " FaqLinkFooter component ",
};

FaqLinkFooter.propTypes = {
  test: PropTypes.string,
};

export default memo(FaqLinkFooter);
