const CONTACT_ACCOUNTS = {
    code: "CONTACT_ACCOUNTS",
    name: "Contact Account",
    path: "",
    functions: {
        CONTACT_ACCOUNT_LIST: {
            code: "CONTACT_ACCOUNT_LIST",
            name: "Contact Account List",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_ACCOUNTS.path}${CONTACT_ACCOUNTS.functions.CONTACT_ACCOUNT_LIST.path}`,
            getFullPath: () => `${CONTACT_ACCOUNTS.path}${CONTACT_ACCOUNTS.functions.CONTACT_ACCOUNT_LIST.path}`,
        },
        CONTACT_ACCOUNT_DETAILS: {
            code: "CONTACT_ACCOUNT_DETAILS",
            name: "Contact Account Details",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_ACCOUNTS.path}${CONTACT_ACCOUNTS.functions.CONTACT_ACCOUNT_DETAILS.path}`,
            getFullPath: (accountId) => `${CONTACT_ACCOUNTS.path}${CONTACT_ACCOUNTS.functions.CONTACT_ACCOUNT_DETAILS.path}`.replace(
                ":accountId",
                accountId
            ),
        },
        CONTACT_ACCOUNT_CREATE: {
            code: "CONTACT_ACCOUNT_CREATE",
            name: "Contact Account Create",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT_ACCOUNTS.path}${CONTACT_ACCOUNTS.functions.CONTACT_ACCOUNT_CREATE.path}`,
            getFullPath: (accountId) => `${CONTACT_ACCOUNTS.path}${CONTACT_ACCOUNTS.functions.CONTACT_ACCOUNT_CREATE.path}`.replace(
                ":accountId",
                accountId
            ),
        },

    },


};

export default CONTACT_ACCOUNTS;
