/*
 * StyledText Messages
 *
 * This contains all the text for the StyledText component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.previewBack';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the PreviewBack component!',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  }
});
