
import { defineMessages } from 'react-intl';

export const scope = 'src.components.payment_status';

export default defineMessages({
    payment: {
        id: `${scope}.payment`,
        defaultMessage: 'Payment',
    },
    successful: {
        id: `${scope}.Successful`,
        defaultMessage: 'Initiated!',
    },
    scheduled: {
        id: `${scope}.scheduled`,
        defaultMessage: 'Scheduled!',
    },
    payment_qued: {
        id: `${scope}.payment_qued`,
        defaultMessage: 'Payment Queued',
    },
    pending_subText_one: {
        id: `${scope}.pending_subText_one`,
        defaultMessage: 'Your selected account has insufficient funds.',
    },

    oops: {
        id: `${scope}.oops`,
        defaultMessage: 'Oops!',
    },
    failSubTxt_one: {
        id: `${scope}.failSubTxt_one`,
        defaultMessage: 'Something went wrong please try again',
    },
    failSubTxt_two: {
        id: `${scope}.failSubTxt_two`,
        defaultMessage: 'Please try again',
    }
});
