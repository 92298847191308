const ACCOUNT_ACCESS = {
    code: "ACCOUNT_ACCESS",
    name: "Account Access",
    path: "",
    functions: {
        USER_CREATE: {
            code: "USER_CREATE",
            name: "Create User",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_CREATE.path}`,
            getFullPath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_CREATE.path}`,
        },
        TEAM_MEMBERS_ACCOUNT_ACCESS: {
            code: "TEAM_MEMBERS_ACCOUNT_ACCESS",
            name: "Account Access",
            isPage: false,
            component: '',
            path: "/:entity_person_id/roles",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.TEAM_MEMBERS_ACCOUNT_ACCESS.path}`,
            getFullPath: (entityPersonId) =>
                `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.TEAM_MEMBERS_ACCOUNT_ACCESS.path}`.replace(
                    ":entityPersonId",
                    entityPersonId
                ),
        },
        USER_PHONE_UPDATE: {
            code: "USER_PHONE_UPDATE",
            name: "Update User Phone",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_PHONE_UPDATE.path}`,
            getFullPath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_PHONE_UPDATE.path}`,
        },
        USER_DETAILS: {
            code: "USER_DETAILS",
            name: "User Info",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_DETAILS.path}`,
            getFullPath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_DETAILS.path}`,
        },
        USER_ENTITIES: {
            code: "USER_ENTITIES",
            name: "User Entites",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ENTITIES.path}`,
            getFullPath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ENTITIES.path}`,
        },
        USER_DUPLICATE_INFO: {
            code: "USER_DUPLICATE_INFO",
            name: "User Duplicate Info",
            isPage: false,
            component: '',
            path: "/:email",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_DUPLICATE_INFO.path}`,
            getFullPath: (email) =>
                `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_DUPLICATE_INFO.path}`.replace(
                    ":email",
                    email
                ),
        },
        USER_ROLE_LIST: {
            code: "USER_ROLE_LIST",
            name: "USer Role List ",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_LIST.path}`,
            getFullPath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_LIST.path}`,
        },
        USER_ROLE_CREATE: {
            code: "USER_ROLE_CREATE",
            name: "Create User Role",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_CREATE.path}`,
            getFullPath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_CREATE.path}`,
        },
        USER_ROLE_DETAILS: {
            code: "USER_ROLE_DETAILS",
            name: "Details User Role",
            isPage: false,
            component: '',
            path: "/:roleId",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_DETAILS.path}`,
            getFullPath: (roleId) =>
                `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_DETAILS.path}`.replace(
                    ":roleId",
                    roleId
                ),
        },
        USER_ROLE_UPDATE: {
            code: "USER_ROLE_UPDATE",
            name: "Details User Role",
            isPage: false,
            component: '',
            path: "/:role_id",
            getRoutePath: () => `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_UPDATE.path}`,
            getFullPath: (roleId) =>
                `${ACCOUNT_ACCESS.path}${ACCOUNT_ACCESS.functions.USER_ROLE_UPDATE.path}`.replace(
                    ":roleId",
                    roleId
                ),
        },

    },
};

export default ACCOUNT_ACCESS;
