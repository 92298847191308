import produce from 'immer';
import {
  RESET_SEND_PAYMENT,
  PRE_SEND_PAYMENT,
  PRE_SEND_PAYMENT_SET_DATA,
  SEND_PAYMENT_NEW,
  SEND_PAYMENT_SET_DATA_NEW
} from './sendPaymentConstants';

export const initialState = {
  sendPaymentState: { data: {}, loading: false, error: '' },
  preSendPaymentState: { data: {}, loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const sendPaymentReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_SEND_PAYMENT:
        draft.sendPaymentState = {...initialState.sendPaymentState};
        draft.preSendPaymentState = {...initialState.preSendPaymentState};
        break;
      case PRE_SEND_PAYMENT:
        draft.preSendPaymentState = {
          ...draft.preSendPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case PRE_SEND_PAYMENT_SET_DATA:
        draft.preSendPaymentState = {
          ...draft.preSendPaymentState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case SEND_PAYMENT_NEW:
        draft.sendPaymentState = {
          ...draft.sendPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case SEND_PAYMENT_SET_DATA_NEW:
        draft.sendPaymentState = {
          ...draft.sendPaymentState,
          ...action.data, loading: false, loaded: true,
        };
        break;
    }
  });

export default sendPaymentReducer;
