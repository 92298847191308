import { call, put, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { request } from 'utils/request';
import {
  // resetReferencesAction,
  getCountryListSetDataAction,
  getRelationshipListSetDataAction,
  getCurrencyListSetDataAction,
  getPurposeListSetDataAction,
  searchLocationSetDataAction,
  getBankAccountConfigSetDataAction,
  getStateListSetDataAction,
  getPaymentStatusSetDataAction,
  getRoleListSetDataAction,
  getJurisdictionListSetDataAction,
  getBusinessTypeListSetDataAction,
  getNumberOfEmployeesListSetDataAction,
  getIndustriesListSetDataAction,
  getBusinessBelongListSetDataAction,
  getPrimaryUseCaseListSetDataAction,
  getPrimarySourceOfFundingListSetDataAction,
  getDocumentTypeListSetDataAction,
  getAccessRoleListSetDataAction
} from './referenceActions';
import {
  // RESET_REFERENCES,
  GET_COUNTRY_LIST,
  GET_RELATIONSHIP_LIST,
  GET_CURRENCY_LIST,
  GET_PURPOSE_LIST,
  SEARCH_LOCATION,
  GET_BANK_ACCOUNT_CONFIG,
  GET_STATE_LIST,
  GET_PAYMENT_STATUS,
  GET_ROLE_LIST,
  GET_JURISDICTION_LIST,
  GET_BUSINESS_TYPE_LIST,
  GET_NUMBER_OF_EMPLOYEES_LIST,
  GET_INDUSTRIES_LIST,
  GET_BUSINESS_BELONG_LIST,
  GET_PRIMARY_USE_CASE_LIST,
  GET_PRIMARY_SOURCE_OF_FUNDING_LIST,
  GET_DOCUMENT_TYPE_LIST,
  GET_ACCESS_ROLE_LIST
} from './referenceConstants';

import {
  REFERENCES_BASE_API_URL,
} from 'config/apiUrls';

const REFERENCES_URL = `${REFERENCES_BASE_API_URL}`;

export default function* referencesSaga() {
  // add all sagas here
  yield takeEvery(GET_STATE_LIST, getStateListSaga);
  yield takeLatest(GET_BANK_ACCOUNT_CONFIG, getBankAccountConfigSaga);
  yield takeLatest(SEARCH_LOCATION, searchLocationSaga);
  yield takeLatest(GET_PURPOSE_LIST, getPurposesSaga);
  yield takeLatest(GET_CURRENCY_LIST, getCurrenciesSaga);
  yield takeLeading(GET_RELATIONSHIP_LIST, getRelationshipsSaga);
  yield takeLeading(GET_COUNTRY_LIST, getCountriesSaga);
  yield takeLeading(GET_PAYMENT_STATUS, getPaymentStatusSaga);
  yield takeLeading(GET_ROLE_LIST, getRoleListSaga);
  yield takeLatest(GET_JURISDICTION_LIST, getJurisdictionListSaga);
  yield takeLatest(GET_BUSINESS_TYPE_LIST, getBusinessTypeListSaga);
  yield takeLatest(GET_NUMBER_OF_EMPLOYEES_LIST, getNumberOfEmployeesListSaga);
  yield takeLatest(GET_INDUSTRIES_LIST, getIndustriesListSaga);
  yield takeLatest(GET_BUSINESS_BELONG_LIST, getBusinessBelongListSaga);
  yield takeLatest(GET_PRIMARY_USE_CASE_LIST, getPrimaryUseCaseListSaga);
  yield takeLatest(GET_PRIMARY_SOURCE_OF_FUNDING_LIST, getPrimarySourceOfFundingListSaga);
  yield takeLatest(GET_DOCUMENT_TYPE_LIST, getDocumentTypeListSaga);
  yield takeLatest(GET_ACCESS_ROLE_LIST, getAccessRoleListSaga);
}

export function* getCountriesSaga(args = {}) {
  // const { id } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/countries`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCountryListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getRelationshipsSaga(args = {}) {
  // const { id } = args.data || {};
  // const requestUrl = `${REFERENCES_URL}/countries`;
  const requestUrl = `${REFERENCES_URL}/payee-relationships`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getRelationshipListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getCurrenciesSaga(args = {}) {
  // const { id } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/currencies`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCurrencyListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getPurposesSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/payment-purposes`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getPurposeListSetDataAction(result));
    // if (Array.isArray(result.data)) cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* searchLocationSaga(args = {}) {
  const { searchString, compId, cb = () => null, countryCode } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/search-locations?address=${searchString}&country_code=${countryCode}`;
  try {
    const result = yield call(request, requestUrl);
    if (!Array.isArray(result.data)) {
      result.data = [];
    }
    yield put(searchLocationSetDataAction({ ...result, compId }));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getBankAccountConfigSaga(args = {}) {
  const { params = {} } = args.data || {};
  const paramBankCountry = params.bank_country_code ? `&bank_country_code=${params.bank_country_code}` : '';
  const paramBankCurrency = params.currency_code ? `&currency_code=${params.currency_code}` : '';
  const paramPaymentType = params.payment_type ? `&payment_type=${params.payment_type}` : '';
  const forContact = '&for_contact=1'
  const requestUrl = `${REFERENCES_URL}/bank-account-fields?${paramBankCountry}${paramBankCurrency}${paramPaymentType}${forContact}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getBankAccountConfigSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getStateListSaga(args = {}) {
  const { countryCode } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/states?country_code=${countryCode}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getStateListSetDataAction({ ...result, countryCode }));
  } catch (error) {
    console.error(error);
  }
}

export function* getPaymentStatusSaga(args = {}) {
  //const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/payment-statuses`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getPaymentStatusSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getRoleListSaga(args = {}) {
  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/designations`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getRoleListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
export function* getJurisdictionListSaga(args = {}) {
  const { countryCode } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/states?country_code=${countryCode}&is_dummy=1`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getJurisdictionListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getBusinessTypeListSaga(args = {}) {
  const { countryCode } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/business-types?country_code=${countryCode}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getBusinessTypeListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getNumberOfEmployeesListSaga(args = {}) {
  //const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/payment-purposes`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getNumberOfEmployeesListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getIndustriesListSaga(args = {}) {
  //const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/industries?is_active=1`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getIndustriesListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
export function* getBusinessBelongListSaga(args = {}) {
  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/business-categories`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getBusinessBelongListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getPrimaryUseCaseListSaga(args = {}) {
  //const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/business-use-cases`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getPrimaryUseCaseListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getPrimarySourceOfFundingListSaga(args = {}) {
  //const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/source-of-funds`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getPrimarySourceOfFundingListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getDocumentTypeListSaga(args = {}) {
  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${REFERENCES_URL}/document-types?document_category_code=ID_VERIFICATION`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getDocumentTypeListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getAccessRoleListSaga(args = {}) {

  const requestUrl = `${REFERENCES_URL}/roles`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getAccessRoleListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
