import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Divider, useMediaQuery } from '@material-ui/core';
import { Typography } from 'components/ui';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from 'components/ui/Button';


const useStyles = makeStyles(theme => ({
  borderRadius: props => ({
    borderRadius: '24px !important',
    [theme.breakpoints.down('xs')]: {
      borderRadius: props.noFullScreen ? '24px !important' : '0 !important',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: (props.fullScreen === undefined || props.fullScreen === null) ? '0 !important' : '24px !important',
    }
  }),
  borderRadiusXs: props => ({
    borderRadius: '0 !important',
  }),
  titleDiv: props => ({
    margin: 0,
    padding: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    height: theme.spacing(7),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,

  }),
  titleDivXs: props => ({
    margin: 0,
    padding: props.noPadding ? theme.spacing(2, 0, 2, 2) : theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    // height: theme.spacing(10),
    height: theme.spacing(10)
  }),
  closeButton: {
    padding: `${theme.spacing(0, 2, 0, 2)} !important`
  },
  btnOneDiv: {
    width: '40%',
  },
  footerContent: {
    justifyContent: 'center !important',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#fff',
  },
  footerConfirm: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: '#fff',
  },
  container_Custom: props => ({
    backgroundColor: 'rgba(17, 17, 17, 0.7)',
    backdropFilter: 'blur(15px)',
    '& .MuiPaper-root': {
      backgroundColor: props.header ? '#000000' : '#fff',
    }
  }),
  padding: props => ({
    padding: !props.headerBgImg ? theme.spacing(1, 1, 1.5) : "0px",
    flexDirection: 'column',
    minHeight: !props.minHeight ? 'auto' : theme.spacing(85.125),
    backgroundColor: '#fff',
    marginTop: props.headerBgImg ? theme.spacing(-1.5) : '0px',
    display: 'flex',
    flex: 1,
  }),
  no_padding: props => ({
    padding: props.noPadding ? theme.spacing(0) : theme.spacing(2),
    minHeight: !props.minHeight ? 'auto' : theme.spacing(85.125),
    backgroundColor: '#fff',
    marginTop: props.headerBgImg ? theme.spacing(-1.5) : '0px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }),
  emptyDiv: props => ({
    width: props.noPadding ? theme.spacing(4) : theme.spacing(6),
  }),
  message: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  closeWithoutHeader: {
    display: "flex",
    justifyContent: "flex-end",
    position: 'absolute',
    right: 0,
  },
  paperWidthXs: {
    maxWidth: theme.spacing(62),
  },
  subMessageTxt: {
    margin: theme.spacing(2, 1, 0, 1)
  },
  backIcon: {
    fontSize: theme.spacing(2.5),
    color: theme.palette.primary.contrastText,
  },
  closeIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.primary.contrastText,
  }
}));

function StyledDialog(props) {
  const classes = useStyles(props);
  const { noFullScreen } = props || {};
  const theme = useTheme();
  const [open, setOpen] = useState(props.open ? true : false);
  useEffect(() => {
    setOpen(props.open ? true : false);
  }, [props.open]);

  // type props accept either confirm or content
  // case 1:- Confirm have only have title and message props for body
  // case 2:- Content have title and child props

  // const isXsScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    props.onClose();
    setOpen(false);
  };

  const showFullScreen = isXsScreen && !noFullScreen ? true : false;

  return (<>
    {props.triggerComponent ? props.triggerComponent(onOpen) : null}
    {open && <Dialog
      // fullScreen={(props.fullScreen === undefined || props.fullScreen === null) ? !isXsScreen ? false : true : props.fullScreen}
      // // fullScreen={isXsScreen && !props.fullScreen ? true : false}

      fullScreen={showFullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      maxWidth="xs"
      fullWidth
      disableBackdropClick={props.disableBackdropClick === undefined || props.disableBackdropClick === null ? true : props.disableBackdropClick}
      onExit={props.onExit}
      onEnter={props.onEnter}
      disableEnforceFocus
      classes={{
        paperFullWidth: !showFullScreen ? classes.borderRadius : classes.borderRadiusXs,
        container: classes.container_Custom,
        paperWidthXs: classes.paperWidthXs
      }}

    >
      {
        props.header ?
          <MuiDialogTitle disableTypography className={!showFullScreen ? classes.titleDiv : classes.titleDivXs}>

            {
              props.backBtn || (props.backBtn && isXsScreen) ?
                <IconButton

                  aria-label="close"
                  className={classes.closeButton}
                  onClick={!props.backBtn && isXsScreen ? onClose : props.onBackPress}
                >
                  <ArrowBackIosIcon className={classes.backIcon} />
                </IconButton> : <div className={classes.emptyDiv} ></div>
            }


            <Typography variant={isXsScreen ? "titleMobile" : "h6"}>{props.title}</Typography>

            {
              props.closeIcon ? <IconButton

                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton> : <div className={classes.emptyDiv} ></div>
            }
          </MuiDialogTitle>
          : <>&nbsp;</>
      }

      {
        !props.header && props.closeIcon ? <div className={classes.closeWithoutHeader}>
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton >
        </div> : null
      }

      {/* <MuiDialogContent className={props.padding === undefined || props.padding === "null" || props.padding === true ? classes.padding : classes.no_padding}> */}
      <MuiDialogContent id="dialog-content-root" className={!isXsScreen ? classes.padding : classes.no_padding}>
        {props.childComponent ? props.childComponent(onClose) : (props.type === 'content' ? props.children :
          <div className={classes.message}><Typography variant="h6" color="grey700">{props.message}</Typography>
            {props.subMessage ? <Typography color="grey500" className={classes.subMessageTxt} variant="body2">{props.subMessage}</Typography> : <></>}
          </div>
        )}
      </MuiDialogContent>
      {props.footer ? (<>
        <Divider />
        <MuiDialogActions className={props.type === "confirm" ? classes.footerConfirm : classes.footerContent} >
          <div className={classes.btnOneDiv}>
            <Button
              variant="outlined"
              onClick={props.type === 'confirm' ? props.btnOneAction : props.btnOneAction}
              title={props.type === 'confirm' ? props.btnOneTitle ? props.btnOneTitle : 'No' : props.btnOneTitle}
            />
          </div>
          {
            props.btnTwoTitle ? <div className={classes.btnOneDiv}>
              <Button
                onClick={props.btnTwoAction}
                title={props.btnTwoTitle}
              />
            </div> : <></>
          }

        </MuiDialogActions>
      </>) : (
        <></>
      )}
    </Dialog>}
  </>);
}
// Specifies the default values for props:
StyledDialog.defaultProps = {
  backBtn: false,
  onClose: () => null,
  closeIcon: true,
  minHeight: false,
  headerBgImg: false,
  noPadding: false
};

StyledDialog.propTypes = {
  open: PropTypes.any,
  title: PropTypes.any,
  message: PropTypes.string,
  children: PropTypes.any,
  subMessage: PropTypes.any,
  footer: PropTypes.any,
  type: PropTypes.oneOf(['confirm', 'content']),
  btnOneTitle: PropTypes.string,
  btnOneAction: PropTypes.func,
  btnTwoAction: PropTypes.func,
  btnTwoTitle: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  onExit: PropTypes.func,
  onEnter: PropTypes.func,
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool,
  header: PropTypes.bool,
  padding: PropTypes.bool,
  onBackPress: PropTypes.func,
  backBtn: PropTypes.bool,
  closeIcon: PropTypes.bool,
  minHeight: PropTypes.bool,
  headerBgImg: PropTypes.bool,
  noPadding: PropTypes.bool
};

export default memo(StyledDialog);
