/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState } from 'react';
import {
  makeStyles, // FormControlLabel,
  Grid,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";

import {
  Typography, Button,
  TextField,
} from "components/ui";
import SearchLocation from 'containers/Common/SearchLocation';
import SelectState from 'containers/Common/SelectState';
import SelectCountry from 'containers/Common/SelectCountry';
import { createStructuredSelector } from 'reselect';
import Notepad from 'assets/images/Notepad.png';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { Loader } from 'components';
import { updatePersonalDetailsAction, uploadVerificationDocumentAction, uploadVerificationDocumentSetDataAction } from 'store/Verification/verificationActions';
import { updatePersonalDetailsState, uploadVerificationDocumentState } from 'store/Verification/verificationSelectors';
import NoDocInstructions from './NoDocInstructions';
import { initialState } from 'store/Verification/verificationReducer';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/Verification/Intl/VerificationIntl';

const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: 'center',
    backgroundColor: "white",
  },
  container: {
    padding: theme.spacing(0, 1, 4, 1),
    justifyContent: "space-between",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  bodyTxt: {
    textAlign: "center",
  },

  bodyTxtCenter: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },

  spanTxt: {
    color: "black",
    fontWeight: "bold",
  },
  inputDiv: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  rowInputDiv: {
    marginTop: theme.spacing(2),

    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  radioInputDiv: {
    marginTop: theme.spacing(2),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  addressTitle: {
    textAlign: "left",
    borderBottom: "1px solid black",
  },
  addressCheck: {
    textAlign: "left",
  },
  continueBtn: {
    marginTop: theme.spacing(3),
  },
  stateAndZipDiv: {
    display: "flex",
  },
  iconColor: {
    color: `${theme.palette.grey500}`,
    "&.Mui-checked": {
      color: `${theme.palette.accent.main} !important`,
    },
  },
  underline: {
    textDecoration: "underline",
  },
  txtRoot: {
    margin: theme.spacing(4, 1, 0, 1),
  },
  subtitleDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  divider: {
    backgroundColor: theme.palette.grey600,
  },
  titleTxt: {
    marginTop: theme.spacing(3),
  },
  spacing: {
    width: theme.spacing(2),
  },
  commonFlex: {
    flex: 1,
    display: "contents",
  },
  radioFlex: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      justifyContent: "center",
    },
  },
  uploadProofTitle: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  proofHelperTxt: {
    margin: theme.spacing(1, 0, 0, 0),
    textAlign: "center",
    color: theme.palette.accent.main,
  },
  dontHaveTxt: {
    textDecoration: "underline",
    textAlign: "center",
    margin: theme.spacing(0, 0, 6, 0),
    cursor: "pointer",
  },
  lastMainGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      justifyContent: "space-between",
    },
  },
  btnDiv: {
    flex: 1,
  },
  shippingAddressDiv: {
    marginBottom: theme.spacing(4),
  },
  residentialAddress: {
    border: "1px solid #F2F3F4",
    borderRadius: "6px",
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
  img: {
    width: theme.spacing(32.125),
    height: theme.spacing(25.125),
  },
  imgDiv: {
    display: "flex",
    justifyContent: "center",
  },
  redFont: {
    color: theme.palette.error.main
  },
  iconRedColor: {
    color: `${theme.palette.error.main}`,
  },
}));

function MailingAddress(props = {}) {
  const classes = useStyles();
  const { data = {}, onStepChange = () => null, updatePersonalDetailsState,
    updatePersonalDetails, isLoading } = props;
  // eslint-disable-next-line no-unused-vars
  const [inputRefs, setInputRefs] = useState([]);

  const [radioError, setRadioError] = useState([]);
  const [noDocAvailable, setNoDocAvailable] = useState(false);
  const [formData, setFormData] = useState({});
  const businessName = data.company_name || data.invited_for || "";
  const isLoadingData = isLoading || updatePersonalDetailsState.loading;

  const handleSubmit = (e) => {

    e?.preventDefault();

    const error = [];
    if ((formData.communication_address_same_as_primary === null || formData.communication_address_same_as_primary === undefined)) {
      error.push("communication_address_same_as_primary");
    }

    setRadioError(error);
    if (error.length === 0) {
      updatePersonalDetails({
        personId: data.entity_person_id, token: data.continue_signup_token, body: {
          communication_address_same_as_primary: formData.communication_address_same_as_primary,
          mailingAddress
        },
        cb: onStepChange,
      });
    }

  };



  const onRefFocus = useCallback(async (textFieldName, textFieldId) => {
    if (textFieldName === "clear") {
      setInputRefs([]);
    } else {
      let refArray = [...inputRefs];
      refArray.push(textFieldId || textFieldName);
      setInputRefs((ar) => [...new Set([...ar, (textFieldId || textFieldName)])]);
    }
  }, []);

  const clearRefFocus = useCallback(() => {
    onRefFocus("clear");
  }, []);


  const onLocationInputChange = useCallback(() => {
    // setFormData(formData => ({ ...formData, [name]: value }));
  }, []);


  const UnderLineTitle = ({ title }) => {
    return (
      <>
        <Typography className={classes.titleTxt} variant="subtitle2" color="grey700">{title}</Typography>
        <Divider className={classes.divider} />
      </>

    )
  }

  const [isMailingLocationSelected, setMailingLocationSelected] = React.useState(false);
  const [mailingAddress, setMailingAddress] = useState({
    address_id: (data && data.prs_address_communication && data.prs_address_communication.address_id) || "",
    address_line_1: (data && data.prs_address_communication && data.prs_address_communication.address_line_1) || "",
    address_line_2: (data && data.prs_address_communication && data.prs_address_communication.address_line_2) || "",
    landmark: (data && data.prs_address_communication && data.prs_address_communication.landmark) || "",
    city: (data && data.prs_address_communication && data.prs_address_communication.city) || "",
    state_code: (data && data.prs_address_communication && data.prs_address_communication.state_code) || "",
    state_name: (data && data.prs_address_communication && data.prs_address_communication.state_name) || "",
    postal_code: (data && data.prs_address_communication && data.prs_address_communication.postal_code) || "",
    country_code: (data && data.prs_address_communication && data.prs_address_communication.country_code) || "",
  });
  const onMailingAddressFieldChange = useCallback((e) => {
    const { name, value } = e.target || {};
    setMailingAddress(formData => ({ ...formData, [name]: value }));
  }, []);
  const onMailingAddressCountryFieldChange = useCallback((e) => {
    const { name, value } = e.target || {};
    // setMailingAddress(formData => ({ ...formData, [name]: value, state_code: '', state_name: '' }));
    setMailingAddress(formData => ({ ...formData, [name]: value }));
  }, []);
  const onMailingLocationChange = useCallback((e, val = {}, reason) => {
    if (reason === 'input') {
      setMailingAddress(d => ({
        ...d,
        address_line_1: val.address_line_1,
      }));
      return;
    }
    if (Object.keys(val).includes("address")) {
      setMailingAddress(d => ({
        ...d,
        address_line_1: val.address_line_1,
        // address_line_2: val.route,
        city: val.city,
        // state_name: val.state,
        // state_code: val.state_code,
        country_code: val.country_code,
        postal_code: val.postal_code,
      }));
      setTimeout(() => {
        setMailingAddress(d => ({
          ...d,
          state_name: val.state,
          state_code: val.state_code,
        }));
      }, 300);
    }
    setMailingLocationSelected(true);
  }, []);
  const onMailingAddressStateChange = useCallback((e, v) => {
    onMailingAddressFieldChange(e);
    onMailingAddressFieldChange({ target: { id: 'state_name', name: 'state_name', value: v?.state_name || '' } });
  }, [onMailingAddressFieldChange]);
  const onBlurSearLocation = useCallback(() => {
    setMailingLocationSelected(true);
    onRefFocus("clear");
  }, []);
  const onChange = useCallback((e) => {
    const { name, value } = e.target || {};
    if (name === "communication_address_same_as_primary") {
      setRadioError([])
    }
    setFormData(formData => ({ ...formData, [name]: value }));
  }, []);

  if (noDocAvailable) {
    return (<NoDocInstructions
      onGoBack={() => setNoDocAvailable(false)}
    />)
  }

  return (<form onSubmit={handleSubmit}><div className={classes.root}>

    <div className={classes.container}>
      <div className={classes.imgDiv}>
        <img src={Notepad} alt="img" className={classes.img} />
      </div>
      <div>
        <Typography variant="subtitle1" color="grey700" className={classes.title}>
          {businessName} <FormattedMessage {...intl.mailingAddressSubTitle} />
        </Typography>
      </div>
      <div>
        <UnderLineTitle title="Address" />
        <div className={classes.residentialAddress}>
          <Typography variant="caption"><FormattedMessage {...intl.residentialAddress} /></Typography>
          <div>
            <Typography variant="subtitle1">{data?.prs_address_residential?.address_line_1}, {data?.prs_address_residential?.address_line_2}</Typography>
            <Typography variant="subtitle1">{data?.prs_address_residential?.landmark}</Typography>
            <Typography variant="subtitle1">{data?.prs_address_residential?.city}, {data?.prs_address_residential?.state_name || data?.prs_address_residential?.state_code}</Typography>
            <Typography variant="subtitle1">{data?.prs_address_residential?.country_name || data?.prs_address_residential?.country_code}, {data?.prs_address_residential?.postal_code}</Typography>
          </div>
        </div>
      </div>
      <div className={classes.shippingAddressDiv}>
        <UnderLineTitle title="Shipping Address" />
        <div className={classes.radioInputDiv}>
          <div className={classes.commonFlex}><Typography className={radioError.includes("communication_address_same_as_primary") && classes.redFont} variant="body2" color="grey700"> <FormattedMessage {...intl.physicalCardTxt} /> </Typography></div>
          <div className={classes.radioFlex}>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="shipping_address" name="communication_address_same_as_primary" value={formData.communication_address_same_as_primary} onChange={onChange} classes={{ row: classes.justifyContentCenter }}>
                <FormControlLabel value={"1"} control={<Radio className={radioError.includes("communication_address_same_as_primary") ? classes.iconRedColor : classes.iconColor} checked={(formData.communication_address_same_as_primary === "true" || formData.communication_address_same_as_primary == 1) && true} />} label={`Yes`} />
                <FormControlLabel value={"0"} control={<Radio className={radioError.includes("communication_address_same_as_primary") ? classes.iconRedColor : classes.iconColor} checked={(formData.communication_address_same_as_primary === "false" || formData.communication_address_same_as_primary == 0) && true} />} label={`No`} />
              </RadioGroup>
            </FormControl>
          </div>
        </div>


        {
          formData.communication_address_same_as_primary == 0 &&
          <>
            <div className={classes.inputDiv} >
              <SelectCountry
                id="m_country_code"
                name="country_code"
                value={mailingAddress.country_code}
                label={'Country'}
                required
                // errorText={addUpdateContactResp.error.country_code || defaultErrorText}
                onChange={onMailingAddressCountryFieldChange}
                onFocusRef={onRefFocus}
                makeFocus={inputRefs[0] === "m_country_code"}
                onBlur={clearRefFocus}
              />
            </div>
            <div className={classes.inputDiv} >
              <SearchLocation
                compId="mailingAddress"
                countryCode={mailingAddress.country_code}
                id="m_address_line_1"
                name="address_line_1"
                value={mailingAddress.address_line_1}
                label={'Street Address'}
                required
                // errorText={addUpdateContactResp.error.address_line_1 || defaultErrorText}
                // error={addUpdateContactResp.error.address_line_1}
                onChange={onMailingLocationChange}
                onInputChange={onLocationInputChange}
                onBlur={onBlurSearLocation}
                onFocusRef={onRefFocus}
                makeFocus={inputRefs[0] === "m_address_line_1"}
              />
            </div>

            <div className={classes.inputDiv}>
              <TextField type="text"
                id="m_address_line_2" name="address_line_2"
                value={mailingAddress.address_line_2}
                label={'Unit/Suite/Floor (optional)'}
                onChange={onMailingAddressFieldChange}
                onFocusRef={onRefFocus}
                makeFocus={inputRefs[0] === "m_address_line_2"}
                onBlur={clearRefFocus}
              />
            </div>
            {isMailingLocationSelected && <div className={classes.inputDiv} >
              <TextField
                type="text"
                id="m_city"
                name="city"
                value={mailingAddress.city}
                label={'City'}
                required
                // errorText={addUpdateContactResp.error.city || defaultErrorText}
                // error={addUpdateContactResp.error.city}
                onChange={onMailingAddressFieldChange}
                onFocusRef={onRefFocus}
                makeFocus={inputRefs[0] === "m_city"}
                onBlur={clearRefFocus}
              />
            </div>}
            {isMailingLocationSelected && <div className={classes.inputDiv}>
              <Grid container spacing={2}>
                <Grid xs={12} lg={7} item>
                  <SelectState
                    id="m_state_code"
                    name="state_code"
                    value={mailingAddress.state_code}
                    valueObj={{ state_code: mailingAddress.state_code, state_name: mailingAddress.state_name }}
                    label={'State/Province'}
                    required
                    // errorText={addUpdateContactResp.error.state_code || defaultErrorText}
                    onChange={onMailingAddressStateChange}
                    // onChange={(e, v) => {
                    //     onMailingAddressFieldChange(e)
                    //     onMailingAddressFieldChange({ target: { id: 'state_name', name: 'state_name', value: ((v && v.state_name) || e.target.value) || '' } })
                    // }}
                    countryCode={mailingAddress.country_code}
                    inputDivClassName={classes.inputDiv}
                    onFocusRef={onRefFocus}
                    makeFocus={inputRefs[0] === "m_state_code"}
                    onBlur={clearRefFocus}
                  />
                </Grid>
                <Grid xs={12} lg={5} item>
                  <TextField
                    type="text"
                    id="m_postal_code"
                    name="postal_code"
                    value={mailingAddress.postal_code}
                    label={'ZIP/Postal Code'}
                    required
                    // errorText={addUpdateContactResp.error.postal_code || (!formData.postal_code ? defaultErrorText : 'Invalid Zip/Postal Code')}
                    // error={addUpdateContactResp.error.postal_code}
                    onChange={onMailingAddressFieldChange}
                    inputProps={{
                      pattern: '^[a-zA-Z0-9 -]{4,7}$',
                      minlength: 4,
                      maxlength: 7,
                    }}
                    onFocusRef={onRefFocus}
                    makeFocus={inputRefs[0] === "m_postal_code"}
                    onBlur={clearRefFocus}
                  />
                </Grid>
              </Grid>
            </div>}
          </>
        }
      </div>
      {isLoadingData && <Loader />}
      <Grid container className={classes.lastMainGrid}>
        {/* <BackStepButton onClick={() => onStepChange('PERSONAL_DETAILS')} /> */}
        <Grid className={classes.btnDiv} md={6}>
          <Button
            disabled={isLoadingData}
            title={"Send"}
            // className={classes.continueBtn}
            className={classes.btnDiv}
            type="submit"
            color="secondary"
          />
        </Grid>
      </Grid>
    </div>
  </div></form >)
}

const mapStateToProps = createStructuredSelector({
  updatePersonalDetailsState: updatePersonalDetailsState(),
  uploadVerificationDocumentState: uploadVerificationDocumentState(),
});

function mapDispatchToProps(dispatch) {
  return {
    updatePersonalDetails: data => dispatch(updatePersonalDetailsAction(data)),
    uploadVerificationDocument: data => dispatch(uploadVerificationDocumentAction(data)),
    resetUploadVerificationDocument: () => dispatch(uploadVerificationDocumentSetDataAction(initialState.documentUploadState))
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(MailingAddress);