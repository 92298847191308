/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import TextField from 'components/ui/TextField';
import SelectCountry from 'containers/Common/SelectCountry';
import intl from 'containers/Contacts/intl/ContactsIntl';
import { getBankAccountConfigAction, getBankAccountConfigSetDataAction } from 'store/Reference/referenceActions';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getBankAccountConfigState } from 'store/Reference/referenceSelectors';
import { initialState } from 'store/Reference/referenceReducer';
import SelectCurrency from 'containers/Common/SelectCurrency';
import { FormattedMessage } from 'react-intl';
import SelectBankAccountType from './SelectBankAccountType';
import { Loader } from 'components';
import { ibanValidationAction } from 'store/Contact/contactActions';
import { validateIbanState } from 'store/Contact/contactSelectors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  inputDiv: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  iconDiv: {
    background: "#e7b18d",
    height: theme.spacing(12.25),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.75),
  },
  loaderDiv: {
    height: theme.spacing(17.5),
  },
  bankFieldsDiv: {

  },
  bottomSpace: {
    paddingBottom: theme.spacing(6.25),
  },
  verifyBtn: {
    cursor: "pointer",
    color: theme.palette.accent.main
  }
}));
function AddUpdateBankDetails(props = {}) {
  const { // addContactBankAccountState,
    getBankConfig, errors = {}, inputRef, getIbanData, ibanValidatedData = {}, defaultAccountHolderName,
    bankConfig, resetBankConfig, onChange: onChangeHandler = () => null,
    defaultErrorText, onFocusRef, inputRefArray, defaultBankDetails = {} } = props;
    // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [formData, setFormData] = useState({...defaultBankDetails});
  const [bankFields, setBankFields] = useState([]);
  const [fieldsLoader, setFieldsLoader] = useState(false);
  const [iconButton, setIconButton] = useState(false);

  useEffect(() => {
    const isExistingCurrencyExists = Array.isArray(bankConfig.currencies) && bankConfig.currencies.some(i => i.currency_code === formData.currency_code);
    const isExistingPaymentTypeExists = Array.isArray(bankConfig.data) && bankConfig.data.some(i => i.bank_country_code === formData.bank_country_code && i.currency_code === formData.currency_code && i.payment_type === formData.payment_type);
    if (!bankConfig.loading && !isExistingCurrencyExists) {
       setFormData(formData => ({ ...formData, currency_code: '', payment_type: '' }));
    } else if (!bankConfig.loading && !isExistingPaymentTypeExists){
      setFormData(formData => ({ ...formData, payment_type: '' }));
    }
  }, [bankConfig.data, bankConfig.currencies])

  useEffect(() => {

    if (defaultBankDetails.account_holder_name !== formData.account_holder_name) {
      setFormData(formData => ({ ...formData, account_holder_name: defaultBankDetails.account_holder_name }));
    }
    if (defaultBankDetails.bank_country_code !== formData.bank_country_code) {

      setFormData(formData => ({ ...formData, bank_country_code: defaultBankDetails.bank_country_code }));
    }

  }, [defaultBankDetails.account_holder_name, defaultBankDetails.bank_country_code]);

  useEffect(() => {
    if (formData.bank_country_code) {
      getBankConfig({
        params: {
          bank_country_code: formData.bank_country_code,
          // currency_code: formData.currency_code,
        }
      });
    } else {
      resetBankConfig();
    }

    // setFormData(formData => ({ ...formData, currency_code: '', payment_type: '' }));
  }, [formData.bank_country_code]);



  useEffect(() => {
    const isExistingPaymentTypeExists = Array.isArray(bankConfig.data) && bankConfig.data.some(i => i.bank_country_code === formData.bank_country_code && i.currency_code === formData.currency_code && i.payment_type === formData.payment_type);
    // setFormData(formData => ({ ...formData, payment_type: '' }));
    if (!bankConfig.loading && !isExistingPaymentTypeExists){
      setFormData(formData => ({ ...formData, payment_type: '' }));
    }
  }, [formData.currency_code]);

  useEffect(() => {
    const { bank_country_code, currency_code, payment_type } = formData;
    setBankFields([]);
    if (bank_country_code && currency_code && payment_type) {
      setFieldsLoader(true);
      const fields = bankConfig.data.filter(i => (i.bank_country_code === bank_country_code && i.currency_code === currency_code && i.payment_type === payment_type));
      const sortedFields = fields.sort((a, b) => a.display_order > b.display_order ? 1 : -1);
      sortedFields.forEach((f, i) => {
        if (f.bank_account_field === "account_holder_name") {
          sortedFields.unshift(sortedFields.splice(i, 1)[0])
        }
      })
      setTimeout(() => {
        setBankFields(sortedFields);
        // setFormData(f => ({...f, account_holder_name: defaultAccountHolderName}))
        onChangeHandler({
          target: {
            name: 'account_holder_name',
            value: defaultAccountHolderName,
          },
        });
        setFieldsLoader(false);
        clearBankFields();
      }, 500);
    }

  }, [formData.payment_type]);


  useEffect(() => {
    setFieldsLoader(false);
    const data = ibanValidatedData.data;
    bankFields.forEach((b) => {
      if (b.routing_code_value === "BIC_SWIFT") {
        setFormData((formData) => ({
          ...formData,
          [b.bank_account_field]: data.bic,
        }));
        onChangeHandler({
          target: {
            name: b.bank_account_field,
            value: data.bic,
          },
        });
      }
    });
    if (formData.iban || formData.iban_regular) {
      if (formData.bank_name !== data.bank) {
        setFormData((formData) => ({ ...formData, bank_name: data.bank }));
        onChangeHandler({
          target: {
            name: "bank_name",
            value: data.bank,
          },
        });
      }
      if (formData.bank_branch_address !== data.address && formData.bank_branch_address !== data.branch) {
        setFormData((formData) => ({
          ...formData,
          bank_branch_address: data?.address?.trim() || data.branch,
        }));
        onChangeHandler({
          target: {
            name: "bank_branch_address",
            value: data?.address?.trim() || data.branch,
          },
        });
      }
    }

    if (Object.keys(ibanValidatedData.data).length) {
      setIconButton(true);
    } else {
      setIconButton(false);
      clearBankFields();
    }
  }, [ibanValidatedData]);

  useEffect(() => {
    if (formData.iban || formData.iban_regular) {
      const handler = setTimeout(() => {
        let isIbanValid = false;
        let iban = formData.iban || formData.iban_regular;
        bankFields.forEach((b) => {
          if (b.bank_account_field === "iban" || b.routing_code_value === "IBAN_REGULAR") {
            const re = new RegExp(b.validation.regex);
            if (re.test(iban)) {
              isIbanValid = true;
            }
          }
        });

        if (isIbanValid) {
          handleVerifyIBAN();
        } else {
          setIconButton(false);
        }
      }, 2000);

      return () => clearTimeout(handler);
    } else {
      setFieldsLoader(false);
      setIconButton(false);
      clearBankFields();
    }
  }, [formData.iban, formData.iban_regular]);

  const onChange = (e) => {
    const { name, value } = e.target || {};
    let trimmedValue = value ? value.trimStart() : "";
    bankFields.forEach((b) => {
      if (b.routing_code_value === "IBAN_REGULAR" && b.bank_account_field === name) {
        setFormData(formData => ({ ...formData, "iban_regular": trimmedValue }));
      }
    })
    setFormData(formData => ({ ...formData, [name]: trimmedValue }));
    onChangeHandler({ target: { name, value } });
  };

  const clearBankFields = () => {
    // bankFields.forEach((b) => {
    //   if (b.routing_code_value === 'BIC_SWIFT') {
    //     setFormData(formData => ({ ...formData, [b.bank_account_field]: "" }));
    //     onChangeHandler({
    //       target: {
    //         name: b.bank_account_field,
    //         value: ""
    //       }
    //     });
    //   }
    // })
    // setFormData(formData => ({ ...formData, bank_name: "" }));
    // onChangeHandler({
    //   target: {
    //     name: 'bank_name',
    //     value: ""
    //   }
    // });
    // setFormData(formData => ({ ...formData, bank_branch_address: "" }));
    // onChangeHandler({
    //   target: {
    //     name: 'bank_branch_address',
    //     value: ""
    //   }
    // });
  }
  const handleVerifyIBAN = (i={}) => {
    setFieldsLoader(true);
    let iban = formData.iban || formData.iban_regular;
    if (i.routing_code_value === "IBAN_REGULAR") {
      iban = formData[i.bank_account_field];
    }
    getIbanData({
      body: {
        iban,
      }
    });
  }

  const renderEndAdornment = (i) => {
    const { bank_account_field, routing_code_value } = i;
    if (bank_account_field !== "iban" && routing_code_value !== "IBAN_REGULAR") {
      return <></>;
    }
    return (
      <>
        <InputAdornment position="end">
          {iconButton ? (
            <IconButton>
              <CheckCircleIcon />
            </IconButton>
          ) : (
            <Link
              variant="subtitle1"
              onClick={() => handleVerifyIBAN(i)}
              title={"Verify"}
              className={classes.verifyBtn}
            >Verify</Link>
          )}
        </InputAdornment>
      </>
    );
  };


  return (
    <>
      <div className={classes.bankFieldsDiv}>

        <div className={classes.inputDiv} >
          <SelectCountry
            id="bank_country_code"
            name="bank_country_code"
            value={formData.bank_country_code}
            label={<FormattedMessage {...intl.bankAddUpdateCountryOfBankLabel} />}
            required
            // errorText={defaultErrorText}
            error={errors.bank_country_code}
            onChange={onChange}
            onFocusRef={onFocusRef}
            makeFocus={inputRefArray[0] === "bank_country_code" ? true : false}
            onBlur={() => { onFocusRef("clear") }}
            onlyDestination
          />
        </div>

        <div className={classes.inputDiv} >
          <SelectCurrency
            id="currency_code"
            name="currency_code"
            value={formData.currency_code}
            label={<FormattedMessage {...intl.bankAddUpdateAccountCurrencyLabel} />}
            required
            // errorText={defaultErrorText}
            error={errors.currency_code}
            onChange={onChange}
            propsCurrencies={{ loading: bankConfig.loading, data: bankConfig.currencies }}
            propsGetCurrencies={() => null}
            onFocusRef={onFocusRef}
            disabled={!formData.bank_country_code}
            makeFocus={inputRefArray[0] === "currency_code" ? true : false}
            onBlur={() => { onFocusRef("clear") }}
          />
        </div>
       <div className={classes.inputDiv} >
          <SelectBankAccountType
            id="payment_type"
            name="payment_type"
            value={formData.payment_type}
            label={<FormattedMessage {...intl.bankAddUpdateBankTypeLabel} />}
            required
            // errorText={defaultErrorText}
            error={errors.payment_type}
            onChange={onChange}
            bankCountryCode={formData.bank_country_code}
            currencyCode={formData.currency_code}
            onFocusRef={onFocusRef}
            disabled={!formData.currency_code}
            makeFocus={inputRefArray[0] === "payment_type" ? true : false}
            onBlur={() => { onFocusRef("clear") }}
          />
        </div>

        {formData.bank_country_code && formData.currency_code && formData.payment_type? <div className={classes.bottomSpace}>
          {
            bankFields.map((i) => {
              return (
                <div className={classes.inputDiv} >
                  <TextField
                    inputRef={inputRef}
                    type={i.bank_account_field_type || 'text'}
                    specialChar={i.bank_account_field === "account_holder_name" ? false : true}
                    id={i.bank_account_field}
                    name={i.bank_account_field}
                    value={formData[i.bank_account_field]}
                    label={i.display_name}
                    required={!!i.required || !!i.validation.regex}
                    errorText={errors[i.bank_account_field] || i.validation.message || defaultErrorText}
                    error={!!errors[i.bank_account_field]}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: renderEndAdornment(i)
                    }}
                    inputProps={{
                      pattern: i.validation.regex,
                      minlength: i.validation.minlength,
                      maxlength: i.validation.maxlength,
                    }}
                    onFocusRef={onFocusRef}
                    makeFocus={inputRefArray[0] === `${i.bank_account_field}` ? true : false}
                    onBlur={() => { onFocusRef("clear") }}
                    inputPropsChanged={iconButton}
                  />
                </div>)
            })
          }
        </div> : null}
        {/* <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div> */}
        {fieldsLoader && <div className={classes.loaderDiv}><Loader /></div>}
      </div>
    </>
  );
}


const mapStateToProps = createStructuredSelector({
  bankConfig: getBankAccountConfigState(),
  ibanValidatedData: validateIbanState(),
});


function mapDispatchToProps(dispatch) {
  return {
    getBankConfig: data => dispatch(getBankAccountConfigAction(data)),
    resetBankConfig: data => dispatch(getBankAccountConfigSetDataAction(initialState.getBankAccountConfigState)),
    getIbanData: data => dispatch(ibanValidationAction(data)),
  }
}

AddUpdateBankDetails.defaultProps = {
  onSuccess: () => null,
  onCancelAction: () => null,
  defaultErrorText: <FormattedMessage {...intl.errorDefaultText} />,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(AddUpdateBankDetails);
