import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Loader, StyledError } from 'components';
import { ENTITY_PERSON_TYPE } from '@truly-financial/common/consts/entity-person.consts';
import { makeStyles } from '@material-ui/core';
import Logo from 'assets/images/logo/Truly_Logo_Light.png';
import { deprecatedGetTeamMemberDetailsAction } from 'store/Verification/verificationActions';
import { deprecatedGetTeamMemberDetailsState } from 'store/Verification/verificationSelectors';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        minHeight: '100vh',
    },
    infoDiv: {
        textAlign: 'center',
        margin: theme.spacing(10, 1),
    },
    logo: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'left',
        borderRadius: '0px 0px 10px 10px',
        minHeight: theme.spacing(10)
    },
}));

function VerificationRedirect(props = {}) {
    const { personalDetails,
        getPersonalDetails } = props;

    const classes = useStyles();
    const location = useLocation();
    const { id, token } = queryString.parse(location.search);
    const history = useHistory();

    useEffect(() => {
        handleTeamMemberDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTeamMemberDetails = () => {
        getPersonalDetails({ personId: id, token });
    };

    useEffect(() => {
        const accessToken = personalDetails?.data?.access_token;
        if (accessToken) {
            if (personalDetails.data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT) {
                history.push(`/`);
            }
            else if (personalDetails.data.role_id && personalDetails.data.is_signup_required) {
                history.push(`/team-member-signup?token=${accessToken}`);
            }
            else {
                history.push(`/verification?token=${accessToken}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalDetails]);

    return (<div className={classes.root}>
        <div className={classes.logo}>
            <img alt="Truly" src={Logo} width="120" />
        </div>
        {personalDetails.loading && <Loader />}
        {(personalDetails.status === 404 || personalDetails.error) && <StyledError data={personalDetails} retry={handleTeamMemberDetails} />}
        {(!personalDetails.loading && personalDetails?.data?.entity_person_id && !personalDetails?.data?.access_token) && <StyledError data={{error:{message: 'Invalid token!'}}} />}
    </div>)
}

const mapStateToProps = createStructuredSelector({
    personalDetails: deprecatedGetTeamMemberDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getPersonalDetails: data => dispatch(deprecatedGetTeamMemberDetailsAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(VerificationRedirect);
