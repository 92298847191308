/* eslint-disable no-script-url */
import React, { 
  // useEffect,
  useState } from 'react';

import { AuthState } from '@aws-amplify/ui-components';
import { Checkbox, FormControlLabel, Grid, makeStyles, } from '@material-ui/core';
import { Typography } from 'components/ui';
import { OtpInput } from 'components';
// import TextField from 'components/ui/TextField';
import { Auth } from 'aws-amplify';
import Button from 'components/ui/Button';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { setMfaDeviceTimeStamp } from 'utils/auth';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  headings: {
    textAlign: "left",
  },
  subHeading: {
    margin: theme.spacing(1, 0, 0),
  },
  submitButton: {
    margin: theme.spacing(-2, 0, 2, 0),
  },
  resendCodeLink: {
    display: "block",
    textAlign: "center",
    textDecoration: "underline",
  },
  iconColor: {
    color: `${theme.palette.grey500}`,
    "&.Mui-checked": {
      color: `${theme.palette.accent.main} !important`,
    },
  },
  otpDiv: {
    display: "flex",
    justifyContent: "center",
  },
  rememberDiv: {
    marginTop: "12px",
    marginBottom: "12px",
  },
}));

function ConfirmSignIn(props = {}) {
  const { user = {}, setUser, setAuthState, setLoading, loading, password } = props;
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [rememberDevice, setRememberDevice] = useState(false);
  let mfaSpecificMethodTxt = '';
  // const allowResendCode = user.challengeName === 'SMS_MFA';
  if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
    mfaSpecificMethodTxt = "Check your authentication app (for example Google Authenticator or Duo Mobile) for the time-sensitive code and enter below.";
  } else if (user.challengeName === 'SMS_MFA') {
    if (user?.challengeParam?.CODE_DELIVERY_DELIVERY_MEDIUM === 'SMS' && user?.challengeParam?.CODE_DELIVERY_DESTINATION) {
      mfaSpecificMethodTxt = `Please enter the confirmation code you received via SMS at ${user?.challengeParam?.CODE_DELIVERY_DESTINATION || ''}.`;
    } else {
      mfaSpecificMethodTxt = 'Please enter the confirmation code you received via SMS.';
    }
  }
  
  // useEffect(() => {
  //   if (formData?.code?.length === 6) {
  //     handleSubmit()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formData.code]);

  const handleSubmit = (e={}) => {
    e.preventDefault &&e.preventDefault();
    confirmSignIn(formData);
  };

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const onChangeRememberDevice = (e, val) => {
    const { checked } = e.target || {};
    setRememberDevice(checked);
  };

  // const onResendCode = () => {
  //   // amplify does not have this function
  // };

  // const handleRememberDeviceSubmit = (u) => {
  //   return new Promise((resolve, reject) => {
  //     if (rememberDevice) {
  //       u.setDeviceStatusRemembered({onSuccess: (res) => {
  //         setMfaDeviceTimeStamp();
  //         resolve(true);
  //       }, onFailure: (err) => {
  //         console.error(err);
  //         resolve(true);
  //       }});
  //     } else {
  //       u.setDeviceStatusNotRemembered({onSuccess: (res) => {
  //         resolve(true);
  //       }, onFailure: (err) => {
  //         console.error(err);
  //         resolve(true);
  //       }});
  //     }
  //   })
  // }

  const handleRememberDeviceSubmit = async (u) => {
    if (rememberDevice) {
      u.setDeviceStatusRemembered({
        onSuccess: (res) => {
          setMfaDeviceTimeStamp();
          setUser(u);
        }, onFailure: (err) => {
          setUser(u);
        }
      });
    } else {
      u.setDeviceStatusNotRemembered({
        onSuccess: (res) => {
          setUser(u);
        }, onFailure: (err) => {
          setUser(u);
        }
      });
    }
  }


  async function confirmSignIn(data) {
    setLoading(true);
    try {
      const u = await Auth.confirmSignIn(user, formData.code, user.challengeName);
      handleRememberDeviceSubmit(u);
      // setUser(u);
    } catch (error) {
      console.error('error: ', error);
      styledSnackbar.error(error.message);
      if (error.code === "NotAuthorizedException") {
        setSignInState();
      }
    } finally {
      setLoading(false);
    }
  }

  const setSignInState = () => {
    setAuthState(AuthState.SignIn)
  };

  const resendConfirmSignInSmsMfa = async () => {
    const u = await Auth.signIn(user?.username, password);
    setUser(u);
    styledSnackbar.success("Code sent");
  }

  return (<>
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant="h5">Enter Confirmation Code</Typography>
            <Typography color="grey500" variant="body2" className={classes.subHeading}>
              {mfaSpecificMethodTxt}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          {/* <TextField
            type="text"
            id="code"
            name="code"
            value={formData.code}
            label="Enter 6-digit Code"
            required
            errorText="Required"
            onChange={onChange}
            inputProps={{
              minlength: 6,
              maxlength: 6,
            }}
          /> */}
          <div className={classes.otpDiv}>
            <div>
              <OtpInput
                // type="text"
                id="code"
                name="code"
                value={formData.code}
                // label="Enter 6-digit Code"
                required
                // errorText=""
                onChange={onChange}
              />
              <div className={classes.rememberDiv}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.iconColor}
                      checked={rememberDevice}
                      onChange={onChangeRememberDevice}
                      name="rememberDevice"
                      color="secondary"
                    />
                  }
                  label={<Typography variant="body2" color="grey500">Remember this device for 10 days</Typography>}
                />
              </div>
            </div>
          </div>
          {user.challengeName === 'SMS_MFA' && password && user?.username ? <Typography className={classes.resendCodeTxt} variant="caption" color="grey700">
            <Link className={classes.resendCodeLink} href="#" onClick={resendConfirmSignInSmsMfa}>Resend SMS</Link>
          </Typography> : null}
        </Grid>
        {/* {allowResendCode && <Grid item xs={12}>
                <Typography className={classes.resendCodeLink} variant="caption" color="grey700">
                  <Link
                    href="#"
                    onClick={onResendCode}>Resend Code</Link>
                </Typography>
              </Grid>} */}
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              title="Back"
              onClick={setSignInState}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              // className={classes.submitButton}
              type="submit"
              title="Continue"
              disabled={formData?.code?.length !== 6 || loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  </>);
}


export default ConfirmSignIn;

