import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import './index.css';
import awsConfig from 'config/aws-config';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import LanguageProvider from 'containers/Common/LanguageProvider';
import theme from 'theme';
import { translationMessages } from 'i18n';
import { store } from './store';
import ReduxSnackbar from 'containers/Common/Snackbar';
import { HelmetProvider } from 'react-helmet-async';
import loadable from 'utils/Loadable';
import { TrulyLoader } from 'components';
const App = loadable(() => import('./App'), { fallback: <TrulyLoader /> });

Amplify.configure({ ...awsConfig, oauth: {} });

const helmetContext = {};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LanguageProvider messages={translationMessages}>
        <ThemeProvider theme={theme}>
          <HelmetProvider context={helmetContext}>
            <App />
            <ReduxSnackbar />
          </HelmetProvider>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.error) or .log or .info)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
