import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'components/ui/TextField';
import { Grid, InputAdornment } from '@material-ui/core';
// import { Typography } from 'components/ui';
import SearchIcon from '@material-ui/icons/Search';
import { useQuery } from 'utils/hooks';
import combineClx from 'utils/combineClx';

const useStyles = makeStyles(theme => ({
    input1: {
        '& .MuiFilledInput-root': {
            height: theme.spacing(5.5),

        }
    },
    circularProgressRoot: {
        position: 'absolute',
        right: theme.spacing(1.375),
        top: theme.spacing(2),
    },
    closeButton: {
        padding: 0,
        color: theme.palette.grey[300],
    },
    helperText: {
        color: theme.palette.hyperlink.main,
        marginLeft: theme.spacing(1.5),
    },
    icon: {
        color: theme.palette.grey[300],
    },
    rootField: {
        // border: `1px solid #CACACA`,
        borderRadius: '6px'
    }
}));

function SearchPartial(props = {}) {
    const { searchParam, fullWidth, /*helperTextAlways, */ searchStr: searchString, placeholder, searchedData, className, iconClassName = {} } = props;
    const classes = useStyles();
    let query = useQuery();
    let queryString = query.get(searchParam) ? (query.get(searchParam)).split("?") : "";
    const searchRef = useRef();
    const [searchStr, setSearchStr] = useState(searchString || queryString[0] || "");
    const [keyUpTimeout, setKeyUpTimeout] = useState(null);
    useEffect(() => {
        if (searchStr && !searchedData.length) {
            setTimeout(() => {
                props.onSearch(searchStr);
                // props.onSearch({ endPoint: `/search?query=${searchStr}`, value: searchStr });
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const search = (value) => {
        setKeyUpTimeout(clearTimeout(keyUpTimeout));
        setKeyUpTimeout(setTimeout(() => {
            props.onSearch(value);
            // if (value) {
            //     history.push(`${location.pathname}?${searchParam}=${value}`);
            //     props.onSearch({ endPoint: `/search?query=${value}`, value });
            // } else {
            //     history.push(`${location.pathname}`);
            //     props.onSearch();
            // }
        }, 1000));
    };

    const onChange = (e) => {
        const { value } = e.target || {};
        setSearchStr(value);
        search(value);
    };
    // const onClear = () => {
    //     if (searchRef.current) {
    //         searchRef.current.onClear();
    //         setSearchStr("");
    //         search("");
    //     }

    // };
    return (<>
        <Grid item xs={12} sm={fullWidth ? 12 : 12}>
            <TextField
                ref={searchRef}
                outline={props.outline}
                size="small"
                // variant={props.variant}
                label={searchStr ? props.label : ""}
                placeholder={placeholder}
                value={searchStr || ""}
                onChange={onChange}
                className={combineClx(classes.input1, className)}
                bgColor={props.bgColor}
                // helperText={props.helperText ? (<Typography variant="caption" className={classes.helperText}>{props.helperText}</Typography>) : undefined}
                InputProps={{

                    // startAdornment: (<SearchIcon color="inherit" />),
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon className={combineClx(classes.icon, iconClassName)} />
                        </InputAdornment>
                    ),
                    // endAdornment: (
                    //     <React.Fragment>
                    //         {options.loading && searchStr ? <CircularProgress className={classes.icon} size={20} /> : null}
                    //         {!options.loading && searchStr ? <IconButton className={classes.closeButton} onClick={onClear}>
                    //             <CloseIcon fontSize="small" />
                    //         </IconButton> : null}
                    //     </React.Fragment>
                    // ),
                }}

            />
        </Grid>
    </>);
}
SearchPartial.defaultProps = {
    options: {},
    searchParam: "",
    helperTextAlways: false,
    fullWidth: false,
    variant: "outlined",
    label: "",
    placeholder: "",
    searchedData: [],
    bgColor: "transparent",
    outline: true
}

SearchPartial.propTypes = {
    options: PropTypes.any,
    searchParam: PropTypes.string
};

export default SearchPartial;
