/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { Button, Typography } from 'components/ui';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useQuery } from 'utils/hooks';
import { StyledLink } from './withStyledComp';

const useStyles = makeStyles(theme => ({
  rootDiv: props => ({
    borderRadius: theme.spacing(0.75),
    border: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    height: theme.spacing(5.5),
    alignItems: 'center',
    // width: theme.spacing(18.5),
    flex: props.flex ? 1 : 0.3,
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      // width: theme.spacing(23.5),
    },
    marginRight: theme.spacing(2),
    cursor: 'pointer'
  }),
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 2),
    flex: 1,
    height: theme.spacing(4.8),
    alignItems: 'center'
  },
  datePicker: {
    '& .MuiInput-underline:before': {
      border: 0
    },
    '& .MuiInputBase-root': {
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    },
    margin: 0,
    border: 0
  },
  icon: {
    fontSize: theme.spacing(3.125),
    color: theme.palette.grey400
  },
  iconButton: {
    padding: 0,
  },
  resetBrnDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: theme.spacing(2.5),
    cursor: 'pointer'
  },
  btnWidth: {
    width: theme.spacing(5)
  }
}));

function StyledDataRangPicker(props) {
  const { dropdown = false, onChangeDate, searchParam, type = "Payment" } = props;

  let query = useQuery();
  let queryString = query.get(searchParam) ? (query.get(searchParam)).split("?") : "";

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  const classes = useStyles(props);
  const [value, setValue] = React.useState([{
    startDate: undefined,
    endDate: undefined,
    key: 'selection',
  }]);
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyUpTimeout, setKeyUpTimeout] = useState(null);
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];
  const maxDate = type === "Payment" ? new Date() : new Date(new Date().setFullYear(new Date().getFullYear() + 50));
  const minDate = type === "Scheduled" ? new Date() : new Date(new Date().setFullYear(new Date().getFullYear() - 50));

  const displayDate = value && value[0] && value[0].startDate ? new Date(value[0].startDate).getDate() + " " + monthNames[new Date(value[0].startDate).getMonth()] + " - " + new Date(value[0].endDate).getDate() + " " + monthNames[new Date(value[0].endDate).getMonth()] + " " + new Date(value[0].endDate).getFullYear() : "";

  const handleSelect = (ranges) => {
    setValue([ranges.selection]);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (value[0].startDate && value[0].endDate) {
      let dataObj = {
        startDate: new Date((value[0].startDate.getTime()) - (value[0].startDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0],
        endDate: new Date((value[0].endDate.getTime()) - (value[0].endDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0]
      };
      onChangeDate && onChangeDate(dataObj);
    }
    setAnchorEl(null);
  };

  const toggle = () => {
    setShow(!show);
  };

  const clearDate = () => {
    let dataObj = {
      key: 'selection',
    };

    setValue([dataObj]);
    toggle();
    handleClose();
    onChangeDate && onChangeDate("");
  };

  return (
    <>
      <div className={classes.rootDiv}>
        <div className={classes.row} onClick={dropdown ? handleClick : toggle} aria-controls="simple-menu"
          aria-haspopup="true">
          {displayDate ? <Typography variant="caption" >{displayDate}</Typography> : <Typography variant="subtitle2" color="grey400" >{"Select Date"}</Typography>}
          <IconButton className={classes.iconButton} >
            <CalendarTodayIcon className={classes.icon} />
          </IconButton>
        </div>

      </div>
      {
        dropdown ? <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          onClose={handleClose}
          getContentAnchorEl={null}
          className={classes.menuClass}
          disableEnforceFocus
        >
          {
            displayDate !== "" && <div className={classes.resetBrnDiv} >
              <StyledLink onClick={clearDate} ><Typography variant="subtitle1" >Reset</Typography></StyledLink>
            </div>
          }
          <DateRange
            dateDisplayFormat="YYYY-MM-DD"
            ranges={value}
            onChange={handleSelect}
            showMonthAndYearPickers={true}
            showDateDisplay={false}
            editableDateInputs={true}
            maxDate={maxDate}
            minDate={minDate}
            moveRangeOnFirstSelection={false}
          />

        </Menu> :
          show && <DateRange
            dateDisplayFormat="YYYY-MM-DD"
            ranges={value}
            onChange={handleSelect}
            showMonthAndYearPickers={true}
            showDateDisplay={false}
            maxDate={maxDate}
            minDate={minDate}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
          />

      }
    </>
  );
}

StyledDataRangPicker.propTypes = {

};

export default memo(StyledDataRangPicker);
