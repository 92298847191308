import produce from 'immer';
import {
  RESET_CONTACT_REQUEST_BANK,
  GET_REQUEST_DETAIL, GET_REQUEST_DETAIL_SET_DATA,
  UPDATE_BANK_DETAIL, UPDATE_BANK_DETAIL_SET_DATA,
} from './contactRequestBankConstants';

export const initialState = {
  updateBankDetailState: { data: {}, loading: false, error: '' },
  getRequestDetailState: { data: {}, loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const contactRequestBankReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UPDATE_BANK_DETAIL:
        draft.updateBankDetailState = {
          ...draft.updateBankDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case UPDATE_BANK_DETAIL_SET_DATA:
        draft.updateBankDetailState = {
          ...draft.updateBankDetailState,
          ...action.data, loading: false,
        };
        break;
      case GET_REQUEST_DETAIL:
        draft.getRequestDetailState = {
          ...draft.getRequestDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_REQUEST_DETAIL_SET_DATA:
        draft.getRequestDetailState = {
          ...draft.getRequestDetailState,
          ...action.data, loading: false,
        };
        break;
      case RESET_CONTACT_REQUEST_BANK:
        draft = initialState;
        break;
    }
  });

export default contactRequestBankReducer;
