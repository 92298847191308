const EXTERNAL_ACCOUNT_PAD = {
    code: "EXTERNAL_ACCOUNT_PAD",
    name: "External Account PAD",
    path: "/",
    functions: {
        EXTERNAL_ACCOUNT_PAD: {
            code: "EXTERNAL_ACCOUNT_PAD",
            name: "External Account PAD",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT_PAD.path}${EXTERNAL_ACCOUNT_PAD.functions.EXTERNAL_ACCOUNT_PAD.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT_PAD.path}${EXTERNAL_ACCOUNT_PAD.functions.EXTERNAL_ACCOUNT_PAD.path}`,
        }
    },
};

export default EXTERNAL_ACCOUNT_PAD;
