import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import combineClx from 'utils/combineClx';
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  ldsEllipsis: {
    display: "inline-block",
    position: "relative",
    width: "32px",
    height: "8px",
  },
  ldsEllipsisDiv: {
    position: "absolute",
    // top: "33px",
    width: "4px",
    height: "4px",
    // width: "13px",
    // height: "13px",
    borderRadius: "50%",
    background: theme.palette.secondary.main,
    animationTimingFunction: "cubic-bezier(0,1,1,0)",
    "&:nth-child(1)": {
      // left: "8px",
      left: "4px",
      animation: "$ldsEllipsis1 0.6s infinite",
    },
    "&:nth-child(2)": {
      // left: "8px",
      left: "4px",
      animation: "$ldsEllipsis2 0.6s infinite",
    },
    "&:nth-child(3)": {
      // left: "32px",
      left: "16px",
      animation: "$ldsEllipsis2 0.6s infinite",
    },
    "&:nth-child(4)": {
      // left: "56px",
      left: "28px",
      animation: "$ldsEllipsis3 0.6s infinite",
    },
  },
  "@keyframes ldsEllipsis1": {
    "0%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@keyframes ldsEllipsis3": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0)",
    },
  },
  "@keyframes ldsEllipsis2": {
    "0%": {
      transform: "translate(0,0)",
    },
    "100%": {
      // transform: "translate(24px,0)",
      transform: "translate(12px,0)",
    },
  },
}));

function EllipsisLoader(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  return (<div className={classes.ldsEllipsis}>
      <div className={combineClx(classes.ldsEllipsisDiv, classes.ldsEllipsis1)} />
      <div className={combineClx(classes.ldsEllipsisDiv, classes.ldsEllipsis2)} />
      <div className={combineClx(classes.ldsEllipsisDiv, classes.ldsEllipsis3)} />
    </div>);
}

EllipsisLoader.defaultProps = {
  test: " EllipsisLoader component ",
};

EllipsisLoader.propTypes = {
  test: PropTypes.string,
};

export default memo(EllipsisLoader);
