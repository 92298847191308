import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalOtpState = state => state.otp || {};
export const otpState = () =>
  createSelector(GlobalState, substate => substate.otp);

export const sendOtpState = () =>
  createSelector(globalOtpState, substate => substate.sendOtpState);

export const resendOtpState = () =>
  createSelector(globalOtpState, substate => substate.resendOtpState);
