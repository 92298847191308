/* eslint-disable no-unused-vars */
// import { makeStyles } from '@material-ui/core';
import React, { memo, useImperativeHandle, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { StyledDialog } from 'components';
import intl from './intl/OnBoardingIntl';
import { FormattedMessage } from 'react-intl';
import { Typography, Button } from 'components/ui';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: theme.spacing(3)
    },
    title: {
        textAlign: 'center',
        marginTop: theme.spacing(3)
    },
    subTitle: {
        textAlign: 'center',
        margin: theme.spacing(3, 0, 2, 0)
    },
    body: {
        textAlign: 'center',
        margin: theme.spacing(0, 0, 2, 0)
    },
    btnMainDiv: {
        display: 'flex',
        justifyContent: 'space-around',

    },
    btnDiv: {
        margin: theme.spacing(2, 0, 2, 0),
        width: '45%'
    }

}));

function DisclosingBeneficialOwnerDialog(props = {}, ref) {

    const { onContinue = () => null, remainingPercentage = "00" } = props;
    const [open, setOpen] = useState(false);

    const classes = useStyles();

    useImperativeHandle(ref, () => ({
        onOpen,
    }));

    const onOpen = () => {
        setOpen(true);
    };

    const onYes = () => {
        setOpen(false);
        onContinue();
    }
    const onClosePopup = () => {
        setOpen(false);
    };
    return (

        <StyledDialog
            header={false}
            backBtn={false}
            open={open}
            type="content"
            footer={false}
            disableBackdropClick={true}
            onClose={onClosePopup}
            padding={true}
            closeIcon={true}
            minHeight={false}
            noFullScreen={true}
        >
            <div className={classes.root} >
                <Typography variant="titleMobile" className={classes.title} ><FormattedMessage {...intl.discloseBeneficialOwnerTitle} /></Typography>

                <Typography variant="subtitle2" color="body500" className={classes.subTitle} ><FormattedMessage {...intl.discloseBeneficialOwnerSubTitle} values={{ message: `${remainingPercentage}` }} /></Typography>
                <Typography variant="subtitle2" color="body500" className={classes.body} ><FormattedMessage {...intl.discloseBeneficialOwnerBody1} /></Typography>

                <div className={classes.btnMainDiv} >
                    <Button
                        className={classes.btnDiv}
                        variant="outlined"
                        color="primary"
                        title="Go Back"
                        onClick={onClosePopup}
                    />
                    <Button
                        className={classes.btnDiv}
                        color="secondary"
                        title="Yes, Continue"
                        onClick={onYes}
                    />


                </div>
            </div>

        </StyledDialog>

    );
}



export default React.forwardRef(DisclosingBeneficialOwnerDialog);

