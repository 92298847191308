const SCHEDULED_PAYMENTS = {
    code: "SCHEDULED_PAYMENTS",
    name: "Schedule Payment",
    path: "",
    functions: {
        SCHEDULED_PAYMENTS_LIST: {
            code: "SCHEDULED_PAYMENTS_LIST",
            name: "Scheduled Payments",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_LIST.path}`,
            getFullPath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_LIST.path}`,
        },
        SCHEDULED_PAYMENTS_FILTER: {
            code: "SCHEDULED_PAYMENTS_FILTER",
            name: "Scheduled Payments Filter",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_FILTER.path}`,
            getFullPath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_FILTER.path}`,
        },
        SCHEDULED_PAYMENTS_SEARCH: {
            code: "SCHEDULED_PAYMENTS_SEARCH",
            name: "Scheduled Payments Search",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_SEARCH.path}`,
            getFullPath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_SEARCH.path}`,
        },
        SCHEDULED_PAYMENTS_UPDATE: {
            code: "SCHEDULED_PAYMENTS_UPDATE",
            name: "Scheduled Payments Edit",
            isPage: false,
            component: '',
            path: "/:eventScheduleId",
            getRoutePath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_UPDATE.path}`,
            getFullPath: (eventScheduleId) =>
                `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_UPDATE.path}`.replace(
                    ":eventScheduleId",
                    eventScheduleId
                ),
        },
        SCHEDULED_PAYMENTS_DELETE: {
            code: "SCHEDULED_PAYMENTS_DELETE",
            name: "Scheduled Payments Delete",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_DELETE.path}`,
            getFullPath: (eventScheduleId) =>
                `${SCHEDULED_PAYMENTS.path}${SCHEDULED_PAYMENTS.functions.SCHEDULED_PAYMENTS_DELETE.path}`.replace(
                    ":eventScheduleId",
                    eventScheduleId
                ),
        },

    },
};

export default SCHEDULED_PAYMENTS;
