import React, { memo } from 'react';
import {
  makeStyles,
  Grid,
} from '@material-ui/core';
import { Typography } from 'components/ui';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './intl/CardDetailsIntl';

const useStyles = makeStyles(theme => ({

  paymentMainDiv: {
    borderRadius: theme.spacing(1.25),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(2),
  },
  contactView: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(2, 0, 0, 0)
  },
  valueTxt: {
    color: theme.palette.grey[500]
  },


}));

function CardDetails(props) {
  const classes = useStyles();

  const content = () => {

    return (
      <>
        <div className={classes.paymentMainDiv}>

          <Typography className={classes.mainTxt} variant="subtitle2" color="primary">
            <FormattedMessage {...messages.card_details} />{' '}
          </Typography>

          <div className={classes.contactView}>
            <Typography color="grey300" variant="body1">
              <FormattedMessage {...messages.card_number} />:
            </Typography>
            <Typography variant="subtitle1" className={classes.valueTxt}>
              18 mar 2020
            </Typography>
          </div>
          <div className={classes.contactView}>
            <Typography color="grey300" variant="body1">
              <FormattedMessage {...messages.expiration} />:
            </Typography>
            <Typography variant="subtitle1" className={classes.valueTxt}>
              123
            </Typography>
          </div>
          <div className={classes.contactView}>
            <Typography color="grey300" variant="body1" >
              <FormattedMessage {...messages.cvv} />:
            </Typography>
            <Typography variant="subtitle1" className={classes.valueTxt}>
              132
            </Typography>
          </div>
          <div className={classes.contactView}>
            <Typography color="grey300" variant="body1">
              <FormattedMessage {...messages.billing_address} />:
            </Typography>
            <Typography variant="subtitle1" className={classes.valueTxt}>
              123, main st,PA
            </Typography>
          </div>
        </div>
      </>
    );
  }


  return (
    <Grid item xs={12} className={classes.rootGrid}>
      <div className={classes.root}>{content()}</div>
    </Grid>
  );
}

CardDetails.defaultProps = {
  data: {},

}

CardDetails.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.any,

};

export default memo(CardDetails);
