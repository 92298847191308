import produce from 'immer';
import {
  RESET_VERIFICATION,
  ACCEPT_CONTROLLING_PARTY_CONSENT, ACCEPT_CONTROLLING_PARTY_CONSENT_SET_DATA,
  ACCEPT_CONFIDENTIALITY_AGREEMENT, ACCEPT_CONFIDENTIALITY_AGREEMENT_SET_DATA,
  REFRESH_ID_VERIFICATION_TOKEN, REFRESH_ID_VERIFICATION_TOKEN_SET_DATA,
  PERSONAL_DETAILS_FROM_ID_VERIFICATION, PERSONAL_DETAILS_FROM_ID_VERIFICATION_SET_DATA,
  GET_PERSONAL_DETAILS, GET_PERSONAL_DETAILS_SET_DATA,
  UPDATE_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS_SET_DATA,
  UPLOAD_VERIFICATION_DOCUMENT, UPLOAD_VERIFICATION_DOCUMENT_SET_DATA,
  CREATE_PASSWORD, CREATE_PASSWORD_SET_DATA,
  ACCEPT_INVITE, ACCEPT_INVITE_SET_DATA,
  DEPRECATED_GET_TEAM_MEMBER_DETAILS, DEPRECATED_GET_TEAM_MEMBER_DETAILS_SET_DATA,
} from './verificationConstants';

export const initialState = {
  deprecatedGetTeamMemberDetailsState: { data: {}, loading: false, error: '' },
  acceptInviteState: { data: {}, loading: false, error: '' },
  createPasswordState: { data: {}, loading: false, error: '' },
  uploadVerificationDocumentState: { data: {}, loading: false, error: '' },
  updatePersonalDetailsState: { data: {}, loading: false, error: '' },
  getPersonalDetailsState: { data: {}, loading: false, error: '' },
  personalDetailsFromIdVerificationState: { data: {}, loading: false, error: '', loaded: false, loadedCount: 0 },
  refreshIdVerificationTokenState: { data: {}, loading: false, error: '' },
  acceptConfidentialityAgreementState: { data: {}, loading: false, error: '' },
  acceptControllingPartyConsentState: { data: {}, loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const verificationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DEPRECATED_GET_TEAM_MEMBER_DETAILS:
        draft.deprecatedGetTeamMemberDetailsState = {
          ...draft.deprecatedGetTeamMemberDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case DEPRECATED_GET_TEAM_MEMBER_DETAILS_SET_DATA:
        draft.deprecatedGetTeamMemberDetailsState = {
          ...draft.deprecatedGetTeamMemberDetailsState,
          ...action.data, loading: false,
        };
        break;
      case ACCEPT_INVITE:
        draft.acceptInviteState = {
          ...draft.acceptInviteState,
          loading: true, message: '', error: '',
        };
        break;
      case ACCEPT_INVITE_SET_DATA:
        draft.acceptInviteState = {
          ...draft.acceptInviteState,
          ...action.data, loading: false,
        };
        break;
      case CREATE_PASSWORD:
        draft.createPasswordState = {
          ...draft.createPasswordState,
          loading: true, message: '', error: '',
        };
        break;
      case CREATE_PASSWORD_SET_DATA:
        draft.createPasswordState = {
          ...draft.createPasswordState,
          ...action.data, loading: false,
        };
        break;
      case UPLOAD_VERIFICATION_DOCUMENT:
        draft.uploadVerificationDocumentState = {
          ...draft.uploadVerificationDocumentState,
          loading: true, message: '', error: '',
        };
        break;
      case UPLOAD_VERIFICATION_DOCUMENT_SET_DATA:
        draft.uploadVerificationDocumentState = {
          ...draft.uploadVerificationDocumentState,
          ...action.data, loading: false,
        };
        break;
      case UPDATE_PERSONAL_DETAILS:
        draft.updatePersonalDetailsState = {
          ...draft.updatePersonalDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case UPDATE_PERSONAL_DETAILS_SET_DATA:
        draft.updatePersonalDetailsState = {
          ...draft.updatePersonalDetailsState,
          ...action.data, loading: false,
        };
        break;
      case GET_PERSONAL_DETAILS:
        draft.getPersonalDetailsState = {
          ...draft.getPersonalDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_PERSONAL_DETAILS_SET_DATA:
        draft.getPersonalDetailsState = {
          ...draft.getPersonalDetailsState,
          ...action.data, loading: false,
        };
        break;
      case PERSONAL_DETAILS_FROM_ID_VERIFICATION:
        draft.personalDetailsFromIdVerificationState = {
          ...draft.personalDetailsFromIdVerificationState,
          loading: true, message: '', error: '',
        };
        break;
      case PERSONAL_DETAILS_FROM_ID_VERIFICATION_SET_DATA:
        let currentLoadedCount = state?.personalDetailsFromIdVerificationState?.loadedCount || 0
        draft.personalDetailsFromIdVerificationState = {
          ...draft.personalDetailsFromIdVerificationState,
          ...action.data, loading: false, loaded: true, loadedCount: currentLoadedCount + 1
        };
        break;
      case REFRESH_ID_VERIFICATION_TOKEN:
        draft.refreshIdVerificationTokenState = {
          ...draft.refreshIdVerificationTokenState,
          loading: true, message: '', error: '',
        };
        break;
      case REFRESH_ID_VERIFICATION_TOKEN_SET_DATA:
        draft.refreshIdVerificationTokenState = {
          ...draft.refreshIdVerificationTokenState,
          ...action.data, loading: false,
        };
        break;
      case ACCEPT_CONFIDENTIALITY_AGREEMENT:
        draft.acceptConfidentialityAgreementState = {
          ...draft.acceptConfidentialityAgreementState,
          loading: true, message: '', error: '',
        };
        break;
      case ACCEPT_CONFIDENTIALITY_AGREEMENT_SET_DATA:
        draft.acceptConfidentialityAgreementState = {
          ...draft.acceptConfidentialityAgreementState,
          ...action.data, loading: false,
        };
        break;
      case ACCEPT_CONTROLLING_PARTY_CONSENT:
        draft.acceptControllingPartyConsentState = {
          ...draft.acceptControllingPartyConsentState,
          loading: true, message: '', error: '',
        };
        break;
      case ACCEPT_CONTROLLING_PARTY_CONSENT_SET_DATA:
        draft.acceptControllingPartyConsentState = {
          ...draft.acceptControllingPartyConsentState,
          ...action.data, loading: false,
        };
        break;
      case RESET_VERIFICATION:
        draft = initialState;
        break;
    }
  });

export default verificationReducer;
