import produce from "immer";
import {
  RESET_EVENT_SCHEDULER,
  CREATE_EVENT, CREATE_EVENT_SET_DATA,
  DELETE_EVENT,
  DELETE_EVENT_SET_DATA,
} from "./eventSchedulerConstants";

export const initialState = {
  deleteEventState: { data: {}, loading: false, error: '' },
  createEventState: { data: {}, loading: false, error: "" },
};

/* eslint-disable default-case, no-param-reassign */
const eventSchedulerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DELETE_EVENT:
        draft.deleteEventState = {
          ...draft.deleteEventState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_EVENT_SET_DATA:
        draft.deleteEventState = {
          ...draft.deleteEventState,
          ...action.data, loading: false,
        };
        break;
      case RESET_EVENT_SCHEDULER:
        draft = initialState;
        break;
      case CREATE_EVENT:
        draft.createEventState = {
          ...draft.createEventState,
          loading: true,
        };
        break;
      case CREATE_EVENT_SET_DATA:
        if (action.data.response_msg) {
          draft.createEventState = {
            ...action.data, loading: false, error: "",
          };
        } else {
          draft.createEventState = {
            ...action.data, loading: false, error: action.data.error,
          };
        }
        break;
    }
  });

export default eventSchedulerReducer;
