/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logout } from 'utils/auth';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
    center: {
        textAlign: 'center'
    }
}));

function Logout(props = {}) {
    const classes = useStyles();
    const history = useHistory();
    useEffect(() => {
        logout();
        setTimeout(() => {
            history.push("/login")
        }, 10000);
    }, []);
    return (<>
        <div className={classes.root}>
            <div>
                <Typography variant="h6">You have been successfully logged out</Typography>
                <div className={classes.center}><Link to="/login">Login</Link></div>
            </div>
        </div>
    </>);
}

export default Logout;