
import React, { memo } from 'react';
// import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles(theme => ({

// }));

function PageSkeleton(props) {


  return (<>
      
    </>);
}
PageSkeleton.defaultProps = {
}

PageSkeleton.propTypes = {
  
};

export default memo(PageSkeleton);
