import produce from 'immer';
import {
  RESET_CONTACTS,
  GET_CONTACT_LIST, GET_CONTACT_LIST_SET_DATA,
  GET_CONTACT_SCHEDULE_PAYMENT_LIST, GET_CONTACT_SCHEDULE_PAYMENT_LIST_SET_DATA,
  GET_CONTACT_DETAILS, GET_CONTACT_DETAILS_SET_DATA,
  ADD_UPDATE_CONTACT, ADD_UPDATE_CONTACT_SET_DATA,
  ADD_CONTACT_BANK_ACCOUNT, ADD_BANK_ACCOUNT_SET_DATA,
  ADD_UPDATE_CONTACT_NOTE, ADD_UPDATE_CONTACT_NOTE_SET_DATA,
  ADD_CONTACT_CARD, ADD_CONTACT_CARD_SET_DATA,
  LINK_CONTACT, LINK_CONTACT_SET_DATA,
  GET_CONTACT_PAYMENTS, SET_GET_CONTACT_PAYMENTS_RESPONSE,
  DELETE_CONTACT_NOTE, DELETE_CONTACT_NOTE_SET_DATA,
  DELETE_CONTACT, DELETE_CONTACT_SET_DATA,
  GET_CONTACT_NOTES, GET_CONTACT_NOTES_SET_DATA,
  GET_CONTACT_BANK_ACCOUNTS, GET_CONTACT_BANK_ACCOUNTS_SET_DATA,
  DELETE_SCHEDULE_PAYMENT, DELETE_SCHEDULE_PAYMENT_SET_DATA,
  CONTACT_PAYMENT_DETAIL, CONTACT_PAYMENT_DETAIL_SET_DATA,
  GET_CONTACT_SCHEDULE_PAYMENT_DETAILS, GET_CONTACT_SCHEDULE_PAYMENT_DETAILS_SET_DATA,
  GET_CONTACT_NOTES_RESET_DATA, GET_CONTACT_PAYMENT_LIST_RESET_DATA, GET_CONTACT_SCHEDULE_PAYMENT_LIST_RESET_DATA,
  GET_CONTACT_BANK_ACCOUNTS_RESET_DATA,
  GET_CONTACT_LIST_REFRESH,
  IBAN_VALIDATION_SET_DATA,
  CONTACT_FULL_LIST,
  CONTACT_FULL_LIST_SET_DATA,
  UPDATE_CONTACT_BANK_ACCOUNT, UPDATE_CONTACT_BANK_ACCOUNT_SET_DATA,
  RESEND_CONTACT_BANK_DETAIL_REQUEST, RESEND_CONTACT_BANK_DETAIL_REQUEST_SET_DATA,
  ARCHIVE_CONTACT_BANK_ACCOUNT, ARCHIVE_CONTACT_BANK_ACCOUNT_SET_DATA,
} from './contactConstants';

export const initialState = {
  archiveContactBankAccountState: { data: {}, loading: false, error: '' },
  resendContactBankDetailRequestState: { data: {}, loading: false, error: '' },
  updateContactBankAccountState: { data: {}, loading: false, error: '' },
  contactFullListState: { data: {}, loading: false, error: '' },
  getContactSchedulePaymentDetailsState: { data: {}, loading: false, error: '' },
  contactPaymentDetailState: { data: {}, loading: false, error: '' },
  contactSchedulePaymentListState: { data: [], previousData: [], loading: false, error: '' },
  deleteSchedulePaymentState: { data: {}, loading: false, error: '' },
  getContactBankAccountsState: { data: [], loading: false, error: '' },
  getContactNotesState: { data: {}, loading: false, error: '' },
  deleteContactState: { data: {}, loading: false, error: '' },
  deleteContactNoteState: { data: {}, loading: false, error: '' },
  getContactPaymentListState: { data: [], previousData: [], loading: false, error: '', count: '' },
  linkContactState: { data: {}, loading: false, error: '' },
  addUpdateContactNoteState: { data: {}, loading: false, error: '' },
  addContactBankAccountState: { data: {}, loading: false, error: '' },
  addContactCardState: { data: {}, loading: false, error: '' },
  addUpdateContactState: { data: {}, error: {}, loading: false, },
  contactDetailsState: { data: {}, loading: false, error: '' },
  contactListState: { data: [], myContacts: [], suggestedContacts: [], recentContacts: [], previousData: [], count: '', loading: false, error: '', },
  contactListRefresh: { refresh: false },
  validateIbanState: { data: {}, loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const contactsReducer = (state = initialState, action) =>
  produce(state, draft => {

    switch (action.type) {
      case ARCHIVE_CONTACT_BANK_ACCOUNT:
        draft.archiveContactBankAccountState = {
          ...draft.archiveContactBankAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case ARCHIVE_CONTACT_BANK_ACCOUNT_SET_DATA:
        draft.archiveContactBankAccountState = {
          ...draft.archiveContactBankAccountState,
          ...action.data, loading: false,
        };
        break;
      case RESEND_CONTACT_BANK_DETAIL_REQUEST:
        draft.resendContactBankDetailRequestState = {
          ...draft.resendContactBankDetailRequestState,
          loading: true, message: '', error: '',
        };
        break;
      case RESEND_CONTACT_BANK_DETAIL_REQUEST_SET_DATA:
        draft.resendContactBankDetailRequestState = {
          ...draft.resendContactBankDetailRequestState,
          ...action.data, loading: false,
        };
        break;
      case UPDATE_CONTACT_BANK_ACCOUNT:
        draft.updateContactBankAccountState = {
          ...draft.updateContactBankAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case UPDATE_CONTACT_BANK_ACCOUNT_SET_DATA:
        draft.updateContactBankAccountState = {
          ...draft.updateContactBankAccountState,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_SCHEDULE_PAYMENT_DETAILS:
        draft.getContactSchedulePaymentDetailsState = {
          ...draft.getContactSchedulePaymentDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CONTACT_LIST_REFRESH:
        draft.contactListRefresh = {
          ...draft.contactListRefresh,
          refresh: action.data
        };
        break;

      case GET_CONTACT_SCHEDULE_PAYMENT_DETAILS_SET_DATA:
        draft.getContactSchedulePaymentDetailsState = {
          ...draft.getContactSchedulePaymentDetailsState,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_BANK_ACCOUNTS:
        draft.getContactBankAccountsState = {
          // ...draft.getContactBankAccountsState,
          data: [],
          loading: true, message: '', error: '',
        };
        break;

      case GET_CONTACT_BANK_ACCOUNTS_SET_DATA:
        draft.getContactBankAccountsState = {
          ...draft.getContactBankAccountsState,
          ...action.data, loading: false, loaded: true
        };
        break;
      case GET_CONTACT_BANK_ACCOUNTS_RESET_DATA:
        draft.getContactBankAccountsState = {
          ...draft.getContactBankAccountsState,
          data: {}, loading: false, error: '',
        };
        break;
      case CONTACT_FULL_LIST:
        draft.contactFullListState = {
          ...draft.contactFullListState,
          loading: true, message: '', error: '',
        };
        break;

      case CONTACT_FULL_LIST_SET_DATA:
        draft.contactFullListState = {
          ...draft.contactFullListState,
          ...action.data, loading: false,
        };
        break;
      case CONTACT_PAYMENT_DETAIL:
        draft.contactPaymentDetailState = {
          ...draft.contactPaymentDetailState,
          loading: true, message: '', error: '',
        };
        break;

      case CONTACT_PAYMENT_DETAIL_SET_DATA:
        draft.contactPaymentDetailState = {
          ...draft.contactPaymentDetailState,
          ...action.data, loading: false,
        };
        break;
      case DELETE_SCHEDULE_PAYMENT:
        draft.deleteSchedulePayment = {
          ...draft.deleteSchedulePayment,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_SCHEDULE_PAYMENT_SET_DATA:
        draft.deleteSchedulePayment = {
          ...draft.deleteSchedulePayment,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_NOTES:
        draft.getContactNotesState = {
          ...draft.getContactNotesState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CONTACT_NOTES_SET_DATA:
        draft.getContactNotesState = {
          ...draft.getContactNotesState,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_NOTES_RESET_DATA:
        draft.getContactNotesState = {
          ...draft.getContactNotesState,
          data: {}, loading: false, error: '',
        };
        break;
      case DELETE_CONTACT:
        draft.deleteContactState = {
          ...draft.deleteContactState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_CONTACT_SET_DATA:
        draft.deleteContactState = {
          ...draft.deleteContactState,
          ...action.data, loading: false,
        };
        break;
      case DELETE_CONTACT_NOTE:
        draft.deleteContactNoteState = {
          ...draft.deleteContactNoteState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_CONTACT_NOTE_SET_DATA:
        draft.deleteContactNoteState = {
          ...draft.deleteContactNoteState,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_PAYMENTS:
        draft.getContactPaymentListState = {
          ...draft.getContactPaymentListState,
          loading: true, message: '', error: '', count: ''
        };
        break;
      case SET_GET_CONTACT_PAYMENTS_RESPONSE:

        const contactPaymentApiData = action.data;

        const previousPaymentData = state.getContactPaymentListState.previousData;

        var NewPaymentData = {};

        if (action && action.page > 0) {
          NewPaymentData.data = previousPaymentData.concat(contactPaymentApiData.data);
        } else {

          NewPaymentData.data = contactPaymentApiData.data;
        }

        const count = (action && action.data && action.data.count) || 0;

        draft.getContactPaymentListState = {
          ...draft.getContactPaymentListState,
          ...NewPaymentData, loading: false,
          previousData: NewPaymentData.data,
          count: count
        };
        break;
      case GET_CONTACT_PAYMENT_LIST_RESET_DATA:
        draft.getContactPaymentListState = {
          ...draft.getContactPaymentListState,
          data: {}, loading: false, error: '',
        };
        break;
      case LINK_CONTACT:
        draft.linkContactState = {
          ...draft.linkContactState,
          loading: true, message: '', error: '',
        };
        break;
      case LINK_CONTACT_SET_DATA:
        draft.linkContactState = {
          ...draft.linkContactState,
          ...action.data, loading: false,
        };
        break;
      case ADD_CONTACT_BANK_ACCOUNT:
        draft.addContactBankAccountState = {
          ...draft.addContactBankAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_BANK_ACCOUNT_SET_DATA:
        draft.addContactBankAccountState = {
          ...draft.addContactBankAccountState,
          ...action.data, loading: false,
        };
        break;
      case ADD_UPDATE_CONTACT:
        draft.addUpdateContactState = {
          ...draft.addUpdateContactState,
          loading: true, message: '', error: {},
        };
        break;
      case ADD_UPDATE_CONTACT_SET_DATA:
        draft.addUpdateContactState = {
          ...draft.addUpdateContactState,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_DETAILS:
        draft.contactDetailsState = {
          ...draft.contactDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CONTACT_DETAILS_SET_DATA:
        draft.contactDetailsState = {
          ...draft.contactDetailsState,
          ...action.data, loading: false,
        };
        break;
      case GET_CONTACT_LIST:
        draft.contactListState = {
          ...draft.contactListState,
          loading: true, message: '', error: '',
          data: [],
          myContacts: [],
          suggestedContacts: [],
          recentContacts: [],
          count: ''
        };
        break;
      case GET_CONTACT_LIST_SET_DATA:
        const apiData = action.data;
        const previousData = state.contactListState.previousData;
        var NewData = { ...apiData };

        if (action && action.page > 0) {
          NewData.data = previousData.concat(apiData.data);
        } else {
          NewData.data = apiData.data;
        }

        const myContacts = NewData.data.filter(i => i.my_contact);
        const suggestedContacts = NewData.data.filter(i => !i.my_contact);
        let totalCount = (action && action.data && action.data.count) || 0;

        draft.contactListState = {
          ...draft.contactListState,
          ...NewData, loading: false,
          previousData: NewData.data,
          myContacts, suggestedContacts, count: totalCount
        };
        break;
      case GET_CONTACT_SCHEDULE_PAYMENT_LIST:
        draft.contactSchedulePaymentListState = {
          ...draft.contactSchedulePaymentListState,
          loading: true, message: '', error: '',
          data: [],
        };
        break;

      case GET_CONTACT_SCHEDULE_PAYMENT_LIST_SET_DATA:
        const scheduleData = action.data;

        const schedulePreviousData = state.contactSchedulePaymentListState.previousData;

        var scheduleNewData = { ...scheduleData };
        let scheduleCount = (action && action.data && action.data.count) || 0;

        if (action && action.page > 0) {
          scheduleNewData.data = schedulePreviousData.concat(scheduleData.data);
        } else {
          scheduleNewData.data = scheduleData.data;
        }

        draft.contactSchedulePaymentListState = {
          ...draft.contactSchedulePaymentListState,
          ...scheduleNewData, loading: false,
          previousData: scheduleNewData.data, count: scheduleCount
        };
        break;

      case GET_CONTACT_SCHEDULE_PAYMENT_LIST_RESET_DATA:
        draft.contactSchedulePaymentListState = {
          ...draft.contactSchedulePaymentListState,
          data: [], loading: false, error: '',
        };
        break;
      case ADD_CONTACT_CARD:
        draft.addContactCardState = {
          ...draft.addContactCardState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_CONTACT_CARD_SET_DATA:
        draft.addContactCardState = {
          ...draft.addContactCardState,
          ...action.data, loading: false,
        };
        break;
      case ADD_UPDATE_CONTACT_NOTE:
        draft.addUpdateContactNoteState = {
          ...draft.addUpdateContactNoteState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_UPDATE_CONTACT_NOTE_SET_DATA:
        draft.addUpdateContactNoteState = {
          ...draft.addUpdateContactNoteState,
          ...action.data, loading: false,
        };
        break;
      case IBAN_VALIDATION_SET_DATA:
        draft.validateIbanState = {
          ...draft.validateIbanState,
          data: { ...action.data },
          loading: true,
        };
        break;
      case RESET_CONTACTS:
        draft = initialState;
        break;

    }
  });

export default contactsReducer;
