
import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCountryListAction } from 'store/Reference/referenceActions';

function DisplayCountry(props = {}) {
    // const classes = useStyles();
    // const { allCountries, destinationCountries, onlyDestination } = props;
    const { countries, getCountries, countryName } = props;
    // const countries = onlyDestination ? destinationCountries : allCountries;
    useEffect(() => {
        if (!countries?.data?.length) {
            getCountries();
        }
    })
    
    return countryName || null;
}

// const mapStateToProps = (s, p) => {

//     return createStructuredSelector({
//         countries: getCountryListState(),
//     });
// };

const mapStateToProps = (s, p) => {
    const countries = s.references.getCountryListState || { data: [], loading: false, error: '' };
    const country = countries.data.find(i => i.country_code === p.countryCode) || {};
    return { countries, countryName: country.country_name  };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountries: data => dispatch(getCountryListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(DisplayCountry);