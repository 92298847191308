/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState } from 'react';
import {
    makeStyles, // FormControlLabel,
    Grid,
    Divider,
    useMediaQuery,
    useTheme
} from "@material-ui/core";

import {
    Typography, Button,
} from "components/ui";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AddressInput, BackStepButton, Loader } from 'components';
import PictureUpload from 'containers/Common/PictureUpload';
import { VERIFICATION_BASE_API_URL } from 'config/apiUrls';
import { updatePersonalDetailsAction, uploadVerificationDocumentAction, uploadVerificationDocumentSetDataAction } from 'store/Verification/verificationActions';
import { updatePersonalDetailsState, uploadVerificationDocumentState } from 'store/Verification/verificationSelectors';
import NoDocInstructions from './NoDocInstructions';
import { initialState } from 'store/Verification/verificationReducer';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/Verification/Intl/VerificationIntl';


const VERIFICATION_URL = `${VERIFICATION_BASE_API_URL}/verification`;

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    root: {
        // textAlign: 'center',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    },
    container: {
        padding: theme.spacing(0, 0, 4, 0),
        // justifyContent: 'space-between',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',


    },
    bodyTxt: {
        textAlign: 'center'
    },

    bodyTxtCenter: {
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },

    spanTxt: {
        color: 'black',
        fontWeight: 'bold'
    },
    inputDiv: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    rowInputDiv: {
        marginTop: theme.spacing(2),

        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    radioInoutDiv: {
        marginTop: theme.spacing(2),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    addressTitle: {
        textAlign: 'left',
        borderBottom: '1px solid black'
    },
    addressCheck: {
        textAlign: 'left',
    },
    continueBtn: {
        marginTop: theme.spacing(3),
    },
    stateAndZipDiv: {
        display: 'flex',
    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    underline: {
        textDecoration: 'underline'
    },
    txtRoot: {
        margin: theme.spacing(4, 1, 0, 1)
    },
    subtitleDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    subtitle2: {
        ...theme.typography.subtitle2
    },
    divider: {
        backgroundColor: theme.palette.grey600
    },
    titleTxt: {
        marginTop: theme.spacing(3)
    },
    spacing: {
        width: theme.spacing(2)
    },
    commonFlex: {
        flex: 1,
        display: 'contents'
    },
    radioFlex: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'center',
        }
    },
    uploadProofTitle: {
        marginTop: theme.spacing(3),
        textAlign: 'center'
    },
    proofHelperTxt: {
        margin: theme.spacing(1, 0, 0, 0),
        textAlign: 'center',
        color: theme.palette.accent.main
    },
    dontHaveTxt: {
        textDecoration: 'underline',
        textAlign: 'center',
        margin: theme.spacing(0, 0, 6, 0),
        cursor: 'pointer'
    },
    lastMainGrid: {

        margin: theme.spacing(3, 0, 2, 0),
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
            marginTop: 'auto',
        }
    },
    btnDiv: {
        flex: 1,

    },
    uploadDivExternal: {
        margin: `${theme.spacing(3, 0, 2, 0)}`,
    },

}));

function ChangeCurrentAddress(props = {}) {
    const classes = useStyles();
    const { data = {}, onStepChange = () => null, updatePersonalDetailsState,
        updatePersonalDetails, uploadVerificationDocumentState, uploadVerificationDocument,
        resetUploadVerificationDocument, isLoading } = props;
    // eslint-disable-next-line no-unused-vars
    const [verificationDocData, setVerificationDocData] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [radioError, setRadioError] = useState([]);
    const [noDocAvailable, setNoDocAvailable] = useState(false);
    const theme = useTheme();
    const xsDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const isLoadingData = isLoading || updatePersonalDetailsState.loading;
    const [residentialAddress, setResidentialAddress] = useState({
        address_id: data.prs_address_residential.address_id || "",
        address_line_1: data.prs_address_residential.address_line_1 || "",
        address_line_2: data.prs_address_residential.address_line_2 || "",
        landmark: data.prs_address_residential.landmark || "",
        city: data.prs_address_residential.city || "",
        state_code: data.prs_address_residential.state_code || "",
        state_name: data.prs_address_residential.state_name || "",
        postal_code: data.prs_address_residential.postal_code || "",
        country_code: data.prs_address_residential.country_code || data.entity_person_country_of_residence || "",
    });

    const handleSubmit = (e) => {
        e?.preventDefault();
        updatePersonalDetails({
            personId: data.entity_person_id, token: data.continue_signup_token, body: {
                primary_address_same_as_berbix: data.primary_address_same_as_berbix,
                residentialAddress
            },
            cb: onStepChange,
        });
    };

    const onDontHaveDoc = () => {
        setNoDocAvailable(true);
    };
    const onFileChange = (e) => {
        const { name, value, response = {} } = e.target || {};
        setVerificationDocData(verificationDocData => ({ ...verificationDocData, [name]: value, ...response }));
        setResidentialAddress(formData => ({ ...formData, 'address_verification_ref_id': response.document_id }));
    };
    const uploadVerificationDocumentHandler = (uploadData) => {
        uploadVerificationDocument({ ...uploadData, personId: data.entity_person_id, token: data.continue_signup_token });
    };

    const UnderLineTitle = ({ title }) => {
        return (<>
            <Typography className={classes.titleTxt} variant="subtitle2" color="grey700">{title}</Typography>
            <Divider className={classes.divider} />
        </>)
    }

    if (noDocAvailable) {
        return (<NoDocInstructions
            onGoBack={() => setNoDocAvailable(false)}
        />)
    }
    if (isLoading) {
        return <Loader />
    }
    return (<form onSubmit={handleSubmit} className={classes.form} >
        <div className={classes.root}>
            <Typography color="primary" variant="h6" className={classes.bodyTxtCenter}>
                <FormattedMessage {...intl.updateAddress} />
            </Typography>
            <div className={classes.container}>
                <UnderLineTitle title="Your Current Residential Address" />
                <AddressInput addressKey="residential" data={residentialAddress} onChange={setResidentialAddress} />
                <div>
                    <Typography variant="subtitle1" color="grey700" className={classes.uploadProofTitle} ><FormattedMessage {...intl.uploadAdd} /></Typography>
                    <Typography variant="caption" className={classes.proofHelperTxt} ><FormattedMessage {...intl.ex} /></Typography>
                    <PictureUpload
                        id={`document`}
                        btnName={"Take a photo"}
                        required
                        columnWise={xsDownMatches ? false : true}
                        key='ex1'
                        // url={`${VERIFICATION_URL}/verification-document-upload?id=${data.entity_person_id}&token=${data.continue_signup_token}`}
                        url={`${VERIFICATION_URL}/verification-document-upload`}
                        name="verification_doc_name"
                        onChange={onFileChange}
                        value={verificationDocData.verification_doc_name || (residentialAddress.address_verification_ref_id ? 'Uploaded' : '')}
                        previewUrl={verificationDocData.public_url || (residentialAddress.address_verification_ref_id ? 'Uploaded' : '')}
                        fileName={verificationDocData.verification_doc_name || (residentialAddress.address_verification_ref_id ? 'Uploaded' : '')}
                        showPreview
                        method="POST"
                        className={classes.uploadDivExternal}
                        formCustomizer={form => form}
                        beforeSend={request => request}
                        documentUploadState={uploadVerificationDocumentState}
                        documentUpload={uploadVerificationDocumentHandler}
                        resetDocumentUpload={resetUploadVerificationDocument}
                    />
                    <span onClick={onDontHaveDoc} className={classes.dontHaveTxt} ><Typography ><FormattedMessage {...intl.notHave} /></Typography></span>
                </div>
                {isLoadingData && <Loader />}
                <Grid container className={classes.lastMainGrid}>
                    <BackStepButton onClick={() => onStepChange('PERSONAL_DETAILS')} />
                    <Grid className={classes.btnDiv} md={6}>
                        <Button
                            disabled={isLoadingData}
                            title={"Submit"}
                            className={classes.btnDiv}
                            type="submit"
                            color="secondary"
                        />
                    </Grid>
                </Grid>
            </div>
        </div></form >)
}

const mapStateToProps = createStructuredSelector({
    updatePersonalDetailsState: updatePersonalDetailsState(),
    uploadVerificationDocumentState: uploadVerificationDocumentState(),
});

function mapDispatchToProps(dispatch) {
    return {
        updatePersonalDetails: data => dispatch(updatePersonalDetailsAction(data)),
        uploadVerificationDocument: data => dispatch(uploadVerificationDocumentAction(data)),
        resetUploadVerificationDocument: () => dispatch(uploadVerificationDocumentSetDataAction(initialState.documentUploadState))
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(ChangeCurrentAddress);