import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Loader, StyledError } from 'components';
import { Grid, makeStyles } from '@material-ui/core';
import VerificationSubmitted from 'containers/Verification/Common/VerificationSubmitted';
import IdVerificationInstruction from 'containers/Verification/Common/IdVerificationInstruction';
import { getPersonalDetailsAction } from 'store/Verification/verificationActions';
import { getPersonalDetailsState } from 'store/Verification/verificationSelectors';
import { ONBOARDING_STEPS } from '@truly-financial/common/consts/entity-person.consts';
import MailingAddress from './Common/MailingAddress';
import UpdateRequiredDetails from './Common/UpdateRequiredDetails';
import ChangeCurrentAddress from './Common/ChangeCurrentAddress';

const {
    ID_VERIFICATION,
    PERSONAL_DETAILS,
    PRIMARY_ADDRESS,
    COMMUNICATION_ADDRESS
} = ONBOARDING_STEPS;

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        //minHeight: `calc(100vh)`,
    },
    infoDiv: {
        textAlign: 'center',
        margin: theme.spacing(10, 1),
    },
}));

function PrimaryPersonVerification(props = {}) {
    const classes = useStyles();
    const { personalDetailsState, getPersonalDetails, isModal } = props;
    const entityPersonData = personalDetailsState.data || {};
    const [step, setStep] = useState('');
    const isLoading = ((!entityPersonData.entity_person_id) || (personalDetailsState.loading));

    useEffect(() => {
        // if (!entityPersonData.entity_person_id) handleTeamMemberDetails();
        handleTeamMemberDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTeamMemberDetails = () => {
        getPersonalDetails();
    };

    useEffect(() => {
        setStep(entityPersonData.primaryPersonCurrentStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalDetailsState]);

    const handleStepChange = (s) => {
        if (s) return setStep(s);
        getPersonalDetails();
    };

    // if (!entityPersonData.entity_person_id && personalDetailsState.loading) {
    //     return (<>
    //         <Loader />
    //     </>);
    // }

    if (personalDetailsState.error || personalDetailsState.status === 404) {
        return (<>
            <StyledError data={personalDetailsState} retry={handleTeamMemberDetails} />
        </>);
    }

    return (<div className={classes.root}>
        {isLoading && <Loader />}
        <Grid container justify="center">
            <Grid xs={12} md={12} lg={12}>
                {step === ID_VERIFICATION &&
                    (<IdVerificationInstruction
                        isLoading={isLoading}
                        data={entityPersonData}
                        verificationLink={entityPersonData.id_verification_link}
                        onStepChange={handleStepChange}
                        getPersonalDetails={getPersonalDetails}
                        isModal={isModal}
                    />)
                }
                {step === PERSONAL_DETAILS &&
                    (<UpdateRequiredDetails
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {(step === PRIMARY_ADDRESS) &&
                    (<ChangeCurrentAddress
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {(step === COMMUNICATION_ADDRESS) &&
                    (<MailingAddress
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {!step && entityPersonData.entity_id &&
                    (<div className={classes.infoDiv}>
                        <VerificationSubmitted
                            data={entityPersonData}
                            showLoginBtn={false}
                        />
                    </div>)
                }
            </Grid>
        </Grid>
    </div>);
}

const mapStateToProps = createStructuredSelector({
    personalDetailsState: getPersonalDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getPersonalDetails: data => dispatch(getPersonalDetailsAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(PrimaryPersonVerification);
