
import { Button, Typography } from 'components/ui';
import React, { memo, useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';

function FileUpload(props = {}) {
  const { multiple = false, onSelectFile, renderComponent, disabled, accept = 'image/*' } = props;
  const [files, setFiles] = useState([{}]);
  const onDrop = useCallback(acceptedFiles => {
    const filesWithPreview = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    setFiles(filesWithPreview);
    onSelectFile(filesWithPreview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<Dropzone disabled={disabled} onDrop={onDrop} accept={accept} multiple={multiple}>
    {({ getRootProps, getInputProps }) => (
      <section>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {renderComponent ? renderComponent(files) : <>
            <Button>Upload</Button>
            {files.map(file => (<Typography>{file.name}</Typography>))}
          </>}
        </div>
      </section>
    )}
  </Dropzone>)
}

FileUpload.defaultProps = {
  onSelectFile: () => null,
  multiple: true,
  renderComponent: false,
  disabled: false
}

FileUpload.propTypes = {
};

export default memo(FileUpload);
