/* eslint-disable no-script-url */
import React, { memo, useEffect, useState } from 'react';
import { Avatar, Grid, ListItem, makeStyles, useMediaQuery, useTheme, Icon } from '@material-ui/core';
import { Typography } from 'components/ui';
import Button from 'components/ui/Button';
import { createStructuredSelector } from 'reselect';
import { getEntitiesState } from 'store/User/userSelectors';
import { getEntityListAction, setSelectedEntityAction } from 'store/User/userActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { backupAndRemoveEntityId, setEntityId } from 'utils/auth';
import { StyledError, TrulyLoader } from 'components';
import StoreIcon from '@material-ui/icons/Store';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useHistory } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import { getCustomerDetailsSetDataAction } from 'store/Register/registerActions';

const useStyles = makeStyles((theme) => ({
  txt: {
    textAlign: "left",
  },
  headings: {},
  subHeading: {
    margin: theme.spacing(1, 0),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  orgDiv: {
    display: "flex",
    alignItems: "stretch",
    overflowX: 'auto',
    margin: theme.spacing(0, -2, 0, 0),
    padding: theme.spacing(0, 0, 1, 0),
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: "#D2E8F1",
    },
    // [theme.breakpoints.down("xs")]: {
    //   flexWrap: "wrap",
    // }
  },
  org: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    minHeight: "148px",
    maxWidth: "131px",
    alignItems: "flex-start",
    padding: "16px 16px",
    "&:hover": {
      backgroundColor: "#D2E8F1",
    },
  },
  orgName: {
    textAlign: "center",
    padding: "8px 0",
  },
  orgImageAndName: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  orgIcon: {
    fontSize: theme.spacing(6),
  },
  lastBtnDiv: {
    // margin: theme.spacing(9.75, 0, 0, 0),
    // margin: theme.spacing(9.75, 12.5, 0, 0),
    margin: theme.spacing(8, 'auto'),
    maxWidth: theme.spacing(60)
    // width: theme.spacing(60)
  }
}));

function SelectOrg(props = {}) {
  const { entities, setSelectedEntityAction, setLoading, getEntities, 
    // resetCustomerDetails
  } = props;
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const entityData = entities.data || [];
  const classes = useStyles();
  const history = useHistory();
  const defaultEntityObjForError = entities.error ? entities : { error: { message: 'No business Profile found!' } };
  const [selectedEntity, setSelectedEntity] = useState({});

  useEffect(() => {
    if (entities.isLoaded) {
      if (!entities.data.length && !entities.error) {
        setLoading(true);
        // resetCustomerDetails();
        // setTimeout(() => {
        //   setLoading(false);
        //   history.push('/complete-registration')
        // }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities.isLoaded]);

  const handleSubmit = (e, entity = selectedEntity) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    if (!entity.entity_id) {
      addNewOrg();
      return;
    }
    setTimeout(() => {
      setLoading(false);
      setEntityId(entity.entity_id);
      setSelectedEntityAction({ data: entity });
    }, 400);
  };

  const onEntityChange = (entity) => {
    setSelectedEntity(entity);
  };

  const onDoubleClick = (entity) => {
    handleSubmit({}, entity);
  };

  const addNewOrg = () => {
    backupAndRemoveEntityId()
    setTimeout(() => {
      setLoading(false);
      history.push('/complete-registration');
    }, 500);
  }

  if ((entities.loading)) {
    // return <Loader />;
    return <TrulyLoader />;
  }
  if (entities.error) {
    return (<StyledError data={defaultEntityObjForError} retry={getEntities} />);
  }

  if (!entities.isLoaded) {
    return null;
  }

  return (
    <Grid
      item
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.headings}>
          <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Welcome Back</Typography>
          <Typography color="grey300" variant={isXsScreen ? "subtitle1" : "h6"} className={classes.subHeading}>Select your business profile:
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.orgDiv}>
          {entityData.map(i => <ListItem value={i} onDoubleClick={() => onDoubleClick(i)} onClick={() => onEntityChange(i)} key={i.entity_id} selected={i.entity_id === selectedEntity.entity_id} alignItems="center" button className={classes.org}>
            <div className={classes.orgImageAndName}>
              <Avatar
                alt="DP"
                src={i.image_url}
                className={classes.large}
                style={{ backgroundColor: i.backgroundColor }}
              >
                {/* <Typography variant="h4">{i.company_name[0]}</Typography>  */}
                <Icon color="primary" style={{ color: i.color }} className={classes.orgIcon} fontSize="xlarge" component={StoreIcon} />
              </Avatar>
              <div>
                <Typography variant="body2" color="grey700" className={classes.orgName}> {i.company_name}</Typography>
              </div>
            </div>
          </ListItem>)}
          <ListItem onDoubleClick={() => onDoubleClick({isNew: true})} onClick={() => onEntityChange({isNew: true})} key={'new'} selected={selectedEntity.isNew === true} alignItems="center" button className={classes.org}>
            <div className={classes.orgImageAndName}>
              <Avatar
                alt="DP"
                src={""}
                className={classes.large}
                style={{ backgroundColor: '#e0e0e06b' }}
              >
                {/* <Typography variant="h4">{i.company_name[0]}</Typography>  */}
                <Icon color="primary" className={classes.orgIcon} fontSize="xlarge" component={AddIcon} />
              </Avatar>
              <div>
                <Typography variant="body2" color="grey700" className={classes.orgName}>Add New</Typography>
              </div>
            </div>
          </ListItem>
          {/* {((!entityData.length && !entities.loading) || entities.error) && <StyledError data={defaultEntityObjForError} retry={getEntities} />} */}
          {/* {(entities.error) && <StyledError data={defaultEntityObjForError} retry={getEntities} />} */}
        </div>
      </Grid>

      <Grid justifyContent="center" className={classes.lastBtnDiv} item xs={12}>
        <Button
          endIcon={<NavigateNextIcon />}
          title="Continue"
          disabled={(!selectedEntity.entity_id && !selectedEntity.isNew)}
          onClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
}

const mapStateToProps = createStructuredSelector({
  entities: getEntitiesState(),
});

function mapDispatchToProps(dispatch) {
  return {
    getEntities: (data) => dispatch(getEntityListAction(data)),
    setSelectedEntityAction: (data) => dispatch(setSelectedEntityAction(data)),
    resetCustomerDetails: (data) => dispatch(getCustomerDetailsSetDataAction(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(SelectOrg);

