/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { /*Checkbox,*/ IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { TextField } from './ui';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import useCommonStyles from 'styles';
import PasswordProgressBar from './PasswordProgressBar';

const useStyles = makeStyles((theme) => ({
  root: {},
  hintDiv: {
    position: "absolute",
    zIndex: 999,
    background: "white",
    padding: "8px",
    margin: "-4px -14px",
    border: "1px solid #f2f3f4",
    borderTop: "none",
    width: "100%",
  },
  errorDiv: {
    padding: "8px",
    margin: "-4px -14px",
    border: "1px solid #f2f3f4",
    borderTop: "none",
  },
  checkboxRoot: {
    padding: '0 !important',
  },
  policyDiv: {
    color: 'gray',
  }
}));

const regs = {
  minLength: '^.{8,}',
  oneUppercase: '^(?=.*[A-Z])',
  oneLowercase: '^(?=.*[a-z])',
  oneNumber: '^(?=.*[0-9])',
  oneSpecialCharacter: '^(?=.*[!@#$%^&*])',
  password: '^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[a-z]).{8,}$',
};

const initialSteps = {
  hasMinLength: false,
  hasOneUppercase: false,
  hasOneLowercase: false,
  hasOneNumber: false,
  hasOneSpecialCharacter: false
}

function NewPasswordInput(props = {}) {
  // const commonClasses = useCommonStyles(props);
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(props);
  const { id, name, onChange = () => null, buttonChangeHandler = () => null } = props;
  const [password, setPassword] = useState(props.value || '');
  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isFocused, setIsFocused] = useState(false);
  const [steps, setSteps] = useState(initialSteps);
  const [progress, setProgress] = useState(0);

  const handleClickShowPassword = (e) => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleChange = useCallback((e) => {
    const { value } = e.target || {};
    setPassword(value);
    onChange(e);
  }, []);
  const onFocus = (e) => {
    setIsFocused(true);
  };
  const onBlur = (e) => {
    setIsFocused(false);
  };

  const checkTrueValues = () => {
    let counter = 0;
    for (let step in steps) {
      if (steps[step] === true) {
        counter = counter + 1;
      }
    }
    if (counter === 1) {
      setProgress(20);
    } else if (counter === 2) {
      setProgress(40);
    } else if (counter === 3) {
      setProgress(60);
    } else if (counter === 4) {
      setProgress(80);
    } else if (counter === 5) {
      setProgress(100);
    } else {
      setProgress(0);
    }
  };

  const hasOneUppercase = new RegExp(regs.oneUppercase).test(password);
  const hasOneLowercase = new RegExp(regs.oneLowercase).test(password);
  const hasOneNumber = new RegExp(regs.oneNumber).test(password);
  const hasOneSpecialCharacter = new RegExp(regs.oneSpecialCharacter).test(password);
  const hasMinLength = new RegExp(regs.minLength).test(password);

  useEffect(() => {
    setSteps((prevSteps) => {
      return {
        ...prevSteps,
        hasOneUppercase: hasOneUppercase,
        hasOneLowercase: hasOneLowercase,
        hasOneNumber: hasOneNumber,
        hasOneSpecialCharacter: hasOneSpecialCharacter,
        hasMinLength: hasMinLength
      };
    });
  }, [hasOneUppercase, hasOneLowercase, hasOneNumber, hasOneSpecialCharacter, hasMinLength])

  useEffect(() => {
    checkTrueValues();
    buttonChangeHandler(progress);
  }, [steps, progress]);

  // const HelperText = ({ error }) => {
  //   if (error && !password) return 'Required';
  //   const hasOneUppercase = new RegExp(regs.oneUppercase).test(password);
  //   const hasOneLowercase = new RegExp(regs.oneLowercase).test(password);
  //   const hasOneNumber = new RegExp(regs.oneNumber).test(password);
  //   const hasOneSpecialCharacter = new RegExp(regs.oneSpecialCharacter).test(password);
  //   const hasMinLength = new RegExp(regs.minLength).test(password);

  //   return (<div className={error ? classes.errorDiv : classes.hintDiv}>
  //     <div>Password must have at least:</div>
  //     <div className={hasOneUppercase && classes.policyDiv}>
  //       <Checkbox
  //         className={classes.checkboxRoot}
  //         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
  //         checkedIcon={<CheckBoxIcon fontSize="small" />}
  //         checked={hasOneUppercase}
  //         name="one"
  //       /> 1 Uppercase letter
  //     </div>
  //     <div className={hasOneLowercase && classes.policyDiv}>
  //       <Checkbox
  //         className={classes.checkboxRoot}
  //         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
  //         checkedIcon={<CheckBoxIcon fontSize="small" />}
  //         checked={hasOneLowercase}
  //         name="one"
  //       /> 1 Lowercase letter</div>
  //     <div className={hasOneNumber && classes.policyDiv}>
  //       <Checkbox
  //         className={classes.checkboxRoot}
  //         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
  //         checkedIcon={<CheckBoxIcon fontSize="small" />}
  //         checked={hasOneNumber}
  //         name="one"
  //       /> 1 Number</div>
  //     <div className={hasOneSpecialCharacter && classes.policyDiv}>
  //       <Checkbox
  //         className={classes.checkboxRoot}
  //         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
  //         checkedIcon={<CheckBoxIcon fontSize="small" />}
  //         checked={hasOneSpecialCharacter}
  //         name="one"
  //       /> 1 Special character</div>
  //     <div className={hasMinLength && classes.policyDiv}>
  //       <Checkbox
  //         className={classes.checkboxRoot}
  //         icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
  //         checkedIcon={<CheckBoxIcon fontSize="small" />}
  //         checked={hasMinLength}
  //         name="one"
  //       /> Minimum 8 characters</div>
  //   </div>)
  // };

  return (<>
    <TextField type={showPassword ? 'text' : 'password'}
      id={id} name={name}
      // errorText="Password must be 8+ characters, include at least 1 Number, 1 Uppercase letter, 1 Lowercase letter and 1 Special character"
      // errorText={<HelperText error />}
      // helperText={(isFocused && <HelperText />) || null}
      // errorText={'required'}
      value={password}
      label={props.label}
      placeholder={props.placeholder}
      required
      inputProps={{
        pattern: regs.password,
        onBlur: onBlur,
        autoComplete: 'no',
      }}
      onFocus={onFocus}
      disableOnTypeValidation
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>)
      }}
      onChange={handleChange}
    />
    <PasswordProgressBar
      progress={progress}
      hasOneUppercase={hasOneUppercase}
      hasOneLowercase={hasOneLowercase}
      hasMinLength={hasMinLength}
      hasOneNumber={hasOneNumber}
      hasOneSpecialCharacter={hasOneSpecialCharacter} />
  </>);
}

NewPasswordInput.defaultProps = {
};

NewPasswordInput.propTypes = {
};

export default memo(NewPasswordInput);
