const DASHBOARD = {
    code: "DASHBOARD",
    name: "Dashboard",
    path: "/dashboard",
    functions: { 
        DASHBOARD_VIEW: {
            code: "DASHBOARD_VIEW",
            name: "View Dashboard",
            isPage: true,
            component: 'DashboardPage',
            path: "",
            getRoutePath: () => `${DASHBOARD.path}${DASHBOARD.functions.DASHBOARD_VIEW.path}`,
            getFullPath: () => `${DASHBOARD.path}${DASHBOARD.functions.DASHBOARD_VIEW.path}`,
        }
    }      
};

export default DASHBOARD;
