import {
  RESET_REFERENCES,
  GET_COUNTRY_LIST, GET_COUNTRY_LIST_SET_DATA,
  GET_RELATIONSHIP_LIST, GET_RELATIONSHIP_LIST_SET_DATA,
  GET_CURRENCY_LIST, GET_CURRENCY_LIST_SET_DATA,
  GET_PURPOSE_LIST, GET_PURPOSE_LIST_SET_DATA,
  SEARCH_LOCATION, SEARCH_LOCATION_SET_DATA,
  GET_BANK_ACCOUNT_CONFIG, GET_BANK_ACCOUNT_CONFIG_SET_DATA,
  GET_STATE_LIST, GET_STATE_LIST_SET_DATA,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_SET_DATA, GET_ROLE_LIST, GET_ROLE_LIST_SET_DATA,
  GET_JURISDICTION_LIST, GET_JURISDICTION_LIST_SET_DATA,
  GET_BUSINESS_TYPE_LIST, GET_BUSINESS_TYPE_LIST_SET_DATA,
  GET_NUMBER_OF_EMPLOYEES_LIST, GET_NUMBER_OF_EMPLOYEES_LIST_SET_DATA,
  GET_INDUSTRIES_LIST, GET_INDUSTRIES_LIST_SET_DATA,
  GET_BUSINESS_BELONG_LIST, GET_BUSINESS_BELONG_LIST_SET_DATA,
  GET_PRIMARY_USE_CASE_LIST, GET_PRIMARY_USE_CASE_LIST_SET_DATA,
  GET_PRIMARY_SOURCE_OF_FUNDING_LIST, GET_PRIMARY_SOURCE_OF_FUNDING_LIST_SET_DATA,
  GET_DOCUMENT_TYPE_LIST, GET_DOCUMENT_TYPE_LIST_SET_DATA,
  GET_ACCESS_ROLE_LIST_SET_DATA, GET_ACCESS_ROLE_LIST
} from './referenceConstants';
import { paymentStatusParser } from './referenceParser';

export function resetReferencesAction() {
  return { type: RESET_REFERENCES };
}

export function getCountryListAction(data) {
  return { type: GET_COUNTRY_LIST, data };
}
export function getCountryListSetDataAction(data) {
  return { type: GET_COUNTRY_LIST_SET_DATA, data };
}

export function getRelationshipListAction(data) {
  return { type: GET_RELATIONSHIP_LIST, data };
}
export function getRelationshipListSetDataAction(data) {
  return { type: GET_RELATIONSHIP_LIST_SET_DATA, data };
}

export function getCurrencyListAction(data) {
  return { type: GET_CURRENCY_LIST, data };
}
export function getCurrencyListSetDataAction(data) {
  return { type: GET_CURRENCY_LIST_SET_DATA, data };
}

export function getPurposeListAction(data) {
  return { type: GET_PURPOSE_LIST, data };
}
export function getPurposeListSetDataAction(data) {
  return { type: GET_PURPOSE_LIST_SET_DATA, data };
}

export function searchLocationAction(data) {
  return { type: SEARCH_LOCATION, data };
}
export function searchLocationSetDataAction(data) {
  return { type: SEARCH_LOCATION_SET_DATA, data };
}

export function getBankAccountConfigAction(data) {
  return { type: GET_BANK_ACCOUNT_CONFIG, data };
}
export function getBankAccountConfigSetDataAction(data) {
  return { type: GET_BANK_ACCOUNT_CONFIG_SET_DATA, data };
}

export function getStateListAction(data) {
  return { type: GET_STATE_LIST, data };
}
export function getStateListSetDataAction(data) {
  return { type: GET_STATE_LIST_SET_DATA, data };
}

export function getPaymentStatusAction(data) {
  return { type: GET_PAYMENT_STATUS, data };
}
export function getPaymentStatusSetDataAction(data) {
  return { type: GET_PAYMENT_STATUS_SET_DATA, data: paymentStatusParser(data) };
}

export function getRoleListAction(data) {
  return { type: GET_ROLE_LIST, data };
}
export function getRoleListSetDataAction(data) {
  return { type: GET_ROLE_LIST_SET_DATA, data };
}
export function getJurisdictionListAction(data) {
  return { type: GET_JURISDICTION_LIST, data };
}

export function getJurisdictionListSetDataAction(data) {
  return { type: GET_JURISDICTION_LIST_SET_DATA, data };
}

export function getBusinessTypeListAction(data) {
  return { type: GET_BUSINESS_TYPE_LIST, data };
}

export function getBusinessTypeListSetDataAction(data) {
  return { type: GET_BUSINESS_TYPE_LIST_SET_DATA, data };
}

export function getNumberOfEmployeesListAction(data) {
  return { type: GET_NUMBER_OF_EMPLOYEES_LIST, data };
}

export function getNumberOfEmployeesListSetDataAction(data) {
  return { type: GET_NUMBER_OF_EMPLOYEES_LIST_SET_DATA, data };
}

export function getIndustriesListAction(data) {
  return { type: GET_INDUSTRIES_LIST, data };
}

export function getIndustriesListSetDataAction(data) {
  return { type: GET_INDUSTRIES_LIST_SET_DATA, data };
}
export function getBusinessBelongListAction(data) {
  return { type: GET_BUSINESS_BELONG_LIST, data };
}
export function getBusinessBelongListSetDataAction(data) {
  return { type: GET_BUSINESS_BELONG_LIST_SET_DATA, data };
}

export function getPrimaryUseCaseListAction(data) {
  return { type: GET_PRIMARY_USE_CASE_LIST, data };
}
export function getPrimaryUseCaseListSetDataAction(data) {
  return { type: GET_PRIMARY_USE_CASE_LIST_SET_DATA, data };
}

export function getPrimarySourceOfFundingListAction(data) {
  return { type: GET_PRIMARY_SOURCE_OF_FUNDING_LIST, data };
}
export function getPrimarySourceOfFundingListSetDataAction(data) {
  return { type: GET_PRIMARY_SOURCE_OF_FUNDING_LIST_SET_DATA, data };
}
export function getDocumentTypeListAction(data) {
  return { type: GET_DOCUMENT_TYPE_LIST, data };
}
export function getDocumentTypeListSetDataAction(data) {
  return { type: GET_DOCUMENT_TYPE_LIST_SET_DATA, data };
}

export function getAccessRoleListAction(data) {
  return { type: GET_ACCESS_ROLE_LIST, data };
}
export function getAccessRoleListSetDataAction(data) {
  return { type: GET_ACCESS_ROLE_LIST_SET_DATA, data };
}
