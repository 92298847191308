
import React, { memo } from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { Dialog as MuiDialog } from '@material-ui/core';


// const useStyles = makeStyles(theme => ({
//   root: {
//   },
// }));

function Dialog(props) {
//   const classes = useStyles(props);
  return (
    <MuiDialog {...props} />
  );
}

Dialog.defaultProps = {
 
}

Dialog.propTypes = {
 
};

export default memo(Dialog);
