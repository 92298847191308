
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getBankAccountConfigAction } from 'store/Reference/referenceActions';
import { getBankAccountConfigState } from 'store/Reference/referenceSelectors';


function SelectBankAccountType(props = {}) {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState({});
    const [options, setOptions] = useState({});
    const { bankConfig, bankCountryCode, currencyCode, } = props;

    useEffect(() => {
        const op = bankConfig.data.filter(i => (i.bank_country_code === bankCountryCode && i.currency_code === currencyCode));
        const uniqueOp = op.filter((elem, index) => op.findIndex(obj => obj.payment_type === elem.payment_type) === index);
        setOptions(uniqueOp);
    }, [bankConfig, bankCountryCode, currencyCode])

    const onChange = (e, val) => {

        setValue(val || {});
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: val && val.payment_type
            }
        }, val);
    }
    return (<>
        <Autocomplete
            {...props}
            getOptions={() => null}
            options={{ loading: bankConfig.loading, data: options }}
            onChange={onChange}
            selectKey="payment_type"
            labelKey="payment_type_name"
            onBlur={props.onBlur}
            makeFocus={props.makeFocus}
            renderOption={(option) => (
                <React.Fragment>
                    {option.payment_type_name || option.payment_type}
                </React.Fragment>
            )}
        />
    </>)
};

const mapStateToProps = createStructuredSelector({
    bankConfig: getBankAccountConfigState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getBankConfig: data => dispatch(getBankAccountConfigAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default compose(
    withConnect,
    memo,
)(SelectBankAccountType,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.bankConfig) === JSON.stringify(nextProps.bankConfig) &&
            prevProps.bankCountryCode === nextProps.bankCountryCode &&
            prevProps.currencyCode === nextProps.currencyCode &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);
