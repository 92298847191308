import React, { memo, useCallback, useEffect, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Autocomplete from 'components/ui/Autocomplete';
// import { Autocomplete } from '@material-ui/lab';
import { getCountryListAction } from 'store/Reference/referenceActions';
import { flag } from 'utils/flag';
import { Typography } from 'components/ui';
import { EllipsisLoader } from 'components';


const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        background: '#fafafa',
    },
    flagDiv: {
        // margin: theme.spacing(0.25, 0, 0, 0.5)
        margin: theme.spacing(-0.5, 0, 0, 0.5)
    },
    showMore: {
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center'
    },
    showMOreTxt: {
        marginRight: theme.spacing(1)
    }
}));

function countryToFlag(isoCode = "") {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : '';
}

function SelectIncorporateCountry(props = {}) {
    const classes = useStyles();
    const [country, setCountry] = useState({});
    const [disableCloseOnSelect, setDisableCloseOnSelect] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showMoreLoader, setShowMoreLoader] = useState(false);
    const { countries, limitedCountries } = props;


    const setDefaultCountry = useCallback(() => {
        let defaultSelectedCountry = {};
        if (countries && Array.isArray(countries.data) && countries.data.length && props.value) {
            defaultSelectedCountry = countries.data.find(i => i.country_code === props.value) || {};
            onChange({}, defaultSelectedCountry);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, props.value]);
    useEffect(() => {
        setDefaultCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries.data, setDefaultCountry]);
    useEffect(() => {
        if (props.value !== country.country_code) {
            setDefaultCountry();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChange = (e, val) => {
        if (val?.country_code === 'MORE') {
            setShowMoreLoader(true);
            setTimeout(() => {
                setShowAll(true);
                setShowMoreLoader(false);
                // setCountry({});
                return;
            }, [1000]);

        } else {
            setCountry({});
            setTimeout(() => {
                setCountry(val || {});
                props.onChange && props.onChange({
                    target: {
                        id: props.id,
                        name: props.name,
                        value: val && val.country_code
                    }
                }, val);
            }, 0);
        }
    };

    const onHighlightChange = (e, object, reason) => {
        if (object?.country_code === 'MORE') {
            setDisableCloseOnSelect(true);
        } else {
            setDisableCloseOnSelect(false);
        }
    };

    return (<>
        <Autocomplete
            {...props}
            disableCloseOnSelect={disableCloseOnSelect}
            getOptions={props.getCountries}
            options={showAll ? countries : limitedCountries}
            onChange={onChange}
            selectKey="country_code"
            labelKey="country_name"
            makeFocus={props.makeFocus}
            helperText={props.helperText}
            onBlur={props.onBlur}
            onHighlightChange={onHighlightChange}
            startAdornment={(
                <React.Fragment>
                    {/* <span>{countryToFlag(props.value || '')}</span> &nbsp; */}
                    <span className={classes.flagDiv}><Avatar alt="flag" src={flag(country.country_code)} className={classes.small} /></span> &nbsp;
                </React.Fragment>
            )
            }
            renderOption={(option) => (
                <React.Fragment>
                    {option.showMore ? <>
                        <div className={classes.showMore}><Typography variant="caption" className={classes.showMOreTxt} >Show more countries</Typography> {showMoreLoader && <EllipsisLoader />}</div>
                    </> :
                        <>
                            <span>{countryToFlag(option.country_code)}</span> &nbsp;
                            {option.country_name} ({option.country_code})
                        </>}
                </React.Fragment>
            )}
        />
    </>);
}


const mapStateToProps = (s, p) => {
    const countries = (p.onlyDestination ? s.references.getDestinationCountryListState : s.references.getCountryListState) || { data: [], loading: false, error: '' };
    let limitedCountries = { data: !countries.loading ? [...countries?.data?.filter(i => ['US', 'CA'].includes(i.country_code)), { country_code: 'MORE', showMore: true, country_name: '' }] : [], loading: false, error: '' }
    if (p.value && !['US', 'CA'].includes(p.value)) {
        limitedCountries = countries;
    }
    return { countries, limitedCountries };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountries: data => dispatch(getCountryListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectIncorporateCountry,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.countries) === JSON.stringify(nextProps.countries) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus &&
            prevProps.disabled === nextProps.disabled
        )
    )
);