import { ordinal_suffix_of, removeNullKeys } from "utils/common";
import { ENTITY, ACCOUNT_LEDGER, PAYMENT, BANK_ACCOUNT } from '@truly-financial/common/consts';
import { MONTHS, WEEKDAYS } from "containers/Common/RecurringConstants";


export const addUpdateContactRequestParser = (data = {}) => {
    const { id, cb, body = {}, formdata = {}, bankConfig } = data;
    const bank_details = body.bank_details;
    let parsedData = {
        id,
        cb: cb || (() => null),
        body: removeNullKeys({
            entity_type: body.entity_type || null,
            first_name: ((body.entity_type === "INDIVIDUAL" || id) ? body.first_name : null),
            last_name: ((body.entity_type === "INDIVIDUAL" || id) ? body.last_name : null),
            contact_person_name: ((body.entity_type === "COMPANY" || id) ? body.contact_person_name : null),
            payee_relationship_code: body.payee_relationship_code || null,
            mobile_country_code: body.mobile_country_code || null,
            email: body.email || null,
            mobile: body.mobile || null,
            company_name: body.company_name || null,
            contact_person_position: ((body.entity_type === "COMPANY" || id) ? body.contact_person_position : null),
            note: body.note || null,
            account_holder_name: body.account_holder_name || null,
            address_line_1: body.address_line_1 || null,
            address_line_2: body.address_line_2 || null,
            landmark: body.landmark || null,
            city: body.city || null,
            state_code: body.state_code || null,
            state_name: body.state_name || null,
            country_code: body.country_code || null,
            postal_code: body.postal_code || null,
        }),
    };

    if (Array.isArray(bankConfig) && bank_details) {
        const bankFields = bankConfig.filter(i => (i.bank_country_code === bank_details.bank_country_code && i.currency_code === bank_details.currency_code && i.payment_type === bank_details.payment_type));
        const bankFilledObj = bankFields.map(i => ({ [i.bank_account_field]: bank_details[i.bank_account_field] }));
        const bankHiddenFieldsObj = bankFields.filter(i => i.routing_code_type_field).map(i => ({ [i.routing_code_type_field]: i.routing_code_value }));
        let bankObj = Object.assign({}, ...bankFilledObj, ...bankHiddenFieldsObj, {
            bank_country_code: bank_details.bank_country_code,
            currency_code: bank_details.currency_code,
            payment_type: bank_details.payment_type,
            request_bank_details: bank_details.request_bank_details,
        })
        if (bank_details.request_bank_details) {
            bankObj = { 
                request_bank_details: bank_details.request_bank_details,
                bank_account_note: bank_details.bank_account_note,
            }
        }
        parsedData.body.bank_details = removeNullKeys(bankObj);
    }
    if (formdata.file && Object.keys(formdata.file).length) {
        parsedData.formdata = removeNullKeys({
            file: formdata.file,
            file_name: formdata.file_name,
        });
    }

    return parsedData;
};
export const updateContactBankAccountParser = (data = {}) => {
    const { contactId, bankAccountId, cb, body = {}, bankConfig } = data;
    const bank_details = {...body};
    let parsedData = {
        contactId,
        bankAccountId,
        cb: cb || (() => null),
        body: {},
    };

    if (Array.isArray(bankConfig) && bank_details) {
        const bankFields = bankConfig.filter(i => (i.bank_country_code === bank_details.bank_country_code && i.currency_code === bank_details.currency_code && i.payment_type === bank_details.payment_type));
        const bankFilledObj = bankFields.map(i => ({ [i.bank_account_field]: bank_details[i.bank_account_field] }));
        const bankHiddenFieldsObj = bankFields.filter(i => i.routing_code_type_field).map(i => ({ [i.routing_code_type_field]: i.routing_code_value }));
        let bankObj = Object.assign({}, ...bankFilledObj, ...bankHiddenFieldsObj, {
            bank_country_code: bank_details.bank_country_code,
            currency_code: bank_details.currency_code,
            payment_type: bank_details.payment_type,
        })
        parsedData.body = removeNullKeys(bankObj);
    }

    return parsedData;
};
export const resendContactBankDetailRequestParser = (data = {}) => {
    const { contactId, bankAccountId, cb, body = {} } = data;
    let parsedData = {
        contactId, bankAccountId,
        cb: cb || (() => null),
        body: {
            bank_account_note: body.bank_account_note
        },
    };

    return parsedData;
};

export const addUpdateContactDataParser = (res = {}) => {
    const { data = {}, error = {} } = res;
    let parsedData = {};
    const errorObj = {};
    if (error.fields) {
        error.fields.forEach(elem => {
            errorObj[elem.key] = elem.message;
        });
    }
    parsedData.error = errorObj;
    parsedData.data = data;

    return parsedData;
};

export const addUpdateContactNoteRequestParser = (data = {}) => {
    const { id, noteId, cb, body = {} } = data;
    let parsedData = {
        id,
        noteId,
        cb: cb || (() => null),
        body: removeNullKeys({
            note: body.note || null,
        }),
    };

    return parsedData;
};

export const addUpdateContactNoteDataParser = (res = {}) => {
    const { data = {}, error = {} } = res;
    let parsedData = {};
    const errorObj = {};
    if (error.fields) {
        error.fields.forEach(elem => {
            errorObj[elem.key] = elem.message;
        });
    }
    parsedData.error = errorObj;
    parsedData.data = data;

    return parsedData;
};

export const contactPaymentListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r.data.map(i => ({
        ...i,
        from_to_display_name: i.from_to_display_name || (i.payee_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? `${i.payee_first_name || ''} ${i.payee_middle_name || ''} ${i.payee_last_name || ''}` : i.payee_company_name),
        amount_currency_code: i.ledger_operation === ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT ? i.source_currency : i.destination_currency,
        prs_make_recurring: i.payment_status === PAYMENT.PAYMENT_STATUS.READY_FOR_SETTLEMENT || i.payment_status === PAYMENT.PAYMENT_STATUS.SETTLED,
        prs_cancel: [PAYMENT.PAYMENT_STATUS.PENDING, PAYMENT.PAYMENT_STATUS.AWAITING_FUNDS, PAYMENT.PAYMENT_STATUS.QUEUED, PAYMENT.PAYMENT_STATUS.ON_HOLD, PAYMENT.PAYMENT_STATUS.READY_FOR_SETTLEMENT, PAYMENT.PAYMENT_STATUS.PICKED_FOR_SETTLEMENT].includes(i.payment_status)
    }));
    parsedResponse.data = d;
    return parsedResponse;
};
export const contactListParser = (r = {}) => {
    const parsedResponse = { ...r };
    const d = r && r.data && r.data.map(i => ({
        ...i,
        prs_display_primary_name: i.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? i.entity_person_first_name + " " + i.entity_person_last_name : i.company_name,
        prs_display_secondary_name: i.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? "" : i.contact_person_name
    }));
    const e = r && r.recentContacts && r.recentContacts.map(i => ({
        ...i,
        prs_display_primary_name: i.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? i.entity_person_first_name + " " + i.entity_person_last_name : i.company_name,
        prs_display_secondary_name: i.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? "" : i.contact_person_name
    }));
    parsedResponse.data = d;
    parsedResponse.recentContacts = e;
    return parsedResponse;
};
export const contactDetailParser = (r = {}) => {
    const res = { ...r };
    const data = r.data || {};
    res.data = {
        ...data,
        prs_display_primary_name: data.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? data.entity_person_first_name + " " + data.entity_person_last_name : data.company_name,
        prs_display_secondary_name: data.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? "" : data.contact_person_name,
        // state_code: 'OTHER',
        // state_name: 'test state name'
    };

    return res;
};
export const contactScheduledPaymentDetailParser = (r = {}) => {
    const res = { ...r };
    const data = r.data || {};
    let selectRepeatedData = data.event_schedule_recurring_type_id;
    let str = "";
    let everyStr = "";


    if (selectRepeatedData === 3 && data.separation_count === 0) {
        str = "Weekly";
        everyStr = Object.values(WEEKDAYS[data.day_of_week - 1])[0];
    } else if (selectRepeatedData === 3 && data.separation_count === 2) {
        str = "Bi-Weekly";
        everyStr = Object.values(WEEKDAYS[data.day_of_week - 1])[0];
    } else if (selectRepeatedData === 4) {
        str = "Monthly";
        everyStr = ordinal_suffix_of(data.day_of_month);

    } else if (data && data.day_of_month && data.month_of_year) {
        str = "Annually";
        everyStr = ordinal_suffix_of(data.day_of_month) + " " + Object.values(MONTHS[(data.month_of_year - 1)])[0];
    }

    res.data = {
        ...data,
        prs_is_Recurring: data.is_recurring === 1 ? true : false,
        prs_repeat: data.event_schedule_recurring_type_id === 0 ? false : str,
        prs_every: everyStr,
        prs_end: data.end_date
    };

    return res;
};
export const getSchedulePaymentListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r.data.map(i => ({
        ...i,
        prs_delete: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.SCHEDULED || i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.RECURRING,
        prs_send_now: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.SCHEDULED,
        prs_reschedule: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.SCHEDULED,
        prs_edit_recurrence: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.RECURRING,
        prs_name: i && i.payee_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? `${i.payee_first_name}  ${i.payee_last_name}` : i.payee_company_name

    }));
    parsedResponse.data = d;
    return parsedResponse;
};

export const addContactBankAccountRequestParser = (data = {}) => {
    const { id, cb, body = {}, bankConfig } = data;
    let parsedData = {
        id,
        cb: cb || (() => null),
        body: removeNullKeys({
            ...body
        }),
    };

    if (Array.isArray(bankConfig)) {
        const bankFields = bankConfig.filter(i => (i.bank_country_code === body.bank_country_code && i.currency_code === body.currency_code && i.payment_type === body.payment_type));
        const bankFilledObj = bankFields.map(i => ({ [i.bank_account_field]: body[i.bank_account_field] }));
        const bankHiddenFieldsObj = bankFields.filter(i => i.routing_code_type_field).map(i => ({ [i.routing_code_type_field]: i.routing_code_value }));
        const bankObj = Object.assign({}, ...bankFilledObj, ...bankHiddenFieldsObj, {
            bank_country_code: body.bank_country_code,
            currency_code: body.currency_code,
            payment_type: body.payment_type,
            bank_account_type: BANK_ACCOUNT.BANK_ACCOUNT_TYPE.EXTERNAL,
            account_type: BANK_ACCOUNT.ACCOUNT_TYPE.SAVINGS,
        })
        parsedData.body = removeNullKeys(bankObj);
    }

    return parsedData;
};

export const getContactAccountsParser = (r = {}) => {
    const parsedResponse = { ...r };
    const d = r.data.map(i => ({
        ...i,
        prs_account_last_digits: getAccountLastDigits(i),

    }));
    parsedResponse.data = d;
    return parsedResponse;
};

const getAccountLastDigits = (obj) => {
    const a = obj.account_number ||
    obj.iban ||
    (obj.routing_code_type_1 === 'IBAN_REGULAR' && obj.routing_code_value_1) ||
    (obj.routing_code_type_2 === 'IBAN_REGULAR' && obj.routing_code_value_2) ||
    (obj.routing_code_type_3 === 'IBAN_REGULAR' && obj.routing_code_value_3) || '';
    return a.substr(a.length - 4, 4);
};