/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useEffect, useState, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as MuiTextField } from '@material-ui/core';
import combineClx from 'utils/combineClx';
import theme from 'theme/index';

const backgroundColors = {
  default: theme.palette.grey50, //"grey"
  transparent: "rgba(0,0,0,0)", // transparent
  white: theme.palette.primary.contrastText //"white"
};
const useStyles = makeStyles((theme) => ({

  root: (props) => ({
    display: "flex",
    flex: 1,
    alignItems: "center",
    borderRadius: theme.spacing(0.75),
    backgroundColor: props.customAdornment ? backgroundColors[props.bgColor] : null,
    // backgroundColor: props.bgColor ? 'rgba(0,0,0,0)' : theme.palette.grey50,
  }),
  inputRoot: (props) => ({
    border: props.error ? `1px solid ${theme.palette.error.main}` : undefined,
  }),
  customAdornmentStyle: {
    padding: theme.spacing(0, 0, 0, 1),
    display: 'flex',
  },
  inputPlaceHolder: {
    '&::placeholder': {
      ...theme.typography.subtitle1,
    }
  },
  input: (props) => ({
    "& .MuiFilledInput-root": {
      backgroundColor: backgroundColors[props.bgColor],
      border: props.outline ? `1px solid ${theme.palette.grey[100]}` : 'none',
      boxShadow: props.error ? `inset 0px 0px 0px 1px ${theme.palette.error.main}` : 'none',
    },
    '& .MuiFormLabel-root': {
      ...theme.typography.subtitle1,

    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "inherit",
      textAlign: "left",
    },
  }),
  inputBase: {
    // ...theme.typography.subtitle1,
    fontWeight: 600,
  },
  mainDiv: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    borderRadius: theme.spacing(0.75),
    backgroundColor: theme.palette.grey50,
  },
  iconDiv: {
    margin: theme.spacing(1, 0, 1, 1),
  },
  helperText: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
    textAlign: "left",
  },
}));

const TextField = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [error, setError] = useState(props.error ? true : false);
  const classes = useStyles({ ...props, error });
  const [keyUpTimeout, setKeyUpTimeout] = useState(null);
  const inputRef = useRef(null);
  const textFieldRef = ref || props.inputRef || inputRef;
  let inputProps = { disableUnderline: true };

  useImperativeHandle(ref, () => ({
    onClear
  }));

  useEffect(() => {
    if (textFieldRef && textFieldRef.current && props.makeFocus) {
      textFieldRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      textFieldRef.current.focus();
    }
  }, [props.makeFocus]);

  if (props.variant === 'outlined') {
    inputProps = {};
  }

  useEffect(() => {

    if (props.value !== value) {

      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {

    setError(props.error ? true : false);
  }, [props.error]);

  useEffect(() => {
    setError(false);
  }, [props.required]);

  const onchange = (e) => {
    const { value } = e.target || {};
    const { specialChar, isCharacterOnly, isNumberOnly, isNonZeroNumber } = props;

    // eslint-disable-next-line no-useless-escape
    let regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let spaceRegex = /^\s$/g;
    let characterRegex = /^(?=.*[A-Za-z])^(?!(?=.*[!@#$%^&*]))^(?!(?=.*[0-9]))/;
    let numberRegex = /^[0-9]*$/;
    let numberWithoutZero = /^[1-9][0-9]*(\.[0-9]+)?/;

    value.replace();

    if (value.length > 0 && isNumberOnly && !numberRegex.test(value)) {
      return;
    }
    if (value.length > 0 && isNonZeroNumber && !numberWithoutZero.test(value)) {
      return;
    }
    if (value.length > 0 && isCharacterOnly && !characterRegex.test(value)) {
      return;
    }
    if (spaceRegex.test(value)) {
      return;
    }
    if (!specialChar && regex.test(value)) {
      return;
    }

    if (value.charAt(value.length - 1) === " " && value.charAt(value.length - 2) === " ") {
      return;
    }

    setError(false);
    setValue(value.trimStart());
    props.onChange && props.onChange(e);
    checkValidation(value.trimStart(value));
    if (props && props.onFocusRef) {
      props.onFocusRef("clear");
    }
  };

  const checkValidation = (value) => {
    setKeyUpTimeout(clearTimeout(keyUpTimeout));
    if (props.inputProps && props.inputProps.pattern && !props.disableOnTypeValidation) {
      setKeyUpTimeout(setTimeout(() => {
        const r = new RegExp(props.inputProps.pattern);
        if (!r.test(value)) {
          setError(true);
        } else {
          setError(false);
        }
      }, 1000));
      return;
    }
    return;
  };

  const onInvalid = (e) => {
    e.preventDefault && e.preventDefault();
    setError(false);
    setTimeout(() => {
      setError(true);
      if (props && props.onFocusRef) {
        props.onFocusRef(props.name, props.id);
      }

    }, 300);
  };
  const onClear = () => {
    setError(false);
    setValue("");
  };

  return (
    <div className={classes.root} >
      {
        props.customAdornment ? <div className={classes.customAdornmentStyle}>{props.customAdornment}</div> : <></>
      }
      <MuiTextField
        // {...props}
        inputRef={ref || props.inputRef || inputRef}
        autoComplete="no"
        id={props.id}
        name={props.name}
        hiddenLabel={!props.label}
        label={props.label}
        error={error}
        fullWidth
        className={combineClx(classes.input, props.className)}
        variant={props.variant || "filled"}
        size={props.size || "small"}
        value={value}
        placeholder={props.placeholder}
        type={props.type}
        autoFocus={props.autoFocus}
        onChange={onchange}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        helperText={error ? (props.errorText || props.helperText) : props.helperText}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        rowsMax={props.rowsMax}
        InputLabelProps={{
          required: false,
          ...props.InputLabelProps
        }}
        inputProps={{ style: { borderRadius: 6 }, ...props.inputProps, }}
        InputProps={{
          classes: {
            input: classes.inputPlaceHolder,
            ...props.InputProps.classes
          },
          startAdornment: props.InputProps.startAdornment,
          endAdornment: props.InputProps.endAdornment,
          required: props.required,
          onInvalid,
          onFocus: props.onFocus,
          onBlur: props.onBlur,
          // disableUnderline: true,
          ...inputProps,
          style: {
            borderRadius: 6,
            fontWeight: 600,
            ...props.customStyle
          },
          ...props.CustomInputProps,
        }}
      />
    </div>
  );
})

TextField.defaultProps = {
  type: 'text',
  errorText: '',
  helperText: '',
  InputProps: {},
  className: '',
  value: "",
  specialChar: true,
  outline: false,
  InputLabelProps: {},
  bgColor: "default",
  transparentBg: false,
  inputPropsChanged: false,
  autoFocus: false
}

TextField.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  // type: PropTypes.oneOf(["number", "password", "text", 'tel', 'email']),
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  customAdornment: PropTypes.any,
  onClear: PropTypes.func,
  specialChar: PropTypes.bool,
  disabled: PropTypes.any,
  outline: PropTypes.any,
  bgColor: PropTypes.bool,
  transparentBg: PropTypes.bool,
  inputPropsChanged: PropTypes.bool,
  autoFocus: PropTypes.bool
};

export default memo(TextField,
  (prevProps, nextProps) => {
    return (prevProps.value === nextProps.value &&
      prevProps.type === nextProps.type &&
      prevProps.error === nextProps.error &&
      prevProps.helperText === nextProps.helperText &&
      prevProps.label === nextProps.label &&
      prevProps.required === nextProps.required &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.makeFocus === nextProps.makeFocus &&
      prevProps.inputPropsChanged === nextProps.inputPropsChanged &&
      prevProps.autoFocus === nextProps.autoFocus
    )
  }
);
// export default memo(TextField);
