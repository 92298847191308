import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Chip as MuiChip } from '@material-ui/core';
import combineClx from 'utils/combineClx';
// import useCommonStyles from 'styles';

const useStyles = makeStyles(theme => ({
  size: props => ({
    height: props.size === 'xSmall' ? theme.spacing(2.5) : 'none',
    backgroundColor: props.color,
    '& .MuiChip-labelSmall': {
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiChip-label': {
      alignItems: 'center',
      display: 'flex'
    }
    // maxWidth: props.mxWidth
  }),
}));

function Chip(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  let { size: propSize, className: propClassName } = props;
  const size = ['medium', 'small'].includes(propSize) ? propSize : 'small';
  return (<MuiChip {...props} size={size} className={combineClx(propClassName, classes.size)} />);
}

Chip.defaultProps = {
  test: "",
  mxWidth: "auto"
};

Chip.propTypes = {
  test: PropTypes.string,
  mxWidth: PropTypes.any
};

export default memo(Chip);
