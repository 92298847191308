
import produce from 'immer';
import {
  RESET_PAYMENTS,
  SEND_PAYMENT, SEND_PAYMENT_SET_DATA,
  PAYMENT_LIST, SEND_PAYMENT_LIST_SET_DATA,
  PRE_SEND_PAYMENT, PRE_SEND_PAYMENT_SET_DATA,
  GET_PAYMENT_CURRENCIES, GET_PAYMENT_CURRENCIES_SET_DATA, CANCEL_PAYMENT, CANCEL_PAYMENT_SET_DATA,
  GET_SCHEDULED_PAYMENT_LIST, GET_SCHEDULED_PAYMENT_LIST_SET_DATA, DELETE_SCHEDULED_PAYMENT_SET_DATA, DELETE_SCHEDULED_PAYMENT,
  SEND_SCHEDULE_PAYMENT_NOW_SET_DATA, SEND_SCHEDULE_PAYMENT_NOW, UPDATE_RECURRING_PAYMENT, UPDATE_RECURRING_PAYMENT_SET_DATA,
  DELETE_DRAFT_PAYMENT, DELETE_DRAFT_PAYMENT_SET_DATA, GET_CARD_PAYMENT_LIST_SET_DATA, GET_CARD_PAYMENT_LIST, GET_CARD_PAYMENT_DETAIL, GET_CARD_PAYMENT_DETAIL_SET_DATA
} from './paymentConstants';

export const initialState = {
  getCardPaymentDetailState: { data: {}, loading: false, error: '' },
  getCardPaymentListState: { data: {}, previousData: {}, loading: false, error: '' },
  deleteDraftPaymentState: { data: {}, loading: false, error: '' },
  updateRecurringPaymentState: { data: {}, loading: false, error: '' },
  sendSchedulePaymentNowState: { data: {}, loading: false, error: '' },
  deleteScheduledPaymentState: { data: {}, loading: false, error: '' },
  getScheduledPaymentListState: { data: {}, previousData: {}, loading: false, error: '' },
  cancelPaymentState: { data: {}, loading: false, error: '' },
  getPaymentCurrenciesState: { data: [], loading: false, error: '' },
  preSendPaymentState: { data: {}, loading: false, error: '' },
  sendPaymentListState: { data: {}, loading: false, error: '', draft: {}, previousData: {}, count: "" },
  sendPaymentState: { data: {}, loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const paymentsReducer = (state = initialState, action) =>
  produce(state, draft => {

    switch (action.type) {
      case DELETE_DRAFT_PAYMENT:
        draft.deleteDraftPaymentState = {
          ...draft.deleteDraftPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_DRAFT_PAYMENT_SET_DATA:
        draft.deleteDraftPaymentState = {
          ...draft.deleteDraftPaymentState,
          ...action.data, loading: false,
        };
        break;
      case UPDATE_RECURRING_PAYMENT:
        draft.updateRecurringPaymentState = {
          ...draft.updateRecurringPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case UPDATE_RECURRING_PAYMENT_SET_DATA:
        draft.updateRecurringPaymentState = {
          ...draft.updateRecurringPaymentState,
          ...action.data, loading: false,
        };
        break;
      case SEND_SCHEDULE_PAYMENT_NOW:
        draft.sendSchedulePaymentNowState = {
          ...draft.sendSchedulePaymentNowState,
          loading: true, message: '', error: '',
        };
        break;
      case SEND_SCHEDULE_PAYMENT_NOW_SET_DATA:
        draft.sendSchedulePaymentNowState = {
          ...draft.sendSchedulePaymentNowState,
          ...action.data, loading: false,
        };
        break;
      case DELETE_SCHEDULED_PAYMENT:
        draft.deleteScheduledPaymentState = {
          ...draft.deleteScheduledPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_SCHEDULED_PAYMENT_SET_DATA:
        draft.deleteScheduledPaymentState = {
          ...draft.deleteScheduledPaymentState,
          ...action.data, loading: false,
        };
        break;
      case GET_SCHEDULED_PAYMENT_LIST:
        draft.getScheduledPaymentListState = {
          ...draft.getScheduledPaymentListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_SCHEDULED_PAYMENT_LIST_SET_DATA:
        const scheduledApiData = action.data;
        let scheduledPreviousData = state.getScheduledPaymentListState.previousData;
        var NewData = {};
        if (action && action.page > 0) {
          NewData.data = scheduledPreviousData.concat(scheduledApiData.data);
        } else {
          NewData.data = scheduledApiData.data;
        }
        draft.getScheduledPaymentListState = {
          ...draft.getScheduledPaymentListState,
          ...NewData,
          previousData: NewData.data,
          count: action.data.count || 0,
          loading: false,
        };
        break;
      case CANCEL_PAYMENT:
        draft.cancelPaymentState = {
          ...draft.cancelPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case CANCEL_PAYMENT_SET_DATA:
        draft.cancelPaymentState = {
          ...draft.cancelPaymentState,
          ...action.data, loading: false,
        };
        break;
      case GET_PAYMENT_CURRENCIES:
        draft.getPaymentCurrenciesState = {
          ...draft.getPaymentCurrenciesState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_PAYMENT_CURRENCIES_SET_DATA:
        draft.getPaymentCurrenciesState = {
          ...draft.getPaymentCurrenciesState,
          ...action.data, loading: false,
        };
        break;
      case PRE_SEND_PAYMENT:
        draft.preSendPaymentState = {
          ...draft.preSendPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case PRE_SEND_PAYMENT_SET_DATA:
        draft.preSendPaymentState = {
          ...draft.preSendPaymentState,
          ...action.data, loading: false,
        };
        break;
      case PAYMENT_LIST:

        draft.sendPaymentListState = {
          ...draft.sendPaymentListState,
          loading: true, message: '', error: '', count: ''
        };
        break;
      case SEND_PAYMENT_LIST_SET_DATA:
        const apiData = action.data;
        let previousData = state.sendPaymentListState.previousData;
        var _NewData = {};
        if (action && action.page > 0) {
          _NewData.data = previousData.concat(apiData.data);
        } else {
          _NewData.data = apiData.data;
        }
        draft.sendPaymentListState = {
          ...draft.sendPaymentListState,
          ..._NewData,
          previousData: _NewData.data,
          count: action.data.count || 0,
          loading: false,
        };
        break;
      case SEND_PAYMENT:
        draft.sendPaymentState = {
          ...draft.sendPaymentState,
          loading: true, message: '', error: '',
        };
        break;
      case SEND_PAYMENT_SET_DATA:
        draft.sendPaymentState = {
          ...draft.sendPaymentState,
          ...action.data, loading: false,
        };
        break;
      case GET_CARD_PAYMENT_DETAIL:
        draft.getCardPaymentDetailState = {
          ...draft.getCardPaymentDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CARD_PAYMENT_DETAIL_SET_DATA:
        draft.getCardPaymentDetailState = {
          ...draft.getCardPaymentDetailState,
          ...action.data, loading: false,
        };
        break;
      case GET_CARD_PAYMENT_LIST:
        draft.getCardPaymentListState = {
          ...draft.getCardPaymentListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CARD_PAYMENT_LIST_SET_DATA:
        const cardListApiData = action.data;
        let cardPreviousData = state.getCardPaymentListState.previousData;
        var cardNewData = {};
        if (action && action.page > 0) {
          cardNewData.data = cardPreviousData.concat(cardListApiData.data);
        } else {
          cardNewData.data = cardListApiData.data;
        }

        draft.getCardPaymentListState = {
          ...draft.getCardPaymentListState,
          ...cardNewData,
          previousData: cardNewData.data,
          count: action.data.count || 0,
          loading: false,
        };
        break;
      case RESET_PAYMENTS:
        draft = initialState;
        break;
    }
  });

export default paymentsReducer;
