/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useEffect, useState } from 'react';
import {
    makeStyles, Checkbox,
    // FormControlLabel,
    Link,
    FormGroup,
    FormControlLabel,
    Divider,
    RadioGroup, Radio, FormControl,
    // Grid
} from "@material-ui/core";

import {
    Typography, Button,
    TextField,
    Autocomplete
} from "components/ui";
import SelectCountry from 'containers/Common/SelectCountry';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Loader, StyledError } from 'components';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/TeamMembers/intl/TeamMembersIntl';
// import SearchLocation from 'containers/Common/SearchLocation';
// import SelectState from 'containers/Common/SelectState';
import { personalDetailsFromIdVerificationAction, updatePersonalDetailsAction } from 'store/Verification/verificationActions';
import { personalDetailsFromIdVerificationState, updatePersonalDetailsState } from 'store/Verification/verificationSelectors';
import DisplayCountry from 'containers/Common/DisplayCountry';
// import SelectGovIdType from 'containers/Common/SelectGovIdType';
var maxDateDob = moment().subtract(18, 'years').format("YYYY-MM-DD");
var minDateDob = moment(maxDateDob).subtract(82, 'years').format("YYYY-MM-DD");
// var minDateExpire = moment().add(1, 'days').format("YYYY-MM-DD");
const useStyles = makeStyles((theme) => ({
    root: {
        // textAlign: 'center',
        backgroundColor: "white",
        padding: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
        },
    },
    container: {
        padding: theme.spacing(0, 0, 4, 0),
        justifyContent: "space-between",
        display: "flex",
        flex: 1,
        flexDirection: "column",

    },
    bodyTxt: {
        textAlign: "center",
    },

    bodyTxtCenter: {
        marginBottom: theme.spacing(2),
        textAlign: "center",
    },

    spanTxt: {
        color: "black",
        fontWeight: "bold",
    },
    inputDiv: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    rowInputDiv: {
        marginTop: theme.spacing(2),

        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    radioInputDiv: {
        marginTop: theme.spacing(2),
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    addressTitle: {
        textAlign: "left",
        borderBottom: "1px solid black",
    },
    addressCheck: {
        textAlign: "left",
    },
    continueBtn: {
        margin: theme.spacing(3, 0),
    },
    stateAndZipDiv: {
        display: "flex",
    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`,
        },
    },
    underline: {
        textDecoration: "underline",
    },
    txtRoot: {
        margin: theme.spacing(4, 1, 0, 1),
    },
    subtitleDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    subtitle2: {
        ...theme.typography.subtitle2,
    },
    divider: {
        backgroundColor: theme.palette.grey600,
    },
    titleTxt: {
        marginTop: theme.spacing(3),
    },

    commonFlex: {
        flex: 1,
        display: "contents",
    },
    radioFlex: {
        flex: 1,
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            justifyContent: "center",
        },
    },
    uploadProofTitle: {
        marginTop: theme.spacing(3),
        textAlign: "center",
    },
    proofHelperTxt: {
        margin: theme.spacing(1, 0, 0, 0),
        textAlign: "center",
        color: theme.palette.accent.main,
    },
    dontHaveTxt: {
        textDecoration: "underline",
        textAlign: "center",
        margin: theme.spacing(0, 0, 6, 0),
        cursor: "pointer",
    },
    birbixAddress: {
        border: "1px solid #F2F3F4",
        borderRadius: "6px",
        marginTop: theme.spacing(2),
        padding: theme.spacing(0, 2),
    },
    redFont: {
        color: theme.palette.error.main
    },
    iconRedColor: {
        color: `${theme.palette.error.main}`,
    },
    ownerShipComplete: {
        alignItems: 'flex-start',
        "& .MuiFormControlLabel-root": {
            alignItems: 'flex-start'
        }
    },
    ownershipCompleteCheckBox: {
        color: `${theme.palette.grey500}`,
        padding: '2px !important',
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    conditionsCheck: {
        marginLeft: theme.spacing(2),
    }
}));

function UpdateRequiredDetails(props = {}) {
    const classes = useStyles();
    const {
        data = {},
        onStepChange = () => null,
        updatePersonalDetailsState,
        personalDetailsFromIdVerificationState,
        updatePersonalDetails,
        personalDetailsFromIdVerification,
        isLoading
    } = props;
    const [inputRefs, setInputRefs] = useState([]);
    const isEntityPersonPrimary = data.entity_person_type === "PRIMARY_CONTACT" ? true : false;
    const detailsFromIdVerificationData = personalDetailsFromIdVerificationState?.data || {};
    const [waitingText, setWaitingText] = useState('');
    const [isUsUser, setIsUser] = useState(false);
    const isLoadingData = isLoading || updatePersonalDetailsState.loading || personalDetailsFromIdVerificationState.loading
    const [formData, setFormData] = useState({
        // entity_person_first_name: data.entity_person_first_name,
        // entity_person_middle_name: data.entity_person_middle_name,
        // entity_person_last_name: data.entity_person_last_name,
        // entity_person_email: data.entity_person_email,
        // entity_person_mobile_country_code: data.entity_person_mobile_country_code,
        // entity_person_mobile: data.entity_person_mobile,
        // contact_person_position: data.contact_person_position,

        entity_person_date_of_birth: data.entity_person_date_of_birth,
        entity_person_gender: data.entity_person_gender,
        entity_person_citizenship_country: data.entity_person_citizenship_country,
        entity_person_country_of_residence: data.entity_person_country_of_residence,
        tnc_and_privacy_accepted: data.tnc_and_privacy_accepted,
        primary_address_same_as_berbix: data.primary_address_same_as_berbix,

        entity_person_tax_id_value: data.entity_person_tax_id_value,

        // document_country_of_issuance: data.document_country_of_issuance,
        // document_expiry_date: data.document_expiry_date,
        // document_type_id: data.document_type_id,
        // document_id_number: data.document_id_number,
        // document_id: data.document_id
    });

    const [radioError, setRadioError] = useState([]);

    useEffect(() => {
        getPersonalDetailsFromIdHandler();
    }, []);

    useEffect(() => {
        if ([formData.entity_person_citizenship_country,
            detailsFromIdVerificationData.entity_person_citizenship_country,
            formData.entity_person_country_of_residence,
            detailsFromIdVerificationData.entity_person_country_of_residence
            ].includes('US')) {
                setIsUser(true);
        } else {
            setIsUser(false);
        }

    }, [formData.entity_person_citizenship_country,
        detailsFromIdVerificationData.entity_person_citizenship_country,
        formData.entity_person_country_of_residence,
        detailsFromIdVerificationData.entity_person_country_of_residence
        ]);

    useEffect(() => {
        if (personalDetailsFromIdVerificationState.loadedCount > 5) {
            setWaitingText('This is taking longer than expected, please wait')
        } else if (personalDetailsFromIdVerificationState?.data?.id_verification_data_synced) {
            setWaitingText('');
        }
        if (personalDetailsFromIdVerificationState.loaded && !personalDetailsFromIdVerificationState.loading && !personalDetailsFromIdVerificationState?.data?.id_verification_data_synced) {
            setTimeout(() => {
                getPersonalDetailsFromIdHandler();
            }, 5000);
        }
    }, [personalDetailsFromIdVerificationState]);

    // useEffect(() => {
    //     setFormData((formData) => ({ ...formData,
    //         entity_person_citizenship_country: formData.entity_person_citizenship_country || detailsFromIdVerificationData.entity_person_citizenship_country,
    //         entity_person_country_of_residence: formData.entity_person_country_of_residence || detailsFromIdVerificationData.entity_person_country_of_residence,
    //     }));
    // }, [personalDetailsFromIdVerificationState]);

    const getPersonalDetailsFromIdHandler = () => {
        personalDetailsFromIdVerification({ personId: data.entity_person_id });
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target || {};

        if (data && data.document_id && Number.isInteger(data.document_id) && (name === "entity_person_country_of_residence" || name === "document_expiry_date" || name === "document_country_of_issuance" || name === "document_type_id")) {
            setFormData((formData) => ({ ...formData, [name]: value, document_id: JSON.stringify(data.document_id) }));
        } else {
            setFormData((formData) => ({ ...formData, [name]: value }));
        }

    }, []);

    const handleSubmit = (e) => {
        e?.preventDefault();
        const error = [];
        setRadioError([]);
        if (detailsFromIdVerificationData.prs_is_address_correct && [null, undefined].includes(formData.primary_address_same_as_berbix)) {
            error.push("primary_address_same_as_berbix");
        }
        if (error.length) {
            setTimeout(() => {
                setRadioError(error);
            }, 400);
        } else {
            updatePersonalDetails({
                personId: data.entity_person_id, token: data.continue_signup_token, body: {
                    ...formData,
                },
                birbixData: detailsFromIdVerificationData,
                cb: onStepChange,
            });
        }
    };

    const onRefFocus = useCallback(async (textFieldName, textFieldId) => {
        if (textFieldName === "clear") {
            setInputRefs([]);
        } else {
            let refArray = [...inputRefs];
            refArray.push(textFieldId || textFieldName);
            setInputRefs((ar) => [...new Set([...ar, (textFieldId || textFieldName)])]);
        }
    }, []);

    const clearRefFocus = useCallback(() => {
        onRefFocus("clear");
    }, []);


    const onChange = useCallback((e) => {
        const { name, value } = e.target || {};
        if (name === "primary_address_same_as_berbix") {
            setRadioError([]);
        }
        setFormData(formData => ({ ...formData, [name]: value }));
    }, []);


    const onCheckBoxPress = (e) => {
        const { name, checked } = e.target || {};
        // setState((state) => ({ ...state, [name]: checked }));
        setFormData((formData) => ({ ...formData, [name]: checked }));
    };

    const onGenderChange = useCallback((e, val) => {
        const { id } = val || {};
        setFormData(formData => ({ ...formData, entity_person_gender: id }));
    }, []);

    const UnderLineTitle = ({ title }) => {
        return (
            <>
                <Typography className={classes.titleTxt} variant="subtitle2" color="grey700">{title}</Typography>
                <Divider className={classes.divider} />
            </>
        )
    }

    if (personalDetailsFromIdVerificationState.loading || !personalDetailsFromIdVerificationState?.data?.id_verification_data_synced) {
        return <Loader loadingTxt={waitingText} />
    }

    return (<form onSubmit={handleSubmit}>
        <div className={classes.root}>
            <StyledError data={personalDetailsFromIdVerificationState} retry={getPersonalDetailsFromIdHandler} />
            <div className={classes.txtRoot} >
                <Typography color="primary" variant="h6" className={classes.bodyTxtCenter}>
                    <FormattedMessage {...intl.idVerificationConfirmDetailsTitle} />
                </Typography>

                <div className={classes.subtitleDiv} >

                    <Typography color="primary" variant="body2" >
                        <span className={classes.subtitle2} >
                            {data.entity_person_first_name}
                        </span>
                        <FormattedMessage {...intl.idVerificationConfirmDetailsSubTitle} />
                        <span className={classes.subtitle2} >
                            {data.company_name || data.invited_for}
                        </span>.
                    </Typography>
                </div>


                <Typography color="primary" variant="body2" className={classes.bodyTxt}>
                    <FormattedMessage {...intl.idVerificationConfirmDetailsSubTitle2} />
                </Typography>
            </div>

            <div className={classes.container}>

                <UnderLineTitle title="Personal Details" />
                {/* {(!detailsFromIdVerificationData.entity_person_first_name || !detailsFromIdVerificationData.entity_person_last_name) && <div className={classes.inputDiv}>
                    <TextField type="text"
                        id="entity_person_first_name" name="entity_person_first_name"
                        value={formData.entity_person_first_name || ""}
                        label={'Name'}
                        required

                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_first_name"}
                        onBlur={clearRefFocus}
                    />
                </div>}
                {(!detailsFromIdVerificationData.entity_person_first_name || !detailsFromIdVerificationData.entity_person_last_name) && <div className={classes.inputDiv}>
                    <TextField type="text"
                        id="entity_person_middle_name" name="entity_person_middle_name"
                        value={formData.entity_person_middle_name || ""}
                        label={'Name'}

                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_middle_name"}
                        onBlur={clearRefFocus}
                    />
                </div>}
                {(!detailsFromIdVerificationData.entity_person_first_name || !detailsFromIdVerificationData.entity_person_last_name) && <div className={classes.inputDiv}>
                    <TextField type="text"
                        id="entity_person_last_name" name="entity_person_last_name"
                        value={formData.entity_person_last_name || ""}
                        label={'Name'}
                        required

                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_last_name"}
                        onBlur={clearRefFocus}
                    />
                </div>} */}
                {!detailsFromIdVerificationData.entity_person_date_of_birth && <div className={classes.rowInputDiv} >
                    <TextField
                        type="date"
                        id="entity_person_date_of_birth"
                        name="entity_person_date_of_birth"
                        value={formData.entity_person_date_of_birth || ""}
                        label={"Date of Birth"}
                        required
                        inputProps={{
                            min: minDateDob,
                            max: maxDateDob
                        }}
                        placeholder=""
                        errorText={formData.entity_person_date_of_birth ? "You must be 18 years or older" : "Required"}
                        onChange={onChange}
                        InputLabelProps={{ shrink: true }}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_date_of_birth"}
                        onBlur={clearRefFocus}
                    />
                </div>}
                {!detailsFromIdVerificationData.entity_person_gender && <div className={classes.inputDiv}>
                    <Autocomplete
                        id="entity_person_gender"
                        name="entity_person_gender"
                        value={formData.entity_person_gender || ""}
                        label={"Gender"}
                        required
                        errorText="Required"
                        onChange={onGenderChange}
                        getOptions={() => null}
                        options={{ data: [{ id: 'MALE', name: 'Male' }, { id: 'FEMALE', name: 'Female' }, { id: 'OTHER', name: 'Other' }] }}
                        selectKey="id"
                        labelKey="name"
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_gender"}
                        onBlur={clearRefFocus}
                    />
                </div>}

                {!detailsFromIdVerificationData.entity_person_citizenship_country && <div className={classes.inputDiv}>
                    <SelectCountry
                        id="entity_person_citizenship_country" name="entity_person_citizenship_country"
                        value={formData.entity_person_citizenship_country || ""}
                        label={'Citizenship'}
                        required
                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_citizenship_country"}
                        onBlur={clearRefFocus}
                    />
                </div>}
                {!detailsFromIdVerificationData.entity_person_country_of_residence && <div className={classes.inputDiv}>
                    <SelectCountry
                        id="entity_person_country_of_residence" name="entity_person_country_of_residence"
                        value={formData.entity_person_country_of_residence || detailsFromIdVerificationData?.prs_address?.country_code || formData.entity_person_citizenship_country || ""}
                        label={'Country Of Residence'}
                        required
                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "entity_person_country_of_residence"}
                        onBlur={clearRefFocus}
                    />
                </div>}
                <div className={classes.inputDiv}>
                    <TextField
                        type="text"
                        id="entity_person_tax_id_value" name="entity_person_tax_id_value"
                        value={formData.entity_person_tax_id_value || ""}
                        label={isUsUser ? 'Social Security Number' : 'Tax Id Number'}
                        required
                        inputProps={{
                            minlength: isUsUser ? 9 : 1,
                            maxlength: isUsUser ? 9 : null,
                        }}
                        onChange={handleChange}
                        // disabled
                        onFocusRef={onRefFocus}
                        helperText={isUsUser ? "" : <div>Enter your Tax Id Number of <strong>{formData.entity_person_country_of_residence ? <DisplayCountry countryCode={formData.entity_person_country_of_residence} /> : "Residential Country"}</strong></div>}
                        makeFocus={inputRefs[0] === "entity_person_tax_id_value"}
                        onBlur={clearRefFocus}
                    />
                </div>

                {/* {
                (!detailsFromIdVerificationData.document_type_id
                    && ((formData.entity_person_citizenship_country && formData.entity_person_citizenship_country !== 'US')
                    && (formData.entity_person_country_of_residence && formData.entity_person_country_of_residence !== 'US'))
                ) && <div className={classes.inputDiv}>
                    <SelectGovIdType
                        id={`document_type_id`}
                        name="document_type_id"
                        value={formData.document_type_id || ""}
                        label={'Govt Issued ID Type'}
                        required
                        errorText="Required"
                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "document_type_id"}
                        onBlur={clearRefFocus}
                    />
                </div>
            }
            {
                (!detailsFromIdVerificationData.document_id_number
                    && ((formData.entity_person_citizenship_country && formData.entity_person_citizenship_country !== 'US')
                    && (formData.entity_person_country_of_residence && formData.entity_person_country_of_residence !== 'US'))
                ) && <div className={classes.inputDiv}>
                    <TextField
                        type="text"
                        id="document_id_number" name="document_id_number"
                        value={formData.document_id_number || ""}
                        label={'Govt Issued ID Number'}
                        required
                        onChange={handleChange}
                        // disabled
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "document_id_number"}
                        onBlur={clearRefFocus}
                    />
                </div>
            }
            {
                (!detailsFromIdVerificationData.document_country_of_issuance
                    && ((formData.entity_person_citizenship_country && formData.entity_person_citizenship_country !== 'US')
                    && (formData.entity_person_country_of_residence && formData.entity_person_country_of_residence !== 'US'))
                ) && <div className={classes.inputDiv}>
                    <SelectCountry
                        id="document_country_of_issuance" name="document_country_of_issuance"
                        value={formData.document_country_of_issuance || ""}
                        label={'Country of Issuance'}
                        required
                        onChange={handleChange}
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "document_country_of_issuance"}
                        onBlur={clearRefFocus}
                    />
                </div>
            }

            {
                (!detailsFromIdVerificationData.document_expiry_date
                    && ((formData.entity_person_citizenship_country && formData.entity_person_citizenship_country !== 'US')
                    && (formData.entity_person_country_of_residence && formData.entity_person_country_of_residence !== 'US'))
                ) && <div className={classes.inputDiv}>
                    <TextField
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        id="document_expiry_date" name="document_expiry_date"
                        value={formData.document_expiry_date ? moment(formData.document_expiry_date).format("YYYY-MM-DD") : ""}
                        inputProps={{
                            min: minDateExpire
                        }}
                        label={'Date of Expiration'}
                        required
                        errorText={formData.document_expiry_date ? "Invalid Date" : ""}
                        onChange={handleChange}
                        // disabled
                        onFocusRef={onRefFocus}
                        makeFocus={inputRefs[0] === "document_expiry_date"}
                        onBlur={clearRefFocus}
                    />
                </div>
            } */}


                {detailsFromIdVerificationData.prs_is_address_correct ? <div>
                    <UnderLineTitle title="Address" />
                    <div className={classes.birbixAddress}>
                        <Typography variant="caption">Residential Address</Typography>
                        <div>
                            <Typography variant="subtitle1">{detailsFromIdVerificationData?.prs_address?.address_line_1}, {detailsFromIdVerificationData?.prs_address?.address_line_2}</Typography>
                            <Typography variant="subtitle1">{detailsFromIdVerificationData?.prs_address?.landmark}</Typography>
                            <Typography variant="subtitle1">{detailsFromIdVerificationData?.prs_address?.city}, {detailsFromIdVerificationData?.prs_address?.state_name || detailsFromIdVerificationData?.prs_address?.state_code}</Typography>
                            <Typography variant="subtitle1">{detailsFromIdVerificationData?.prs_address?.country_name || detailsFromIdVerificationData?.prs_address?.country_code}, {detailsFromIdVerificationData?.prs_address?.postal_code}</Typography>
                        </div>
                    </div>
                    <div className={classes.radioInputDiv}>
                        <div className={classes.commonFlex}><Typography className={radioError.includes("primary_address_same_as_berbix") && classes.redFont} variant="subtitle2" color="grey700">This is my current address</Typography></div>
                        <div className={classes.radioFlex}>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="is_current_address" name="primary_address_same_as_berbix" value={formData.primary_address_same_as_berbix} onChange={onChange} classes={{ row: classes.justifyContentCenter }}>
                                    <FormControlLabel value={1} control={<Radio className={radioError.includes("primary_address_same_as_berbix") ? classes.iconRedColor : classes.iconColor} checked={(formData.primary_address_same_as_berbix === "true" || formData.primary_address_same_as_berbix == 1) && true} />} label={`Yes`} />
                                    <FormControlLabel value={0} control={<Radio className={radioError.includes("primary_address_same_as_berbix") ? classes.iconRedColor : classes.iconColor} checked={(formData.primary_address_same_as_berbix === "false" || formData.primary_address_same_as_berbix == 0) && true} />} label={`No`} />
                                </RadioGroup>
                            </FormControl>

                        </div>
                    </div>
                </div> : null}
            </div>

            {
                !isEntityPersonPrimary && <>
                    <div className={classes.conditionsCheck}>
                        <FormGroup className={classes.ownerShipComplete}>
                            <FormControlLabel
                                onChange={onCheckBoxPress}
                                control={<Checkbox className={classes.ownershipCompleteCheckBox} checked={formData.tnc_and_privacy_accepted} name="tnc_and_privacy_accepted" />} label={<Typography color="primary" variant="inherit">
                                    I agree to the <Link className={classes.underline} href="/legal/terms-and-conditions" target="_blank">Terms and Conditions</Link>, and <Link className={classes.underline} href="https://trulyfinancial.com/legal/#privacy" target="_blank">Privacy Policy</Link> of Truly Financial
                                </Typography>} />
                        </FormGroup>
                    </div>
                </>
            }

            {isLoadingData && <Loader />}

            <Button

                title={"Continue"}
                disabled={(!isEntityPersonPrimary && !formData.tnc_and_privacy_accepted) || isLoadingData}
                className={classes.continueBtn}
                type="submit"
                color="secondary"
            />
        </div>

    </form >)
}

const mapStateToProps = createStructuredSelector({
    updatePersonalDetailsState: updatePersonalDetailsState(),
    personalDetailsFromIdVerificationState: personalDetailsFromIdVerificationState(),
});

function mapDispatchToProps(dispatch) {
    return {
        updatePersonalDetails: data => dispatch(updatePersonalDetailsAction(data)),
        personalDetailsFromIdVerification: data => dispatch(personalDetailsFromIdVerificationAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(UpdateRequiredDetails);