/* eslint-disable eqeqeq */
// eslint-disable-next-line no-unused-vars
import { ENTITY_PERSON_STATUS, ENTITY_PERSON_TYPE } from "@truly-financial/common/consts/entity-person.consts";
import { getKeyValueByStringPath, getUrlParamByName, removeNullKeys } from "utils/common";
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import moment from "moment";

const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;
const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
const BUSINESS_BRIEF = REGISTRATION_STEP.BUSINESS_BRIEF;
const BUSINESS_CATEGORIES = REGISTRATION_STEP.BUSINESS_CATEGORIES;
const BUSINESS_ADDRESS = REGISTRATION_STEP.BUSINESS_ADDRESS;
const BUSINESS_INDUSTRIES = REGISTRATION_STEP.BUSINESS_INDUSTRIES;
const BUSINESS_USE_CASES = REGISTRATION_STEP.BUSINESS_USE_CASES;
const BUSINESS_COUNTRIES = REGISTRATION_STEP.BUSINESS_COUNTRIES;
const BUSINESS_SOURCE_OF_FUNDS = REGISTRATION_STEP.BUSINESS_SOURCE_OF_FUNDS;
const BUSINESS_STRUCTURE = REGISTRATION_STEP.BUSINESS_STRUCTURE;
const PERSONAL_DETAILS = REGISTRATION_STEP.PERSONAL_DETAILS;
const BENEFICIAL_OWNERS = REGISTRATION_STEP.BENEFICIAL_OWNERS;
const CONTROLLING_PARTY = REGISTRATION_STEP.CONTROLLING_PARTY;
const ID_VERIFICATION = REGISTRATION_STEP.ID_VERIFICATION;
const ACCOUNT_AGREEMENT = REGISTRATION_STEP.ACCOUNT_AGREEMENT;
const TERMS_AND_CONDITIONS = REGISTRATION_STEP.TERMS_AND_CONDITIONS;

const preRequiredStepForIdVerification = [
    CUSTOMER_INTRODUCTION, CONFIDENTIAL_AGREEMENT, BUSINESS_BRIEF, BUSINESS_ADDRESS, BUSINESS_INDUSTRIES, BUSINESS_USE_CASES, BUSINESS_COUNTRIES, BUSINESS_SOURCE_OF_FUNDS,
    BUSINESS_STRUCTURE, PERSONAL_DETAILS, BENEFICIAL_OWNERS, CONTROLLING_PARTY
];
const preRequiredStepForInformationReview = [
    CUSTOMER_INTRODUCTION, CONFIDENTIAL_AGREEMENT, BUSINESS_BRIEF, BUSINESS_ADDRESS, BUSINESS_INDUSTRIES, BUSINESS_USE_CASES, BUSINESS_COUNTRIES, BUSINESS_SOURCE_OF_FUNDS,
    BUSINESS_STRUCTURE, PERSONAL_DETAILS, BENEFICIAL_OWNERS, CONTROLLING_PARTY, ID_VERIFICATION
];
export const getCustomerDetailParser = (r = {}, cs) => {
    const sp = getUrlParamByName("s");
    const spStr = REGISTRATION_STEP[sp];
    const currentStep = cs || spStr;

    const parsedResponse = { ...r };
    const prs_user = (Array.isArray(r.data?.entity_persons) && r.data?.entity_persons.find(user => user.user_account_id === r.data?.user_account_id)) || {};
    const primaryAdd = r.data && r.data.addresses && r.data.addresses.filter(item => item.address_type === "PRIMARY")[0];
    const primaryContact = r.data && r.data.entity_persons && r.data.entity_persons.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT)[0];
    const controllingParty = r.data && r.data.entity_persons && r.data.entity_persons.filter(item => item.is_controlling_party)[0];
    const controllingPartyPrimaryAdd = controllingParty && controllingParty.addresses && controllingParty.addresses.filter(item => item.address_type === "PRIMARY")[0];
    const beneficialOwnersForAgreement = r.data && r.data.entity_persons && r.data.entity_persons.filter(item => Number(item.ownership_percentage) > 24);

    const prs_agreement_data = {
        prs_company_name: r?.data?.company_name,
        prs_tax_id_value: r?.data?.company_details?.business_tax_id_value,
        prs_entity_street_address: primaryAdd?.address_line_1,
        prs_entity_city: primaryAdd?.city,
        prs_entity_state_name: primaryAdd?.state_name,
        prs_entity_zip_code: primaryAdd?.postal_code,
        prs_entity_country_name: primaryAdd?.country_name || primaryAdd?.country_code,
        prs_full_name: primaryContact?.entity_person_first_name + " " + primaryContact?.entity_person_last_name,
        prs_controlling_party_contact_person_position: controllingParty?.contact_person_position,
        prs_controlling_party_contact_person_last_name: controllingParty?.entity_person_last_name,
        prs_controlling_party_contact_person_middle_name: controllingParty?.entity_person_middle_name,
        prs_controlling_party_contact_person_first_name: controllingParty?.entity_person_first_name,
        prs_controlling_party_street_address: controllingPartyPrimaryAdd?.address_line_1,
        prs_controlling_party_city: controllingPartyPrimaryAdd?.city,
        prs_controlling_party_state: controllingPartyPrimaryAdd?.state_name,
        prs_controlling_party_zip_code: controllingPartyPrimaryAdd?.postal_code,
        prs_controlling_party_country_name: controllingPartyPrimaryAdd?.country_name,
        prs_controlling_party_date_of_birth: controllingParty?.entity_person_date_of_birth ? moment(new Date(controllingParty?.entity_person_date_of_birth)).utc().format("MM/DD/YYYY") : "-",
        prs_controlling_party_ssn_value: controllingParty?.ssn_value || "-",
        prs_controlling_party_id_number: controllingParty?.document_id_number || controllingParty?.id_number || "-",
        prs_controlling_party_country_of_issuance: controllingParty?.country_of_issuance_name || controllingParty?.document_country_of_issuance || controllingParty?.country_of_issuance || "-",
        prs_controlling_party_expiry_date: (controllingParty?.document_expiry_date && moment(new Date(controllingParty?.document_expiry_date)).utc().format("MM/DD/YYYY")) || (controllingParty?.expiry_date && moment(new Date(controllingParty?.expiry_date)).utc().format("MM/DD/YYYY")) || "-",
        prs_beneficialOwners: beneficialOwnersForAgreement,
    };
    parsedResponse.data = {
        ...r.data,
        status: 1,
        prs_user,
        prs_agreement_data
    };
    parsedResponse.data = {
        ...parsedResponse.data,
        ...getCurrentStepAndData(parsedResponse.data, currentStep),
        slideStepDirection: 'left',
        access_token: primaryContact?.access_token,
        // entity_status: "UNDER_EDD_PROCESS"
        // INITIATED
        // REGISTRATION_SUBMITTED
        // UNDER_EDD_PROCESS
        // UNDER_COMPLIANCE_REVIEW
        // APPROVED
        // APPROVED_NO_TRADING
        // REJECTED
    };
    return parsedResponse;
};

const getObjectByArrayKeys = ({ step, data, keys = [], optionalKeys = [] }) => {

    const ob = {};
    keys.forEach(elm => {
        const keySplitArray = elm && elm.split('.');
        const mainKey = keySplitArray[keySplitArray.length - 1];
        const v = getKeyValueByStringPath(data, elm);
        ob[mainKey] = v;
    });
    ob.isStepComplete = (Array.isArray(data.completed_steps) && data.completed_steps.includes(step)) || false;
    return ob;
};

const getCurrentStepAndData = (data = {}, currentStep) => {

    const incorporationCountryCode = (data.company_details && data.company_details.incorporation_country_code) || null;
    const completedSteps = (Array.isArray(data.completed_steps) && data.completed_steps) || [];
    const entityPersons = data?.entity_persons || [];
    // const businessCategories = data.business_categories || [];
    // categories removed from steps
    // const allowedBusinessCategories = !businessCategories.length || (businessCategories.length === 1 && businessCategories[0].business_category_id === -1) ? true : false;
    const controllingParties = entityPersons.filter(i => i.is_controlling_party);
    const isValidControllingPartyCount = controllingParties.length === 1 ? true : false;
    const enteredShareholdingPercentage = entityPersons.reduce((prevValue, currentValue) => isNaN(Number(currentValue.ownership_percentage)) ? prevValue : Number(currentValue.ownership_percentage) + prevValue, 0);
    const isValidShareholdingPercentage = enteredShareholdingPercentage > 100 ? false : true;
    const supportedCountries = ["US", 'CA'];
    const prog = (100 - (100 - ((100 / 12) * completedSteps.length)));
    const progress = prog > 100 ? 100 : prog;
    // eslint-disable-next-line eqeqeq
    // const skipOwnership = (completedSteps.includes(BENEFICIAL_OWNERS) && (['FREELANCER', 'SOLE_PROPRIETORSHIP'].includes(data.entity_sub_type) && isValidControllingPartyCount)) ? true : false;
    const skipOwnership = (completedSteps.includes(BENEFICIAL_OWNERS) && (entityPersons.length === 1 && entityPersons[0].is_controlling_party && entityPersons[0].ownership_percentage == '100')) ? true : false;
    const skipControllingParty = completedSteps.includes(CONTROLLING_PARTY) && (entityPersons.length === 1 && entityPersons[0].is_controlling_party) ? true : false;
    const hideOwnershipPercentage = ['FREELANCER', 'SOLE_PROPRIETORSHIP'].includes(data.entity_sub_type) ? true : false;
    const allowRegistrationDataEdit = data.allow_registration_data_edit ? true : false;
    const remainingStep = getRemainingStep(completedSteps, allowRegistrationDataEdit);
    const remainingMainStep = getMainStepFromStep(remainingStep);
    const mainStep = getMainStepFromStep(currentStep);
    const isAllowIdVerificationStepRender = completedSteps.length > 0 && preRequiredStepForIdVerification.every(v => completedSteps.includes(v));
    const isAllowInformationStepRender = completedSteps.length > 0 && preRequiredStepForInformationReview.every(v => completedSteps.includes(v));
    const isIdVerificationContinueBtnDisable = entityPersons.filter(item => item.entity_person_invitation_url).every((item) => (item.entity_person_id_verification_status === "PASS_PROCESSED" || item.entity_person_id_verification_status === "PENDING_PROCESSED"));
    const checkIsExtraDetail = data && data.company_details && data.company_details.extra_registration_details && data.company_details.extra_registration_details.filter(i => !i.field_value);

    const isRegExtraInfo = data && data.company_details && data.company_details.extra_registration_details_required && checkIsExtraDetail.length > 0 ? true : false;

    const obj = {
        mainStep: (currentStep && mainStep) || remainingMainStep,
        currentStep: (currentStep || remainingMainStep),
        progress,
        isAllStepsCompleted: data.entity_status === 'REGISTRATION_SUBMITTED' || remainingStep === null ? true : false,
        isTerminateRegistration: incorporationCountryCode && (!supportedCountries.includes(incorporationCountryCode)) ? true : false,
        skipOwnership,
        skipControllingParty,
        hideOwnershipPercentage,
        isValidShareholdingPercentage,
        isValidControllingPartyCount,
        isIdVerificationContinueBtnDisable,
        allowRegistrationDataEdit,
        isRegExtraInfo,
        isAllowIdVerificationStepRender,
        isAllowInformationStepRender
    };

    //step five
    obj.prs_terms_and_conditions_data = getObjectByArrayKeys({ step: TERMS_AND_CONDITIONS, data, keys: ['terms_and_conditions_accepted', 'terms_and_conditions_name'], optionalKeys: [] });

    //step four
    obj.prs_controlling_party_data = getObjectByArrayKeys({ step: CONTROLLING_PARTY, data, keys: ['entity_persons'], optionalKeys: [] });
    obj.prs_beneficial_owners_data = getObjectByArrayKeys({ step: BENEFICIAL_OWNERS, data, keys: ['entity_persons'], optionalKeys: [] });
    obj.prs_personal_details_data = getObjectByArrayKeys({
        step: PERSONAL_DETAILS, data: { ...data.prs_user, completed_steps: completedSteps }, keys: [
            'entity_person_first_name',
            'entity_person_last_name',
            'entity_person_email',
            'contact_person_position',
            'entity_person_mobile',
            'entity_person_mobile_country_code',
            'entity_person_mobile_verified',
            'ownership_percentage',
            'is_controlling_party',
            'entity_person_id_verification_status',
        ], optionalKeys: []
    });

    obj.prs_business_structure_data = getObjectByArrayKeys({ step: BUSINESS_STRUCTURE, data, keys: ['company_details.incorporation_jurisdiction', 'company_details.no_of_employee', 'company_details.business_tax_id_value', 'entity_sub_type', 'entity_sub_type_document_url', 'entity_sub_type_document_name'], optionalKeys: [] });

    //step three
    obj.prs_business_source_of_funds_data = getObjectByArrayKeys({ step: BUSINESS_SOURCE_OF_FUNDS, data, keys: ['source_of_funds'], optionalKeys: [] });
    obj.prs_business_countries_data = getObjectByArrayKeys({ step: BUSINESS_COUNTRIES, data, keys: ['countries'], optionalKeys: [] });
    obj.prs_business_use_cases_data = getObjectByArrayKeys({ step: BUSINESS_USE_CASES, data, keys: ['business_use_cases'], optionalKeys: [] });

    //step two
    obj.prs_business_industries_data = getObjectByArrayKeys({ step: BUSINESS_INDUSTRIES, data, keys: ['industries'], optionalKeys: [] });
    obj.prs_business_address_data = getObjectByArrayKeys({ step: BUSINESS_ADDRESS, data, keys: ['addresses', 'primary_address_same_as_communication'], optionalKeys: [] });
    obj.prs_business_categories_data = getObjectByArrayKeys({ step: BUSINESS_CATEGORIES, data, keys: ['business_categories'], optionalKeys: [] });
    obj.prs_business_categories_data.isStepComplete = true;
    obj.prs_business_brief_data = getObjectByArrayKeys({ step: BUSINESS_BRIEF, data, keys: ['company_details.website', 'company_details.business_brief'], optionalKeys: [] });

    //step one
    obj.prs_confidential_agreement_data = getObjectByArrayKeys({ step: CONFIDENTIAL_AGREEMENT, data, keys: [], optionalKeys: [] });
    obj.prs_customer_introduction_data = getObjectByArrayKeys({
        step: CUSTOMER_INTRODUCTION, data, keys: [
            'prs_user.entity_person_first_name',
            'prs_user.entity_person_middle_name',
            'prs_user.contact_person_position',
            'prs_user.entity_person_last_name',
            'prs_user.entity_person_mobile_country_code',
            'prs_user.entity_person_mobile',
            'company_name',
            'brand_name',
            'company_details.incorporation_country_code',
        ], optionalKeys: []
    });

    if (obj.prs_customer_introduction_data.isStepComplete && obj.prs_confidential_agreement_data.isStepComplete) {
        obj.prs_main_confidential_agreement_isStepComplete = true;
    } else {
        obj.prs_main_confidential_agreement_isStepComplete = false;
    }
    if (obj.prs_business_brief_data.isStepComplete && obj.prs_business_categories_data.isStepComplete && obj.prs_business_address_data.isStepComplete && obj.prs_business_industries_data.isStepComplete) {
        obj.prs_main_business_brief_isStepComplete = true;
    } else {
        obj.prs_main_business_brief_isStepComplete = false;
    }
    if (obj.prs_business_use_cases_data.isStepComplete && obj.prs_business_countries_data.isStepComplete && obj.prs_business_source_of_funds_data.isStepComplete) {
        obj.prs_main_business_categories_isStepComplete = true;
    } else {
        obj.prs_main_business_categories_isStepComplete = false;
    }
    if (obj.prs_business_structure_data.isStepComplete && obj.prs_personal_details_data.isStepComplete && obj.prs_beneficial_owners_data.isStepComplete && obj.prs_controlling_party_data.isStepComplete) {
        obj.prs_main_business_address_isStepComplete = true;
    } else {
        obj.prs_main_business_address_isStepComplete = false;
    }
    if (obj.prs_terms_and_conditions_data.isStepComplete) {
        obj.prs_main_terms_and_conditions_isStepComplete = true;
    } else {
        obj.prs_main_terms_and_conditions_isStepComplete = false;
    }

    return obj;
};

export const verifyPersonalDetailParser = (data = {}) => {

    const { cb, body = {} } = data;

    const parsedBody = body.map((item) => removeNullKeys({
        entity_person_id: item.entity_person_id || null,
        entity_person_first_name: item.entity_person_first_name || null,
        entity_person_last_name: item.entity_person_last_name || null,
        entity_person_email: item.entity_person_email || null,
        entity_person_mobile: item.entity_person_mobile || null,
        contact_person_position: item.contact_person_position || null,
        entity_person_type: item.entity_person_type || null,
        entity_person_mobile_country_code: item.entity_person_mobile_country_code || null,
        is_controlling_party: (item.is_controlling_party === 1 || item.is_controlling_party === "true") ? true : false,
        ownership_percentage: item.ownership_percentage || "0",
    }));

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            entity_persons: parsedBody.filter((item) => item.entity_person_type !== "PRIMARY_CONTACT"),
        }),
    };
    parsedData.primaryBody = parsedBody.filter((item) => item.entity_person_type === "PRIMARY_CONTACT")[0];

    if (parsedData && parsedData.primaryBody && Number(parsedData.primaryBody.ownership_percentage) === 100 && parsedData.primaryBody.entity_person_id) {
        parsedData.primaryBody.is_controlling_party = true
        parsedData.controllingPartyBody = {
            entity_person_ids: [parsedData.primaryBody.entity_person_id]
        }
    }

    return parsedData;
};

export const saveCustomerDetailParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            entity_person_first_name: body.entity_person_first_name || null,
            entity_person_middle_name: body.entity_person_middle_name || null,
            entity_person_mobile_country_code: body.entity_person_mobile_country_code || null,
            entity_person_mobile: body.entity_person_mobile || null,
            entity_person_last_name: body.entity_person_last_name || null,
            contact_person_position: body.contact_person_position || null,
            company_name: body.company_name || null,
            daily_limit: body.daily_limit || null,
            brand_name: body.brand_name || "",
            incorporation_country_code: body.incorporation_country_code || null,
        }),
    };
    return parsedData;
};
export const saveCustomerBusinessBriefParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            website: body.website || "",
            business_brief: body.business_brief || null,

        }),
    };
    return parsedData;
};
export const saveBusinessCategoriesParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            business_categories: body.business_categories || null,
        }),
    };
    return parsedData;
};
export const saveBusinessIndustriesParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            industries: body.industries || null,
        }),
    };
    return parsedData;
};
export const saveBusinessUseCasesParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            business_use_cases: body.business_use_cases || null,
        }),
    };
    return parsedData;
};
export const saveBusinessCountriesParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            countries: body.countries || null,
        }),
    };
    return parsedData;
};
export const saveBusinessSourceOfFundingParser = (data = {}) => {
    const { cb, body = {} } = data;

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            source_of_funds: body.source_of_funds || null,
        }),
    };
    return parsedData;
};
export const saveBusinessStructureParser = (data = {}) => {
    const { cb, body = {} } = data;
    let parsedData = {
        cb: cb || (() => null),
        body: {
            incorporation_jurisdiction: body.incorporation_jurisdiction || null,
            no_of_employee: body.no_of_employee || null,
            business_tax_id_type: body.business_tax_id_type || null,
            business_tax_id_value: body.business_tax_id_value || null,
            entity_sub_type: body.entity_sub_type || null,
        },
        // body: removeNullKeys({
        //     incorporation_jurisdiction: body.incorporation_jurisdiction || null,
        //     no_of_employee: body.no_of_employee || null,
        //     business_tax_id_type: body.business_tax_id_type || null,
        //     business_tax_id_value: body.business_tax_id_value || null,
        //     entity_sub_type: body.entity_sub_type || null,
        // }),
    };
    return parsedData;
};
export const addCustomerContactDetailsParser = (data = {}) => {
    const { cb, body = {} } = data;
    const { bodyCustomerDetail = {}, bodyCountry = {} } = body;
    const communicationAddress = bodyCustomerDetail.communicationAddress || {};
    const primaryAddress = bodyCustomerDetail.primaryAddress || {};
    const parsedData = {};
    if (Object.keys(primaryAddress).length > 0 || Object.keys(communicationAddress).length > 0) {
        let addresses = [communicationAddress];
        if (!bodyCustomerDetail.primary_address_same_as_communication) {
            addresses = [primaryAddress, communicationAddress];
        }
        parsedData.bodyCustomerDetail =
            removeNullKeys({
                primary_address_same_as_communication: !!bodyCustomerDetail.primary_address_same_as_communication,
                addresses,
            });

    }

    // parsedData.cb = cb || (() => null);
    parsedData.bodyCountry = bodyCountry;
    let temp = { body: parsedData, cb: cb || (() => null) };
    return temp;
};
export const addCustomerBusinessDetailParser = (data = {}) => {
    const { cb, body = {} } = data;

    const entity_person_primary = body && body.primaryBody && body.primaryBody.filter(item => item.entity_person_type === "PRIMARY_CONTACT").map((item) => removeNullKeys({
        entity_person_id: item.entity_person_id || null,
        entity_person_first_name: item.entity_person_first_name || null,
        entity_person_last_name: item.entity_person_last_name || null,
        entity_person_email: item.entity_person_email || null,
        contact_person_position: item.contact_person_position || null,
        entity_person_mobile: item.entity_person_mobile || null,
        entity_person_type: item.entity_person_type || null,
        entity_person_mobile_country_code: item.entity_person_mobile_country_code || null,
        is_controlling_party: true,
        ownership_percentage: "100",
    }));

    let parsedData = {
        body,
        cb: cb || (() => null),

    };
    if (body && body.primaryBody && body.primaryBody.length > 0 && entity_person_primary.length > 0) {
        parsedData.body.primaryBody = entity_person_primary[0]
        parsedData.body.stackHolderBody = {
            entity_persons: []
        };
        parsedData.body.controllingParty = {
            entity_person_ids: [entity_person_primary[0].entity_person_id]
        }
    }


    return parsedData;
}
export const addBeneficialOwnerParser = (data = {}) => {
    const { cb, body = {} } = data;

    const parsedBody = body.map((item) => removeNullKeys({
        entity_person_id: item.entity_person_id || null,
        entity_person_first_name: item.entity_person_first_name || null,
        entity_person_last_name: item.entity_person_last_name || null,
        entity_person_email: item.entity_person_email || null,
        contact_person_position: item.contact_person_position || null,
        entity_person_mobile: item.entity_person_mobile || null,
        entity_person_type: item.entity_person_type || null,
        entity_person_mobile_country_code: item.entity_person_mobile_country_code || null,
        is_controlling_party: true,
        ownership_percentage: item.ownership_percentage || "0",
    }));

    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            entity_persons: parsedBody,
        }),
    };
    // parsedData.primaryBody = parsedBody.filter((item) => item === "PRIMARY_CONTACT")[0];
    return parsedData;
};

export const saveControllingPartyWithBeneficialOwnerParser = (data = {}) => {
    const { cb, body = {}, beneficialOwnerBody } = data;

    const parsedBody = beneficialOwnerBody.map((item) => removeNullKeys({
        entity_person_id: item.entity_person_id || null,
        entity_person_first_name: item.entity_person_first_name || null,
        entity_person_last_name: item.entity_person_last_name || null,
        entity_person_email: item.entity_person_email || null,
        contact_person_position: item.contact_person_position || null,
        entity_person_mobile: item.entity_person_mobile || null,
        entity_person_type: item.entity_person_type || null,
        entity_person_mobile_country_code: item.entity_person_mobile_country_code || null,
        ownership_percentage: item.ownership_percentage || "0",
    }));

    let parsedData = {
        cb: cb || (() => null),
        beneficialOwnerBody: removeNullKeys({
            entity_persons: parsedBody,
        }),
        body
    };

    return parsedData;
};
export const getMainStepFromStep = (step) => {

    const mainSteps = {
        CUSTOMER_INTRODUCTION: 'CONFIDENTIAL_AGREEMENT',
        CONFIDENTIAL_AGREEMENT: 'CONFIDENTIAL_AGREEMENT',
        BUSINESS_BRIEF: 'BUSINESS_BRIEF',
        BUSINESS_CATEGORIES: 'BUSINESS_BRIEF',
        BUSINESS_ADDRESS: 'BUSINESS_BRIEF',
        BUSINESS_INDUSTRIES: 'BUSINESS_BRIEF',
        BUSINESS_USE_CASES: 'BUSINESS_USE_CASES',
        BUSINESS_COUNTRIES: 'BUSINESS_USE_CASES',
        BUSINESS_SOURCE_OF_FUNDS: 'BUSINESS_USE_CASES',
        BUSINESS_STRUCTURE: 'BUSINESS_STRUCTURE',
        PERSONAL_DETAILS: 'BUSINESS_STRUCTURE',
        BENEFICIAL_OWNERS: 'BUSINESS_STRUCTURE',
        CONTROLLING_PARTY: 'BUSINESS_STRUCTURE',
        ID_VERIFICATION: 'ID_VERIFICATION',
        TERMS_AND_CONDITIONS: 'ID_VERIFICATION',
        ACCOUNT_AGREEMENT: "ID_VERIFICATION"
    };
    return mainSteps[step] || CUSTOMER_INTRODUCTION;
};


export const getRemainingStep = (steps = [], isEditAllow = false) => {

    if (!steps.includes(CONFIDENTIAL_AGREEMENT)) return CONFIDENTIAL_AGREEMENT;
    if (!steps.includes(CUSTOMER_INTRODUCTION)) return CUSTOMER_INTRODUCTION;
    if (!steps.includes(BUSINESS_BRIEF)) return BUSINESS_BRIEF;
    // if (!steps.includes(BUSINESS_CATEGORIES)) return BUSINESS_CATEGORIES;
    if (!steps.includes(BUSINESS_ADDRESS)) return BUSINESS_ADDRESS;
    if (!steps.includes(BUSINESS_INDUSTRIES)) return BUSINESS_INDUSTRIES;
    if (!steps.includes(BUSINESS_USE_CASES)) return BUSINESS_USE_CASES;
    if (!steps.includes(BUSINESS_COUNTRIES)) return BUSINESS_COUNTRIES;
    if (!steps.includes(BUSINESS_SOURCE_OF_FUNDS)) return BUSINESS_SOURCE_OF_FUNDS;
    if (!steps.includes(BUSINESS_STRUCTURE)) return BUSINESS_STRUCTURE;
    if (!steps.includes(PERSONAL_DETAILS)) return PERSONAL_DETAILS;
    if (!steps.includes(BENEFICIAL_OWNERS)) return BENEFICIAL_OWNERS;
    if (!steps.includes(CONTROLLING_PARTY)) return CONTROLLING_PARTY;
    if (!steps.includes(ID_VERIFICATION)) return ID_VERIFICATION;
    if (!steps.includes(TERMS_AND_CONDITIONS)) return TERMS_AND_CONDITIONS;

    // if (!data.account_agreement_accepted) return ACCOUNT_AGREEMENT;
    return isEditAllow ? CUSTOMER_INTRODUCTION : ACCOUNT_AGREEMENT;



};