/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import messages from 'components/intl/PaymentStatusIntl';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import triangle from 'assets/images/triangle.png';
import Polygon from 'assets/images/Polygon.png';
import { PAYMENT } from '@truly-financial/common/consts';
import successStatus from 'assets/images/successStatus.png';

const useStyles = makeStyles(theme => ({

    rootDiv: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    wrightDiv: (props) => ({
        height: theme.spacing(23.125),
        width: theme.spacing(23.125),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: props.directContent ? theme.spacing(0) : -theme.spacing(11.5),
        position: props.directContent ? "initial" : 'fixed',
        backgroundImage: `url(${successStatus})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

    }),
    triangleDiv: (props) => ({
        height: theme.spacing(23.75),
        width: theme.spacing(23.75),
        backgroundColor: 'rgba(0,0,0,0)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: props.directContent ? theme.spacing(0) : -theme.spacing(12.5),
        position: props.directContent ? "initial" : 'fixed',
        backgroundImage: `url(${triangle})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

    }),
    doneIcon: {
        fontSize: theme.spacing(18.125),
        color: theme.palette.primary.main
    },
    exclamationIcon: {
        fontSize: theme.spacing(8.125),
        marginTop: theme.spacing(2),
        color: theme.palette.primary.contrastText
    },
    payment_successfulTxt: {
        [theme.breakpoints.up('sm')]: {
            lineHeight: `${theme.spacing(7)}px`,
            fontSize: theme.spacing(5),
        },
        textAlign: 'center',
        fontSize: theme.spacing(4.5),
        fontWeight: 600,
        fontFamily: 'sofia pro',
        lineHeight: `${theme.spacing(7)}px`,
    },
    successTxtDiv: (props) => ({
        paddingTop: props.directContent ? theme.spacing(0) : theme.spacing(10.5),
        paddingBottom: theme.spacing(8),
        alignItems: 'center',
        justifyContent: 'center'
    }),
    pendingTxtDiv: (props) => ({
        paddingTop: props.directContent ? theme.spacing(0) : theme.spacing(15),
        paddingBottom: theme.spacing(10),
        alignItems: 'center',
        justifyContent: 'center',

    }),

    pendingTxt: {

        textAlign: 'center',

    },
    polygon: (props) => ({
        height: theme.spacing(23.75),
        width: theme.spacing(23.75),
        backgroundColor: 'rgba(0,0,0,0)',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: props.directContent ? theme.spacing(0) : -theme.spacing(12.8),
        position: props.directContent ? "initial" : 'fixed',
        backgroundImage: `url(${Polygon})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }),
    failIcon: {
        fontSize: theme.spacing(15.375),
        color: theme.palette.primary.contrastText
    }
}));

function PaymentStatus(props) {
    // eslint-disable-next-line no-unused-vars
    const { directContent = false } = props;

    const classes = useStyles(props);
    const { sendPaymentState = {}, getContactPaymentDetail,
        // resetSendPayment,
        setCurrentModal } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = sendPaymentState.data || {};
    // eslint-disable-next-line no-unused-vars
    const [calledPaymentDetails, setCalledPaymentDetails] = useState(false);

    useEffect(() => {
        if (data.payment_id && data.contact_id && !calledPaymentDetails) {
            getContactPaymentDetail({ contactId: data.contact_id, paymentId: data.payment_id });
            setCalledPaymentDetails(true);
            setTimeout(() => setCurrentModal(7), 3000);
        } else if (sendPaymentState.error) {
            setTimeout(() => setCurrentModal(4), 3000);
        }
    }, [sendPaymentState, setCurrentModal]);

    const content = () => {
        if (data.payment_id && [PAYMENT.PAYMENT_STATUS.PENDING].includes(data.payment_status)) {
            return (
                <>
                    <div className={classes.wrightDiv} >
                    </div>
                    <div className={classes.successTxtDiv} >
                        <Typography className={classes.payment_successfulTxt} ><FormattedMessage {...messages.payment} /></Typography>
                        <Typography className={classes.payment_successfulTxt} ><FormattedMessage {...messages.successful} /></Typography>
                    </div>
                </>
            )
        } else if (data.payment_id && [PAYMENT.PAYMENT_STATUS.SCHEDULED].includes(data.payment_status)) {
            return (
                <>
                    <div className={classes.wrightDiv} >

                    </div>
                    <div className={classes.successTxtDiv} >
                        <Typography className={classes.payment_successfulTxt} ><FormattedMessage {...messages.payment} /></Typography>
                        <Typography className={classes.payment_successfulTxt} ><FormattedMessage {...messages.scheduled} /></Typography>
                    </div>
                </>
            )
        } else if (data.payment_id && [PAYMENT.PAYMENT_STATUS.QUEUED].includes(data.payment_status)) {
            return (
                <>
                    <div className={classes.triangleDiv} >

                    </div>
                    <div className={classes.pendingTxtDiv} >
                        <Typography variant="h3" color="Primary" className={classes.pendingTxt} ><FormattedMessage {...messages.payment_qued} /></Typography>
                        <Typography variant="body1" color="grey200" className={classes.pendingTxt} ><FormattedMessage {...messages.pending_subText_one} /></Typography>



                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className={classes.polygon} >
                        <PriorityHighIcon className={classes.failIcon} />
                    </div>
                    <div className={classes.pendingTxtDiv} >
                        <Typography variant="h3" color="Primary" className={classes.pendingTxt} ><FormattedMessage {...messages.oops} /></Typography>
                        <Typography variant="body1" color="grey200" className={classes.pendingTxt} ><FormattedMessage {...messages.failSubTxt_one} /></Typography>


                    </div>
                </>
            )
        }
    }
    return (
        <>
            <div className={classes.rootDiv} >
                {content()}
            </div>

        </>
    );
}

PaymentStatus.defaultProps = {

}
PaymentStatus.propTypes = {


};


export default memo(PaymentStatus);
