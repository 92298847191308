import {
  RESET_TAGS,
  GET_TAGS, GET_TAGS_SET_DATA,
  ADD_TAGS, ADD_TAGS_SET_DATA,
  REMOVE_TAG, REMOVE_TAG_SET_DATA,
} from './tagConstants';

import { addUpdateTagParser, tagListParser } from './tagParser';

export function resetTagsAction() {
  return { type: RESET_TAGS };
}

export function getTagsAction(data) {
  return { type: GET_TAGS, data };
}
export function getTagsSetDataAction(data) {
  return { type: GET_TAGS_SET_DATA, data: tagListParser(data) };
}

export function addTagsAction(data) {
  return { type: ADD_TAGS, data: addUpdateTagParser(data) };
}
export function addTagsSetDataAction(data) {
  return { type: ADD_TAGS_SET_DATA, data };
}

export function removeTagAction(data) {
  return { type: REMOVE_TAG, data };
}
export function removeTagSetDataAction(data) {
  return { type: REMOVE_TAG_SET_DATA, data };
}
