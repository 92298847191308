const ACCOUNT_TRANSFER = {
    code: "ACCOUNT_TRANSFER",
    name: "Account Tranfer",
    path: "/",
    functions: {
        PRE_ACCOUNT_TRANSFER: {
            code: "PRE_ACCOUNT_TRANSFER",
            name: "Pre Account Transfer Details",
            isPage: false,
            component: "AccountListPage",
            path: "",
            getRoutePath: () => `${ACCOUNT_TRANSFER.path}${ACCOUNT_TRANSFER.functions.PRE_ACCOUNT_TRANSFER.path}`,
            getFullPath: () => `${ACCOUNT_TRANSFER.path}${ACCOUNT_TRANSFER.functions.PRE_ACCOUNT_TRANSFER.path}`,
        },
        CREATE_ACCOUNT_TRANSFER: {
            code: "CREATE_ACCOUNT_TRANSFER",
            name: "Create Account Transfer",
            isPage: false,
            component: "AccountListPage",
            path: "",
            getRoutePath: () => `${ACCOUNT_TRANSFER.path}${ACCOUNT_TRANSFER.functions.CREATE_ACCOUNT_TRANSFER.path}`,
            getFullPath: () => `${ACCOUNT_TRANSFER.path}${ACCOUNT_TRANSFER.functions.CREATE_ACCOUNT_TRANSFER.path}`,
        },

    },
};

export default ACCOUNT_TRANSFER;
