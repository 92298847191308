
import { defineMessages } from 'react-intl';

export const scope = 'src.components.StyledFilter';

export default defineMessages({
    status: {
        id: `${scope}.status`,
        defaultMessage: 'STATUS',
    },
    tag: {
        id: `${scope}.tag`,
        defaultMessage: 'TAG',
    },
    type: {
        id: `${scope}.type`,
        defaultMessage: 'TYPE',
    },
    cards: {
        id: `${scope}.cards`,
        defaultMessage: 'CARDS',
    }

});
