/* eslint-disable no-mixed-operators */
export function parseResponse(result = {}) {
    const defaultErrorMessage = 'Something went wrong';
    const defaultSuccessMessage = '';
    let response = {};
    if (typeof result !== 'object') {
        response = {
            error: { message: JSON.stringify(result)},
            data: [],
            message: '',
        };
    } else if (!result) {
        response = {
            error: { message: defaultErrorMessage},
            data: [],
            message: '',
        };
    } else if (result instanceof Error) {
        response = {
            // error: result.error && result.error,
            error: { message: result.toString()},
            data: [],
            message: '',
        };
    } else if (result.error || !result.data && !result.message) {
        response = {
            error: result.error && result.error,
            data: [],
            message: '',
        };
    } else if (!result.error && result.data) {
        response = {
            error: '',
            message: result.message || defaultSuccessMessage,
            ...result,
        };
    } else if (result.message && !result.data) {
        response = {
            error: result.message || defaultErrorMessage,
            data: [],
            message: '',
        };
    } else {
        response = {
            data: [],
            error: null,
            message: null,
        };
    }

    return {
        ...response,
        ok: result.ok,
        status: result.status
    };

}