/* eslint-disable no-script-url */
import React, { useState } from 'react';

import { AuthState } from '@aws-amplify/ui-components';
import { Grid, Link, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';
import TextField from 'components/ui/TextField';


import { Auth } from 'aws-amplify';
import Button from 'components/ui/Button';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { isMfaDeviceExpired, removeDeviceKey } from 'utils/auth';
import { markAttributeVerified } from 'store/User/userApi';

const useStyles = makeStyles(theme => ({
  headings: {
    textAlign: "left",
    paddingBottom: theme.spacing(3),
  },
  subHeading: {
    margin: theme.spacing(1, 0),
  },
  resendCodeTxt: {
    padding: theme.spacing(1, 0),
    display: "block",
  },
  resendCodeLink: {
    textDecoration: "underline",
  },
  submitButton: {
    margin: theme.spacing(-2, 0, 2, 0),
  },
}));

function ConfirmSignUp(props = {}) {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { setUser, setAuthState, setLoading, user = {}, password } = props;
  const email = (user.attributes && user.attributes.email) || user.username;
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isNewPasswordSetSuccess, setNewPasswordSetSuccess] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    confirmSignUp(email || formData.email, formData.code);
  };

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  async function singIn(data) {
    setLoading(true);
    try {
      let user = await Auth.signIn(email, password);
      if (!['SOFTWARE_TOKEN_MFA', 'SMS_MFA'].includes(user.challengeName) && isMfaDeviceExpired()) {
        await removeDeviceKey();
        user = await Auth.signIn(email, password);
      }
      setUser({ ...user, preferredMFA: null });
    } catch (error) {
      console.error('error: ', error);
      if (error.code === "UserNotConfirmedException") {
        resendConfirmationCode(data.email);
        setAuthState(AuthState.ConfirmSignUp);
        setUser({ username: data.email });
        return;
      } else if (error.code === "PasswordResetRequiredException") {
        setAuthState(AuthState.ForgotPassword);
        return;
      }
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }


  async function confirmSignUp(username, code) {
    setLoading(true);
    try {
      await Auth.confirmSignUp(username, code);
      await markAttributeVerified("email");
      // setNewPasswordSetSuccess(true);
      // setUser({});;
      singIn();
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function resendConfirmationCode(e) {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      await Auth.resendSignUp(email);
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const gotoLogin = () => {
    setAuthState(AuthState.SignIn);
  };

  if (isNewPasswordSetSuccess) {
    return (<Grid
      container
      spacing={5}
      item
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.headings}>
          <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Your sign up is verified!</Typography>
          <Typography color="grey300" variant="body1" className={classes.subHeading}>
            Login to continue.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.submitButton}
          color="secondary"
          title="Log In"
          onClick={gotoLogin}
        />
      </Grid>
    </Grid>);
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Verify Your Sign Up</Typography>
            <Typography color="grey300" variant="body1" className={classes.subHeading}>
              Enter the code sent to your email address to verify your sign up.
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  value={email || formData.email}
                  placeholder="Email"
                  required
                  errorText="Required"
                  disabled={email}
                  onChange={onChange}
                />
              </Grid> */}
        <Grid item xs={12}>
          <TextField
            type="text"
            id="code"
            name="code"
            value={formData.code}
            label="Code"
            required
            errorText="Required"
            onChange={onChange}
          />
          <Typography className={classes.resendCodeTxt} variant="caption" color="grey700">
            <Link className={classes.resendCodeLink} href="#" onClick={resendConfirmationCode}>Resend Code</Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            type="submit"
            title="Confirm"
          />
          <Button
            variant="outlined"
            title="Back"
            onClick={gotoLogin}
          />
        </Grid>
      </Grid>
    </form>
  );
}


export default ConfirmSignUp;

