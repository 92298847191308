import { getCardPaymentDetailParser, getCardPaymentListParser } from 'store/Account/accountParser';
import {
  RESET_PAYMENTS,
  SEND_PAYMENT, SEND_PAYMENT_SET_DATA,
  PAYMENT_LIST, SEND_PAYMENT_LIST_SET_DATA,
  PRE_SEND_PAYMENT, PRE_SEND_PAYMENT_SET_DATA,
  GET_PAYMENT_CURRENCIES, GET_PAYMENT_CURRENCIES_SET_DATA, CANCEL_PAYMENT, CANCEL_PAYMENT_SET_DATA,
  GET_SCHEDULED_PAYMENT_LIST, GET_SCHEDULED_PAYMENT_LIST_SET_DATA, DELETE_SCHEDULED_PAYMENT, DELETE_SCHEDULED_PAYMENT_SET_DATA,
  SEND_SCHEDULE_PAYMENT_NOW, SEND_SCHEDULE_PAYMENT_NOW_SET_DATA, UPDATE_RECURRING_PAYMENT, UPDATE_RECURRING_PAYMENT_SET_DATA,
  DELETE_DRAFT_PAYMENT, DELETE_DRAFT_PAYMENT_SET_DATA, GET_CARD_PAYMENT_LIST, GET_CARD_PAYMENT_LIST_SET_DATA,
  GET_CARD_PAYMENT_DETAIL, GET_CARD_PAYMENT_DETAIL_SET_DATA
} from './paymentConstants';
import { getSchedulePaymentListParser, sendPaymentListParser } from './paymentParser';

export function resetPaymentsAction() {
  return { type: RESET_PAYMENTS };
}

export function sendPaymentAction(data) {

  return { type: SEND_PAYMENT, data };
}
export function sendPaymentSetDataAction(data) {
  return { type: SEND_PAYMENT_SET_DATA, data };
}

export function sendPaymentListAction(data) {
  return { type: PAYMENT_LIST, data };
}
export function sendPaymentListSetDataAction(data, page) {
  return { type: SEND_PAYMENT_LIST_SET_DATA, data: sendPaymentListParser(data), page };
}

export function preSendPaymentAction(data) {
  return { type: PRE_SEND_PAYMENT, data };
}
export function preSendPaymentSetDataAction(data) {
  return { type: PRE_SEND_PAYMENT_SET_DATA, data };
}

export function getPaymentCurrenciesAction(data) {
  return { type: GET_PAYMENT_CURRENCIES, data };
}
export function getPaymentCurrenciesSetDataAction(data) {
  return { type: GET_PAYMENT_CURRENCIES_SET_DATA, data };
}

export function cancelPaymentAction(data) {
  return { type: CANCEL_PAYMENT, data };
}
export function cancelPaymentSetDataAction(data) {
  return { type: CANCEL_PAYMENT_SET_DATA, data };
}

export function getScheduledPaymentListAction(data) {
  return { type: GET_SCHEDULED_PAYMENT_LIST, data };
}
export function getScheduledPaymentListSetDataAction(data, page) {
  return { type: GET_SCHEDULED_PAYMENT_LIST_SET_DATA, data: getSchedulePaymentListParser(data), page };
}

export function deleteScheduledPaymentAction(data) {
  return { type: DELETE_SCHEDULED_PAYMENT, data };
}
export function deleteScheduledPaymentSetDataAction(data) {
  return { type: DELETE_SCHEDULED_PAYMENT_SET_DATA, data };
}

export function sendSchedulePaymentNowAction(data) {
  return { type: SEND_SCHEDULE_PAYMENT_NOW, data };
}
export function sendSchedulePaymentNowSetDataAction(data) {
  return { type: SEND_SCHEDULE_PAYMENT_NOW_SET_DATA, data };
}

export function updateRecurringPaymentAction(data) {
  return { type: UPDATE_RECURRING_PAYMENT, data };
}
export function updateRecurringPaymentSetDataAction(data) {
  return { type: UPDATE_RECURRING_PAYMENT_SET_DATA, data };
}

export function deleteDraftPaymentAction(data) {
  return { type: DELETE_DRAFT_PAYMENT, data };
}
export function deleteDraftPaymentSetDataAction(data) {
  return { type: DELETE_DRAFT_PAYMENT_SET_DATA, data };
}

export function getCardPaymentListAction(data) {
  return { type: GET_CARD_PAYMENT_LIST, data };
}
export function getCardPaymentListSetDataAction(data, page) {
  return { type: GET_CARD_PAYMENT_LIST_SET_DATA, data: getCardPaymentListParser(data), page };
}

export function getCardPaymentDetailAction(data) {
  return { type: GET_CARD_PAYMENT_DETAIL, data };
}
export function getCardPaymentDetailSetDataAction(data) {
  return { type: GET_CARD_PAYMENT_DETAIL_SET_DATA, data: getCardPaymentDetailParser(data) };
}
