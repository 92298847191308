
import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import {
//   LinearProgress,
// } from '@material-ui/core';
import { Typography } from 'components/ui';
import { EllipsisLoader } from 'components';
import combineClx from 'utils/combineClx';

const useStyles = makeStyles((theme) => ({
  inputRoot: (props) => ({
    // height: 44,
    // padding: '8px 11px 6px 6px !important',
    borderRadius: 6,
    fontWeight: 600,
    // marginBottom: 10,
    // backgroundColor: theme.palette.background.default,
    color: props.txtColor ? props.txtColor : "",
    paddingTop: `7px !important`,
    paddingBottom: `${theme.spacing(0.5)}px !important`,
    paddingRight: "30px !important",
    ...props.customStyle,
    // border: props.error ? `1px solid ${theme.palette.error.main}` : undefined,
    boxShadow: props.error
      ? `inset 0px 0px 0px 1px ${theme.palette.error.main}`
      : "none",
  }),
  hideLabelInputRoot: (props) => ({
    // height: 44,
    // padding: '8px 11px 6px 6px !important',
    borderRadius: 6,
    fontWeight: 600,
    // marginBottom: 10,
    // backgroundColor: theme.palette.background.default,
    color: props.txtColor ? props.txtColor : "",
    paddingRight: "30px !important",
    paddingBottom: "0px !important",
    ...props.customStyle,
    // border: props.error ? `1px solid ${theme.palette.error.main}` : undefined,
    boxShadow: props.error
      ? `inset 0px 0px 0px 1px ${theme.palette.error.main}`
      : "none",
  }),
  input: {
    // padding: '6.5px 4px !important',
  },
  textField: (props) => ({
    verticalAlign: props.hiddenLabel ? "middle !important" : "none",
    "& .MuiFilledInput-root": {
      backgroundColor: props.bgcolor ? props.bgcolor : theme.palette.grey50,
      // paddingTop: 8,
    },
    '& .MuiFormLabel-root': {
      ...theme.typography.subtitle1
    },
    inputPlaceHolder: {
      '&::placeholder': {
        ...theme.typography.subtitle1,
      }
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "inherit",
      textAlign: "left",
    },
  }),
  paper: (props) => ({
    // width: props.phoneInput ? 'max-content !important' : 'none',
    width: props.optionListWidth ? `${props.optionListWidth}px` : "none",
  }),
  circularProgressRoot: {
    position: "absolute",
    right: theme.spacing(1.375),
    top: theme.spacing(2),
  },
  linearProgress: {
    width: theme.spacing(4),
    position: "absolute",
    right: theme.spacing(2),
    height: "3px",
    top: "47%",
  },
  ellipsisProgress: {
    width: theme.spacing(4),
    position: "absolute",
    right: theme.spacing(2),
    height: "3px",
    top: "36%",
  },
  helperTextError: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    textAlign: "left",
  },
  helperText: {
    color: theme.palette.grey400,
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
    marginTop: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    textAlign: "left",
  },
  mainDiv: (props) => ({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    borderRadius: theme.spacing(0.75),
    backgroundColor: props.bgcolor ? props.bgcolor : theme.palette.grey50,
    height: '100%'
  }),
  txtDiv: {
    display: "flex",
    padding: theme.spacing(1),
  },
}));

function Autocomplete(props) {
  const { options = { data: [] }, selectKey, labelKey, getOptions,
    // showLoading,
    hiddenLabel, className } = props;
  const theme = useTheme();
  const inputRef = useRef(null);
  const textFieldRef = props.inputRef || inputRef;

  useEffect(() => {

    (!Array.isArray(options.data) || !options.data.length) && typeof getOptions === "function" && getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (textFieldRef && textFieldRef.current && props.makeFocus) {
      textFieldRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.makeFocus]);

  useEffect(() => {

    Array.isArray(options.data) && setSelectedValue(options.data.find(i => i[selectKey] === props.value) || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.data, props.value]);

  const [selectedValue, setSelectedValue] = useState({});
  const [error, setError] = useState(props.error ? true : false);
  const classes = useStyles({ ...props, error, });

  const handleChange = (e, value) => {
    setError(false);
    setSelectedValue(value || {});
    props.onChange(e, value);
    if (props && props.onFocusRef) {
      props.onFocusRef("clear");
    }
  };
  useEffect(() => {
    setError(props.error ? true : false);
  }, [props.error]);
  useEffect(() => {
    setError(false);
  }, [props.required]);
  const onInvalid = (e) => {
    e.preventDefault && e.preventDefault();
    setError(false);
    setTimeout(() => {
      setError(true);
      if (props && props.onFocusRef) {
        props.onFocusRef(props.name, props.id);
      }
    }, 300);
  };

  return (
    <>
      <div className={combineClx(classes.mainDiv, className)} >
        {
          props.startText ? <div className={classes.txtDiv}> {props.startText}</div> : <></>
        }
        <MuiAutocomplete
          // error={error}
          // helperText={error ? props.errorText : props.helperText}
          ref={props.inputRef}
          PopperComponent={props.PopperComponent}
          disableClearable={props.disableClearable}
          popupIcon={props.popupIcon || <ExpandMoreIcon style={{ color: props.iconColor ? props.iconColor : `${theme.palette.primary}` }} />}
          id={props.id}
          variant={props.variant ? props.variant : "filled"}
          options={Array.isArray(options.data) ? options.data : []}
          classes={{
            inputRoot: !hiddenLabel ? classes.hideLabelInputRoot : classes.inputRoot,
            input: classes.input,
            popper: classes.popper,
            paper: classes.paper,

          }}
          loadingText={props.loadingText}
          loading={options.loading}
          onOpen={() => {
            !options?.data?.length && getOptions();
            // getOptions();
          }}
          value={selectedValue[selectKey] ? selectedValue : (props.freeSolo ? props.value : null)}
          autoHighlight
          getOptionDisabled={(props.getOptionDisabled || (option => !option[selectKey]))}
          // getOptionSelected={(option, value) => option[selectKey] === value[selectKey]}
          getOptionLabel={(option) => props.getOptionLabel ? props.getOptionLabel(option) : (option[labelKey] || '')}
          renderOption={(option) => props.renderOption ? props.renderOption(option) : option[labelKey]}
          onChange={handleChange}
          onInputChange={props.onInputChange}
          inputValue={props.inputValue}
          autoSelect={props.autoSelect}
          onClose={props.onClose}
          closeIcon={null}
          fullWidth
          className={props.className}
          disabled={props.disabled}
          freeSolo={props.freeSolo}
          autoComplete={props.autoComplete}
          disableCloseOnSelect={props.disableCloseOnSelect}
          onHighlightChange={props.onHighlightChange}
          // openOnFocus
          open={props.open}
          forcePopupIcon={props.forcePopupIcon}
          noOptionsText={props.noOptionsText}
          filterOptions={props.filterOptions}
          includeInputInList={props.includeInputInList}
          filterSelectedOptions={props.filterSelectedOptions}
          renderInput={props.renderInput ? props.renderInput : (params) => (
            <TextField
              {...params}
              id={props.id}
              inputRef={textFieldRef}
              name={props.name}
              label={props.label}
              placeholder={props.placeholder}
              multiline
              error={error}
              className={classes.textField}
              // helperText={error ? props.errorText : props.helperText}
              variant={props.variant ? props.variant : "filled"}
              size="small"
              onBlur={props.onBlur}
              InputProps={{
                ...params.InputProps,
                required: props.required,
                classes: {
                  input: classes.inputPlaceHolder
                },
                onInvalid,
                disableUnderline: true,
                // shrink: false,

                startAdornment: (props.value || props.startAdornmentShowAlways) ? (
                  <React.Fragment>
                    {props.startAdornment}
                    {params.InputProps.startAdornment}
                  </React.Fragment>
                ) : null,
                endAdornment: (
                  <React.Fragment>
                    {options.loading ? <div className={classes.ellipsisProgress}><EllipsisLoader /></div> : params.InputProps.endAdornment}
                    {/* {options.loading ? <LinearProgress className={classes.linearProgress} /> : params.InputProps.endAdornment} */}
                    {/* {options.loading && showLoading ? <CircularProgress classes={{ root: classes.circularProgressRoot }} color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment} */}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        {/* </FormControl> */}
      </div>
      {
        props.selectKey !== "entity_id" && (props.errorText || props.helperText) ? <div><Typography className={error ? classes.helperTextError : classes.helperText}> {error ? props.errorText : props.helperText}</Typography></div> : <></>
      }

    </>
  );
}

Autocomplete.defaultProps = {
  selectKey: 'id',
  labelKey: 'name',
  errorText: '',
  helperText: '',
  getOptions: () => null,
}

Autocomplete.propTypes = {
  bgcolor: PropTypes.any,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  error: PropTypes.any,
  startText: PropTypes.any,
  customStyle: PropTypes.any,
  variant: PropTypes.any,
  iconColor: PropTypes.any,
  onFocusRef: PropTypes.any,
  makeFocus: PropTypes.any,
  className: PropTypes.any
};

export default memo(Autocomplete
  , (prevProps, nextProps) => ((
    JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options) &&
    prevProps.startAdornment === nextProps.startAdornment &&
    prevProps.hiddenLabel === nextProps.hiddenLabel &&
    prevProps.value === nextProps.value &&
    prevProps.inputValue === nextProps.inputValue &&
    prevProps.optionListWidth === nextProps.optionListWidth &&
    prevProps.error === nextProps.error &&
    prevProps.required === nextProps.required &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.makeFocus === nextProps.makeFocus &&
    prevProps.freeSolo === nextProps.freeSolo &&
    prevProps.forcePopupIcon === nextProps.forcePopupIcon &&
    prevProps.open === nextProps.open &&
    prevProps.onClose === nextProps.onClose &&
    prevProps.disableCloseOnSelect === nextProps.disableCloseOnSelect &&
    prevProps.onHighlightChange === nextProps.onHighlightChange &&
    prevProps.className === nextProps.className &&
    prevProps.label === nextProps.label
  ))
);

