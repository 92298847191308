import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
    link: {
      padding: "1px",
      fontSize: "14px",
      textDecoration: "underline",
      color: "#0075E1",
    },
}));

function ResendOtp(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { onResend, countDownInSeconds = 60, label='Resend', timerText = 'Resend in' } = props;
  const [timer, setTimer] = useState(countDownInSeconds);    
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const onResendHandler = () => {
    onResend(resetTimer);
  }

  const resetTimer = function () {
      setTimer(countDownInSeconds);
  };

  return (<>
   { timer <= 0 ?
    <Button className={classes.link} onClick={onResendHandler}>{label}</Button> : 
    <span>{timerText} {timer}</span>
   }
  </>);
}

ResendOtp.propTypes = {
  test: PropTypes.string,
};

export default memo(ResendOtp);
