
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getCurrencyListAction } from 'store/Reference/referenceActions';
import { getCurrencyListState } from 'store/Reference/referenceSelectors';


function SelectCurrency(props = {}) {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState({});
    const { currencies, getCurrencies, propsCurrencies, propsGetCurrencies } = props;
    const options = propsCurrencies || currencies;
    const getOptions = propsGetCurrencies || getCurrencies;
    const onChange = (e, val) => {
        setValue(val || {});
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: val && val.currency_code
            }
        }, val);
    }

    return (<>
        <Autocomplete
            {...props}
            getOptions={getOptions}
            options={options}
            onChange={onChange}
            onBlur={props.onBlur}
            selectKey="currency_code"
            labelKey="currency_code"
            makeFocus={props.makeFocus}
            renderOption={(option) => (
                <React.Fragment>
                    {option.currency_code}
                </React.Fragment>
            )}
        />
    </>)
};

const mapStateToProps = createStructuredSelector({
    currencies: getCurrencyListState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getCurrencies: data => dispatch(getCurrencyListAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);


export default compose(
    withConnect,
    memo,
)(SelectCurrency,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.currencies) === JSON.stringify(nextProps.currencies) &&
            JSON.stringify(prevProps.propsCurrencies || {}) === JSON.stringify(nextProps.propsCurrencies || {}) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);
