/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Typography, Button } from 'components/ui';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Grid, useTheme } from '@material-ui/core/';
import { FormattedMessage } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCustomerDetailsState, markIdVerificationStepState, resendInvitationLinkState } from 'store/Register/registerSelectors';
import { Loader } from 'components';
import { getAvatarTxt } from 'utils/common';
import { ENTITY_PERSON_TYPE } from '@truly-financial/common/consts/entity-person.consts';
import { styledSnackbar } from 'containers/Common/Snackbar';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Skeleton } from '@material-ui/lab';
import GoBack from '../GoBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IdVerificationIframeDialog from 'containers/RegistrationComplete/IdVerificationIframeDialog';
import { getCustomerDetailsAction, markIdVerificationStepAction, resendInvitationLinkAction } from 'store/Register/registerActions';
import { getRemainingStep } from 'store/Register/registerParser';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import { getTempAccessToken } from 'utils/auth';


const idVerificationStatusMap = {
    PASS_PROCESSED: 'Verified',
    FAIL_PROCESSED: 'Verification Failed',
    PENDING_NEW: 'Pending',
    PENDING_PROCESSED: 'ID Verification submitted',
    PENDING_RETRY: 'Needing Reverification',
};

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#fff',
        display: 'flex',
        flex: 1,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',

    },
    subContent: {
        // display: 'grid',
        position: 'relative',

    },
    textCenter: {
        textAlign: 'center',
        marginBottom: theme.spacing(4)
    },
    card: {
        background: '#F2F3F4',
        borderRadius: '8px',
        display: 'flex',
        minHeight: '38vh',
        flex: 1,
        flexDirection: 'column',
        // overflow: 'auto',
        position: 'relative',
        padding: theme.spacing(0, 7.5, 3, 7.5),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            padding: theme.spacing(0, 3, 0, 3),
        },

    },
    inputDiv: {
        margin: theme.spacing(2, 0)
    },
    input1: {
        background: '#fff',
        borderRadius: theme.spacing(0.9)
    },
    mainTitle: {

    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),

    },
    bottomStick: {
        position: 'inherit',
        bottom: 0,
    },
    position: {
        position: 'relative'
    },
    tncDiv: {

        overflow: 'auto',

    },
    padding: {
        marginBottom: theme.spacing(3),
        justifyContent: 'flex-start'
    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },

    lastMainGrid: {
        marginBottom: theme.spacing(6.2),
        marginTop: theme.spacing(5),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexWrap: "wrap",
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
        }
    },
    listDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing(2, 0, 0, 0),
        flexWrap: 'wrap',
    },
    rightIcon: {
        fontSize: theme.spacing(3),
        color: theme.palette.successGreen,
        width: 24,
        height: 24,
    },
    row: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(0, 0, 0, 1),
    },
    iconButton: {
        fontSize: theme.spacing(2),
        padding: "0px !important",
    },
    dotIcon: {
        marginRight: theme.spacing(1.5),
        color: theme.palette.grey500,
        fontSize: theme.spacing(2),
    },
    copyIcon: {
        fontSize: theme.spacing(2),
        padding: "0px !important",
        color: theme.palette.grey500,
    },
    nameDiv: {
        margin: theme.spacing(0, 0, 0, 2),
    },
    btnDiv: {
        display: "flex",
        height: theme.spacing(3.8),
        // margin: theme.spacing(.5),
        // flex: 1
        // margin: theme.spacing(4, 'auto', 0, 'auto')
    },
    btnDivLast: {
        flex: 1
    },
    avatar: {
        width: 24,
        height: 24,
    },
    btn: {
        margin: theme.spacing(3, 3, 0, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3, 0),
        }

    },
    centerImg: {
        width: theme.spacing(29.875),
        height: theme.spacing(19.625),
        margin: theme.spacing(0, 0, 3, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3, 0),
        }
    },
    descriptionTxtMrg: {
        marginTop: theme.spacing(2),
    },
    roundTxt: {
        color: theme.palette.primary.main,
    },
    col: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    pendingInfo: {
        marginTop: theme.spacing(17.75),
    },
    rootGridHold: {
        flex: 1,
    },
    listMain: {
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.grey100}`,
        padding: theme.spacing(2, 2, 2, 2),
        maxWidth: theme.spacing(61.25),
        textAlign: "left",
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '16px'
    },
    lastTxt: {
        margin: theme.spacing(2, 0, 0, 0),
        textAlign: 'center'

    },
    align: {
        display: "flex",
        alignItems: "center",
    },
    skeleton: {
        height: theme.spacing(30),
        width: 'auto',
        borderRadius: theme.spacing(1),
    },
    skeletonDiv: {
        height: theme.spacing(30),
    }

}));
function IdVerification(props = {}) {
    const { getCustomerDetailsState, markIdVerificationStepState, markIdVerificationStep, resendInvitationLinkAction, resendInvitationLinkState
        , onContinue = () => null, onBackPress, getCustomerDetails } = props;

    const classes = useStyles();
    const isLoading = ((getCustomerDetailsState && getCustomerDetailsState.loading) || (resendInvitationLinkState && resendInvitationLinkState.loading) || (markIdVerificationStepState && markIdVerificationStepState.loading));
    const customerDetailData = getCustomerDetailsState.data || {};
    const IdVerificationIframeDialogRef = useRef(null);
    const [isIdDialogOpen, setIsIdDialogOpen] = useState(false);
    const theme = useTheme();


    useEffect(() => {

        if ((!getCustomerDetailsState.loading && customerDetailData?.prs_user && !customerDetailData.isAllowIdVerificationStepRender)
        ) {
            let remainingStep = getRemainingStep(customerDetailData.completed_steps);
            onContinue(remainingStep);
        }
    }, [customerDetailData]);

    useEffect(() => {
        if (!getCustomerDetailsState.loading && !getCustomerDetailsState?.data?.isIdVerificationContinueBtnDisable) {
            setTimeout(() => {
                if (!isIdDialogOpen && !getTempAccessToken()) {
                    getCustomerDetails();
                }
            }, 15000);
        }

    }, [getCustomerDetailsState])

    const avatarColor = [
        theme.palette.tertiary_one[200], theme.palette.tertiary_two[200],
        theme.palette.tertiary_three[200], theme.palette.tertiary_four[200],
    ];

    const onResendLink = (item) => {

        if (item && item.entity_person_id) {
            resendInvitationLinkAction({ id: item.entity_person_id });
        }
    };

    const copyUrlLink = (item) => {

        if (item.entity_person_invitation_url) {
            navigator.clipboard.writeText(item.entity_person_invitation_url);
            styledSnackbar.success("Copied");

        }
    };
    const onRedirect = (item) => {
        if (IdVerificationIframeDialogRef && IdVerificationIframeDialogRef.current && IdVerificationIframeDialogRef.current.onToggle) {
            setIsIdDialogOpen(true);
            IdVerificationIframeDialogRef.current.onToggle();

        }
    };
    const onCloseDialog = () => {
        setIsIdDialogOpen(false);
        getCustomerDetails();
    };

    const onSuccessStepMark = () => {
        onContinue();
    };

    const markIdVerificationStepApi = () => {
        markIdVerificationStep({ cb: onSuccessStepMark });
    };
    const onBtnPress = () => {
        if (customerDetailData && customerDetailData.completed_steps && !customerDetailData.completed_steps.includes(REGISTRATION_STEP.ID_VERIFICATION)
        ) {
            markIdVerificationStepApi();
        } else {
            onContinue();
        }
    };

    return (<>
        <div className={classes.root} >
            <Grid container justifyContent="center" className={classes.content}>
                {
                    isLoading && <Loader />
                }
                <Grid item xs={11} md={8} className={classes.subContent}>
                    <div className={classes.textCenter}>
                        <Typography className={classes.mainTitle} variant={"h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep16Title} /></Typography>
                        <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep16Subtitle} /></Typography>
                    </div>

                    {
                        getCustomerDetailsState.loading && (!customerDetailData || !customerDetailData.entity_persons || customerDetailData.entity_persons.length === 0) ? <div className={classes.skeletonDiv}>
                            <Skeleton variant='rect' className={classes.skeleton} />
                        </div> : <div className={classes.listMain} >
                            <Typography color="grey500" variant="overline" className={classes.pendingTxt} ><FormattedMessage {...intl.onBoardingStep16pendingDetails} /></Typography>
                            {
                                customerDetailData && customerDetailData.entity_persons && customerDetailData.entity_persons.length > 0 && customerDetailData.entity_persons.map((item, index) => {
                                    if (!item.invitation_allowed) {
                                        return null;
                                    }
                                    return (
                                        <div key={index} className={classes.listDiv}>
                                            <div className={classes.row} >
                                                {
                                                    (item.entity_person_id_verification_status === 'PASS_PROCESSED') ?
                                                        <CheckCircleOutlineIcon className={classes.rightIcon} />
                                                        :
                                                        <Avatar
                                                            className={classes.avatar}
                                                            classes={{ colorDefault: classes.avatarColor }}
                                                            style={{
                                                                // height: theme.spacing(3.75),
                                                                // width: theme.spacing(3.75),
                                                                color: theme.palette.primary.main,
                                                                backgroundColor: avatarColor[(index + 1) % 4]
                                                            }}
                                                            alt="dp"
                                                        >
                                                            <Typography className={classes.roundTxt} variant="caption" >{getAvatarTxt(item, "entity")}</Typography>
                                                        </Avatar>
                                                }
                                                <div className={classes.nameDiv} >
                                                    <Typography color="grey700" variant="body1" >{item.entity_person_first_name} {item.entity_person_last_name}</Typography>
                                                    {/* <Typography color={!item.entity_person_id_verification_status === 'PASS_PROCESSED' ? "grey300" : "tertiary_one700"} variant="caption" >{(item.entity_person_status === ENTITY_PERSON_STATUS.INVITATION_SENT || item.entity_person_status === ENTITY_PERSON_STATUS.DISABLED) ? <FormattedMessage {...intl.verificationPending} /> : <FormattedMessage {...intl.verified} />}</Typography> */}
                                                    <Typography color={item.entity_person_id_verification_status !== 'PASS_PROCESSED' ? (item.entity_person_id_verification_status === "FAIL_PROCESSED" || item.entity_person_id_verification_status === "PENDING_RETRY") ? "error" : "grey300" : "tertiary_one700"} variant="caption" >{idVerificationStatusMap[item.entity_person_id_verification_status] || idVerificationStatusMap.PENDING_NEW}</Typography>
                                                </div>
                                            </div>
                                            {!['PASS_PROCESSED', "PENDING_PROCESSED", "FAIL_PROCESSED"].includes(item.entity_person_id_verification_status) &&
                                                item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ?
                                                <div  >
                                                    <Button
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => { onRedirect(item) }}
                                                        className={classes.btnDiv}
                                                    >

                                                        Verify Your ID Now
                                                    </Button>
                                                    <IdVerificationIframeDialog
                                                        ref={IdVerificationIframeDialogRef}
                                                        url={item.entity_person_invitation_url + "&isDialog=1"}
                                                        onClose={onCloseDialog}
                                                        data={item}
                                                        id={item.entity_person_id}
                                                        token={item.continue_signup_token}
                                                        access_token={item.access_token}
                                                    />
                                                </div>
                                                :

                                                !['PASS_PROCESSED', 'PENDING_PROCESSED', "FAIL_PROCESSED"].includes(item.entity_person_id_verification_status) &&
                                                <div className={classes.row} >
                                                    <Tooltip title="Resend Link">
                                                        <IconButton onClick={(e) => { onResendLink(item) }} className={classes.iconButton}>
                                                            <EmailOutlinedIcon className={classes.dotIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Copy">
                                                        <IconButton onClick={(e) => { copyUrlLink(item) }} className={classes.iconButton}>
                                                            <FileCopyOutlinedIcon className={classes.copyIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }

                        </div>
                    }
                    <Typography className={classes.lastTxt} variant="body2" color="grey500"><FormattedMessage {...intl.onBoardingStep16LastTxt} /></Typography>

                    <Grid container justifyContent="center" className={classes.lastMainGrid}>

                        <GoBack onClick={onBackPress} isLoading={isLoading} />
                        <Grid item md={3} className={classes.btnDivLast} >
                            <Button
                                className={classes.btnDivLast}
                                disabled={!customerDetailData.isIdVerificationContinueBtnDisable}
                                size="small" onClick={onBtnPress}>
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep5ContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    </>);
}

const mapStateToProps = createStructuredSelector({
    getCustomerDetailsState: getCustomerDetailsState(),
    markIdVerificationStepState: markIdVerificationStepState(),
    resendInvitationLinkState: resendInvitationLinkState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
        markIdVerificationStep: (data) => dispatch(markIdVerificationStepAction(data)),
        resendInvitationLinkAction: (data) => dispatch(resendInvitationLinkAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(IdVerification);
