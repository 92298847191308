import Authentication from "containers/Authentication";
import Logout from "containers/Authentication/Logout";
import NoEntityFound from "containers/Authentication/NoEntityFound";
import BuildInfoPage from "pages/BuildInfo/BuildInfoPage";
import IdVerificationStatusPage from "pages/IdVerification/IdVerificationStatusPage";
import Register from "pages/OnBoarding/OnBoardingPage";
import RegistrationOnHoldPage from "pages/OnBoarding/RegistrationOnHoldPage";
import RegistrationCompletePage from 'pages/RegistrationComplete/RegistrationCompletePage';
import TermsAndConditionsPage from 'pages/Legal/TermsAndConditionsPage';
import OnBoardingSummaryPage from "pages/OnBoarding/OnBoardingSummaryPage";
import loadable from "utils/Loadable";
import VerificationPage from "pages/Verification/VerificationPage";
import TeamMemberSignupPage from "pages/Verification/TeamMemberSignupPage";
import VerificationRedirectPage from "pages/Verification/VerificationRedirectPage";
import ContactRequestBankPage from "pages/ContactRequestBank/ContactRequestBankPage";
const isDevEnv = (process.env && ['development', 'local'].includes(process.env.REACT_APP_ENV) ? true : false);
const TestApiPage = isDevEnv ? loadable(() => import('pages/TestApi/TestApiPage')) : null;

const PublicRoutes = [
      {
            path: '/login',
            component: Authentication
      },
      {
            path: '/signup',
            component: Authentication
      },
      {
            path: '/complete-registration',
            component: Register
      },
      {
            path: '/registration-summary',
            component: OnBoardingSummaryPage
      },
      {
            path: '/logout',
            component: Logout
      },
      {
            path: '/no-entities',
            component: NoEntityFound
      },
      {
            path: '/build-info',
            component: BuildInfoPage
      },
      {
            path: '/verification-status',
            component: IdVerificationStatusPage
      },
      ///old verification routes
      {
            path: '/team-member-verification',
            component: VerificationRedirectPage
      },
      ///new verification routes
      {
            path: '/team-member-signup',
            component: TeamMemberSignupPage
      },
      {
            path: '/verification',
            component: VerificationPage
      },
      {
            path: '/registration-on-hold',
            component: RegistrationOnHoldPage
      },
      {
            path: '/registration-complete',
            component: RegistrationCompletePage
      },
      {
            path: '/legal/terms-and-conditions',
            component: TermsAndConditionsPage,
      },
      {
            path: '/bank-detail-request',
            component: ContactRequestBankPage,
      },

];

if (isDevEnv) {
      PublicRoutes.push({
            path: '/test-api',
            component: TestApiPage
      });
}
export default PublicRoutes;