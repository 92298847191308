import {
  RESET_OTP,
  SEND_OTP, SEND_OTP_SET_DATA,
  RESEND_OTP, RESEND_OTP_SET_DATA,
} from './otpConstants';

export function resetOtpAction() {
  return { type: RESET_OTP };
}

export function sendOtpAction(data) {
  return { type: SEND_OTP, data };
}
export function sendOtpSetDataAction(data) {
  return { type: SEND_OTP_SET_DATA, data };
}

export function resendOtpAction(data) {
  return { type: RESEND_OTP, data };
}
export function resendOtpSetDataAction(data) {
  return { type: RESEND_OTP_SET_DATA, data };
}
