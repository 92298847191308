/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';

import { Grid, Link, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import { ANALYTICS } from '@truly-financial/common/consts'
import { Typography } from 'components/ui';
// import { FormattedMessage } from 'react-intl';
// import messages from './messages';
import TextField from 'components/ui/TextField';
// import PhoneInput from 'containers/Common/PhoneInput';


import Button from 'components/ui/Button';
import { Loader, NewPasswordInput } from 'components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { signupAction } from 'store/Register/registerActions';
import { deleteCookieByName, getUrlParamByName } from 'utils/common';
// import { signupState } from 'store/Register/registerSelectors';
import { signupApi } from 'store/Register/registerApi';
import { useHistory } from 'react-router';
import {
  // setAccessToken, setEntityId, setLastAuthUser,
  setRegistrationLocalData
} from 'utils/auth';

// import { analyticsTrackApi } from 'store/Analytics/analyticsApi';

// import { styledSnackbar } from 'containers/Common/Snackbar';
// import { signupState } from 'store/Register/registerSelectors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sigInLink: {
    color: theme.palette.accent.main,
    cursor: "pointer",
  },
  headings: {
    textAlign: "left",
    // paddingBottom: theme.spacing(3),
  },
  subHeading: {
    margin: theme.spacing(2, 0),
    display: "block",
  },
  tnc: {
    padding: theme.spacing(3, 0),
    textAlign: "center",
  },
  link: {
    color: theme.palette.hyperlink.main,
    textDecoration: "underline",
  },
}));

function SignUp(props = {}) {
  const {
    // setUser, setAuthState, setLoading,
    // signupState,
    // signup,
    setPassword,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const firstName = getUrlParamByName('first_name');
  const lastName = getUrlParamByName('last_name');
  const companyName = getUrlParamByName('company_name');
  const email = getUrlParamByName('email');
  const ref = getUrlParamByName('tf_drilldown');
  const plan = getUrlParamByName('plan');
  const urlParams = new URLSearchParams(window.location.search);
  const marketingData = Object.fromEntries(urlParams);
  const [loading, setLoading] = useState(false);
  delete marketingData.first_name;
  delete marketingData.last_name;
  delete marketingData.email;
  delete marketingData.company_name;
  delete marketingData.tf_drilldown;
  delete marketingData.plan;
  const [formData, setFormData] = useState({
    // business_name: 'remitr',
    // phone_number: "+919664096649",
    // email: 'roshan+4@remitr.com',
    // password: "Remitr@123",
    // mobile_country_code: 'IN',
    // mobile_dialing_code: "+91",
    // mobile: "9664096649"
    first_name: firstName,
    last_name: lastName,
    company_name: companyName,
    email,
    marketing_data: marketingData,
    referral_data: { ref, plan }
  });
  useEffect(() => {
    // analyticsTrackApi({ event: ANALYTICS.EVENTS.CREATE_ACCOUNT_PAGE_LOAD.event })
    // removing hubspot user identifier cookie to handle merge hubspot contacts issue
    setTimeout(() => {
      deleteCookieByName('hubspotutk');
      deleteCookieByName('messagesUtk');
    }, 2000);
  }, [])

  useEffect(() => {
    setPassword(formData.password);
  }, [formData.password, setPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    signupHandler(formData);
    // analyticsTrackApi({ event: ANALYTICS.EVENTS.CREATE_ACCOUNT_CLICK.event })

  };

  const [isPassword8CharLong, setIsPassword8CharLong] = useState(false)
  const buttonDisableAndEnableHandler = (progress) => {
    if (progress === 100) {
      setIsPassword8CharLong(true)
    } else {
      setIsPassword8CharLong(false)
    }
  }

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  // create user direct in cognito
  // eslint-disable-next-line no-unused-vars
  // async function signupHandler(data) {
  //   setLoading(true);
  //   try {
  //     const { user } = await Auth.signUp({
  //       username: data.email,
  //       password: data.password,
  //       attributes: {
  //         email: data.email,// optional
  //         phone_number: data.phone_number || '+1123123',// optional - E.164 number convention
  //         'custom:business_name': data.business_name || 'test',
  //         'custom:mobile_country_code': data.mobile_country_code || 'CA',
  //         'custom:mobile_dialing_code': data.mobile_dialing_code || '+1',
  //         'custom:mobile': data.mobile || '123123',
  //       }
  //     });
  //     setUser(user);
  //     setAuthState(AuthState.ConfirmSignUp);
  //   } catch (error) {
  //     styledSnackbar.error(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const signupHandler = async () => {
    // signup({
    //   body: formData,
    // });
    setLoading(true);
    try {
      const result = await signupApi(formData);
      if (result.ok && result.data.redirect_url) {
        const token = result.data.token || '';
        const uid = result.data.user_account_id || '';
        const eid = result.data.entity_id || '';
        const setLocalData = await setRegistrationLocalData({ token, uid, eid });
        if (setLocalData) {
          const completeRegistrationUrl = `/complete-registration`;
          history.push(completeRegistrationUrl);
        }
      }

    } catch (error) {
      console.error('error: ', error);
    } finally {
      setLoading(false);
    }
  };

  // const setLocalDataAndRedirect = async (result) => {
  //   try {
  //     const token = result.data.token || '';
  //     const uid = result.data.user_account_id || '';
  //     const id = result.data.entity_id || '';
  //     const setuid = await setLastAuthUser(uid);
  //     await setEntityId(id);
  //     if (setuid) {
  //       const setAToken = await setAccessToken(token);
  //       if (setAToken) {
  //         const completeRegistrationUrl = `/complete-registration`;
  //         history.push(completeRegistrationUrl);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('error: ', error);
  //     styledSnackbar.error(error);
  //   }
  // };

  // const gotoLogin = () => {
  //   setAuthState(AuthState.SignIn);
  // };

  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      {/* {signupState.loading && <Loader componentCenter />} */}
      {loading && <Loader />}
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h6" : "h5"} >{getUrlParamByName('email') ? <>Continue Your Application</> : <>Get Started </>}</Typography>
            <Typography color="grey300" variant={isXsScreen ? "subtitle1" : "titleMobile"} className={classes.subHeading} > {getUrlParamByName('email') ? <>Set a password to save and secure your information</> : <>Already have an account?
              <Link
                className={classes.sigInLink}
                href="/login" target="_self"
              // onClick={gotoLogin}
              >  Log In</Link></>}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            id="email"
            name="email"
            value={formData.email}
            label="Email"
            required
            disabled={getUrlParamByName('email') ? true : false}
            errorText="Required"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <NewPasswordInput
            id="password"
            name="password"
            value={formData.password}
            label="Password"
            required
            errorText="Required"
            onChange={onChange}
            buttonChangeHandler={buttonDisableAndEnableHandler}
          />
        </Grid>
        {/* <Grid item xs={12}>
                <PhoneInput
                  mobileCountryId="mobile_country_code"
                  mobileCountryName="mobile_country_code"
                  mobileCountryValue={formData.mobile_country_code}
                  mobileCountryPlaceholder="+00"
                  // mobileCountryLabel="Country"

                  mobileDialingCodeId="mobile_dialing_code"
                  mobileDialingCodeName="mobile_dialing_code"

                  mobileId="mobile"
                  mobileName="mobile"
                  mobilePlaceholder="Phone Number"
                  // mobileLabel="Phone Number"
                  mobileValue={formData.mobile}

                  phoneNumberId="phone_number"
                  phoneNumberName="phone_number"

                  // dialingCodeId="mobile_country_code"
                  // dialingCodeName="mobile_country_code"
                  // dialingCodePlaceholder="Country"

                  // dialingCodeValue={formData.mobile_country_code}

                  // phoneId="mobile"
                  // phoneName="mobile"
                  // phonePlaceholder="Phone Number"
                  // phoneValue={formData.mobile}

                  required
                  errorText="Required"
                  onChange={onChange}
                  hiddenLabel
                />
              </Grid> */}
        {/* <Grid item xs={12}>
                <TextField
                  type="text"
                  id="business_name"
                  name="business_name"
                  value={formData.business_name}
                  placeholder="Business Name"
                  // required
                  errorText="Required"
                  onChange={onChange}
                />
              </Grid> */}
        <Grid item xs={12}>
          <Button
            type="submit"
            title="Create Account"
            disabled={!isPassword8CharLong}
          />
          <div className={classes.tnc}>
            <Typography color="grey700" variant="body2">
            By applying for a Truly Financial account you are agreeing to our <Link className={classes.link} href="/legal/terms-and-conditions" target="_blank">Terms and Conditions</Link>, and <Link className={classes.link} href="https://trulyfinancial.com/legal/#privacy" target="_blank">Privacy Policy</Link>.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </form>
  );
}


const mapStateToProps = createStructuredSelector({
  // signupState: signupState(),
});

function mapDispatchToProps(dispatch) {
  return {
    // dispatch actions and from props;
    // signup: data => dispatch(signupAction(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(SignUp);
