import {
  RESET_REGISTER,
  ADD_CUSTOMER_INTRODUCTION, ADD_CUSTOMER_INTRODUCTION_SET_DATA,
  GET_CUSTOMER_DETAILS, GET_CUSTOMER_DETAILS_SET_DATA,
  ADD_CUSTOMER_BUSINESS_BRIEF, ADD_CUSTOMER_BUSINESS_BRIEF_SET_DATA,
  SAVE_BUSINESS_BRIEF, SAVE_BUSINESS_BRIEF_SET_DATA, UPDATE_STEPS, SAVE_BUSINESS_STRUCTURE, SAVE_BUSINESS_STRUCTURE_SET_DATA,
  SAVE_TERM_CONDITION, SAVE_TERM_CONDITION_SET_DATA, SAVE_BUSINESS_CATEGORIES, SAVE_BUSINESS_CATEGORIES_SET_DATA,
  SAVE_BUSINESS_INDUSTRIES, SAVE_BUSINESS_INDUSTRIES_SET_DATA, SAVE_BUSINESS_COUNTRIES, SAVE_BUSINESS_COUNTRIES_SET_DATA,
  SAVE_BUSINESS_USE_CASES, SAVE_BUSINESS_USE_CASES_SET_DATA,
  ADD_CUSTOMER_CONTACT_DETAILS, ADD_CUSTOMER_CONTACT_DETAILS_SET_DATA,
  SAVE_BUSINESS_SOURCE_OF_FUNDING, SAVE_BUSINESS_SOURCE_OF_FUNDING_SET_DATA,
  VERIFY_PERSONAL_DETAIL, VERIFY_PERSONAL_DETAIL_SET_DATA,
  ADD_BENEFICIAL_OWNER, ADD_BENEFICIAL_OWNER_SET_DATA,
  SIGNUP, SIGNUP_SET_DATA,
  GET_TERM_AND_CONDITION, GET_TERM_AND_CONDITION_SET_DATA,
  SAVE_CONFIDENTIALITY_AGREEMENT, SAVE_CONFIDENTIALITY_AGREEMENT_SET_DATA,
  DELETE_BENEFICIAL_OWNER, DELETE_BENEFICIAL_OWNER_SET_DATA,
  ACCEPT_CONFIDENTIAL_AGREEMENT, ACCEPT_CONFIDENTIAL_AGREEMENT_SET_DATA,
  RESEND_INVITATION_LINK, RESEND_INVITATION_LINK_SET_DATA,
  INVITE_AS_TEAM_MEMBER, INVITE_AS_TEAM_MEMBER_SET_DATA,
  SAVE_CONTROLLING_PARTY, SAVE_CONTROLLING_PARTY_SET_DATA,
  ACCEPT_ACCOUNT_AGREEMENT, ACCEPT_ACCOUNT_AGREEMENT_SET_DATA,
  TEMP_SAVE_REGISTRATION_DATA_ACTION, SUBMIT_REGISTRATION_EXTRA_INFO,
  SUBMIT_REGISTRATION_EXTRA_INFO_SET_DATA,
  ADD_CUSTOMER_BUSINESS_DETAIL,
  ADD_CUSTOMER_BUSINESS_DETAIL_SET_DATA, ADD_BUSINESS_GLOBAL_POSITION,
  ADD_BUSINESS_GLOBAL_POSITION_SET_DATA,
  RE_GENERATE_PDF, RE_GENERATE_PDF_SET_DATA,
  DOCUMENT_UPLOAD,
  DOCUMENT_UPLOAD_SET_DATA,
  SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER, SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER_SET_DATA,
  MARK_ID_VERIFICATION_STEP, MARK_ID_VERIFICATION_STEP_SET_DATA
} from './registerConstants';

import { getCustomerDetailParser, saveBusinessCategoriesParser, saveBusinessSourceOfFundingParser, saveBusinessCountriesParser, saveBusinessIndustriesParser, saveBusinessUseCasesParser, saveCustomerBusinessBriefParser, saveCustomerDetailParser, verifyPersonalDetailParser, saveBusinessStructureParser, addCustomerContactDetailsParser, addBeneficialOwnerParser, saveControllingPartyWithBeneficialOwnerParser, addCustomerBusinessDetailParser } from './registerParser';

export function resetRegisterAction() {
  return { type: RESET_REGISTER };
}

export function addCustomerIntroductionAction(data) {
  return { type: ADD_CUSTOMER_INTRODUCTION, data: saveCustomerDetailParser(data) };
}
export function addCustomerIntroductionSetDataAction(data) {
  return { type: ADD_CUSTOMER_INTRODUCTION_SET_DATA, data };
}

export function getCustomerDetailsAction(data) {
  return { type: GET_CUSTOMER_DETAILS, data };
}
export function getCustomerDetailsSetDataAction(data = {}, currentStep) {
  return { type: GET_CUSTOMER_DETAILS_SET_DATA, data: getCustomerDetailParser(data, currentStep) };
}

export function updateStepAction(data) {
  return { type: UPDATE_STEPS, data, };
}

export function addCustomerBusinessBriefAction(data) {
  return { type: ADD_CUSTOMER_BUSINESS_BRIEF, data: saveCustomerBusinessBriefParser(data) };
}
export function addCustomerBusinessBriefSetDataAction(data) {
  return { type: ADD_CUSTOMER_BUSINESS_BRIEF_SET_DATA, data };
}


export function saveBusinessBriefAction(data) {
  return { type: SAVE_BUSINESS_BRIEF, data };
}
export function saveBusinessBriefSetDataAction(data) {
  return { type: SAVE_BUSINESS_BRIEF_SET_DATA, data };
}

export function saveBusinessStructureAction(data) {
  return { type: SAVE_BUSINESS_STRUCTURE, data: saveBusinessStructureParser(data) };
}
export function saveBusinessStructureSetDataAction(data) {
  return { type: SAVE_BUSINESS_STRUCTURE_SET_DATA, data };
}

export function saveTermConditionAction(data) {
  return { type: SAVE_TERM_CONDITION, data };
}
export function saveTermConditionSetDataAction(data) {
  return { type: SAVE_TERM_CONDITION_SET_DATA, data };
}

export function saveBusinessCategoriesAction(data) {
  return { type: SAVE_BUSINESS_CATEGORIES, data: saveBusinessCategoriesParser(data) };
}
export function saveBusinessCategoriesSetDataAction(data) {
  return { type: SAVE_BUSINESS_CATEGORIES_SET_DATA, data };
}

export function saveBusinessIndustriesAction(data) {
  return { type: SAVE_BUSINESS_INDUSTRIES, data: saveBusinessIndustriesParser(data) };
}
export function saveBusinessIndustriesSetDataAction(data) {
  return { type: SAVE_BUSINESS_INDUSTRIES_SET_DATA, data };
}

export function saveBusinessUseCasesAction(data) {
  return { type: SAVE_BUSINESS_USE_CASES, data: saveBusinessUseCasesParser(data) };
}
export function saveBusinessUseCasesSetDataAction(data) {
  return { type: SAVE_BUSINESS_USE_CASES_SET_DATA, data };
}

export function saveBusinessCountriesAction(data) {
  return { type: SAVE_BUSINESS_COUNTRIES, data: saveBusinessCountriesParser(data) };
}
export function saveBusinessCountriesSetDataAction(data) {
  return { type: SAVE_BUSINESS_COUNTRIES_SET_DATA, data };
}
export function addCustomerContactDetailsAction(data) {
  return { type: ADD_CUSTOMER_CONTACT_DETAILS, data: addCustomerContactDetailsParser(data) };
}
export function addCustomerContactDetailsSetDataAction(data) {
  return { type: ADD_CUSTOMER_CONTACT_DETAILS_SET_DATA, data };
}


export function saveBusinessSourceOfFundingAction(data) {
  return { type: SAVE_BUSINESS_SOURCE_OF_FUNDING, data: saveBusinessSourceOfFundingParser(data) };
}
export function saveBusinessSourceOfFundingSetDataAction(data) {
  return { type: SAVE_BUSINESS_SOURCE_OF_FUNDING_SET_DATA, data };
}

export function verifyPersonalDetailAction(data) {
  return { type: VERIFY_PERSONAL_DETAIL, data: verifyPersonalDetailParser(data) };
}
export function verifyPersonalDetailSetDataAction(data) {
  return { type: VERIFY_PERSONAL_DETAIL_SET_DATA, data };
}

export function addBeneficialOwnerAction(data) {
  return { type: ADD_BENEFICIAL_OWNER, data: addBeneficialOwnerParser(data) };
}
export function addBeneficialOwnerSetDataAction(data) {
  return { type: ADD_BENEFICIAL_OWNER_SET_DATA, data };
}

export function signupAction(data) {
  return { type: SIGNUP, data };
}
export function signupSetDataAction(data) {
  return { type: SIGNUP_SET_DATA, data };
}
export function getTermAndConditionAction(data) {
  return { type: GET_TERM_AND_CONDITION, data };
}
export function getTermAndConditionSetDataAction(data) {
  return { type: GET_TERM_AND_CONDITION_SET_DATA, data };
}

export function saveConfidentialityAgreementAction(data) {
  return { type: SAVE_CONFIDENTIALITY_AGREEMENT, data };
}
export function saveConfidentialityAgreementSetDataAction(data) {
  return { type: SAVE_CONFIDENTIALITY_AGREEMENT_SET_DATA, data };
}

export function deleteBeneficialOwnerAction(data) {
  return { type: DELETE_BENEFICIAL_OWNER, data };
}
export function deleteBeneficialOwnerSetDataAction(data) {
  return { type: DELETE_BENEFICIAL_OWNER_SET_DATA, data };
}

export function acceptConfidentialAgreementAction(data) {
  return { type: ACCEPT_CONFIDENTIAL_AGREEMENT, data };
}
export function acceptConfidentialAgreementSetDataAction(data) {
  return { type: ACCEPT_CONFIDENTIAL_AGREEMENT_SET_DATA, data };
}

export function resendInvitationLinkAction(data) {
  return { type: RESEND_INVITATION_LINK, data };
}
export function resendInvitationLinkSetDataAction(data) {
  return { type: RESEND_INVITATION_LINK_SET_DATA, data };
}

export function inviteAsTeamMemberAction(data) {
  return { type: INVITE_AS_TEAM_MEMBER, data };
}
export function inviteAsTeamMemberSetDataAction(data) {
  return { type: INVITE_AS_TEAM_MEMBER_SET_DATA, data };
}

export function saveControllingPartyAction(data) {
  return { type: SAVE_CONTROLLING_PARTY, data };
}
export function saveControllingPartySetDataAction(data) {
  return { type: SAVE_CONTROLLING_PARTY_SET_DATA, data };
}

export function acceptAccountAgreementAction(data) {
  return { type: ACCEPT_ACCOUNT_AGREEMENT, data };
}
export function acceptAccountAgreementSetDataAction(data) {
  return { type: ACCEPT_ACCOUNT_AGREEMENT_SET_DATA, data };
}

export function tempSaveRegistrationDataAction(data) {
  return { type: TEMP_SAVE_REGISTRATION_DATA_ACTION, data };
}

export function submitRegistrationExtraInfoAction(data) {
  return { type: SUBMIT_REGISTRATION_EXTRA_INFO, data };
}
export function submitRegistrationExtraInfoSetDataAction(data) {
  return { type: SUBMIT_REGISTRATION_EXTRA_INFO_SET_DATA, data };
}
export function addCustomerBusinessDetailAction(data) {
  return { type: ADD_CUSTOMER_BUSINESS_DETAIL, data: addCustomerBusinessDetailParser(data) };
}
export function addCustomerBusinessDetailSetDataAction(data) {
  return { type: ADD_CUSTOMER_BUSINESS_DETAIL_SET_DATA, data };
}

export function addBusinessGlobalPositionAction(data) {
  return { type: ADD_BUSINESS_GLOBAL_POSITION, data: addCustomerContactDetailsParser(data) };
}
export function addBusinessGlobalPositionSetDataAction(data) {
  return { type: ADD_BUSINESS_GLOBAL_POSITION_SET_DATA, data };
}

export function reGeneratePdfAction(data) {

  return { type: RE_GENERATE_PDF, data };
}

export function reGeneratePdfSetDataAction(data) {
  return { type: RE_GENERATE_PDF_SET_DATA, data };
}

export function documentUploadAction(data) {
  return { type: DOCUMENT_UPLOAD, data };
}
export function documentUploadSetDataAction(data) {
  return { type: DOCUMENT_UPLOAD_SET_DATA, data };
}

export function saveControllingPartyWithBeneficialOwnerAction(data) {
  return { type: SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER, data: saveControllingPartyWithBeneficialOwnerParser(data) };
}
export function saveControllingPartyWithBeneficialOwnerSetDataAction(data) {
  return { type: SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER_SET_DATA, data };
}

export function markIdVerificationStepAction(data) {
  return { type: MARK_ID_VERIFICATION_STEP, data };
}
export function markIdVerificationStepSetDataAction(data) {
  return { type: MARK_ID_VERIFICATION_STEP_SET_DATA, data };
}
