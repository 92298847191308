import { removeNullKeys } from "utils/common";

export const updateBankDetailParser = (data = {}) => {
    const { id, cb, body = {}, bankConfig } = data;
    const bank_details = body.bank_details;
    const address = body.address;
    let parsedData = {
        id,
        cb: cb || (() => null),
        body: removeNullKeys({
            otp: body.otp,
            otp_ref_id: body.otp_ref_id,
        }),
    };

    if (Array.isArray(bankConfig) && bank_details) {
        const bankFields = bankConfig.filter(i => (i.bank_country_code === bank_details.bank_country_code && i.currency_code === bank_details.currency_code && i.payment_type === bank_details.payment_type));
        const bankFilledObj = bankFields.map(i => ({ [i.bank_account_field]: bank_details[i.bank_account_field] }));
        const bankHiddenFieldsObj = bankFields.filter(i => i.routing_code_type_field).map(i => ({ [i.routing_code_type_field]: i.routing_code_value }));
        let bankObj = Object.assign({}, ...bankFilledObj, ...bankHiddenFieldsObj, {
            bank_country_code: bank_details.bank_country_code,
            currency_code: bank_details.currency_code,
            payment_type: bank_details.payment_type,
            request_bank_details: bank_details.request_bank_details
        })
        if (bank_details.request_bank_details) {
            bankObj = { request_bank_details: bank_details.request_bank_details }
        }
        parsedData.body.bank_details = removeNullKeys(bankObj);
        parsedData.body.address = removeNullKeys(address);
    }

    return parsedData;
};