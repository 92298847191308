import { Divider, Grid, makeStyles } from '@material-ui/core';
import { BANK_ACCOUNT } from '@truly-financial/common/consts';
import { Loader, StyledError } from 'components';
import { Typography } from 'components/ui';
import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getRequestDetailAction } from 'store/ContactRequestBank/contactRequestBankActions';
import { getRequestDetailState } from 'store/ContactRequestBank/contactRequestBankSelectors';
import { validateLinkState } from 'store/User/userSelectors';
import BankDetailSubmitted from './BankDetailSubmitted';
import ContactAddBankDialog from './ContactAddBankDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(5, 2),
    },
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2, 2, 0, 0),
    background: theme.palette.background.paper,
    boxShadow: "0px 0px 10px rgba(49, 49, 56, 0.03)",
    height: "100vh",
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(2, 0),
  },
  infoDiv: {
    padding: "24px",
    gap: "20px",
    border: "2px solid #F2F3F4",
    borderRadius: "8px",
  },
  divider: {
    margin: theme.spacing(2.5, 0),
  },
  note: {
    paddingTop: theme.spacing(1),
  },
  info: {
    padding: theme.spacing(0.25, 0),
  },
  btnDiv: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    width: "45%",
    marginTop: theme.spacing(4),
  },
}));

function RequestSummary (props = {}) {
    const classes = useStyles();
    const { linkRes, requestDetails, getRequestDetail } = props;
    
    useEffect(() => {
        linkRes?.data?.bank_account_id && getRequestDetail({
            id: linkRes?.data?.bank_account_id,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkRes.data]);
    const data = requestDetails.data || {}
    const onSuccessAction = () => {
      linkRes?.data?.bank_account_id && getRequestDetail({
          id: linkRes?.data?.bank_account_id,
      });
    }
    
    if (requestDetails.error) {
      return (<StyledError data={requestDetails} retry={onSuccessAction} />)
    }

    if(requestDetails.loading || !data.bank_account_status){
      return (<Loader />);
    }


    if (data.bank_account_status && data.bank_account_status !== BANK_ACCOUNT.BANK_ACCOUNT_STATUS.REQUESTED) {
      return (<BankDetailSubmitted />)
    }

    return (<div>
           <div className={classes.root}>
            <div>
                <Typography className={classes.header} variant="h6">
                    Requesting Bank Details
                </Typography>
                <Typography variant="body2">
                    <b>{data.requested_by_first_name} {data.requested_by_last_name ? ' ' + data.requested_by_last_name : ''}</b> from <b>{data.requested_by_company_name} {data.requested_by_brand_name && <span>({data.requested_by_brand_name})</span>}</b> has added you to their contact list for future payments. please enter the deposit account information by selecting the button below.
                </Typography>
                <Typography className={classes.text} variant="body2">
                    Confidential bank details are always kept hidden, for your privacy.
                </Typography>
            </div>
            <div className={classes.infoDiv}>
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={classes.info} variant="subtitle2" color="grey400">Provided Info</Typography>
                            {data.company_name && <Typography className={classes.info} variant="subtitle2" color="grey600">{data.company_name}</Typography>}
                            <Typography className={classes.info} variant="body2" color="grey600">
                              {data.entity_person_first_name && <span>{data.entity_person_first_name} </span>}
                              {data.entity_person_middle_name && <span>{data.entity_person_middle_name} </span>}
                              {data.entity_person_last_name && <span>{data.entity_person_last_name}</span>}
                            </Typography>
                            <Typography className={classes.info} variant="body2" color="grey600">
                              {data.entity_person_dialing_code && <span>{data.entity_person_dialing_code} </span>}
                              {data.entity_person_mobile_masked && <span>{data.entity_person_mobile_masked}</span>}
                            </Typography>
                            <Typography className={classes.info} variant="body2" color="grey600">
                              {data.entity_person_email}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} md={5}>
                            <Typography variant="subtitle2" color="grey400">Request from</Typography>
                            <Typography className={classes.info} variant="subtitle2" color="grey600">{data.requested_by_company_name} {data.requested_by_brand_name && <span>({data.requested_by_brand_name})</span>}</Typography>
                            <Typography className={classes.info} variant="body2" color="grey600">
                              {data.requested_by_first_name && <span>{data.requested_by_first_name} </span>}
                              {data.requested_by_middle_name && <span>{data.requested_by_middle_name} </span>}
                              {data.requested_by_last_name && <span>{data.requested_by_last_name}</span>}
                            </Typography>
                        </Grid> */}
                    </Grid>
                </div>
                {data.bank_account_note && <div>
                    <Divider className={classes.divider} />
                    <Typography variant="subtitle2" color="grey400">
                        Note from {data.requested_by_company_name} {data.requested_by_brand_name && <span>({data.requested_by_brand_name})</span>}
                    </Typography>
                    <Typography className={classes.note} variant="body2" color="grey600">
                        {data.bank_account_note}
                    </Typography>
                </div>}
            </div>
            <div className={classes.btnDiv}>
                <ContactAddBankDialog data={data} onSuccessAction={onSuccessAction} onCancelAction={onSuccessAction} />
            </div>
           </div>
        </div>);
};

const mapStateToProps = createStructuredSelector({
    linkRes: validateLinkState(),
    requestDetails: getRequestDetailState()
});
  
const mapDispatchToProps = (dispatch) => ({
    getRequestDetail: data => dispatch(getRequestDetailAction(data)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(RequestSummary);
