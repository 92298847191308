
import ContactRequestBankDetail from 'containers/ContactRequestBank/ContactRequestBankDetail';
import PublicPageLayout from 'layouts/publicPageLayout';
import React from 'react';

function ContactRequestBankPage(props = {}) {
  return (<PublicPageLayout>
    <ContactRequestBankDetail />
  </PublicPageLayout>);
}

export default ContactRequestBankPage;
