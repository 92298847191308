import React, { memo } from 'react';
// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui'
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing('50%', 2),
    textAlign: "center",
  },
}));

function FeatureUnavailable(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { message } = props;
  return (<>
    <div className={classes.root}>
      <Typography variant="body1">{message}</Typography>
    </div>
  </>);
}

FeatureUnavailable.defaultProps = {
  message: 'This feature will be available once your account is approved!'
};

FeatureUnavailable.propTypes = {
  message: PropTypes.string
};

export default memo(FeatureUnavailable);
