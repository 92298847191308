
import React, { memo, useState, useEffect } from 'react';
import { Divider, Grid, Paper, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from 'components';
import { FormattedMessage } from 'react-intl';
import intl from './intl/RegistrationCompleteDashboard';
import { Typography, TextField, Button } from 'components/ui';
import extraQue from 'assets/images/extraQue.png';
import { REGISTER_BASE_API_URL } from 'config/apiUrls';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCustomerDetailsAction, submitRegistrationExtraInfoAction, documentUploadAction, documentUploadSetDataAction } from 'store/Register/registerActions';
import { submitIdVerificationAgreementState } from 'store/TeamMember/teamMemberSelectors';
import { getCustomerDetailsState, submitRegistrationExtraInfoState, documentUploadState } from 'store/Register/registerSelectors';
import PictureUpload from 'containers/Common/PictureUpload';
import { initialState } from 'store/Register/registerReducer';

const REGISTER_URL = `${REGISTER_BASE_API_URL}/registrations`;

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(0, 4),
            backgroundColor: theme.palette.background.default,
        },
        backgroundColor: theme.palette.primary.contrastText,
        width: "100%",
        display: 'flex',
        flex: 1
    },
    main: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            borderTopLeftRadius: theme.spacing(1.5),
            borderTopRightRadius: theme.spacing(1.5),
        },
        flex: 1,
        backgroundColor: theme.palette.primary.contrastText,
        minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
        // minHeight: `calc(100vh - 50px)`,
        // minHeight: `calc(100vh - 69px)`,
    },
    column: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,


    },
    questionDiv: {
        display: 'flex',
        // flex: 1,
        flexDirection: 'column'
    },
    textInoutQues: {
        textAlign: 'left',

    },
    inputDiv: {
        margin: theme.spacing(0.5, 0, 4, 0)
    },
    textAriaDiv: {
        // backgroundColor: theme.palette.grey50,
        margin: theme.spacing(0.5, 0, 4, 0),
        borderRadius: theme.spacing(1),
        flexDirection: 'column',
        display: 'flex',
        '& .input': {
            padding: '0px !important'
        }
    },
    textArea: {
        '& .MuiFilledInput-multiline.MuiFilledInput-marginDense': {
            padding: theme.spacing(2),
            fontWeight: 'normal !important',
            alignItems: 'flex-start',
            height: theme.spacing(11.75)
        },
        '& .MuiFilledInput-inputMultiline': {
            padding: `${theme.spacing(0)} !important `,
        },
        '& .MuiInputBase-inputMultiline': {
            height: theme.spacing(8.75),
        }
    },

    btnDiv: {
        margin: theme.spacing(5, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3, 0, 6, 0),
        }
    },
    img: {
        height: theme.spacing(25),
        width: theme.spacing(25),
        margin: theme.spacing(3, 0, 2, 0)
    },
    subTitle: {
        margin: theme.spacing(2, 2, 6, 2),
        textAlign: 'center',
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(2, 22, 6, 22),
        }
    },
    uploadDiv: {
        margin: theme.spacing(3, 0, 6, 0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    accentColor: {
        color: theme.palette.accent.main,
        marginTop: theme.spacing(1),
        textAlign: 'left',

        flex: 1
    },
    divider: {
        width: '55%',
        margin: theme.spacing(0, 0, 5, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            width: '100%',
        }
    },
    orTxt: {
        margin: theme.spacing(1, 0),
        textAlign: 'center'
    },
    space: {
        width: theme.spacing(1),
    },
    title: {
        textAlign: 'center'
    }
}));

function RequestExtraInfo(props = {}) {
    const classes = useStyles();
    const { customerDetails, getCustomerDetails, submitIdVerificationAgreementState,
        submitRegistrationExtraInfo, submitRegistrationExtraInfoState,
        documentUploadState, documentUpload, resetDocumentUpload
    } = props;
    const customerDetailData = customerDetails.data || {};

    const [formData, setFormData] = useState([]);

    useEffect(() => {
        if (customerDetailData && customerDetailData.company_details && customerDetailData.company_details.extra_registration_details) {
            let filterData = customerDetailData.company_details.extra_registration_details.filter(i => !i.field_value);
            setFormData(filterData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerDetailData.company_details]);

    const onChange = (e, index, id = "") => {

        const { name, value } = e.target || {};

        setFormData(previousState => {
            const updatedArray = previousState.map((item, i) => {
                return i === index ? { ...item, [name]: value } : item;
            });

            return updatedArray;
        });

    };
    const onFileChange = (e, index, id = "") => {
        const { name, value, response = {}, eId } = e.target || {};

        setFormData(previousState => {
            const updatedArray = previousState.map((item, i) => {
                if (item.document_url && i === index && Object.keys(response).length === 0) {
                    return i === index ? { ...item, "field_value": "", "document_url": "", "document_id": "" } : item;
                }
                else if (response && i === index) {
                    return id === eId && i === index ? { ...item, id: id, [name]: value, ...response } : item;
                } else {
                    return item;
                }

            });

            return updatedArray;
        });

    };

    const onSuccess = () => {
        getCustomerDetails();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        let body = {
            extra_registration_details: formData
        };
        submitRegistrationExtraInfo({ body: body, cb: onSuccess });
    };

    // if (!customerDetailData || ) {
    //     return (<><Loader componentCenter /></>);
    // };

    return (
        <Paper elevation={0} className={classes.root}>
            {
                ((submitIdVerificationAgreementState && submitIdVerificationAgreementState.loading) || (submitRegistrationExtraInfoState && submitRegistrationExtraInfoState.loading) || (customerDetails && customerDetails.loading) || !customerDetailData) && <Loader />
            }
            <div className={classes.main}>
                <form onSubmit={onSubmit} className={classes.column} >
                    <img src={extraQue} alt="checkList" className={classes.img} />
                    <Typography variant="h5" color="grey700" className={classes.title} ><FormattedMessage {...intl.accountExtraInfoTitle} /></Typography>
                    <Typography className={classes.subTitle} variant="body1" color="grey700" ><FormattedMessage {...intl.accountExtraInfoSubTitle} /></Typography>
                    <Divider className={classes.divider} />
                    <Grid container justifyContent='center' md={7} >
                        {
                            formData.map((item, index) => {
                                return (
                                    <>
                                        {
                                            (item.field_type === "text" || item.field_type === "textarea") &&
                                            <Grid container direction='column' justifyContent='center' md={8}>
                                                <div key={index} className={classes.questionDiv} >

                                                    <Typography className={classes.textInoutQues} variant="subtitle1" color="grey700" >{item.field_label}</Typography>
                                                    {item.field_type === "file" && item.field_hint && <Typography variant="caption" className={classes.accentColor} > {item.field_hint}</Typography>}
                                                    {
                                                        item.field_type === "text" &&
                                                        <>
                                                            <div className={classes.inputDiv} >
                                                                <TextField
                                                                    type="text"
                                                                    id={item.question_id}
                                                                    name="field_value"
                                                                    value={item.field_value}
                                                                    label={"Type Answer"}
                                                                    required
                                                                    onChange={(e) => { onChange(e, index) }}

                                                                />
                                                                {item.field_hint && <Typography variant="caption" className={classes.accentColor} > {item.field_hint}</Typography>}
                                                            </div>

                                                        </>
                                                    }

                                                    {
                                                        item.field_type === "textarea" &&
                                                        <div className={classes.textAriaDiv} >
                                                            <TextField
                                                                className={classes.textArea}
                                                                id={item.question_id}
                                                                name="field_value"
                                                                value={item.field_value}
                                                                placeholder={"Type Answer"}
                                                                required
                                                                multiline
                                                                rows={8}
                                                                onChange={(e) => { onChange(e, index) }}

                                                            />
                                                            {item.field_hint && <Typography variant="caption" className={classes.accentColor} > {item.field_hint}</Typography>}
                                                        </div>
                                                    }
                                                </div>
                                            </Grid>
                                        }
                                        {
                                            item.field_type === "file" && <Grid container direction='column' justifyContent='center' md={10}>
                                                <Typography className={classes.textInoutQues} variant="subtitle1" color="grey700" >{item.field_label}</Typography>
                                                {item.field_type === "file" && item.field_hint && <Typography variant="caption" className={classes.accentColor} > {item.field_hint}</Typography>}

                                                {/* <FileUploadWithProgress
                                                        key={`upload_document${index}`}
                                                        id={`upload_document${index}`}
                                                        btnName={"Upload a Document"}
                                                        method="POST"
                                                        url={`${REGISTER_URL}/business-document`}
                                                        formCustomizer={form => {

                                                            return form;
                                                        }}
                                                        beforeSend={request => {

                                                            return request;
                                                        }}
                                                        onFileError={(e) => {
                                                       
                                                        }}
                                                        required={item.field_value ? false : true}
                                                        name="field_value"
                                                        onChange={(e) => { onChange(e, index, `upload_document${index}`) }}
                                                    
                                                    /> */}
                                                {/* <div className={classes.space} /> */}


                                                <PictureUpload
                                                    id={`document${index}`}
                                                    btnName={"Take a photo"}
                                                    required
                                                    key='ex1'
                                                    url={`${REGISTER_URL}/business-document`}
                                                    name="field_value"
                                                    onChange={(e) => { onFileChange(e, index, `document${index}`) }}
                                                    value={item.field_value}
                                                    previewUrl={item.document_url || ""}
                                                    fileName={item.field_value || ""}
                                                    showPreview
                                                    method="POST"
                                                    formCustomizer={form => {

                                                        return form;
                                                    }}
                                                    beforeSend={request => {

                                                        return request;
                                                    }}
                                                    documentUploadState={documentUploadState}
                                                    documentUpload={documentUpload}
                                                    resetDocumentUpload={resetDocumentUpload}
                                                />

                                            </Grid>
                                        }
                                    </>
                                )
                            })
                        }
                    </Grid>

                    <Grid container justifyContent='center' md={2} className={classes.btnDiv} >
                        <Button color='secondary' type="submit"  >
                            Send
                        </Button>
                    </Grid>
                </form>

            </div>
        </Paper >


    );
}

const mapStateToProps = createStructuredSelector({
    submitIdVerificationAgreementState: submitIdVerificationAgreementState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    submitRegistrationExtraInfoState: submitRegistrationExtraInfoState(),
    documentUploadState: documentUploadState()
});

function mapDispatchToProps(dispatch) {
    return {
        submitRegistrationExtraInfo: (data) => dispatch(submitRegistrationExtraInfoAction(data)),
        getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
        documentUpload: data => dispatch(documentUploadAction(data)),
        resetDocumentUpload: data => dispatch(documentUploadSetDataAction(initialState.documentUploadState))
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(RequestExtraInfo);

