import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalContactState = state => state.contacts || {};

export const contactsState = () =>
  createSelector(GlobalState, substate => substate.contacts);

export const contactListState = () =>
  createSelector(globalContactState, substate => substate.contactListState);

export const contactSchedulePaymentListState = () =>
  createSelector(globalContactState, substate => substate.contactSchedulePaymentListState);

export const contactDetailsState = () =>
  createSelector(globalContactState, substate => substate.contactDetailsState);

export const addUpdateContactState = () =>
  createSelector(globalContactState, substate => substate.addUpdateContactState);

export const addContactBankAccountState = () =>
  createSelector(globalContactState, substate => substate.addContactBankAccountState);

export const addUpdateContactNoteState = () =>
  createSelector(globalContactState, substate => substate.addUpdateContactNoteState);

export const addContactCardState = () =>
  createSelector(globalContactState, substate => substate.addContactCardState);

export const linkContactState = () =>
  createSelector(globalContactState, substate => substate.linkContactState);

export const getContactPaymentsState = () =>
  createSelector(globalContactState, substate => substate.getContactPaymentListState);

export const deleteContactNoteState = () =>
  createSelector(globalContactState, substate => substate.deleteContactNoteState);

export const deleteContactState = () =>
  createSelector(globalContactState, substate => substate.deleteContactState);

export const getContactNotesState = () =>
  createSelector(globalContactState, substate => substate.getContactNotesState);

export const getContactBankAccountsState = () =>
  createSelector(globalContactState, substate => substate.getContactBankAccountsState);

export const deleteSchedulePaymentState = () =>
  createSelector(globalContactState, substate => substate.deleteSchedulePaymentState);

export const contactPaymentDetailState = () =>
  createSelector(globalContactState, substate => substate.contactPaymentDetailState);

export const getContactSchedulePaymentDetailsState = () =>
  createSelector(globalContactState, substate => substate.getContactSchedulePaymentDetailsState);

export const contactListRefresh = () =>
  createSelector(globalContactState, substate => substate.contactListRefresh);

export const validateIbanState = () =>
  createSelector(globalContactState, substate => substate.validateIbanState);
export const contactFullListState = () =>
  createSelector(globalContactState, substate => substate.contactFullListState);

export const updateContactBankAccountState = () =>
  createSelector(globalContactState, substate => substate.updateContactBankAccountState);

export const resendContactBankDetailRequestState = () =>
  createSelector(globalContactState, substate => substate.resendContactBankDetailRequestState);

export const archiveContactBankAccountState = () =>
  createSelector(globalContactState, substate => substate.archiveContactBankAccountState);
