import { Button } from "components/ui";
import React, { memo, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
// import { getUrlParamByName } from "utils/common";
import Auth from "@aws-amplify/auth";
import Logo from 'components/Logo';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles((theme) => ({

    logo: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'left',
        borderRadius: '0px 0px 10px 10px',
    },
    img: {
        height: theme.spacing(3),
        width: theme.spacing(8.5)
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));


export function GotoTrulyButton(props = {}) {
    const { title = "" } = props;
    const history = useHistory();
    const [u, setU] = useState();
    const classes = useStyles();

    useEffect(() => {
        getCurrentAuthUser();
    }, []);

    const continueToTruly = () => {
        if (u) {
            history.push(`/home`);
        } else {
            history.push(`/login`);
        }
    };

    const getCurrentAuthUser = async () => {
        const u = await Auth.currentAuthenticatedUser();
        setU(u);
    };

    if (history.location.pathname === '/home' || history.location.pathname === '/dashboard') {
        return null;
    }

    return (<Grid container justifyContent="flex-end">
        {/* <Grid xs={12} sm={4} md={3} item> */}
        <div>
            <Button size="small"
                onClick={continueToTruly}
                variant="outlined"
            >
                <div className={classes.row}>
                    {title || 'Login To Truly'}
                    <Logo width={68} height={24} />
                    <div>
                        <ArrowRightAltIcon />
                    </div>
                </div>
            </Button>
        </div>
    </Grid>);
}

export default memo(GotoTrulyButton);