/*
 * AccountData Messages
 *
 * This contains all the text for the AccountData component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.addPaymentMenuIntl';

export default defineMessages({
    add: {
        id: `${scope}.add`,
        defaultMessage: 'Add',
    },
    banAcc: {
        id: `${scope}.banAcc`,
        defaultMessage: 'Bank Account',
    },
    card: {
        id: `${scope}.card`,
        defaultMessage: 'Card',
    },
    reqFromContact: {
        id: `${scope}.reqFromContact`,
        defaultMessage: 'Request from Contact',
    },

});
