/* eslint-disable no-script-url */
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { logout } from 'utils/auth';
import { addSpaceEvery } from 'utils/common';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import MfaIntro from './MfaIntro';
import MfaOptions from './MfaOptions';
import TotpGenerateQr from './TotpGenerateQr';
import VerifyQrCode from './VerifyQrCode';
import VerifySmsCode from './VerifySmsCode';
import MfaSetSuccess from './MfaSetSuccess';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    // padding: theme.spacing(2, 4),
  },
}));

function SetupMfa(props = {}) {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [mfaType, setMfaType] = React.useState('');
  const [totpQrStr, setTotpQrStr] = useState('');
  const [totpQrSecretStr, setTotpQrSecretStr] = useState('');
  const [step, setStepHook] = useState(props.step || 0);
  const [formData, setFormData] = useState({});
  const {
    allowDisableMfa = false,
    setLoading = () => null,
    loading,
    setAuthState = () => null,
    setUser: propsSetUser = () => null,
    checkUser: propsCheckUser = () => null,
  } = props;

  useEffect(() => {
    checkUser();
  }, []);

  const setStep = (s) => {
    setLoading(true);
    setTimeout(() => {
      setStepHook(s);
      setLoading(false)
    }, 300);
  }

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const checkUser = async () => {
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      if (authenticatedUser.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
        setMfaType('TOTP');
      } else if (authenticatedUser.preferredMFA === 'SMS_MFA') {
        setMfaType('SMS');
      } else {
        // setMfaType('NOMFA');
      }
      setUser(authenticatedUser);
    } catch (error) {
      console.error('error: ', error);
      logout();
    }
  }

  const getQrCode = async () => {
    setLoading(true);
    try {
      const code = await Auth.setupTOTP(user);
      const str = "otpauth://totp/" + user.attributes.email + "?secret=" + code + "&issuer=Truly Financial";
      setTotpQrStr(str);
      setTotpQrSecretStr(addSpaceEvery(code));
      setStep(2);
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyQrToken = async (e = {}) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      await Auth.verifyTotpToken(user, formData.code);
      await Auth.setPreferredMFA(user, 'TOTP');
      // await checkUser();
      setStep(6);
      // Auth.verifyTotpToken(user, formData.code)
      // .then(() => {
      //   Auth.setPreferredMFA(user, 'TOTP').then(() => {
      //     // styledSnackbar.success('Preferred MFA Set');
      //     checkUser();
      //     setStep(6);
      //   }).catch(() => {
      //     styledSnackbar.error('Error setting Preferred MFA');
      //   });
      // }).catch(e => {
      //   console.error('failed with error', e);
      //   styledSnackbar.error('failed with error');
      // });
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifySmsCode = async (e = {}) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', formData.code);
      await Auth.setPreferredMFA(user, 'SMS');
      // await checkUser();
      setStep(6);
      // Auth.verifyCurrentUserAttributeSubmit('phone_number', formData.code)
      // .then(() => {
      //   Auth.setPreferredMFA(user, 'SMS').then(() => {
      //     checkUser();
      //     setStep(6);
      //   }).catch(() => {
      //     styledSnackbar.error('Error setting Preferred MFA');
      //   });
      // }).catch(e => {
      //   console.error('failed with error', e);
      //   styledSnackbar.error('failed with error');
      // });
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const verifyNoMFA = async () => {
    setLoading(true);
    try {
      await Auth.setPreferredMFA(user, 'NOMFA')
      // await checkUser();
      setStep(6);
      // Auth.setPreferredMFA(user, 'NOMFA').then(() => {
      //   checkUser();
      //   setStep(6);
      // }).catch((e) => {
      //   styledSnackbar.error('Error setting Preferred MFA', e);
      // });
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinalContinue = async () => {
    setLoading(true);
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      propsSetUser(authenticatedUser);
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (<>
    <div className={classes.mainDiv}>
      {step === 0 && <MfaIntro setStep={setStep} setAuthState={setAuthState} />}
      {step === 1 && <MfaOptions mfaType={mfaType} setMfaType={setMfaType} setStep={setStep} getQrCode={getQrCode} verifyNoMFA={verifyNoMFA} allowDisableMfa={allowDisableMfa} />}
      {step === 2 && <TotpGenerateQr totpQrStr={totpQrStr} getQrCode={getQrCode} totpQrSecretStr={totpQrSecretStr} setStep={setStep} />}
      {step === 3 && <VerifyQrCode handleVerifyQrToken={handleVerifyQrToken} setStep={setStep} loading={loading} code={formData.code} onChange={onChange} />}
      {step === 4 && <VerifyPhoneNumber checkUser={propsCheckUser} setStep={setStep} setLoading={setLoading} user={props.user} />}
      {step === 5 && <VerifySmsCode user={props.user} setLoading={setLoading} handleVerifySmsCode={handleVerifySmsCode} loading={loading} code={formData.code} onChange={onChange} setStep={setStep} />}
      {step === 6 && <MfaSetSuccess onFinalContinue={onFinalContinue} />}
    </div>
  </>);
}


export default memo(SetupMfa);
