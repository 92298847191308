
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import messages from './intl/CardLockIntl';
import PropType from 'prop-types';
import PanToolOutlinedIcon from '@material-ui/icons/PanToolOutlined';
import PanToolIcon from '@material-ui/icons/PanTool';
import StyledSwitch from './StyledSwitch';
const useStyles = makeStyles(theme => ({

  root: props => ({

    padding: theme.spacing(1, 0, 1, 1),
    backgroundColor: props.locked ? "rgba(226, 45, 32, 0.1)" : theme.palette.smoke.main,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    borderRadius: theme.spacing(0.625),
    alignItems: 'center'

  }),
  subDiv: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  txtDiv: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column'
  },
  lockIcon: {
    fontSize: theme.spacing(3.125),
    margin: theme.spacing(0, 1, 0, 1)
  },
  panIcon: {
    fontSize: theme.spacing(3.125),
    margin: theme.spacing(0, 1, 0, 1),
    color: " #E22D20"
  },


  switch_primary: {
    "&.Mui-checked": {
      color: "#E22D20",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#E22D20",
    },
  },

}));

function CardLock(props) {

  const classes = useStyles(props);
  const { onSwitchChangeAction, checked, locked } = props;

  return (
    <div className={classes.root} >
      <div className={classes.subDiv} >
        {
          !locked ? <PanToolOutlinedIcon className={classes.lockIcon} /> : <PanToolIcon className={classes.panIcon} />
        }

        <div className={classes.txtDiv}>
          <Typography variant="subtitle2" color="grey700" > {!locked ? <FormattedMessage {...messages.lockCard} /> : <FormattedMessage {...messages.unLockCard} />}</Typography>
          <Typography variant="caption" color="grey500" > {!locked ? <FormattedMessage {...messages.desTxt} /> : <FormattedMessage {...messages.unDesTxt} />}</Typography>
        </div>
      </div>

      <StyledSwitch
        checked={checked}
        onChange={onSwitchChangeAction}
        color="primary"
        classes={{
          colorPrimary: classes.switch_primary,
        }}
      />
    </div>

  );
}
CardLock.defaultProps = {
  onSwitchChangeAction: () => { },
}

CardLock.propTypes = {
  onSwitchChangeAction: PropType.func
};

export default memo(CardLock);
