

import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { IconButton, makeStyles, Button } from '@material-ui/core';
import { Icon, Typography } from 'components/ui';
import { ArrowBackIos } from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import message from 'components/intl/PreviewBackIntl';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  backDiv: props => ({
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // position: props && props.position === "relative" ? "relative" : "fixed",
    position: "sticky",
    background: "white",
    // top: props && props.position === "relative" ? "0px" : theme.appHeaderHeight,
    top: 0,
    // paddingTop: theme.spacing(1.5),
    padding: theme.spacing(1.5, 2, 0),
    zIndex: 999,
    // width: props && props.position === "relative" ? "null" : theme.spacing(40.25),
    width: "-webkit-fill-available",
    borderTopLeftRadius: props && props.roundCorner ? theme.spacing(1.5) : undefined,
    borderTopRightRadius: props && props.roundCorner ? theme.spacing(1.5) : undefined,
    // marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      position: props && props.position === "relative" ? "relative" : "absolute",
      top: 0,
      right: 0,
      left: 0,
      width: "-webkit-fill-available",
      // margin: theme.spacing(0, 3),
      // background: "transparent",
    },
  }),
  backIcon: {
    fontSize: '16px !important',
    paddingBottom: '2px'
  },
  BackBtn: {
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0),
    },
    '&:hover': {
      background: 'none'
    },
    borderRadius: theme.spacing(3.125),
    padding: `${theme.spacing(0.75, 1, 0.75, 0.5)} !important`
  },
  button: {
    margin: theme.spacing(1),
  },
  iconButton: {
    padding: '0px !important',
    '&:hover': {
      background: 'none'
    },
  }
}));


function PreviewBack(props) {
  // eslint-disable-next-line no-unused-vars
  const { onBackAction, title, menuOptions, showMenu, hideBackBtn = false, roundCorner = false } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.backDiv} >
        {!hideBackBtn ? <Button disableRipple onClick={onBackAction} className={classes.BackBtn}
          startIcon={<Icon disableRipple component={ArrowBackIos} color="grey300" fontSize="inherit" className={classes.backIcon} />}
        ><Typography color="grey300" variant="subtitle1">{title}</Typography></Button> : <div></div>}

        {
          showMenu ? <IconButton className={classes.iconButton} disableRipple aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
            <Icon component={MoreHorizIcon} color="grey300" />
          </IconButton> : <></>
        }

      </div>
      {Boolean(anchorEl) && <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableEnforceFocus
      // ref={null}
      >
        {menuOptions ? menuOptions(handleClose) : [
          <MenuItem key={1} onClick={handleClose}><Typography><FormattedMessage {...message.edit} /></Typography></MenuItem>,
          <MenuItem key={2} onClick={handleClose}><Typography><FormattedMessage {...message.delete} /></Typography></MenuItem>
        ]}
      </Menu>}
    </>
  );
}
PreviewBack.defaultProps = {
  showMenu: true
}

PreviewBack.propTypes = {
  onBackAction: PropTypes.any,
  title: PropTypes.any,
  onEditAction: PropTypes.func,
  onDeleteAction: PropTypes.func,
  showMenu: PropTypes.bool,
  position: PropTypes.any
};

export default memo(PreviewBack);
