import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { AbilityBuilder, Ability } from "@casl/ability";

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export function defineAbility(access) {
  const { can, rules } = new AbilityBuilder(Ability);

  access.forEach((a) => {
    a.functions.forEach((f) => {
      can(f.functionUiCode, a.trulyModuleCode);
    });

    a.subModules.forEach((s) => {
      s.functions.forEach((f) => {
        can(f.functionUiCode, s.trulySubModuleCode);
      });
    });
  });

  return new Ability(rules);
}
