/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from 'components/ui';
import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTermAndConditionAction } from 'store/Register/registerActions';
import { getTermAndConditionState } from 'store/Register/registerSelectors';
import parse from 'html-react-parser';
import { Loader, StyledError } from 'components';

function TermsAndConditions(props = {}) {
    const { getTermAndConditionState,
        getTermAndConditionAction } = props;
    const tncData = getTermAndConditionState.data.find(i => i.name === 'GENERAL_TNC') || {};

    const agreement = tncData.description || '';

    useEffect(() => {
        if (!agreement) {
            getTerms();
        }
    }, []);

    const getTerms = () => {
        getTermAndConditionAction();
    };

    if (getTermAndConditionState.loading) {
        return (<Loader componentCenter />);
    }

    return (<>
        <StyledError data={getTermAndConditionState} retry={getTerms} />
        {agreement && <Typography color="grey600" variant="body1">{parse(agreement)}</Typography>}
    </>);
}

const mapStateToProps = createStructuredSelector({
    getTermAndConditionState: getTermAndConditionState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getTermAndConditionAction: data => dispatch(getTermAndConditionAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(TermsAndConditions);
