/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import IdVerificationStatus from 'containers/IdVerification/IdVerificationStatus';

function VerificationStatusPage(props = {}) {
    return (
        <>
            <IdVerificationStatus />
        </>

    )
}

export default VerificationStatusPage;
