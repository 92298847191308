/* eslint-disable eqeqeq */
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import combineClx from 'utils/combineClx';
import moment from 'moment';
import intl from 'components/intl/RegistrationAgreementPdfIntl';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
  },
  img1: {
    width: theme.spacing(15),
    height: theme.spacing(7.5),
  },
  span1: {
    overflow: "hidden",
    display: "inline-block",
    margin: "0px 0px",
    width: theme.spacing(27.75),
    height: theme.spacing(9.49),
  },
  img2: {
    width: theme.spacing(20),
    height: theme.spacing(7.5),
  },
  textCenter: {
    textAlign: "center",
  },
  header: {
    color: " #2f5496",
    fontWeight: 700,
    textDecoration: "none",
    fontSize: "16px",
    fontFamily: "Calibri",
    fontStyle: "normal",
    lineHeight: 1,
  },
  font400: {
    fontWeight: 400,
  },
  th: {
    color: "#000000",
    fontWeight: 700,
    fontSize: "11px",
    fontFamily: "Calibri",
  },
  table: {
    borderCollapse: "collapse",
  },
  tdata: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "11px",
    fontFamily: "Calibri",
    fontStyle: "normal",
  },

  tpara: {
    color: "#000000",
    fontWeight: 400,
    fontSize: "12px",
    fontFamily: "Calibri",
    fontStyle: "normal",
  },

  td2: {
    borderBottomColor: "#000000",
    borderTopWidth: "1pt",
    borderLeftColor: "#000000",
    borderLeftWidth: "1pt",
    borderTopStyle: "solid",
    borderLeftStyle: "solid",
    borderBottomWidth: "1pt",
    width: theme.spacing(83),
    borderTopColor: "#000000",
    borderBottomStyle: "solid",
    paddingLeft: "10px",
  },
  td1: {
    borderBottomColor: "#000000",
    borderTopWidth: "1pt",
    borderLeftColor: "#000000",
    borderTopStyle: "solid",
    borderBottomWidth: "1pt",
    width: theme.spacing(83),
    borderTopColor: "#000000",
    borderBottomStyle: "solid",
    paddingLeft: "10px",
  },

  signature: {
    fontSize: "24px",
    fontWeight: "bold",
    fontFamily: "Corinthia, cursive",
  },
  bodyDiv: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  table2: {
    borderCollapse: "collapse",
    marginTop: theme.spacing(2),
  },
  acknowledgementTxt: {
    marginLeft: "0px",
  },
  statementTxt: {
    // marginLeft: theme.spacing(2.5),
  },
  imgDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
  },
  spanLabel: {
    fontSize: "10px",
    color: "gray",
    fontWeight: 400,
  },
}));


/* eslint-disable react/prop-types */
function RegistrationAgreementPdf(props = {}) {

    const { data, signature = "" } = props;
    const classes = useStyles();
    const agreementData = data.prs_agreement_data || {};

    return (<>
        <div className={classes.bodyDiv} >
            <div className={classes.root}>
                <p className={classes.imgDiv}>
                    <img alt="" src="https://truly-webapp-assets.s3.amazonaws.com/partner-logos/truly-white-logo.svg" title="Truly" className={classes.img1} />

                    {/* <img alt="" src="https://truly-webapp-assets.s3.amazonaws.com/partner-logos/cross-revier-logo.png"
                        className={classes.img2} title="cross" /> */}

                </p>
            </div>
            <h1 className={classes.textCenter} >
                <span className={classes.header}><FormattedMessage {...intl.creationOfBeneficialOwners} /></span>
            </h1>

            <h1 className={classes.textCenter}>
                <span className={combineClx(classes.header, classes.font400)} >{agreementData?.prs_company_name || ""}</span>
            </h1>
            <p>
                <span className={classes.th}><FormattedMessage {...intl.detailsOfBusiness} /></span>
            </p>

            <table className={classes.table} >

                <tr>
                    <td className={classes.td1} colSpan="2" rowSpan="1">
                        <span className={classes.spanLabel} ><FormattedMessage {...intl.fullName} /></span>
                        <br />
                        <span className={classes.tdata} >{agreementData?.prs_company_name || ""}</span>
                    </td>
                    <td className={classes.td2} colSpan="8" rowSpan="1">
                        <span className={classes.spanLabel} ><FormattedMessage {...intl.textId} /></span>
                        <br />
                        <span className={classes.tdata} >{agreementData?.prs_tax_id_value}</span>
                    </td>
                </tr>

                <tr>
                    <td className={classes.td1} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.streetAddress} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_entity_street_address}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.city} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_entity_city}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.state} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_entity_state_name}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel} ><FormattedMessage {...intl.zipCode} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_entity_zip_code}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel} ><FormattedMessage {...intl.country} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_entity_country_name}</span>
                    </td>
                </tr>

                <tr>
                    <td className={classes.td1} colSpan="20" rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.relationShip} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_full_name}</span>
                    </td>
                </tr>
            </table>
            <br />
            <p>
                <span className={classes.th}><FormattedMessage {...intl.beneficialOwners} /></span><br />
                <span className={classes.tpara}><FormattedMessage {...intl.ownerDescription} />
                </span>
            </p>

            {/* <span>#each beneficial_owners</span> */}
            {
                agreementData?.prs_beneficialOwners && agreementData?.prs_beneficialOwners.length > 0 && agreementData?.prs_beneficialOwners.map((item, index) => {

                    const primaryAdd = item && item.addresses && item.addresses.filter(item => item.address_type === "PRIMARY")[0];

                    const street_address = primaryAdd?.address_line_1;
                    const city = primaryAdd?.city;
                    const state = primaryAdd?.state_name;
                    const zip_code = primaryAdd?.postal_code;
                    const country_name = primaryAdd?.country_name;
                    const date_of_birth = item?.entity_person_date_of_birth ? moment(new Date(item?.entity_person_date_of_birth)).utc().format("MM/DD/YYYY") : "-";
                    // const ssn_value = [item?.entity_person_country_of_residence, item?.entity_person_citizenship_country].includes('US') ? (item.entity_person_identification_value || item?.entity_person_tax_id_value) : "-";
                    const ssn_value = item?.ssn_value || "-";
                    const id_number = item?.document_id_number || item?.id_number || "-";
                    const country_of_issuance = item?.country_of_issuance_name || item?.document_country_of_issuance || item?.country_of_issuance || "-";
                    const expiry_date = (item?.document_expiry_date && moment(new Date(item?.document_expiry_date)).utc().format("MM/DD/YYYY")) || (item?.expiry_date && moment(new Date(item?.expiry_date)).utc().format("MM/DD/YYYY")) || "-";
                    // const id_number = item?.entity_person_citizenship_country !== "US" ? item?.document_id_number : "-";
                    // const country_of_issuance = item?.entity_person_citizenship_country !== "US" ? item?.document_country_of_issuance : "-";
                    // const expiry_date = item?.entity_person_citizenship_country !== "US" ? item?.document_expiry_date : "-";
                    return (
                        <table className={classes.table2}>
                            <tr>
                                <td className={classes.td1} colSpan="2" rowSpan="1">
                                    <span className={classes.th}>Beneficial Owner {index + 1} Information
                                    </span>
                                </td>
                                <td className={classes.td2} colSpan="2" rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.ownershipPer} /></span>
                                    <br />
                                    <span className={classes.tdata}>{item.ownership_percentage}</span>
                                </td>
                            </tr>

                            <tr>
                                <td className={classes.td1} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.lastName} /></span>
                                    <br />
                                    <span className={classes.tdata}>{item.entity_person_last_name}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.middleName} /></span>
                                    <br />
                                    <span className={classes.tdata}>{item.entity_person_middle_name}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.firstName} /></span>
                                    <br />
                                    <span className={classes.tdata}>{item.entity_person_first_name}</span>
                                </td>
                            </tr>

                            <tr>
                                <td className={classes.td1} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.address} /></span>
                                    <br />
                                    <span className={classes.tdata}>{street_address}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.city} /></span>
                                    <br />
                                    <span className={classes.tdata}>{city}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.state} /></span>
                                    <br />
                                    <span className={classes.tdata}>{state}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.zipCode} /></span>
                                    <br />
                                    <span className={classes.tdata}>{zip_code} </span>
                                </td>
                            </tr>

                            <tr>
                                <td className={classes.td1} colSpan="1" rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.country} /></span>
                                    <br />
                                    <span className={classes.tdata}>{country_name}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.dob} /></span>
                                    <br />
                                    <span className={classes.tdata}>{date_of_birth}</span>
                                </td>
                                <td className={classes.td2} colSpan="2" rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.ssn} /></span>
                                    <br />
                                    <span className={classes.tdata}>{ssn_value}</span>
                                </td>
                            </tr>

                            <tr>
                                <td className={classes.td1} rowSpan="1">
                                    <span className={classes.tdata}><FormattedMessage {...intl.nonUsa} /></span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.gov} /></span>
                                    <br />
                                    <span className={classes.tdata}>{id_number}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.dateExpiration} /></span>
                                    <br />
                                    <span className={classes.tdata}>{expiry_date}</span>
                                </td>
                                <td className={classes.td2} rowSpan="1">
                                    <span className={classes.spanLabel}><FormattedMessage {...intl.countryOfIssuance} /></span>
                                    <br />
                                    <span className={classes.tdata}>{country_of_issuance}</span>
                                </td>
                            </tr>
                        </table>
                    )
                })
            }

            <br />
            <p>
                <span className={classes.th}><FormattedMessage {...intl.controllingParties} /></span>
                <br />
                <span className={classes.tpara}><FormattedMessage {...intl.controllingPartiesDes} />
                </span>
            </p>
            <table className={classes.table} >
                <tr>
                    <td className={classes.td1} colSpan="2" rowSpan="1">
                        <span class="th"><FormattedMessage {...intl.controllingPartiesInfo} /></span>
                    </td>
                    <td className={classes.td2} colSpan="2" rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.position} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_contact_person_position}</span>
                    </td>
                </tr>
                <tr>
                    <td className={classes.td1} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.lastName} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_contact_person_last_name}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.middleName} /> </span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_contact_person_middle_name}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.firstName} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_contact_person_first_name}</span>
                    </td>
                </tr>

                <tr>
                    <td className={classes.td1} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.address} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_street_address}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.city} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_city}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.state} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_state}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.zipCode} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_zip_code}</span>
                    </td>
                </tr>

                <tr>
                    <td className={classes.td1} colSpan="1" rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.country} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_country_name}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.dob} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_date_of_birth}</span>
                    </td>
                    <td className={classes.td2} colSpan="2" rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.ssn} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_ssn_value}</span>
                    </td>
                </tr>

                <tr>
                    <td className={classes.td1} rowSpan="1">
                        <span className={classes.tdata}><FormattedMessage {...intl.nonUsa} /></span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.gov} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_id_number}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.dateExpiration} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_expiry_date}</span>
                    </td>
                    <td className={classes.td2} rowSpan="1">
                        <span className={classes.spanLabel}><FormattedMessage {...intl.countryOfIssuance} /></span>
                        <br />
                        <span className={classes.tdata}>{agreementData?.prs_controlling_party_country_of_issuance}</span>
                    </td>
                </tr>
            </table>

            <br />

            <span className={combineClx(classes.acknowledgementTxt, classes.th)}><FormattedMessage {...intl.acknowledgement} /></span>
            <br />
            <br />
            <span className={combineClx(classes.tpara, classes.statementTxt)} >
                <FormattedMessage {...intl.iHerebyCertify} />
            </span>
            <br />
            <br />
            <span className={combineClx(classes.spanLabel)}><FormattedMessage {...intl.nameOfIndividual} /></span>
            <span className={classes.tdata}> {agreementData?.prs_full_name}</span>
            <br />
            <br />
            <span className={combineClx(classes.signature)}>{signature}</span>
            <br />
            <br />
            <span className={combineClx(classes.tdata)}>{moment(new Date()).format("MM/DD/YYYY ")}</span>

        </div>
    </>);
}

RegistrationAgreementPdf.defaultProps = {

};
RegistrationAgreementPdf.propTypes = {


};


export default memo(RegistrationAgreementPdf);
