import React, { memo } from 'react';

import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';
import rightTick from 'assets/images/rightTick.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: theme.palette.accent.main
        },
        '& .MuiSwitch-track': {
            backgroundImage: `url(${rightTick})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '41%,71%',
            backgroundPosition: '2px,0px'
        }
    }
}));


function StyledSwitch(props = {}) {
    const { name, onChange = () => { }, } = props;

    const classes = useStyles();
    const handleChange = (event) => {
        onChange && onChange(event.target.checked, name);
    };
    return (
        <>
            <Switch
                {...props}
                onChange={handleChange}
                className={classes.root}
                name={name}

            />
        </>
    )
}

StyledSwitch.defaultProps = {


}
StyledSwitch.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string
};


export default memo(StyledSwitch);
