import React, { useState, memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getBusinessTypeListAction } from 'store/Reference/referenceActions';
import { getBusinessTypeListState } from 'store/Reference/referenceSelectors';


function SelectBusinessType(props) {
    // eslint-disable-next-line no-unused-vars
    const [value, setValue] = useState({});
    const { businessTypeList, getBusinessTypeList, propsBusinessTypeList,
        // propsGetBusinessTypeList,
        countryCode } = props;
    const options = propsBusinessTypeList || businessTypeList;
    // const getOptions = propsGetBusinessTypeList || getBusinessTypeList;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (countryCode) {
            getBusinessTypeList({ countryCode: countryCode });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    const onChange = (e, val) => {
        setValue(val || {});
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: val && val.business_type
            }
        }, val);
    };

    return (<>
        <Autocomplete
            {...props}
            getOptions={() => null}
            options={options}
            onChange={onChange}
            onBlur={props.onBlur}
            selectKey="business_type"
            labelKey="business_type_name"
            makeFocus={props.makeFocus}
            helperText={props.helperText}
            renderOption={(option) => (
                <React.Fragment>
                    {option.business_type_name}
                </React.Fragment>
            )}
        />
    </>);
}


const mapStateToProps = createStructuredSelector({
    businessTypeList: getBusinessTypeListState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getBusinessTypeList: data => dispatch(getBusinessTypeListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectBusinessType,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.businessTypeList) === JSON.stringify(nextProps.businessTypeList) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);