import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core';

const StatusColor = (status) => {

    const theme = useTheme();
    let colorObj = {
        DRAFTED: `${theme.palette.primary.main}`,
        SCHEDULED: `${theme.palette.accent.main}`,
        PENDING: `${theme.palette.warning.main}`,
        AWAITING_FUNDS: `${theme.palette.warning.main}`,
        QUEUED: `${theme.palette.warning.main}`,
        READY_FOR_SETTLEMENT: `${theme.palette.warning.main}`,
        PICKED_FOR_SETTLEMENT: `${theme.palette.warning.main}`,
        SETTLED: `${theme.palette.success.main}`,
        FAILED: `${theme.palette.error.main}`,
        CANCELLED: `${theme.palette.error.main}`,
        ON_HOLD: `${theme.palette.error.main}`,
        RETURNED: `${theme.palette.error.main}`,
        RECURRING: `#B882B9`,
    };
    return colorObj[`${status}`];
};

const useStyles = makeStyles(theme => ({

    roundDiv: props => ({
        height: theme.spacing(1.25),
        width: theme.spacing(1.25),
        backgroundColor: props.color,
        borderRadius: theme.spacing(0.625),
        marginLeft: props.type === "prefix" ? "none" : 'auto',
        marginTop: props.type === "prefix" ? "none" : -7,
        marginRight: 4
    })
}));


/* eslint-disable react/prop-types */
function PaymentStatusDot(props = {}) {

    const { status, type } = props;
    const color = StatusColor(status);
    const classes = useStyles({ color, type });

    return (<>
        <div className={classes.roundDiv} />
    </>);
}

PaymentStatusDot.defaultProps = {
    status: "",
    type: "suffix"
}
PaymentStatusDot.propTypes = {

    status: PropTypes.any,
    type: PropTypes.any

};


export default memo(PaymentStatusDot);
