/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable indent */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import StyledTableHead from './TableHead';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from 'components/ui';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  root: {
    // borderBottom: "none",
    // borderRadius: theme.spacing(0.75),
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
      //  borderRadius: theme.spacing(0.75),
    },
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  table: {
    // minWidth: 700,
  },
  dateDiv: {
    flex: 1,
    display: 'flex',
    // backgroundColor: theme.palette.background.default,
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.625)
  },
  expandView: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    justifyContent: 'space-around'
  }
}));

function StyledTable(props) {
  const { rows, columns } = props;
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowsPerPage || 0);
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [open, setOpen] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSelectAllClick = event => {
    if (event.target.checked && rows && rows.length > 0) {
      const newSelected = rows.map(n => n.No);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer >
        <Table stickyHeader className={classes.table} aria-label="collapsible table">
          <StyledTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={(rows && rows.length) || 0}
            columns={(columns && columns.length) || 0}
            checkBox={props.checkBox}
          />
          <TableBody>
            {/* <StyledTableRow>
              <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} >
                <div className={classes.dateDiv} >05 MAR</div>
              </StyledTableCell>
            </StyledTableRow> */}
            {Array.isArray(rows) && rows.map((row, rowIndex) => {
              return (
                <>
                  <StyledTableRow
                    hover
                    key={row.No}>
                    {props.columns.map((item, cellIndex) => {
                      return (
                        <StyledTableCell
                          key={`index${rowIndex}${cellIndex}`}
                        // align="center"
                        >
                          {typeof item.renderComponent === 'function' ? item.renderComponent(row[item.key], row) : row[item.key]}
                        </StyledTableCell>
                      )
                    })}
                    {
                      Array.isArray(row.Expand) && row.Expand.length > 0 ?
                        <StyledTableCell >
                          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </StyledTableCell>
                        : <></>
                    }
                  </StyledTableRow>
                  {
                    Array.isArray(row.Expand) && row.Expand.length > 0 ? <StyledTableRow>
                      <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5} >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <div className={classes.expandView} >

                            <Typography> Paid from: ****** 1234</Typography>
                            <Typography>Reference No. 123456</Typography>

                            {/* {
                              row.Expand.map((item, index) => {
                                return (
                                  <>
                                    <Typography> Paid from: {item.debit_from}</Typography>
                                    <Typography>Reference No. {item.ref_no}</Typography>
                                  </>
                                )
                              })
                            } */}
                          </div>
                        </Collapse>
                      </StyledTableCell>
                    </StyledTableRow> : <></>
                  }
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {
        props.pagination ? <TablePagination
          rowsPerPageOptions={props.rowsPerPageOptions}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> : <></>
      }

    </>
  );
}

StyledTable.defaultProps = {
  checkBox: false,
  pagination: false
}

StyledTable.propTypes = {
  data: PropTypes.any,
  checkBox: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  rowsPerPage: PropTypes.number,
  pagination: PropTypes.bool
};

export default memo(StyledTable);
