import { call, all } from 'redux-saga/effects';
import otpSaga from './Otp/otpSaga';
import contactRequestBankSaga from './ContactRequestBank/contactRequestBankSaga';
import verificationSaga from './Verification/verificationSaga';
import teamMemberSaga from './TeamMember/teamMemberSaga';
import tagsSaga from './Tag/tagSaga';
import referencesSaga from './Reference/referenceSaga';
import userSaga from './User/userSaga';
import paymentsSaga from './Payment/paymentSaga';
import contactsSaga from './Contact/contactSaga';
import eventSchedulerSaga from './EventScheduler/eventSchedulerSaga';
import accountSaga from './Account/accountSaga';
import aclSaga from './Acl/aclSaga';
import registerSaga from './Register/registerSaga';
import sendPaymentSaga from './SendPayment/sendPaymentSaga';

export default function* rootSaga() {
  // service Sagas
  yield all([
    call(otpSaga),
    call(contactRequestBankSaga),
    call(verificationSaga),
    call(teamMemberSaga),
    call(tagsSaga),
    call(referencesSaga),
    call(userSaga),
    call(paymentsSaga),
    call(contactsSaga),
    call(eventSchedulerSaga),
    call(accountSaga),
    call(aclSaga),
    call(registerSaga),
    call(sendPaymentSaga)
  ]);
}
