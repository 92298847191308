export const RESET_ACCOUNTS = 'Account/RESET_ACCOUNTS';

export const GET_ACCOUNT_LIST = 'Account/GET_ACCOUNT_LIST';
export const GET_ACCOUNT_LIST_SET_DATA = 'Account/GET_ACCOUNT_LIST_SET_DATA';
export const GET_ACCOUNT_LIST_RESET_DATA = 'Account/GET_ACCOUNT_LIST_RESET_DATA';

export const GET_ACCOUNT_DETAILS = 'Account/GET_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_SET_DATA = 'Account/GET_ACCOUNT_DETAILS_SET_DATA';

export const ACCOUNT_PAYMENT_TRANSFER = 'Account/ACCOUNT_PAYMENT_TRANSFER';
export const ACCOUNT_PAYMENT_TRANSFER_SET_DATA = 'Account/ACCOUNT_PAYMENT_TRANSFER_SET_DATA';

export const CREATE_CHECK_IN_ACCOUNT = 'Account/CREATE_CHECK_IN_ACCOUNT';
export const CREATE_CHECK_IN_ACCOUNT_SET_DATA = 'Account/CREATE_CHECK_IN_ACCOUNT_SET_DATA';

export const ACCOUNT_PAYMENT_LIST = 'Account/ACCOUNT_PAYMENT_LIST';
export const ACCOUNT_PAYMENT_LIST_SET_DATA = 'Account/ACCOUNT_PAYMENT_LIST_SET_DATA';
export const ACCOUNT_PAYMENT_LIST_RESET_DATA = 'Account/ACCOUNT_PAYMENT_LIST_RESET_DATA';

export const ACCOUNT_PAYMENT_DETAIL = 'Account/ACCOUNT_PAYMENT_DETAIL';
export const ACCOUNT_PAYMENT_DETAIL_SET_DATA = 'Account/ACCOUNT_PAYMENT_DETAIL_SET_DATA';
export const GET_ACCOUNT_CURRENCIES = 'Account/GET_ACCOUNT_CURRENCIES';
export const GET_ACCOUNT_CURRENCIES_SET_DATA = 'Account/GET_ACCOUNT_CURRENCIES_SET_DATA';

export const GET_CHECK_IN_ACCOUNT_LIST = 'Account/GET_CHECK_IN_ACCOUNT_LIST';
export const GET_CHECK_IN_ACCOUNT_LIST_SET_DATA = 'Account/GET_CHECK_IN_ACCOUNT_LIST_SET_DATA';

export const GET_SMART_LINK_ACCOUNT_LIST = 'Account/GET_SMART_LINK_ACCOUNT_LIST';
export const GET_SMART_LINK_ACCOUNT_LIST_SET_DATA = 'Account/GET_SMART_LINK_ACCOUNT_LIST_SET_DATA';

export const GET_SMART_LINK_CARD_LIST = 'Account/GET_SMART_LINK_CARD_LIST';
export const GET_SMART_LINK_CARD_LIST_SET_DATA = 'Account/GET_SMART_LINK_CARD_LIST_SET_DATA';
export const RESET_SMART_LINK_CARD_LIST = 'Account/RESET_SMART_LINK_CARD_LIST';
export const GET_SMART_LINK_ACCOUNT_DETAILS = 'Account/GET_SMART_LINK_ACCOUNT_DETAILS';
export const GET_SMART_LINK_ACCOUNT_DETAILS_SET_DATA = 'Account/GET_SMART_LINK_ACCOUNT_DETAILS_SET_DATA';

export const GET_SMART_LINK_ACCOUNT_PAYMENTS = 'Account/GET_SMART_LINK_ACCOUNT_PAYMENTS';
export const GET_SMART_LINK_ACCOUNT_PAYMENTS_SET_DATA = 'Account/GET_SMART_LINK_ACCOUNT_PAYMENTS_SET_DATA';
export const RESET_SMART_LINK_ACCOUNT_PAYMENTS = 'Account/RESET_SMART_LINK_ACCOUNT_PAYMENTS';

export const ACTIVATE_PHYSICAL_CARD = 'Account/ACTIVATE_PHYSICAL_CARD';
export const ACTIVATE_PHYSICAL_CARD_SET_DATA = 'Account/ACTIVATE_PHYSICAL_CARD_SET_DATA';

export const GET_CARD_SECURE_DETAILS = 'Account/GET_CARD_SECURE_DETAILS';
export const GET_CARD_SECURE_DETAILS_SET_DATA = 'Account/GET_CARD_SECURE_DETAILS_SET_DATA';

export const CARD_CHANGE_PIN = 'Account/CARD_CHANGE_PIN';
export const CARD_CHANGE_PIN_SET_DATA = 'Account/CARD_CHANGE_PIN_SET_DATA';

export const CARD_LOCK = 'Account/CARD_LOCK';
export const CARD_LOCK_SET_DATA = 'Account/CARD_LOCK_SET_DATA';

export const SMART_LINK_ACC_LOCK = 'Account/SMART_LINK_ACC_LOCK';
export const SMART_LINK_ACC_LOCK_SET_DATA = 'Account/SMART_LINK_ACC_LOCK_SET_DATA';

export const GET_ACCOUNT_SETTING = 'Account/GET_ACCOUNT_SETTING';
export const GET_ACCOUNT_SETTING_SET_DATA = 'Account/GET_ACCOUNT_SETTING_SET_DATA';

export const SAVE_ACCOUNT_SETTING = 'Account/SAVE_ACCOUNT_SETTING';
export const SAVE_ACCOUNT_SETTING_SET_DATA = 'Account/SAVE_ACCOUNT_SETTING_SET_DATA';

export const GET_ACCOUNT_TEAM_MEMBERS = 'Account/GET_ACCOUNT_TEAM_MEMBERS';
export const GET_ACCOUNT_TEAM_MEMBERS_SET_DATA = 'Account/GET_ACCOUNT_TEAM_MEMBERS_SET_DATA';

export const GET_TEAM_MEMBER_DETAIL = 'Account/GET_TEAM_MEMBER_DETAIL';
export const GET_TEAM_MEMBER_DETAIL_SET_DATA = 'Account/GET_TEAM_MEMBER_DETAIL_SET_DATA';

export const ADD_UPDATE_TEAM_MEMBER = 'Account/ADD_UPDATE_TEAM_MEMBER';
export const ADD_UPDATE_TEAM_MEMBER_SET_DATA = 'Account/ADD_UPDATE_TEAM_MEMBER_SET_DATA';
export const RESET_TEAM_MEMBER_DATA = 'Account/RESET_TEAM_MEMBER_DATA';

export const CREATE_SMART_LINK_ACC = 'Account/CREATE_SMART_LINK_ACC';
export const CREATE_SMART_LINK_ACC_SET_DATA = 'Account/CREATE_SMART_LINK_ACC_SET_DATA';


export const GET_CARD_DETAILS = 'Account/GET_CARD_DETAILS';
export const GET_CARD_DETAILS_SET_DATA = 'Account/GET_CARD_DETAILS_SET_DATA';

export const ISSUE_SMARTLINK_CARDS = 'Account/ISSUE_SMARTLINK_CARDS';
export const ISSUE_SMARTLINK_CARDS_SET_DATA = 'Account/ISSUE_SMARTLINK_CARDS_SET_DATA';

export const GET_CARD_PAYMENT_DETAIL = 'Account/GET_CARD_PAYMENT_DETAIL';
export const GET_CARD_PAYMENT_DETAIL_SET_DATA = 'Account/GET_CARD_PAYMENT_DETAIL_SET_DATA';

export const UPDATE_CARD_NICK_NAME = 'Account/UPDATE_CARD_NICK_NAME';
export const UPDATE_CARD_NICK_NAME_SET_DATA = 'Account/UPDATE_CARD_NICK_NAME_SET_DATA';

export const PRE_QUICK_TRANSFER = 'Account/PRE_QUICK_TRANSFER';
export const PRE_QUICK_TRANSFER_SET_DATA = 'Account/PRE_QUICK_TRANSFER_SET_DATA';

export const QUICK_TRANSFER = 'Account/QUICK_TRANSFER';
export const QUICK_TRANSFER_SET_DATA = 'Account/QUICK_TRANSFER_SET_DATA';

export const GET_LINK_ACC_LIST = 'Account/GET_LINK_ACC_LIST';
export const GET_LINK_ACC_LIST_SET_DATA = 'Account/GET_LINK_ACC_LIST_SET_DATA';

export const GET_EXTERNAL_ACCOUNTS = 'Account/GET_EXTERNAL_ACCOUNTS';
export const GET_EXTERNAL_ACCOUNTS_SET_DATA = 'Account/GET_EXTERNAL_ACCOUNTS_SET_DATA';

export const ADD_EXTERNAL_ACCOUNT = 'Account/ADD_EXTERNAL_ACCOUNT';
export const ADD_EXTERNAL_ACCOUNT_SET_DATA = 'Account/ADD_EXTERNAL_ACCOUNT_SET_DATA';

export const GET_EXTERNAL_ACC_DETAILS = 'Account/GET_EXTERNAL_ACC_DETAILS';
export const GET_EXTERNAL_ACC_DETAILS_SET_DATA = 'Account/GET_EXTERNAL_ACC_DETAILS_SET_DATA';

export const REMOVE_EXTERNAL_ACCOUNT = 'Account/REMOVE_EXTERNAL_ACCOUNT';
export const REMOVE_EXTERNAL_ACCOUNT_SET_DATA = 'Account/REMOVE_EXTERNAL_ACCOUNT_SET_DATA';

export const GET_EXTERNAL_ACC_PAYMENT_LIST = 'Account/GET_EXTERNAL_ACC_PAYMENT_LIST';
export const GET_EXTERNAL_ACC_PAYMENT_LIST_SET_DATA = 'Account/GET_EXTERNAL_ACC_PAYMENT_LIST_SET_DATA';

export const GET_EXTERNAL_ACC_BANK_TRANSACTIONS = 'Account/GET_EXTERNAL_ACC_BANK_TRANSACTIONS';
export const GET_EXTERNAL_ACC_BANK_TRANSACTIONS_SET_DATA = 'Account/GET_EXTERNAL_ACC_BANK_TRANSACTIONS_SET_DATA';

export const GET_EXTERNAL_ACCOUNT_BALANCE = 'Account/GET_EXTERNAL_ACCOUNT_BALANCE';
export const GET_EXTERNAL_ACCOUNT_BALANCE_SET_DATA = 'Account/GET_EXTERNAL_ACCOUNT_BALANCE_SET_DATA';

export const LINK_EXTERNAL_ACCOUNT = 'Account/LINK_EXTERNAL_ACCOUNT';
export const LINK_EXTERNAL_ACCOUNT_SET_DATA = 'Account/LINK_EXTERNAL_ACCOUNT_SET_DATA';

export const UNLINK_EXTERNAL_ACCOUNT = 'Account/UNLINK_EXTERNAL_ACCOUNT';
export const UNLINK_EXTERNAL_ACCOUNT_SET_DATA = 'Account/UNLINK_EXTERNAL_ACCOUNT_SET_DATA';

export const GET_GLOBAL_ACCOUNT_LIST = 'Account/GET_GLOBAL_ACCOUNT_LIST';
export const GET_GLOBAL_ACCOUNT_LIST_SET_DATA = 'Account/GET_GLOBAL_ACCOUNT_LIST_SET_DATA';
export const GET_GLOBAL_ACCOUNT_LIST_REFRESH = 'Account/GET_GLOBAL_ACCOUNT_LIST_REFRESH';



export const CSV_GET_PAYMENT_LIST = 'Account/CSV_GET_PAYMENT_LIST';
export const CSV_GET_PAYMENT_LIST_SET_DATA = 'Account/CSV_GET_PAYMENT_LIST_SET_DATA';

export const RESET_ACCOUNT_TRANSFER_FORM_DATA = 'Account/RESET_ACCOUNT_TRANSFER_FORM_DATA';
export const QUICK_TRANSFER_DATA = 'Account/QUICK_TRANSFER_DATA';
export const SUBMIT_PAD_VERIFICATION = 'Account/SUBMIT_PAD_VERIFICATION';
export const SUBMIT_PAD_VERIFICATION_SET_DATA = 'Account/SUBMIT_PAD_VERIFICATION_SET_DATA';

export const RE_AUTHORIZED_EXTERNAL_ACC = 'Account/RE_AUTHORIZED_EXTERNAL_ACC';
export const RE_AUTHORIZED_EXTERNAL_ACC_SET_DATA = 'Account/RE_AUTHORIZED_EXTERNAL_ACC_SET_DATA';
