import React from "react";
import Verification from "containers/Verification/Verification";

function VerificationPage(props = {}) {

    return (
        <>
            <Verification />
        </>
    )
}

export default VerificationPage;

