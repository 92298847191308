import { call, put, takeLatest } from 'redux-saga/effects';
import { requestWithErrorFeedback } from 'utils/request';

import { aclListSetDataAction } from './aclActions';
import { ACL_LIST } from './aclConstants';
import { USER_BASE_API_URL } from 'config/apiUrls';

const TRULY_USER_URL = `${USER_BASE_API_URL}/truly-user`;

export default function* aclSaga() {
    yield takeLatest(ACL_LIST, getAclListSaga);
}

export function* getAclListSaga() {
    const requestUrl = `${TRULY_USER_URL}/access`;
    try {
        const result = yield call(requestWithErrorFeedback, requestUrl);

        yield put(aclListSetDataAction(result));
    } catch (error) {
        console.error(error);
    }
}
