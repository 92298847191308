import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import { makeStyles } from '@material-ui/core';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

const useStyles = makeStyles((theme) => ({
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: '50%',
        backgroundColor: theme.palette.secondary[500]
    },
    icon1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: '50%',
        border: '2px solid #409EC3',
        color: '#409EC3',
    },
    checkIcon: {
        fontSize: theme.spacing(2),
        color: theme.palette.primary.main
    },
    otherIcon: {
        fontSize: theme.spacing(1.9),
    }
}));
function SummaryStepIcon(props = {}) {
    const { completed, type } = props;
    const classes = useStyles();
    if (completed) {
        return (<div className={classes.icon}>
            <CheckIcon className={classes.checkIcon} />
        </div>)
    }
    else if (type === REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT) {

        return (<div className={classes.icon1}>
            <SentimentSatisfiedIcon className={classes.otherIcon} />
        </div>)
    }
    else if (type === REGISTRATION_STEP.BUSINESS_BRIEF) {

        return (<div className={classes.icon1}>
            <FolderOutlinedIcon className={classes.otherIcon} />
        </div>)
    }
    else if (type === REGISTRATION_STEP.BUSINESS_USE_CASES) {
        return (<div className={classes.icon1}>
            <CreateOutlinedIcon className={classes.otherIcon} />
        </div>)
    }
    else if (type === REGISTRATION_STEP.BUSINESS_STRUCTURE) {
        return (<div className={classes.icon1}>
            <BusinessOutlinedIcon className={classes.otherIcon} />
        </div>)
    }
    else if (type === REGISTRATION_STEP.TERMS_AND_CONDITIONS) {
        return (<div className={classes.icon1}>
            <AssignmentTurnedInOutlinedIcon className={classes.otherIcon} />
        </div>)
    }
    else if (type === "ID_VERIFICATION") {
        return (<div className={classes.icon1}>
            <StoreOutlinedIcon className={classes.otherIcon} />
        </div>)
    }
    else {
        return <></>
    }
};

export default SummaryStepIcon;