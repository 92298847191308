import { createSelector } from 'reselect';
import { initialState } from './snackbarReducer';

const SnackbarDomainState = state => state || initialState;

export const selectSnackbar = () =>
  createSelector(
    SnackbarDomainState,
    substate => substate.snackbar,
  );

export default SnackbarDomainState;
