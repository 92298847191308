/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button, TextField } from 'components/ui';
import React, { useState, memo, useEffect } from 'react';
import { Grid } from '@material-ui/core/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import IndustryListSkeleton from '../Skeleton/IndustryListSkeleton';
import { addBeneficialOwnerAction, deleteBeneficialOwnerAction, getCustomerDetailsAction, saveControllingPartyAction, saveControllingPartyWithBeneficialOwnerAction, tempSaveRegistrationDataAction } from 'store/Register/registerActions';
import { addBeneficialOwnerState, deleteBeneficialOwnerState, getCustomerDetailsState, saveControllingPartyState, saveControllingPartyWithBeneficialOwnerState, tempRegistrationDataState } from 'store/Register/registerSelectors';
import GoBack from '../GoBack';
import ControllingPartySquare from 'components/ControllingPartySquare';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
import SelectRole from 'containers/Common/SelectRole';
import PhoneInput from 'containers/Common/PhoneInput';
import { Loader } from 'components';
import { getAvatarTxt } from 'utils/common';
import { ENTITY_PERSON_TYPE } from '@truly-financial/common/consts/entity-person.consts';
import { styledSnackbar } from 'containers/Common/Snackbar';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#fff',
    },
    content: {
        // textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    beneficialContent: {
        margin: theme.spacing(4, 0),
    },
    middleContent: {
        margin: theme.spacing(4, 0, 3, 0),
    },
    margin: {
        margin: theme.spacing(0, 0),
        width: '100%'
    },
    align: {
        display: 'flex',
        alignItems: 'center'
    },
    marginAuto: {
        margin: 'auto'
    },

    title: {
        textAlign: 'center'
    },
    btnDiv: {
        flex: 1
    },
    lastMainGrid: {
        margin: theme.spacing(0, 0, 2, 0),
        alignItems: 'center',
        justifyContent: 'center',
        // [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
        //     justifyContent: 'space-between',
        // }
    },
    aboveMiddleContent: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    inputDiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    justifyContentCenter: {
        justifyContent: 'center'
    },
    mainTitle: {
        textAlign: 'center',
    },
    subTitle: {
        textAlign: 'center',
        margin: theme.spacing(2, 0, 0, 0),
    },
    yourDetailTxt: {
        margin: theme.spacing(0, 0, 2, 0)
    },
    ownDetailDiv: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        width: theme.spacing(50)

    },
    lastDetailDiv: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        marginBottom: theme.spacing(1),

    },
    avatar: {
        height: theme.spacing(3.75),
        width: theme.spacing(3.75),
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.tertiary_three[200]} !important`,
    },
    otherAvatar: {
        height: theme.spacing(3.75),
        width: theme.spacing(3.75),
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.grey50} !important`,
    },
    ownerShipTxt: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    nameDiv: {
        margin: theme.spacing(0, 0, 0, 1),
        display: 'flex',
        // flex: 1,
        flexDirection: 'column'
    },
    deleteIconDiv: {
        marginLeft: theme.spacing(2),
        padding: 0
    },
    deleteIcon: {
        fontSize: theme.spacing(2),
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        margin: theme.spacing(4, 0)
    },
    contentTitle: {
        margin: theme.spacing(0, 0, 2, 0)
    },
    iconDiv: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        // marginTop: theme.spacing(3)
    },
    addOwnerBtn: {
        margin: theme.spacing(6, 0, 3, 0)
    },

    continueBtn: {

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addOwnerBtnDiv: {
        margin: theme.spacing(5, 0, 0, 0)
    },
    otherDetailDiv: {
        margin: theme.spacing(3, 'auto', 5, 'auto'),
        flex: 1,
        justifyContent: 'space-between',
        display: 'flex'
    },
    ownerErrorTxt: {
        marginLeft: theme.spacing(1)
    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    btnDiv2: {
        flex: 1,
        width: theme.spacing(21.875)
    },

    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(50),
        marginLeft: theme.spacing(8),
        marginBottom: theme.spacing(1.5)
    },
    termConditionMargin: {
        margin: theme.spacing(4.75, 0, 0, 0),
    },
    emailHelperText: {
        color: theme.palette.accent.main,
        // marginLeft: theme.spacing(1.5)
    },
    ownerShipOtherTxt: {
        margin: theme.spacing(0, 3, 0, 1),
    },
    commonFlex: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    rootGrid: {
        maxWidth: theme.spacing(51.75),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0, 2)
        }
    },
    addIcon: {
        fontSize: theme.spacing(3),
        marginRight: theme.spacing(1.5)
    },
    clickHere: {
        textDecoration: 'underline',
        color: theme.palette.accent.main,
        cursor: 'pointer',
    },
    subContentNone: {
        cursor: 'pointer',
        color: theme.palette.accent.light,
        textDecoration: 'underline',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        flex: 1,
        marginBottom: theme.spacing(4)
    },
    box: {
        maxWidth: '100%',
        flexBasis: '56%'
    },

}));
const initialFormData = {
    entity_person_first_name: '',
    entity_person_last_name: '',
    entity_person_email: '',
    contact_person_position: '',
    entity_person_mobile: '',
    entity_person_mobile_country_code: '',
    entity_person_type: ENTITY_PERSON_TYPE.STAKEHOLDER,
};

function ControllingPartyNew(props = {}) {

    const { onContinue = () => null, onBackPress = () => null, addBeneficialOwnerAction, tempRegistrationDataState,
        getCustomerDetailsState, addBeneficialOwnerState, saveControllingParty, getCustomerDetailsAction, saveControllingPartyState,
        tempSaveRegistrationData, deleteBeneficialOwnerState, deleteBeneficialOwnerAction, saveControllingPartyWithBeneficialOwnerAction,
        saveControllingPartyWithBeneficialOwnerState } = props;

    const [formData, setFormData] = useState({ ...initialFormData });
    const textIntl = useIntl();
    const intlStr = useIntl();

    const [isAddBeneficialOwner, setIsAddBeneficialOwner] = useState(false);
    const classes = useStyles();
    const [controllingPartyArray, setControllingPartyArray] = useState([]);
    const theme = useTheme();
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};

    const avatarColor = [
        theme.palette.tertiary_one[200], theme.palette.tertiary_two[200],
        theme.palette.tertiary_three[200], theme.palette.tertiary_four[200],
    ];
    const isLoading = ((addBeneficialOwnerState && addBeneficialOwnerState.loading) || (deleteBeneficialOwnerState && deleteBeneficialOwnerState.loading) ||
        (saveControllingPartyState && saveControllingPartyState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading) ||
        (saveControllingPartyWithBeneficialOwnerState && saveControllingPartyWithBeneficialOwnerState.loading));

    useEffect(() => {
        if (controllingPartyArray.length > 0) {
            const tempArray = [];
            controllingPartyArray.forEach((item) => {
                const localSave = {};
                // eslint-disable-next-line eqeqeq

                localSave.entity_person_id = item;
                tempArray.push(localSave);
            });

        }

    }, [controllingPartyArray]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_SELECTION_PAGE_LOAD.event })
    }, []);

    const onSuccessDelete = (item) => {
        tempSaveRegistrationData({
            prs_beneficial_owners_data: JSON.parse(JSON.stringify(item.entity_person_id)),
        });

        getCustomerDetailsAction();
    };
    const onDeleteOwners = (item) => {


        if (item.entity_person_id) {

            deleteBeneficialOwnerAction({
                id: item.entity_person_id,
                cb: () => { onSuccessDelete(item) }
            });

        }
    };
    useEffect(() => {
        if (customerDetail.skipControllingParty) {
            onSuccess();
        } else {

            if (customerDetail && customerDetail.prs_controlling_party_data && customerDetail.prs_controlling_party_data.entity_persons && customerDetail.prs_controlling_party_data.entity_persons.length > 0) {
                let idArray = [];
                customerDetail.prs_controlling_party_data.entity_persons.forEach((item) => {
                    if (item.is_controlling_party) {
                        idArray.push(item.entity_person_id);
                    }
                });

                setControllingPartyArray(idArray);
            }

            if ((tempRegistrationDataState && tempRegistrationDataState.prs_controlling_party_data && tempRegistrationDataState.prs_controlling_party_data.length > 0)) {
                let idArray = [];
                tempRegistrationDataState.prs_controlling_party_data.forEach((item) => {
                    // eslint-disable-next-line eqeqeq

                    idArray.push(item.entity_person_id);
                });
                setControllingPartyArray(idArray);
            }
        }

    }, [customerDetail.prs_controlling_party_data]);



    const pushItem = (e) => {
        setControllingPartyArray([e]);
    };

    const onSuccess = (arg = "onBoarding") => {
        onContinue(arg);
    };

    const onAddSuccess = (id) => {

        if (id) {
            let body = {
                entity_person_ids: [id],
            };
            saveControllingParty({
                body,
                cb: onSuccess
            });

            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_SELECTION_CONTINUE_CLICK.event });
        }
    };

    const onContinuePress = (e) => {
        e.preventDefault();
        let body = {
            entity_person_ids: controllingPartyArray,
        };
        saveControllingPartyWithBeneficialOwnerAction({
            body,
            beneficialOwnerBody: [{ ...formData }],
            cb: onSuccess
        });

        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_SELECTION_CONTINUE_CLICK.event });
    };


    const onAddControllingParty = async (params) => {
        setFormData({ ...initialFormData });
        setIsAddBeneficialOwner(true);
    };

    const onBack = () => {
        setIsAddBeneficialOwner(false);
    };
    const onChangeControllingParty = (e, index, item) => {
        const { name, value } = e.target || {};

        if (name) {
            setFormData({
                ...item,
                [name]: value
            });
        }

    };
    const onChange = (e, index) => {
        const { name, value } = e.target || {};

        if (name) {
            setFormData(previousState => ({
                ...previousState,
                [name]: value
            }));
        }

    };
    const isEMailDuplicate = () => {

        let tempFormData = [...customerDetail.entity_persons, { ...formData }];

        var emailArray = tempFormData.map(function (item) { return item.entity_person_email; });
        let duplicateEmailArray = emailArray.some((item, index) => emailArray.indexOf(item) !== index);
        return duplicateEmailArray;
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (isEMailDuplicate()) {
            styledSnackbar.error('Same email id can not be used for multiple owners / controlling party');
            return;
        }
        addBeneficialOwnerAction({ body: [formData], cb: (id) => { onAddSuccess(id) } });


    };

    const beneficialOwnerContent = (index) => {
        let name = (formData.entity_person_first_name || "") + (formData.entity_person_last_name || "");

        return (
            <Grid className={classes.rootGrid} direction="column" key={index}>
                <Grid >
                    <span className={classes.ownDetailDiv}>
                        <div className={classes.iconDiv}>
                            <Avatar
                                // className={classes.avatar}
                                style={{
                                    height: theme.spacing(3.75),
                                    width: theme.spacing(3.75),
                                    color: theme.palette.primary.main,
                                    backgroundColor: avatarColor[(index + 1) % 4]
                                }}
                                classes={{ colorDefault: classes.avatarColor }}
                                alt="dp"
                            >
                                <Typography className={classes.roundTxt} color="grey700" variant="body2" >{getAvatarTxt(formData, "entity")}</Typography>
                            </Avatar>
                            <div className={classes.nameDiv} >
                                <Typography variant="body2" color="grey700">{name ? (formData.entity_person_first_name || "") + " " + (formData.entity_person_last_name || "") : "-"} </Typography>


                                <div className={classes.ownerShipTxt} >
                                    <Typography variant="cation" color="grey400">{(formData && formData.contact_person_position) || "-"}</Typography>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            {
                                formData.ownership_percentage && Number(formData.ownership_percentage) !== 0 ? <div>
                                    <Typography variant="cation" color="grey500">{(formData && formData.ownership_percentage) || 0}% <FormattedMessage {...intl.onBoardingStep13OwnerShip} /></Typography>
                                </div> : <div></div>
                            }
                        </div>
                    </span>
                </Grid>
                <Grid>
                    <div className={classes.inputDiv}>
                        <TextField
                            label={`${intlStr.formatMessage(intl.onBoardingStep10FirstName)}`}
                            type="text"
                            id="entity_person_first_name"
                            name="entity_person_first_name"
                            required
                            value={formData.entity_person_first_name}
                            errorText=""
                            onChange={(e) => { onChange(e, index); }}
                        />
                    </div>
                    <div className={classes.inputDiv}>
                        <TextField
                            label={`${intlStr.formatMessage(intl.onBoardingStep10LastName)}`}
                            type="text"
                            id="entity_person_last_name"
                            name="entity_person_last_name"
                            required
                            value={formData.entity_person_last_name}
                            errorText=""
                            onChange={(e) => { onChange(e, index); }}
                        />
                    </div>

                    <div className={classes.inputDiv}>
                        <SelectRole
                            label={`${intlStr.formatMessage(intl.onBoardingStepRole)}`}
                            required
                            id="contact_person_position"
                            name="contact_person_position"
                            errorText=""
                            value={formData.contact_person_position}
                            onChange={(e) => { onChange(e, index); }}
                        />
                    </div>

                    <div className={classes.inputDiv}>
                        <TextField
                            id="entity_person_email"
                            label={`${intlStr.formatMessage(intl.onBoardingStep10Email)}`}
                            type="email"
                            name="entity_person_email"
                            required
                            value={formData.entity_person_email}
                            errorText=""
                            onChange={(e) => { onChange(e, index); }}
                        />
                        <Typography variant="caption" className={classes.emailHelperText} > <FormattedMessage {...intl.onBoardingStep13VerificationLabel} /></Typography>
                    </div>
                    <div className={classes.inputDiv} >
                        <PhoneInput
                            // disabled={data.entity_person_type === "PRIMARY_CONTACT" ? true : false}
                            mobileCountryId="entity_person_mobile_country_code"
                            mobileCountryName="entity_person_mobile_country_code"
                            mobileCountryValue={formData.entity_person_mobile_country_code}
                            mobileCountryLabel={<FormattedMessage {...intl.onBoardingStep13YourDetailCountryLabel} />}
                            mobileDialingCodeId=""
                            mobileDialingCodeName="entity_person_mobile_dialing_code"
                            mobileId=""
                            mobileName="entity_person_mobile"
                            mobileLabel={`${textIntl.formatMessage(intl.onBoardingStep13YourDetailMobileLabel)}`}
                            mobileValue={formData.entity_person_mobile}
                            phoneNumberId=""
                            required
                            errorText=""
                            phoneNumberName="entity_person_phone_number"
                            onChange={(e) => { onChange(e, index); }}
                        // helperTextReg={`${textIntl.formatMessage(intl.onBoardingStep13VerificationLabel)}`}
                        />
                    </div>
                </Grid>
            </Grid >
        );
    };

    return (<>
        <div className={classes.root}>

            <Grid container justifyContent="center" className={classes.content}>
                {
                    isLoading && <Loader />
                }

                {!isAddBeneficialOwner &&

                    < Grid item xs="11" md="8">
                        <form onSubmit={onContinuePress} >
                            <Typography variant={smDownMatches ? "h6" : "h5"} color="grey700" className={classes.title}>
                                <FormattedMessage {...intl.onBoardingStep14TitleNew} />
                            </Typography>

                            <Typography variant="subtitle1" color="grey400" className={classes.subTitle}>
                                <FormattedMessage {...intl.onBoardingStep14SubtitleBreakNew} />
                            </Typography>


                            <Grid container justifyContent="center" className={classes.aboveMiddleContent}>
                                <Grid item xs="12" md="12" className={classes.middleContent}>
                                    <Grid container justifyContent="center" className={classes.margin}>
                                        {getCustomerDetailsState && getCustomerDetailsState.data && (controllingPartyArray.length > 0 || !getCustomerDetailsState.loading) && customerDetail.entity_persons && customerDetail.entity_persons.length > 0 ?
                                            customerDetail.entity_persons.map((item, index) => (
                                                <Grid item xs="12" md="9" key={item.entity_person_id}>
                                                    <ControllingPartySquare
                                                        data={item}
                                                        index={index}
                                                        onDeleteParty={(item) => { onDeleteOwners(item) }}
                                                        controllingPartyArray={controllingPartyArray}
                                                        onPressItem={() => { pushItem(item.entity_person_id); }}
                                                        id={item.entity_person_id} title={item.business_use_case_name}
                                                        onChange={(e) => { onChangeControllingParty(e, index, item); }}
                                                    />
                                                </Grid>
                                            )) : <IndustryListSkeleton type="controlling_party" arraySize={4} />
                                        }
                                    </Grid>
                                </Grid>

                                <Grid item xs="12" md="12" onClick={() => { onAddControllingParty("onBoarding") }} className={classes.subContentNone}>
                                    <Typography variant="subtitle1"  >
                                        <FormattedMessage {...intl.onBoardingStep14SubtitleBreakTwo} />
                                    </Typography>
                                </Grid>

                            </Grid>

                            <Grid container className={classes.lastMainGrid}>
                                <GoBack onClick={onBackPress} isLoading={isLoading} />
                                <Grid item className={classes.btnDiv} md="3">
                                    <Button type="submit" className={classes.btnDiv} disabled={isLoading || controllingPartyArray.length !== 1} size="small" >
                                        <div className={classes.align}>
                                            <FormattedMessage {...intl.onBoardingStep6ContinueBtn} />
                                            <NavigateNextIcon />
                                        </div>
                                    </Button>
                                </Grid>
                            </Grid>
                            {getCustomerDetailsState && getCustomerDetailsState.loading && <Loader />}
                        </form>
                    </Grid>}

                {isAddBeneficialOwner &&
                    <form onSubmit={onSubmit} className={classes.root}>
                        {
                            isLoading && <Loader />
                        }

                        <Grid container justifyContent="center">
                            <Grid item xs="11" >

                                <Typography variant={"h5"} color="grey700" className={classes.title}>
                                    <FormattedMessage {...intl.onBoardingStep10TitleNew} />
                                </Typography>

                                <Typography variant="subtitle1" color="grey400" className={classes.subTitle}>
                                    <FormattedMessage {...intl.onBoardingStep10SubTitleNew} />
                                </Typography>

                                {/* <DisclosingBeneficialOwnerDialog ref={discloseOwner} /> */}
                                <Grid container justifyContent="center" className={classes.beneficialContent}>
                                    <Grid container justifyContent="center">
                                        {beneficialOwnerContent()}
                                    </Grid>

                                </Grid>

                                <Grid container className={classes.lastMainGrid} >
                                    <GoBack onClick={onBack} isLoading={isLoading} />
                                    <Grid >
                                        <Button size="small" type="submit">
                                            <div className={classes.align}>
                                                <FormattedMessage {...intl.onBoardingStep10ContinueBtn} />
                                                <NavigateNextIcon />
                                            </div>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Grid>
        </div>
    </>);
}

const mapStateToProps = createStructuredSelector({
    saveControllingPartyState: saveControllingPartyState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    tempRegistrationDataState: tempRegistrationDataState(),
    addBeneficialOwnerState: addBeneficialOwnerState(),
    deleteBeneficialOwnerState: deleteBeneficialOwnerState(),
    saveControllingPartyWithBeneficialOwnerState: saveControllingPartyWithBeneficialOwnerState(),
});

function mapDispatchToProps(dispatch) {
    return {
        saveControllingParty: (data) => dispatch(saveControllingPartyAction(data)),
        saveControllingPartyWithBeneficialOwnerAction: (data) => dispatch(saveControllingPartyWithBeneficialOwnerAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
        getCustomerDetailsAction: (data) => dispatch(getCustomerDetailsAction(data)),
        addBeneficialOwnerAction: (data) => dispatch(addBeneficialOwnerAction(data)),
        deleteBeneficialOwnerAction: (data) => dispatch(deleteBeneficialOwnerAction(data)),

    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(ControllingPartyNew);