import React, { useState, useEffect, memo } from 'react';
import { makeStyles, useMediaQuery, useTheme, /*useMediaQuery,useTheme */ } from "@material-ui/core";
import VerificationOne from 'assets/images/verificationOne.png';
import { Icon, Typography, Button } from "components/ui";
import { Loader } from "components";
import { BrightnessLowOutlined } from "@material-ui/icons";
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
import MonochromePhotosIcon from '@material-ui/icons/MonochromePhotos';
import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { personalDetailsFromIdVerificationAction, refreshIdVerificationTokenAction } from 'store/Verification/verificationActions';
import { personalDetailsFromIdVerificationState, refreshIdVerificationTokenState } from 'store/Verification/verificationSelectors';
// import BerbixVerify from 'berbix-react';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/Verification/Intl/VerificationIntl';
import AlloyVerify from './AlloyVerify';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        backgroundColor: 'white',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.appHeaderHeight})`,
    },
    container: {
        // margin: 'auto',
        // padding: theme.spacing(3),
    },
    bodyTxt: {
        marginBottom: theme.spacing(3),
    },
    subTxt: {
        color: theme.palette
    },
    verificationImg: {
        margin: theme.spacing(0, 0, 1, 0)
    },
    iconsDiv: {
        textAlign: "center",
        // margin: theme.spacing(3)
    },
    iconsDiv1: {
        textAlign: "left",
        margin: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'flex-start'
    },

    eachIcon: {
        fontSize: '22px',
        margin: theme.spacing(0, 1, 0, 0),
        color: theme.palette.grey500
    },
    nextIcons: {
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(4.5, 0)
    },
    iconActive: {
        color: "#808080",
        cursor: 'pointer'
    },
    iconDisabled: {
        color: "#F2F3F4",
        cursor: 'pointer'
    },
    carouselContent: {
        display: 'flex',
        flexDirection: 'column',

    },
    centerWrapDiv: {
        display: 'inline-block',
        textAlign: 'left'
    },
    img1: {
        height: theme.spacing(22.75),
        width: theme.spacing(22.75)
    },
    img2: {
        height: theme.spacing(25.25),
        width: theme.spacing(22.5)
    },
    img3: {
        height: theme.spacing(28.75),
        width: theme.spacing(30.25)
    },
    centerDot: {
        margin: theme.spacing(0, 1)
    },
    btnDiv: {
        margin: theme.spacing(4, 2, 2, 2),
        // maxWidth: theme.spacing(50)
    },
    validTxt: {
        margin: theme.spacing(3, 0, 2, 0),
        color: theme.palette.hyperlink.main
    },
    accent: {
        color: theme.palette.accent.main,
        margin: theme.spacing(1)
    },
    btn: {
        width: '75%',
        [theme.breakpoints.down('md')]: {
            width: '50%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    infoRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(4, 0)
    },
    roundDiv: {
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
        borderRadius: theme.spacing(2.5),
        alignItems: 'end',
        display: 'flex',
        justifyContent: 'center',
        border: `2px dashed  ${theme.palette.grey100}`,
        backgroundColor: theme.palette.grey50
    },
    roundRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    roundTxt: {
        marginTop: theme.spacing(2)
    },
    verticalLine: {
        height: '2px',
        backgroundColor: theme.palette.grey200,
        width: theme.spacing(3.75),
        margin: theme.spacing(0, 0, 0, 0),
        display: 'flex',
        alignSelf: 'flex-start',
        marginTop: theme.spacing(2.5)
    },
    txtRoot: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    rootCenter: {
        display: 'flex',
        flexDirection: 'column'
    },
    berbixRoot: {
        margin: theme.spacing(3, 0),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(3, 2),
        }
    }
}));

function IdVerificationInstruction(props = {}) {
    const classes = useStyles();
    const { isModal, getPersonalDetails, data, onStepChange, refreshIdVerificationTokenState,
        // refreshIdVerificationToken,
        isLoading,
        // personalDetailsFromIdVerification,
        personalDetailsFromIdVerificationState } = props;
    const [startIdVerification, setStartIdVerification] = useState(false);
    const [isLoadingUi, setIsLoadingUi] = useState(false);
    // const clientToken = refreshIdVerificationTokenState?.data?.clientToken;
    const journeyApplicationToken = data?.id_verification_check?.response?.tokens?.journey_application_token || '';
    const theme = useTheme();
    const isLoadingData = (isLoading || refreshIdVerificationTokenState?.loading || isLoadingUi || personalDetailsFromIdVerificationState?.loading);

    useEffect(() => {
        analyticsTrackApi({ event: ANALYTICS.EVENTS.ID_VERIFICATION_PAGE_LOAD.event });
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        document.body.style.zoom = "100%";
    }, [isLoadingUi]);

    const mdMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));

    const successTokenGenerate = () => {
        setStartIdVerification(true);
        setIsLoadingUi(true);
        setTimeout(() => {
            setIsLoadingUi(false);
        }, [30000]);
        // window.open(verificationLink, '_self');
    };

    const onComplete = () => {
        // personalDetailsFromIdVerification({
        //     personId: data.entity_person_id,
        //     cb: onStepChange,
        // });
        onStepChange();
    }

    const handleNext = () => {
        analyticsTrackApi({ event: ANALYTICS.EVENTS.ID_VERIFICATION_BEGIN_CLICK.event });
        successTokenGenerate();
        // refreshIdVerificationToken({ personId: data.entity_person_id, token: data.continue_signup_token, cb: successTokenGenerate });
    };


    const PageOne = () => {
        return (
            <div>
                <div className={classes.verificationImg}>
                    <img className={classes.img1} alt="ID Verification" src={VerificationOne} />
                </div>

                <div className={classes.carouselContent}>

                    <Typography color="primary" variant="h6">
                        <FormattedMessage {...intl.verify} />
                    </Typography>
                    <Typography variant="caption" className={classes.accent}>
                        <FormattedMessage {...intl.using_passport} />
                    </Typography>
                    <div className={classes.rootCenter} >
                        <div className={classes.infoRoot} >
                            <div className={classes.roundRoot}>
                                <div className={classes.roundDiv}>
                                    <Typography color="grey600" variant="subtitle1" ><FormattedMessage {...intl.one} /></Typography>
                                </div>
                                <Typography className={classes.roundTxt} color="grey700" variant="caption" ><FormattedMessage {...intl.scanId} /></Typography>
                            </div>
                            <div className={classes.verticalLine} ></div>
                            <div className={classes.roundRoot}>
                                <div className={classes.roundDiv}>
                                    <Typography color="grey600" variant="subtitle1" ><FormattedMessage {...intl.two} /></Typography>
                                </div>
                                <Typography className={classes.roundTxt} color="grey700" variant="caption" ><FormattedMessage {...intl.takeSelfie} /></Typography>
                            </div>
                            <div className={classes.verticalLine} ></div>
                            <div className={classes.roundRoot}>
                                <div className={classes.roundDiv}>
                                    <Typography color="grey600" variant="subtitle1" ><FormattedMessage {...intl.three} /></Typography>
                                </div>
                                <Typography className={classes.roundTxt} color="grey700" variant="caption" ><FormattedMessage {...intl.addInfo} /></Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.iconsDiv}>
                        <div className={classes.centerWrapDiv}>
                            <div className={classes.iconsDiv1}>
                                <Icon className={classes.eachIcon} component={ChromeReaderModeOutlinedIcon} />
                                <Typography color="grey500" variant="body1">
                                    <FormattedMessage {...intl.idMust} />
                                </Typography>

                            </div>

                            <div className={classes.iconsDiv1}>
                                <Icon className={classes.eachIcon} component={MonochromePhotosIcon} />
                                <Typography color="grey500" variant="body1">
                                    <FormattedMessage {...intl.pose} />
                                </Typography>
                            </div>

                            <div className={classes.iconsDiv1}>
                                <Icon className={classes.eachIcon} component={BrightnessLowOutlined} />
                                <div>
                                    <Typography color="grey500" variant="body1">
                                        <span>
                                            <FormattedMessage {...intl.photograph} />
                                        </span>
                                    </Typography>
                                    <Typography color="grey500" variant="body1">
                                        <span>
                                            <FormattedMessage {...intl.noShadow} />
                                        </span>
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.iconsDiv1}>
                                <Icon className={classes.eachIcon} component={ErrorOutlineOutlinedIcon} />
                                <Typography color="grey500" variant="body1">
                                    <FormattedMessage {...intl.noPhoto} />
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    const onDisplay = () => {
        setIsLoadingUi(false)
    }
    if (startIdVerification) {
        return (<div className={classes.berbixRoot} >
            {/* {isLoadingData && <Loader />} */}
            {/* {isLoadingUi && <Loader />} */}
            <AlloyVerify
                journeyApplicationToken={journeyApplicationToken}
                onComplete={onComplete}
                onDisplay={onDisplay}
                isModal={isModal}
                getPersonalDetails={getPersonalDetails}
            />
            {/* <BerbixVerify
                clientToken={clientToken}
                onComplete={berbixCallbackHandler}
                onDisplay={onVisibleBerbixUi}
            /> */}
            </div>)
    }

    return (<div className={classes.root}>
        {isLoadingData && <Loader />}
        <div className={classes.container}>
            {PageOne()}
            <div className={classes.btnDiv} >
                <Button
                    disabled={isLoadingData}
                    className={classes.btn}
                    title={mdMatches ? "Continue" : "Begin"}
                    variant={"contained"}
                    color={"secondary"}
                    onClick={handleNext}
                />
            </div>
        </div>
    </div>)
}

const mapStateToProps = createStructuredSelector({
    refreshIdVerificationTokenState: refreshIdVerificationTokenState(),
    personalDetailsFromIdVerificationState: personalDetailsFromIdVerificationState(),
});

function mapDispatchToProps(dispatch) {
    return {
        refreshIdVerificationToken: data => dispatch(refreshIdVerificationTokenAction(data)),
        personalDetailsFromIdVerification: data => dispatch(personalDetailsFromIdVerificationAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(IdVerificationInstruction);
