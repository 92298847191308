/* eslint-disable react/no-string-refs */
import { IconButton } from '@material-ui/core';
import React, { useState, useRef, useEffect, memo } from 'react';
import FileUploadProgress from 'react-fileupload-progress';
import { getAccessToken, getEntityId, } from 'utils/auth';
import PublishIcon from '@material-ui/icons/Publish';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Typography } from './ui';
import theme from 'theme';
const styles = {
  progressWrapper: {
    height: '40px',
    // width: '245px',
    width: '100%',
    float: 'left',
    overflow: 'hidden',
    // backgroundColor: 'rgb(245, 245, 245)',
    // color: '#fff',
    // fontFamily: 'Avenir',
    cursor: 'pointer',
    textAlign: 'center',
    border: '1px solid #D2E8F1',
    borderRadius: '100px',
  },
  progressBar: {
    // float: 'left',
    width: '0',
    height: '100%',
    // color: '#fff',
    textAlign: 'center',
    backgroundColor: '#D2E8F1',
    // WebkitBoxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
    // boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
    WebkitTransition: 'width .6s ease',
    Otransition: 'width .6s ease',
    transition: 'width .6s ease',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancelButton: {
    marginTop: '-5px',
    WebkitAppearance: 'none',
    cursor: 'pointer',
    background: '0 0',
    border: 0,
    // float: 'left',
    fontSize: '21px',
    fontWeight: 700,
    lineHeight: 1,
    color: '#000',
    textShadow: '0 1px 0 #fff',
    filter: 'alpha(opacity=20)',
    opacity: '.2',
    padding: '8px',
  },
  centerFix: {
    paddingTop: theme.spacing(0.625)
  }
};
function FileUploadWithProgress(props = {}) {
  const { url, method, formCustomizer, name, onChange, value, btnName = "Upload File",
    onFileError, required, disabled, id = `${+new Date()}`, key = "ex1" } = props;

  const [progressCount, setProgressCount] = useState(value ? 100 : 0);
  const [selectedFile, setSelectedFile] = useState({});
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorText, setErrorText] = useState('Required');
  const inputRef = useRef(null);

  useEffect(() => {
    if (value) {
      setProgressCount(100);
    }
  }, [value]);

  const onFileChange = (e, uploadHandler) => {
    setError(false);
    setErrorText('Required');
    const { files } = e.target || {};
    const file = files[0] || {};
    onChange({ target: { name, value } }); // to clear the error;
    onFileError('');
    setSelectedFile(file);
    if (file.name) {
      uploadHandler(e);
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    } else {
      setProgressCount(value ? 100 : 0);
    }
  };
  const style = {
    // height: '28px',
    // width: '245px',
    // fontFamily: 'Avenir',
    cursor: 'pointer',
    textAlign: 'center',
  };

  const onInvalid = (e) => {
    e.preventDefault && e.preventDefault();
    setError(false);
    setTimeout(() => {
      setError(true);
      if (props && props.onFocusRef) {
        props.onFocusRef(props.name, props.id);
      }

    }, 300);
  };

  const disabledStyle = props.disabled ? {
    backgroundColor: 'rgb(242, 242, 243)',
    border: '1px solid rgb(203, 203, 203)',
    color: '#545454',
    cursor: 'no-drop',
  } : {};

  return (<div style={{ display: 'flex', flex: 1 }}>
    <div style={{ flex: 1 }}>
      <FileUploadProgress
        id={id}
        key={key}
        method={method || 'POST'}
        url={url}
        onProgress={(e, request, progress) => {
          setProgressCount(progress);
        }}
        onLoad={(e, request) => {
          if (request.status === 200) {
            const resp = JSON.parse(request.response);
            // const documentId = resp.res && resp.res.insertId || '';
            const documentId = (resp.data && resp.data.document_id) || '';
            onChange({ target: { name, value: selectedFile.name || '' }, documentId, id: id, response: resp.data });
          }
        }}
        onError={() => {
          setProgressCount(value ? 100 : 0);
          setError(true);
          setErrorText(`${selectedFile.name} - Failed to upload, Try again`);
          onFileError(`${selectedFile.name} - Failed to upload, Try again`);
          onChange({ target: { name, value } });
        }}
        onAbort={() => {
          setProgressCount(value ? 100 : 0);
        }}
        formRenderer={onSubmit => (
          <div>
            <input
              id={`input_file_${id}`}
              style={{ display: 'none' }}
              type="file"
              onChange={e => {
                onFileChange(e, onSubmit);
              }}
              name={name}
              required={!value && required}
              // required={required}
              disabled={disabled}
              onInvalid={onInvalid}
              ref={inputRef}
            />
            {
              (progressCount === 0 && !value) ? <div style={{ flex: 1 }}><div><label
                style={{
                  ...style,
                  ...{
                    cursor: 'pointer',
                    textAlign: 'center',
                    color: '#1e1e1e',
                    padding: error ? '5px' : '4px',
                    borderRadius: '100px',
                    border: error ? '1px solid #e32e21' : '2px solid #1e1e1e',
                    display: 'block'
                  },
                  ...disabledStyle,
                }}
                htmlFor={`input_file_${id}`}
              >
                <Typography variant="button">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div><PublishIcon /></div>
                    <div style={{ marginLeft: '8px' }} >{btnName}</div>
                  </div>
                </Typography>
              </label></div>
                {/* {error && <div style={{ color: '#e32e21', textAlign: 'left', margin: '10px 14px 0' }}>{errorText}</div>} */}
              </div> : null}
            {progressCount === 100 && value ? <div style={{ flex: 1 }}><div><label
              style={{
                ...style,
                ...{
                  height: '40px',
                  backgroundColor: '#D2E8F1',
                  padding: '4px',
                  // border: '1px solid #3B99FB',
                  borderRadius: '100px',
                  display: 'flex',
                  flex: 1
                },
                ...disabledStyle,
              }}
              htmlFor={`input_file_${id}`}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                <div><Typography variant="button"><CheckCircleOutlineIcon style={styles.centerFix} /></Typography></div>
                <div><Typography style={styles.centerFix} variant="body2"><span>&nbsp;{value}</span></Typography></div>
                <div style={{
                  alignSelf: 'center',
                  textAlign: 'right',
                  justifySelf: 'right',
                  flex: 1,
                }}><CloseIcon style={{ ...styles.centerFix, paddingRight: '6px' }} /></div>
              </div>

            </label>
            </div>
            </div>
              : null
            }
          </div>)}

        formGetter={() => new FormData()}

        progressRenderer={(progress, hasError, cancelHandler) => {
          if (hasError) {
            setError(true);
          }
          if (!error && progress > -1) {
            const barStyle = { ...styles.progressBar };
            barStyle.width = `${progress}%`;

            return (<div>
              {(progress > 1 && progress < 100) || (progress === 100 && !value) ? <div style={{
                border: '2px solid #1e1e1e',
                borderRadius: '100px'
              }}>
                <div className="_react_fileupload_progress_content">
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <div style={styles.progressWrapper}>
                      <div className="_react_fileupload_progress_bar" style={barStyle}><Typography variant="body2">Uploading...</Typography></div>
                    </div>
                    <div>
                      <IconButton className="_react_fileupload_progress_cancel" onClick={cancelHandler} size="small">
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div></div> : null}

            </div>);
          }
          return '';
        }}

        // formCustomizer={formCustomizer}
        formCustomizer={form => {
          form.set('file', selectedFile);
          return formCustomizer(form);
        }}
        beforeSend={request => {
          const a = getAccessToken();
          const e = getEntityId();
          a && request.setRequestHeader('Authorization', a);
          e && request.setRequestHeader('X-Entity-Id', e);
          return request;
        }}
      />
    </div>
  </div>);
}

export default memo(FileUploadWithProgress);
