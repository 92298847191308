const CONTACT = {
    code: "CONTACT",
    name: "Contact",
    path: "/contacts",
    functions: { 
        CONTACT_LIST: {
            code: "CONTACT_LIST",
            name: "List Contacts",
            isPage: true,
            component: 'ContactListPage',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_LIST.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_LIST.path}`,
            preview: {
                code: "CONTACT_PREVIEW",
                name: "CONTACT Preview",
                isPage: true,
                component: 'ContactListPage',
                path: "/:id",
                getRoutePath: () => [`${CONTACT.path}${CONTACT.functions.CONTACT_LIST.path}`,`${CONTACT.path}${CONTACT.functions.CONTACT_PREVIEW.path}`],
                getFullPath: (id) => `${CONTACT.path}${CONTACT.functions.CONTACT_PREVIEW.path}`.replace(
                    ":id",
                    id
                )
        }
        },
        CONTACT_SEARCH: {
            code: "CONTACT_SEARCH",
            name: "Search Contacts",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEARCH.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEARCH.path}`,
        },
        CONTACT_CREATE: {
            code: "CONTACT_CREATE",
            name: "Add Contacts",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_CREATE.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_CREATE.path}`,
        },
        CONTACT_PREVIEW: {
            code: "CONTACT_PREVIEW",
            name: "Preview Contact",
            isPage: false,
            component: '',
            path: "/:id",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_DETAILS.path}`,
            getFullPath: (id) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_DETAILS.path}`.replace(
                    ":id",
                    id
                ),
        },
        CONTACT_DETAILS: {
            code: "CONTACT_DETAILS",
            name: "Details Contact",
            isPage: true,
            component: 'ContactDetailsPage',
            path: "/:id/details",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_DETAILS.path}`,
            getFullPath: (id) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_DETAILS.path}`.replace(
                    ":id",
                    id
                ),
        },
        CONTACTS_UPDATE: {
            code: "CONTACTS_UPDATE",
            name: "Edit Contact",
            isPage: false,
            component: '',
            path: "/:contactId",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACTS_UPDATE.path}`,
            getFullPath: (contactId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACTS_UPDATE.path}`.replace(
                    ":contactId",
                    contactId
                ),
        },
        CONTACTS_DELETE: {
            code: "CONTACTS_DELETE",
            name: "Delete Contact",
            isPage: false,
            component: '',
            path: "/:contactId",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACTS_DELETE.path}`,
            getFullPath: (contactId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACTS_DELETE.path}`.replace(
                    ":contactId",
                    contactId
                ),
        },
        CONTACT_SEND_PAYMENTS_CREATE: {
            code: "CONTACT_SEND_PAYMENTS_CREATE",
            name: "Create Send Payments",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENTS_CREATE.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENTS_CREATE.path}`,
        },
        CONTACT_SEND_PAYMENTS_DETAILS: {
            code: "CONTACT_SEND_PAYMENTS_DETAILS",
            name: "Send Payments Details",
            isPage: false,
            component: '',
            path: "/:paymentId",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENTS_DETAILS.path}`,
            getFullPath: (paymentId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENTS_DETAILS.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },
        CONTACT_SEND_PAYMENTS_DELETE: {
            code: "CONTACT_SEND_PAYMENTS_DELETE",
            name: "Send Payments Delete",
            isPage: false,
            component: '',
            path: "/:paymentId",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENTS_DELETE.path}`,
            getFullPath: (paymentId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENTS_DELETE.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },
        CONTACT_SEND_PAYMENT_NOW: {
            code: "CONTACT_SEND_PAYMENT_NOW",
            name: "Send Payment Now",
            isPage: false,
            component: '',
            path: "/:payment_id/send-now",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENT_NOW.path}`,
            getFullPath: (paymentId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENT_NOW.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },

        CONTACT_SEND_PAYMENT_PRE_SEND_NOW: {
            code: "CONTACT_SEND_PAYMENT_PRE_SEND_NOW",
            name: "Pre Send Payment Now",
            isPage: false,
            component: '',
            path: "/:payment_id/pre-send-now",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENT_PRE_SEND_NOW.path}`,
            getFullPath: (paymentId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_SEND_PAYMENT_PRE_SEND_NOW.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },
        CONTACT_PRE_SEND_PAYMENT: {
            code: "CONTACT_PRE_SEND_PAYMENT",
            name: "Contact Pre Send Payment",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_PRE_SEND_PAYMENT.path}`,
            getFullPath: (paymentId) =>
                `${CONTACT.path}${CONTACT.functions.CONTACT_PRE_SEND_PAYMENT.path}`.replace(
                    ":paymentId",
                    paymentId
                ),
        },
        CONTACT_PAYMENTS_SHORT_REF: {
            code: "CONTACT_PAYMENTS_SHORT_REF",
            name: "Send Payment Short Ref",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_PAYMENTS_SHORT_REF.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_PAYMENTS_SHORT_REF.path}`,
        },

        CONTACT_PAYMENTS_SHORT_REF_CREATE: {
            code: "CONTACT_PAYMENTS_SHORT_REF_CREATE",
            name: "Create Send Payment Short Ref",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_PAYMENTS_SHORT_REF_CREATE.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.CONTACT_PAYMENTS_SHORT_REF_CREATE.path}`,
        },
        TAGS_LIST: {
            code: "TAGS_LIST",
            name: "List Tags",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.TAGS_LIST.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.TAGS_LIST.path}`,
        },
        SEND_PAYMENTS_CURRENCIES: {
            code: "SEND_PAYMENTS_CURRENCIES",
            name: "Send Payment Currencies",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CONTACT.path}${CONTACT.functions.SEND_PAYMENTS_CURRENCIES.path}`,
            getFullPath: () => `${CONTACT.path}${CONTACT.functions.SEND_PAYMENTS_CURRENCIES.path}`,
        },
    },
};

export default CONTACT;
