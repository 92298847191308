/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useCallback, useState } from 'react';
// import PropTypes from 'prop-types';
import { Checkbox, IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { TextField } from './ui';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  hintDiv: {
    position: "absolute",
    zIndex: 999,
    background: "white",
    padding: "8px",
    margin: "-4px -14px",
    border: "1px solid #f2f3f4",
    borderTop: "none",
    width: "100%",
  },
  errorDiv: {
    padding: "8px",
    margin: "-4px -14px",
    border: "1px solid #f2f3f4",
    borderTop: "none",
  },
  checkboxRoot: {
    padding: '0 !important',
  },
  policyDiv: {
    color: 'gray',
  }
}));

function ConfirmNewPasswordInput(props = {}) {
  // const commonClasses = useCommonStyles(props);
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles(props);
  const { id, name, compareWith, onChange = () => null, label } = props;
  const [password, setPassword] = useState(props.value || '');
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  
  const handleClickShowPassword =(e) => {
    setShowPassword(!showPassword)
  };
  
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  
  const handleChange = useCallback((e) => {
    const { value } = e.target || {};
    setPassword(value);
    onChange(e);
  }, []);
  const onFocus = (e) => {
    setIsFocused(true)
  };
  const onBlur = (e) => {
    setIsFocused(false)
  };
  
  const HelperText = ({error}) => { 
    if(error && !password) return 'Required';
    const isMatched = password && compareWith && password === compareWith ? true : false;
    return (<div className={error ? classes.errorDiv : classes.hintDiv}>
    <div className={isMatched && classes.policyDiv}>
      <Checkbox
        className={classes.checkboxRoot}
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        checked={isMatched}
        name="one"
      /> Match with password
    </div>

  </div>)};

  return (<>
    <TextField type={showPassword ? 'text': 'password'}
      id={id} name={name}
      // errorText="Password must be 8+ characters, include at least 1 Number, 1 Uppercase letter, 1 Lowercase letter and 1 Special character"
      errorText={<HelperText error />}
      helperText={(isFocused && <HelperText />) || null}
      value={password}
      label={label}
      placeholder={props.placeholder}
      required
      inputProps={{
        onBlur:onBlur
      }}
      onFocus={onFocus}
      disableOnTypeValidation
      InputProps={{
        endAdornment:(
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>)
      }}
      onChange={handleChange}
    />
  </>);
}

ConfirmNewPasswordInput.defaultProps = {
};

ConfirmNewPasswordInput.propTypes = {
};

export default memo(ConfirmNewPasswordInput);
