import { ADDRESS_TYPE } from "@truly-financial/common/consts/address.consts";
import { ENTITY_PERSON_STATUS, ENTITY_PERSON_TYPE } from "@truly-financial/common/consts/entity-person.consts";
import moment from "moment";
import { ID_VERIFICATION_STEPS, removeNullKeys } from "utils/common";

export const getTeamMemberListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r && r.data && r.data.map(i => {
        const ps = [];
        if (i.role_id === 4) {
            ps.push("Accountant Viewer");
        }
        if (i.role_id === 5) {
            ps.push("Accountant Admin");
        }
        if (i.role_id === 1) {
            ps.push("Admin");
        }
        if (i.role_id === 3) {
            ps.push("View Only");
        }
        if (i.role_id === 2 || i.smartlink_account_id) {
            ps.push("SmartSpend");
        }
        if (i.role_id === null) {
            ps.push("No Access");
        }
        return ({
            ...i,
            prs_display_primary_name: i.entity_person_first_name + " " + i.entity_person_last_name,
            first_name: i.entity_person_first_name,
            last_name: i.entity_person_last_name,
            prs_access: (i.entity_person_status === ENTITY_PERSON_STATUS.ENABLED || i.entity_person_status === ENTITY_PERSON_STATUS.NEW_RECORD) ? ps : "",
            prs_status: [ENTITY_PERSON_STATUS.NEW_RECORD, ENTITY_PERSON_STATUS.INVITATION_SENT].includes(i.entity_person_status) ? "PENDING" : [ENTITY_PERSON_STATUS.DISABLED].includes(i.entity_person_status) ? "DEACTIVATED" : "ENABLE"
        });
    }
    );

    parsedResponse.data = d;
    return parsedResponse;
};
export const teamMemberDetailParser = (r = {}) => {
    const parsedResponse = { ...r };
    const data = r.data || {};
    const ps = [];
    if (data.role_id === 1) {
        ps.push("Admin");
    }
    if (data.smartlink_account_id) {
        ps.push("SmartSpend");
    }
    parsedResponse.data = {
        ...data,
        prs_company_display_name: data.entity_display_name,
        prs_image_url: data.entity_person_image_url,
        prs_name_initials: `${typeof data.entity_person_first_name === 'string' && data.entity_person_first_name.length ? data.entity_person_first_name[0].toUpperCase() : ''}${typeof data.entity_person_last_name === 'string' && data.entity_person_last_name.length ? data.entity_person_last_name[0].toUpperCase() : ''}`,
        prs_display_name: `${data.entity_person_first_name || ''} ${data.entity_person_middle_name || ''} ${data.entity_person_last_name || ''}`,
        prs_country_code: data.entity_person_country_code || data.entity_person_mobile_country_code,
        prs_address_communication: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.COMMUNICATION)) || {},
        prs_address_billing: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.BILLING)) || {},
        prs_access: data.entity_person_status === ENTITY_PERSON_STATUS.ENABLED ? ps : "",
        prs_smart_link_id: data && data.smartlink_account_id ? data.smartlink_account_id : "",
        prs_status: [ENTITY_PERSON_STATUS.NEW_RECORD, ENTITY_PERSON_STATUS.INVITATION_SENT].includes(data.entity_person_status) ? "PENDING" : [ENTITY_PERSON_STATUS.DISABLED].includes(data.entity_person_status) ? "DEACTIVATED" : "ENABLE"
    };

    return parsedResponse;
};

export const addUpdateTeamMemberRequestParser = (data = {}) => {
    const { id, cb, body = {}, formdata = {}, entityPersonId } = data;
    let smartlink_account = null;

    if (body.smartlink_account.enable_smartlink) {
        smartlink_account = {
            nick_name: body.smartlink_account.nick_name || null,
            parent_account_id: body.smartlink_account.parent_account_id || null,
            secondary_parent_account_id: body.smartlink_account.secondary_parent_account_id || null,
            monthly_limit: body.smartlink_account.monthly_limit || null,
            per_transaction_limit: body.smartlink_account.per_transaction_limit || null,
            daily_limit: body.smartlink_account.daily_limit || null,
            enable_send_payment: body.smartlink_account.enable_send_payment || null,
            enable_e_wallet_capability: body.smartlink_account.enable_e_wallet_capability || null,
            enable_virtual_card_issuance: body.smartlink_account.enable_virtual_card_issuance || null,
            mail_physical_card: body.smartlink_account.mail_physical_card || null,
        }
    }

    let parsedData = {
        id,
        entityPersonId,
        cb: cb || (() => null),
        body: removeNullKeys({
            role_id: body.role_id || null,
            entity_person_first_name: body.entity_person_first_name || null,
            entity_person_middle_name: body.entity_person_middle_name || null,
            entity_person_last_name: body.entity_person_last_name || null,
            entity_person_email: body.entity_person_email || null,
            entity_person_country_of_residence: body.entity_person_country_of_residence || null,
            entity_person_mobile_country_code: body.entity_person_mobile_country_code || null,
            entity_person_mobile: body.entity_person_mobile || null,
            contact_person_position: body.contact_person_position || null,
            entity_person_id: body.entity_person_id || null,
            entity_person_type: body.entity_person_type || null,
            smartlink_account,
        }),
    };

    if (formdata.file && Object.keys(formdata.file).length) {
        parsedData.formdata = removeNullKeys({
            file: formdata.file,
            file_name: formdata.file_name,
        });
    }

    return parsedData;

};

export const selfUpdateTeamMemberRequestParser = (data = {}) => {
    const { teamMemberId, token, cb, body = {} } = data;
    let { mailingAddress = {}, billingAddress = {}, residentialAddress = {} } = body;
    mailingAddress = removeNullKeys({
        address_id: mailingAddress.address_id || null,
        address_type: mailingAddress.address_id ? null : ADDRESS_TYPE.COMMUNICATION,
        address_line_1: mailingAddress.address_line_1,
        address_line_2: mailingAddress.address_line_2,
        landmark: mailingAddress.landmark,
        city: mailingAddress.city,
        state_code: mailingAddress.state_code,
        state_name: mailingAddress.state_name,
        postal_code: mailingAddress.postal_code,
        country_code: mailingAddress.country_code,
    });
    billingAddress = removeNullKeys({
        address_id: billingAddress.address_id || null,
        address_type: billingAddress.address_id ? null : ADDRESS_TYPE.BILLING,
        address_line_1: billingAddress.address_line_1,
        address_line_2: billingAddress.address_line_2,
        landmark: mailingAddress.landmark,
        city: billingAddress.city,
        state_code: billingAddress.state_code,
        state_name: billingAddress.state_name,
        postal_code: billingAddress.postal_code,
        country_code: billingAddress.country_code,
    });
    residentialAddress = removeNullKeys({
        address_id: residentialAddress.address_id || null,
        address_type: residentialAddress.address_id ? null : ADDRESS_TYPE.PRIMARY,
        address_line_1: residentialAddress.address_line_1,
        address_line_2: residentialAddress.address_line_2,
        landmark: residentialAddress.landmark,
        city: residentialAddress.city,
        state_code: residentialAddress.state_code,
        state_name: residentialAddress.state_name,
        postal_code: residentialAddress.postal_code,
        country_code: residentialAddress.country_code,
    });
    let addresses = [];
    if (Object.keys(mailingAddress).length > 1) {
        addresses.push(mailingAddress);
    }
    if (Object.keys(billingAddress).length > 1) {
        addresses.push(billingAddress);
    }
    if (Object.keys(residentialAddress).length > 1) {
        addresses.push(residentialAddress);
    }
    if (!addresses.length) {
        addresses = null;
    }
    let parsedData = {
        teamMemberId,
        token,
        cb: cb || (() => null),
        body: removeNullKeys({
            entity_person_first_name: body.entity_person_first_name || null,
            entity_person_last_name: body.entity_person_last_name || null,
            entity_person_mobile_country_code: body.entity_person_mobile_country_code || null,
            entity_person_mobile: body.entity_person_mobile || null,
            entity_person_date_of_birth: body.entity_person_date_of_birth ? moment(body.entity_person_date_of_birth).format("YYYY-MM-DD") : null,
            entity_person_gender: body.entity_person_gender || null,
            // entity_person_identification_type: body.social_security_number ? 'SOCIAL_SECURITY_NUMBER' : null,
            // entity_person_identification_value: body.social_security_number || null,
            contact_person_position: body.contact_person_position || null,
            entity_person_citizenship_country: body.entity_person_citizenship_country || null,
            entity_person_country_of_residence: residentialAddress.country_code || null,
            document_country_of_issuance: body.document_country_of_issuance || null,
            document_expiry_date: body.document_expiry_date || null,
            document_id_number: body.document_id_number || null,
            document_type_id: body.document_type_id || null,
            billing_address_same_as_communication: body.isSameAsMailingAddr,
            communication_address_same_as_primary: body.isSameAsResidentialAddr,
            document_id: body.document_id ? JSON.parse(body.document_id) : null,
            addresses,
        }),
    };


    return parsedData;
};

export const selfGetTeamMemberRequestParser = (r = {}) => {
    const parsedResponse = { ...r };
    const data = r.data || {};
    var currentStep = "";

    parsedResponse.data = {
        ...data,
        // is_smartlink_activation_required: false,
        // is_id_verification_required: true,
        // is_signup_required: true,
        prs_is_account_authorize_accepted: false,
        prs_is_account_agreement_accepted: true,
        prs_is_details_confirm: true,
        prs_address_residential: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.PRIMARY)) || {},
        entity_person_date_of_birth: data.entity_person_date_of_birth ? moment(data.entity_person_date_of_birth).format("YYYY-MM-DD") : '',
        prs_address_communication: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.COMMUNICATION)) || {},
        prs_address_billing: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.BILLING)) || {},
        isSameAsMailingAddr: data.billing_address_same_as_communication,
        isSameAsResidentialAddr: data.communication_address_same_as_primary,
        social_security_number: data.entity_person_identification_value,
    };

    if (data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT) {
        if (data.is_id_verification_required) {
            currentStep = ID_VERIFICATION_STEPS.ID_VERIFICATION;
        } else if (data.prs_is_details_confirm) {
            currentStep = ID_VERIFICATION_STEPS.CONFIRM_DETAILS;
        }
        else if (data.is_signup_required) {
            currentStep = ID_VERIFICATION_STEPS.CREATE_PASSWORD;
        } else {
            currentStep = ID_VERIFICATION_STEPS.COMPLETE;
        }
    } else if (data.entity_person_type === ENTITY_PERSON_TYPE.STAKEHOLDER) {
        if (data.is_id_verification_required && data.is_stakeholder_approval_required) {
            if (!data.prs_is_account_authorize_accepted) {
                currentStep = ID_VERIFICATION_STEPS.CONSENT;
            } else if (data.prs_is_account_agreement_accepted) {
                currentStep = ID_VERIFICATION_STEPS.CONFIDENTIAL_AGREEMENT;
            } else if (data.is_id_verification_required) {
                currentStep = ID_VERIFICATION_STEPS.ID_VERIFICATION;
            } else if (data.prs_is_details_confirm) {
                currentStep = ID_VERIFICATION_STEPS.CONFIRM_DETAILS;
            }
        } else {
            currentStep = ID_VERIFICATION_STEPS.COMPLETE;
        }
    } else if (data.entity_person_type === ENTITY_PERSON_TYPE.TEAM_MEMBER) {
        if (data.is_id_verification_required) {
            if (data.is_signup_required) {
                currentStep = ID_VERIFICATION_STEPS.CREATE_PASSWORD;
            } else if (!data.is_signup_required) {
                currentStep = ID_VERIFICATION_STEPS.TEAM_MEMBER_INITIAL;
            } else if (data.is_id_verification_required) {
                currentStep = ID_VERIFICATION_STEPS.ID_VERIFICATION;
            } else if (data.prs_is_details_confirm) {
                currentStep = ID_VERIFICATION_STEPS.CONFIRM_DETAILS;
            }
        }

    }

    parsedResponse.data.prs_current_step = currentStep;

    return parsedResponse;
};


