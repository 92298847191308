/* eslint-disable no-unused-vars */
/* eslint-disable require-yield */
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { request, requestWithErrorFeedback, requestWithFeedback, requestWithSuccessFeedback } from 'utils/request';
import {
  // resetTeamMemberAction,
  getTeamMembersSetDataAction,
  addUpdateTeamMemberSetDataAction,
  getTeamMemberDetailsSetDataAction,
  selfUpdateTeamMemberSetDataAction,
  getSelfTeamMemberDetailsSetDataAction,
  generatePasswordSetDataAction,
  createTeamMemberSmartLinkAccountSetDataAction,
  sendInvitationLinkSetDataAction,
  deleteTeamMemberSetDataAction,
  updateTeamMemberAccessSetDataAction,
  idVerificationReceivedSetDataAction,
  submitIdVerificationAcceptTermAndConditionsSetDataAction,
  submitIdVerificationConsentSetDataAction,
  submitIdVerificationAgreementSetDataAction,
  getSelfUserDetailsSetDataAction,
  getBerbixTokenSetDataAction,
  checkTeamMemberExistSetDataAction
} from './teamMemberActions';
import {
  // RESET_TEAM_MEMBER,
  GET_TEAM_MEMBERS,
  ADD_UPDATE_TEAM_MEMBER,
  GET_TEAM_MEMBER_DETAILS,
  SELF_UPDATE_TEAM_MEMBER,
  GET_SELF_TEAM_MEMBER_DETAILS,
  GENERATE_PASSWORD,
  CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT,
  SEND_INVITATION_LINK,
  DELETE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_ACCESS,
  ID_VERIFICATION_RECEIVED,
  SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS,
  SUBMIT_ID_VERIFICATION_CONSENT,
  SUBMIT_ID_VERIFICATION_AGREEMENT,
  GET_SELF_USER_DETAILS, GET_BERBIX_TOKEN,
  CHECK_TEAM_MEMBER_EXIST
} from './teamMemberConstants';

import {
  TEAM_MEMBER_BASE_API_URL,
} from 'config/apiUrls';
import { recordPerPageWeb } from 'utils/constant';

const TEAM_MEMBER_URL = `${TEAM_MEMBER_BASE_API_URL}/team-members`;

export default function* teamMemberSaga() {
  // add all sagas here
  yield takeLatest(ID_VERIFICATION_RECEIVED, idVerificationReceivedSaga);
  yield takeLatest(CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT, createTeamMemberSmartLinkAccountSaga);
  yield takeLatest(GENERATE_PASSWORD, generatePasswordSaga);
  yield takeLatest(GET_SELF_TEAM_MEMBER_DETAILS, getSelfTeamMemberDetailsSaga);
  yield takeLatest(SELF_UPDATE_TEAM_MEMBER, selfUpdateTeamMemberSaga);
  yield takeLatest(GET_TEAM_MEMBER_DETAILS, getTeamMemberDetailsSaga);
  yield takeLeading(ADD_UPDATE_TEAM_MEMBER, addUpdateTeamMemberSaga);
  yield takeLatest(GET_TEAM_MEMBERS, getTeamMembersSaga);
  yield takeLatest(SEND_INVITATION_LINK, sendInvitationLinkSaga);
  yield takeLatest(DELETE_TEAM_MEMBER, deleteTeamMemberSaga);
  yield takeLatest(UPDATE_TEAM_MEMBER_ACCESS, updateTeamMemberAccessSaga);
  yield takeLatest(SUBMIT_ID_VERIFICATION_CONSENT, submitIdVerificationConsentSaga);
  yield takeLatest(SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS, submitIdVerificationAcceptTermAndConditionsSaga);
  yield takeLatest(SUBMIT_ID_VERIFICATION_AGREEMENT, submitIdVerificationAgreementSaga);
  yield takeLatest(GET_SELF_USER_DETAILS, getSelfUserDetailsSaga);
  yield takeLatest(GET_BERBIX_TOKEN, getBerbixTokenSaga);
  yield takeLatest(CHECK_TEAM_MEMBER_EXIST, checkTeamMemberExistSaga);
}

export function* getTeamMembersSaga(args = {}) {
  const { endPoint = "", cb = () => null, page } = args.data || {};
  const skip = page * recordPerPageWeb;
  const requestUrl = `${TEAM_MEMBER_URL}${endPoint ? `${endPoint}&` : '?'}&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getTeamMembersSetDataAction(result, page));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addUpdateTeamMemberSaga({ data }) {
  const { body, formdata, cb = () => null, id, entityPersonId } = data || {};
  let { teamMemberId = "" } = data;
  const requestUrl = `${TEAM_MEMBER_URL}${teamMemberId ? `/${teamMemberId}` : ''}`;
  const requestUrlAsStackHolder = `${TEAM_MEMBER_URL}/${entityPersonId}/update-stakeholder-as-team-member`;
  const method = (teamMemberId || id || entityPersonId ? 'PUT' : 'POST');
  const options = { method, body };
  const uploadImageOptions = { method: 'POST', formdata };
  let result = {};
  try {
    if (Object.keys(body).length) {
      result = yield call(requestWithErrorFeedback, entityPersonId ? requestUrlAsStackHolder : requestUrl, options);
      teamMemberId = (result.data && result.data.entity_person_id) || null;
    }
    if ((teamMemberId) && formdata) {
      result = yield call(requestWithErrorFeedback, `${TEAM_MEMBER_URL}/${teamMemberId}/image`, uploadImageOptions);
      teamMemberId = (result.data && result.data.entity_person_id) || null;
    }
    yield put(addUpdateTeamMemberSetDataAction(result));
    if (result.data && result.data.entity_person_id) cb(result.data.entity_person_id);
  } catch (error) {
    console.error(error);
  }
}

export function* getTeamMemberDetailsSaga(args = {}) {
  const { teamMemberId, cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/${teamMemberId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getTeamMemberDetailsSetDataAction(result));
    // result.data.entity_person_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* selfUpdateTeamMemberSaga(args = {}) {
  const { teamMemberId, token, body, cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/update-details?id=${teamMemberId}&token=${token}`;
  const options = {
    method: 'PUT',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(selfUpdateTeamMemberSetDataAction(result));
    // result.data.entity_person_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getSelfTeamMemberDetailsSaga(args = {}) {
  const { teamMemberId, token, cb = () => null } = args.data || {};
  let requestUrl;
  if (teamMemberId && token) {
    requestUrl = `${TEAM_MEMBER_URL}/public/continue-verification?id=${teamMemberId}&token=${token}`;
  } else {
    requestUrl = `${TEAM_MEMBER_URL}/${teamMemberId}`;
  }
  try {
    const result = yield call(request, requestUrl);
    yield put(getSelfTeamMemberDetailsSetDataAction(result));
    // result.data.entity_person_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* generatePasswordSaga(args = {}) {
  const { teamMemberId, token, body, cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/finish-verification?id=${teamMemberId}&token=${token}`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(generatePasswordSetDataAction(result));
    // result.data.user_account_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* createTeamMemberSmartLinkAccountSaga(args = {}) {
  const { teamMemberId, body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body, };
  const requestUrl = `${TEAM_MEMBER_URL}/${teamMemberId}/smartlink-accounts`;
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    yield put(createTeamMemberSmartLinkAccountSetDataAction(result));
    // result.data.account_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* sendInvitationLinkSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/${id}/resend-invite`;
  const options = {
    method: 'POST'
  };
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    // eslint-disable-next-line eqeqeq
    // result && result.data && result.data.entity_person_id == id && cb();
    result.ok && cb();
    yield put(sendInvitationLinkSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteTeamMemberSaga(args = {}) {
  const { id, endPoint, cb = () => null } = args.data || {};
  const options = { method: "POST" };
  const requestUrl = `${TEAM_MEMBER_URL}/${id}/${endPoint}`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    // eslint-disable-next-line eqeqeq
    // result && result.data && result.data.entity_person_id == id && cb();
    result.ok && cb();
    yield put(deleteTeamMemberSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* updateTeamMemberAccessSaga(args = {}) {
  const { id, body, cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/${id}/roles`;
  const options = {
    method: 'PUT',
    body: body
  };
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    yield put(updateTeamMemberAccessSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* idVerificationReceivedSaga(args = {}) {
  const { teamMemberId, token, body, cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/id-verification-received?id=${teamMemberId}&token=${token}`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(idVerificationReceivedSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* submitIdVerificationConsentSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  // const requestUrl = `${TEAM_MEMBER_URL}/${id}/consent`;
  try {
    // const result = yield call(requestWithSuccessFeedback, requestUrl);
    cb();
    // yield put(submitIdVerificationConsentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* submitIdVerificationAcceptTermAndConditionsSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  // const requestUrl = `${TEAM_MEMBER_URL}/${id}/team-conditions`;
  try {
    // const result = yield call(requestWithSuccessFeedback, requestUrl);
    cb();
    // yield put(submitIdVerificationAcceptTermAndConditionsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* submitIdVerificationAgreementSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/accept-confidentiality-agreement`;
  const options = {
    method: 'POST',
  };
  try {
    const result = yield call(requestWithSuccessFeedback, requestUrl, options);
    cb();
    yield put(submitIdVerificationAgreementSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getSelfUserDetailsSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/personal-details-from-id-verification`;
  try {
    const result = yield call(request, requestUrl);
    result.ok && cb();
    yield put(getSelfUserDetailsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getBerbixTokenSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/refresh-id-verification-token`;
  const options = {
    method: 'POST',
  };
  try {
    const result = yield call(request, requestUrl, options);
    result.ok && cb();
    yield put(getBerbixTokenSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* checkTeamMemberExistSaga(args = {}) {
  const { cb = () => null, email } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/stakeholder-details-from-email?entity_person_email=${email}`;

  try {
    const result = yield call(requestWithErrorFeedback, requestUrl);
    yield put(checkTeamMemberExistSetDataAction(result));
    cb(email, result);
  } catch (error) {
    console.error(error);
  }
}
