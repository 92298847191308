import {
  RESET_CONTACTS,
  GET_CONTACT_LIST, GET_CONTACT_LIST_SET_DATA,
  GET_CONTACT_DETAILS, GET_CONTACT_DETAILS_SET_DATA,
  ADD_UPDATE_CONTACT, ADD_UPDATE_CONTACT_SET_DATA,
  ADD_CONTACT_BANK_ACCOUNT, ADD_BANK_ACCOUNT_SET_DATA,
  ADD_UPDATE_CONTACT_NOTE, ADD_UPDATE_CONTACT_NOTE_SET_DATA,
  ADD_CONTACT_CARD, ADD_CONTACT_CARD_SET_DATA,
  LINK_CONTACT, LINK_CONTACT_SET_DATA,
  GET_CONTACT_PAYMENTS, SET_GET_CONTACT_PAYMENTS_RESPONSE,
  DELETE_CONTACT_NOTE, DELETE_CONTACT_NOTE_SET_DATA,
  DELETE_CONTACT, DELETE_CONTACT_SET_DATA,
  GET_CONTACT_SCHEDULE_PAYMENT_LIST, GET_CONTACT_SCHEDULE_PAYMENT_LIST_SET_DATA,
  GET_CONTACT_NOTES, GET_CONTACT_NOTES_SET_DATA,
  GET_CONTACT_BANK_ACCOUNTS, GET_CONTACT_BANK_ACCOUNTS_SET_DATA,
  DELETE_SCHEDULE_PAYMENT, DELETE_SCHEDULE_PAYMENT_SET_DATA,
  CONTACT_PAYMENT_DETAIL, CONTACT_PAYMENT_DETAIL_SET_DATA,
  GET_CONTACT_SCHEDULE_PAYMENT_DETAILS, GET_CONTACT_SCHEDULE_PAYMENT_DETAILS_SET_DATA,
  GET_CONTACT_NOTES_RESET_DATA, GET_CONTACT_SCHEDULE_PAYMENT_LIST_RESET_DATA,
  GET_CONTACT_PAYMENT_LIST_RESET_DATA, GET_CONTACT_BANK_ACCOUNTS_RESET_DATA,
  GET_CONTACT_LIST_REFRESH,
  IBAN_VALIDATION,
  IBAN_VALIDATION_SET_DATA,
  CONTACT_FULL_LIST,
  CONTACT_FULL_LIST_SET_DATA,
  UPDATE_CONTACT_BANK_ACCOUNT, UPDATE_CONTACT_BANK_ACCOUNT_SET_DATA,
  RESEND_CONTACT_BANK_DETAIL_REQUEST, RESEND_CONTACT_BANK_DETAIL_REQUEST_SET_DATA,
  ARCHIVE_CONTACT_BANK_ACCOUNT, ARCHIVE_CONTACT_BANK_ACCOUNT_SET_DATA,
} from './contactConstants';

import {
  addUpdateContactRequestParser, addUpdateContactDataParser,
  addUpdateContactNoteRequestParser,
  contactPaymentListParser,
  contactListParser,
  contactDetailParser,
  contactScheduledPaymentDetailParser,
  getSchedulePaymentListParser,
  addContactBankAccountRequestParser,
  getContactAccountsParser,
  updateContactBankAccountParser,
  resendContactBankDetailRequestParser,

} from './contactParser';

export function resetContactsAction() {
  return { type: RESET_CONTACTS };
}

export function resetContactsNotesAction() {

  return { type: GET_CONTACT_NOTES_RESET_DATA };
}

export function resetContactsBankAccAction() {

  return { type: GET_CONTACT_BANK_ACCOUNTS_RESET_DATA };
}
export function resetContactsSchedulePaymentAction() {
  return { type: GET_CONTACT_SCHEDULE_PAYMENT_LIST_RESET_DATA };
}

export function resetContactPaymentAction() {
  return { type: GET_CONTACT_PAYMENT_LIST_RESET_DATA };
}

export function getContactListAction(data) {

  return { type: GET_CONTACT_LIST, data };
}
export function getContactListRefreshAction(data) {

  return { type: GET_CONTACT_LIST_REFRESH, data };
}
export function getContactListSetDataAction(data, page) {
  return { type: GET_CONTACT_LIST_SET_DATA, data: contactListParser(data), page };
}

export function getContactDetailsAction(data) {
  return { type: GET_CONTACT_DETAILS, data };
}
export function getContactDetailsSetDataAction(data) {
  return { type: GET_CONTACT_DETAILS_SET_DATA, data: contactDetailParser(data) };
}

export function addUpdateContactAction(data) {
  return { type: ADD_UPDATE_CONTACT, data: addUpdateContactRequestParser(data) };
}
export function addUpdateContactSetDataAction(data) {
  return { type: ADD_UPDATE_CONTACT_SET_DATA, data: addUpdateContactDataParser(data) };
}

export function addContactBankAccountAction(data) {
  return { type: ADD_CONTACT_BANK_ACCOUNT, data: addContactBankAccountRequestParser(data) };
}
export function addContactBankAccountSetDataAction(data) {
  return { type: ADD_BANK_ACCOUNT_SET_DATA, data };
}

export function addUpdateContactNoteAction(data) {
  return { type: ADD_UPDATE_CONTACT_NOTE, data: addUpdateContactNoteRequestParser(data) };
}
export function addUpdateContactNoteSetDataAction(data) {
  return { type: ADD_UPDATE_CONTACT_NOTE_SET_DATA, data };
}

export function addContactCardAction(data) {
  return { type: ADD_CONTACT_CARD, data };
}

export function addContactCardSetDataAction(data) {
  return { type: ADD_CONTACT_CARD_SET_DATA, data };
}

export function linkContactAction(data) {
  return { type: LINK_CONTACT, data };
}
export function linkContactSetDataAction(data) {
  return { type: LINK_CONTACT_SET_DATA, data };
}

export function getContactPaymentListAction(data) {
  return { type: GET_CONTACT_PAYMENTS, data };
}
export function getContactPaymentListSetDataAction(data, page) {
  return { type: SET_GET_CONTACT_PAYMENTS_RESPONSE, data: contactPaymentListParser(data), page };
}

export function deleteContactNoteAction(data) {
  return { type: DELETE_CONTACT_NOTE, data };
}
export function deleteContactNoteSetDataAction(data) {
  return { type: DELETE_CONTACT_NOTE_SET_DATA, data };
}

export function deleteContactAction(data) {
  return { type: DELETE_CONTACT, data };
}
export function deleteContactSetDataAction(data) {
  return { type: DELETE_CONTACT_SET_DATA, data };
}

export function getContactSchedulePaymentListAction(data) {
  return { type: GET_CONTACT_SCHEDULE_PAYMENT_LIST, data };
}
export function getSchedulePaymentListSetDataAction(data, page) {
  return { type: GET_CONTACT_SCHEDULE_PAYMENT_LIST_SET_DATA, data: getSchedulePaymentListParser(data), page };
}

export function getContactNotesAction(data) {
  return { type: GET_CONTACT_NOTES, data };
}
export function getContactNotesSetDataAction(data) {
  return { type: GET_CONTACT_NOTES_SET_DATA, data };
}

export function getContactBankAccountsAction(data) {
  return { type: GET_CONTACT_BANK_ACCOUNTS, data };
}
export function getContactBankAccountsSetDataAction(data) {
  return { type: GET_CONTACT_BANK_ACCOUNTS_SET_DATA, data: getContactAccountsParser(data) };
}

export function deleteSchedulePaymentAction(data) {
  return { type: DELETE_SCHEDULE_PAYMENT, data };
}
export function deleteSchedulePaymentSetDataAction(data) {
  return { type: DELETE_SCHEDULE_PAYMENT_SET_DATA, data };
}

export function contactPaymentDetailAction(data) {
  return { type: CONTACT_PAYMENT_DETAIL, data };
}
export function contactPaymentDetailSetDataAction(data) {
  return { type: CONTACT_PAYMENT_DETAIL_SET_DATA, data };
}

export function getContactSchedulePaymentDetailsAction(data) {
  return { type: GET_CONTACT_SCHEDULE_PAYMENT_DETAILS, data };
}
export function getContactSchedulePaymentDetailsSetDataAction(data) {
  return { type: GET_CONTACT_SCHEDULE_PAYMENT_DETAILS_SET_DATA, data: contactScheduledPaymentDetailParser(data) };
}
export function ibanValidationAction(data) {
  return { type: IBAN_VALIDATION, data };
}
export function ibanValidationSetDataAction(data) {
  return { type: IBAN_VALIDATION_SET_DATA, data };
}
export function contactFullListAction(data) {
  return { type: CONTACT_FULL_LIST, data };
}
export function contactFullListSetDataAction(data) {
  return { type: CONTACT_FULL_LIST_SET_DATA, data: contactListParser(data), };
}

export function updateContactBankAccountAction(data) {
  return { type: UPDATE_CONTACT_BANK_ACCOUNT, data: updateContactBankAccountParser(data) };
}
export function updateContactBankAccountSetDataAction(data) {
  return { type: UPDATE_CONTACT_BANK_ACCOUNT_SET_DATA, data };
}

export function resendContactBankDetailRequestAction(data) {
  return { type: RESEND_CONTACT_BANK_DETAIL_REQUEST, data: resendContactBankDetailRequestParser(data) };
}
export function resendContactBankDetailRequestSetDataAction(data) {
  return { type: RESEND_CONTACT_BANK_DETAIL_REQUEST_SET_DATA, data };
}

export function archiveContactBankAccountAction(data) {
  return { type: ARCHIVE_CONTACT_BANK_ACCOUNT, data };
}
export function archiveContactBankAccountSetDataAction(data) {
  return { type: ARCHIVE_CONTACT_BANK_ACCOUNT_SET_DATA, data };
}
