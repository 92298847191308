/* eslint-disable no-useless-computed-key */
import React, { memo, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button, Typography } from "components/ui";
import { styledSnackbar } from "containers/Common/Snackbar";
import Auth from "@aws-amplify/auth";
import PhoneInput from "containers/Common/PhoneInput";
import { markAttributeVerified, updateUserPhoneNumber } from "store/User/userApi";

const useStyles = makeStyles((theme) => ({
  txt: {
    textAlign: "left",
  },
  linkText: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    textDecoration: "underline",
  },
}));

function VerifyPhoneNumber(props = {}) {
  const { setStep, setLoading, user = {}, /*checkUser = () => null*/ } = props;
  // const data = user.attributes || {};
  const classes = useStyles();
  const userAtt = {
    // mobile: data['custom:mobile'] || data['phone_number'] || '',
    // mobile_country_code: data['custom:mobile_country_code'] || '',
    // mobile_dialing_code: data['custom:mobile_dialing_code'] || '',
    // phone_number: data['phone_number'] || '',
  };
  const [attr, setAttr] = useState(userAtt);
  const [formData, setFormData] = useState(userAtt);

  useEffect(() => {
    getUserAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserAttributes = async () => {
    const at = await Auth.userAttributes(user);
    const d = {
      mobile: getExactAttributeValue(at, 'custom:mobile') || getExactAttributeValue(at, 'phone_number') || '',
      mobile_country_code: getExactAttributeValue(at, 'custom:mobile_country_code') || '',
      mobile_dialing_code: getExactAttributeValue(at, 'custom:mobile_dialing_code') || '',
      phone_number: getExactAttributeValue(at, 'phone_number') || '',
    };
    setFormData(d);
    setAttr(d);
    return at;
  };

  const getExactAttributeValue = (arr = [], name) => {
    const attr = arr.find(i => i.Name === name);
    if (attr) {
      return attr.Value;
    }
    return;
  };

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    if (formData.mobile === attr.mobile
      && formData.mobile_country_code === attr.mobile_country_code
      && formData.mobile_dialing_code === attr.mobile_dialing_code
      && formData.phone_number === attr.phone_number
    ) {
      submitVerifyPhoneNumber();
    } else {
      submitUpdateAndVerifyPhoneNumber();
    }

  };

  const submitUpdateAndVerifyPhoneNumber = async (e = {}) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      const updatePhone = await updateUserPhoneNumber({ mobile: formData.mobile, mobile_country_code: formData.mobile_country_code });
      if (updatePhone.ok) {
        setLoading(true);
        // await checkUser();
        await getUserAttributes();
        await Auth.verifyCurrentUserAttribute("phone_number");
        await markAttributeVerified("phone");
        setStep(5);
      } else {
        const { error = {} } = updatePhone;
        if (error.message)
          styledSnackbar.error(error.message);

      }
      // await Auth.updateUserAttributes(user, {
      //     ['custom:mobile']: formData.mobile,
      //     ['custom:mobile_country_code']: formData.mobile_country_code,
      //     ['custom:mobile_dialing_code']: formData.mobile_dialing_code,
      //     ['phone_number']: formData.phone_number,
      // });


      // Auth.updateUserAttributes(user, {
      //     ['custom:mobile']: formData.mobile,
      //     ['custom:mobile_country_code']: formData.mobile_country_code,
      //     ['custom:mobile_dialing_code']: formData.mobile_dialing_code,
      //     ['phone_number']: formData.phone_number,
      // }).then((r) => {
      //     checkUser();
      //     Auth.verifyCurrentUserAttribute("phone_number").then((res) => {
      //         setStep(5);
      //     }).catch((e) => {
      //         styledSnackbar.error((e && e.message) || 'Error sending verification code, please try again later.');
      //     });
      // }).catch((e) => {
      //     styledSnackbar.error((e && e.message));
      // });

    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const submitVerifyPhoneNumber = async (e = {}) => {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttribute("phone_number");
      await markAttributeVerified("phone");
      setStep(5);
      // Auth.verifyCurrentUserAttribute("phone_number").then((res) => {
      //   setStep(5);
      // }).catch((e) => {
      //   styledSnackbar.error((e && e.message) || 'Error sending verification code, please try again later.');
      // });
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (<div>
    <form onSubmit={onSubmit} autoComplete="off">
      <Grid
        container
        spacing={3}
        item
        direction="column"
      >

        <Grid item xs={12}>
          <Typography variant="h5" className={classes.txt} >Verify Your Mobile Number</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="grey500" className={classes.txt}>Verify or change the following number to match your current mobile number. This is the number you will receive your verification SMS code on.</Typography>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <PhoneInput
            mobileCountryId="mobile_country_code"
            mobileCountryName="mobile_country_code"
            mobileCountryValue={formData.mobile_country_code}
            // mobileCountryLabel={<FormattedMessage {...intl.contactAddUpdateMobileCountryLabel} />}
            mobileCountryPlaceholder=""
            // mobileCountryLabel="Country"
            mobileDialingCodeId=""
            mobileDialingCodeName="mobile_dialing_code"
            mobileId=""
            mobileName="mobile"
            // mobileLabel="Phone Number"
            // mobileLabel={`Phone Number ${!formData.email || formData.mobile ? "" : '(Optional)'}`}
            // mobileLabel={`Phone Number ${!formData.email || formData.mobile ? "" : '(Optional)'}`}
            // mobileLabel={`${textIntl.formatMessage(intl.contactAddUpdateMobileLabel)} ${textIntl.formatMessage(intl.contactAddUpdateOptionalLabel)}`}
            mobilePlaceholder="Mobile"
            // mobileLabel="Phone Number"
            mobileValue={formData.mobile}
            phoneNumberId=""
            phoneNumberName="phone_number"
            required
            noPadding
            errorText={"Required"}
            onChange={onChange}
            hiddenLabel
          // onFocusRef={onRefFocus}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              title="Back"
              onClick={(e) => setStep(1)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              title="Continue"
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  </div>);
};

export default memo(VerifyPhoneNumber);