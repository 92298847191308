import { BANK_ACCOUNT } from '@truly-financial/common/consts';
import { PAD_STATUS } from '@truly-financial/common/consts/external-account.consts';
import produce from 'immer';

import {
  RESET_ACCOUNTS,
  GET_ACCOUNT_LIST, GET_ACCOUNT_LIST_SET_DATA,
  GET_ACCOUNT_LIST_RESET_DATA,
  GET_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS_SET_DATA,
  ACCOUNT_PAYMENT_TRANSFER, ACCOUNT_PAYMENT_TRANSFER_SET_DATA,
  CREATE_CHECK_IN_ACCOUNT, CREATE_CHECK_IN_ACCOUNT_SET_DATA,
  ACCOUNT_PAYMENT_LIST, ACCOUNT_PAYMENT_LIST_SET_DATA,
  ACCOUNT_PAYMENT_DETAIL_SET_DATA, ACCOUNT_PAYMENT_DETAIL,
  GET_ACCOUNT_CURRENCIES, GET_ACCOUNT_CURRENCIES_SET_DATA,
  ACCOUNT_PAYMENT_LIST_RESET_DATA,
  GET_CHECK_IN_ACCOUNT_LIST,
  GET_CHECK_IN_ACCOUNT_LIST_SET_DATA,
  GET_SMART_LINK_ACCOUNT_LIST,
  GET_SMART_LINK_ACCOUNT_LIST_SET_DATA,
  GET_SMART_LINK_CARD_LIST,
  GET_SMART_LINK_CARD_LIST_SET_DATA,
  RESET_SMART_LINK_CARD_LIST,
  GET_SMART_LINK_ACCOUNT_DETAILS, GET_SMART_LINK_ACCOUNT_DETAILS_SET_DATA,
  GET_SMART_LINK_ACCOUNT_PAYMENTS, GET_SMART_LINK_ACCOUNT_PAYMENTS_SET_DATA, RESET_SMART_LINK_ACCOUNT_PAYMENTS,
  ACTIVATE_PHYSICAL_CARD, ACTIVATE_PHYSICAL_CARD_SET_DATA,
  GET_CARD_SECURE_DETAILS, GET_CARD_SECURE_DETAILS_SET_DATA, CARD_CHANGE_PIN_SET_DATA, CARD_CHANGE_PIN,
  CARD_LOCK_SET_DATA, CARD_LOCK, SMART_LINK_ACC_LOCK, SMART_LINK_ACC_LOCK_SET_DATA,
  GET_ACCOUNT_SETTING_SET_DATA, GET_ACCOUNT_SETTING, SAVE_ACCOUNT_SETTING_SET_DATA, SAVE_ACCOUNT_SETTING,
  GET_ACCOUNT_TEAM_MEMBERS_SET_DATA, GET_ACCOUNT_TEAM_MEMBERS, GET_TEAM_MEMBER_DETAIL_SET_DATA, GET_TEAM_MEMBER_DETAIL,
  ADD_UPDATE_TEAM_MEMBER_SET_DATA, ADD_UPDATE_TEAM_MEMBER, CREATE_SMART_LINK_ACC_SET_DATA, CREATE_SMART_LINK_ACC,
  GET_CARD_DETAILS, GET_CARD_DETAILS_SET_DATA, ISSUE_SMARTLINK_CARDS_SET_DATA, ISSUE_SMARTLINK_CARDS, GET_CARD_PAYMENT_DETAIL,
  GET_CARD_PAYMENT_DETAIL_SET_DATA, UPDATE_CARD_NICK_NAME, UPDATE_CARD_NICK_NAME_SET_DATA,
  PRE_QUICK_TRANSFER_SET_DATA, PRE_QUICK_TRANSFER, QUICK_TRANSFER_SET_DATA, QUICK_TRANSFER,
  GET_LINK_ACC_LIST_SET_DATA, GET_LINK_ACC_LIST,
  GET_EXTERNAL_ACCOUNTS, GET_EXTERNAL_ACCOUNTS_SET_DATA,
  ADD_EXTERNAL_ACCOUNT, ADD_EXTERNAL_ACCOUNT_SET_DATA,
  GET_EXTERNAL_ACC_DETAILS, GET_EXTERNAL_ACC_DETAILS_SET_DATA,
  REMOVE_EXTERNAL_ACCOUNT, REMOVE_EXTERNAL_ACCOUNT_SET_DATA, GET_EXTERNAL_ACC_PAYMENT_LIST, GET_EXTERNAL_ACC_PAYMENT_LIST_SET_DATA,
  GET_EXTERNAL_ACC_BANK_TRANSACTIONS, GET_EXTERNAL_ACC_BANK_TRANSACTIONS_SET_DATA,
  GET_EXTERNAL_ACCOUNT_BALANCE, GET_EXTERNAL_ACCOUNT_BALANCE_SET_DATA,
  LINK_EXTERNAL_ACCOUNT, LINK_EXTERNAL_ACCOUNT_SET_DATA,
  UNLINK_EXTERNAL_ACCOUNT, UNLINK_EXTERNAL_ACCOUNT_SET_DATA,
  GET_GLOBAL_ACCOUNT_LIST, GET_GLOBAL_ACCOUNT_LIST_SET_DATA, GET_GLOBAL_ACCOUNT_LIST_REFRESH, CSV_GET_PAYMENT_LIST, CSV_GET_PAYMENT_LIST_SET_DATA,
  RESET_ACCOUNT_TRANSFER_FORM_DATA, QUICK_TRANSFER_DATA, SUBMIT_PAD_VERIFICATION, SUBMIT_PAD_VERIFICATION_SET_DATA,
  RE_AUTHORIZED_EXTERNAL_ACC, RE_AUTHORIZED_EXTERNAL_ACC_SET_DATA
} from './accountConstants';

export const initialState = {
  reAuthorizedExternalAccState: { data: {}, loading: false, error: '' },
  submitPadVerificationState: { data: {}, loading: false, error: '' },
  quickTransferFormData: { data: {} },
  csvGetPaymentListState: { data: {}, loading: false, error: '' },
  getGlobalAccountListState: { data: [], loading: false, error: '' },
  getGlobalAccountDirectDebitEnableListState: { data: {}, loading: false, error: '' },
  unlinkExternalAccountState: { data: {}, loading: false, error: '' },
  linkExternalAccountState: { data: {}, loading: false, error: '' },
  getExternalAccountBalanceState: { data: {}, loading: false, error: '' },
  getExternalAccBankTransactionsState: { data: [], loading: false, error: '' },
  getExternalAccPaymentListState: { data: {}, loading: false, error: '' },
  removeExternalAccountState: { data: {}, loading: false, error: '' },
  getExternalAccDetailsState: { data: {}, loading: false, error: '' },
  addExternalAccountState: { data: {}, loading: false, error: '' },
  getExternalAccountsState: { data: [], loading: false, error: '' },
  getLinkAccListState: { data: {}, loading: false, error: '' },
  quickTransferState: { data: {}, loading: false, error: '' },
  preQuickTransferState: { data: {}, loading: false, error: '' },
  updateCardNickNameState: { data: {}, loading: false, error: '' },
  getCardPaymentDetailState: { data: {}, loading: false, error: '' },
  issueSmartlinkCardsState: { data: {}, loading: false, error: '' },
  getCardDetailsState: { data: {}, loading: false, error: '' },
  createSmartLinkAccState: { data: {}, loading: false, error: '' },
  addUpdateTeamMemberState: { data: {}, loading: false, error: '' },
  getTeamMemberDetailState: { data: {}, loading: false, error: '' },
  getAccountTeamMembersState: { data: {}, loading: false, error: '' },
  saveAccountSettingState: { data: {}, loading: false, error: '' },
  getAccountSettingState: { data: {}, loading: false, error: '' },
  smartLinkAccLockState: { data: {}, loading: false, error: '' },
  cardLockState: { data: {}, loading: false, error: '' },
  cardChangePinState: { data: {}, loading: false, error: '' },
  getCardSecureDetailsState: { data: {}, loading: false, error: '' },
  activatePhysicalCardState: { data: {}, loading: false, error: '' },
  getSmartLinkAccountPaymentsState: { data: {}, previousData: [], loading: false, error: '' },
  getSmartLinkAccountDetailsState: { data: {}, loading: false, error: '' },
  getSmartLinkCardListState: { data: [], loading: false, error: '' },
  getSmartLinkAccountListState: { data: [], loading: false, error: '' },
  getCheckInAccountListState: { data: [], loading: false, error: '' },
  getAccountCurrenciesState: { data: {}, loading: false, error: '' },
  accountPaymentListState: { data: [], previousData: [], loading: false, error: '', count: '' },
  createCheckInAccountState: { data: {}, loading: false, error: '' },
  accountPaymentTransferState: { data: {}, loading: false, error: '' },
  getAccountDetailsState: { data: {}, loading: false, error: '' },
  getAccountListState: { data: [], checkingAcc: [], multiCurrencyAcc: [], loading: false, error: '' },
  accountPaymentDetailsState: { data: {}, loading: false, error: '' },
  getAccountGlobalListRefresh: { refresh: false },
  resetAccountTransferFormDataState: { resetState: false },
};

/* eslint-disable default-case, no-param-reassign */
const accountReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RE_AUTHORIZED_EXTERNAL_ACC:
        draft.reAuthorizedExternalAccState = {
          ...draft.reAuthorizedExternalAccState,
          loading: true, message: '', error: '',
        };
        break;
      case RE_AUTHORIZED_EXTERNAL_ACC_SET_DATA:
        draft.reAuthorizedExternalAccState = {
          ...draft.reAuthorizedExternalAccState,
          ...action.data, loading: false
        };
        break;
      case SUBMIT_PAD_VERIFICATION:
        draft.submitPadVerificationState = {
          ...draft.submitPadVerificationState,
          loading: true, message: '', error: '',
        };
        break;
      case SUBMIT_PAD_VERIFICATION_SET_DATA:
        draft.submitPadVerificationState = {
          ...draft.submitPadVerificationState,
          ...action.data, loading: false
        };
        break;
      case QUICK_TRANSFER_DATA:
        draft.quickTransferFormData = {
          ...draft.quickTransferFormData,
          ...action.data,
        };
        break;
      case CSV_GET_PAYMENT_LIST:
        draft.csvGetPaymentListState = {
          ...draft.csvGetPaymentListState,
          loading: true, message: '', error: '',
        };
        break;
      case CSV_GET_PAYMENT_LIST_SET_DATA:
        draft.csvGetPaymentListState = {
          ...draft.csvGetPaymentListState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_GLOBAL_ACCOUNT_LIST_REFRESH:
        draft.getAccountGlobalListRefresh = {
          ...draft.getAccountGlobalListRefresh,
          refresh: action.data
        };
        break;
      case RESET_ACCOUNT_TRANSFER_FORM_DATA:
        draft.resetAccountTransferFormDataState = {
          ...draft.resetAccountTransferFormDataState,
          resetState: action.data
        };
        break;
      case GET_GLOBAL_ACCOUNT_LIST:
        draft.getGlobalAccountListState = {
          ...draft.getGlobalAccountListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_GLOBAL_ACCOUNT_LIST_SET_DATA:
        const directDebitEnableAcc = action.data && action.data.data.filter(i => ((i.bank_account_type === BANK_ACCOUNT.BANK_ACCOUNT_TYPE.TRULY_MANAGED) || (i.bank_account_type === BANK_ACCOUNT.BANK_ACCOUNT_TYPE.EXTERNAL_LINKED && i.pad_status === PAD_STATUS.APPROVED)));
        draft.getGlobalAccountListState = {
          ...draft.getGlobalAccountListState,
          ...action.data, loading: false, loaded: true,
        };
        draft.getGlobalAccountDirectDebitEnableListState = {

          ...draft.getGlobalAccountListState,
          data: directDebitEnableAcc, loading: false, loaded: true,
        };
        break;
      case UNLINK_EXTERNAL_ACCOUNT:
        draft.unlinkExternalAccountState = {
          ...draft.unlinkExternalAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case UNLINK_EXTERNAL_ACCOUNT_SET_DATA:
        draft.unlinkExternalAccountState = {
          ...draft.unlinkExternalAccountState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case LINK_EXTERNAL_ACCOUNT:
        draft.linkExternalAccountState = {
          ...draft.linkExternalAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case LINK_EXTERNAL_ACCOUNT_SET_DATA:
        draft.linkExternalAccountState = {
          ...draft.linkExternalAccountState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_EXTERNAL_ACCOUNT_BALANCE:
        draft.getExternalAccountBalanceState = {
          ...draft.getExternalAccountBalanceState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_EXTERNAL_ACCOUNT_BALANCE_SET_DATA:
        draft.getExternalAccountBalanceState = {
          ...draft.getExternalAccountBalanceState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_EXTERNAL_ACC_BANK_TRANSACTIONS:
        draft.getExternalAccBankTransactionsState = {
          ...draft.getExternalAccBankTransactionsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_EXTERNAL_ACC_BANK_TRANSACTIONS_SET_DATA:
        draft.getExternalAccBankTransactionsState = {
          ...draft.getExternalAccBankTransactionsState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_EXTERNAL_ACC_PAYMENT_LIST:
        draft.getExternalAccPaymentListState = {
          ...draft.getExternalAccPaymentListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_EXTERNAL_ACC_PAYMENT_LIST_SET_DATA:
        draft.getExternalAccPaymentListState = {
          ...draft.getExternalAccPaymentListState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case REMOVE_EXTERNAL_ACCOUNT:
        draft.removeExternalAccountState = {
          ...draft.removeExternalAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case REMOVE_EXTERNAL_ACCOUNT_SET_DATA:
        draft.removeExternalAccountState = {
          ...draft.removeExternalAccountState,
          ...action.data, loading: false,
        };
        break;
      case GET_EXTERNAL_ACC_DETAILS:
        draft.getExternalAccDetailsState = {
          ...draft.getExternalAccDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_EXTERNAL_ACC_DETAILS_SET_DATA:
        draft.getExternalAccDetailsState = {
          ...draft.getExternalAccDetailsState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case ADD_EXTERNAL_ACCOUNT:
        draft.addExternalAccountState = {
          ...draft.addExternalAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_EXTERNAL_ACCOUNT_SET_DATA:
        draft.addExternalAccountState = {
          ...draft.addExternalAccountState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_EXTERNAL_ACCOUNTS:
        draft.getExternalAccountsState = {
          ...draft.getExternalAccountsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_EXTERNAL_ACCOUNTS_SET_DATA:
        draft.getExternalAccountsState = {
          ...draft.getExternalAccountsState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_LINK_ACC_LIST:
        draft.getLinkAccListState = {
          ...draft.getLinkAccListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_LINK_ACC_LIST_SET_DATA:
        draft.getLinkAccListState = {
          ...draft.getLinkAccListState,
          ...action.data, loading: false,
        };
        break;
      case QUICK_TRANSFER:
        draft.quickTransferState = {
          ...draft.quickTransferState,
          loading: true, message: '', error: '',
        };
        break;
      case QUICK_TRANSFER_SET_DATA:
        draft.quickTransferState = {
          ...draft.quickTransferState,
          ...action.data, loading: false,
        };
        break;
      case PRE_QUICK_TRANSFER:
        draft.preQuickTransferState = {
          ...draft.preQuickTransferState,
          loading: true, message: '', error: '',
        };
        break;
      case PRE_QUICK_TRANSFER_SET_DATA:
        draft.preQuickTransferState = {
          ...draft.preQuickTransferState,
          ...action.data, loading: false, loaded: true
        };
        break;
      case UPDATE_CARD_NICK_NAME:
        draft.updateCardNickNameState = {
          ...draft.updateCardNickNameState,
          loading: true, message: '', error: '',
        };
        break;
      case UPDATE_CARD_NICK_NAME_SET_DATA:
        draft.updateCardNickNameState = {
          ...draft.updateCardNickNameState,
          ...action.data, loading: false,
        };
        break;
      case ISSUE_SMARTLINK_CARDS:
        draft.issueSmartlinkCardsState = {
          ...draft.issueSmartlinkCardsState,
          loading: true, message: '', error: '',
        };
        break;
      case ISSUE_SMARTLINK_CARDS_SET_DATA:
        draft.issueSmartlinkCardsState = {
          ...draft.issueSmartlinkCardsState,
          ...action.data, loading: false,
        };
        break;
      case GET_CARD_DETAILS:
        draft.getCardDetailsState = {
          ...draft.getCardDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CARD_DETAILS_SET_DATA:
        draft.getCardDetailsState = {
          ...draft.getCardDetailsState,
          ...action.data, loading: false,
        };
        break;
      case GET_CARD_SECURE_DETAILS:
        draft.getCardSecureDetailsState = {
          ...draft.getCardSecureDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CARD_SECURE_DETAILS_SET_DATA:
        draft.getCardSecureDetailsState = {
          ...draft.getCardSecureDetailsState,
          ...action.data, loading: false,
        };
        break;
      case ACTIVATE_PHYSICAL_CARD:
        draft.activatePhysicalCardState = {
          ...draft.activatePhysicalCardState,
          loading: true, message: '', error: '',
        };
        break;
      case ACTIVATE_PHYSICAL_CARD_SET_DATA:
        draft.activatePhysicalCardState = {
          ...draft.activatePhysicalCardState,
          ...action.data, loading: false,
        };
        break;
      case GET_SMART_LINK_ACCOUNT_PAYMENTS:
        draft.getSmartLinkAccountPaymentsState = {
          ...draft.getSmartLinkAccountPaymentsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_SMART_LINK_ACCOUNT_PAYMENTS_SET_DATA:
        const resp = action.data;
        let pData = state.getSmartLinkAccountPaymentsState.previousData;
        const nData = {};

        if (action && action.page > 0) {
          nData.data = pData.concat(resp.data);
        } else {
          nData.data = resp.data;
        }
        draft.getSmartLinkAccountPaymentsState = {
          ...draft.getSmartLinkAccountPaymentsState,
          ...nData,
          previousData: nData.data,
          loading: false, count: action.data.count || 0
        };

        break;
      case RESET_SMART_LINK_ACCOUNT_PAYMENTS:
        draft.getSmartLinkAccountPaymentsState = {
          ...draft.getSmartLinkAccountPaymentsState,
          data: [], previousData: [], loading: false, message: '', error: '',
        };
        break;
      case GET_SMART_LINK_ACCOUNT_DETAILS:
        draft.getSmartLinkAccountDetailsState = {
          ...draft.getSmartLinkAccountDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_SMART_LINK_ACCOUNT_DETAILS_SET_DATA:
        draft.getSmartLinkAccountDetailsState = {
          ...draft.getSmartLinkAccountDetailsState,
          ...action.data, loading: false,
        };
        break;
      case RESET_ACCOUNTS:
        draft = initialState;
        break;
      case GET_ACCOUNT_CURRENCIES:
        draft.getAccountCurrenciesState = {
          ...draft.getAccountCurrenciesState,
          loading: true, message: '', error: '',
        };
        break;

      case GET_ACCOUNT_CURRENCIES_SET_DATA:
        draft.getAccountCurrenciesState = {
          ...draft.getAccountCurrenciesState,
          ...action.data, loading: false,
        };
        break;
      case GET_ACCOUNT_LIST:
        draft.getAccountListState = {
          ...draft.getAccountListState,
          loading: true, message: '', error: '',
        };
        break;

      case GET_ACCOUNT_LIST_SET_DATA:
        draft.getAccountListState = {
          ...draft.getAccountListState,
          ...action.checkingAcc, ...action.multiCurrencyAcc,
          ...action.data, loading: false, loaded: true,
        };
        break;

      case GET_ACCOUNT_LIST_RESET_DATA:
        draft.getAccountListState = {
          ...draft.getAccountListState,
          data: {}, loading: false, error: ''
        };
        break;
      case ACCOUNT_PAYMENT_LIST:
        draft.accountPaymentListState = {
          ...draft.accountPaymentListState,
          loading: true, message: '', error: '', count: ''
        };
        break;

      case ACCOUNT_PAYMENT_LIST_SET_DATA:

        const apiData = action.data;
        let previousData = state.accountPaymentListState.previousData;
        var NewData = {};

        if (action && action.page > 0) {
          NewData.data = previousData.concat(apiData.data);
        } else {
          NewData.data = apiData.data;
        }
        draft.accountPaymentListState = {
          ...draft.accountPaymentListState,
          ...NewData,
          previousData: NewData.data,
          loading: false, count: action.data.count || 0
        };
        break;
      case ACCOUNT_PAYMENT_LIST_RESET_DATA:
        draft.accountPaymentListState = {
          ...draft.accountPaymentListState,
          data: [], loading: false, error: '', count: '', previousData: []
        };
        break;
      case ACCOUNT_PAYMENT_DETAIL:
        draft.accountPaymentDetailsState = {
          ...draft.accountPaymentDetailsState,
          loading: true, message: '', error: '',
        };
        break;

      case ACCOUNT_PAYMENT_DETAIL_SET_DATA:
        draft.accountPaymentDetailsState = {
          ...draft.accountPaymentDetailsState,
          ...action.data, loading: false,
        };
        break;
      case GET_ACCOUNT_DETAILS:
        draft.getAccountDetailsState = {
          ...draft.getAccountDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_ACCOUNT_DETAILS_SET_DATA:
        draft.getAccountDetailsState = {
          ...draft.getAccountDetailsState,
          ...action.data, loading: false,
        };
        break;
      case ACCOUNT_PAYMENT_TRANSFER:
        draft.accountPaymentTransferState = {
          ...draft.accountPaymentTransferState,
          loading: true, message: '', error: '',
        };
        break;
      case ACCOUNT_PAYMENT_TRANSFER_SET_DATA:
        draft.accountPaymentTransferState = {
          ...draft.accountPaymentTransferState,
          ...action.data, loading: false,
        };
        break;
      case CREATE_CHECK_IN_ACCOUNT:
        draft.createCheckInAccountState = {
          ...draft.createCheckInAccountState,
          loading: true, message: '', error: '',
        };
        break;
      case CREATE_CHECK_IN_ACCOUNT_SET_DATA:
        draft.createCheckInAccountState = {
          ...draft.createCheckInAccountState,
          ...action.data, loading: false,
        };
        break;
      case GET_CHECK_IN_ACCOUNT_LIST:
        draft.getCheckInAccountListState = {
          ...draft.getCheckInAccountListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CHECK_IN_ACCOUNT_LIST_SET_DATA:
        draft.getCheckInAccountListState = {
          ...draft.getCheckInAccountListState,
          ...action.data, loading: false,
        };
        break;
      case GET_SMART_LINK_ACCOUNT_LIST:
        draft.getSmartLinkAccountListState = {
          ...draft.getSmartLinkAccountListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_SMART_LINK_ACCOUNT_LIST_SET_DATA:
        draft.getSmartLinkAccountListState = {
          ...draft.getSmartLinkAccountListState,
          ...action.data, loading: false, loaded: true,
        };
        break;
      case GET_SMART_LINK_CARD_LIST:
        draft.getSmartLinkCardListState = {
          ...draft.getSmartLinkCardListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_SMART_LINK_CARD_LIST_SET_DATA:
        draft.getSmartLinkCardListState = {
          ...draft.getSmartLinkCardListState,
          ...action.data, loading: false,
        };
        break;
      case RESET_SMART_LINK_CARD_LIST:
        draft.getSmartLinkCardListState = {
          ...draft.getSmartLinkCardListState,
          data: {}, loading: false, error: ''
        };
        break;
      case CARD_CHANGE_PIN:
        draft.cardChangePinState = {
          ...draft.cardChangePinState,
          loading: true, message: '', error: '',
        };
        break;
      case CARD_CHANGE_PIN_SET_DATA:
        draft.cardChangePinState = {
          ...draft.cardChangePinState,
          ...action.data, loading: false,
        };
        break;
      case CARD_LOCK:
        draft.cardLockState = {
          ...draft.cardLockState,
          loading: true, message: '', error: '',
        };
        break;
      case CARD_LOCK_SET_DATA:
        draft.cardLockState = {
          ...draft.cardLockState,
          ...action.data, loading: false,
        };
        break;
      case SMART_LINK_ACC_LOCK:
        draft.smartLinkAccLockState = {
          ...draft.smartLinkAccLockState,
          loading: true, message: '', error: '',
        };
        break;
      case SMART_LINK_ACC_LOCK_SET_DATA:
        draft.smartLinkAccLockState = {
          ...draft.smartLinkAccLockState,
          ...action.data, loading: false,
        };
        break;
      case GET_ACCOUNT_SETTING:
        draft.getAccountSettingState = {
          ...draft.getAccountSettingState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_ACCOUNT_SETTING_SET_DATA:
        draft.getAccountSettingState = {
          ...draft.getAccountSettingState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_ACCOUNT_SETTING:
        draft.saveAccountSettingState = {
          ...draft.saveAccountSettingState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_ACCOUNT_SETTING_SET_DATA:
        draft.saveAccountSettingState = {
          ...draft.saveAccountSettingState,
          ...action.data, loading: false,
        };
        break;
      case GET_ACCOUNT_TEAM_MEMBERS:
        draft.getAccountTeamMembersState = {
          ...draft.getAccountTeamMembersState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_ACCOUNT_TEAM_MEMBERS_SET_DATA:
        draft.getAccountTeamMembersState = {
          ...draft.getAccountTeamMembersState,
          ...action.data, loading: false,
        };
        break;
      case GET_TEAM_MEMBER_DETAIL:
        draft.getTeamMemberDetailState = {
          ...draft.getTeamMemberDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_TEAM_MEMBER_DETAIL_SET_DATA:
        draft.getTeamMemberDetailState = {
          ...draft.getTeamMemberDetailState,
          ...action.data, loading: false,
        };
        break;
      case ADD_UPDATE_TEAM_MEMBER:
        draft.addUpdateTeamMemberState = {
          ...draft.addUpdateTeamMemberState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_UPDATE_TEAM_MEMBER_SET_DATA:
        draft.addUpdateTeamMemberState = {
          ...draft.addUpdateTeamMemberState,
          ...action.data, loading: false,
        };
        break;
      case CREATE_SMART_LINK_ACC:
        draft.createSmartLinkAccState = {
          ...draft.createSmartLinkAccState,
          loading: true, message: '', error: '',
        };
        break;
      case CREATE_SMART_LINK_ACC_SET_DATA:
        draft.createSmartLinkAccState = {
          ...draft.createSmartLinkAccState,
          ...action.data, loading: false,
        };

        break;
      case GET_CARD_PAYMENT_DETAIL:
        draft.getCardPaymentDetailState = {
          ...draft.getCardPaymentDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CARD_PAYMENT_DETAIL_SET_DATA:
        draft.getCardPaymentDetailState = {
          ...draft.getCardPaymentDetailState,
          ...action.data, loading: false,
        };

        break;
    }

  });

export default accountReducer;
