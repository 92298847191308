import SabonBold from './sabon/sabonBold.ttf';
import SabonBoldItalic from './sabon/sabonBoldItalic.ttf';
import SabonCeItalic from './sabon/sabonCeBold.ttf';
import SabonCeBoldItalic from './sabon/sabonCeBoldItalic.ttf';
import SabonItalic from './sabon/sabonItalic.ttf';
import SabonRoman from './sabon/sabonRoman.ttf';

import SofiaProBold from './sofiaPro/sofiaProBoldAz.otf';
import sofiaProRegularAz from './sofiaPro/sofiaProRegularAz.otf';

export const sabon = {
  fontFamily: 'Sabon',
  src: `
      local('Sabon'),
      url(${SabonBold}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sabonBold = {
  fontFamily: 'Sabon',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
      local('Sabon'),
      local('Sabon-Bold'),
      url(${SabonBold}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sabonBoldItalic = {
  fontFamily: 'Sabon',
  fontStyle: 'italic',
  fontWeight: 700,
  src: `
      local('Sabon'),
      local('Sabon-Bold-Italic'),
      url(${SabonBoldItalic}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sabonCeItalic = {
  fontFamily: 'Sabon',
  fontStyle: 'italic',
  fontWeight: 400,
  src: `
      local('Sabon'),
      local('Sabon-CE-Italic'),
      url(${SabonCeItalic}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sabonCeBoldItalic = {
  fontFamily: 'Sabon',
  fontStyle: 'italic',
  fontWeight: 700,
  src: `
      local('Sabon'),
      local('Sabon-CE-Bold-Italic'),
      url(${SabonCeBoldItalic}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sabonItalic = {
  fontFamily: 'Sabon',
  fontStyle: 'italic',
  fontWeight: 400,
  src: `
      local('Sabon'),
      local('Sabon-Italic'),
      url(${SabonItalic}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sabonRoman = {
  fontFamily: 'Sabon',
  fontStyle: 'italic',
  fontWeight: 400,
  src: `
      local('Sabon'),
      local('Sabon-Roman'),
      url(${SabonRoman}) format('truetype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


export const sofiaPro = {
  fontFamily: 'Sofia Pro',
  src: `
      local('SofiaPro'),
      url(${sofiaProRegularAz}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sofiaProBold = {
  fontFamily: 'Sofia Pro',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
      local('Sofia Pro'),
      local('SofiaPro'),
      local('Sofia Pro Bold'),
      url(${SofiaProBold}) format('opentype')
    `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
