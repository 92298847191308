import { REGISTER_BASE_API_URL } from "config/apiUrls";
import { styledSnackbar } from "containers/Common/Snackbar";
import { requestWithErrorFeedback } from "utils/request";
const SIGNUP_URL = `${REGISTER_BASE_API_URL}/signup`;
export function signupApi(body={}) {
    if (!body.email || !body.password) {
      styledSnackbar.error('Please enter required fields');
      return;
    }
    const requestUrl = `${SIGNUP_URL}`;
    const options = {
        method: 'POST',
        body,
    }
    return requestWithErrorFeedback(requestUrl, options);
}