import { removeNullKeys } from "utils/common";

export const addUpdateTagParser = (data = {}) => {
    const { body = {}, cb, } = data;
    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            tag_id: body.tag_id || null,
            tag_name: !body.tag_id ? body.tag_name : null,
            tag_color: !body.tag_id ? body.tag_color : null,
            for_payment_id: body.for_payment_id,
            for_entity_id: body.for_entity_id,
            associate_single: body.associate_single || 0,
        }),
    };
    return parsedData;
};
export const tagListParser = (r) => {
    const parsedResponse = { ...r };
    const d = r.data.map(i => ({
        ...i,
        prs_is_selected: false
    }));
    parsedResponse.data = d;
    return parsedResponse;
};