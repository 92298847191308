import React, { memo, useEffect, useImperativeHandle, useState, } from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import StyledDialog from 'components/StyledDialog';
// import { StyledIframe } from "components";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import PrimaryPersonVerification from "containers/Verification/PrimaryPersonVerification";
import { Button, Typography } from "components/ui";
import { removeTempAccessToken, setTempAccessToken } from "utils/auth";
import Auth from "@aws-amplify/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: theme.spacing(3, 1, 1, 1)
    },
    titleTxt: {
        textAlign: 'center'
    },
    subTitleTxt: {
        textAlign: 'center',
        margin: theme.spacing(3, 0)
    },
    reasonTxt: {
        textAlign: 'center',
        color: theme.palette.accent.main
    },
    btnDiv: {
        display: 'flex',
        flex: 1,
        margin: theme.spacing(5, 3, 0, 3)
    }
}));


function IdVerificationIframeDialog(props = {}, ref) {
    // eslint-disable-next-line no-unused-vars
    const { onClose, data = {} } = props;

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState("");
    const [u, setU] = useState();
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));

    useEffect(() => {
        getCurrentAuthUser();
    }, []);

    useEffect(() => {
        if (data.entity_person_id_verification_status === "PENDING_RETRY") {
            setStep("RE_ID_VERIFICATION");
        } else {
            setStep("PRIMARY_ID_VERIFICATION");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const getCurrentAuthUser = async () => {
        const u = await Auth.currentAuthenticatedUser();
        setU(u);
    };

    const onClosePopup = () => {
        removeTempAccessToken();
        setTimeout(() => {
            setOpen(false);
            onClose && onClose();
        }, 100);
    };

    const onToggle = () => {
        if (!u) setTempAccessToken(data.access_token);
        setTimeout(() => {
            setOpen(true);
        }, 100);
    };

    useImperativeHandle(ref, () => ({
        onToggle,
    }));

    const content = () => {
        if (step === "RE_ID_VERIFICATION") {
            return (
                <div className={classes.root}>
                    <Typography className={classes.titleTxt} variant="titleMobile" >Please Reverify Your ID</Typography>
                    <Typography className={classes.subTitleTxt} variant="body1" color="grey500" >Unfortunately there was a issue while validating your ID; we’ll need you to restart the process. </Typography>
                    {
                        data && data.failureReason && <>
                            <Typography className={classes.titleTxt} variant="body2" >Reason for Request: </Typography>
                            <Typography className={classes.reasonTxt} variant="body2" >{data.failureReason} </Typography>
                        </>
                    }
                    <div className={classes.btnDiv} >
                        <Button
                            color="Secondary"
                            onClick={() => { setStep("PRIMARY_ID_VERIFICATION"); }}
                            title="Continue To ID Verification"
                        />
                    </div>
                </div>
            )
        }

        if (step === "PRIMARY_ID_VERIFICATION") {
            return (
                <>
                    <PrimaryPersonVerification isModal />
                </>

            )
        }

    };
    return (
        <>
            <StyledDialog
                title={"ID verification"}
                header={true}
                backBtn={false}
                open={open}
                type="content"
                noFullScreen={false}
                footer={false}
                disableBackdropClick={true}

                onClose={onClosePopup}
                padding={true}
                closeIcon={true}
                minHeight={step === "PRIMARY_ID_VERIFICATION" ? true : false}

            ><>
                    {content()}

                </>
            </StyledDialog>

        </>
    );
}


const mapStateToProps = createStructuredSelector({


});

function mapDispatchToProps(dispatch) {
    return {

    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
);

export default compose(
    withConnect,
    memo,
)(React.forwardRef(IdVerificationIframeDialog));