import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalTagsState = state => state.tags || {};

export const tagsState = () =>
  createSelector(GlobalState, substate => substate.tags);
export const getTagsState = () =>
  createSelector(globalTagsState, substate => substate.getTagsState);

export const addTagsState = () =>
  createSelector(globalTagsState, substate => substate.addTagsState);

export const removeTagState = () =>
  createSelector(globalTagsState, substate => substate.removeTagState);
