/* eslint-disable no-script-url */
import React, { useState } from 'react';

import { AuthState } from '@aws-amplify/ui-components';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';


import { Auth } from 'aws-amplify';
import Button from 'components/ui/Button';
import { ConfirmNewPasswordInput, NewPasswordInput } from 'components';
import { styledSnackbar } from 'containers/Common/Snackbar';

const useStyles = makeStyles((theme) => ({
  headings: {
    textAlign: "left",
    paddingBottom: theme.spacing(4),
  },
  subHeading: {
    margin: theme.spacing(1, 0),
  },
  submitButton: {
    margin: theme.spacing(2, 0, 2, 0),
  },
}));

function ResetPassword(props = {}) {
  const { /*setUser,*/ setAuthState, setLoading, user } = props;
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [isNewPasswordSetSuccess, setNewPasswordSetSuccess] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmNewPassword) {
      styledSnackbar.error('Confirm password does not match');
      return;
    }
    submitNewPassword(formData);
  };

  const onChange = (e, val) => {
    const { name, value } = e.target || {};

    setFormData(formData => ({ ...formData, [name]: value }));
  };


  async function submitNewPassword(data) {
    setLoading(true);
    try {
      // const u = await Auth.completeNewPassword(user, data.newPassword);
      await Auth.completeNewPassword(user, data.newPassword);
      // setUser(u);
      setNewPasswordSetSuccess(true);
    } catch (error) {
      styledSnackbar.error(error.message);
      console.error('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const gotoLogin = () => {
    setAuthState(AuthState.SignIn);
  }

  if (isNewPasswordSetSuccess) {
    return (<Grid
      container
      spacing={5}
      item
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.headings}>
          <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Your Password has been Reset</Typography>
          <Typography color="grey300" variant="body1" className={classes.subHeading}>
            Click log in to continue.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.submitButton}
          color="secondary"
          title="Log In"
          onClick={gotoLogin}
        />
      </Grid>
    </Grid>);
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"} >Reset password</Typography>
            <Typography className={classes.subHeading} color="grey300" variant="body1">
              Set a new password! Try to combine numbers, letters, and symbols to keep your password secure.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <NewPasswordInput
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            placeholder="New Password"
            required
            errorText="Required"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <ConfirmNewPasswordInput
            id="confirmNewPassword"
            name="confirmNewPassword"
            value={formData.confirmNewPassword}
            compareWith={formData.newPassword}
            placeholder="Confirm New Password"
            required
            errorText="Required"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            type="submit"
            title="Reset Password"
          />
          <Button
            variant="outlined"
            title="Back"
            onClick={gotoLogin}
          />
        </Grid>
      </Grid>
    </form>
  );
}


export default ResetPassword;

