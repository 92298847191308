export const RESET_TEAM_MEMBER = 'TeamMember/RESET_TEAM_MEMBER';

export const GET_TEAM_MEMBERS = 'TeamMember/GET_TEAM_MEMBERS';
export const GET_TEAM_MEMBERS_SET_DATA = 'TeamMember/GET_TEAM_MEMBERS_SET_DATA';

export const ADD_UPDATE_TEAM_MEMBER = 'TeamMember/ADD_UPDATE_TEAM_MEMBER';
export const ADD_UPDATE_TEAM_MEMBER_SET_DATA = 'TeamMember/ADD_UPDATE_TEAM_MEMBER_SET_DATA';

export const GET_TEAM_MEMBER_DETAILS = 'TeamMember/GET_TEAM_MEMBER_DETAILS';
export const GET_TEAM_MEMBER_DETAILS_SET_DATA = 'TeamMember/GET_TEAM_MEMBER_DETAILS_SET_DATA';

export const SELF_UPDATE_TEAM_MEMBER = 'TeamMember/SELF_UPDATE_TEAM_MEMBER';
export const SELF_UPDATE_TEAM_MEMBER_SET_DATA = 'TeamMember/SELF_UPDATE_TEAM_MEMBER_SET_DATA';

export const GET_SELF_TEAM_MEMBER_DETAILS = 'TeamMember/GET_SELF_TEAM_MEMBER_DETAILS';
export const GET_SELF_TEAM_MEMBER_DETAILS_SET_DATA = 'TeamMember/GET_SELF_TEAM_MEMBER_DETAILS_SET_DATA';

export const GENERATE_PASSWORD = 'TeamMember/GENERATE_PASSWORD';
export const GENERATE_PASSWORD_SET_DATA = 'TeamMember/GENERATE_PASSWORD_SET_DATA';

export const CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT = 'TeamMember/CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT';
export const CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT_SET_DATA = 'TeamMember/CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT_SET_DATA';

export const SEND_INVITATION_LINK = 'TeamMember/SEND_INVITATION_LINK';
export const SEND_INVITATION_LINK_SET_DATA = 'TeamMember/SEND_INVITATION_LINK_SET_DATA';

export const DELETE_TEAM_MEMBER = 'TeamMember/DELETE_TEAM_MEMBER';
export const DELETE_TEAM_MEMBER_SET_DATA = 'TeamMember/DELETE_TEAM_MEMBER_SET_DATA';

export const UPDATE_TEAM_MEMBER_ACCESS = 'TeamMember/UPDATE_TEAM_MEMBER_ACCESS';
export const UPDATE_TEAM_MEMBER_ACCESS_SET_DATA = 'TeamMember/UPDATE_TEAM_MEMBER_ACCESS_SET_DATA';

export const ID_VERIFICATION_RECEIVED = 'TeamMember/ID_VERIFICATION_RECEIVED';
export const ID_VERIFICATION_RECEIVED_SET_DATA = 'TeamMember/ID_VERIFICATION_RECEIVED_SET_DATA';

export const SUBMIT_ID_VERIFICATION_CONSENT = 'TeamMember/SUBMIT_ID_VERIFICATION_CONSENT';
export const SUBMIT_ID_VERIFICATION_CONSENT_SET_DATA = 'TeamMember/SUBMIT_ID_VERIFICATION_CONSENT_SET_DATA';

export const SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS = 'TeamMember/SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS';
export const SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS_SET_DATA = 'TeamMember/SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS_SET_DATA';

export const SUBMIT_ID_VERIFICATION_AGREEMENT = 'TeamMember/SUBMIT_ID_VERIFICATION_AGREEMENT';
export const SUBMIT_ID_VERIFICATION_AGREEMENT_SET_DATA = 'TeamMember/SUBMIT_ID_VERIFICATION_AGREEMENT_SET_DATA';

export const GET_SELF_USER_DETAILS = 'TeamMember/GET_SELF_USER_DETAILS';
export const GET_SELF_USER_DETAILS_SET_DATA = 'TeamMember/GET_SELF_USER_DETAILS_SET_DATA';

export const GET_BERBIX_TOKEN = 'TeamMember/GET_BERBIX_TOKEN';
export const GET_BERBIX_TOKEN_SET_DATA = 'TeamMember/GET_BERBIX_TOKEN_SET_DATA';

export const CHECK_TEAM_MEMBER_EXIST = 'TeamMember/CHECK_TEAM_MEMBER_EXIST';
export const CHECK_TEAM_MEMBER_EXIST_SET_DATA = 'TeamMember/CHECK_TEAM_MEMBER_EXIST_SET_DATA';
