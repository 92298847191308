/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';

import { AuthState } from '@aws-amplify/ui-components';
import { Grid, Link, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';
// import TextField from 'components/ui/TextField';


import { Auth } from 'aws-amplify';
import Button from 'components/ui/Button';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { markAttributeVerified } from 'store/User/userApi';
import OtpInput from 'components/OtpInput';

const useStyles = makeStyles(theme => ({
  headings: {
    textAlign: "left",
    paddingBottom: theme.spacing(3),
  },
  subHeading: {
    margin: theme.spacing(1, 0),
  },
  resendCodeTxt: {
    padding: theme.spacing(1, 0),
    display: "block",
  },
  resendCodeLink: {
    textDecoration: "underline",
  },
  submitButton: {
    margin: theme.spacing(-2, 0, 2, 0),
  },
  otpDiv: {
    display: "flex",
    justifyContent: "center",
  },
  resendCode: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  }
}));

function ConfirmEmail(props = {}) {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  // eslint-disable-next-line no-unused-vars
  const { setUser, setAuthState, setLoading, loading, user = {}, password } = props;
  // eslint-disable-next-line no-unused-vars
  const email = (user.attributes && user.attributes.email) || user.username;
  const classes = useStyles();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (formData?.code?.length === 6) {
      handleSubmit()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.code]);

  const handleSubmit = (e = {}) => {
    e.preventDefault && e.preventDefault();
    confirmSignUp(user, formData.code);
  };

  const onChange = (e, val) => {
    const { name, value } = e.target || {};
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  async function confirmSignUp(user, code) {
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
      await markAttributeVerified("email");
      setAuthState(AuthState.SettingMFA);
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  async function resendConfirmationCode(e) {
    e.preventDefault && e.preventDefault();
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttribute("email");
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const gotoLogin = () => {
    setAuthState(AuthState.SignIn);
  };

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid
        container
        spacing={5}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <div className={classes.headings}>
            <Typography color="grey700" variant={isXsScreen ? "h5" : "h3"}>Verify Your Email</Typography>
            <Typography color="grey300" variant="body1" className={classes.subHeading}>
              Enter the code sent to your email address to verify your email.
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12}>
                <TextField
                  type="email"
                  id="email"
                  name="email"
                  value={email || formData.email}
                  placeholder="Email"
                  required
                  errorText="Required"
                  disabled={email}
                  onChange={onChange}
                />
              </Grid> */}
        <Grid item xs={12}>
          {/* <TextField
            type="text"
            id="code"
            name="code"
            value={formData.code}
            label="Code"
            required
            errorText="Required"
            onChange={onChange}
          /> */}
          <div className={classes.otpDiv}>
            <OtpInput
              // type="text"
              id="code"
              name="code"
              value={formData.code}
              // label="Enter 6-digit Code"
              required
              // errorText=""
              onChange={onChange}
            />
          </div>
          <div className={classes.resendCode}>
            <Typography className={classes.resendCodeTxt} variant="caption" color="grey700">
              <Link className={classes.resendCodeLink} href="#" onClick={resendConfirmationCode}>Resend Code</Link>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.submitButton}
            type="submit"
            title="Confirm"
            disabled={formData?.code?.length !== 6 || loading}
          />
          <Button
            variant="outlined"
            title="Back"
            onClick={gotoLogin}
          />
        </Grid>
      </Grid>
    </form>
  );
}


export default ConfirmEmail;

