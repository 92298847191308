import {
  RESET_VERIFICATION,
  ACCEPT_CONTROLLING_PARTY_CONSENT, ACCEPT_CONTROLLING_PARTY_CONSENT_SET_DATA,
  ACCEPT_CONFIDENTIALITY_AGREEMENT, ACCEPT_CONFIDENTIALITY_AGREEMENT_SET_DATA,
  REFRESH_ID_VERIFICATION_TOKEN, REFRESH_ID_VERIFICATION_TOKEN_SET_DATA,
  PERSONAL_DETAILS_FROM_ID_VERIFICATION, PERSONAL_DETAILS_FROM_ID_VERIFICATION_SET_DATA,
  GET_PERSONAL_DETAILS, GET_PERSONAL_DETAILS_SET_DATA,
  UPDATE_PERSONAL_DETAILS, UPDATE_PERSONAL_DETAILS_SET_DATA,
  UPLOAD_VERIFICATION_DOCUMENT, UPLOAD_VERIFICATION_DOCUMENT_SET_DATA,
  CREATE_PASSWORD, CREATE_PASSWORD_SET_DATA,
  ACCEPT_INVITE, ACCEPT_INVITE_SET_DATA,
  DEPRECATED_GET_TEAM_MEMBER_DETAILS, DEPRECATED_GET_TEAM_MEMBER_DETAILS_SET_DATA, GET_PERSONAL_DETAILS_PULLING,
} from './verificationConstants';

import { getPersonalDetailsFromIdVerificationParser, getPersonalDetailsParser, updatePersonalDetailsParser } from './verificationParser';

export function resetVerificationAction() {
  return { type: RESET_VERIFICATION };
}

export function acceptControllingPartyConsentAction(data) {
  return { type: ACCEPT_CONTROLLING_PARTY_CONSENT, data };
}
export function acceptControllingPartyConsentSetDataAction(data) {
  return { type: ACCEPT_CONTROLLING_PARTY_CONSENT_SET_DATA, data };
}

export function acceptConfidentialityAgreementAction(data) {
  return { type: ACCEPT_CONFIDENTIALITY_AGREEMENT, data };
}
export function acceptConfidentialityAgreementSetDataAction(data) {
  return { type: ACCEPT_CONFIDENTIALITY_AGREEMENT_SET_DATA, data };
}

export function refreshIdVerificationTokenAction(data) {
  return { type: REFRESH_ID_VERIFICATION_TOKEN, data };
}
export function refreshIdVerificationTokenSetDataAction(data) {
  return { type: REFRESH_ID_VERIFICATION_TOKEN_SET_DATA, data };
}

export function personalDetailsFromIdVerificationAction(data) {
  return { type: PERSONAL_DETAILS_FROM_ID_VERIFICATION, data };
}
export function personalDetailsFromIdVerificationSetDataAction(data) {
  return { type: PERSONAL_DETAILS_FROM_ID_VERIFICATION_SET_DATA, data: getPersonalDetailsFromIdVerificationParser(data) };
}

export function getPersonalDetailsPullingAction(data) {
  return { type: GET_PERSONAL_DETAILS_PULLING, data };
}

export function getPersonalDetailsAction(data) {
  return { type: GET_PERSONAL_DETAILS, data };
}
export function getPersonalDetailsSetDataAction(data) {
  return { type: GET_PERSONAL_DETAILS_SET_DATA, data: getPersonalDetailsParser(data) };
}

export function updatePersonalDetailsAction(data) {
  return { type: UPDATE_PERSONAL_DETAILS, data: updatePersonalDetailsParser(data) };
}
export function updatePersonalDetailsSetDataAction(data) {
  return { type: UPDATE_PERSONAL_DETAILS_SET_DATA, data };
}

export function uploadVerificationDocumentAction(data) {
  return { type: UPLOAD_VERIFICATION_DOCUMENT, data };
}
export function uploadVerificationDocumentSetDataAction(data) {
  return { type: UPLOAD_VERIFICATION_DOCUMENT_SET_DATA, data };
}

export function createPasswordAction(data) {
  return { type: CREATE_PASSWORD, data };
}
export function createPasswordSetDataAction(data) {
  return { type: CREATE_PASSWORD_SET_DATA, data };
}

export function acceptInviteAction(data) {
  return { type: ACCEPT_INVITE, data };
}
export function acceptInviteSetDataAction(data) {
  return { type: ACCEPT_INVITE_SET_DATA, data };
}

export function deprecatedGetTeamMemberDetailsAction(data) {
  return { type: DEPRECATED_GET_TEAM_MEMBER_DETAILS, data };
}
export function deprecatedGetTeamMemberDetailsSetDataAction(data) {
  return { type: DEPRECATED_GET_TEAM_MEMBER_DETAILS_SET_DATA, data };
}
