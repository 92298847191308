import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalTeamMemberState = state => state.teamMember || {};
export const teamMemberState = () =>
  createSelector(GlobalState, substate => substate.teamMember);

export const getTeamMembersState = () =>
  createSelector(globalTeamMemberState, substate => substate.getTeamMembersState);

export const addUpdateTeamMemberState = () =>
  createSelector(globalTeamMemberState, substate => substate.addUpdateTeamMemberState);

export const getTeamMemberDetailsState = () =>
  createSelector(globalTeamMemberState, substate => substate.getTeamMemberDetailsState);

export const selfUpdateTeamMemberState = () =>
  createSelector(globalTeamMemberState, substate => substate.selfUpdateTeamMemberState);

export const getSelfTeamMemberDetailsState = () =>
  createSelector(globalTeamMemberState, substate => substate.getSelfTeamMemberDetailsState);

export const generatePasswordState = () =>
  createSelector(globalTeamMemberState, substate => substate.generatePasswordState);

export const createTeamMemberSmartLinkAccountState = () =>
  createSelector(globalTeamMemberState, substate => substate.createTeamMemberSmartLinkAccountState);

export const sendInvitationLinkState = () =>
  createSelector(globalTeamMemberState, substate => substate.sendInvitationLinkState);

export const deleteTeamMemberState = () =>
  createSelector(globalTeamMemberState, substate => substate.deleteTeamMemberState);

export const updateTeamMemberAccessState = () =>
  createSelector(globalTeamMemberState, substate => substate.updateTeamMemberAccessState);

export const idVerificationReceivedState = () =>
  createSelector(globalTeamMemberState, substate => substate.idVerificationReceivedState);

export const submitIdVerificationConsentState = () =>
  createSelector(globalTeamMemberState, substate => substate.submitIdVerificationConsentState);

export const submitIdVerificationAcceptedTermAndConditionsState = () =>
  createSelector(globalTeamMemberState, substate => substate.submitIdVerificationAcceptedTermAndConditionsState);

export const submitIdVerificationAgreementState = () =>
  createSelector(globalTeamMemberState, substate => substate.submitIdVerificationAgreementState);

export const getSelfUserDetailsState = () =>
  createSelector(globalTeamMemberState, substate => substate.getSelfUserDetailsState);

export const getBerbixTokenState = () =>
  createSelector(globalTeamMemberState, substate => substate.getBerbixTokenState);

export const checkTeamMemberExistState = () =>
  createSelector(globalTeamMemberState, substate => substate.checkTeamMemberExistState);
