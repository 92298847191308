/*
 * AccountData Messages
 *
 * This contains all the text for the AccountData component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.accountData';

export default defineMessages({
  routingDetails: {
    id: `${scope}.routingDetails`,
    defaultMessage: 'Routing Details:',
  },
  sub_title_one: {
    id: `${scope}.sub_title_one`,
    defaultMessage: 'ACH Details:',
  },
  sub_title_two: {
    id: `${scope}.sub_title_two`,
    defaultMessage: 'Fedwire Details:',
  },
  sub_title_three: {
    id: `${scope}.sub_title_three`,
    defaultMessage: 'Swift Details',
  },
  account_name: {
    id: `${scope}.account_name`,
    defaultMessage: 'Account Name.',
  },
  option_one: {
    id: `${scope}.option_one`,
    defaultMessage: 'Routing No.',
  },
  option_two: {
    id: `${scope}.option_two`,
    defaultMessage: 'Account No.',
  },
  option_three: {
    id: `${scope}.option_three`,
    defaultMessage: 'Institution Name',
  },

  option_four: {
    id: `${scope}.option_four`,
    defaultMessage: 'SWIFT Code',
  },
  option_five: {
    id: `${scope}.option_five`,
    defaultMessage: 'Address',
  },
  option_six: {
    id: `${scope}.option_six`,
    defaultMessage: 'Address',
  },
  option_seven: {
    id: `${scope}.option_seven`,
    defaultMessage: 'SWIFT Code',
  },
  option_eight: {
    id: `${scope}.option_eight`,
    defaultMessage: 'IBAN',
  },
  ach: {
    id: `${scope}.ach`,
    defaultMessage: 'ACH',
  },
  fedwire: {
    id: `${scope}.fedwire`,
    defaultMessage: 'Fedwire',
  },
  bic_swift: {
    id: `${scope}.bic_swift`,
    defaultMessage: 'Swift Code',
  },
  sort_code: {
    id: `${scope}.sort_code`,
    defaultMessage: 'Sort Code',
  },
  bank_code: {
    id: `${scope}.bank_code`,
    defaultMessage: 'Institution Number',
  },
  transit_code: {
    id: `${scope}.transit_code`,
    defaultMessage: 'Transit Code',
  },
  wire_payment_routing_details: {
    id: `${scope}.wire_payment_routing_details`,
    defaultMessage: 'Wire Transfer Routing Details:',
  },
  regular_payment_routing_details: {
    id: `${scope}.regular_payment_routing_details`,
    defaultMessage: 'Local Routing Details:',
  },
  bank_name: {
    id: `${scope}.bank_name`,
    defaultMessage: 'Institution Name',
  },
  bank_address: {
    id: `${scope}.bank_address`,
    defaultMessage: 'Address',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  iban: {
    id: `${scope}.iban`,
    defaultMessage: 'IBAN',
  },
  account_number: {
    id: `${scope}.account_number`,
    defaultMessage: 'Account Number',
  },
});
