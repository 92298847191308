const HOME = {
  code: "HOME",
  name: "Home",
  path: "/home",
  functions: {
    HOME_VIEW: {
      code: "HOME_VIEW",
      name: "View Home",
      isPage: true,
      component: "HomePage",
      path: "",
      getRoutePath: () => `${HOME.path}${HOME.functions.HOME_VIEW.path}`,
      getFullPath: () => `${HOME.path}${HOME.functions.HOME_VIEW.path}`,
    },
    ADD_BUSINESS: {
      code: "ADD_BUSINESS",
      name: "View Home",
      isPage: false,
      component: "",
      path: "",
      getRoutePath: () =>
        `${HOME.path}${HOME.functions.ADD_BUSINESS.path}`,
      getFullPath: () =>
        `${HOME.path}${HOME.functions.ADD_BUSINESS.path}`,
    },
  },
};

export default HOME;
