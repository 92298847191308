import {
  RESET_SEND_PAYMENT,
  PRE_SEND_PAYMENT,
  PRE_SEND_PAYMENT_SET_DATA,
  SEND_PAYMENT_NEW,
  SEND_PAYMENT_SET_DATA_NEW
} from './sendPaymentConstants';
import { sendPaymentParser } from './SendPaymentParser';

export function resetSendPaymentAction() {
  return { type: RESET_SEND_PAYMENT };
}

export function preSendPaymentAction(data) {
  return { type: PRE_SEND_PAYMENT, data };
}
export function preSendPaymentSetDataAction(data) {
  return { type: PRE_SEND_PAYMENT_SET_DATA, data };
}

export function sendPaymentAction(data) {

  return { type: SEND_PAYMENT_NEW, data: sendPaymentParser(data) };
}
export function sendPaymentSetDataAction(data) {
  return { type: SEND_PAYMENT_SET_DATA_NEW, data };
}
