import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, IconButton, useTheme, Icon } from '@material-ui/core';
import { Button, Typography } from 'components/ui';
import FaqLinkFooter from './FaqLinkFooter';
// import useCommonStyles from 'styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import SendIcon from '@material-ui/icons/Send';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
  },
  root: {
    display: "flex",
    justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  btn: {
    margin: theme.spacing(1),
  },
  faqLink: {
    marginTop: theme.spacing(5),
    // [theme.breakpoints.down("sm")]: {
    //   marginTop: theme.spacing(8),
    // },
  },
  ref: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },

  rootRoundButton: {
    display: "flex",
    flex: 0.8,
    justifyContent: 'space-evenly',
    [theme.breakpoints.down("sm")]: {
      flex: 1
    },
  },
  iconButtonDiv: {
    flex: 'none',
    [theme.breakpoints.down("sm")]: {
      flex: 1
    },
  }

}));

function GuideButtons(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const theme = useTheme();
  // const { test } = props;

  const RoundButton = (props = {}) => {
    const { color, onClick = () => null, icon, subText, id } = props;

    return (
      <div onClick={onClick} className={classes.iconButtonDiv} >

        <IconButton
          id={id}
          style={{
            height: theme.spacing(5),
            width: theme.spacing(5),
            border: `3px solid ${theme.palette.[color]}`,
            alignItems: 'center',
            margin: theme.spacing(0, 0, 1, 0),
          }} >

          <Icon component={icon} style={{
            height: theme.spacing(3),
            width: theme.spacing(3),
            color: theme.palette.[color]
          }} />
        </IconButton>

        <Typography style={{ color: theme.palette.[color] }} variant="subtitle1" >{subText}</Typography>

      </div>
    )
  }

  return (<div className={classes.main}>
    <div className={classes.root}>
      {
        props.type === "round" ?
          <>
            <div className={classes.rootRoundButton} >

              <RoundButton
                icon={AccountBalanceIcon}
                color="secondary800"
                id="guideDepositFunds"
                subText="Opening an Account"
              // onClick={}
              />

              <RoundButton
                icon={AttachMoneyOutlinedIcon}
                id="guideManageExpenses"
                color="accent500"
                subText="Manage Expenses"
              // onClick={}
              />

              <RoundButton
                icon={SendIcon}
                color="tertiary_three700"
                subText="Sending a Payment"
                id="guideSendPayment"
              // onClick={}
              />

              <RoundButton
                icon={LinkIcon}
                color="tertiary_two700"
                subText="Link a Payment Gateway"
                id="guideLinkPaymentGateway"
              // onClick={}
              />

            </div>
          </>
          : <>
            <Button borderWidth="3px" id="guideDepositFunds" className={classes.btn} variant="outlined" color="tertiary_one" colorShade="800">Deposit Funds</Button>
            <Button borderWidth="3px" id="guideManageExpenses" className={classes.btn} variant="outlined" color="accent" colorShade="500">Manage Expenses</Button>
            <Button borderWidth="3px" id="guideSendPayment" className={classes.btn} variant="outlined" color="tertiary_three" colorShade="700">Sending a Payment</Button>
            <Button borderWidth="3px" id="guideLinkPaymentGateway" className={classes.btn} variant="outlined" color="tertiary_two" colorShade="700">Link a Payment Gateway</Button>
          </>

      }

    </div>
    {props.hideFaq ? null : <div className={classes.faqLink}>
      <FaqLinkFooter />
    </div>}
    {props.showRef ? <div className={classes.ref}>
      <Typography variant="caption" color="gray[500]">Confirmation #{props.showRef}</Typography>
    </div> : null}
  </div>);
}

GuideButtons.defaultProps = {
  test: " GuideButtons component ",
};

GuideButtons.propTypes = {
  test: PropTypes.string,
};

export default memo(GuideButtons);
