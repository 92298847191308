/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { FormattedMessage } from 'react-intl';
// import messages from './messages';
import FilterListIcon from '@material-ui/icons/FilterList';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, Chip } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import intl from './intl/StyledFilter';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { StyledLink } from './withStyledComp';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    height: theme.spacing(5.5),
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[100]}`,
    // width: theme.spacing(14.625),
    margin: theme.spacing(0, 2, 0, 0),
    [theme.breakpoints.down('sm')]: {
      flex: 1
    },
  },
  filterTxt: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.2),
  },
  optionDiv: {
    width: theme.spacing(42.625),

  },
  menuClass: {
    '& .MuiMenu-paper': {
      height: '50%'
    }
  },
  menuClassSch: {
    '& .MuiMenu-paper': {
      height: '22%'
    }
  },
  tagDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'

  },
  disableMenuItemNoMargin: {
    opacity: '1 !important',
  },
  disableMenuItem: {
    opacity: '1 !important',
    margin: theme.spacing(4, 0, 0, 0)
  },

  tagTxt: {
    marginRight: theme.spacing(1)
  },
  icon: {
    fontSize: theme.spacing(3),
    color: theme.palette.primary.main
  },
  iconChecked: {
    fontSize: theme.spacing(3),
    color: theme.palette.accent.main

  },
  itemDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  tagMenuDiv: {
    marginTop: theme.spacing(1.5),

    //  padding: theme.spacing(2, 0)
  },
  tagCountDiv: {



  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    cursor: 'pointer'
  },
  iconButton: {
    padding: theme.spacing(0, 1),
    '& .MuiIconButton-root': {
      padding: theme.spacing(0, 1)
    }
  },
  resetBrnDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: theme.spacing(3),
    cursor: 'pointer'
  },
  btnWidth: {
    width: theme.spacing(5)
  },
  filterIcons: {
    color: theme.palette.grey400
  }
}));



function StyledFilter(props = {}) {

  const { tags, status, onChangeFilter, type = "Payment" } = props;

  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const [optionArray, setOptionArray] = useState([]);
  const [PaymentStatus, setPaymentStatus] = useState([]);
  const [ScheduledPaymentType, setScheduledPaymentType] = useState([{ type: "Recurring", id: "11" }, { type: "Scheduled", id: "12" }]);
  const [CardPaymentType, setCardPaymentType] = useState([{ type: "Debit", id: "11" }, { type: "Credit", id: "12" }]);
  const [SelectedScheduledType, setSelectedScheduledType] = useState([]);
  const [selectedTag, setSelectedTags] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const theme = useTheme();
  const [tagCount, setCount] = useState(0);

  // TODO : Should get product categories from api
  const productCategories = [
    { product_category_code: 'SEND_PAYMENTS', product_category_name: 'Send Payments' },
    { product_category_code: 'RECEIVE_PAYMENTS', product_category_name: 'Receive Payments' },
    { product_category_code: 'TRANSFERS', product_category_name: 'Transfers' }
  ]

  useEffect(() => {
    let initializeTag = [...tags];
    let initializeStatus = [...status];
    setPaymentStatus(initializeStatus);
    setOptionArray(initializeTag);
  }, [tags, status]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (selectedTag.length > 0 || selectedStatus.length > 0 || selectedCategory || SelectedScheduledType.length > 0) {
      filterSelectedTag();
    }

    setAnchorEl(null);
  };

  const filterSelectedTag = () => {
    let tagsStr = [];
    let statusArray = [];

    if (selectedTag && selectedTag.length > 0) {
      for (var i = 0; i < selectedTag.length; i++) {
        tagsStr.push(selectedTag[i].tag_id);
      }
    }
    if (selectedStatus && selectedStatus.length > 0) {
      for (var j = 0; j < selectedStatus.length; j++) {
        statusArray.push(selectedStatus[j].payment_status);
      }
    }

    let tagCount = tagsStr.length + statusArray.length + SelectedScheduledType.length;
    if (selectedCategory) {
      tagCount += 1;
    }
    setCount(tagCount);

    onChangeFilter && onChangeFilter({
      tags_id: tagsStr.join(";"),
      status: statusArray.join(";"),
      is_recurring: SelectedScheduledType && SelectedScheduledType[0] && SelectedScheduledType[0].type,
      product_category_code: selectedCategory.product_category_code ? selectedCategory.product_category_code : ''
    });
  };

  const onOptionPress = (item) => {
    if (item.tag_id) {
      var selectedTagArray = [...selectedTag];
      if (selectedTagArray.includes(item)) {
        selectedTagArray = selectedTagArray.filter(eachItem => eachItem !== item);
      } else {
        selectedTagArray.push(item);
      }
      setSelectedTags(selectedTagArray);
    } if (item.product_category_code) {
      setSelectedCategory(item)
    } else {
      var selectedStatusArray = [...selectedStatus];
      if (selectedStatusArray.includes(item)) {
        selectedStatusArray = selectedStatusArray.filter(eachItem => eachItem !== item);
      } else {
        selectedStatusArray.push(item);
      }
      setSelectedStatus(selectedStatusArray);
    }
  };

  const onScheduledOptionPress = (item) => {
    var SelectedScheduledTypeArray = [];
    SelectedScheduledTypeArray.push(item);
    setSelectedScheduledType([...SelectedScheduledTypeArray]);
  };

  const clearTags = () => {
    setSelectedStatus([]);
    setSelectedTags([]);
    setSelectedCategory('');
    setSelectedScheduledType([]);
    handleClose();
    setCount(0);
    onChangeFilter && onChangeFilter({ tags_id: "", status: "", is_recurring: "", product_category_code: "" });
  };

  return (
    <>
      <div className={classes.root}>
        <div ref={ref} aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick} className={classes.row}>
          <FilterListIcon fontSize="small" className={classes.filterIcons} />
          <Typography variant="subtitle2" color="grey400" >Filters</Typography>
          {tagCount > 0 ? <div className={classes.tagCountDiv}>
            <Typography variant="body1" >+{tagCount}</Typography>
          </div> : <></>}
        </div>

        {/* <ExpandMoreIcon fontSize="small" /> */}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handleClose}
        getContentAnchorEl={null}
        className={type === "Payment" ? classes.menuClass : classes.menuClassSch}
        disableEnforceFocus
      >
        <div className={classes.optionDiv} >
          {
            tagCount > 0 && <div className={classes.resetBrnDiv} >
              <StyledLink onClick={clearTags} ><Typography variant="subtitle1" >Reset</Typography></StyledLink>
            </div>
          }
        </div>
        {
          type === "Payment" || type === "ContactPayment" ? <div>
            <MenuItem className={classes.disableMenuItemNoMargin} disabled ><Typography color="grey700" variant="button" ><FormattedMessage {...intl.status} /> </Typography> </MenuItem>
            {
              Array.isArray(PaymentStatus) && PaymentStatus.map((item, index) => {

                return (
                  <MenuItem onClick={() => { onOptionPress(item) }} key={index} style={{ backgroundColor: 'transparent' }} >
                    <div className={classes.itemDiv} >
                      <Typography color="grey500" variant="body1" >{item.payment_status_lira_description}</Typography>
                      {selectedStatus.includes(item) ? <CheckBoxIcon className={classes.iconChecked} /> : <CheckBoxOutlineBlankIcon className={classes.icon} />}
                    </div>
                  </MenuItem>
                )


              })
            }


            <MenuItem disabled className={classes.disableMenuItem} ><Typography color="grey700" variant="button" ><FormattedMessage {...intl.tag} /> </Typography> </MenuItem>
            {
              optionArray && optionArray.map((item, index) => {
                return (
                  <MenuItem key={index} style={{ backgroundColor: 'transparent' }} className={classes.tagMenuDiv} onClick={() => { onOptionPress(item) }}  >
                    <div className={classes.tagDiv} >
                      <Chip className={classes.chipClassName} maxwidth={theme.spacing(18.75)} color={item.tag_color} size="small" label={(
                        <>
                          <Typography
                            color="grey700"
                            variant="caption"
                            className={classes.tagTxt}
                          >{item.tag_name}</Typography>

                        </>)} />

                    </div>
                    {selectedTag.includes(item) ? <CheckBoxIcon className={classes.iconChecked} /> : <CheckBoxOutlineBlankIcon className={classes.icon} />}
                  </MenuItem>
                )
              })
            }

            {
              type !== "ContactPayment" ?
                <>
                  <MenuItem className={classes.disableMenuItem} disabled ><Typography color="grey700" variant="button" ><FormattedMessage {...intl.type} /> </Typography> </MenuItem>
                  {
                    Array.isArray(productCategories) && productCategories.map((item, index) => {
                      return (
                        <MenuItem onClick={() => { onOptionPress(item) }} key={index} style={{ backgroundColor: 'transparent' }} >
                          <div className={classes.itemDiv} >
                            <Typography color="grey500" variant="body1" >{item.product_category_name}</Typography>
                            {
                              selectedCategory.product_category_code === item.product_category_code ?
                                <CheckBoxIcon className={classes.iconChecked} />
                                : <CheckBoxOutlineBlankIcon className={classes.icon} />
                            }

                          </div>
                        </MenuItem>
                      )
                    })
                  }
                </>
                : null
            }

          </div> : type === "Card" ?
            <div>
              <MenuItem className={classes.disableMenuItemNoMargin} disabled ><Typography color="grey700" variant="button" ><FormattedMessage {...intl.type} /> </Typography> </MenuItem>
              {
                Array.isArray(CardPaymentType) && CardPaymentType.map((item, index) => {

                  return (
                    <MenuItem onClick={() => { onScheduledOptionPress(item) }} key={index} style={{ backgroundColor: 'transparent' }} >
                      <div className={classes.itemDiv} >
                        <Typography color="grey500" variant="body1" >{item.type}</Typography>
                        {SelectedScheduledType.includes(item) ? <RadioButtonCheckedIcon className={classes.iconChecked} /> : <RadioButtonUncheckedIcon className={classes.icon} />}
                      </div>
                    </MenuItem>
                  )
                })
              }
            </div>
            : <div>
              <MenuItem className={classes.disableMenuItemNoMargin} disabled ><Typography color="grey700" variant="button" ><FormattedMessage {...intl.type} /> </Typography> </MenuItem>
              {
                Array.isArray(ScheduledPaymentType) && ScheduledPaymentType.map((item, index) => {

                  return (
                    <MenuItem onClick={() => { onScheduledOptionPress(item) }} key={index} style={{ backgroundColor: 'transparent' }} >
                      <div className={classes.itemDiv} >
                        <Typography color="grey500" variant="body1" >{item.type}</Typography>
                        {SelectedScheduledType.includes(item) ? <CheckBoxIcon className={classes.iconChecked} /> : <CheckBoxOutlineBlankIcon className={classes.icon} />}
                      </div>
                    </MenuItem>
                  )
                })
              }
            </div>
        }
      </Menu>
    </>
  );
}

StyledFilter.propTypes = {};

export default memo(StyledFilter);
