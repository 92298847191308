import React from 'react'
import { makeStyles, LinearProgress, Grid } from '@material-ui/core'
import { Typography } from './ui';
import { FormattedMessage } from 'react-intl';
import intl from './intl/SignupIntl'

const useStyles = makeStyles((theme) => ({
    rootDiv: {

    },
    progress: {
        height: theme.spacing(0.7),
        margin: theme.spacing(-0.3, 0, 0, 0),
        backgroundColor: theme.palette.grey[100],
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
    },
    progressBarDiv: {

    },
    barColorSecondary: props => ({
        background: props.progress <= 80 ? '#EAA341' : theme.palette.successGreen,

    }),
    passwordInfo: {
        margin: theme.spacing(1, 0, 0, 1),
    },
    info: {
        margin: theme.spacing(1, 0, 0, 0)
    },
    accepted: {
        color: theme.palette.successGreen
    },
    notAccepted: {
        color: theme.palette.primary.light,
    }
}));

function PasswordProgressBar(props = {}) {
    const { progress, hasOneUppercase, hasOneLowercase, hasMinLength,
        hasOneNumber, hasOneSpecialCharacter } = props;

    const classes = useStyles(props);
    return (
        <div className={classes.rootDiv}>
            <div className={classes.progressBarDiv}>
                <LinearProgress classes={{
                    root: classes.progress,
                    barColorSecondary: classes.barColorSecondary
                }} variant="determinate" color="secondary" value={progress} />
            </div>

            <Grid container xs={12} className={classes.passwordInfo}>
                <Grid item xs={4}><li className={hasOneUppercase ? classes.accepted : classes.notAccepted}><Typography variant='caption'><FormattedMessage {...intl.aCapitalLetter} /></Typography></li></Grid>
                <Grid item xs={4}><li className={hasOneSpecialCharacter ? classes.accepted : classes.notAccepted}><Typography variant='caption' ><FormattedMessage {...intl.aSpecialCharacter} /></Typography></li></Grid>
                <Grid item xs={4}><li className={hasMinLength ? classes.accepted : classes.notAccepted}><Typography variant='caption' ><FormattedMessage {...intl.atLeast8CharacterLong} /></Typography></li></Grid>
                <Grid item xs={4}><li className={hasOneLowercase ? classes.accepted : classes.notAccepted}><Typography variant='caption' ><FormattedMessage {...intl.aLowerCaseLetter} /></Typography></li></Grid>
                <Grid item xs={3}><li className={hasOneNumber ? classes.accepted : classes.notAccepted}><Typography variant='caption'><FormattedMessage {...intl.aNumber} /></Typography></li></Grid>
            </Grid>

        </div>
    )
}

export default PasswordProgressBar
