import { call, put, takeLatest } from "redux-saga/effects";
import { requestWithErrorFeedback } from "utils/request";
import { createEventSetDataAction, deleteEventSetDataAction } from "./eventSchedulerActions";
import { CREATE_EVENT, DELETE_EVENT } from "./eventSchedulerConstants";
import {
  SCHEDULED_PAYMENT_BASE_API_URL,
 } from "config/apiUrls";

const SCHEDULED_PAYMENT_URL = `${SCHEDULED_PAYMENT_BASE_API_URL}/scheduled-payments`;

export default function* eventSchedulerSaga() {
  // add all sagas here
  yield takeLatest(DELETE_EVENT, deleteEventSaga);
  yield takeLatest(CREATE_EVENT, createEventSaga);
}

export function* createEventSaga(args = {}) {
  const { body, cb } = args.data || {};
  const requestUrl = `${SCHEDULED_PAYMENT_URL}/recurring`;
  const options = { body, method: "POST" };

  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(createEventSetDataAction(result));
    // if (result.data && result.data.is_recurring) cb();
    result.ok && cb();
  } catch (error) {
    console.error("SOMETHING WENT WRONG", { error });
  }
}

export function* deleteEventSaga(args = {}) {
  const { eventScheduleId, cb = () => null } = args.data || {};
  const requestUrl = `${SCHEDULED_PAYMENT_URL}/${eventScheduleId}`;
  const options = { method: 'DELETE'}
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(deleteEventSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}
