/* eslint-disable eqeqeq */
import React, { memo } from 'react';
import { Avatar, IconButton, makeStyles } from '@material-ui/core';
import { Typography } from './ui';
import { getAvatarTxt } from 'utils/common';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import SelectRole from 'containers/Common/SelectRole';
import { useIntl } from 'react-intl';
import intl from 'containers/OnBoarding/intl/OnBoardingIntl';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
    select: {
        background: '#F2F3F4',
        borderRadius: theme.spacing(1),
        minHeight: '62px',
        padding: theme.spacing(2, 2),
        cursor: 'pointer',
        display: 'flex',
        '&:hover': {
            background: '#D2E8F1',
        },
        flexDirection: 'column',
        zIndex: 1,
        margin: theme.spacing(1, 0)
    },
    selected: {
        backgroundColor: '#D2E8F1',
        borderRadius: theme.spacing(1),
        minHeight: '62px',
        padding: theme.spacing(2, 2),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        margin: theme.spacing(1, 0)
    },
    txtDiv: {
        margin: theme.spacing(0, 0, 0, 0.8),
    },
    avatar: {
        height: theme.spacing(3.75),
        width: theme.spacing(3.75),
        marginTop: theme.spacing(0.5)
    },
    checkIcon: {
        color: theme.palette.accent.main,
        height: theme.spacing(2.7),
        width: theme.spacing(2.7),

    },
    unCheckIcon: {
        color: theme.palette.grey500,
        height: theme.spacing(2.7),
        width: theme.spacing(2.7),

    },
    mainRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    rowFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },
    inputDiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0),
        zIndex: 100
    },
    deleteIconDiv: {
        marginLeft: theme.spacing(2),
        padding: 0,
        marginTop: theme.spacing(-0.5)
    },
    deleteIcon: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        zIndex: 100
    },
}));


/* eslint-disable react/prop-types */
function ControllingPartySquare(props = {}) {

    const { id, onPressItem = () => null, data, controllingPartyArray = [], onChange = () => null, onDeleteParty = () => null } = props;
    const classes = useStyles();
    const fullName = data.entity_person_first_name + " " + data.entity_person_last_name;
    const intlStr = useIntl();

    const checkItem = (e) => {
        if (controllingPartyArray.includes(e)) {
            return true;
        }
    };
    const onDeleteItem = (index) => {
        onDeleteParty(index);
    };

    return (<>

        <div key={id} className={checkItem(id) ? classes.selected : classes.select}>

            <div className={classes.mainRow} >
                <div onClick={onPressItem} className={classes.rowFlex} >
                    <Avatar
                        className={classes.avatar}
                        classes={{ colorDefault: classes.avatarColor }}
                        alt="dp"
                    >
                        <Typography className={classes.roundTxt} color="grey700" variant="body2" >{getAvatarTxt(data, "entity")}</Typography>
                    </Avatar>
                    <Typography variant="body2" color="grey700" className={classes.txtDiv} >{fullName}</Typography>
                </div>

                <div className={classes.row} >
                    {checkItem(id) ? <div onClick={onPressItem} >  <RadioButtonCheckedIcon className={classes.checkIcon} /></div> : <div onClick={onPressItem}>  <RadioButtonUncheckedIcon className={classes.unCheckIcon} /> </div>}
                    {
                        data.entity_person_type !== "PRIMARY_CONTACT" && data.ownership_percentage && Number(data.ownership_percentage) < 25 && <IconButton onClick={() => { onDeleteItem(data); }} className={classes.deleteIconDiv} >
                            <DeleteOutlineIcon className={classes.deleteIcon} />
                        </IconButton>
                    }
                </div>

            </div>
            {
                checkItem(id) && <div className={classes.inputDiv}>
                    <SelectRole
                        id={id}
                        label={`${intlStr.formatMessage(intl.onBoardingStepRole)}`}
                        required
                        bgcolor="white"
                        name="contact_person_position"
                        errorText=""
                        value={data.contact_person_position}
                        onChange={(e) => { onChange(e); }}
                    />
                </div>
            }

        </div>
    </>);
}

ControllingPartySquare.defaultProps = {

};
ControllingPartySquare.propTypes = {


};


export default memo(ControllingPartySquare, (prevProps, nextProps) => (
    (JSON.stringify(prevProps.id) === JSON.stringify(nextProps.id)) &&
    (JSON.stringify(prevProps.controllingPartyArray) === JSON.stringify(nextProps.controllingPartyArray))

));
