import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalContactRequestBankState = state => state.contactRequestBank || {};
export const contactRequestBankState = () =>
  createSelector(GlobalState, substate => substate.contactRequestBank);

export const getRequestDetailState = () =>
  createSelector(globalContactRequestBankState, substate => substate.getRequestDetailState);

export const updateBankDetailState = () =>
  createSelector(globalContactRequestBankState, substate => substate.updateBankDetailState);
