import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Loader, StyledError } from 'components';
import { Grid, makeStyles } from '@material-ui/core';
import Logo from 'assets/images/logo/Truly_Logo_Light.png';
import VerificationSubmitted from 'containers/Verification/Common/VerificationSubmitted';
import IdVerificationInstruction from 'containers/Verification/Common/IdVerificationInstruction';
import ConfidentialAgreement from 'containers/Verification/Common/ConfidentialAgreement';
import ControllingPartyConsent from './Common/ControllingPartyConsent';
import { getPersonalDetailsAction } from 'store/Verification/verificationActions';
import { getPersonalDetailsState } from 'store/Verification/verificationSelectors';
import UpdateRequiredDetails from './Common/UpdateRequiredDetails';
import ChangeCurrentAddress from './Common/ChangeCurrentAddress';
import { ONBOARDING_STEPS } from '@truly-financial/common/consts/entity-person.consts';
import MailingAddress from './Common/MailingAddress';

const {
    ACCEPT_CONTROLLING_PARTY_CONSENT,
    ACCEPT_CONFIDENTIAL_AGREEMENT,
    ID_VERIFICATION,
    PERSONAL_DETAILS,
    PRIMARY_ADDRESS,
    COMMUNICATION_ADDRESS,
} = ONBOARDING_STEPS;

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        minHeight: `calc(100vh)`,
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    infoDiv: {
        textAlign: 'center',
        // margin: theme.spacing(10, 1),
    },
    logo: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'left',
        borderRadius: '0px 0px 10px 10px',
        minHeight: theme.spacing(10)
    },
    mainGrid: {
        flex: 1,
        display: 'flex',

    },
    subGrid: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    }
}));

function Verification(props = {}) {
    const classes = useStyles();
    const { personalDetailsState, getPersonalDetails } = props;

    const entityPersonData = personalDetailsState.data || {};
    const [step, setStep] = useState('');
    const isLoading = ((!entityPersonData.entity_person_id) || (personalDetailsState.loading))

    useEffect(() => {
        if (!entityPersonData.entity_person_id) getPersonalDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setStep(entityPersonData.verificationStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalDetailsState]);

    const handleStepChange = (s) => {
        if (s) return setStep(s);
        getPersonalDetails();
    };

    // if (!entityPersonData.entity_person_id && personalDetailsState.loading) {
    //     return (<>
    //         <Loader />
    //     </>);
    // }

    if (personalDetailsState.error || personalDetailsState.status === 404) {
        return (<>
            <StyledError data={personalDetailsState} retry={getPersonalDetails} />
        </>);
    }

    return (<div className={classes.root}>
        {isLoading && <Loader />}
        <div className={classes.logo}>
            <img alt="Truly" src={Logo} width="120" />
        </div>
        {personalDetailsState.loading && <Loader />}
        <Grid className={classes.mainGrid} container justify="center">
            <Grid className={classes.subGrid} xs={12} md={6} lg={4}>
                {step === ACCEPT_CONTROLLING_PARTY_CONSENT &&
                    (<ControllingPartyConsent
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {step === ACCEPT_CONFIDENTIAL_AGREEMENT &&
                    (<ConfidentialAgreement
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {step === ID_VERIFICATION &&
                    (<IdVerificationInstruction
                        isLoading={isLoading}
                        data={entityPersonData}
                        getPersonalDetails={getPersonalDetails}
                        verificationLink={entityPersonData.id_verification_link}
                        onStepChange={handleStepChange}
                    />)
                }
                {step === PERSONAL_DETAILS &&
                    (<UpdateRequiredDetails
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {step === PRIMARY_ADDRESS &&
                    (<ChangeCurrentAddress
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {step === COMMUNICATION_ADDRESS &&
                    (<MailingAddress
                        isLoading={isLoading}
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {!step && entityPersonData.entity_id &&
                    (<div className={classes.infoDiv}>
                        <VerificationSubmitted
                            data={entityPersonData}
                            showLoginBtn={false}
                        />
                    </div>)
                }
            </Grid>
        </Grid>
    </div>);
}

const mapStateToProps = createStructuredSelector({
    personalDetailsState: getPersonalDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getPersonalDetails: data => dispatch(getPersonalDetailsAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(Verification);
