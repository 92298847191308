import OnBoarding from 'containers/OnBoarding/OnBoarding';
import React from 'react';
import { Helmet } from 'react-helmet-async';


function Register(props = {}) {

    return (
        <>
            <Helmet >
                <title>Register</title>
                <meta name="description" content="Register page" />
            </Helmet>
            <OnBoarding />
        </>
    )

}

export default Register;