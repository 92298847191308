import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core';
// import useCommonStyles from 'styles';
import { Loader } from "components";

// const useStyles = makeStyles(theme => ({
//   root: {

//   },
// }));

function StyledIframe(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const [loading, setLoading] = useState(props.loading === undefined ? true : props.loading);
  // const classes = useStyles(props);
  const { src = "", style = {}, title = "", id, onLoad = () => null} = props;

  const onLoadHandler = () => {
    setTimeout(() => {
      setLoading(false);
      onLoad();
    }, 1200);

  };

  return (<div style={{
    height: "100%",
    width: "-webkit-fill-available",
    border: "none",
    ...style,
  }}>
    {loading && <Loader componentCenter />}
    <iframe
      id={id}
      style={{
        height: "100%",
        width: "-webkit-fill-available",
        border: "none",
        ...style,
      }}
      loading="lazy"
      onLoad={onLoadHandler}
      title={title}
      src={src}
      // scrolling="no"
    /></div>);
}

StyledIframe.defaultProps = {
  src: "",
};

StyledIframe.propTypes = {
  src: PropTypes.string,
};

export default memo(StyledIframe);
