import { aclListParser } from "./aclParser";
import {
    ACL_LIST,
    ACL_LIST_SET_DATA
} from "./aclConstants";

export function aclListAction() {
    return { type: ACL_LIST };
}

export function aclListSetDataAction(data) {
    return { type: ACL_LIST_SET_DATA, data: aclListParser(data) };
}