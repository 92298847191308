import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logout } from 'utils/auth';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
    },
}));

function NoEntityFound(props = {}) {
    const classes = useStyles();
    const history = useHistory();
    useEffect(() => {
        logout();
        setTimeout(() => {
            history.push("/login");
        }, 10000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (<>
        <div className={classes.root}>
            <div>
                <Typography variant="body1">No company found in this account, please contact administrator or <Link to="/login">Login</Link> with different account </Typography>
            </div>
        </div>
    </>);
}

export default NoEntityFound;