import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { Typography } from './ui';
import Loader from './Loader';
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "162px",
    textAlign: "center",
    position: "fixed",
    left: 0,
    right: 0,
    top: "46%",
    margin: "auto",
    zIndex: 9000,
  },
  linearProgressDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  linearProgress: {
    borderRadius: "100px",
  },
  imageDiv: {
    // height: "46px",
  },

  iconDiv: {
    display: "flex",
    justifyContent: "center",
  },

  iconDiv1: {
    height: "36px",
    background: theme.palette.primary.main,
    width: "24px",
    borderRadius: "2px",
    transform: "rotate(45deg)",
  },
  iconDiv2: {
    height: "36px",
    background: theme.palette.secondary.main,
    width: "24px",
    borderRadius: "2px",
    transform: "rotate(45deg)",
    marginLeft: "-10px",
    position: "relative",
  },
  iconCheckVertical: {
    width: "4px",
    height: "36px",
    background: "white",
    position: "absolute",
    left: "calc(50% - 2px)",
  },
  iconCheckHorizontal: {
    width: "4px",
    height: "12px",
    background: "white",
    position: "absolute",
    left: "4px",
    bottom: "-4px",
    transform: "rotate(89deg)",
  },
}));

function TrulyLoader(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { text = '' } = props;
  return (<Loader />);
  // eslint-disable-next-line no-unreachable
  return (<>
    <div className={classes.root}>
      <div className={classes.imageDiv}>
        {/* <img src={Logo} alt={'Truly'} /> */}
        <div className={classes.iconDiv}>
          <div className={classes.iconDiv1} />
          <div className={classes.iconDiv2}>
            <div className={classes.iconCheckVertical} />
            <div className={classes.iconCheckHorizontal} />
          </div>
        </div>
      </div>
      <div className={classes.linearProgressDiv}>
        <LinearProgress className={classes.linearProgress} />
      </div>
      {text && <div><Typography variant="subtitle1" color="grey700">{text}</Typography></div>}
    </div>
  </>);
}

TrulyLoader.defaultProps = {
};

TrulyLoader.propTypes = {
  test: PropTypes.string,
};

export default memo(TrulyLoader);
