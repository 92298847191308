
// import { StyledDrawer } from 'components';
import RegistrationComplete from 'containers/RegistrationComplete/RegistrationComplete';
// import BaseLayout from 'layouts/baseLayout';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function RegistrationCompletePage(props = {}) {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
    }, []);

    return (<>
        <Helmet>
            <title>RegistrationComplete</title>
            <meta name="description" content="Description of RegistrationComplete" />
        </Helmet>

        {/* <StyledDrawer role={1} /> */}
        {/* <BaseLayout> */}
        <RegistrationComplete />
        {/* </BaseLayout> */}

    </>);
}

export default RegistrationCompletePage;
