/* eslint-disable eqeqeq */

import React, { memo, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { NavLink, } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from "@material-ui/icons/Settings";
import { FormattedMessage } from "react-intl";
import AppHeader from "components/AppHeader";
// import Logout from 'components/Logout';
import intl from "./intl/StyledDrawerIntl";
import { IconButton, useMediaQuery } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import SelectEntity from "containers/Common/SelectEntity";
// import { styledSnackbar } from 'containers/Common/Snackbar';
// import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
// import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
// import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
// import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import combineClx from 'utils/combineClx';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Typography } from './ui';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Logo from 'assets/images/logo/Truly_Logo_GreenSvg.svg';
import HeaderNotification from './HeaderNotification';


const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: theme.drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      width: `calc(100%)`,
    },
    height: theme.appHeaderHeight,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  // necessary for content to be below app bar
  // toolbar: theme.mixins.toolbar,
  drawerPaper: (props) => ({
    width: theme.drawerWidth,
    // marginTop: theme.appHeaderHeight,
    // position: "inherit",
    zIndex: 0,
    backgroundColor: theme.palette.primary.main,
    marginTop: props.showNotification ? "64px" : "auto",
    paddingBottom: props.showNotification ? "64px" : "0",
    // height: `calc(100% - 80px)`,
    // height: `calc(100% - ${theme.appHeaderHeight})`,
    // height: `calc(100vh - ${theme.appHeaderHeight})`,
    border: 0,
  }),
  options: {
    display: "flex",
    flex: 1,
  },
  list: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 0,
  },
  listItem: {
    display: "flex",
  },
  setting: {
    display: "flex",
    marginTop: "auto",
  },
  menuIcon: {
    fontSize: theme.spacing(3.125),
    marginLeft: theme.spacing(2.25),
  },
  menuIconWithoutMargin: {
    fontSize: theme.spacing(3.125),
    marginLeft: theme.spacing(2.25),
  },
  listName: {
    marginLeft: theme.spacing(3),
    // '& span:hover': {
    //   fontWeight: 'bold !important',
    // }
  },
  listNameBottom: {
    marginLeft: theme.spacing(1),
    // '& span:hover': {
    //   fontWeight: 'bold !important',
    // }
  },
  entitySelect: {
    // margin: theme.spacing(4, 3, 8, 0),
    margin: theme.spacing(2, 3, 8, 0),
  },
  ListItemDiv: {
    // borderRadius: '0px 8px 8px 0px !important',
    borderRadius: `${theme.spacing(0, 1, 8, 0)} !important`,
    paddingLeft: theme.spacing(2),
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)",
    },
    display: "flex",
    // alignItems: 'flex-start'
  },
  ListItemDiv1: {
    // borderRadius: '0px 8px 8px 0px !important',
    borderRadius: `${theme.spacing(0, 1, 8, 0)} !important`,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)",
    },
    display: "flex",
    alignItems: "center",
  },
  selectedMenuClass: {
    // fontWeight: 'bold'
  },
  iconImg: {
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      // marginLeft: theme.spacing(1.5),

      height: theme.spacing(5.25),

      width: theme.spacing(14.875),
    },

    height: theme.spacing(3.875),

    width: theme.spacing(11.25),
  },
  drawerImage: {
    margin: theme.spacing(4, 0, 2, 4),
  },
  mobileDrawerClose: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeIcon: {
    color: theme.palette.background.default,
  },
}));

function StyledDrawer(props = {}) {
  const allMenus = {
    ACCOUNT: {
      label: <FormattedMessage {...intl.account} />,
      path: "/accounts",
    },
    CONTACT: {
      label: <FormattedMessage {...intl.contact} />,
      path: "/contacts",
    },
    PAYMENT: {
      label: <FormattedMessage {...intl.payment} />,
      path: "/payments",
    },
    TEAM_MEMBER: {
      label: <FormattedMessage {...intl.teamMember} />,
      path: "/team-members",
    },
    // DASHBOARD: {
    //   label: <FormattedMessage {...intl.dashBoard} />,
    //   path: '/dashboard'
    // },
    HOME: {
      label: <FormattedMessage {...intl.home} />,
      path: "/home",
    },
  };
  const topMenuCodeArray = ["HOME", "ACCOUNT", "CONTACT", "PAYMENT"];
  const bottomMenuCodeArray = ["TEAM_MEMBER"];
  const theme = useTheme();
  const { access, selectedEntity, updateUserLocalSettings } = props;
  // const showNotification = !selectedEntity?.data?.user_entity_settings?.hideNotification;
  const showNotification = false;
  const classes = useStyles({...props, showNotification});
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState({});
  const [bottomMenus, setBottomMenus] = React.useState([]);
  const [topMenus, setTopMenus] = React.useState([]);

  useEffect(() => {
    const topMenu = [];
    const bottomMenu = [];
    if (access && access.length) {
      topMenuCodeArray.forEach((a) => {
        access.forEach((b) => {
          if (b.trulyModuleCode === a) {
            topMenu.push(allMenus[b.trulyModuleCode]);
          }
        });
      });
      bottomMenuCodeArray.forEach((a) => {
        access.forEach((b) => {
          if (b.trulyModuleCode === a) {
            bottomMenu.push(allMenus[b.trulyModuleCode]);
          }
        });
      });
      setTopMenus(topMenu);
      setBottomMenus(bottomMenu);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  const handleStyledDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const onEntityCloseCallBack = () => {};
  const optionIcons = (m) => {
    // if (m.path === "/dashboard") {
    //   return selectedMenu.path === m.path ? <ViewQuiltIcon className={classes.menuIcon} /> : <ViewQuiltOutlinedIcon className={classes.menuIcon} />;
    // }
    if (m.path === "/home") {
      return selectedMenu.path === m.path ? (
        <HomeIcon className={classes.menuIcon} />
      ) : (
        <HomeOutlinedIcon className={classes.menuIcon} />
      );
    }
    if (m.path === "/accounts") {
      return selectedMenu.path === m.path ? (
        <TrendingUpIcon className={classes.menuIcon} />
      ) : (
        <TrendingUpIcon className={classes.menuIcon} />
      );
    }
    if (m.path === "/contacts") {
      return selectedMenu.path === m.path ? (
        <PersonIcon className={classes.menuIcon} />
      ) : (
        <PersonOutlineIcon className={classes.menuIcon} />
      );
    }
    if (m.path === "/payments") {
      return selectedMenu.path === m.path ? <MonetizationOnIcon className={classes.menuIcon} /> : <MonetizationOnOutlinedIcon className={classes.menuIcon} />;
    }
    if (m.path === "/team-members") {
      return selectedMenu.path === m.path ? (
        <PeopleIcon className={classes.menuIconWithoutMargin} />
      ) : (
        <GroupOutlinedIcon className={classes.menuIconWithoutMargin} />
      );
    }
    return selectedMenu.path === m.path ? (
      <SettingsIcon className={classes.menuIconWithoutMargin} />
    ) : (
      <SettingsOutlinedIcon className={classes.menuIconWithoutMargin} />
    );
  };

  const onOptionPress = (match) => {
    if (!match && match !== undefined) {
      handleStyledDrawerToggle();
    }
  };

  const isMobileRes = useMediaQuery(theme.breakpoints.down("sm"));

  const drawerList = (match) => {
    return (
      <div className={classes.options}>
        <List className={classes.list}>
          {topMenus.length > 0 &&
            topMenus.map((m, index) => (
              <NavLink
                className={classes.listItem}
                key={index}
                activeStyle={{
                  fontWeight: "bold !important",
                  color: theme.palette.primary.main,
                  backgroundImage:
                    "linear-gradient(97.05deg, #F3D1B3 2.81%, #A5D8C5 99.34%)",
                }}
                style={{
                  color: theme.palette.primary.contrastText,
                  textDecoration: "none",
                  marginRight: theme.spacing(3),
                  marginLeft: 0,
                  marginBottom: 0,
                  // paddingLeft: index > 14== ? theme.spacing(5) : 0,
                  borderRadius: theme.spacing(0, 1, 1, 0),
                }}
                to={m.path}
                isActive={(match, location) => {
                  if (match && match.url === m.path) {
                    setSelectedMenu(m);
                    // !selectedMenu.path && setSelectedMenu(m);
                    return true;
                  }
                  return false;
                }}
              >
                <ListItem
                  disableGutters
                  className={classes.ListItemDiv}
                  onClick={() => {
                    onOptionPress(match);
                  }}
                  button
                  href=""
                >
                  {optionIcons(m)}
                  <ListItemText
                    className={combineClx(
                      classes.listName,
                      selectedMenu.path === m.path && classes.selectedMenuClass
                    )}
                    primary={
                      <span
                        style={
                          selectedMenu.path === m.path
                            ? { fontWeight: "bold" }
                            : {}
                        }
                      >
                        {m.label}
                      </span>
                    }
                  />
                </ListItem>
              </NavLink>
            ))}
          {bottomMenus.length > 0 &&
            bottomMenus.map((m, index) => (
              <NavLink
                className={index === 0 ? classes.setting : classes.listItem}
                key={index}
                activeStyle={{
                  fontWeight: "bold !important",
                  color: theme.palette.primary.main,
                  backgroundImage:
                    "linear-gradient(97.05deg, #F3D1B3 2.81%, #A5D8C5 99.34%)",
                }}
                style={{
                  color: theme.palette.primary.contrastText,
                  textDecoration: "none",
                  marginRight: 0,
                  marginLeft: theme.spacing(2.5),
                  marginBottom:
                    bottomMenus.length === index + 1
                      ? isMobileRes
                        ? window.innerHeight / 8
                        : theme.spacing(4)
                      : 0,
                  borderRadius: theme.spacing(1, 0, 0, 1),
                }}
                to={m.path}
                isActive={(match, location) => {
                  if (match && match.url === m.path) {
                    setSelectedMenu(m);
                    // !selectedMenu.path && setSelectedMenu(m);
                    return true;
                  }
                  return false;
                }}
              >
                <ListItem
                  disableGutters
                  className={classes.ListItemDiv1}
                  onClick={() => {
                    onOptionPress(match);
                  }}
                  button
                  href=""
                >
                  {optionIcons(m)}
                  <ListItemText
                    className={combineClx(
                      classes.listName,
                      selectedMenu.path === m.path && classes.selectedMenuClass
                    )}
                    primary={
                      <span
                        style={
                          selectedMenu.path === m.path
                            ? { fontWeight: "bold" }
                            : {}
                        }
                      >
                        {m.label}
                      </span>
                    }
                  />
                </ListItem>
              </NavLink>
            ))}
        </List>
      </div>
    );
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;
  const showDrawer = useMediaQuery(
    theme.breakpoints.up(theme.showDrawerBreakPointUp)
  );
  
  return (<>
    {showNotification ? <HeaderNotification showNotification={showNotification} updateUserLocalSettings={updateUserLocalSettings} /> : null}
    <div className={classes.root}>
      <AppHeader
        showNotification={showNotification}
        menuOpen={handleStyledDrawerToggle}
        selectedMenu={selectedMenu}
      />


      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* Drawer for mobile  View */}
        {!showDrawer && (
          <Drawer
            selectedEntity={selectedEntity}
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleStyledDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
              <div className={classes.mobileDrawerClose}>
                <Typography variant="h6" className={classes.drawerImage} noWrap>
                  <img src={Logo} className={classes.iconImg} alt="Truly" />
                </Typography>
                <div>
                <IconButton className={classes.closeIcon} onClick={handleStyledDrawerToggle}>
                <CloseOutlinedIcon />
              </IconButton>
                </div>
              </div>
              <div className={classes.entitySelect}>
                <SelectEntity onCloseCallBack={onEntityCloseCallBack} />
              </div>
            {drawerList(showDrawer)}
          </Drawer>
        )}

        {/* Drawer for web  View */}
        {showDrawer && (
          <Drawer
            selectedEntity={selectedEntity}
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
             <Typography variant="h6" className={classes.drawerImage} noWrap>
                <img src={Logo} className={classes.iconImg} alt="Truly" />
              </Typography>
              <div className={classes.entitySelect}>
                <SelectEntity onCloseCallBack={onEntityCloseCallBack} />
              </div>

            {drawerList(showDrawer)}
          </Drawer>
        )}
      </nav>
    </div>
    </>);
}

StyledDrawer.propTypes = {};

export default memo(StyledDrawer);
