
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Typography } from 'components/ui';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    height: theme.spacing(5),
    backgroundColor: theme.palette.grey.main,
    borderRadius: theme.spacing(0.75),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%'
  },
  labletxt: {
    justifyContent: 'space-between'
  },
  itemName: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: "rgba(33, 33, 33, 0.08)",
    borderBottomRightRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    minHeight: theme.spacing(5)
  },
  eachitem: {
    margin: theme.spacing(1)
  },
  placholderTxt: {
    color: theme.palette.text.disabled
  },
  selectedTxt: {
    color: theme.palette.primary.main
  },
  eachitemDiv: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey.dark
    }

  }
}));

function StyledDropDown(props) {
  const classes = useStyles();
  // const theme = useTheme();

  return (
    <>
      <Button
        classes={{ label: classes.labletxt }}
        endIcon={props.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        className={classes.root} aria-controls={`simple-menu-${props.id}`} aria-haspopup="true" onClick={props.handleClick}>
        {props.selectedValue !== undefined && props.open !== null ?
          <Typography variant="subtitle2" className={classes.selectedTxt} >{PropTypes.selectedValue}</Typography> :
          <Typography variant="subtitle2" className={classes.placholderTxt} >{props.placeholder}</Typography>}
      </Button>
      <Collapse in={props.open} timeout="auto" unmountOnExit>
        <div className={classes.itemName} >
          {
            Array.isArray(props.data) && props.data.map((item, index) => {
              return (
                <div className={classes.eachitemDiv} onClick={() => { props.itemPress(item, index) }} >
                  <Typography className={classes.eachitem}>{item.name}</Typography>
                </div>
              )
            })
          }

        </div>

      </Collapse>
    </>
  );
}
StyledDropDown.defaultProps = {
  placeholder: "",
  itemPress: () => { },
  handleClick: () => { },
  selectedValue: ""
}
StyledDropDown.propTypes = {
  placeholder: PropTypes.string,
  selectedValue: PropTypes.string,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  data: PropTypes.any,
  itemPress: PropTypes.func
};

export default memo(StyledDropDown);
