
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Avatar, makeStyles, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import TextField from 'components/ui/TextField';
import Autocomplete from 'components/ui/Autocomplete';
import { getCountryListAction } from 'store/Reference/referenceActions';
import { getCountryListState } from 'store/Reference/referenceSelectors';
import { flag } from 'utils/flag';
import { Typography } from 'components/ui';
import MaskedInput from 'react-text-mask';
import { createFilterOptions } from '@material-ui/lab';
// import MaskedInput from 'react-text-mask';

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        background: theme.palette.background.default,
        filter: `drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))`
    },
    flagDiv: {
        marginLeft: theme.spacing(.5)
    },
    autoCompleteRoot: {
        '& .MuiFormControl-root': {
            padding: `${theme.spacing(0)} !important`,
            height: '100%',
            '& .MuiAutocomplete-inputRoot': {
                height: '100%',
            }
        },

        borderTopLeftRadius: theme.spacing(0.75),
        borderBottomLeftRadius: theme.spacing(0.75),
        borderRadius: 0,
        height: '100%'

    },
    flagOption: {
        marginRight: theme.spacing(0.5)
    }
}));

function countryToFlag(isoCode = "") {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : '';
}

function PhoneInput(props = {}) {
    const classes = useStyles();
    const ref = useRef(null);
    const [optionListWidth, setOptionListWith] = useState();
    const theme = useTheme();
    const [country, setCountry] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [mobile, setMobile] = useState(props.mobileValue || '');
    const [textFieldFocus, setTextFieldFocus] = useState(false);
    const { countries } = props;

    useEffect(() => {
        setOptionListWith(ref && ref.current ? ref.current.offsetWidth : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    const getSelectedCountry = useCallback(() => {
        return countries.data.find(i => i.country_code === props.mobileCountryValue) || {};
    }, [countries, props.mobileCountryValue]);


    const setDefaultCountry = useCallback(() => {
        let defaultSelectedCountry = {};
        if (countries && Array.isArray(countries.data) && props.mobileCountryValue) {
            // defaultSelectedCountry = countries.data.find(i => i.country_code === props.mobileCountryValue) || {};
            defaultSelectedCountry = getSelectedCountry();
        }
        setCountry(defaultSelectedCountry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, props.mobileCountryValue]);


    useEffect(() => {
        let dialingCode = country.dialing_code || '';
        if (!dialingCode && props.mobileCountryValue) {
            const s = getSelectedCountry();
            s.dialing_code && setCountry(s);
        }
        props.onChange && props.onChange({
            target: {
                id: props.phoneNumberId,
                name: props.phoneNumberName,
                value: `${dialingCode || ""}${props.mobileValue || ""}`,
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country, props.mobileValue]);

    useEffect(() => {
        setDefaultCountry();
    }, [countries.data, setDefaultCountry]);


    const onDialingCodeChange = (e, val = {}) => {
        setCountry({});
        setTimeout(() => {
            setCountry(val || {});
        }, 0);
        props.onChange && props.onChange({
            target: {
                id: props.mobileCountryId,
                name: props.mobileCountryName,
                value: val && val.country_code,
            }
        });
        setTimeout(() => {
            props.onChange && props.onChange({
                target: {
                    id: props.mobileDialingCodeId,
                    name: props.mobileDialingCodeName,
                    value: val && val.dialing_code,
                }
            });
            setTextFieldFocus(true);
        }, 100);

        setTimeout(() => {
            setTextFieldFocus(false);
        }, 300);
    };

    const onInputChange = (e) => {
        const { value } = e.target || {};
        setMobile(value || "");
        props.onChange && props.onChange({
            target: {
                id: props.mobileId,
                name: props.mobileName,
                value: value.replace(/[" "]/g, '').replace(/[^a-zA-Z0-9]/g, ''),
            }
        });
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option.country_code + option.country_name + option.dialing_code,
      });

    return (<>
        <div ref={ref} style={{ display: 'flex', flex: 1, }}>
            <div style={{ width: theme.spacing(14.375), }}>
                <Autocomplete
                    {...props}
                    filterOptions={filterOptions}
                    optionListWidth={optionListWidth}
                    getOptionDisabled={(option) => !option.dialing_code}
                    placeholder={props.mobileCountryPlaceholder}
                    errorText={!props.mobileCountryValue || !props.mobileValue ? props.errorText : ''}
                    className={classes.autoCompleteRoot}
                    id={props.mobileCountryId}
                    name={props.mobileCountryName}
                    value={props.mobileCountryValue}
                    hiddenLabel
                    getOptions={props.getCountries}
                    options={countries}
                    onBlur={props.onBlur}
                    onChange={onDialingCodeChange}
                    selectKey="country_code"
                    labelKey="country_name"
                    bgcolor="#eaebec"
                    customStyle={{
                        width: theme.spacing(15),
                        borderRadius: theme.spacing(0.75, 0, 0, 0.75),
                        paddingRight: `${theme.spacing(3.125)}px !important`,
                    }}
                    startAdornment={(
                        <React.Fragment>
                            <span className={classes.flagDiv}><Avatar alt="flag" src={flag(country.country_code)} className={classes.small} /></span> &nbsp;
                        </React.Fragment>
                    )
                    }

                    getOptionLabel={(option) => props.mobileCountryValue === option.country_code ? (option.dialing_code || '') : `${option.dialing_code}
                    ${option.country_code} ${option.country_name}`}
                    renderOption={(option) => (
                        <React.Fragment>
                            <span className={classes.flagOption} >{countryToFlag(option.country_code)}</span> &nbsp;
                            <span>
                                ({option.dialing_code})-{option.country_name}
                            </span>

                        </React.Fragment>
                    )}
                />
            </div>
            <div style={{ flex: 3 }} >
                <TextField
                    {...props}
                    type="tel"
                    errorText={props.mobileCountryValue && !props.mobileValue ? props.errorText : ''}
                    id={props.mobileId}
                    name={props.mobileName}
                    placeholder={props.mobilePlaceholder}
                    label={props.mobileLabel}
                    value={props.mobileValue}
                    helperText={props.helperText}
                    makeFocus={textFieldFocus}
                    customStyle={{
                        borderRadius: theme.spacing(0, 0.75, 0.75, 0),
                    }}
                    CustomInputProps={{
                        inputComponent: TextMaskCustom,
                    }}
                    inputProps={{
                        maxlength: 17,
                        minlength: 13,
                    }}
                    onChange={onInputChange}

                />
            </div>


        </div>
        {
            props.helperTextReg && <Typography variant="caption" color="grey500" >{props.helperTextReg}</Typography>
        }
    </>)
};

const mapStateToProps = createStructuredSelector({
    countries: getCountryListState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getCountries: data => dispatch(getCountryListAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(PhoneInput, (p, n) => {
    return (
        JSON.stringify(p.countries) === JSON.stringify(n.countries) &&
        p.mobileValue === n.mobileValue &&
        p.mobileCountryValue === n.mobileCountryValue &&
        p.noPadding === n.noPadding &&
        p.error === n.error &&
        p.required === n.required &&
        p.disabled === n.disabled
    );
});


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            guide={false}
        // showMask
        />
    );
}