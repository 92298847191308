import { Divider, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { StyledTabPanel } from 'components';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    helperTextRootDiv: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: 'space-between',
        margin: theme.spacing(1)

    },
    helperTextEachDiv: {
        display: "flex",
        alignItems: 'center',
        textAlign: 'center',
        flex: 1,
        justifyContent: 'center'
    },

    switchRoot: props => ({
        display: "flex",
        flex: 1,
        flexDirection: "row",
        height: props.type === "fill" ? theme.spacing(4.50) : theme.spacing(5.5),
        borderRadius: theme.spacing(25),
        border: props.type === "fill" ? "1px solid #F2F3F4" : "1px solid rgba(0, 0, 0, 0.12)", // to be fixed
        cursor: 'pointer',
    }),
    switchSingle: props => ({
        display: "flex",
        flex: 1,
        flexDirection: "row",
        height: props.type === "fill" ? theme.spacing(4.50) : theme.spacing(5.5),
        borderRadius: theme.spacing(25),
        // border: props.type === "fill" ? "1px solid #F2F3F4" : "1px solid rgba(0, 0, 0, 0.12)", // to be fixed
        justifyContent: 'center',
        cursor: 'pointer',
    }),
    selectedBtnRight: props => ({
        flex: 1,
        backgroundColor: props.type === "fill" ? theme.palette.primary.main : theme.palette.secondary.main,
        borderRadius: theme.spacing(25),
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        border: props.type === "fill" ? `1px solid ${theme.palette.primary.main} ` : `1px solid ${theme.palette.secondary.main} `,
        height: props.type === "fill" ? theme.spacing(4.50) : theme.spacing(5.5),
        marginTop: '-1px',
        marginRight: '-1px',
    }),
    selectedBtnLeft: props => ({
        flex: 1,
        backgroundColor: props.type === "fill" ? theme.palette.primary.main : theme.palette.secondary.main,
        borderRadius: "200px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        border: props.type === "fill" ? `1px solid ${theme.palette.primary.main} ` : `1px solid ${theme.palette.secondary.main} `,
        height: props.type === "fill" ? theme.spacing(4.50) : theme.spacing(5.5),
        marginTop: '-1px',
        marginLeft: '-1px',
    }),
    selectedBtnLeftSingleItem: props => ({

        backgroundColor: props.type === "fill" ? theme.palette.primary.main : theme.palette.secondary.main,
        borderRadius: "200px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        // border: props.type === "fill" ? `1px solid ${theme.palette.primary.main} ` : `1px solid ${theme.palette.secondary.main} `,
        height: props.type === "fill" ? theme.spacing(4.50) : theme.spacing(5.5),
        width: props.type === "fill" ? theme.spacing(4.50) : theme.spacing(5.5),
        // marginTop: '-1px',
        // marginLeft: '-1px',
    }),
    unselectBtn: props => ({
        flex: 1,
        backgroundColor: props.type === "fill" ? theme.palette.primary.contrastText : theme.palette.grey[50],
        borderRadius: "200px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    }),
    actionComponent: {
        marginRight: theme.spacing(-2.25),
    },
    indicator: {
        backgroundColor: "white"
    },
    rootIndicator: {
        '& .MuiTab-wrapper': {
            alignItems: 'flex-start'
        },
        '& .MuiTab-root': {
            padding: 0,
            minWidth: 0,
            margin: theme.spacing(0, 2.5, 0, 0)
        },
        '& .MuiTab-textColorInherit.Mui-selected span': {
            color: theme.palette.grey[700]
        },
        '& .MuiTab-textColorInherit span': {
            color: theme.palette.grey[200]
        },
    },
    selectedFontColor: props => ({
        color: props.type === "fill" ? theme.palette.primary.contrastText : theme.palette.grey[700],
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            fontSize: theme.spacing(1.2),
            width: theme.spacing(14),
            textAlign: 'center'
        },

    }),
    unSelectedFontColor: {
        color: theme.palette.grey500,
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            fontSize: theme.spacing(1.2),
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: theme.spacing(14),
            textAlign: 'center'
        },


    },
    tab: {
        color: theme.palette.primary.main,
        '& .makeStyles-rootIndicator-2154 .MuiTab-textColorInherit span': {
            color: theme.palette.primary.main
        }
    },
    tabPanel: {
        flex: 1
    },

}));

export function SectionTabs(props = {}) {

    const { heading, actionComponent, actionVisitable = [0, 1], mobileTab = false, onChangeTab, type = "fill", helperText = false, singleItemEffect = false } = props;
    const classes = useStyles({ type: type });
    const tabs = (Array.isArray(props.tabs) && props.tabs.filter(i => i)) || [];
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onChangeTab && onChangeTab(newValue);
    };
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    const onContactTypeChange = (value) => {
        onChangeTab && onChangeTab(value);
        setValue(value);
        //setFormData(formData => ({ ...formData, entity_type: value }));
    };

    return (<>
        <div className={classes.root}>
            {
                ((match || mobileTab) && tabs.length > 1) ? <div className={tabs.length === 1 && singleItemEffect ? classes.switchSingle : classes.switchRoot} >
                    {
                        tabs.map((item, index) => {
                            return (
                                <div key={index} onClick={item.disabled ? null : () => onContactTypeChange(index)} className={value === index ? tabs.length === 1 && singleItemEffect ? classes.selectedBtnLeftSingleItem : classes.selectedBtnLeft : classes.unselectBtn} {...a11yProps(index)} >
                                    <Typography variant="button" className={value === index ? classes.selectedFontColor : classes.unSelectedFontColor}  >{item.heading || heading}</Typography>
                                </div>
                            )
                        })
                    }
                </div> : <> <Tabs classes={{ indicator: classes.indicator }} className={classes.rootIndicator} value={value} onChange={handleChange} aria-label="simple tabs example">
                    {
                        tabs.map((item, index) => {

                            return (
                                <Tab disableRipple={true} key={index} label={<Typography color={props.color || 'grey700'} variant={props.variant || "titleMobile"} >{item.heading || heading}</Typography>} {...a11yProps(index)} />
                            )
                        })
                    }
                </Tabs>
                </>
            }

            {
                actionComponent && actionVisitable.includes(value) ?
                    <>{actionComponent}</> : <></>
            }
        </div>
        {
            type === "outlined" && helperText &&
            <div className={classes.helperTextRootDiv} >
                {
                    tabs.map((item, index) => {
                        return (
                            <div key={index} className={classes.helperTextEachDiv}>
                                {
                                    item.bottomTxt && <Typography variant="subtitle2" className={value === index ? classes.selectedFontColor : classes.unSelectedFontColor}  >{item.bottomTxt}</Typography>
                                }

                            </div>
                        )
                    })
                }
            </div>


        }

        {(!mobileTab && !match) && <Divider />}
        {
            tabs.map((item, index) => {

                return (
                    <StyledTabPanel className={classes.tabPanel} key={index} value={value} index={index}>
                        {item.content}
                    </StyledTabPanel>
                )
            })
        }
    </>)
}
SectionTabs.defaultProps = {
    heading: "",
    actionComponent: null
}

SectionTabs.propTypes = {
    heading: PropTypes.string,
    actionComponent: PropTypes.element
};

export default SectionTabs;
