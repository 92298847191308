
import { defineMessages } from 'react-intl';

export const scope = 'src.components.card_details';

export default defineMessages({
  accounts: {
    id: `${scope}.accounts`,
    defaultMessage: 'Account',
  },
  monthlyLimit: {
    id: `${scope}.accounts`,
    defaultMessage: 'Monthly LIMIT',
  },
  balance: {
    id: `${scope}.balance`,
    defaultMessage: 'BALANCE'
  },
  expand: {
    id: `${scope}.expand`,
    defaultMessage: 'Expand',
  },
  card_details: {
    id: `${scope}.card_details`,
    defaultMessage: 'CARD DETAILS',
  },
  cvv: {
    id: `${scope}.cvv`,
    defaultMessage: 'CVV',
  },
  card_number: {
    id: `${scope}.card_number`,
    defaultMessage: 'Card Number',
  },
  expiration: {
    id: `${scope}.expiration`,
    defaultMessage: 'Expiration',
  },
  billing_address: {
    id: `${scope}.billing_address`,
    defaultMessage: 'Billing Address',
  },
});
