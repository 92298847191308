
import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalReferencesState = state => state.references || {};

export const referencesState = () =>
  createSelector(GlobalState, substate => substate.references);

export const getCountryListState = () =>
  createSelector(globalReferencesState, substate => substate.getCountryListState);

export const getDestinationCountryListState = () =>
  createSelector(globalReferencesState, substate => substate.getDestinationCountryListState);

export const getRelationshipListState = () =>
  createSelector(globalReferencesState, substate => substate.getRelationshipListState);

export const getCurrencyListState = () =>
  createSelector(globalReferencesState, substate => substate.getCurrencyListState);

export const getPurposeListState = () =>
  createSelector(globalReferencesState, substate => substate.getPurposeListState);

export const searchLocationState = () =>
  createSelector(globalReferencesState, substate => substate.searchLocationState);

export const getBankAccountConfigState = () =>
  createSelector(globalReferencesState, substate => substate.getBankAccountConfigState);

export const getStateListState = (c = 'ALL') =>
  createSelector(globalReferencesState, substate => substate.getStateListState[c]);

export const getPaymentStatusState = () =>
  createSelector(globalReferencesState, substate => substate.getPaymentStatusState);

export const getRoleListState = () =>
  createSelector(globalReferencesState, substate => substate.getRoleListState);
export const getJurisdictionListState = () =>
  createSelector(globalReferencesState, substate => substate.getJurisdictionListState);

export const getBusinessTypeListState = () =>
  createSelector(globalReferencesState, substate => substate.getBusinessTypeListState);

export const getNumberOfEmployeesListState = () =>
  createSelector(globalReferencesState, substate => substate.getNumberOfEmployeesListState);

export const getIndustriesListState = () =>
  createSelector(globalReferencesState, substate => substate.getIndustriesListState);


export const getBusinessBelongListState = () =>
  createSelector(globalReferencesState, substate => substate.getBusinessBelongListState);

export const getPrimaryUseCaseListState = () =>
  createSelector(globalReferencesState, substate => substate.getPrimaryUseCaseListState);


export const getPrimarySourceOfFundingListState = () =>
  createSelector(globalReferencesState, substate => substate.getPrimarySourceOfFundingListState);
export const getDocumentTypeListState = () =>
  createSelector(globalReferencesState, substate => substate.getDocumentTypeListState);

export const getAccessRoleListState = () =>
  createSelector(globalReferencesState, substate => substate.getAccessRoleListState);
