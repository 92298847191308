import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Typography } from 'components/ui';
import { Checkbox, FormControlLabel, FormGroup, Grid, makeStyles } from '@material-ui/core';
import successStatus from 'assets/images/successStatus.png';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from 'containers/TeamMembers/intl/TeamMembersIntl';
import { Loader } from 'components';
import authorizeAcc from 'assets/images/authorizeAcc.png';
import { acceptControllingPartyConsentAction } from 'store/Verification/verificationActions';
import { acceptControllingPartyConsentState } from 'store/Verification/verificationSelectors';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
        padding: theme.spacing(0, 1.625),
        display: 'flex',
        flexDirection: 'column'
    },
    wrightDiv: {
        height: theme.spacing(11),
        width: theme.spacing(11),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: `url(${successStatus})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',

    },
    titleDiv: {
        margin: theme.spacing(5, 0, 2, 0),
        textAlign: 'center',
    },
    img: {
        height: theme.spacing(15.5),
        width: theme.spacing(14.625),
        margin: theme.spacing(0, 0, 2, 0)
    },
    desc: {
        margin: theme.spacing(2, 0, 2, 0)
    },
    checkBoxDiv: {
        margin: theme.spacing(2, 0, 2.25, 0),
        alignSelf: 'flex-start'
    },
    btn: {
        margin: theme.spacing(0, 0, 2, 0),
        flex: 1
    },
    mainGrid: {
        flex: 1
    },
    btnDiv: {
        flex: 1,
        width: 'inherit',
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-end',
            marginBottom: theme.spacing(3)
        }

    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    authorizeAccImg: {
        height: theme.spacing(11),
        width: theme.spacing(11),
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    nameDiv: {
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.accent.main}`,
        borderRadius: theme.spacing(1),
        backgroundColor: '#EFF7FA'
    },
    accentColor: {
        color: theme.palette.accent[700]
    },
    accentColorSubTitle2: {
        ...theme.typography.subtitle2,
        color: theme.palette.accent[700]
    }
}));

function ControllingPartyConsent(props = {}) {
    const classes = useStyles();
    const { data, onStepChange, controllingPartyConsentState, acceptControllingPartyConsent, isLoading } = props;
    const personalDetails = data || {};
    const [termCondition, setTermCondition] = useState(false);
    const intlTxt = useIntl();
    const firstName = (personalDetails?.primary_person_first_name) || "";
    const lastName = (personalDetails?.primary_person_last_name) || "";
    const businessName = (personalDetails?.invited_for) || "";
    const isEntityPersonPrimary = (personalDetails?.entity_person_type === "PRIMARY_CONTACT" || (!personalDetails?.is_smartlink_activation_required && personalDetails?.entity_person_type === "TEAM_MEMBER")) ? true : false;
    const isLoadingData = (isLoading) || (controllingPartyConsentState?.loading);

    const onCheckBoxPress = (e, index) => {
        setTermCondition(e.target.checked);
    };
    const onSuccessCallBack = () => {
        onStepChange();
    };

    const onContinueClick = () => {
        acceptControllingPartyConsent({ personId: personalDetails.entity_person_id, token: personalDetails.continue_signup_token, cb: onSuccessCallBack });
    };

    return (<>
        {isLoadingData && <Loader />}
        <div className={classes.root}>
            <Grid container direction="column" justify="center" alignItems="center" className={classes.mainGrid} >
                <img src={authorizeAcc} alt="authorize_acc" className={classes.authorizeAccImg} />
                <div className={classes.titleDiv}>
                    {isEntityPersonPrimary ? <Typography color="grey700" variant="h6"> <FormattedMessage {...intl.primaryIdVerificationTitle} /> </Typography> : <Typography color="grey700" variant="h6"> <FormattedMessage {...intl.stackHolderIdVerificationTitle} /> </Typography>}
                    <Typography color="grey700" variant="h6"> <FormattedMessage {...intl.stackHolderIdVerificationTitleBreak} /> </Typography>
                </div>
                <Typography className={classes.desc} color="grey700" variant="body1"> <FormattedMessage {...intl.stackHolderIdVerificationDesc} /> </Typography>
                <div className={classes.nameDiv} >
                    <Typography className={classes.accentColor} variant="body2" >
                        <b className={classes.accentColorSubTitle2} >{firstName}</b> <b className={classes.accentColorSubTitle2}>{lastName}</b> is authorized to set up and make payments through Truly Financial on behalf of <b className={classes.accentColorSubTitle2}>{businessName}</b> </Typography>
                </div>
                <FormGroup className={classes.checkBoxDiv}>
                    <FormControlLabel
                        // disabled={data.entity_person_type === "PRIMARY_CONTACT" ? true : false}
                        onChange={(e) => { onCheckBoxPress(e); }}
                        control={<Checkbox className={classes.iconColor} checked={termCondition} name={"termCondition"} />} label={< Typography variant="body2" >{intlTxt.formatMessage(intl.primaryIdVerificationTermCon)} </Typography>} />
                </FormGroup>
                <div className={classes.btnDiv}>
                    <Button
                        title={`${intlTxt.formatMessage(intl.stackHolderIdVerificationTermConBtn)}`}
                        color="secondary"
                        disabled={!termCondition || isLoadingData}
                        className={classes.btn}
                        onClick={onContinueClick}
                    />
                </div>

            </Grid>
        </div>
    </>);
}

const mapStateToProps = createStructuredSelector({
    controllingPartyConsentState: acceptControllingPartyConsentState(),
});

function mapDispatchToProps(dispatch) {
    return {
        acceptControllingPartyConsent: data => dispatch(acceptControllingPartyConsentAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(ControllingPartyConsent);
