export const WEEKDAYS = [
    { 1: 'Mon' },
    { 2: 'Tue' },
    { 3: 'Wed' },
    { 4: 'Thu' },
    { 5: 'Fri' },
    { 6: 'Sat' },
    { 7: 'Sun' }
]

export const MONTHS = [
    { 1: 'January' },
    { 2: 'February' },
    { 3: 'March' },
    { 4: 'April' },
    { 5: 'May' },
    { 6: 'June' },
    { 7: 'July' },
    { 8: 'August' },
    { 9: 'September' },
    { 10: 'October' },
    { 11: 'November' },
    { 12: 'December' }
]

export const EVENT_SCHEDULE_RECURRING_TYPE_ID = {
    Hourly: 1,
    Daily: 2,
    Weekly: 3,
    Monthly: 4,
    Yearly: 5
}

export const DATE_IN_MONTH = (year, month) => {
    const monthArry = []
    const temp = new Date(month, year, 0).getDate();
    for (var i = 1; i <= temp; i++) {
        monthArry.push(i)
    }

    return monthArry
}