
import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalUserState = state => state.user || {};

export const userState = () =>
  createSelector(GlobalState, substate => substate.user);

export const getEntitiesState = () =>
  createSelector(globalUserState, substate => substate.getEntitiesState);

export const setSelectedEntityState = () =>
  createSelector(globalUserState, substate => substate.setSelectedEntityState);

export const updateUserLocalSettingsState = () =>
  createSelector(globalUserState, substate => substate.updateUserLocalSettingsState);

export const validateLinkState = () =>
  createSelector(globalUserState, substate => substate.validateLinkState);
