const SMARTLINK_ACCOUNT = {
    code: "SMARTLINK_ACCOUNT",
    name: "Smartlink Account",
    path: "/smartlink",
    functions: {
        SMARTLINK_ACCOUNT_LIST: {
            code: "SMARTLINK_ACCOUNT_LIST",
            name: "List",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LIST.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LIST.path}`,
        },
        SMARTLINK_ACCOUNT_DETAILS: {
            code: "SMARTLINK_ACCOUNT_DETAILS",
            name: "Detail",
            isPage: true,
            component: 'SmartLinkAccountDetailsPage',
            path: "/:id/details",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_DETAILS.path}`,
            getFullPath: (id) =>
                `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_DETAILS.path}`.replace(
                    ":id",
                    id
                ),
        },
        SMARTLINK_ACCOUNT_DEACTIVATE: {
            code: "SMARTLINK_ACCOUNT_DEACTIVATE",
            name: "Smartlink Account Deactivate",
            isPage: false,
            component: '',
            path: "/:accountId/deactivate",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_DEACTIVATE.path}`,
            getFullPath: (accountId) => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_DEACTIVATE.path}`.replace(
                ":accountId",
                accountId
            ),
        },
        SMARTLINK_ACCOUNT_ACTIVATE: {
            code: "SMARTLINK_ACCOUNT_ACTIVATE",
            name: "Smartlink Account Activate",
            isPage: false,
            component: '',
            path: "/:accountId/activate",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_ACTIVATE.path}`,
            getFullPath: (accountId) => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_ACTIVATE.path}`.replace(
                ":accountId",
                accountId
            ),
        },
        SMARTLINK_ACCOUNT_LEDGERS_LIST: {
            code: "SMARTLINK_ACCOUNT_LEDGERS_LIST",
            name: "Smartlink Account Ledger List",
            isPage: false,
            component: '',
            path: "/:accountId/account-ledgers",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LEDGERS_LIST.path}`,
            getFullPath: (accountId) => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LEDGERS_LIST.path}`.replace(
                ":accountId",
                accountId
            ),
        },
        SMARTLINK_ACCOUNT_LEDGERS_DETAILS: {
            code: "SMARTLINK_ACCOUNT_LEDGERS_DETAILS",
            name: "Smartlink Account Ledger Details",
            isPage: false,
            component: '',
            path: "/:accountId/account-ledgers/:accountLedgerId",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LEDGERS_DETAILS.path}`,
            getFullPath: (accountId, accountLedgerId) => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LEDGERS_DETAILS.path}`.replace(
                ":accountId",
                accountId
            ).replace(
                ":accountLedgerId",
                accountLedgerId
            ),
        },
        SMARTLINK_ACCOUNT_LEDGERS_FILTER: {
            code: "SMARTLINK_ACCOUNT_LEDGERS_FILTER",
            name: "Smartlink Account Ledger Filter",
            isPage: false,
            component: '',
            path: "/:accountId/account-ledgers",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LEDGERS_FILTER.path}`,
            getFullPath: (accountId) =>
                `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_LEDGERS_FILTER.path}`.replace(
                    ":accountId",
                    accountId
                ),
        },
        SMARTLINK_ACCOUNT_CARDS_LIST: {
            code: "SMARTLINK_ACCOUNT_CARDS_LIST",
            name: "Smartlink Account Cards List ",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARDS_LIST.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARDS_LIST.path}`,
        },
        SMARTLINK_ACCOUNT_CARDS_DETAILS: {
            code: "SMARTLINK_ACCOUNT_CARDS_DETAILS",
            name: "Smartlink Account Cards Details",
            isPage: false,
            component: '',
            path: "/:cardholderCardId",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARDS_DETAILS.path}`,
            getFullPath: (cardholderCardId) => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARDS_DETAILS.path}`.replace(
                ":cardholderCardId",
                cardholderCardId
            ),
        },
        SMARTLINK_ACCOUNT_CARD_ACTIVATE: {
            code: "SMARTLINK_ACCOUNT_CARD_ACTIVATE",
            name: "Smartlink Account Card Activate",
            isPage: false,
            component: '',
            path: "/:cardholderCardId/activate",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_ACTIVATE.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_ACTIVATE.path}`,
        },
        SMARTLINK_ACCOUNT_CARD_ISSUE: {
            code: "SMARTLINK_ACCOUNT_CARD_ISSUE",
            name: "Smartlink Account Card Issue",
            isPage: false,
            component: '',
            path: "/requests",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_ISSUE.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_ISSUE.path}`,
        },
        SMARTLINK_ACCOUNT_CARD_LOCK: {
            code: "SMARTLINK_ACCOUNT_CARD_LOCK",
            name: "Smartlink Account Cards Lock",
            isPage: false,
            component: '',
            path: "/:cardholderCardId/temporary-block",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_LOCK.path}`,
            getFullPath: (cardholderCardId) => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_LOCK.path}`.replace(
                ":cardholderCardId",
                cardholderCardId
            ),
        },
        SMARTLINK_ACCOUNT_CARD_RENAME: {
            code: "SMARTLINK_ACCOUNT_CARD_RENAME",
            name: "Smartlink Account Cards Rename",
            isPage: false,
            component: '',
            path: "/rename",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_RENAME.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_RENAME.path}`,
        },
        SMARTLINK_ACCOUNT_CARD_PIN: {
            code: "SMARTLINK_ACCOUNT_CARD_PIN",
            name: "Smartlink Account Cards Pin",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_PIN.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_PIN.path}`,
        },
        SMARTLINK_ACCOUNT_SETTINGS_DETAILS: {
            code: "SMARTLINK_ACCOUNT_SETTINGS_DETAILS",
            name: "Smartlink Account Settings",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_SETTINGS_DETAILS.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_SETTINGS_DETAILS.path}`,
        
        },
        SMARTLINK_ACCOUNT_RENAME: {
            code: "SMARTLINK_ACCOUNT_RENAME",
            name: "Smartlink Account Rename",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_RENAME.path}`,
            getFullPath: () => `${SMARTLINK_ACCOUNT.path}${SMARTLINK_ACCOUNT.functions.SMARTLINK_ACCOUNT_CARD_RENAME.path}`,
        
        }
    },
};

export default SMARTLINK_ACCOUNT;
