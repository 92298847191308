const TEAM_MEMBER = {
    code: "TEAM_MEMBER",
    name: "Team Member",
    path: "/team-members",
    functions: {
        TEAM_MEMBERS_LIST: {
            code: "TEAM_MEMBERS_LIST",
            name: "Team Member List",
            isPage: true,
            component: 'TeamMembersPage',
            path: "/",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_LIST.path}`,
            getFullPath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_LIST.path}`,
            preview: {
                code: "TEAM_MEMBERS_DETAILS",
                name: "Team Member Details",
                isPage: true,
                component: 'TeamMembersPage',
                path: "/:id",
                getRoutePath: () =>
                    [
                        `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_LIST.path}`,
                        `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_DETAILS.path}`
                    ]
                ,
                getFullPath: (id) =>
                    `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_DETAILS.path}`.replace(
                        ":id",
                        id
                    ),
            }
        },
        TEAM_MEMBERS_SEARCH: {
            code: "TEAM_MEMBERS_SEARCH",
            name: "Team Member Search",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_SEARCH.path}`,
            getFullPath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_SEARCH.path}`,
        },
        TEAM_MEMBERS_CREATE: {
            code: "TEAM_MEMBERS_CREATE",
            name: "Team Member Create",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_CREATE.path}`,
            getFullPath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_CREATE.path}`,
        },
        TEAM_MEMBERS_DETAILS: {
            code: "TEAM_MEMBERS_DETAILS",
            name: "Team Member Details",
            isPage: true,
            component: 'TeamMembersPage',
            path: "/:id",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_DETAILS.path}`,
            getFullPath: (id) =>
                `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_DETAILS.path}`.replace(
                    ":id",
                    id
                ),
        },
        TEAM_MEMBERS_ACTIVATE: {
            code: "TEAM_MEMBERS_ACTIVATE",
            name: "Team Member Activate",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_ACTIVATE.path}`,
            getFullPath: (entityPersonId) =>
                `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_ACTIVATE.path}`.replace(
                    ":entityPersonId",
                    entityPersonId
                ),
        },
        TEAM_MEMBERS_DEACTIVATE: {
            code: "TEAM_MEMBERS_DEACTIVATE",
            name: "Team Member Deactivate",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_DEACTIVATE.path}`,
            getFullPath: (entityPersonId) =>
                `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_DEACTIVATE.path}`.replace(
                    ":entityPersonId",
                    entityPersonId
                ),
        },
        TEAM_MEMBERS_IMAGE: {
            code: "TEAM_MEMBERS_IMAGE",
            name: "Team Member Image",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_IMAGE.path}`,
            getFullPath: (entityPersonId) =>
                `${TEAM_MEMBER.path}${TEAM_MEMBER.functions.TEAM_MEMBERS_IMAGE.path}`.replace(
                    ":entityPersonId",
                    entityPersonId
                ),
        },

    },
};

export default TEAM_MEMBER;
