import { defineMessages } from 'react-intl';

export const scope = 'src.containers.';

export default defineMessages({
  searchHint: {
    id: `${scope}.searchHint`,
    defaultMessage: 'Type to search location',
    description: 'initial Search hint'
  },
  searchHintNoResultsOrMatchHint: {
    id: `${scope}.searchHintNoResultsOrMatchHint`,
    defaultMessage: 'Type the street number and full street name to narrow your search or select <b>{inputOnlyString}</b> to enter manually',
    description: 'initial Search hint'
  },
});
