import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Loader, StyledError } from 'components';
import { Grid, makeStyles } from '@material-ui/core';
import Logo from 'assets/images/logo/Truly_Logo_Light.png';
import ConfidentialAgreement from 'containers/Verification/Common/ConfidentialAgreement';
import { getPersonalDetailsAction } from 'store/Verification/verificationActions';
import { getPersonalDetailsState } from 'store/Verification/verificationSelectors';
import CreatePassword from './Common/CreatePassword';
// import AcceptInvite from './Common/AcceptInvite';
import InvitationAccepted from './Common/InvitationAccepted';
import { ONBOARDING_STEPS } from '@truly-financial/common/consts/entity-person.consts';

const {
    SIGNUP,
    // ACCEPT_INVITE,
    ACCEPT_CONFIDENTIAL_AGREEMENT,
} = ONBOARDING_STEPS;

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        minHeight: `calc(100vh)`,
    },
    infoDiv: {
        textAlign: 'center',
        margin: theme.spacing(10, 1),
    },
    logo: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'left',
        borderRadius: '0px 0px 10px 10px',
        minHeight: theme.spacing(10)
    },
}));

function TeamMemberSignup(props = {}) {
    const classes = useStyles();
    const { personalDetailsState,
        getPersonalDetails } = props;

    const entityPersonData = personalDetailsState.data || {};
    const [step, setStep] = useState('');

    useEffect(() => {
        if(!entityPersonData.entity_person_id) handleTeamMemberDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTeamMemberDetails = () => {
        getPersonalDetails();
    };

    useEffect(() => {
        setStep(entityPersonData.teamMemberSignupStep)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalDetailsState]);

    const handleStepChange = (s) => {
        if (s) return  setStep(s);
        getPersonalDetails();
    };

    const onSuccessCreatePassword = (s) => {
        setStep('');
    };

    if (!entityPersonData.entity_person_id && personalDetailsState.loading) {
        return (<>
            <Loader />
        </>);
    }
    if (personalDetailsState.error || personalDetailsState.status === 404) {
        return (<>
            <StyledError data={personalDetailsState} retry={handleTeamMemberDetails} />
        </>);
    }

    return (<div className={classes.root}>
        <div className={classes.logo}>
            <img alt="Truly" src={Logo} width="120" />
        </div>
        {personalDetailsState.loading && <Loader />}
        <Grid container justify="center">
            <Grid xs={12} md={6} lg={4}>
                {step === ACCEPT_CONFIDENTIAL_AGREEMENT &&
                    (<ConfidentialAgreement
                        data={entityPersonData}
                        onStepChange={handleStepChange}
                    />)
                }
                {step === SIGNUP &&
                    (<CreatePassword
                        data={entityPersonData}
                        onStepChange={onSuccessCreatePassword}
                    />)
                }
                {/* {step === ACCEPT_INVITE &&
                    (<AcceptInvite
                        data={entityPersonData}
                    />)
                } */}
                {!step && entityPersonData.entity_id &&
                    (<div className={classes.infoDiv}>
                        <InvitationAccepted
                            data={entityPersonData}
                            showLoginBtn={true}
                        />
                    </div>)
                }
            </Grid>
        </Grid>
    </div>);
}

const mapStateToProps = createStructuredSelector({
    personalDetailsState: getPersonalDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getPersonalDetails: data => dispatch(getPersonalDetailsAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(TeamMemberSignup);


