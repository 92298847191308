import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { requestWithErrorFeedback } from 'utils/request';
import {
  // resetOtpAction,
  sendOtpSetDataAction,
  resendOtpSetDataAction,
} from './otpActions';
import {
  // RESET_OTP,
  SEND_OTP,
  RESEND_OTP,
} from './otpConstants';

import {
  OTP_BASE_API_URL,
} from 'config/apiUrls';
import { styledSnackbar } from 'containers/Common/Snackbar';

const OTP_URL = `${OTP_BASE_API_URL}/otp`;

export default function* otpSaga() {
  // add all sagas here
  yield takeLeading(RESEND_OTP, resendOtpSaga);
  yield takeLatest(SEND_OTP, sendOtpSaga);
}

export function* sendOtpSaga(args = {}) {
  const { cb = () => null, headers = null } = args.data || {};
  const requestUrl = `${OTP_URL}/send`;
  const options = {
    method: 'POST',
  }
  if(headers) options.headers = {...headers}
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(sendOtpSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* resendOtpSaga(args = {}) {
  const { otp_ref_id, cb = () => null, headers = null } = args.data || {};
  const requestUrl = `${OTP_URL}/resend/${otp_ref_id}`;
  const options = {
    method: 'PUT',
  }
  if(headers) options.headers = {...headers}
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(resendOtpSetDataAction(result));
    result.ok && cb();
    result.ok && styledSnackbar.success('Security code sent successfully')
  } catch (error) {
    console.error(error);
  }
}
