import React, { memo, useCallback } from "react"
import { Grid, IconButton, makeStyles, Paper } from "@material-ui/core"
import { Button, Typography } from "components/ui"
import QRCode from 'qrcode.react';
import { styledSnackbar } from "containers/Common/Snackbar";
import CachedIcon from '@material-ui/icons/Cached';
const useStyles = makeStyles((theme) => ({
  txt: {
    textAlign: "left",
  },
  centerContent: {
    textAlign: "center",
  },
  secretCodeDiv: {
    textAlign: "center",
    padding: theme.spacing(1),
    cursor: "pointer",
    border: `1px solid ${theme.palette.grey200}`,
    borderRadius: theme.spacing(1)
  },
  secretCodeLabel: {
    padding: theme.spacing(0.3),
  },
  secretCodeValue: {
    padding: theme.spacing(0.3),
  },
  linkText: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    textDecoration: "underline",
  },
  QrDivWrapper: {
    textAlign: "-webkit-center",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  QrDiv: {
    width: "fit-content",
    border: "1px dashed gray",
    padding: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshButton: {
    padding: theme.spacing(0, 1)
  }
}));
function TotpGenerateQr(props = {}) {
  const { totpQrStr, getQrCode, totpQrSecretStr, setStep } = props;
  const classes = useStyles();

  const onBack = useCallback(() => {
    setStep(1)
  }, [setStep]);

  const copyCode = useCallback(() => {
    navigator.clipboard.writeText(totpQrSecretStr);
    styledSnackbar.success("Copied");
  }, [totpQrSecretStr])

  return (<Grid
    container
    spacing={3}
    item
    direction="column"
  >
    <Grid item xs={12}>
      <Typography variant="h5" className={classes.txt} >Set Up App on a Different Device</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2" color="grey500" className={classes.txt}>Once you’ve installed an authentication app (for example Google Authenticator or Duo Mobile), you can set up this account. From there, you can either scan this barcode or enter the following code manually.</Typography>
    </Grid>
    <Grid item xs={12} />
    <Grid item xs={12}>
      <div className={classes.centerContent}>
        {totpQrStr && <div className={classes.QrDivWrapper}>
          <div className={classes.QrDiv}><QRCode value={totpQrStr} /></div>
          <div>
            <IconButton color="primary" className={classes.refreshButton} onClick={getQrCode}><CachedIcon /></IconButton>
          </div>
        </div>}
      </div>
    </Grid>
    <Grid item xs={12} />
    <Grid item xs={12}>
      <Paper elevation={0} >
        <div onClick={copyCode} className={classes.secretCodeDiv}>
          <Typography className={classes.secretCodeLabel} variant="body2" color="grey500">Or enter this code into your authentication app</Typography>
          <Typography className={classes.secretCodeValue} variant="subtitle2" color="grey700">{totpQrSecretStr}</Typography>
        </div>
      </Paper>
    </Grid>

    <Grid item xs={12} />
    <Grid container spacing={3} item>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          title="Back"
          onClick={onBack}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          title="Continue"
          onClick={() => setStep(3)}
        />
      </Grid>
    </Grid>
  </Grid>
  )
}

export default memo(TotpGenerateQr)