import React, { memo, useEffect, useState } from 'react';
import { makeStyles, Grid, Collapse } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Typography, Button } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import intl from './intl/OnBoardingIntl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCustomerDetailsState } from 'store/Register/registerSelectors';
import { compose } from 'redux';
import { getCustomerDetailsAction } from 'store/Register/registerActions';
import { useHistory } from 'react-router';
import { StyledError, TrulyLoader } from 'components';
import SummaryStepIcon from './SummaryStepIcon';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import { getMainStepFromStep } from 'store/Register/registerParser';

const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
// const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;

const BUSINESS_BRIEF = REGISTRATION_STEP.BUSINESS_BRIEF;
// const BUSINESS_ADDRESS = REGISTRATION_STEP.BUSINESS_ADDRESS;
// const BUSINESS_INDUSTRIES = REGISTRATION_STEP.BUSINESS_INDUSTRIES;

// const BUSINESS_USE_CASES = REGISTRATION_STEP.BUSINESS_USE_CASES;
// const BUSINESS_COUNTRIES = REGISTRATION_STEP.BUSINESS_COUNTRIES;
const BUSINESS_SOURCE_OF_FUNDS = REGISTRATION_STEP.BUSINESS_SOURCE_OF_FUNDS;

const BUSINESS_STRUCTURE = REGISTRATION_STEP.BUSINESS_STRUCTURE;
const PERSONAL_DETAILS = REGISTRATION_STEP.PERSONAL_DETAILS;
const BENEFICIAL_OWNERS = REGISTRATION_STEP.BENEFICIAL_OWNERS;
const CONTROLLING_PARTY = REGISTRATION_STEP.CONTROLLING_PARTY;

const TERMS_AND_CONDITIONS = REGISTRATION_STEP.TERMS_AND_CONDITIONS;
const ID_VERIFICATION = REGISTRATION_STEP.ID_VERIFICATION;
const ACCOUNT_AGREEMENT = "ACCOUNT_AGREEMENT";

const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: `calc(100vh - ${theme.appHeaderHeight})`,
        background: "#fff",
        paddingTop: theme.spacing(4),
        flexDirection: 'column'
    },
    container: {
        width: theme.spacing(62),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            width: 'auto',
            margin: theme.spacing(4)
        },
        borderRadius: theme.spacing(4),
        boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.14)",
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: theme.spacing(8.75),
        background: "#000",
        color: "#fff",
        width: "100%",
        borderTopLeftRadius: theme.spacing(4),
        borderTopRightRadius: theme.spacing(4),
    },
    headerTitle: {
        margin: theme.spacing(0, 0, 0, 2.5),
    },
    subtitle: {
        textAlign: "center",
        margin: theme.spacing(3, 2),
    },
    wrapper: {
        margin: theme.spacing(0, 6, 3, 6),
        cursor: 'pointer'
    },
    lineThrough: {
        // textDecoration: 'line-through',
        margin: theme.spacing(0, 0, 0, 1),
        color: theme.palette.grey600
    },
    lineThroughDisable: {
        // textDecoration: 'line-through',
        margin: theme.spacing(0, 0, 0, 1),
        opacity: "0.5",
    },
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(6.75, 0, 0, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(2, 0, 2, 0),
        }

    },
    iconContentWrapper: {
        display: "flex",
    },
    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.main,
    },
    icon1: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: theme.spacing(3),
        width: theme.spacing(3),
        borderRadius: "50%",
        border: "2px solid #E22D20",
        color: "#E22D20",
        margin: theme.spacing(0, 1, 0, 0),
    },
    capsule: {
        backgroundColor: "rgba(226, 45, 32, 0.2)",
        borderRadius: theme.spacing(2),
        width: "75%",
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(1, 0),
        display: "flex",
        alignItems: "center",
    },
    margin: {
        margin: theme.spacing(0, 0, 0, 4),
    },
    opacity: {
        opacity: "0.5",
        margin: theme.spacing(0, 0, 0, 4),
    },
    list: {
        opacity: "0.5",
        margin: theme.spacing(0, 0, 0, 4),
    },
    incomplete: {
        opacity: "0.5",
    },
    inTypo: {
        margin: theme.spacing(0, 0, 0, 2.25),
    },
    iconSummary: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    subTitleDisableTxt: {
        color: theme.palette.grey200,
        cursor: 'pointer',
        margin: theme.spacing(1.2, 0, 0, 6.2)
    },
    subTitleTxt: {
        color: theme.palette.grey600,
        cursor: 'pointer',
        margin: theme.spacing(1.2, 0, 0, 6.2)
    },
}));

function CompleteStepsSummary(props) {
    // eslint-disable-next-line no-unused-vars
    const { onBackPress, getCustomerDetailsState, getCustomerDetailsAction } = props;
    const classes = useStyles();
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const history = useHistory();
    const [completedSteps, setCompletedSteps] = useState({});
    const [mainStep, setMainStep] = useState("");
    const isAllStepComplete = customerDetail && customerDetail.completed_steps && customerDetail.completed_steps.length === 15 && customerDetail.mainStep === "ACCOUNT_AGREEMENT" ? true : false;
    const {
        completed_steps = [],
        // prs_main_business_industries_isStepComplete = false,
    } = customerDetail;

    useEffect(() => {
        getDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (customerDetail.prs_user) {
            const steps = customerDetail.completed_steps || [];
            setCompletedSteps({
                CONFIDENTIAL_AGREEMENT: ["CUSTOMER_INTRODUCTION", "CONFIDENTIAL_AGREEMENT"].every((val) => steps.includes(val)),
                BUSINESS_BRIEF: ["BUSINESS_STRUCTURE", "BUSINESS_BRIEF", "BUSINESS_ADDRESS", "BUSINESS_INDUSTRIES", "BUSINESS_COUNTRIES",].every((val) => steps.includes(val)),
                BUSINESS_USE_CASES: ["BUSINESS_USE_CASES", "BUSINESS_SOURCE_OF_FUNDS"].every((val) => steps.includes(val)),
                BUSINESS_STRUCTURE: ["PERSONAL_DETAILS", "BENEFICIAL_OWNERS", "CONTROLLING_PARTY"].every((val) => steps.includes(val)),
                TERMS_AND_CONDITIONS: ["TERMS_AND_CONDITIONS"].every((val) => steps.includes(val)),
                ID_VERIFICATION: ["REGISTRATION_SUBMITTED", "APPROVED", "APPROVED_NO_TRADING"].includes(customerDetail.entity_status),
            });


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCustomerDetailsState.data]);

    useEffect(() => {
        if (Object.keys(completedSteps).length > 0) {
            const unCompleteStep = Object.keys(completedSteps).filter(key => !completedSteps[key])[0];
            if (unCompleteStep) {
                const mainStepFromCurrentStep = getMainStepFromStep(unCompleteStep);
                setMainStep(mainStepFromCurrentStep)
            }
        }


    }, [completedSteps]);
    const getDetails = () => {
        getCustomerDetailsAction();
    };

    const onContinue = (action) => {
        const search = window.location.search || '';
        const params = search ? `${search}&s=${customerDetail.currentStep}` : `?s=${customerDetail.currentStep}`;
        history.push(`/complete-registration${params}`);
    };

    if ((!customerDetail.prs_user || getCustomerDetailsState.loading) && !getCustomerDetailsState.error) {
        return (<><TrulyLoader /></>)
    }
    if (getCustomerDetailsState.error) {
        return (<StyledError data={getCustomerDetailsState} retry={getDetails} />)
    }

    const onStepPress = (step, push = true) => {
        const mainStepFromCurrentStep = getMainStepFromStep(step)
        setMainStep(mainStepFromCurrentStep)
        push && history.push(`/complete-registration?s=${step}`);

    };

    return (
        <Grid container alignItems='center' className={classes.root} >
            <Grid className={classes.container}>
                <Grid item className={classes.header}>
                    <AssignmentIcon className={classes.iconSummary} />
                    <Typography variant='h6' className={classes.headerTitle}>
                        <FormattedMessage {...intl.onBoardingStepSummaryTitle} />
                    </Typography>
                </Grid>
                <Typography variant="subtitle1" className={classes.subtitle}>
                    <FormattedMessage {...intl.onBoardingStepSummarySubTitle} />
                </Typography>
                <Grid container>
                    <Grid xs={12} className={classes.wrapper}>
                        <div onClick={isAllStepComplete ? () => null : () => onStepPress(
                            customerDetail && customerDetail.completed_steps && customerDetail.completed_steps.length > 0 && customerDetail.completed_steps.includes(CONFIDENTIAL_AGREEMENT) ? CUSTOMER_INTRODUCTION : CONFIDENTIAL_AGREEMENT)} xs={12} className={classes.iconContentWrapper}>
                            <SummaryStepIcon type={REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT} completed={completedSteps.CONFIDENTIAL_AGREEMENT} />
                            <Typography variant='subtitle1' className={completedSteps.CONFIDENTIAL_AGREEMENT ? classes.lineThrough : classes.lineThroughDisable}><FormattedMessage {...intl.onBoardingStepSummaryTitleMain} /></Typography>
                        </div>
                        <Typography variant='body2' className={classes.opacity} ><FormattedMessage {...intl.onBoardingStepSummarySubTitleMain} /></Typography>
                    </Grid>
                    <Grid xs={12} className={classes.wrapper}>
                        <div className={classes.iconContentWrapper} onClick={isAllStepComplete ? () => null : () => onStepPress(BUSINESS_BRIEF, false)}>
                            <SummaryStepIcon type={REGISTRATION_STEP.BUSINESS_BRIEF} completed={completedSteps.BUSINESS_BRIEF} />
                            <Typography variant='subtitle1' className={completedSteps.BUSINESS_BRIEF ? classes.lineThrough : classes.lineThroughDisable}><FormattedMessage {...intl.onBoardingStepSummaryBusinessBrief} /></Typography>
                        </div>
                        {
                            mainStep !== BUSINESS_BRIEF && <Typography variant='body2' className={classes.opacity}><FormattedMessage {...intl.onBoardingStepSummaryBusinessBriefSubTitle} /></Typography>
                        }

                        <Collapse in={mainStep === BUSINESS_BRIEF ? true : false}>
                            <div onClick={() => onStepPress(BUSINESS_BRIEF)}>
                                <Typography variant={"body2"} className={completed_steps.includes(BUSINESS_BRIEF) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessBrief} /> </Typography>
                            </div>

                            <div onClick={() => onStepPress(BUSINESS_SOURCE_OF_FUNDS)}>
                                <Typography variant={"body2"} className={completed_steps.includes(BUSINESS_SOURCE_OF_FUNDS) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessFunds} /> </Typography>
                            </div>
                            {/* <div onClick={() => onStepPress(BUSINESS_ADDRESS)}>
                                <Typography variant={"body2"} className={completed_steps.includes(BUSINESS_ADDRESS) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessLocated} /> </Typography>
                            </div> */}

                        </Collapse>
                    </Grid>

                    {/* <Grid xs={12} className={classes.wrapper}>
                        <div className={classes.iconContentWrapper} onClick={isAllStepComplete ? () => null : () => onStepPress(BUSINESS_USE_CASES, false)}>
                            <SummaryStepIcon type={REGISTRATION_STEP.BUSINESS_USE_CASES} completed={completedSteps.BUSINESS_USE_CASES} />
                            <Typography variant='subtitle1' color="grey600" className={classes.lineThrough}><FormattedMessage {...intl.onBoardingStepSummaryTailorProfile} /></Typography>
                        </div>
                        {
                            mainStep !== BUSINESS_USE_CASES && <Typography variant='body2' className={classes.opacity}><FormattedMessage {...intl.onBoardingStepSummaryTailorProfileSubTitle} /></Typography>
                        }
                        <Collapse in={mainStep === BUSINESS_USE_CASES ? true : false}>
                            <div onClick={() => onStepPress(BUSINESS_USE_CASES)}>
                                <Typography variant={"body2"} className={completed_steps.includes(BUSINESS_USE_CASES) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.businessUseCase} /> </Typography>
                            </div>


                        </Collapse>
                    </Grid> */}
                    <Grid xs={12} className={classes.wrapper}>
                        <div className={classes.iconContentWrapper} onClick={isAllStepComplete ? () => null : () => onStepPress(PERSONAL_DETAILS, false)}>
                            <SummaryStepIcon type={REGISTRATION_STEP.BUSINESS_STRUCTURE} completed={completedSteps.BUSINESS_STRUCTURE} />
                            <Typography variant='subtitle1' className={completedSteps.BUSINESS_STRUCTURE ? classes.lineThrough : classes.lineThroughDisable}><FormattedMessage {...intl.onBoardingStepSummaryBusinessStructure} /></Typography>
                        </div>
                        {
                            mainStep !== BUSINESS_STRUCTURE && <Typography variant='body2' className={classes.opacity}><FormattedMessage {...intl.onBoardingStepSummaryOwnerShipSubTitle} /></Typography>
                        }
                        <Collapse in={mainStep === BUSINESS_STRUCTURE ? true : false}>

                            {/* <div onClick={() => onStepPress(PERSONAL_DETAILS)}>
                                <Typography variant={"body2"} className={completed_steps.includes(PERSONAL_DETAILS) ? classes.subTitleTxt : classes.subTitleDisableTxt}><FormattedMessage {...intl.personalDetail} /> </Typography>
                            </div> */}
                            {
                                (customerDetail.hideOwnershipPercentage || (customerDetail.skipOwnership && !customerDetail.hideOwnershipPercentage)) ? <></> : <div onClick={() => onStepPress(BENEFICIAL_OWNERS)}>
                                    <Typography variant={"body2"} className={completed_steps.includes(BENEFICIAL_OWNERS) ? classes.subTitleTxt : classes.subTitleDisableTxt}><FormattedMessage {...intl.businessOwners} /> </Typography>
                                </div>
                            }
                            {
                                !customerDetail.skipControllingParty && <div onClick={() => onStepPress(CONTROLLING_PARTY)}>
                                    <Typography variant={"body2"} className={completed_steps.includes(CONTROLLING_PARTY) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.selectControlling} /> </Typography>
                                </div>
                            }
                        </Collapse>
                    </Grid>
                    <Grid xs={12} className={classes.wrapper}>
                        <div className={classes.iconContentWrapper} onClick={isAllStepComplete ? () => null : () => onStepPress(TERMS_AND_CONDITIONS, false)}>
                            <SummaryStepIcon type={REGISTRATION_STEP.TERMS_AND_CONDITIONS} completed={completedSteps.TERMS_AND_CONDITIONS} />
                            <Typography variant='subtitle1' className={completedSteps.TERMS_AND_CONDITIONS && completedSteps.ID_VERIFICATION ? classes.lineThrough : classes.lineThroughDisable}><FormattedMessage {...intl.onBoardingStepSummaryTermsAndConditions} /></Typography>
                        </div>
                        {
                            mainStep !== TERMS_AND_CONDITIONS && <Typography variant='body2' className={classes.opacity}><FormattedMessage {...intl.onBoardingStepSummaryTermConditionSubTitle} /></Typography>
                        }
                        <Collapse in={mainStep === TERMS_AND_CONDITIONS ? true : false}>
                            {/* <div onClick={() => onStepPress(TERMS_AND_CONDITIONS)}>
                                <Typography variant={"body2"} className={completed_steps.includes(TERMS_AND_CONDITIONS) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.termAndCondition} /> </Typography>
                            </div> */}
                            <div onClick={completed_steps.includes(CONTROLLING_PARTY) ? () => onStepPress(ID_VERIFICATION) : () => null}>
                                <Typography variant={"body2"} className={completed_steps.includes(ID_VERIFICATION) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.idVerification} /> </Typography>
                            </div>
                            <div onClick={completed_steps.includes(ID_VERIFICATION) && completed_steps.includes(TERMS_AND_CONDITIONS) ? () => onStepPress(ACCOUNT_AGREEMENT) : () => null}  >
                                <Typography variant={"body2"} className={completed_steps.includes(ACCOUNT_AGREEMENT) ? classes.subTitleTxt : classes.subTitleDisableTxt} ><FormattedMessage {...intl.informationReview} /> </Typography>
                            </div>
                        </Collapse>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container justifyContent="center" className={classes.content}>
                {/* <GoBack onClick={onBackPress} /> */}
                <Grid item xs="8" md="3">
                    <Button color="primary"
                        onClick={onContinue}
                    ><FormattedMessage {...intl.onBoardingStepSummaryNext} /></Button>
                </Grid>
            </Grid>
        </Grid >
    )
}

const mapStateToProps = createStructuredSelector({

    getCustomerDetailsState: getCustomerDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getCustomerDetailsAction: data => dispatch(getCustomerDetailsAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(CompleteStepsSummary);

