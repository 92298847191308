// import { makeStyles } from '@material-ui/core';
import React, { memo, useEffect, useRef, useState } from 'react';
import CustomerIntroduction from 'containers/OnBoarding/Steps/CustomerIntroduction';
import ConfidentialAgreement from 'containers/OnBoarding/Steps/ConfidentialAgreement';
import BusinessBrief from 'containers/OnBoarding/Steps/BusinessBrief';
// import BusinessStructure from 'containers/OnBoarding/Steps/BusinessStructure';
// import BusinessCountries from 'containers/OnBoarding/Steps/BusinessCountries';
import BusinessAddress from 'containers/OnBoarding/Steps/BusinessAddress';
// import BusinessCategories from 'containers/OnBoarding/Steps/BusinessCategories';
// import BusinessIndustries from 'containers/OnBoarding/Steps/BusinessIndustries';
import BusinessSourceOfFunds from 'containers/OnBoarding/Steps/BusinessSourceOfFunds';
import BusinessUseCases from 'containers/OnBoarding/Steps/BusinessUseCases';
import BeneficialOwners from 'containers/OnBoarding/Steps/BeneficialOwners';
// import PersonalDetails from 'containers/OnBoarding/Steps/PersonalDetails';
// import TermsAndConditions from 'containers/OnBoarding/Steps/TermsAndConditions';
import ControllingParty from 'containers/OnBoarding/Steps/ControllingParty';
import RegistrationHeader from 'components/RegistrationHeader';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCustomerDetailsAction, getCustomerDetailsSetDataAction, getTermAndConditionAction, updateStepAction } from 'store/Register/registerActions';
import { getCustomerDetailsState, getTermAndConditionState } from 'store/Register/registerSelectors';
import { Loader, StyledError } from 'components';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from 'utils/hooks';
import {
    getAccessToken, getLastAuthUser,
    removeTempAccessToken,
    // setAccessToken, setEntityId,
    // setLastAuthUser,
    setRegistrationLocalData,
    // setRegistrationToken,
} from 'utils/auth';
// import { useBeforeunload } from 'react-beforeunload';
import { getMainStepFromStep } from 'store/Register/registerParser';
import SlideStep from './SlideStep';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import RegistrationSideMenu from 'components/RegistrationSideMenu';
import { getCountryListAction, getIndustriesListAction, getPrimarySourceOfFundingListAction, getPrimaryUseCaseListAction, getRoleListAction } from 'store/Reference/referenceActions';
import { getIndustriesListState, getPrimarySourceOfFundingListState, getPrimaryUseCaseListState, getRoleListState } from 'store/Reference/referenceSelectors';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
import IdVerification from './Steps/IdVerification';
import InformationReview from './Steps/InformationReview';
// import CloseRegFlowPopUp from 'containers/OnBoarding/CloseRegFlowPopUp';
import { useIdleTimer } from 'react-idle-timer'
import { openLiveChat } from 'utils/hubspot';
const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;
const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
const BUSINESS_BRIEF = REGISTRATION_STEP.BUSINESS_BRIEF;
// const BUSINESS_CATEGORIES = REGISTRATION_STEP.BUSINESS_CATEGORIES;
const BUSINESS_ADDRESS = REGISTRATION_STEP.BUSINESS_ADDRESS;
const BUSINESS_INDUSTRIES = REGISTRATION_STEP.BUSINESS_INDUSTRIES;
const BUSINESS_USE_CASES = REGISTRATION_STEP.BUSINESS_USE_CASES;
const BUSINESS_COUNTRIES = REGISTRATION_STEP.BUSINESS_COUNTRIES;
const BUSINESS_SOURCE_OF_FUNDS = REGISTRATION_STEP.BUSINESS_SOURCE_OF_FUNDS;
const BUSINESS_STRUCTURE = REGISTRATION_STEP.BUSINESS_STRUCTURE;
const PERSONAL_DETAILS = REGISTRATION_STEP.PERSONAL_DETAILS;
const BENEFICIAL_OWNERS = REGISTRATION_STEP.BENEFICIAL_OWNERS;
const TERMS_AND_CONDITIONS = REGISTRATION_STEP.TERMS_AND_CONDITIONS;
const CONTROLLING_PARTY = REGISTRATION_STEP.CONTROLLING_PARTY;
const ID_VERIFICATION = REGISTRATION_STEP.ID_VERIFICATION;
const ACCOUNT_AGREEMENT = REGISTRATION_STEP.ACCOUNT_AGREEMENT;


const useStyles = makeStyles(theme => ({
    rootOnboarding: {
        // backgroundColor: theme.palette.primary.contrastText,
        minHeight: '100vh',
        // backgroundImage: `linear-gradient(to left, ${theme.palette.secondary.light} 20%, transparent), linear-gradient(to top, ${theme.palette.secondary.light} 10%, transparent), repeating-linear-gradient( 
        //     45deg, ${theme.palette.secondary.light}, ${theme.palette.secondary.light} 0.6%, #4d7b6985 0.6%, #4d7b6985 1.2%)`
        background: 'white',

        [theme.breakpoints.up('md')]: {
            background: `linear-gradient(325deg, #e0e0 1%, #ffff 21%),
            repeating-linear-gradient(44deg, #e0e0e0, #e0e0e0 2px, #ffff 2px, #ffff 6px)`,
            backgroundAttachment: 'fixed',
        },

        display: 'flex',
        // [theme.breakpoints.up('md')]: {
        //     background: `linear-gradient(56deg, #e0e0 1%, #ffff 21%),
        //     repeating-linear-gradient(135deg, #e0e0e0, #e0e0e0 2px, #ffff 2px, #ffff 6px)`,
        //     backgroundAttachment: 'fixed',
        // },

        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            paddingBottom: theme.spacing(8),
        },

        flex: 1

    },
    contentRoot: {
        paddingTop: theme.spacing(18),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            paddingTop: theme.spacing(14),
            marginTop: theme.spacing(0),
        },
        flex: 3,
        display: 'flex',
        flexDirection: 'column'
    },
    mainRoot: {
        display: 'flex',
        flex: 1
    },
    sideMenu: {
        flex: 1,
        display: 'flex',
        paddingTop: theme.spacing(18),
    }
}));

function OnBoarding(props = {}) {
    const history = useHistory();
    const location = useLocation();
    const refFrom = useRef();
    // const refFrom = createRef();
    const { getCustomerDetailsState, getCustomerDetailsAction, updateStep, resetCustomerDetails,
        getRole, role, getCountries, industriesList, getIndustriesList, getPrimarySourceOfFundingList,
        primarySourceOfFundingList, getPrimaryUseCaseList, primaryUseCaseList, getTermAndConditionState,
        getTermAndConditionAction

    } = props;
    const getCustomerDetailsStateData = getCustomerDetailsState.data;
    let query = useQuery();
    const s = query.get('s');
    const t = query.get('token');
    const uid = query.get('uid');
    const eid = query.get('id') || getCustomerDetailsStateData.entity_id;
    const classes = useStyles();
    const theme = useTheme();

    const currentStep = getCustomerDetailsStateData.currentStep || "CONFIDENTIAL_AGREEMENT";

    // const [currentStep, setCurrentStep] = useState(getCustomerDetailsStateData.currentStep || 0);
    // const [selectedBusinessTypeList, setSelectedBusinessTypeList] = useState([]);
    // const getCustomerDetailsData = getCustomerDetailsState.data || {};
    const [isError, setError] = useState(false);
    const mdUpMatch = useMediaQuery(theme.breakpoints.up('md'));
    const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'));
    // const closeRefFlowRef = useRef();

    useEffect(() => {
        if (role && !role.data.length) {
            getRole();
        }
        if (industriesList && !industriesList.data.length) {
            getIndustriesList();
        }
        if (primarySourceOfFundingList && !primarySourceOfFundingList.data.length) {
            getPrimarySourceOfFundingList();
        }
        if (primarySourceOfFundingList && !primarySourceOfFundingList.data.length) {
            getPrimarySourceOfFundingList();
        }
        if (primaryUseCaseList && !primaryUseCaseList.data.length) {
            getPrimaryUseCaseList();
        }
        if (getTermAndConditionState && !getTermAndConditionState.data.length) {
            getTermAndConditionAction();
        }
        getCountries();
        resetCustomerDetails();
        getDetailsHandler();
        // window.addEventListener("beforeunload", (e) => {
        //     openCloseFlow(e);
        // });
        // return () => {
        //     window.removeEventListener("beforeunload", (e) => {
        //         openCloseFlow(e);
        //     });
        // }
        removeTempAccessToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useBeforeunload((event) => {
    //     event.preventDefault();
    //     openCloseFlow(event);
    // });

    useEffect(() => {
        if (getCustomerDetailsStateData.user_account_id && !getCustomerDetailsStateData.entity_id) {
            setCurrentStep(CONFIDENTIAL_AGREEMENT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCustomerDetailsStateData.entity_id, getCustomerDetailsStateData.user_account_id]);

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (s && s != currentStep) {
            getCustomerDetailsAction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [s]);

    useEffect(() => {
        if (getCustomerDetailsStateData.isTerminateRegistration && ![CUSTOMER_INTRODUCTION].includes(getCustomerDetailsStateData.currentStep)) {
            if (t) {
                history.push(`/registration-on-hold?token=${t}&uid=${uid}&id=${eid}`);
            } else {
                history.push(`/registration-on-hold`);
            }
        }
        // else if ((getCustomerDetailsStateData.entity_id && getCustomerDetailsStateData.entity_status && getCustomerDetailsStateData.entity_status !== ENTITY_STATUS.INITIATED)) {
        //     const entId = getCustomerDetailsStateData.entity_id || '';
        //     if (t) {
        //         history.push(`/registration-complete?token=${t}&uid=${uid}&id=${entId}`);
        //     } else {
        //         history.push(`/registration-complete`);
        //     }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCustomerDetailsStateData]);

    // const openCloseFlow = (e) => {
    //     e.preventDefault();
    //     // e.returnValue = null;
    //     e.returnValue = null;
    //     if (closeRefFlowRef && closeRefFlowRef.current) {
    //         closeRefFlowRef.current.onToggle();
    //     }

    // };

    const getDetailsHandler = async () => {
        if (uid && uid !== getLastAuthUser()) {
            const ld = await setRegistrationLocalData({ token: t, uid, eid });
            ld && getCustomerDetailsAction();
        } else {
            getCustomerDetailsAction();
        }
    };

    const appendUrl = (step) => {
        const entId = getCustomerDetailsStateData.entity_id || '';
        if (t === getAccessToken()) {
            history.push(`${location.pathname}?token=${t}&uid=${uid}&id=${entId}&s=${step}`);
        } else {
            history.push(`${location.pathname}?s=${step}`);
        }
    };

    const setCurrentStep = (step, from = "onBoarding") => {

        var currentStep = step;
        setError(false);
        if (from === "header" && ![CONFIDENTIAL_AGREEMENT, CUSTOMER_INTRODUCTION].includes(currentStep) && !getCustomerDetailsStateData.entity_id) {
            // if (currentStep !== CUSTOMER_INTRODUCTION && !getCustomerDetailsStateData.entity_id) {
            setTimeout(() => {
                setError(true);
                refFrom?.current?.click();
            }, 500);
            // styledSnackbar.error('Introduction step is required');
            return;
        }

        const mainStep = getMainStepFromStep(currentStep);
        if (from === "onBoarding") {
            getCustomerDetailsAction({
                currentStep,
                cb: () => appendUrl(currentStep),
            });
        } else {
            updateStep({
                data: {
                    ...getCustomerDetailsStateData,
                    currentStep,
                    mainStep,
                    slideStepDirection: 'left',
                }
            });
            appendUrl(currentStep);
        }
    };

    const setCurrentStepOnBack = (step) => {
        setError(false);
        var currentStep = step;
        const mainStep = getMainStepFromStep(currentStep);
        updateStep({
            data: {
                ...getCustomerDetailsStateData,
                currentStep,
                mainStep,
                slideStepDirection: 'right',
            }
        });
        appendUrl(currentStep);
        if (step === BUSINESS_BRIEF) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_BREIF_BACK_CLICK.event })
        }
        if (step === BUSINESS_ADDRESS) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_LOCATION_BACK_CLICK.event })
        }
        if (step === BUSINESS_INDUSTRIES) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_INDUSTRY_BACK_CLICK.event })
        }
        if (step === BUSINESS_USE_CASES) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_PROFILE_BACK_CLICK.event })
        }
        if (step === BUSINESS_COUNTRIES) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_BUSINESS_COUNTRY_BACK_CLICK.event })
        }
        if (step === BUSINESS_SOURCE_OF_FUNDS) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_FUND_SOURCE_BACK_CLICK.event })
        }
        if (step === BUSINESS_STRUCTURE) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_STRUCTURE_BACK_CLICK.event })
        }
        if (step === PERSONAL_DETAILS) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_DETAIL_BACK_CLICK.event })
        }
        if (step === BENEFICIAL_OWNERS) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_BACK_CLICK.event })
        }
        if (step === CONTROLLING_PARTY) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_DETAIL_BACK_CLICK.event })
        }
        if (step === TERMS_AND_CONDITIONS) {
            analyticsTrackApi({ event: ANALYTICS.EVENTS.TERMS_N_CONDITION_BACK_CLICK.event })
        }
    };

    // eslint-disable-next-line no-unused-vars
    const { getRemainingTime } = useIdleTimer({
        timeout: 10000,
        // throttle: 5000,
        onIdle: () => {
            const status = window.HubSpotConversations?.widget?.status();
            if (status?.loaded) {
                return;
            }
            openLiveChat(isBigScreen);
        },
        // crossTab: true
    });

    if (getCustomerDetailsState.error) {
        return (<StyledError data={getCustomerDetailsState} retry={getCustomerDetailsAction} />)
    }

    return (<div className={classes.rootOnboarding}>
        <RegistrationHeader
            changeStep={(newStep) => { setCurrentStep(newStep, "header") }}
            activeStep={getCustomerDetailsStateData.mainStep}
            currentStep={getCustomerDetailsStateData.currentStep}
            progress={getCustomerDetailsStateData.progress}
            data={getCustomerDetailsStateData}
        />
        {/* <CloseRegFlowPopUp
        ref={closeRefFlowRef}
    /> */}
        {
            !getCustomerDetailsStateData.prs_user ? <Loader /> :
                <div className={classes.mainRoot} >
                    {
                        mdUpMatch && currentStep !== CONFIDENTIAL_AGREEMENT && <div className={classes.sideMenu} >
                            <RegistrationSideMenu
                                data={getCustomerDetailsStateData}
                                changeStep={(newStep) => { setCurrentStep(newStep, "header") }}
                                currentStep={getCustomerDetailsStateData.currentStep}
                            />
                        </div>
                    }

                    <div className={classes.contentRoot} >
                        {currentStep === CONFIDENTIAL_AGREEMENT && <SlideStep><ConfidentialAgreement error={isError} onContinue={(params) => setCurrentStep(CUSTOMER_INTRODUCTION, params)} /></SlideStep>}
                        {currentStep === CUSTOMER_INTRODUCTION && <SlideStep><CustomerIntroduction innerRef={refFrom} error={isError} onContinue={(params) => setCurrentStep(BUSINESS_BRIEF, params)} onBackPress={() => setCurrentStepOnBack(CONFIDENTIAL_AGREEMENT)} /></SlideStep>}
                        {[BUSINESS_BRIEF, BUSINESS_INDUSTRIES, BUSINESS_STRUCTURE].includes(currentStep) && <SlideStep><BusinessBrief onContinue={(params) => setCurrentStep(BUSINESS_ADDRESS, params)} onBackPress={() => setCurrentStepOnBack(CUSTOMER_INTRODUCTION)} /></SlideStep>}
                        {/* {currentStep === BUSINESS_BRIEF && <SlideStep><BusinessBrief onContinue={(params) => setCurrentStep(BUSINESS_CATEGORIES, params)} onBackPress={() => setCurrentStepOnBack(CONFIDENTIAL_AGREEMENT)} /></SlideStep>} */}
                        {/* {currentStep === BUSINESS_CATEGORIES && <SlideStep><BusinessCategories onContinue={(from) => setCurrentStep(BUSINESS_ADDRESS, from)} onBackPress={() => setCurrentStepOnBack(BUSINESS_BRIEF)} /></SlideStep>} */}
                        {/* {currentStep === BUSINESS_ADDRESS && <SlideStep><BusinessAddress onContinue={(params) => setCurrentStep(BUSINESS_INDUSTRIES, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_CATEGORIES)} /></SlideStep>} */}
                        {[BUSINESS_ADDRESS, BUSINESS_COUNTRIES].includes(currentStep) && <SlideStep><BusinessAddress onContinue={(params) => setCurrentStep(BUSINESS_USE_CASES, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_BRIEF)} /></SlideStep>}
                        {/* {currentStep === BUSINESS_INDUSTRIES && <SlideStep><BusinessIndustries onContinue={(params) => setCurrentStep(BUSINESS_USE_CASES, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_ADDRESS)} /></SlideStep>} */}
                        {currentStep === BUSINESS_USE_CASES && <SlideStep><BusinessUseCases onContinue={(params) => setCurrentStep(BUSINESS_SOURCE_OF_FUNDS, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_ADDRESS)} /></SlideStep>}
                        {/* {currentStep === BUSINESS_COUNTRIES && <SlideStep><BusinessCountries onContinue={(params) => setCurrentStep(BUSINESS_SOURCE_OF_FUNDS, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_USE_CASES)} /></SlideStep>} */}
                        {currentStep === BUSINESS_SOURCE_OF_FUNDS && <SlideStep><BusinessSourceOfFunds onContinue={(params) => setCurrentStep(getCustomerDetailsStateData.hideOwnershipPercentage ? ID_VERIFICATION : BENEFICIAL_OWNERS, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_USE_CASES)} /></SlideStep>}
                        {/* {currentStep === BUSINESS_STRUCTURE && <SlideStep><BusinessStructure onContinue={(params) => setCurrentStep(PERSONAL_DETAILS, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_SOURCE_OF_FUNDS)} /></SlideStep>} */}
                        {/* {currentStep === PERSONAL_DETAILS && <SlideStep><PersonalDetails globalCustomerDetail={getCustomerDetailsData} onContinue={(params) => setCurrentStep(BENEFICIAL_OWNERS, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_SOURCE_OF_FUNDS)} /></SlideStep>} */}
                        {[PERSONAL_DETAILS, BENEFICIAL_OWNERS].includes(currentStep) && <SlideStep><BeneficialOwners onContinue={(params) => setCurrentStep(getCustomerDetailsStateData.skipControllingParty ? ID_VERIFICATION : CONTROLLING_PARTY, params)} onBackPress={() => setCurrentStepOnBack(BUSINESS_SOURCE_OF_FUNDS)} /></SlideStep>}
                        {currentStep === CONTROLLING_PARTY && <SlideStep><ControllingParty onContinue={(params) => setCurrentStep(ID_VERIFICATION, params)} onBackPress={() => setCurrentStepOnBack(BENEFICIAL_OWNERS)} /></SlideStep>}
                        {/* {currentStep === TERMS_AND_CONDITIONS && <SlideStep><TermsAndConditions onContinue={(params) => setCurrentStep(ID_VERIFICATION, params)} onBackPress={() => setCurrentStepOnBack(getCustomerDetailsData.skipControllingParty ? PERSONAL_DETAILS : CONTROLLING_PARTY)} /></SlideStep>} */}
                        {currentStep === ID_VERIFICATION && <SlideStep><IdVerification onContinue={(params) => setCurrentStep(ACCOUNT_AGREEMENT, params)} onBackPress={() => setCurrentStepOnBack(getCustomerDetailsStateData.hideOwnershipPercentage || getCustomerDetailsStateData.skipControllingParty ? getCustomerDetailsStateData.skipControllingParty ? BENEFICIAL_OWNERS : BUSINESS_SOURCE_OF_FUNDS : CONTROLLING_PARTY)} /></SlideStep>}
                        {[TERMS_AND_CONDITIONS, ACCOUNT_AGREEMENT].includes(currentStep) && <SlideStep><InformationReview onContinue={(step, params) => setCurrentStep(step, params)} onBackPress={() => setCurrentStepOnBack(ID_VERIFICATION)} /></SlideStep>}

                    </div>
                    {
                        mdUpMatch && currentStep !== CONFIDENTIAL_AGREEMENT && <div className={classes.sideMenu} >
                        </div>
                    }
                </div>
        }
        {getCustomerDetailsState && getCustomerDetailsState.loading && <Loader />}
    </div >);
}
const mapStateToProps = createStructuredSelector({
    getCustomerDetailsState: getCustomerDetailsState(),
    role: getRoleListState(),
    industriesList: getIndustriesListState(),
    primarySourceOfFundingList: getPrimarySourceOfFundingListState(),
    primaryUseCaseList: getPrimaryUseCaseListState(),
    getTermAndConditionState: getTermAndConditionState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getCustomerDetailsAction: (data) => dispatch(getCustomerDetailsAction(data)),
        updateStep: (data) => dispatch(updateStepAction(data)),
        resetCustomerDetails: (data) => dispatch(getCustomerDetailsSetDataAction(data)),
        getCountries: data => dispatch(getCountryListAction(data)),
        getRole: data => dispatch(getRoleListAction(data)),
        getIndustriesList: (data) => dispatch(getIndustriesListAction(data)),
        getPrimarySourceOfFundingList: (data) => dispatch(getPrimarySourceOfFundingListAction(data)),
        getPrimaryUseCaseList: (data) => dispatch(getPrimaryUseCaseListAction(data)),
        getTermAndConditionAction: data => dispatch(getTermAndConditionAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(OnBoarding);
