
import { defineMessages } from 'react-intl';

export const scope = 'src.components.payment_purpose';

export default defineMessages({
    title: {
        id: `${scope}.title`,
        defaultMessage: 'Type of Payment',
    },

});
