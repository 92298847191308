/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles, Menu, MenuItem, Divider, useTheme } from '@material-ui/core';
// import useCommonStyles from 'styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import intl from 'components/intl/HelperMenuIntl';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'components/ui';
// import CallIcon from '@material-ui/icons/Call';
import HelpIcon from '@material-ui/icons/Help';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CreateIcon from '@material-ui/icons/Create';
// import BugReportIcon from '@material-ui/icons/BugReport';
import ExternalDialog from './ExternalDialog';
import StyledIframe from './StyledIframe';

import { openLiveChat } from 'utils/hubspot';

const useStyles = makeStyles(theme => ({
  root: {

  },
  helpIcon: {
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      // marginLeft: theme.spacing(3),
      // marginRight: theme.spacing(1),
      color: theme.palette.grey[400],
    },
    padding: '0px !important',
    color: theme.palette.primary.contrastText,
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  rootMenu: {
    zIndex: `3147483648 !important`,
    '& .MuiList-padding': {
      padding: theme.spacing(1, 0, 2, 0),

    },
  },
  icon: {
    fontSize: theme.spacing(2.3),
    color: theme.palette.secondary700,
    margin: theme.spacing(0, 1.3, 0, 0)
  },
  underline: {
    textDecoration: 'underline'
  },
  col: {
    display: 'flex',
    flexDirection: 'column'
  },
  navLink: {
    textDecoration: 'underline',
    color: theme.palette.hyperlink.main,
  },
  nonMenu: {
    margin: theme.spacing(0, 1, 1, 1.95)
  },
  deveined: {
    margin: theme.spacing(1, 0, 2, 0)
  },
  iconAndText: {
    display: 'flex',
  }
}));

function HelperMenu(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const { data = {} } = props;
  const { entity_person_email = "", entity_person_first_name = "", entity_person_last_name = "", company_name = "" } = data;
  const classes = useStyles(props);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openChat = () => {
    openLiveChat();
    handleClose();
  }
  // const openFeedback = () => {
  //   window?.Usersnap?.logEvent('feedback_event');
  //   handleClose();
  // }

  // const onClickLiveChat = () => {
  //   window.open(`https://app.trulyfinancial.com/live-chat?email=${entity_person_email || ""}&firstname=${entity_person_first_name || ""}&lastname=${entity_person_last_name || ""}&company=${company_name || ""}`, "mywindow","menubar=1,resizable=1,width=400,height=600");
  //   handleClose();
  // }
  // const onClickContactForm = () => {
  //   window.open(`https://share.hsforms.com/1TPsf4MCsQbW9ESTnF7I3LA5k4k6?email=${entity_person_email || ""}&firstname=${entity_person_first_name || ""}&lastname=${entity_person_last_name || ""}&company=${company_name || ""}`, "mywindow");
  //   handleClose();
  // }

  return (
    <>
      <div className={classes.root}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          id="helpIcon"
          onClick={handleClick}
          className={classes.helpIcon}
        >
          <HelpOutlineIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.rootMenu}
          PaperProps={{
            style: {
              width: theme.spacing(30),
            },
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          {/* <MenuItem
            className={classes.row}
            onClick={handleClose}
            id="guideFaq"
          >
            <HelpIcon className={classes.icon} />
            <Typography variant="body2" color="grey800">
              <FormattedMessage {...intl.faq} />
            </Typography>
          </MenuItem> */}

          <MenuItem
            className={classes.row}
            onClick={openChat}
          >
            <QuestionAnswerIcon className={classes.icon} />
            <div className={classes.col}>
              <Typography variant="body2" color="grey800">
                <FormattedMessage {...intl.liveChat} />
              </Typography>
              <Typography variant="caption" color="grey600">
                <FormattedMessage {...intl.time} />
              </Typography>
            </div>
          </MenuItem>

          {/* <ExternalDialog
            triggerComponent={(onOpen) => (
              <MenuItem
                className={classes.row}
                onClick={() => {
                  onOpen();
                  handleClose();
                }}
              >
                <QuestionAnswerIcon className={classes.icon} />
                <div className={classes.col}>
                  <Typography variant="body2" color="grey800">
                    <FormattedMessage {...intl.liveChat} />
                  </Typography>
                  <Typography variant="caption" color="grey600">
                    <FormattedMessage {...intl.time} />
                  </Typography>
                </div>
              </MenuItem>
            )}
            childComponent={(onClose) => (
              <StyledIframe
                // src={`https://app.trulyfinancial.com/live-chat?email=${
                src={`/live-chat?email=${
                  entity_person_email || ""
                }&firstname=${entity_person_first_name || ""}&lastname=${
                  entity_person_last_name || ""
                }&company=${company_name || ""}`}
              />
            )}
            title="Live Chat"
          /> */}

          {/* <MenuItem className={classes.row} onClick={onClickLiveChat}>
          <QuestionAnswerIcon className={classes.icon} />
          <div className={classes.col}>
            <Typography variant="body2" color="grey800" ><FormattedMessage {...intl.liveChat} /></Typography>
            <Typography variant="caption" color="grey600" ><FormattedMessage {...intl.time} /></Typography>
          </div>
        </MenuItem> */}

          {/* <MenuItem className={classes.row} onClick={handleClose}>
          <CallIcon className={classes.icon} />
          <div className={classes.col}>
            <Typography variant="body2" color="grey800" ><FormattedMessage {...intl.callUs} /></Typography>
            <Typography variant="caption" color="grey600" ><FormattedMessage {...intl.time} /></Typography>
            <Typography variant="caption" color="grey600" className={classes.underline} ><FormattedMessage {...intl.number} /></Typography>
          </div>

        </MenuItem> */}

          <ExternalDialog
            triggerComponent={(onOpen) => (
              <MenuItem
                className={classes.row}
                onClick={() => {
                  onOpen();
                  handleClose();
                }}
              >
                <CreateIcon className={classes.icon} />
                <Typography variant="body2" color="grey800">
                  <FormattedMessage {...intl.contactForm} />
                </Typography>
              </MenuItem>
            )}
            childComponent={(onClose) => (
              <StyledIframe
                src={`https://share.hsforms.com/1TPsf4MCsQbW9ESTnF7I3LA5k4k6?email=${entity_person_email || ""}&firstname=${entity_person_first_name || ""}&lastname=${entity_person_last_name || ""}&company=${company_name || ""}`}
              />
            )}
            title="Message Us"
          />

          {/* <MenuItem
            className={classes.row}
            onClick={openFeedback}
          >
            <BugReportIcon className={classes.icon} />
            <div className={classes.col}>
              <Typography variant="body2" color="grey800">
                <FormattedMessage {...intl.reportBug} />
              </Typography>
            </div>
          </MenuItem> */}

          {/* <MenuItem className={classes.row} onClick={onClickContactForm}>
            <CreateIcon className={classes.icon} />
            <Typography variant="body2" color="grey800">
              <FormattedMessage {...intl.contactForm} />
            </Typography>
          </MenuItem> */}

          {/* <Divider className={classes.deveined} />

          <Typography
            className={classes.nonMenu}
            variant="caption"
            color="#000000"
          >
            <FormattedMessage {...intl.productTour} />
          </Typography>

          <MenuItem id="guideDepositFunds" className={classes.row} onClick={handleClose}>
            <Typography
              variant="body2"
              color="grey800"
              className={classes.navLink}
            >
              <FormattedMessage {...intl.fund} />
            </Typography>
          </MenuItem>

          <MenuItem id="guideSendPayment" className={classes.row} onClick={handleClose}>
            <Typography
              variant="body2"
              color="grey800"
              className={classes.navLink}
            >
              <FormattedMessage {...intl.send} />
            </Typography>
          </MenuItem>

          <MenuItem id="guideManageExpenses" className={classes.row} onClick={handleClose}>
            <Typography
              variant="body2"
              color="grey800"
              className={classes.navLink}
            >
              <FormattedMessage {...intl.link} />
            </Typography>
          </MenuItem>

          <MenuItem id="guideLinkPaymentGateway" className={classes.row} onClick={handleClose}>
            <Typography
              variant="body2"
              color="grey800"
              className={classes.navLink}
            >
              <FormattedMessage {...intl.create} />
            </Typography>
          </MenuItem> */}
        </Menu>
      </div>
    </>
  );
}

HelperMenu.defaultProps = {
  test: " HelperMenu component ",
};

HelperMenu.propTypes = {
  test: PropTypes.string,
};

export default memo(HelperMenu);
