import {
  RESET_TEAM_MEMBER,
  GET_TEAM_MEMBERS, GET_TEAM_MEMBERS_SET_DATA,
  ADD_UPDATE_TEAM_MEMBER, ADD_UPDATE_TEAM_MEMBER_SET_DATA,
  GET_TEAM_MEMBER_DETAILS, GET_TEAM_MEMBER_DETAILS_SET_DATA,
  SELF_UPDATE_TEAM_MEMBER, SELF_UPDATE_TEAM_MEMBER_SET_DATA,
  GET_SELF_TEAM_MEMBER_DETAILS, GET_SELF_TEAM_MEMBER_DETAILS_SET_DATA,
  GENERATE_PASSWORD, GENERATE_PASSWORD_SET_DATA,
  CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT, CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT_SET_DATA,
  SEND_INVITATION_LINK, SEND_INVITATION_LINK_SET_DATA, DELETE_TEAM_MEMBER, DELETE_TEAM_MEMBER_SET_DATA,
  UPDATE_TEAM_MEMBER_ACCESS, UPDATE_TEAM_MEMBER_ACCESS_SET_DATA,
  ID_VERIFICATION_RECEIVED, ID_VERIFICATION_RECEIVED_SET_DATA,
  SUBMIT_ID_VERIFICATION_CONSENT, SUBMIT_ID_VERIFICATION_CONSENT_SET_DATA,
  SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS, SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS_SET_DATA,
  SUBMIT_ID_VERIFICATION_AGREEMENT, SUBMIT_ID_VERIFICATION_AGREEMENT_SET_DATA,
  GET_SELF_USER_DETAILS, GET_SELF_USER_DETAILS_SET_DATA,
  GET_BERBIX_TOKEN, GET_BERBIX_TOKEN_SET_DATA, CHECK_TEAM_MEMBER_EXIST, CHECK_TEAM_MEMBER_EXIST_SET_DATA,
} from './teamMemberConstants';

import { addUpdateTeamMemberRequestParser, getTeamMemberListParser, selfGetTeamMemberRequestParser, selfUpdateTeamMemberRequestParser, teamMemberDetailParser } from './teamMemberParser';

export function resetTeamMemberAction() {
  return { type: RESET_TEAM_MEMBER };
}

export function getTeamMembersAction(data) {
  return { type: GET_TEAM_MEMBERS, data };
}
export function getTeamMembersSetDataAction(data, page) {
  return { type: GET_TEAM_MEMBERS_SET_DATA, data: getTeamMemberListParser(data), page };
}

export function addUpdateTeamMemberAction(data) {
  return { type: ADD_UPDATE_TEAM_MEMBER, data: addUpdateTeamMemberRequestParser(data) };
}
export function addUpdateTeamMemberSetDataAction(data) {
  return { type: ADD_UPDATE_TEAM_MEMBER_SET_DATA, data };
}

export function getTeamMemberDetailsAction(data) {
  return { type: GET_TEAM_MEMBER_DETAILS, data };
}
export function getTeamMemberDetailsSetDataAction(data) {
  return { type: GET_TEAM_MEMBER_DETAILS_SET_DATA, data: teamMemberDetailParser(data) };
}

export function selfUpdateTeamMemberAction(data) {
  return { type: SELF_UPDATE_TEAM_MEMBER, data: selfUpdateTeamMemberRequestParser(data) };
}
export function selfUpdateTeamMemberSetDataAction(data) {
  return { type: SELF_UPDATE_TEAM_MEMBER_SET_DATA, data };
}

export function getSelfTeamMemberDetailsAction(data) {
  return { type: GET_SELF_TEAM_MEMBER_DETAILS, data };
}
export function getSelfTeamMemberDetailsSetDataAction(data) {
  return { type: GET_SELF_TEAM_MEMBER_DETAILS_SET_DATA, data: selfGetTeamMemberRequestParser(data) };
}

export function generatePasswordAction(data) {
  return { type: GENERATE_PASSWORD, data };
}
export function generatePasswordSetDataAction(data) {
  return { type: GENERATE_PASSWORD_SET_DATA, data };
}

export function createTeamMemberSmartLinkAccountAction(data) {
  return { type: CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT, data };
}
export function createTeamMemberSmartLinkAccountSetDataAction(data) {
  return { type: CREATE_TEAM_MEMBER_SMART_LINK_ACCOUNT_SET_DATA, data };
}

export function sendInvitationLinkAction(data) {
  return { type: SEND_INVITATION_LINK, data };
}
export function sendInvitationLinkSetDataAction(data) {
  return { type: SEND_INVITATION_LINK_SET_DATA, data };
}

export function deleteTeamMemberAction(data) {
  return { type: DELETE_TEAM_MEMBER, data };
}
export function deleteTeamMemberSetDataAction(data) {
  return { type: DELETE_TEAM_MEMBER_SET_DATA, data };
}

export function updateTeamMemberAccessAction(data) {
  return { type: UPDATE_TEAM_MEMBER_ACCESS, data };
}
export function updateTeamMemberAccessSetDataAction(data) {
  return { type: UPDATE_TEAM_MEMBER_ACCESS_SET_DATA, data };
}

export function idVerificationReceivedAction(data) {
  return { type: ID_VERIFICATION_RECEIVED, data };
}
export function idVerificationReceivedSetDataAction(data) {
  return { type: ID_VERIFICATION_RECEIVED_SET_DATA, data };
}

export function submitIdVerificationConsentAction(data) {
  return { type: SUBMIT_ID_VERIFICATION_CONSENT, data };
}
export function submitIdVerificationConsentSetDataAction(data) {
  return { type: SUBMIT_ID_VERIFICATION_CONSENT_SET_DATA, data };
}

export function submitIdVerificationAcceptTermAndConditionsAction(data) {
  return { type: SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS, data };
}
export function submitIdVerificationAcceptTermAndConditionsSetDataAction(data) {
  return { type: SUBMIT_ID_VERIFICATION_ACCEPTED_TERM_AND_CONDITIONS_SET_DATA, data };
}

export function submitIdVerificationAgreementAction(data) {
  return { type: SUBMIT_ID_VERIFICATION_AGREEMENT, data };
}
export function submitIdVerificationAgreementSetDataAction(data) {
  return { type: SUBMIT_ID_VERIFICATION_AGREEMENT_SET_DATA, data };
}

export function getSelfUserDetailsAction(data) {
  return { type: GET_SELF_USER_DETAILS, data };
}
export function getSelfUserDetailsSetDataAction(data) {
  return { type: GET_SELF_USER_DETAILS_SET_DATA, data };
}

export function getBerbixTokenAction(data) {
  return { type: GET_BERBIX_TOKEN, data };
}
export function getBerbixTokenSetDataAction(data) {
  return { type: GET_BERBIX_TOKEN_SET_DATA, data };
}

export function checkTeamMemberExistAction(data) {
  return { type: CHECK_TEAM_MEMBER_EXIST, data };
}
export function checkTeamMemberExistSetDataAction(data) {
  return { type: CHECK_TEAM_MEMBER_EXIST_SET_DATA, data };
}
