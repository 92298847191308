import { call, put, select, takeLatest } from 'redux-saga/effects';
import { request, requestWithErrorFeedback } from 'utils/request';
import {
  // resetContactRequestBankAction,
  getRequestDetailSetDataAction,
  updateBankDetailSetDataAction,
} from './contactRequestBankActions';
import {
  // RESET_CONTACT_REQUEST_BANK,
  GET_REQUEST_DETAIL,
  UPDATE_BANK_DETAIL,
} from './contactRequestBankConstants';

import {
  CONTACT_REQUEST_BANK_BASE_API_URL,
} from 'config/apiUrls';
import { validateLinkState } from 'store/User/userSelectors';
import { getRequestBankHeaders } from 'utils/common';

const CONTACT_REQUEST_BANK_URL = `${ CONTACT_REQUEST_BANK_BASE_API_URL }/bank-detail-request`;

export default function* contactRequestBankSaga() {
  // add all sagas here
  yield takeLatest(UPDATE_BANK_DETAIL, updateBankDetailSaga);
  yield takeLatest(GET_REQUEST_DETAIL, getRequestDetailSaga);
}

export function* getRequestDetailSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  let tokenData = yield select(validateLinkState());
  let headers = null;
  if (tokenData?.data?.link_token) {
    headers = getRequestBankHeaders(tokenData);
  }
  const options = {};
  if (headers) {
    options.headers = {...headers}
  }
  const requestUrl = `${CONTACT_REQUEST_BANK_URL}/${id}`;
  try {
    const result = yield call(request, requestUrl, options);
    yield put(getRequestDetailSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* updateBankDetailSaga(args = {}) {
  const { id, body, cb = () => null } = args.data || {};
  const requestUrl = `${CONTACT_REQUEST_BANK_URL}/${id}`;
  let tokenData = yield select(validateLinkState());
  const options = { method : 'PUT', body, headers: {
    ...getRequestBankHeaders(tokenData),
  } };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(updateBankDetailSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}
