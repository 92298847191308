import React, { memo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Typography } from 'components/ui';
import { makeStyles } from '@material-ui/core';
import { PAYMENT } from '@truly-financial/common/consts';
window.moment = moment;
// import 'moment-timezone' ;
/* eslint-disable react/prop-types */

const useStyle = makeStyles((theme) => ({
    amountTxt: (props) => ({
        textDecoration: props && props.status === PAYMENT.PAYMENT_STATUS.FAILED ? 'line-through' : 'none',

    })
}));


function StyledAmount(props = {}) {
    const { amount, currency, type, from, status = "" } = props;
    const classes = useStyle({ status })
    let styleObj = { style: 'decimal' };
    if (currency) {
        styleObj = { style: 'currency', currency };
    }
    const formattedAmount = new Intl.NumberFormat('en', {
        ...styleObj,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
    const splitAmount = formattedAmount.split(".");
    if (from === "numberPad") {
        let amt = amount;
        var numberPad = 0;
        if (amt.includes(".")) {
            let indexOfDot = amt.indexOf(".");
            if (amt.charAt(indexOfDot + 2)) {
                let amtTONumber = Number(amt);
                numberPad = amtTONumber.toFixed(2);
            } else {
                numberPad = amt;
            }
        } else {
            numberPad = amt;
        }
    }
    return (<>
        {type === "normal" ? `${formattedAmount || ''} ${currency || ''}` : <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }} >
            <Typography className={classes.amountTxt} color="grey700" variant="subtitle2">
                {splitAmount[0] || ''}
            </Typography>
            <Typography className={classes.amountTxt} color="grey700" variant="caption">
                .{splitAmount[1] || ''} {currency || ''}
            </Typography>
        </div>}
        {from === "numberPad" && numberPad}
    </>);
}
StyledAmount.defaultProps = {
    type: "normal",
    amount: 0,
    currency: '',
}
StyledAmount.propTypes = {
    amount: PropTypes.any,
    type: PropTypes.string,
    from: PropTypes.any
};
export default memo(StyledAmount);
