import { Slide } from '@material-ui/core';
import React, { memo } from 'react';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCustomerDetailsState } from 'store/Register/registerSelectors';
import { createStructuredSelector } from 'reselect';
const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;
// const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
// const BUSINESS_BRIEF = REGISTRATION_STEP.BUSINESS_BRIEF;
// const BUSINESS_CATEGORIES = REGISTRATION_STEP.BUSINESS_CATEGORIES;
// const BUSINESS_ADDRESS = REGISTRATION_STEP.BUSINESS_ADDRESS;
// const BUSINESS_INDUSTRIES = REGISTRATION_STEP.BUSINESS_INDUSTRIES;
// const BUSINESS_USE_CASES = REGISTRATION_STEP.BUSINESS_USE_CASES;
// const BUSINESS_COUNTRIES = REGISTRATION_STEP.BUSINESS_COUNTRIES;
// const BUSINESS_SOURCE_OF_FUNDS = REGISTRATION_STEP.BUSINESS_SOURCE_OF_FUNDS;
// const BUSINESS_STRUCTURE = REGISTRATION_STEP.BUSINESS_STRUCTURE;
// const PERSONAL_DETAILS = REGISTRATION_STEP.PERSONAL_DETAILS;
// const BENEFICIAL_OWNERS = REGISTRATION_STEP.BENEFICIAL_OWNERS;
// const TERMS_AND_CONDITIONS = REGISTRATION_STEP.TERMS_AND_CONDITIONS;
const ACCOUNT_AGREEMENT = REGISTRATION_STEP.ACCOUNT_AGREEMENT;

export function SlideStep(props = {}) {
    const { customerDetailsState } = props;
    const customerDetailsStateData = customerDetailsState.data || {};
    const currentStep = customerDetailsStateData.currentStep || CUSTOMER_INTRODUCTION;
    const slideStepDirection = customerDetailsStateData.slideStepDirection === 'right' ? 'top' : 'up';
    // const slideStepDirection = 'left';
    return (<Slide style={{ display: 'flex', flex: 1, flexDirection: 'column' }} direction={slideStepDirection} timeout={500} in={REGISTRATION_STEP[currentStep] || ACCOUNT_AGREEMENT} mountOnEnter unmountOnExit>
        <div>{props.children}</div>
    </Slide>);
}

const mapStateToProps = createStructuredSelector({
    customerDetailsState: getCustomerDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SlideStep);