const CARD_PAYMENTS = {
    code: "CARD_PAYMENTS",
    name: "Card Payments",
    path: "/card-payments",
    functions: {
        CARD_PAYMENTS_LIST: {
            code: "CARD_PAYMENTS_LIST",
            name: "Card transaction list",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_LIST.path}`,
            getFullPath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_LIST.path}`,
        },
        CARD_PAYMENTS_SEARCH: {
            code: "CARD_PAYMENTS_SEARCH",
            name: "Card Transaction Search",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_SEARCH.path}`,
            getFullPath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_SEARCH.path}`,
        },
        CARD_PAYMENTS_DETAILS: {
            code: "CARD_PAYMENTS_DETAILS",
            name: "Card Transaction Details",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_DETAILS.path}`,
            getFullPath: (cardTransactionId) =>
                `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_DETAILS.path}`.replace(
                    ":cardTransactionId",
                    cardTransactionId
                ),
        },
        CARD_PAYMENTS_FILTER: {
            code: "CARD_PAYMENTS_FILTER",
            name: "Card Transaction Filter",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_FILTER.path}`,
            getFullPath: () => `${CARD_PAYMENTS.path}${CARD_PAYMENTS.functions.CARD_PAYMENTS_FILTER.path}`,
        },
    },


};

export default CARD_PAYMENTS;
