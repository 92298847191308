import _get from "lodash.get";


export const aclListParser = (list) => {
    let r = [];

    if (!list) {
        list = []
    }
    if (list.data) {
        list = list.data
    }

    r = list.map(d => ({
        trulyModuleCode: _get(d, 'truly_module_code', ''),
        trulyModuleName: _get(d, 'truly_module_name', ''),
        trulyModuleUiPath: _get(d, 'truly_module_ui_path', ''),

        subModules: _get(d, 'subModules', []).map(s => ({
            trulySubModuleCode: s.truly_sub_module_code,
            trulySubModuleName: s.truly_sub_module_name,
            trulySubModuleUiPath: s.truly_sub_module_ui_path,

            functions: _get(s, 'functions', []).map(f => ({
                functionId: f.truly_function_id,
                functionName: f.function_name,
                functionUiPath: f.function_ui_path,
                functionApiEndpoint: f.function_api_endpoint,
                functionApiMethod: f.function_api_method,
                functionUiCode: f.function_ui_code
            }))
        })),

        functions: _get(d, 'functions', []).map(f => ({
            functionId: f.truly_function_id,
            functionName: f.function_name,
            functionUiPath: f.function_ui_path,
            functionApiEndpoint: f.function_api_endpoint,
            functionApiMethod: f.function_api_method,
            functionUiCode: f.function_ui_code
        }))
    }));

    return r;
}

