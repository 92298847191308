/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button, TextField } from 'components/ui';
import React, { useState, memo, useEffect, useCallback } from 'react';
import { Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SelectCountry from 'containers/Common/SelectCountry';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import SelectState from 'containers/Common/SelectState';
import { Loader } from 'components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { addBusinessGlobalPositionAction, addCustomerContactDetailsAction, tempSaveRegistrationDataAction } from 'store/Register/registerActions';
import { addBusinessGlobalPositionState, addCustomerContactDetailsState, getCustomerDetailsState, tempRegistrationDataState } from 'store/Register/registerSelectors';
import SearchLocation from 'containers/Common/SearchLocation';
import { getDirtyValues, checkAddressArrayIsEmpty, groupRegionWiseCountry } from 'utils/common';
import GoBack from '../GoBack';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
import TypicallyDoBusiness from '../TypicallyDoBusiness';
import { getCountryListState, getDestinationCountryListState } from 'store/Reference/referenceSelectors';
import { getCountryListAction } from 'store/Reference/referenceActions';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';

const BUSINESS_COUNTRIES = REGISTRATION_STEP.BUSINESS_COUNTRIES;

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#fff',

    },

    content: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    contentButton: {
        marginBottom: theme.spacing(1.5),
        alignItems: 'center'
    },
    contentMain: {
        textAlign: 'center',


    },
    textAlignLeft: {
        textAlign: 'left',
    },
    subContent: {
        display: 'grid',
        //  height: '80vh'
    },
    overflow: {
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: theme.spacing(5, 0),
    },
    margin: {
        margin: theme.spacing(2, 0)
    },
    align: {
        display: 'flex',
        alignItems: 'center'
    },
    inputDiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: "left"
    },
    sameOperationDiv: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        textAlign: "left"
    },
    justifyContentCenter: {
        justifyContent: 'center'
    },
    mainTitle: {
        marginTop: theme.spacing(3),
    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),

    },
    operationAddress: {
        textAlign: "left",
        margin: theme.spacing(0, 0, -1, 0)
    },
    inCorporationAddress: {
        margin: theme.spacing(0, 0, 0, 0)
    },
    radioClassNam: {
        margin: theme.spacing(0, 0, 0, 0)
    },
    commonFlex: {
        display: "flex",
        alignItems: 'center'
    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    btnDiv: {
        flex: 1
    },
    lastMainGrid: {
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',

        }
    },
    lastTxt: {
        margin: theme.spacing(0, 0, 0, 0),
        color: theme.palette.accent.main,
        fontStyle: "italic"
    },
    radioDiv: {
        marginLeft: theme.spacing(0.5)
    },
    gridDiv: {
        flex: 1,
        display: 'flex'
    },
    optionalText: {
        textAlign: 'left',
        paddingTop: theme.spacing(0.5),
    },
    inputPlaceHolder: {
        '& .MuiFormLabel-root': {
            ...theme.typography.subtitle1
        }

    },
}));

const initialPrimaryAddress = {
    address_type: 'PRIMARY',
    address_line_1: '',
    address_line_2: '',
    landmark: '',
    city: '',
    state_name: '',
    state_code: '',
    country_code: '',
    postal_code: '',
    address_id: ''
};

const initialCommunicationAddress = {
    address_type: 'COMMUNICATION',
    address_line_1: '',
    address_line_2: '',
    landmark: '',
    city: '',
    state_name: '',
    state_code: '',
    country_code: '',
    postal_code: '',
    address_id: ''
};

function BusinessAddress(props = {}) {
    const { onContinue = () => null, onBackPress = () => null, tempRegistrationDataState, tempSaveRegistrationData,
        //  addCustomerContactDetailsState, addCustomerContactDetails,
        getCustomerDetailsState, countries, getCountries, addBusinessGlobalPosition, addBusinessGlobalPositionState } = props;
    const classes = useStyles();
    const [value, setValue] = useState("");
    const intlStr = useIntl();
    const theme = useTheme();
    // eslint-disable-next-line no-unused-vars
    const [formData, setFormData] = useState({});
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const [primaryAddress, setPrimaryAddress] = useState(initialPrimaryAddress);
    const [communicationAddress, setCommunicationAddress] = useState(initialCommunicationAddress);
    const isLoading = ((addBusinessGlobalPositionState && addBusinessGlobalPositionState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading));
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [preSelectedCountry, setPreSelectedCountry] = useState({});

    useEffect(() => {
        if (Object.keys(formData).length > 0 || Object.keys(communicationAddress).length > 0 || Object.keys(primaryAddress).length > 0) {
            let address = [];
            if (Object.keys(communicationAddress).length > 0) {
                address.push(communicationAddress);
            }
            if (Object.keys(primaryAddress).length > 0) {
                address.push(primaryAddress);
            }
            tempSaveRegistrationData({
                prs_business_address_data: { ...formData, addresses: address },
            });

        }
    }, [formData, communicationAddress, primaryAddress]);

    useEffect(() => {
        setPreSelectValueOnAddressCountryChange();

    }, [communicationAddress.country_code, primaryAddress.country_code,])
    /// countries 

    useEffect(() => {

        tempSaveRegistrationData({
            prs_business_countries_data: [...selectedCountry],
        });
    }, [selectedCountry]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_LOCATION_PAGE_LOAD.event })

        if (!countries.data.length) {
            getCountries();
        }
    }, []);

    useEffect(() => {

        let ob = { ...formData, ...customerDetail.prs_business_address_data };

        if (tempRegistrationDataState && tempRegistrationDataState.prs_business_address_data && checkAddressArrayIsEmpty(tempRegistrationDataState.prs_business_address_data)) {
            ob = { ...formData, ...customerDetail.prs_business_address_data, ...tempRegistrationDataState.prs_business_address_data }
        }

        if (ob && Object.keys(ob).length > 0 && ob.addresses && ob.addresses.length > 0) {
            let filterCommAdd = ob.addresses.filter((item) => item.address_type === "COMMUNICATION");
            var newArr = JSON.parse(JSON.stringify(filterCommAdd));
            if (filterCommAdd.length > 0) {
                setCommunicationAddress(newArr[0]);
            }
        }
        if (ob && Object.keys(ob).length > 0) {
            setValue((ob.primary_address_same_as_communication === "false" || ob.primary_address_same_as_communication === 0) ? "false" : "true");

            if ((ob.primary_address_same_as_communication === "false" || ob.primary_address_same_as_communication === 0) && ob.addresses && ob.addresses.length > 0) {
                let filterPrimaryAdd = ob.addresses.filter((item) => item.address_type === "PRIMARY");
                if (filterPrimaryAdd.length > 0) {
                    setPrimaryAddress(filterPrimaryAdd[0]);
                }
            }
        }

        if (customerDetail.company_details?.incorporation_country_code && customerDetail.prs_business_address_data?.addresses && customerDetail.prs_business_address_data?.addresses.length === 0 &&
            communicationAddress && !communicationAddress.country_code) {
            let communicationAddressTemp = { ...communicationAddress };
            communicationAddressTemp.country_code = customerDetail.company_details.incorporation_country_code;
            setCommunicationAddress(communicationAddressTemp);
        }
    }, [customerDetail.prs_business_address_data]);

    /// countries 

    useEffect(() => {

        if (!customerDetail?.completed_steps?.includes(BUSINESS_COUNTRIES) && !customerDetail.prs_business_countries_data?.countries?.length &&
            !tempRegistrationDataState?.prs_business_countries_data?.length) {
            let preCountryArray = [];
            if (customerDetail?.prs_customer_introduction_data?.incorporation_country_code) {
                Array.isArray(countries.data) && countries.data.forEach((obj) => {
                    if (obj.country_code === customerDetail?.prs_customer_introduction_data?.incorporation_country_code)
                        preCountryArray.push(obj);
                });
            }
            setCountryList(preCountryArray);
        }

        if (customerDetail && customerDetail.prs_business_countries_data && customerDetail.prs_business_countries_data.countries && customerDetail.prs_business_countries_data.countries.length > 0) {
            let countryArray = [];
            customerDetail.prs_business_countries_data.countries.forEach((item) => {
                Array.isArray(countries.data) && countries.data.forEach((obj) => {
                    if (obj.country_code === item.country_code)
                        countryArray.push(obj);
                });
            });
            setCountryList(countryArray);
        }

        if (tempRegistrationDataState && tempRegistrationDataState.prs_business_countries_data && tempRegistrationDataState.prs_business_countries_data.length > 0) {
            setCountryList(tempRegistrationDataState.prs_business_countries_data.length)
        }

    }, [customerDetail.prs_business_countries_data, countries.data]);

    const onSuccess = (arg = "onBoarding") => {
        onContinue(arg);
    };
    const setCountryList = (array = []) => {
        if (array.length > 0) {
            setSelectedCountry(array);
            let regionWiseCountry = groupRegionWiseCountry(array);
            setPreSelectedCountry(regionWiseCountry);
        }
    };

    const setPreSelectValueOnAddressCountryChange = () => {
        if (customerDetail && customerDetail.completed_steps && !customerDetail.completed_steps.includes(BUSINESS_COUNTRIES)) {

            let preCountryArray = [];
            if (customerDetail.prs_customer_introduction_data && customerDetail.prs_customer_introduction_data.incorporation_country_code) {
                Array.isArray(countries.data) && countries.data.forEach((obj) => {
                    if (obj.country_code === customerDetail.prs_customer_introduction_data.incorporation_country_code)
                        preCountryArray.push(obj);
                });
            }
            if (primaryAddress.country_code) {
                Array.isArray(countries.data) && countries.data.forEach((obj) => {
                    if (obj.country_code === primaryAddress.country_code)
                        preCountryArray.push(obj);
                });
            }
            if (communicationAddress.country_code) {
                Array.isArray(countries.data) && countries.data.forEach((obj) => {
                    if (obj.country_code === communicationAddress.country_code)
                        preCountryArray.push(obj);
                });
            }

            setSelectedCountry(selectedCountry => {
                let mergedArray = [...selectedCountry, ...preCountryArray];
                let removeDuplicate = mergedArray.filter((v, i, a) => a.findIndex(v2 => (v2.country_code === v.country_code)) === i)
                return removeDuplicate;
            });
            let regionWiseCountry = groupRegionWiseCountry(preCountryArray);
            setPreSelectedCountry(regionWiseCountry);
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const body = {};

        let filterCommAdd = customerDetail.prs_business_address_data.addresses.filter((item) => item.address_type === "COMMUNICATION");
        let filterPrimaryAdd = customerDetail.prs_business_address_data.addresses.filter((item) => item.address_type === "PRIMARY");
        let dirtyPrimaryAdd = getDirtyValues(filterPrimaryAdd[0], primaryAddress);
        let dirtyCommunicationAdd = getDirtyValues(filterCommAdd[0], communicationAddress);
        if (dirtyPrimaryAdd.address_id) {
            delete dirtyPrimaryAdd.address_id;
        }

        if (dirtyCommunicationAdd.address_id) {
            delete dirtyCommunicationAdd.address_id;
        }

        if (Object.keys(dirtyPrimaryAdd).length > 0 || Object.keys(dirtyCommunicationAdd).length > 0) {
            const bodyCustomerDetail = {
                primaryAddress: primaryAddress,
                communicationAddress: communicationAddress,
                primary_address_same_as_communication: value === "false" ? false : true
            };
            body.bodyCustomerDetail = bodyCustomerDetail;
        }

        let a = selectedCountry.filter(i => !customerDetail.prs_business_countries_data.countries.some(j => j.country_code === i.country_code))
        let b = customerDetail.prs_business_countries_data.countries.filter(i => !selectedCountry.some(j => j.country_code === i.country_code))

        if ([...a, ...b].length > 0) {
            let countryCodeArray = [];
            if (selectedCountry.length > 0) {

                selectedCountry.forEach((item) => {
                    countryCodeArray.push(item.country_code);
                });

            }
            body.bodyCountry = {
                countries: countryCodeArray
            };
        }

        if ((body.bodyCountry && body.bodyCountry.countries && Array.isArray(body.bodyCountry.countries) && body.bodyCountry.countries.length > 0) || Object.keys(dirtyPrimaryAdd).length > 0 || Object.keys(dirtyCommunicationAdd).length > 0) {
            addBusinessGlobalPosition({
                body,
                cb: onSuccess
            });

        } else {
            onSuccess("header");
        }
        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_LOCATION_CONTINUE_CLICK.event })
        if (value) {
            let trackingProperties = ANALYTICS.EVENTS.BUSINESS_LOCATION_SAME_AS_INCORPORATION.tracking_properties;

            let tracking_data = {};
            trackingProperties.forEach((tP) => {
                return tracking_data[tP.key] = value === "true" ? "YES" : "NO";
            })
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_LOCATION_SAME_AS_INCORPORATION.event, tracking_data })
        }
    };

    const onChange = (e) => {
        const { name, value } = e.target || {};
        setValue(value);
        setFormData((formData) => ({ ...formData, [name]: value }));
        if (value === "false") {
            setPrimaryAddress(initialPrimaryAddress);
        }

    };

    useEffect(() => {
        if (value === "true") {

            setPrimaryAddress((formData) => ({
                ...formData,
                address_type: "PRIMARY",
                address_line_1: communicationAddress.address_line_1,
                address_line_2: communicationAddress.address_line_2,
                landmark: communicationAddress.landmark,
                city: communicationAddress.city,
                state_name: communicationAddress.state_name,
                state_code: communicationAddress.state_code,
                country_code: communicationAddress.country_code,
                postal_code: communicationAddress.postal_code,
                address_id: communicationAddress.address_id,

            }));
        }

    }, [value]);

    const onPrimaryAddressFieldChange = useCallback((e, val) => {
        const { name, value } = e.target || {};
        setPrimaryAddress(formData => ({ ...formData, [name]: value }));
    }, []);
    const onPrimaryAddressCountryFieldChange = useCallback((e, val) => {
        const { name, value } = e.target || {};

        setPrimaryAddress(formData => ({ ...formData, [name]: value }));

    }, []);
    const onCommunicationAddressFieldChange = useCallback((e, val) => {
        const { name, value } = e.target || {};
        setCommunicationAddress(formData => ({ ...formData, [name]: value }));
    }, []);
    const onCommunicationAddressCountryFieldChange = useCallback((e, val) => {
        const { name, value } = e.target || {};

        setCommunicationAddress(formData => ({ ...formData, [name]: value }));

    }, []);

    const onPrimaryAddressStateChange = useCallback((e, v) => {
        onPrimaryAddressFieldChange(e);
        onPrimaryAddressFieldChange({ target: { id: 'state_name', name: 'state_name', value: v?.state_name || '' } });
    }, [onPrimaryAddressFieldChange]);

    const onCommunicationAddressStateChange = useCallback((e, v) => {
        onCommunicationAddressFieldChange(e);
        onCommunicationAddressFieldChange({ target: { id: 'state_name', name: 'state_name', value: v?.state_name || '' } });
    }, [onCommunicationAddressFieldChange]);

    // eslint-disable-next-line no-unused-vars
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));

    const onCommunicationLocationChange = useCallback((e, val = {}, reason) => {
        if (reason === 'input') {
            setCommunicationAddress(d => ({
                ...d,
                address_line_1: val.address_line_1,
            }));
            return;
        }
        if (Object.keys(val).includes("address")) {
            setCommunicationAddress(d => ({
                ...d,
                address_line_1: val.address_line_1,
                // address_line_2: val.route,
                city: val.city,
                state_name: val.state,
                state_code: val.state_code,
                country_code: val.country_code,
                postal_code: val.postal_code,
            }));
            setTimeout(() => {
                setCommunicationAddress(d => ({
                    ...d,
                    state_name: val.state,
                    state_code: val.state_code,
                }));
            }, 300);
        }
    }, []);

    const onPrimaryLocationChange = useCallback((e, val = {}, reason) => {
        if (reason === 'input') {
            setPrimaryAddress(d => ({
                ...d,
                address_line_1: val.address_line_1,
            }));
            return;
        }
        if (Object.keys(val).includes("address")) {
            setPrimaryAddress(d => ({
                ...d,
                address_line_1: val.address_line_1,
                // address_line_2: val.route,
                city: val.city,
                state_name: val.state,
                state_code: val.state_code,
                country_code: val.country_code,
                postal_code: val.postal_code,
            }));
            setTimeout(() => {
                setPrimaryAddress(d => ({
                    ...d,
                    state_name: val.state,
                    state_code: val.state_code,
                }));
            }, 300);
        }
    }, []);
    const onChangeCountry = (list) => {

        setSelectedCountry(list);
    };

    return (<>
        <form className={classes.root} onSubmit={onSubmit}>
            {
                isLoading && <Loader />
            }
            <Grid container justifyContent="center" className={classes.contentMain}>
                <Grid item xs="11" md="9" className={classes.subContent}>
                    <Grid container justifyContent="center">
                        <Grid item md="12">
                            <Typography className={classes.mainTitle} variant={"h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep4TitleStart} /></Typography>

                            <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep4SubTitle} /></Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.overflow}>
                        <Grid item md="7">
                            <div>
                                <Typography className={classes.operationAddress} variant="subtitle1" color="grey700"><FormattedMessage {...intl.onBoardingStep4AddressOfOperation} /></Typography>
                            </div>
                            <div>
                                <div className={classes.inputDiv} >
                                    <SelectCountry
                                        id={`c_country_code`}
                                        name="country_code"
                                        value={communicationAddress.country_code}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4Country)}`}
                                        required
                                        errorText={""}
                                        // error={errors.bank_country_code}
                                        onChange={onCommunicationAddressCountryFieldChange}
                                    // onFocusRef={onRefFocus}
                                    // makeFocus={inputRefs[0] === "prs_country" ? true : false}
                                    // onBlur={() => { onRefFocus("clear") }}
                                    />
                                </div>
                                <div className={classes.inputDiv}>
                                    <SearchLocation
                                        compId="communicationAddress"
                                        countryCode={communicationAddress.country_code}
                                        id="c_address_line_1"
                                        name="address_line_1"
                                        value={communicationAddress.address_line_1}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4StreetAddress)}`}
                                        // required={communicationAddress.address_line_1}
                                        required
                                        errorText={""}
                                        // errorText={addUpdateContactResp.error.address_line_1 || defaultErrorText}
                                        // error={addUpdateContactResp.error.address_line_1}
                                        onChange={onCommunicationLocationChange}
                                    // onInputChange={onLocationInputChange}
                                    // onBlur={onBlurSearLocation}
                                    // onFocusRef={onRefFocus}
                                    // makeFocus={inputRefs[0] === "m_address_line_1"}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        type="text"
                                        id="c_address_line_2"
                                        name="address_line_2"
                                        specialChar={false}
                                        value={communicationAddress.address_line_2}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4Apartment)}`}
                                        onChange={onCommunicationAddressFieldChange}
                                        errorText={""}
                                    // CustomInputProps={{ classes: { input: classes.inputPlaceHolder } }}
                                    // onFocusRef={onRefFocus}
                                    // makeFocus={inputRefs[0] === "address_line_2" ? true : false}
                                    // onBlur={() => { onRefFocus("clear") }}
                                    />
                                    <div className={classes.optionalText}>
                                        <Typography className={classes.helperTextMargin} variant="caption" color="grey500">{`${intlStr.formatMessage(intl.onBoardingStepOption)}`}</Typography>
                                    </div>
                                </div>
                                {/* <div className={classes.inputDiv}>
                                    <TextField
                                        id="c_landmark"
                                        name="landmark"
                                        specialChar={false}
                                        value={communicationAddress.landmark}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4Landmark)}`}
                                        onChange={onCommunicationAddressFieldChange}
                                    // required
                                    // errorText={""}
                                    />
                                </div> */}
                                <div className={classes.inputDiv}>
                                    <TextField
                                        id="c_city"
                                        name="city"
                                        specialChar={false}
                                        value={communicationAddress.city}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4City)}`}
                                        onChange={onCommunicationAddressFieldChange}
                                        required
                                        errorText={""}
                                    />
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={7}>
                                        <SelectState
                                            id="c_state_code"
                                            name="state_code"
                                            value={communicationAddress.state_code}
                                            valueObj={{ state_code: communicationAddress.state_code, state_name: communicationAddress.state_name }}
                                            label={`${intlStr.formatMessage(intl.onBoardingStep4State)}`}
                                            onChange={onCommunicationAddressStateChange}
                                            countryCode={communicationAddress.country_code}
                                            required
                                            errorText={""}
                                            className={classes.state_code}
                                        // onFocusRef={onRefFocus}
                                        // makeFocus={inputRefs[0] === "m_state_code"}
                                        // onBlur={clearRefFocus}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <TextField
                                            type="text"
                                            id="c_postal_code"
                                            name="postal_code"
                                            value={communicationAddress.postal_code}
                                            label={`${intlStr.formatMessage(intl.onBoardingStep4Postal)}`}
                                            onChange={onCommunicationAddressFieldChange}
                                            inputProps={{
                                                pattern: '^[a-zA-Z0-9 -]{4,7}$',
                                                minlength: 4,
                                                maxlength: 7,
                                            }}
                                            required
                                            errorText={""}
                                        />
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={classes.sameOperationDiv}>
                                <div className={classes.inCorporationAddress}>
                                    <Typography variant="subtitle1" className={classes.textAlignLeft}><FormattedMessage {...intl.onBoardingStep4AddressOfIncorporation} /></Typography>
                                </div>
                                <Grid container md={12} className={classes.commonFlex}>
                                    <Grid item className={classes.gridDiv} ><Typography variant="body2"><FormattedMessage {...intl.onBoardingStep4SameAddressTitle} /></Typography></Grid>
                                    <div className={classes.radioDiv} item>
                                        <RadioGroup row required aria-label="primary_address_same_as_communication" name="primary_address_same_as_communication" value={value} onChange={onChange} classes={{ row: classes.justifyContentCenter }}>
                                            <FormControlLabel value="true" control={<Radio className={classes.iconColor} checked={value === "true" && true} />} label={`${intlStr.formatMessage(intl.onBoardingStep4RadioBtnYes)}`} />
                                            <FormControlLabel className={classes.radioClassNam} value="false" control={<Radio className={classes.iconColor} checked={value === "false" && true} />} label={`${intlStr.formatMessage(intl.onBoardingStep4RadioBtnNo)}`} />
                                        </RadioGroup>
                                    </div>
                                </Grid>
                            </div>
                            {value === "false" ? <>

                                <div className={classes.inputDiv} >
                                    <SelectCountry
                                        id={`p_country_code`}
                                        name="country_code"
                                        value={primaryAddress.country_code}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4Country)}`}
                                        required
                                        errorText={""}
                                        // error={errors.bank_country_code}
                                        onChange={onPrimaryAddressCountryFieldChange}
                                    // onFocusRef={onRefFocus}
                                    // makeFocus={inputRefs[0] === "prs_country" ? true : false}
                                    // onBlur={() => { onRefFocus("clear") }}

                                    />
                                </div>
                                <div className={classes.inputDiv}>
                                    <SearchLocation
                                        compId="primaryAddress"
                                        id="c_address_line_1"
                                        name="address_line_1"
                                        countryCode={primaryAddress.country_code}
                                        value={primaryAddress.address_line_1}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4StreetAddress)}`}
                                        // required={primary.address_line_1}
                                        required
                                        errorText={""}
                                        // errorText={addUpdateContactResp.error.address_line_1 || defaultErrorText}
                                        // error={addUpdateContactResp.error.address_line_1}
                                        onChange={onPrimaryLocationChange}
                                    // onInputChange={onLocationInputChange}
                                    // onBlur={onBlurSearLocation}
                                    // onFocusRef={onRefFocus}
                                    // makeFocus={inputRefs[0] === "m_address_line_1"}
                                    />
                                </div>
                                <div className={classes.inputDiv}>
                                    <TextField
                                        type="text"
                                        id="p_address_line_2"
                                        name="address_line_2"
                                        specialChar={false}
                                        errorText={""}
                                        value={primaryAddress.address_line_2}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4Apartment)}`}
                                        onChange={onPrimaryAddressFieldChange}
                                    // onFocusRef={onRefFocus}
                                    // makeFocus={inputRefs[0] === "address_line_2" ? true : false}
                                    // onBlur={() => { onRefFocus("clear") }}
                                    />
                                    <div className={classes.optionalText}>
                                        <Typography className={classes.helperTextMargin} variant="caption" color="grey500">{`${intlStr.formatMessage(intl.onBoardingStepOption)}`}</Typography>
                                    </div>
                                </div>
                                {/* <div className={classes.inputDiv}>
                                    <TextField
                                        id="p_landmark"
                                        name="landmark"
                                        specialChar={false}
                                        value={primaryAddress.landmark}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4Landmark)}`}
                                        onChange={onPrimaryAddressFieldChange}
                                    // required
                                    // errorText={""}
                                    />
                                </div> */}
                                <div className={classes.inputDiv}>
                                    <TextField
                                        id="p_city"
                                        name="city"
                                        specialChar={false}
                                        value={primaryAddress.city}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep4City)}`}
                                        onChange={onPrimaryAddressFieldChange}
                                        required
                                        errorText={""}
                                    />
                                </div>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={7}>
                                        <SelectState
                                            id="p_state_code"
                                            name="state_code"
                                            value={primaryAddress.state_code}
                                            valueObj={{ state_code: primaryAddress.state_code, state_name: primaryAddress.state_name }}
                                            label={`${intlStr.formatMessage(intl.onBoardingStep4State)}`}
                                            onChange={onPrimaryAddressStateChange}
                                            countryCode={primaryAddress.country_code}
                                            required
                                            errorText={""}
                                        // onFocusRef={onRefFocus}
                                        // makeFocus={inputRefs[0] === "m_state_code"}
                                        // onBlur={clearRefFocus}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={5}>
                                        <TextField
                                            type="text"
                                            id="p_postal_code"
                                            name="postal_code"
                                            value={primaryAddress.postal_code}
                                            label={`${intlStr.formatMessage(intl.onBoardingStep4Postal)}`}
                                            onChange={onPrimaryAddressFieldChange}
                                            inputProps={{
                                                pattern: '^[a-zA-Z0-9 -]{4,7}$',
                                                minlength: 4,
                                                maxlength: 7,
                                            }}
                                            required
                                            errorText={""}
                                        />
                                    </Grid>
                                </Grid>
                            </>

                                : null}
                        </Grid>
                        <Grid item xs="12" md="10" className={classes.subContent}>
                            <Grid justifyContent="center" container>
                                <Grid item md="12" >
                                    <Typography className={classes.mainTitle} variant={"h6"} color="grey700"><FormattedMessage {...intl.onBoardingStep7Title} /></Typography>
                                    {/* <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep7Subtitle} /></Typography> */}
                                </Grid>
                            </Grid>
                            <div className={classes.content}>
                                <Grid justifyContent="center" container>
                                    <Grid item xs="12" md="12" className={classes.gridDiv}>
                                        <TypicallyDoBusiness selectedCountryArray={selectedCountry} preSelectedCountryObj={preSelectedCountry} countries={countries} onChangeHandleCountry={(list) => { onChangeCountry(list); }} />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs="12" md="11" >
                            {
                                selectedCountry && selectedCountry.length === 0 && <Typography className={classes.lastTxt} ><FormattedMessage {...intl.onBoardingStepAddress} /></Typography>
                            }

                        </Grid>

                    </Grid>

                    <Grid container className={classes.lastMainGrid}>
                        {/* <Grid item xs="5" md="3">
                            <Button size="small" title="Back" startIcon={<NavigateBeforeIcon />} variant="outlined" onClick={onClick} />
                        </Grid>
                        <Grid item md="6"></Grid> */}
                        <GoBack onClick={onBackPress} isLoading={isLoading} />
                        <Grid item md="3" className={classes.btnDiv}>
                            <Button size="small" type="submit"
                                disabled={isLoading || (selectedCountry && selectedCountry.length === 0)}
                                className={classes.btnDiv}
                            >
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep4ContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </>);
}


const mapStateToProps = (s, p) => {
    return createStructuredSelector({
        addCustomerContactDetailsState: addCustomerContactDetailsState(),
        getCustomerDetailsState: getCustomerDetailsState(),
        tempRegistrationDataState: tempRegistrationDataState(),
        countries: p.onlyDestination ? getDestinationCountryListState() : getCountryListState(),
        addBusinessGlobalPositionState: addBusinessGlobalPositionState(),
    })
};

function mapDispatchToProps(dispatch) {
    return {
        addCustomerContactDetails: data => dispatch(addCustomerContactDetailsAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
        getCountries: data => dispatch(getCountryListAction(data)),
        addBusinessGlobalPosition: data => dispatch(addBusinessGlobalPositionAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(BusinessAddress);


