
import { defineMessages } from 'react-intl';

export const scope = 'src.components.send_amount';

export default defineMessages({
    amount: {
        id: `${scope}.amount`,
        defaultMessage: 'Amount to Send',
    },
    send_from: {
        id: `${scope}.send_from`,
        defaultMessage: 'Send From',
    },
    sendToLabel: {
        id: `${scope}.sendToLabel`,
        defaultMessage: 'Send to',
    },
    depositToLabel: {
        id: `${scope}.depositToLabel`,
        defaultMessage: 'Deposit in',
    },
    chooseRecurrence: {
        id: `${scope}.chooseRecurrence`,
        defaultMessage: 'Sending on',
    },
    selectPaymentAcc: {
        id: `${scope}.selectPaymentAcc`,
        defaultMessage: 'Paying From',
    },
    amountTxt: {
        id: `${scope}.amountTxt`,
        defaultMessage: 'Amount',
    },
    paymentPurpose: {
        id: `${scope}.paymentPurpose`,
        defaultMessage: 'Purpose of Payment',
    },
    paymentInvoiceNumber: {
        id: `${scope}.paymentInvoiceNumber`,
        defaultMessage: 'Invoice Number',
    },
    paymentInvoiceDate: {
        id: `${scope}.paymentInvoiceDate`,
        defaultMessage: 'Invoice Date',
    },
    sendOn: {
        id: `${scope}.sendOn`,
        defaultMessage: 'Sending on',
    },
    infoTxt: {
        id: `${scope}.infoTxt`,
        defaultMessage: '*Amount subject to change based on exchange rate at the time of payment initiation. ',
    },
    repeatPayment: {
        id: `${scope}.repeatPayment`,
        defaultMessage: 'Repeat this Payment?',
    },
    repeat: {
        id: `${scope}.repeat`,
        defaultMessage: 'Repeat',
    },
    ends: {
        id: `${scope}.ends`,
        defaultMessage: 'Ends',
    },
    purpose: {
        id: `${scope}.purpose`,
        defaultMessage: 'Purpose',
    },
    note: {
        id: `${scope}.note`,
        defaultMessage: 'Note to Recipient',
    },
    invoiceNumberLabel: {
        id: `${scope}.invoiceNumberLabel`,
        defaultMessage: 'Invoice Number',
    },
    invoiceDateLabel: {
        id: `${scope}.invoiceDateLabel`,
        defaultMessage: 'Invoice Date',
    },
    invoiceLabel: {
        id: `${scope}.invoiceLabel`,
        defaultMessage: 'Invoice Number',
    },
    timeline: {
        id: `${scope}.timeline`,
        defaultMessage: 'Timeline',
    },
    subtotal: {
        id: `${scope}.subtotal`,
        defaultMessage: 'Subtotal',
    },
    conversion: {
        id: `${scope}.conversion`,
        defaultMessage: 'Exchange Rate',
    },
    fee: {
        id: `${scope}.fee`,
        defaultMessage: 'Fee',
    },
    total: {
        id: `${scope}.total `,
        defaultMessage: 'Total ',
    },
    pay_from: {
        id: `${scope}.pay_from`,
        defaultMessage: 'Paying From',
    },
    pay_from_select_label: {
        id: `${scope}.pay_from`,
        defaultMessage: 'Pay From',
    },
});
