import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from 'components/ui';
import { TextField } from '@material-ui/core';
import CurrencySelector from './CurrencySelector';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  amountDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: 'center',
    marginTop: theme.spacing(4)
  },
  error: {
    textAlign: "center",
    margin: theme.spacing(0, 0, 0, 0),
    color: theme.palette.error.main,
  },
  resize: (props) => ({
    ...theme.typography.h3,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: props.inputAmount.length ? 'center' : 'right'
  }),
  currencyField: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
}));

function AmountTextFiled(props) {

  const { getCurrencies, currencies, amount, payerBank, contactBankAccounts, setAmount,
    onCurrencySelect, selectedCurrency, setSelectedCurrency, error, onAmountChange = () => null } = props;

    const [inputAmount, setInputAmount] = useState(amount);
    const numberInputRef = useRef();
    const theme = useTheme();
    const classes = useStyles({inputAmount});


  useEffect(() => {
    if (numberInputRef.current) {
      // numberInputRef.current.focus();
    }
  }, []);

  useEffect(() => {

    if (amount !== inputAmount) {
      setInputAmount(amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const actionCurrencySelect = (selectedCurrency) => {
    onCurrencySelect(selectedCurrency);
  };
  const onInputAmountChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    let trimValue = value.trim();

    let regex = /^(\d*)\.?(\d{0,2})?$/g;
    if (regex.test(trimValue)) {
      setInputAmount(trimValue);
      setAmount(trimValue);
    }
    onAmountChange();
  };

  return (
    <div className={classes.root}>
      <div className={classes.amountDiv}>

        {/* <div style={{ width: inputAmount.length ? (inputAmount.length) * theme.spacing(4.5) : theme.spacing(16), alignSelf: 'center', }} > */}
        <div style={{ width: inputAmount.length ? 'auto' : theme.spacing(16), alignSelf: 'center'}} >
          <TextField
            type="tel"
            inputRef={numberInputRef}
            value={(inputAmount).toLocaleString()}
            onChange={onInputAmountChange}
            className={classes.currencyField}
            placeholder="0.00"
            inputProps={{

            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                input: classes.resize,
              },
            }}

          />
        </div>

      </div>
      <div>
        <CurrencySelector
          payerBank={payerBank}
          contactBankAccounts={contactBankAccounts}
          getCurrencies={getCurrencies}
          currencies={currencies}
          error={error}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
          onSelect={(selectedCurrency) => { actionCurrencySelect(selectedCurrency) }}
        />
      </div>
      <div className={classes.error}>
        <Typography color="error" variant="caption">{error} &nbsp;</Typography>
      </div>

    </div>
  );
}

AmountTextFiled.defaultProps = {
  getCurrencies: () => { },
  onCurrencySelect: () => { },

}
AmountTextFiled.propTypes = {
  getCurrencies: PropTypes.func,
  currencies: PropTypes.any,
  amount: PropTypes.any,
  setAmount: PropTypes.any,
  onCurrencySelect: PropTypes.func,
  selectedCurrency: PropTypes.any,
  setSelectedCurrency: PropTypes.any
};

export default memo(AmountTextFiled);
