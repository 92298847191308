
import React, { memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { GuideButtons } from 'components';
import { FormattedMessage } from 'react-intl';
import intl from './intl/RegistrationCompleteDashboard';
import { Typography } from 'components/ui';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
// eslint-disable-next-line no-unused-vars
import { GotoTrulyButton } from './GotoTrulyButton';
import { setSelectedEntityState } from 'store/User/userSelectors';
import { updateUserLocalSettingsAction } from 'store/User/userActions';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4),
      backgroundColor: theme.palette.background.default,
    },
    backgroundColor: theme.palette.primary.contrastText,
    width: "100%",
    textAlign: "center",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: theme.spacing(1.5),
      borderTopRightRadius: theme.spacing(1.5),
    },

    backgroundColor: theme.palette.primary.contrastText,
    minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    // minHeight: `calc(100vh - 50px)`,
    // minHeight: `calc(100vh - 69px)`,
  },
  titleTxt: {
    marginTop: theme.spacing(8.25),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5.25),
    },
    marginBottom: theme.spacing(2),
  },
  titleSubTxt: {
    marginBottom: theme.spacing(4),
  },
  info1: {
    padding: theme.spacing(4),
  },
  pdMainView: {
    backgroundColor: theme.palette.grey50,
    borderRadius: theme.spacing(1),
    width: "auto",
    // height: theme.spacing(47.5),
    //height: '75%',
    flex: 1,
    margin: theme.spacing(4, 0),
    padding: theme.spacing(3, 3, 0, 3),
    display: "flex",
  },
  padSubView: {
    backgroundColor: "white",
    flex: 1,
    borderRadius: theme.spacing(1, 1, 0, 1),
  },
  iconColor: {
    color: `${theme.palette.grey500}`,
    "&.Mui-checked": {
      color: `${theme.palette.accent.main} !important`,
    },
  },
  btnDiv: {
    margin: theme.spacing(0, 1, 0, 1),
    padding: theme.spacing(0, 3),
  },
  correctionBtnDiv: {
    margin: theme.spacing(0, 1, 0, 1),
    padding: theme.spacing(0, 3),
    minWidth: theme.spacing(27),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  lastGrid: {
    margin: theme.spacing(2, "auto", 0, "auto"),
    display: "flex",
  },
  iframePdf: {
    width: "100%",
    height: "100%",
  },
  mainWrapperDiv: {
    flex: 0.75,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  column: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "relative",
  },
  gotoTrulyBtn: {
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

function CompanyStatusVerified(props = {}) {

  const { selectedEntity, updateUserLocalSettings } = props;
  const classes = useStyles();
  const loggedInAfterApproval = (selectedEntity.data && selectedEntity.data.user_entity_settings && selectedEntity.data.user_entity_settings.loggedInAfterApproval);
  useEffect(() => {
    if (selectedEntity.data && selectedEntity.data.entity_id && !loggedInAfterApproval) {
      updateUserLocalSettings({
        body: {
          loggedInAfterApproval: 1,
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity.data]);
  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.main}>
        <div className={classes.column}  >
          <div className={classes.mainWrapperDiv} >
            <Typography className={classes.titleTxt} color="grey700" variant="h5"> {loggedInAfterApproval ? <FormattedMessage {...intl.accountApprovedAndNextLogin} /> : <FormattedMessage {...intl.accountAppr} />}</Typography>
            {/* temporarily - removing guide buttons */}
            {/* <Typography className={classes.titleSubTxt} color="grey600" variant="body1"> <FormattedMessage {...intl.accountApprovedAndNextLoginSubText} /></Typography>
            <GuideButtons type="round" /> */}
          </div>
        </div>
        <div className={classes.gotoTrulyBtn}>
          <GotoTrulyButton />
        </div>
      </div>
    </Paper>
  );
}

const mapStateToProps = createStructuredSelector({
  selectedEntity: setSelectedEntityState(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateUserLocalSettings: (data) => dispatch(updateUserLocalSettingsAction(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(CompanyStatusVerified);
