import { call, /*delay,*/ put, takeLatest, takeLeading } from 'redux-saga/effects';
import { request, requestWithErrorFeedback } from 'utils/request';
import {
  // resetVerificationAction,
  acceptControllingPartyConsentSetDataAction,
  acceptConfidentialityAgreementSetDataAction,
  refreshIdVerificationTokenSetDataAction,
  personalDetailsFromIdVerificationSetDataAction,
  getPersonalDetailsSetDataAction,
  updatePersonalDetailsSetDataAction,
  uploadVerificationDocumentSetDataAction,
  createPasswordSetDataAction,
  acceptInviteSetDataAction,
  deprecatedGetTeamMemberDetailsSetDataAction,
} from './verificationActions';
import {
  // RESET_VERIFICATION,
  ACCEPT_CONTROLLING_PARTY_CONSENT,
  ACCEPT_CONFIDENTIALITY_AGREEMENT,
  REFRESH_ID_VERIFICATION_TOKEN,
  PERSONAL_DETAILS_FROM_ID_VERIFICATION,
  GET_PERSONAL_DETAILS,
  UPDATE_PERSONAL_DETAILS,
  UPLOAD_VERIFICATION_DOCUMENT,
  CREATE_PASSWORD,
  ACCEPT_INVITE,
  DEPRECATED_GET_TEAM_MEMBER_DETAILS,
  GET_PERSONAL_DETAILS_PULLING,
} from './verificationConstants';

import {
  TEAM_MEMBER_BASE_API_URL,
  VERIFICATION_BASE_API_URL,
} from 'config/apiUrls';

const VERIFICATION_URL = `${VERIFICATION_BASE_API_URL}/verification`;
const TEAM_MEMBER_URL = `${TEAM_MEMBER_BASE_API_URL}/team-members`;

export default function* verificationSaga() {
  // add all sagas here
  yield takeLatest(DEPRECATED_GET_TEAM_MEMBER_DETAILS, deprecatedGetTeamMemberDetailsSaga);
  yield takeLatest(ACCEPT_INVITE, acceptInviteSaga);
  yield takeLatest(CREATE_PASSWORD, createPasswordSaga);
  yield takeLatest(UPLOAD_VERIFICATION_DOCUMENT, uploadVerificationDocumentSaga);
  yield takeLatest(UPDATE_PERSONAL_DETAILS, updatePersonalDetailsSaga);
  yield takeLeading(GET_PERSONAL_DETAILS, getPersonalDetailsSaga);
  yield takeLeading(GET_PERSONAL_DETAILS_PULLING, getPersonalDetailsPullingSaga);
  yield takeLeading(PERSONAL_DETAILS_FROM_ID_VERIFICATION, personalDetailsFromIdVerificationSaga);
  yield takeLatest(REFRESH_ID_VERIFICATION_TOKEN, refreshIdVerificationTokenSaga);
  yield takeLatest(ACCEPT_CONFIDENTIALITY_AGREEMENT, acceptConfidentialityAgreementSaga);
  yield takeLatest(ACCEPT_CONTROLLING_PARTY_CONSENT, acceptControllingPartyConsentSaga);
}

export function* acceptControllingPartyConsentSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/accept-controlling-party-consent`;
  const options = {
    method: 'POST',
    body: {},
  };
  try {
    const result = yield call(request, requestUrl, options);
    if (result.ok) cb();
    yield put(acceptControllingPartyConsentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* acceptConfidentialityAgreementSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/accept-confidentiality-agreement`;
  const options = {
    method: 'POST',
    body: {},
  };
  try {
    const result = yield call(request, requestUrl, options);
    if (result.ok) cb();
    yield put(acceptConfidentialityAgreementSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* refreshIdVerificationTokenSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/refresh-id-verification-token`;
  const options = {
    method: 'POST',
    body: {},
  };
  try {
    const result = yield call(request, requestUrl, options);
    if (result.ok) cb();
    yield put(refreshIdVerificationTokenSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* personalDetailsFromIdVerificationSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/personal-details-from-id-verification`;
  try {
    let result = yield call(request, requestUrl);
    // if (!result?.data?.entity_person_identification_type) {
    //   yield delay(5000);
    //   result = yield call(request, requestUrl);
    //   if (!result?.data?.entity_person_identification_type) {
    //     yield delay(5000);
    //     result = yield call(request, requestUrl);
    //   }
    // }
    if (result.ok) cb();
    yield put(personalDetailsFromIdVerificationSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getPersonalDetailsSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/personal-details`;
  try {
    const result = yield call(request, requestUrl);
    if (result.ok) cb(result);
    yield put(getPersonalDetailsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getPersonalDetailsPullingSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/personal-details`;
  try {
    const result = yield call(request, requestUrl);
    if (result.ok) cb(result);
  } catch (error) {
    console.error(error);
  }
}

export function* updatePersonalDetailsSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/personal-details`;
  const options = {
    method: 'PUT',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    if (result.ok) cb();
    yield put(updatePersonalDetailsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* uploadVerificationDocumentSaga(args = {}) {
  const { formData, cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/verification-document-upload`;
  const options = {
    method: 'POST', formdata: formData, headers: {
      'Content-type': "multipart/form-data; boundary=----WebKitFormBoundarytJGA9aZWTfLbA6oP"
    }
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(uploadVerificationDocumentSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* createPasswordSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/create-password`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(createPasswordSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* acceptInviteSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${VERIFICATION_URL}/accept-invite`;
  try {
    const result = yield call(request, requestUrl);
    yield put(acceptInviteSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* deprecatedGetTeamMemberDetailsSaga(args = {}) {
  const { personId, token } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/public/continue-verification?id=${personId}&token=${token}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(deprecatedGetTeamMemberDetailsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
