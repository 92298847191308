import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
// import useCommonStyles from 'styles';
import OTPInput, { /*ResendOTP */} from "otp-input-react";


const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // justifyContent: "center",
  },
  inputClassName: {
    textAlign: "center",
    // marginRight: "20px",
    marginRight: theme.spacing(2.5),
    // width: `${theme.spacing(4.875)} !important`,
    // height: `${theme.spacing(6)} !important`,
    width: "39px !important",
    height: "48px !important",
    background: "#F2F3F4",
    borderRadius: "6px",
    flex: "none",
    order: 0,
    alignSelf: "stretch",
    flexGrow: 0,
    border: "none",
    fontWeight: 600,
    fontSize: "16px",
  },
}));

function OtpInput(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { /*type,*/ id, name, value, onChange } = props;

  const handlerOnchange = (v) => {
    onChange({ target: { id, name, value:v}})
  };

  return (<>
    <OTPInput
      value={value}
      onChange={handlerOnchange}
      OTPLength={6}
      inputClassName={classes.inputClassName}
      className={classes.root}
      // otpType={type}
      otpType="number"
      autoFocus
      // secure
      />
    {/* <ResendOTP onResendClick={() => console.info("Resend clicked")} /> */}
  </>);
}

OtpInput.defaultProps = {
  test: " OtpInput component ",
};

OtpInput.propTypes = {
  test: PropTypes.string,
};

export default memo(OtpInput);
