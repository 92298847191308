
import { defineMessages } from 'react-intl';

export const scope = 'src.components.CardLock';

export default defineMessages({
  lockCard: {
    id: `${scope}.CardLock`,
    defaultMessage: 'Lock SmartSpend Account',
  },
  desTxt: {
    id: `${scope}.desTxt`,
    defaultMessage: 'Currently all transactions are allowed',
  },
  unLockCard: {
    id: `${scope}.unLockCard`,
    defaultMessage: 'Account is Locked',
  },
  unDesTxt: {
    id: `${scope}.unDesTxt`,
    defaultMessage: 'Unlock to enable transactions',
  }
});
