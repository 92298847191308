import React from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    btn: props => ({
        border: '2px solid #1E1E1E',
        borderRadius: '50%',
        width: theme.spacing(5),
        height: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: props.isLoading ? '' : 'pointer',
        margin: theme.spacing(0, 2, 0, 0),
        opacity: props.isLoading ? '0.3' : '1'
    }),
}))

function GoBack(props) {
    const { isLoading, onClick } = props
    const classes = useStyles({ isLoading: isLoading })
    return (
        <>
            <div className={classes.btn} onClick={isLoading ? null : () => onClick()}>
                <NavigateBeforeIcon />
            </div>
        </>
    )
}

export default GoBack
