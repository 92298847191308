
import { defineMessages } from 'react-intl';

export const scope = 'src.components.checkin_acc_open_info';

export default defineMessages({
    txt1: {
        id: `${scope}.txt1`,
        defaultMessage: 'Open a USD Checking or Multi-Currency Account',
    },
    txt2: {
        id: `${scope}.txt2`,
        defaultMessage: ' - USD Checking Account',
    },
    txt3: {
        id: `${scope}.txt3`,
        defaultMessage: '- Multi-ccy Account',
    },
    txt4: {
        id: `${scope}.txt4`,
        defaultMessage: '- Handle multiple accounts with ease',
    },
    txt5: {
        id: `${scope}.txt5`,
        defaultMessage: '- Hold accounts and receive payments in 32 different currencies, making it simple to transfer and convert.',

    },
    detail: {
        id: `${scope}.detail`,
        defaultMessage: 'The Details',

    },
    detailTxt1: {
        id: `${scope}.detailTxt1`,
        defaultMessage: 'USD accounts are automatically converted into FDIC insured checking accounts',

    },
    detailTxt2: {
        id: `${scope}.detailTxt2`,
        defaultMessage: 'Create unlimited currency accounts to hold and receive money in any of our 32 accepted currencies.',

    },
    detailTxt3: {
        id: `${scope}.detailTxt3`,
        defaultMessage: 'Hold funds in the currency you receive in any account via SWIFT, or choose to convert between currencies as you need.',

    }
   
   
    
});
