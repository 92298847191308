import { Divider, makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5)
    },
    actionComponent: {
        marginRight: theme.spacing(-2.25),
    },
    row: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
    }
}));

export function SectionHeading(props = {}) {
    const { onClickHeader, collapseState } = props;
    const classes = useStyles();
    const { heading, actionComponent, tooltip } = props;

    return (<>
        <div className={classes.root}>
            <div onClick={onClickHeader || null} className={classes.row} >
                <Typography color={props.color || 'grey700'} variant={props.variant || "titleMobile"} >{heading}</Typography>
                {onClickHeader && <>{!collapseState ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</>}
                {
                    props.tooltip ? <> {tooltip} </> : <></>
                }
            </div>

            {
                actionComponent ?
                    <>{actionComponent}</> : <></>
            }
        </div>
        <Divider />
    </>)
}
SectionHeading.defaultProps = {
    heading: "",
    actionComponent: null
}

SectionHeading.propTypes = {
    heading: PropTypes.any,
    actionComponent: PropTypes.element
};

export default SectionHeading;
