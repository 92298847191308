const flagBaseUrl = 'https://all-country-flags.s3.amazonaws.com/1x1/';
const fileType = 'svg';
// const flags = {
//     "AD": `${flagBaseUrl}ad.${fileType}`,
//     "AE": `${flagBaseUrl}ae.${fileType}`,
//     "AF": `${flagBaseUrl}af.${fileType}`,
//     "AG": `${flagBaseUrl}ag.${fileType}`,
//     "AI": `${flagBaseUrl}ai.${fileType}`,
//     "AL": `${flagBaseUrl}al.${fileType}`,
//     "AM": `${flagBaseUrl}am.${fileType}`,
//     "AO": `${flagBaseUrl}ao.${fileType}`,
//     "AQ": `${flagBaseUrl}aq.${fileType}`,
//     "AR": `${flagBaseUrl}ar.${fileType}`,
//     "AS": `${flagBaseUrl}as.${fileType}`,
//     "AT": `${flagBaseUrl}at.${fileType}`,
//     "AU": `${flagBaseUrl}au.${fileType}`,
//     "AW": `${flagBaseUrl}aw.${fileType}`,
//     "AX": `${flagBaseUrl}ax.${fileType}`,
//     "AZ": `${flagBaseUrl}az.${fileType}`,
//     "BA": `${flagBaseUrl}ba.${fileType}`,
//     "BB": `${flagBaseUrl}bb.${fileType}`,
//     "BD": `${flagBaseUrl}bd.${fileType}`,
//     "BE": `${flagBaseUrl}be.${fileType}`,
//     "BF": `${flagBaseUrl}bf.${fileType}`,
//     "BG": `${flagBaseUrl}bg.${fileType}`,
//     "BH": `${flagBaseUrl}bh.${fileType}`,
//     "BI": `${flagBaseUrl}bi.${fileType}`,
//     "BJ": `${flagBaseUrl}bj.${fileType}`,
//     "BL": `${flagBaseUrl}bl.${fileType}`,
//     "BM": `${flagBaseUrl}bm.${fileType}`,
//     "BN": `${flagBaseUrl}bn.${fileType}`,
//     "BO": `${flagBaseUrl}bo.${fileType}`,
//     "BQ": `${flagBaseUrl}bq.${fileType}`,
//     "BR": `${flagBaseUrl}br.${fileType}`,
//     "BS": `${flagBaseUrl}bs.${fileType}`,
//     "BT": `${flagBaseUrl}bt.${fileType}`,
//     "BV": `${flagBaseUrl}bv.${fileType}`,
//     "BW": `${flagBaseUrl}bw.${fileType}`,
//     "BY": `${flagBaseUrl}by.${fileType}`,
//     "BZ": `${flagBaseUrl}bz.${fileType}`,
//     "CA": `${flagBaseUrl}ca.${fileType}`,
//     "CC": `${flagBaseUrl}cc.${fileType}`,
//     "CD": `${flagBaseUrl}cd.${fileType}`,
//     "CF": `${flagBaseUrl}cf.${fileType}`,
//     "CG": `${flagBaseUrl}cg.${fileType}`,
//     "CH": `${flagBaseUrl}ch.${fileType}`,
//     "CI": `${flagBaseUrl}ci.${fileType}`,
//     "CK": `${flagBaseUrl}ck.${fileType}`,
//     "CL": `${flagBaseUrl}cl.${fileType}`,
//     "CM": `${flagBaseUrl}cm.${fileType}`,
//     "CN": `${flagBaseUrl}cn.${fileType}`,
//     "CO": `${flagBaseUrl}co.${fileType}`,
//     "CR": `${flagBaseUrl}cr.${fileType}`,
//     "CU": `${flagBaseUrl}cu.${fileType}`,
//     "CV": `${flagBaseUrl}cv.${fileType}`,
//     "CW": `${flagBaseUrl}cw.${fileType}`,
//     "CX": `${flagBaseUrl}cx.${fileType}`,
//     "CY": `${flagBaseUrl}cy.${fileType}`,
//     "CZ": `${flagBaseUrl}cz.${fileType}`,
//     "DE": `${flagBaseUrl}de.${fileType}`,
//     "DJ": `${flagBaseUrl}dj.${fileType}`,
//     "DK": `${flagBaseUrl}dk.${fileType}`,
//     "DM": `${flagBaseUrl}dm.${fileType}`,
//     "DO": `${flagBaseUrl}do.${fileType}`,
//     "DZ": `${flagBaseUrl}dz.${fileType}`,
//     "EC": `${flagBaseUrl}ec.${fileType}`,
//     "EE": `${flagBaseUrl}ee.${fileType}`,
//     "EG": `${flagBaseUrl}eg.${fileType}`,
//     "EH": `${flagBaseUrl}eh.${fileType}`,
//     "ER": `${flagBaseUrl}er.${fileType}`,
//     "ES": `${flagBaseUrl}es.${fileType}`,
//     "ET": `${flagBaseUrl}et.${fileType}`,
//     "FI": `${flagBaseUrl}fi.${fileType}`,
//     "FJ": `${flagBaseUrl}fj.${fileType}`,
//     "FK": `${flagBaseUrl}fk.${fileType}`,
//     "FM": `${flagBaseUrl}fm.${fileType}`,
//     "FO": `${flagBaseUrl}fo.${fileType}`,
//     "FR": `${flagBaseUrl}fr.${fileType}`,
//     "GA": `${flagBaseUrl}ga.${fileType}`,
//     "GB": `${flagBaseUrl}gb.${fileType}`,
//     "GD": `${flagBaseUrl}gd.${fileType}`,
//     "GE": `${flagBaseUrl}ge.${fileType}`,
//     "GF": `${flagBaseUrl}gf.${fileType}`,
//     "GG": `${flagBaseUrl}gg.${fileType}`,
//     "GH": `${flagBaseUrl}gh.${fileType}`,
//     "GI": `${flagBaseUrl}gi.${fileType}`,
//     "GL": `${flagBaseUrl}gl.${fileType}`,
//     "GM": `${flagBaseUrl}gm.${fileType}`,
//     "GN": `${flagBaseUrl}gn.${fileType}`,
//     "GP": `${flagBaseUrl}gp.${fileType}`,
//     "GQ": `${flagBaseUrl}gq.${fileType}`,
//     "GR": `${flagBaseUrl}gr.${fileType}`,
//     "GS": `${flagBaseUrl}gs.${fileType}`,
//     "GT": `${flagBaseUrl}gt.${fileType}`,
//     "GU": `${flagBaseUrl}gu.${fileType}`,
//     "GW": `${flagBaseUrl}gw.${fileType}`,
//     "GY": `${flagBaseUrl}gy.${fileType}`,
//     "HK": `${flagBaseUrl}hk.${fileType}`,
//     "HM": `${flagBaseUrl}hm.${fileType}`,
//     "HN": `${flagBaseUrl}hn.${fileType}`,
//     "HR": `${flagBaseUrl}hr.${fileType}`,
//     "HT": `${flagBaseUrl}ht.${fileType}`,
//     "HU": `${flagBaseUrl}hu.${fileType}`,
//     "ID": `${flagBaseUrl}id.${fileType}`,
//     "IE": `${flagBaseUrl}ie.${fileType}`,
//     "IL": `${flagBaseUrl}il.${fileType}`,
//     "IM": `${flagBaseUrl}im.${fileType}`,
//     "IN": `${flagBaseUrl}in.${fileType}`,
//     "IO": `${flagBaseUrl}io.${fileType}`,
//     "IQ": `${flagBaseUrl}iq.${fileType}`,
//     "IR": `${flagBaseUrl}ir.${fileType}`,
//     "IS": `${flagBaseUrl}is.${fileType}`,
//     "IT": `${flagBaseUrl}it.${fileType}`,
//     "JE": `${flagBaseUrl}je.${fileType}`,
//     "JM": `${flagBaseUrl}jm.${fileType}`,
//     "JO": `${flagBaseUrl}jo.${fileType}`,
//     "JP": `${flagBaseUrl}jp.${fileType}`,
//     "KE": `${flagBaseUrl}ke.${fileType}`,
//     "KG": `${flagBaseUrl}kg.${fileType}`,
//     "KH": `${flagBaseUrl}kh.${fileType}`,
//     "KI": `${flagBaseUrl}ki.${fileType}`,
//     "KM": `${flagBaseUrl}km.${fileType}`,
//     "KN": `${flagBaseUrl}kn.${fileType}`,
//     "KP": `${flagBaseUrl}kp.${fileType}`,
//     "KR": `${flagBaseUrl}kr.${fileType}`,
//     "KW": `${flagBaseUrl}kw.${fileType}`,
//     "KY": `${flagBaseUrl}ky.${fileType}`,
//     "KZ": `${flagBaseUrl}kz.${fileType}`,
//     "LA": `${flagBaseUrl}la.${fileType}`,
//     "LB": `${flagBaseUrl}lb.${fileType}`,
//     "LC": `${flagBaseUrl}lc.${fileType}`,
//     "LI": `${flagBaseUrl}li.${fileType}`,
//     "LK": `${flagBaseUrl}lk.${fileType}`,
//     "LR": `${flagBaseUrl}lr.${fileType}`,
//     "LS": `${flagBaseUrl}ls.${fileType}`,
//     "LT": `${flagBaseUrl}lt.${fileType}`,
//     "LU": `${flagBaseUrl}lu.${fileType}`,
//     "LV": `${flagBaseUrl}lv.${fileType}`,
//     "LY": `${flagBaseUrl}ly.${fileType}`,
//     "MA": `${flagBaseUrl}ma.${fileType}`,
//     "MC": `${flagBaseUrl}mc.${fileType}`,
//     "MD": `${flagBaseUrl}md.${fileType}`,
//     "ME": `${flagBaseUrl}me.${fileType}`,
//     "MF": `${flagBaseUrl}mf.${fileType}`,
//     "MG": `${flagBaseUrl}mg.${fileType}`,
//     "MH": `${flagBaseUrl}mh.${fileType}`,
//     "MK": `${flagBaseUrl}mk.${fileType}`,
//     "ML": `${flagBaseUrl}ml.${fileType}`,
//     "MM": `${flagBaseUrl}mm.${fileType}`,
//     "MN": `${flagBaseUrl}mn.${fileType}`,
//     "MO": `${flagBaseUrl}mo.${fileType}`,
//     "MP": `${flagBaseUrl}mp.${fileType}`,
//     "MQ": `${flagBaseUrl}mq.${fileType}`,
//     "MR": `${flagBaseUrl}mr.${fileType}`,
//     "MS": `${flagBaseUrl}ms.${fileType}`,
//     "MT": `${flagBaseUrl}mt.${fileType}`,
//     "MU": `${flagBaseUrl}mu.${fileType}`,
//     "MV": `${flagBaseUrl}mv.${fileType}`,
//     "MW": `${flagBaseUrl}mw.${fileType}`,
//     "MX": `${flagBaseUrl}mx.${fileType}`,
//     "MY": `${flagBaseUrl}my.${fileType}`,
//     "MZ": `${flagBaseUrl}mz.${fileType}`,
//     "NA": `${flagBaseUrl}na.${fileType}`,
//     "NC": `${flagBaseUrl}nc.${fileType}`,
//     "NE": `${flagBaseUrl}ne.${fileType}`,
//     "NF": `${flagBaseUrl}nf.${fileType}`,
//     "NG": `${flagBaseUrl}ng.${fileType}`,
//     "NI": `${flagBaseUrl}ni.${fileType}`,
//     "NL": `${flagBaseUrl}nl.${fileType}`,
//     "NO": `${flagBaseUrl}no.${fileType}`,
//     "NP": `${flagBaseUrl}np.${fileType}`,
//     "NR": `${flagBaseUrl}nr.${fileType}`,
//     "NU": `${flagBaseUrl}nu.${fileType}`,
//     "NZ": `${flagBaseUrl}nz.${fileType}`,
//     "OM": `${flagBaseUrl}om.${fileType}`,
//     "PA": `${flagBaseUrl}pa.${fileType}`,
//     "PE": `${flagBaseUrl}pe.${fileType}`,
//     "PF": `${flagBaseUrl}pf.${fileType}`,
//     "PG": `${flagBaseUrl}pg.${fileType}`,
//     "PH": `${flagBaseUrl}ph.${fileType}`,
//     "PK": `${flagBaseUrl}pk.${fileType}`,
//     "PL": `${flagBaseUrl}pl.${fileType}`,
//     "PM": `${flagBaseUrl}pm.${fileType}`,
//     "PN": `${flagBaseUrl}pn.${fileType}`,
//     "PR": `${flagBaseUrl}pr.${fileType}`,
//     "PS": `${flagBaseUrl}ps.${fileType}`,
//     "PT": `${flagBaseUrl}pt.${fileType}`,
//     "PW": `${flagBaseUrl}pw.${fileType}`,
//     "PY": `${flagBaseUrl}py.${fileType}`,
//     "QA": `${flagBaseUrl}qa.${fileType}`,
//     "RE": `${flagBaseUrl}re.${fileType}`,
//     "RO": `${flagBaseUrl}ro.${fileType}`,
//     "RS": `${flagBaseUrl}rs.${fileType}`,
//     "RU": `${flagBaseUrl}ru.${fileType}`,
//     "RW": `${flagBaseUrl}rw.${fileType}`,
//     "SA": `${flagBaseUrl}sa.${fileType}`,
//     "SB": `${flagBaseUrl}sb.${fileType}`,
//     "SC": `${flagBaseUrl}sc.${fileType}`,
//     "SD": `${flagBaseUrl}sd.${fileType}`,
//     "SE": `${flagBaseUrl}se.${fileType}`,
//     "SG": `${flagBaseUrl}sg.${fileType}`,
//     "SH": `${flagBaseUrl}sh.${fileType}`,
//     "SI": `${flagBaseUrl}si.${fileType}`,
//     "SJ": `${flagBaseUrl}sj.${fileType}`,
//     "SK": `${flagBaseUrl}sk.${fileType}`,
//     "SL": `${flagBaseUrl}sl.${fileType}`,
//     "SM": `${flagBaseUrl}sm.${fileType}`,
//     "SN": `${flagBaseUrl}sn.${fileType}`,
//     "SO": `${flagBaseUrl}so.${fileType}`,
//     "SR": `${flagBaseUrl}sr.${fileType}`,
//     "SS": `${flagBaseUrl}ss.${fileType}`,
//     "ST": `${flagBaseUrl}st.${fileType}`,
//     "SV": `${flagBaseUrl}sv.${fileType}`,
//     "SX": `${flagBaseUrl}sx.${fileType}`,
//     "SY": `${flagBaseUrl}sy.${fileType}`,
//     "SZ": `${flagBaseUrl}sz.${fileType}`,
//     "TC": `${flagBaseUrl}tc.${fileType}`,
//     "TD": `${flagBaseUrl}td.${fileType}`,
//     "TF": `${flagBaseUrl}tf.${fileType}`,
//     "TG": `${flagBaseUrl}tg.${fileType}`,
//     "TH": `${flagBaseUrl}th.${fileType}`,
//     "TJ": `${flagBaseUrl}tj.${fileType}`,
//     "TK": `${flagBaseUrl}tk.${fileType}`,
//     "TL": `${flagBaseUrl}tl.${fileType}`,
//     "TM": `${flagBaseUrl}tm.${fileType}`,
//     "TN": `${flagBaseUrl}tn.${fileType}`,
//     "TO": `${flagBaseUrl}to.${fileType}`,
//     "TR": `${flagBaseUrl}tr.${fileType}`,
//     "TT": `${flagBaseUrl}tt.${fileType}`,
//     "TV": `${flagBaseUrl}tv.${fileType}`,
//     "TW": `${flagBaseUrl}tw.${fileType}`,
//     "TZ": `${flagBaseUrl}tz.${fileType}`,
//     "UA": `${flagBaseUrl}ua.${fileType}`,
//     "UG": `${flagBaseUrl}ug.${fileType}`,
//     "UM": `${flagBaseUrl}um.${fileType}`,
//     "US": `${flagBaseUrl}us.${fileType}`,
//     "UY": `${flagBaseUrl}uy.${fileType}`,
//     "UZ": `${flagBaseUrl}uz.${fileType}`,
//     "VA": `${flagBaseUrl}va.${fileType}`,
//     "VC": `${flagBaseUrl}vc.${fileType}`,
//     "VE": `${flagBaseUrl}ve.${fileType}`,
//     "VG": `${flagBaseUrl}vg.${fileType}`,
//     "VI": `${flagBaseUrl}vi.${fileType}`,
//     "VN": `${flagBaseUrl}vn.${fileType}`,
//     "VU": `${flagBaseUrl}vu.${fileType}`,
//     "WF": `${flagBaseUrl}wf.${fileType}`,
//     "WS": `${flagBaseUrl}ws.${fileType}`,
//     "XK": `${flagBaseUrl}xk.${fileType}`,
//     "YE": `${flagBaseUrl}ye.${fileType}`,
//     "YT": `${flagBaseUrl}yt.${fileType}`,
//     "ZA": `${flagBaseUrl}za.${fileType}`,
//     "ZM": `${flagBaseUrl}zm.${fileType}`,
//     "ZW": `${flagBaseUrl}zw.${fileType}`,
// };

// const flags = {
//     "AD": "https://restcountries.eu/data/and.svg",
//     "AE": "https://restcountries.eu/data/are.svg",
//     "AF": "https://restcountries.eu/data/afg.svg",
//     "AG": "https://restcountries.eu/data/atg.svg",
//     "AI": "https://restcountries.eu/data/aia.svg",
//     "AL": "https://restcountries.eu/data/alb.svg",
//     "AM": "https://restcountries.eu/data/arm.svg",
//     "AO": "https://restcountries.eu/data/ago.svg",
//     "AQ": "https://restcountries.eu/data/ata.svg",
//     "AR": "https://restcountries.eu/data/arg.svg",
//     "AS": "https://restcountries.eu/data/asm.svg",
//     "AT": "https://restcountries.eu/data/aut.svg",
//     "AU": "https://restcountries.eu/data/aus.svg",
//     "AW": "https://restcountries.eu/data/abw.svg",
//     "AX": "https://restcountries.eu/data/ala.svg",
//     "AZ": "https://restcountries.eu/data/aze.svg",
//     "BA": "https://restcountries.eu/data/bih.svg",
//     "BB": "https://restcountries.eu/data/brb.svg",
//     "BD": "https://restcountries.eu/data/bgd.svg",
//     "BE": "https://restcountries.eu/data/bel.svg",
//     "BF": "https://restcountries.eu/data/bfa.svg",
//     "BG": "https://restcountries.eu/data/bgr.svg",
//     "BH": "https://restcountries.eu/data/bhr.svg",
//     "BI": "https://restcountries.eu/data/bdi.svg",
//     "BJ": "https://restcountries.eu/data/ben.svg",
//     "BL": "https://restcountries.eu/data/blm.svg",
//     "BM": "https://restcountries.eu/data/bmu.svg",
//     "BN": "https://restcountries.eu/data/brn.svg",
//     "BO": "https://restcountries.eu/data/bol.svg",
//     "BQ": "https://restcountries.eu/data/bes.svg",
//     "BR": "https://restcountries.eu/data/bra.svg",
//     "BS": "https://restcountries.eu/data/bhs.svg",
//     "BT": "https://restcountries.eu/data/btn.svg",
//     "BV": "https://restcountries.eu/data/bvt.svg",
//     "BW": "https://restcountries.eu/data/bwa.svg",
//     "BY": "https://restcountries.eu/data/blr.svg",
//     "BZ": "https://restcountries.eu/data/blz.svg",
//     "CA": "https://restcountries.eu/data/can.svg",
//     "CC": "https://restcountries.eu/data/cck.svg",
//     "CD": "https://restcountries.eu/data/cod.svg",
//     "CF": "https://restcountries.eu/data/caf.svg",
//     "CG": "https://restcountries.eu/data/cog.svg",
//     "CH": "https://restcountries.eu/data/che.svg",
//     "CI": "https://restcountries.eu/data/civ.svg",
//     "CK": "https://restcountries.eu/data/cok.svg",
//     "CL": "https://restcountries.eu/data/chl.svg",
//     "CM": "https://restcountries.eu/data/cmr.svg",
//     "CN": "https://restcountries.eu/data/chn.svg",
//     "CO": "https://restcountries.eu/data/col.svg",
//     "CR": "https://restcountries.eu/data/cri.svg",
//     "CU": "https://restcountries.eu/data/cub.svg",
//     "CV": "https://restcountries.eu/data/cpv.svg",
//     "CW": "https://restcountries.eu/data/cuw.svg",
//     "CX": "https://restcountries.eu/data/cxr.svg",
//     "CY": "https://restcountries.eu/data/cyp.svg",
//     "CZ": "https://restcountries.eu/data/cze.svg",
//     "DE": "https://restcountries.eu/data/deu.svg",
//     "DJ": "https://restcountries.eu/data/dji.svg",
//     "DK": "https://restcountries.eu/data/dnk.svg",
//     "DM": "https://restcountries.eu/data/dma.svg",
//     "DO": "https://restcountries.eu/data/dom.svg",
//     "DZ": "https://restcountries.eu/data/dza.svg",
//     "EC": "https://restcountries.eu/data/ecu.svg",
//     "EE": "https://restcountries.eu/data/est.svg",
//     "EG": "https://restcountries.eu/data/egy.svg",
//     "EH": "https://restcountries.eu/data/esh.svg",
//     "ER": "https://restcountries.eu/data/eri.svg",
//     "ES": "https://restcountries.eu/data/esp.svg",
//     "ET": "https://restcountries.eu/data/eth.svg",
//     "FI": "https://restcountries.eu/data/fin.svg",
//     "FJ": "https://restcountries.eu/data/fji.svg",
//     "FK": "https://restcountries.eu/data/flk.svg",
//     "FM": "https://restcountries.eu/data/fsm.svg",
//     "FO": "https://restcountries.eu/data/fro.svg",
//     "FR": "https://restcountries.eu/data/fra.svg",
//     "GA": "https://restcountries.eu/data/gab.svg",
//     "GB": "https://restcountries.eu/data/gbr.svg",
//     "GD": "https://restcountries.eu/data/grd.svg",
//     "GE": "https://restcountries.eu/data/geo.svg",
//     "GF": "https://restcountries.eu/data/guf.svg",
//     "GG": "https://restcountries.eu/data/ggy.svg",
//     "GH": "https://restcountries.eu/data/gha.svg",
//     "GI": "https://restcountries.eu/data/gib.svg",
//     "GL": "https://restcountries.eu/data/grl.svg",
//     "GM": "https://restcountries.eu/data/gmb.svg",
//     "GN": "https://restcountries.eu/data/gin.svg",
//     "GP": "https://restcountries.eu/data/glp.svg",
//     "GQ": "https://restcountries.eu/data/gnq.svg",
//     "GR": "https://restcountries.eu/data/grc.svg",
//     "GS": "https://restcountries.eu/data/sgs.svg",
//     "GT": "https://restcountries.eu/data/gtm.svg",
//     "GU": "https://restcountries.eu/data/gum.svg",
//     "GW": "https://restcountries.eu/data/gnb.svg",
//     "GY": "https://restcountries.eu/data/guy.svg",
//     "HK": "https://restcountries.eu/data/hkg.svg",
//     "HM": "https://restcountries.eu/data/hmd.svg",
//     "HN": "https://restcountries.eu/data/hnd.svg",
//     "HR": "https://restcountries.eu/data/hrv.svg",
//     "HT": "https://restcountries.eu/data/hti.svg",
//     "HU": "https://restcountries.eu/data/hun.svg",
//     "ID": "https://restcountries.eu/data/idn.svg",
//     "IE": "https://restcountries.eu/data/irl.svg",
//     "IL": "https://restcountries.eu/data/isr.svg",
//     "IM": "https://restcountries.eu/data/imn.svg",
//     "IN": "https://restcountries.eu/data/ind.svg",
//     "IO": "https://restcountries.eu/data/iot.svg",
//     "IQ": "https://restcountries.eu/data/irq.svg",
//     "IR": "https://restcountries.eu/data/irn.svg",
//     "IS": "https://restcountries.eu/data/isl.svg",
//     "IT": "https://restcountries.eu/data/ita.svg",
//     "JE": "https://restcountries.eu/data/jey.svg",
//     "JM": "https://restcountries.eu/data/jam.svg",
//     "JO": "https://restcountries.eu/data/jor.svg",
//     "JP": "https://restcountries.eu/data/jpn.svg",
//     "KE": "https://restcountries.eu/data/ken.svg",
//     "KG": "https://restcountries.eu/data/kgz.svg",
//     "KH": "https://restcountries.eu/data/khm.svg",
//     "KI": "https://restcountries.eu/data/kir.svg",
//     "KM": "https://restcountries.eu/data/com.svg",
//     "KN": "https://restcountries.eu/data/kna.svg",
//     "KP": "https://restcountries.eu/data/prk.svg",
//     "KR": "https://restcountries.eu/data/kor.svg",
//     "KW": "https://restcountries.eu/data/kwt.svg",
//     "KY": "https://restcountries.eu/data/cym.svg",
//     "KZ": "https://restcountries.eu/data/kaz.svg",
//     "LA": "https://restcountries.eu/data/lao.svg",
//     "LB": "https://restcountries.eu/data/lbn.svg",
//     "LC": "https://restcountries.eu/data/lca.svg",
//     "LI": "https://restcountries.eu/data/lie.svg",
//     "LK": "https://restcountries.eu/data/lka.svg",
//     "LR": "https://restcountries.eu/data/lbr.svg",
//     "LS": "https://restcountries.eu/data/lso.svg",
//     "LT": "https://restcountries.eu/data/ltu.svg",
//     "LU": "https://restcountries.eu/data/lux.svg",
//     "LV": "https://restcountries.eu/data/lva.svg",
//     "LY": "https://restcountries.eu/data/lby.svg",
//     "MA": "https://restcountries.eu/data/mar.svg",
//     "MC": "https://restcountries.eu/data/mco.svg",
//     "MD": "https://restcountries.eu/data/mda.svg",
//     "ME": "https://restcountries.eu/data/mne.svg",
//     "MF": "https://restcountries.eu/data/maf.svg",
//     "MG": "https://restcountries.eu/data/mdg.svg",
//     "MH": "https://restcountries.eu/data/mhl.svg",
//     "MK": "https://restcountries.eu/data/mkd.svg",
//     "ML": "https://restcountries.eu/data/mli.svg",
//     "MM": "https://restcountries.eu/data/mmr.svg",
//     "MN": "https://restcountries.eu/data/mng.svg",
//     "MO": "https://restcountries.eu/data/mac.svg",
//     "MP": "https://restcountries.eu/data/mnp.svg",
//     "MQ": "https://restcountries.eu/data/mtq.svg",
//     "MR": "https://restcountries.eu/data/mrt.svg",
//     "MS": "https://restcountries.eu/data/msr.svg",
//     "MT": "https://restcountries.eu/data/mlt.svg",
//     "MU": "https://restcountries.eu/data/mus.svg",
//     "MV": "https://restcountries.eu/data/mdv.svg",
//     "MW": "https://restcountries.eu/data/mwi.svg",
//     "MX": "https://restcountries.eu/data/mex.svg",
//     "MY": "https://restcountries.eu/data/mys.svg",
//     "MZ": "https://restcountries.eu/data/moz.svg",
//     "NA": "https://restcountries.eu/data/nam.svg",
//     "NC": "https://restcountries.eu/data/ncl.svg",
//     "NE": "https://restcountries.eu/data/ner.svg",
//     "NF": "https://restcountries.eu/data/nfk.svg",
//     "NG": "https://restcountries.eu/data/nga.svg",
//     "NI": "https://restcountries.eu/data/nic.svg",
//     "NL": "https://restcountries.eu/data/nld.svg",
//     "NO": "https://restcountries.eu/data/nor.svg",
//     "NP": "https://restcountries.eu/data/npl.svg",
//     "NR": "https://restcountries.eu/data/nru.svg",
//     "NU": "https://restcountries.eu/data/niu.svg",
//     "NZ": "https://restcountries.eu/data/nzl.svg",
//     "OM": "https://restcountries.eu/data/omn.svg",
//     "PA": "https://restcountries.eu/data/pan.svg",
//     "PE": "https://restcountries.eu/data/per.svg",
//     "PF": "https://restcountries.eu/data/pyf.svg",
//     "PG": "https://restcountries.eu/data/png.svg",
//     "PH": "https://restcountries.eu/data/phl.svg",
//     "PK": "https://restcountries.eu/data/pak.svg",
//     "PL": "https://restcountries.eu/data/pol.svg",
//     "PM": "https://restcountries.eu/data/spm.svg",
//     "PN": "https://restcountries.eu/data/pcn.svg",
//     "PR": "https://restcountries.eu/data/pri.svg",
//     "PS": "https://restcountries.eu/data/pse.svg",
//     "PT": "https://restcountries.eu/data/prt.svg",
//     "PW": "https://restcountries.eu/data/plw.svg",
//     "PY": "https://restcountries.eu/data/pry.svg",
//     "QA": "https://restcountries.eu/data/qat.svg",
//     "RE": "https://restcountries.eu/data/reu.svg",
//     "RO": "https://restcountries.eu/data/rou.svg",
//     "RS": "https://restcountries.eu/data/srb.svg",
//     "RU": "https://restcountries.eu/data/rus.svg",
//     "RW": "https://restcountries.eu/data/rwa.svg",
//     "SA": "https://restcountries.eu/data/sau.svg",
//     "SB": "https://restcountries.eu/data/slb.svg",
//     "SC": "https://restcountries.eu/data/syc.svg",
//     "SD": "https://restcountries.eu/data/sdn.svg",
//     "SE": "https://restcountries.eu/data/swe.svg",
//     "SG": "https://restcountries.eu/data/sgp.svg",
//     "SH": "https://restcountries.eu/data/shn.svg",
//     "SI": "https://restcountries.eu/data/svn.svg",
//     "SJ": "https://restcountries.eu/data/sjm.svg",
//     "SK": "https://restcountries.eu/data/svk.svg",
//     "SL": "https://restcountries.eu/data/sle.svg",
//     "SM": "https://restcountries.eu/data/smr.svg",
//     "SN": "https://restcountries.eu/data/sen.svg",
//     "SO": "https://restcountries.eu/data/som.svg",
//     "SR": "https://restcountries.eu/data/sur.svg",
//     "SS": "https://restcountries.eu/data/ssd.svg",
//     "ST": "https://restcountries.eu/data/stp.svg",
//     "SV": "https://restcountries.eu/data/slv.svg",
//     "SX": "https://restcountries.eu/data/sxm.svg",
//     "SY": "https://restcountries.eu/data/syr.svg",
//     "SZ": "https://restcountries.eu/data/swz.svg",
//     "TC": "https://restcountries.eu/data/tca.svg",
//     "TD": "https://restcountries.eu/data/tcd.svg",
//     "TF": "https://restcountries.eu/data/atf.svg",
//     "TG": "https://restcountries.eu/data/tgo.svg",
//     "TH": "https://restcountries.eu/data/tha.svg",
//     "TJ": "https://restcountries.eu/data/tjk.svg",
//     "TK": "https://restcountries.eu/data/tkl.svg",
//     "TL": "https://restcountries.eu/data/tls.svg",
//     "TM": "https://restcountries.eu/data/tkm.svg",
//     "TN": "https://restcountries.eu/data/tun.svg",
//     "TO": "https://restcountries.eu/data/ton.svg",
//     "TR": "https://restcountries.eu/data/tur.svg",
//     "TT": "https://restcountries.eu/data/tto.svg",
//     "TV": "https://restcountries.eu/data/tuv.svg",
//     "TW": "https://restcountries.eu/data/twn.svg",
//     "TZ": "https://restcountries.eu/data/tza.svg",
//     "UA": "https://restcountries.eu/data/ukr.svg",
//     "UG": "https://restcountries.eu/data/uga.svg",
//     "UM": "https://restcountries.eu/data/umi.svg",
//     "US": "https://restcountries.eu/data/usa.svg",
//     "UY": "https://restcountries.eu/data/ury.svg",
//     "UZ": "https://restcountries.eu/data/uzb.svg",
//     "VA": "https://restcountries.eu/data/vat.svg",
//     "VC": "https://restcountries.eu/data/vct.svg",
//     "VE": "https://restcountries.eu/data/ven.svg",
//     "VG": "https://restcountries.eu/data/vgb.svg",
//     "VI": "https://restcountries.eu/data/vir.svg",
//     "VN": "https://restcountries.eu/data/vnm.svg",
//     "VU": "https://restcountries.eu/data/vut.svg",
//     "WF": "https://restcountries.eu/data/wlf.svg",
//     "WS": "https://restcountries.eu/data/wsm.svg",
//     "XK": "https://restcountries.eu/data/kos.svg",
//     "YE": "https://restcountries.eu/data/yem.svg",
//     "YT": "https://restcountries.eu/data/myt.svg",
//     "ZA": "https://restcountries.eu/data/zaf.svg",
//     "ZM": "https://restcountries.eu/data/zmb.svg",
//     "ZW": "https://restcountries.eu/data/zwe.svg",
// };
export function flag(code = '') {
    // const upperCasedCode = code.toUpperCase();
    // return flags[upperCasedCode] || '';
    const lowerCasedCode = code && code.toLowerCase();
    return (code && `${flagBaseUrl}${lowerCasedCode}.${fileType}`) || '';
}