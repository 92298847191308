/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Collapse, Divider, FormControl, IconButton, makeStyles, Radio, RadioGroup, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button, TextField } from 'components/ui';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PhoneInput from 'containers/Common/PhoneInput';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { getAvatarTxt, getDirtyValues } from 'utils/common';
// import SelectRole from 'containers/Common/SelectRole';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { addBeneficialOwnerState, getCustomerDetailsState, deleteBeneficialOwnerState, tempRegistrationDataState, saveControllingPartyState, verifyPersonalDetailState } from 'store/Register/registerSelectors';
import { addBeneficialOwnerAction, deleteBeneficialOwnerAction, getCustomerDetailsAction, saveControllingPartyAction, tempSaveRegistrationDataAction, verifyPersonalDetailAction } from 'store/Register/registerActions';
import { Loader } from 'components';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import AddBusinessOwnersSkeleton from '../Skeleton/AddBusinessOwnersSkeleton';
// import { styledSnackbar } from 'containers/Common/Snackbar';
import GoBack from '../GoBack';
//import StyledToolTip from 'components/StyledToolTip';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
import AddIcon from '@material-ui/icons/Add';
import { ENTITY_PERSON_TYPE } from '@truly-financial/common/consts/entity-person.consts';
import DisclosingBeneficialOwnerDialog from '../DisclosingBeneficialOwnerDialog';
import { styledSnackbar } from 'containers/Common/Snackbar';

const BENEFICIAL_OWNERS = REGISTRATION_STEP.BENEFICIAL_OWNERS;
const PERSONAL_DETAILS = REGISTRATION_STEP.PERSONAL_DETAILS;

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#fff',
    },
    content: {
        margin: theme.spacing(4, 0, 3, 0)
    },
    subContent: {
        display: 'grid',
    },
    margin: {
        margin: theme.spacing(2, 0)
    },
    align: {
        display: 'flex',
        alignItems: 'center'
    },
    inputDiv: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    justifyContentCenter: {
        justifyContent: 'center'
    },
    mainTitle: {
        textAlign: 'center',
    },
    subTitle: {
        textAlign: 'center',
        margin: theme.spacing(2, 4, 0, 4),
    },
    yourDetailTxt: {
        margin: theme.spacing(0, 0, 2, 0)
    },
    ownDetailDiv: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',

    },
    lastDetailDiv: {
        display: 'flex',

        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        marginBottom: theme.spacing(1),

    },
    avatar: {
        height: theme.spacing(3.75),
        width: theme.spacing(3.75),
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.tertiary_three[200]} !important`,
    },
    otherAvatar: {
        height: theme.spacing(3.75),
        width: theme.spacing(3.75),
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.grey50} !important`,
    },
    ownerShipTxt: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    nameDiv: {
        margin: theme.spacing(0, 0, 0, 1),
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    deleteIconDiv: {
        marginLeft: theme.spacing(2),
        padding: 0
    },
    deleteIcon: {
        fontSize: theme.spacing(2),
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        margin: theme.spacing(0, 0, 3, 0)
    },
    addOwnerDivider: {
        margin: theme.spacing(2, 0)
    },
    stackHolderMargin: {
        margin: theme.spacing(2, 0, 2, 0)
    },
    contentTitle: {
        margin: theme.spacing(0, 0, 2, 0)
    },
    iconDiv: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    addOwnerBtn: {
        margin: theme.spacing(6, 0, 3, 0)
    },

    continueBtn: {

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    addOwnerBtnDiv: {
        margin: theme.spacing(2, 0, 0, 0)
    },
    otherDetailDiv: {
        margin: theme.spacing(3, 'auto', 5, 'auto'),
        flex: 1,
        justifyContent: 'space-between',
        display: 'flex'
    },
    ownerErrorTxt: {
        marginLeft: theme.spacing(1)
    },
    iconColor: {
        color: `${theme.palette.grey500}`,

        padding: `${theme.spacing(0, 0.5, 0, 0)}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },

    },
    btnDiv: {
        flex: 1
    },
    lastMainGrid: {
        margin: theme.spacing(3.8, 0, 2, 0),
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
        }
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    termConditionMargin: {
        margin: theme.spacing(0, 0, 0, 3),
    },
    emailHelperText: {
        color: theme.palette.accent.main,
        // marginLeft: theme.spacing(1.5)
    },
    ownerShipOtherTxt: {
        margin: theme.spacing(0, 3, 0, 1),
    },
    commonFlex: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    rootGrid: {
        maxWidth: theme.spacing(51.75)
    },
    addIcon: {
        fontSize: theme.spacing(3),
        marginRight: theme.spacing(1.5)
    },
    clickHere: {
        textDecoration: 'underline',
        color: theme.palette.accent.main,
        cursor: 'pointer',
    },
    ownerShipRadioTxt: {
        margin: theme.spacing(2, 0)
    },
    isAnyOtherRadio: {
        margin: theme.spacing(0, 0, 2, 0)
    },
    inputBottomDiv: {
        marginBottom: theme.spacing(2)
    },
    remainingSharesTxt: {
        margin: theme.spacing(2, 0)
    },
    redFont: {
        color: theme.palette.error.main
    },
    iconRedColor: {
        color: `${theme.palette.error.main}`,
        padding: `${theme.spacing(0, 0.5, 0, 0)}`,
    },
    ownershipCompleteCheckBox: {
        color: `${theme.palette.grey500}`,
        padding: '2px !important',
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    ownerShipComplete: {
        alignItems: 'flex-start',
        "& .MuiFormControlLabel-root": {
            alignItems: 'flex-start'
        }
    },
    radioButton: {

    },
    radioMain: {
        margin: theme.spacing(2, 0, 2, 2)
    }

}));
const initialStakeHolderForm = {
    entity_person_first_name: '',
    entity_person_last_name: '',
    entity_person_email: '',
    contact_person_position: '',
    entity_person_mobile: '',
    entity_person_mobile_country_code: '',
    ownership_percentage: null,
    is_controlling_party: null,
    entity_person_type: ENTITY_PERSON_TYPE.STAKEHOLDER,
    is_expanded: true,
    tempId: ""
};
function BeneficialOwners(props = {}) {
    const { onContinue = () => null, onBackPress = () => null,
        tempSaveRegistrationData, tempRegistrationDataState,
        getCustomerDetailsAction, getCustomerDetailsState, verifyPersonalDetailAction, verifyPersonalDetailState,
        saveControllingPartyState, deleteBeneficialOwnerAction, addBeneficialOwnerState,
       /* addBeneficialOwnerAction, */deleteBeneficialOwnerState } = props;
    const classes = useStyles();
    const intlStr = useIntl();
    const theme = useTheme();
    const avatarColor = [
        theme.palette.tertiary_one[200], theme.palette.tertiary_two[200],
        theme.palette.tertiary_three[200], theme.palette.tertiary_four[200],
    ];

    const [formData, setFormData] = useState([]);
    const [totalOwnerShip, setTotalOwnerShip] = useState(100);
    const discloseOwner = useRef();
    // eslint-disable-next-line no-unused-vars
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const textIntl = useIntl();
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const isOwnershipStepCompleted = Array.isArray(customerDetail.completed_steps) && customerDetail.completed_steps.includes(BENEFICIAL_OWNERS) && customerDetail.completed_steps.includes(PERSONAL_DETAILS) ? true : false;
    const [isOwnershipComplete, setIsOwnershipComplete] = useState(isOwnershipStepCompleted);
    const [keyUpTimeout, setKeyUpTimeout] = useState(null);
    const isStepComplete = (Array.isArray(customerDetail.completed_steps) && customerDetail.completed_steps.includes(BENEFICIAL_OWNERS)) || false;
    const [submitButton, setSubmitButton] = useState("1");
    const isLoading = ((verifyPersonalDetailState && verifyPersonalDetailState.loading) || (deleteBeneficialOwnerState && deleteBeneficialOwnerState.loading) || (saveControllingPartyState && saveControllingPartyState.loading) || (addBeneficialOwnerState && addBeneficialOwnerState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading));
    const isFieldDisable = ((customerDetail && customerDetail.allowRegistrationDataEdit));
    const company_name = (customerDetail && customerDetail.prs_customer_introduction_data && customerDetail.prs_customer_introduction_data.company_name) || ""
    const [isAnyOtherShareHolder, setIsAnyOtherShareHolder] = useState('');
    const primaryContact = formData.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT)[0];
    // const isAnyStackHolderAdded = formData.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.STAKEHOLDER && item.entity_person_id).length > 0 ? true : false;
    const isAnyStackHolderAdd = formData.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.STAKEHOLDER && item.ownership_percentage && parseFloat(item.ownership_percentage) > 24).length > 0 ? true : false;

    const [radioError, setRadioError] = useState([]);

    useEffect(() => {

        if (formData.length > 0) {
            tempSaveRegistrationData({
                prs_beneficial_owners_data: JSON.parse(JSON.stringify(formData)),
            });
        }

    }, [formData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";

        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_PAGE_LOAD.event });
    }, []);

    useEffect(() => {

        var ob = { ...formData, ...customerDetail.prs_beneficial_owners_data };

        if (tempRegistrationDataState && tempRegistrationDataState.prs_beneficial_owners_data && Array.isArray(tempRegistrationDataState.prs_beneficial_owners_data) && tempRegistrationDataState.prs_beneficial_owners_data.length > 0) {
            let result = customerDetail && customerDetail.prs_beneficial_owners_data && customerDetail.prs_beneficial_owners_data.entity_persons &&
                customerDetail.prs_beneficial_owners_data.entity_persons.concat(tempRegistrationDataState && tempRegistrationDataState.prs_beneficial_owners_data &&
                    tempRegistrationDataState.prs_beneficial_owners_data.filter(bo => customerDetail.prs_beneficial_owners_data.entity_persons.every(ao => (ao.entity_person_id !== bo.entity_person_id && ao.entity_person_email !== bo.entity_person_email))));
            ob = { ...ob, entity_persons: result };
        }

        if (ob && ob.entity_persons && ob.entity_persons.length > 0) {
            var entity_personsCpy = ob.entity_persons;
            var array = entity_personsCpy.map((item, index) => ({
                ...item,
                is_expanded: false,
            }));
            var temp = JSON.parse(JSON.stringify(array));

            temp = temp.map((item, index) => {
                if (item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT && item.ownership_percentage && parseFloat(item.ownership_percentage) > 24) {
                    item.isPrimaryContactHaveOwnership = "true";
                } else if (customerDetail.completed_steps.includes(PERSONAL_DETAILS)) {
                    item.isPrimaryContactHaveOwnership = "false";
                }
                return item;
            });
            const isAnyStackHolderAddLocal = temp.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.STAKEHOLDER && item.ownership_percentage && parseFloat(item.ownership_percentage) > 24).length > 0 ? true : false;
            if (isAnyStackHolderAddLocal) {
                setIsAnyOtherShareHolder("true");
            } else if (customerDetail.completed_steps.includes(BENEFICIAL_OWNERS)) {
                setIsAnyOtherShareHolder("false");
            }

            setFormData(temp);
            checkOwnerShipPercentage(temp);


        }
    }, [customerDetail.prs_beneficial_owners_data]);


    const onOwnershipComplete = (e) => {
        const { checked } = e.target || {};
        setIsOwnershipComplete(checked);
    };
    const onChange = (e, index) => {
        const { name, value } = e.target || {};

        setRadioError([]);
        if (name && formData.length > 0) {
            setFormData(previousState => {
                let updatedArray = [];
                // eslint-disable-next-line eqeqeq
                if (name === "isPrimaryContactHaveOwnership" && value == "false") {

                    updatedArray = previousState.map((item, i) => {
                        return i === index ? { ...item, "isPrimaryContactHaveOwnership": value, ownership_percentage: 0 } : item;
                    });
                } else {

                    updatedArray = previousState.map((item, i) => {
                        return i === index ? { ...item, [name]: value } : item;
                    });
                }


                if (name === "ownership_percentage" && value) {
                    setKeyUpTimeout(clearTimeout(keyUpTimeout));
                    setKeyUpTimeout(setTimeout(() => {
                        checkOwnerShipPercentage(updatedArray);
                    }, 1000));
                }
                return updatedArray;
            });
        }
    };
    const isAnyOtherRadioChange = (e) => {
        const { value } = e.target || {};
        setRadioError([]);

        if (isAnyStackHolderAdd) {
            styledSnackbar.error("You have to delete added stockholder to change this option");
        } else {

            if (value === "true" && isAnyOtherShareHolder !== "true") {
                onAddOwner();
            } else {
                removeAllShareHolders();
            }
            setIsAnyOtherShareHolder(value);
        }
    };

    const onSuccessDelete = () => {
        getCustomerDetailsAction();
    };
    const onDeleteBeneficialClick = (index) => {
        let formDataArray = [...formData];
        if (formDataArray[index].entity_person_id) {
            let FilterDeleteId = formDataArray.filter((item) => item.entity_person_id !== formDataArray[index].entity_person_id)
            tempSaveRegistrationData({
                prs_beneficial_owners_data: JSON.parse(JSON.stringify(FilterDeleteId)),
            });


            deleteBeneficialOwnerAction({
                id: formDataArray[index].entity_person_id,
                cb: onSuccessDelete
            });
        } else {
            let filteredArray = formDataArray.filter((item, arrayIndex) => arrayIndex !== index);
            checkOwnerShipPercentage(filteredArray);
            setFormData(filteredArray);
        }
    };
    const removeAllShareHolders = () => {
        let formDataArray = [...formData];
        let filteredPrimaryUser = formDataArray.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT);
        setFormData(filteredPrimaryUser);
    };

    const onAddOwner = () => {
        let formDataArray = [...formData];
        let copyInitialStakeHolderForm = { ...initialStakeHolderForm };
        if (customerDetail && customerDetail.company_details && customerDetail.company_details.incorporation_country_code) {
            copyInitialStakeHolderForm.entity_person_mobile_country_code = (customerDetail.company_details.incorporation_country_code || "");
        }
        copyInitialStakeHolderForm.tempId = "id" + Math.random().toString(16).slice(2);
        formDataArray.push(copyInitialStakeHolderForm);
        if (formDataArray.length > 2) {
            formDataArray.forEach((item, itemIndex) => {
                if ((formDataArray.length - 1) === itemIndex) {
                    item.is_expanded = true;
                } else {
                    item.is_expanded = false;
                }
            });
        }
        setFormData(formDataArray);
    };

    const onDetailsPress = (index) => {
        let formDataArray = [...formData];

        formDataArray.forEach((item, itemIndex) => {
            if (index === itemIndex) {
                item.is_expanded = !item.is_expanded;
            } else {
                item.is_expanded = false;
            }
        });
        setFormData(formDataArray);
    };

    const onSuccess = (arg = "onBoarding") => {
        onContinue(arg);
    };
    const checkRadioBtnChecked = () => {
        const error = [];
        if (formData[0] && (formData[0].isPrimaryContactHaveOwnership === null || formData[0].isPrimaryContactHaveOwnership === undefined)) {
            error.push("isPrimaryContactHaveOwnership");
        }
        if (((formData[0] && (formData[0].isPrimaryContactHaveOwnership === "true" && (parseFloat(formData[0].ownership_percentage) > 24 && parseFloat(formData[0].ownership_percentage) < 76)) && !isAnyOtherShareHolder))) {
            error.push("isAnyOtherShareHolder");
        }

        setRadioError(error);

        return error.length === 0 ? true : false;
    };

    const isEMailDuplicate = () => {
        let tempFormData = [...formData];
        var emailArray = tempFormData.map(function (item) { return item.entity_person_email; });
        let duplicateEmailArray = emailArray.some((item, index) => emailArray.indexOf(item) !== index);
        return duplicateEmailArray;
    };

    const onContinueSubmission = () => {

        if (checkRadioBtnChecked()) {

            if (isEMailDuplicate()) {
                styledSnackbar.error('Same email id can not be used for multiple owners / controlling party');
                return;
            }
            let tempFormData = [...formData];
            tempFormData.forEach(item => {
                delete item.is_expanded;
                delete item.entity_person_phone_number;
                delete item.entity_person_mobile_dialing_code;
            });

            let dirtyValArray = [];
            for (var i = 0; i < tempFormData.length; i++) {
                const ob = getDirtyValues(customerDetail.prs_beneficial_owners_data.entity_persons[i], tempFormData[i]);

                if (ob.addresses) {
                    delete ob.addresses;
                }
                if (Object.keys(ob).length > 0) {
                    dirtyValArray.push(ob);
                }
            }

            if (dirtyValArray.length > 0 || !isStepComplete) {
                verifyPersonalDetailAction({ body: formData, cb: onSuccess });
                // addBeneficialOwnerAction({ body: formData, cb: onSuccess });
            }


            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_CONTINUE_CLICK.event });

            if (formData && formData.length) {

                let trackingProperties = ANALYTICS.EVENTS.BUSINESS_OWNER_NO_OF_OWNERS.tracking_properties;

                let tracking_data = {};
                trackingProperties.forEach((tP) => {
                    return tracking_data[tP.key] = formData.length;
                });

                analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_NO_OF_OWNERS.event, tracking_data });
            }

            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_CONTINUE_CLICK.event });

            if (formData && formData.length) {

                let trackingProperties = ANALYTICS.EVENTS.BUSINESS_OWNER_NO_OF_OWNERS.tracking_properties;

                let tracking_data = {};
                trackingProperties.forEach((tP) => {
                    return tracking_data[tP.key] = formData.length;
                });

                analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_OWNER_NO_OF_OWNERS.event, tracking_data });
            }
        }
    };

    const onSubmit = (e) => {

        e.preventDefault();
        if (submitButton === 2) {
            if (checkRadioBtnChecked() && totalOwnerShip > 0) {

                discloseOwner.current && discloseOwner.current.onOpen();
            } else {
                onContinueSubmission();
            }
        } else {
            onAddOwner();
        }


    };

    const checkOwnerShipPercentage = (array = []) => {
        let totalOwnerShipPer = 100;
        var entityOwnerShipPer = 0;
        array.forEach((item) => {
            if (parseFloat(item.ownership_percentage)) {
                entityOwnerShipPer = entityOwnerShipPer + parseFloat(item.ownership_percentage);
            }
        });
        setTotalOwnerShip((totalOwnerShipPer - entityOwnerShipPer).toFixed(2));

    };
    const onInvalided = (index) => {

        if (index && formData.length > 0) {
            setFormData(previousState => {
                const updatedArray = previousState.map((item, i) => {
                    if (index === i && !item.is_expanded) {
                        item.is_expanded = true;
                    }
                    return item;
                });

                return updatedArray;
            });
        }

    };

    const beneficialOwnerContent = (data = {}, index) => {
        let name = (data.entity_person_first_name || "") + (data.entity_person_last_name || "");
        let isDisabled = (data.entity_person_first_name && data.entity_person_id_verification_status === 'PASS_PROCESSED');

        return (

            <Grid container direction="column" key={index}>
                <Grid >
                    <span className={classes.ownDetailDiv}>
                        <div onClick={() => { data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && onDetailsPress(index); }} className={classes.iconDiv}>
                            <Avatar
                                // className={classes.avatar}
                                style={{
                                    height: theme.spacing(3.75),
                                    width: theme.spacing(3.75),
                                    color: theme.palette.primary.main,
                                    backgroundColor: avatarColor[(index + 1) % 4]
                                }}
                                classes={{ colorDefault: classes.avatarColor }}
                                alt="dp"
                            >
                                <Typography className={classes.roundTxt} color="grey700" variant="body2" >{getAvatarTxt(data, "entity")}</Typography>
                            </Avatar>
                            <div className={classes.nameDiv} >
                                <Typography variant="body2" color="grey700">{name ? (data.entity_person_first_name || "") + " " + (data.entity_person_last_name || "") : "-"} </Typography>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            {
                                data.ownership_percentage && parseFloat(data.ownership_percentage) !== 0 ? <div onClick={() => { data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && onDetailsPress(index) }}>
                                    <Typography variant="cation" color="grey500">{(data && data.ownership_percentage) || 0}% <FormattedMessage {...intl.onBoardingStep13OwnerShip} /></Typography>
                                </div> : <div></div>
                            }

                            {
                                data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && <IconButton onClick={() => { onDeleteBeneficialClick(index); }} className={classes.deleteIconDiv} >
                                    <DeleteOutlineIcon className={classes.deleteIcon} />
                                </IconButton>
                            }
                        </div>
                    </span>
                </Grid>

                <Collapse in={data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ? false : data.is_expanded} >
                    <Grid>
                        <div className={classes.inputDiv}>
                            <TextField
                                label={`${intlStr.formatMessage(intl.onBoardingStep10FirstName)}`}
                                type="text"
                                id="entity_person_first_name"
                                name="entity_person_first_name"
                                required
                                value={data.entity_person_first_name}
                                errorText=""
                                onFocusRef={() => { onInvalided(index) }}
                                disabled={(isFieldDisable && data.entity_person_id_verification_status === "PENDING_PROCESSED") || isDisabled}
                                onChange={(e) => { onChange(e, index); }}
                            />
                        </div>
                        <div className={classes.inputDiv}>
                            <TextField
                                label={`${intlStr.formatMessage(intl.onBoardingStep10LastName)}`}
                                type="text"
                                id="entity_person_last_name"
                                name="entity_person_last_name"
                                required
                                value={data.entity_person_last_name}
                                errorText=""
                                onFocusRef={() => { onInvalided(index) }}
                                disabled={(isFieldDisable && data.entity_person_id_verification_status === "PENDING_PROCESSED") || isDisabled}
                                onChange={(e) => { onChange(e, index); }}
                            />
                        </div>
                        <div className={classes.inputDiv}>
                            <TextField
                                id="entity_person_email"
                                label={`${intlStr.formatMessage(intl.onBoardingStep10Email)}`}
                                type="email"
                                name="entity_person_email"
                                required
                                value={data.entity_person_email}
                                errorText=""
                                onFocusRef={() => { onInvalided(index) }}
                                // inputProps={{
                                //     pattern: "/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/"
                                // }}
                                disabled={((isFieldDisable && data.entity_person_id_verification_status === "PENDING_PROCESSED") || isDisabled)}
                                onChange={(e) => { onChange(e, index); }}
                            />
                            <Typography variant="caption" className={classes.emailHelperText} > <FormattedMessage {...intl.onBoardingStep13VerificationLabel} /></Typography>
                        </div>
                        {/* <div className={classes.inputDiv}>
                            <SelectRole
                                label={`${intlStr.formatMessage(intl.onBoardingStepRole)}`}
                                required
                                id="contact_person_position"
                                name="contact_person_position"
                                errorText=""
                                onFocusRef={() => { onInvalided(index) }}
                                value={data.contact_person_position}
                                // disabled={data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ? true : false}
                                onChange={(e) => { onChange(e, index); }}
                            />
                        </div> */}
                        <div className={classes.inputDiv} >
                            <PhoneInput
                                // disabled={data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ? true : false}
                                mobileCountryId="entity_person_mobile_country_code"
                                mobileCountryName="entity_person_mobile_country_code"
                                mobileCountryValue={data.entity_person_mobile_country_code}
                                mobileCountryLabel={<FormattedMessage {...intl.onBoardingStep13YourDetailCountryLabel} />}
                                mobileDialingCodeId=""
                                mobileDialingCodeName="entity_person_mobile_dialing_code"
                                mobileId=""
                                mobileName="entity_person_mobile"
                                // onFocusRef={() => { onInvalided(index) }}
                                mobileLabel={`${textIntl.formatMessage(intl.onBoardingStep13YourDetailMobileLabel)}`}
                                mobileValue={data.entity_person_mobile}
                                phoneNumberId=""
                                required
                                errorText=""
                                phoneNumberName="entity_person_phone_number"
                                onChange={(e) => { onChange(e, index); }}
                            // helperTextReg={`${textIntl.formatMessage(intl.onBoardingStep13VerificationLabel)}`}
                            />
                        </div>
                        {
                            data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT &&
                            <div className={classes.inputDiv}>
                                <TextField
                                    // disabled={data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ? true : false}
                                    required={parseFloat(data.ownership_percentage) > 0}
                                    id="ownership_percentage"
                                    name="ownership_percentage"
                                    onKeyPress={(e) => {
                                        if (e.charCode === 45 || e.charCode === 43) {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                    }}
                                    errorText={parseFloat(data.ownership_percentage) < 25 ? "Required only if ownership is 25% or more" : "Ownership can't be more then 100%"}
                                    inputProps={{
                                        min: parseFloat(data.ownership_percentage) > 0 ? 25 : 0,
                                        max: 100,
                                        step: "0.01"
                                    }}
                                    value={parseFloat(data.ownership_percentage) || ""}
                                    onChange={(e) => { onChange(e, index); }}
                                    onFocusRef={() => { onInvalided(index) }}
                                    label={`${intlStr.formatMessage(intl.onBoardingStep10SharedOwner)}`}
                                    type="number"

                                />
                            </div>
                        }


                    </Grid>
                </Collapse>
                {
                    data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && index !== (formData.length - 1) &&
                    < Divider className={classes.stackHolderMargin} />
                }


                {
                    data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT &&
                    <Grid className={classes.ownerShipRadioTxt}>
                        <Grid item xs={12}><Typography className={radioError.includes("isPrimaryContactHaveOwnership") && classes.redFont} variant="subtitle2"><FormattedMessage {...intl.onBoardingStep10OwnerShare} /></Typography></Grid>
                        <div item>
                            <RadioGroup className={classes.radioMain} row required aria-label="isPrimaryContactHaveOwnership" name="isPrimaryContactHaveOwnership" value={data.isPrimaryContactHaveOwnership} onChange={(e) => { onChange(e, index); }} >
                                <FormControlLabel value={"true"} control={<Radio className={radioError.includes("isPrimaryContactHaveOwnership") ? classes.iconRedColor : classes.iconColor} checked={(data.isPrimaryContactHaveOwnership === "true" || data.isPrimaryContactHaveOwnership === 1 || (primaryContact && primaryContact.ownership_percentage && parseFloat(primaryContact.ownership_percentage > 24))) && true} />} label={`${intlStr.formatMessage(intl.onBoardingStep4RadioBtnYes)}`} />
                                <FormControlLabel value={"false"} control={<Radio className={radioError.includes("isPrimaryContactHaveOwnership") ? classes.iconRedColor : classes.iconColor} checked={(data.isPrimaryContactHaveOwnership === "false" || data.isPrimaryContactHaveOwnership === 0) && true} />} label={`${intlStr.formatMessage(intl.onBoardingStep4RadioBtnNo)}`} />
                            </RadioGroup>
                        </div>
                    </Grid>
                }

                {
                    (data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT &&
                        (data.isPrimaryContactHaveOwnership === "true" || parseFloat(data.ownership_percentage > 24 && customerDetail.completed_steps.includes(PERSONAL_DETAILS)) || (data.ownership_percentage && !customerDetail.completed_steps.includes(PERSONAL_DETAILS)))) ?
                        <div>
                            <TextField
                                className={classes.inputBottomDiv}
                                // disabled={data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ? true : false}
                                required={parseFloat(data.ownership_percentage) > 0}
                                id="ownership_percentage"
                                name="ownership_percentage"
                                errorText={parseFloat(data.ownership_percentage) < 25 ? "Required only if ownership is 25% or more" : "Ownership can't be more then 100%"}
                                onKeyPress={(e) => {
                                    if (e.charCode === 45 || e.charCode === 43) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }}
                                inputProps={{
                                    min: 25,
                                    max: 100,
                                    step: "0.01"
                                }}
                                value={parseFloat(data.ownership_percentage) || ""}
                                onChange={(e) => { onChange(e, index); }}
                                onFocusRef={() => { onInvalided(index) }}
                                label={`${intlStr.formatMessage(intl.onBoardingStep10SharedOwner)}`}
                                type="number"
                            />
                        </div> : <></>
                }
                {
                    data.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT &&
                    <Divider className={classes.divider} />
                }

                {
                    data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && totalOwnerShip > 0 && index === (formData.length - 1) &&
                    <Grid >
                        <Typography className={classes.remainingSharesTxt} variant="subtitle2" >Remaining Shares</Typography>
                        <div className={classes.lastDetailDiv}>

                            <div className={classes.iconDiv}>
                                <Avatar
                                    className={classes.otherAvatar}
                                    // classes={{ colorDefault: classes.avatarColor }}
                                    alt="dp"
                                >
                                    <Typography className={classes.roundTxt} variant="body2" >-</Typography>
                                </Avatar>
                                <div className={classes.ownerShipOtherTxt} >
                                    <Typography variant="body2" color="grey700">{"Percentage of company held by persons with <25% shares each"} </Typography>


                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Typography variant="cation" color="grey700">{totalOwnerShip}% <FormattedMessage {...intl.onBoardingStep13OwnerShip} /></Typography>
                                {/* {
                                    <IconButton className={classes.deleteIconDiv} >
                                        <InfoOutlinedIcon className={classes.deleteIcon} />
                                    </IconButton>
                                } */}
                            </div>
                        </div>
                        <Divider className={classes.addOwnerDivider} />

                    </Grid>

                }
                {
                    data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && index === (formData.length - 1) && <Grid className={data.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT && totalOwnerShip > 0 && index === (formData.length - 1) ? undefined : classes.addOwnerBtnDiv}>
                        <Button size="small" color="secondary" type="submit" onClick={() => { setSubmitButton(1) }} >
                            <div className={classes.row} >
                                <AddIcon className={classes.addIcon} />
                                <Typography variant="subtitle2" color="primary">

                                    <FormattedMessage {...intl.onBoardingStep10AddOwner} />
                                </Typography>
                            </div>


                        </Button>
                    </Grid>
                }

            </Grid >
        );
    };

    if (!getCustomerDetailsState.prs_user && getCustomerDetailsState.loading) {
        return null;
    }

    return (<>
        <form onSubmit={onSubmit} className={classes.root}>
            {
                isLoading && <Loader />
            }

            <Grid container justifyContent="center">
                <Grid item xs="11" md="9" className={classes.subContent}>
                    <div>
                        <div className={classes.row} >
                            <Typography className={classes.mainTitle} variant={"h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep10Title} /></Typography>
                            {/* <StyledToolTip
                                normal={true}
                                component={<HelpOutlineIcon />}
                                infoBtnSize="16px"
                                caption={'Controlling Party is an individual with significant responsibility for managing or directing the entity, including, an executive officer or senior manager(e.g. Chief Executive Officer, Chief Financial Officer, Chief Operating Officer, Managing Member, General Partner, President, Executive Vice President, Treasurer) or Any other individual who regularly performs similar functions'}
                            /> */}
                        </div>

                        <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep10Subtitle} /> {company_name}</Typography>
                    </div>
                    <DisclosingBeneficialOwnerDialog ref={discloseOwner} remainingPercentage={totalOwnerShip} onContinue={onContinueSubmission} />
                    <Grid container justifyContent="center" className={classes.content}>
                        <Grid md="8" className={classes.rootGrid} container justifyContent="center">
                            <>
                                {
                                    customerDetail && (Object.keys(customerDetail).length > 0 || !customerDetail.loading) && formData && formData.length > 0 && formData.filter(item => item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT).map((item, index) => {
                                        return (
                                            <>
                                                {beneficialOwnerContent(item, index)}

                                            </>
                                        );
                                    })
                                    //  : <AddBusinessOwnersSkeleton />

                                }
                                {
                                    ((primaryContact && ((((primaryContact.ownership_percentage && parseFloat(primaryContact.ownership_percentage) < 76) || isAnyStackHolderAdd) && primaryContact.isPrimaryContactHaveOwnership === "true") || primaryContact.isPrimaryContactHaveOwnership === "false"))) &&
                                    <>
                                        <Grid className={classes.isAnyOtherRadio}>
                                            <Grid ><Typography className={radioError.includes("isAnyOtherShareHolder") && classes.redFont} variant="subtitle2"><FormattedMessage {...intl.onBoardingStep10OwnerIsAnyOtherShare} /></Typography></Grid>
                                            <div >
                                                <FormControl>
                                                    <RadioGroup
                                                        className={classes.radioMain}
                                                        row required
                                                        aria-label="isAnyOtherShareHolder"
                                                        name="isAnyOtherShareHolder"
                                                        value={isAnyOtherShareHolder} onChange={(e) => { isAnyOtherRadioChange(e); }} >
                                                        <FormControlLabel value={"true"} control={<Radio className={radioError.includes("isAnyOtherShareHolder") ? classes.iconRedColor : classes.iconColor} checked={(isAnyOtherShareHolder === "true") && true} />} label={`${intlStr.formatMessage(intl.onBoardingStep4RadioBtnYes)}`} />
                                                        <FormControlLabel value={"false"} control={<Radio className={radioError.includes("isAnyOtherShareHolder") ? classes.iconRedColor : classes.iconColor} checked={(isAnyOtherShareHolder === "false") && true} />} label={`${intlStr.formatMessage(intl.onBoardingStep4RadioBtnNo)}`} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                        {
                                            customerDetail && (Object.keys(customerDetail).length > 0 || !customerDetail.loading) && formData && formData.length > 0 && formData.map((item, index) => {

                                                return (
                                                    <>
                                                        {((item.entity_person_type !== ENTITY_PERSON_TYPE.PRIMARY_CONTACT) && ((parseFloat(item.ownership_percentage) !== 0 && item.entity_person_id) || !item.entity_person_id)) && beneficialOwnerContent(item, index)}

                                                    </>
                                                );
                                            })
                                        }
                                    </>
                                }
                            </>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center'>

                        <Grid md="8" className={classes.termConditionMargin} >
                            {totalOwnerShip < 0 && <Typography className={classes.ownerErrorTxt} variant="cation" color="error"><FormattedMessage {...intl.onBoardingStep10OwnerShipError} /></Typography>}
                            <FormGroup
                                className={classes.ownerShipComplete}
                                onChange={(e) => { onOwnershipComplete(e); }}
                            >
                                <FormControlLabel control={<Checkbox className={classes.ownershipCompleteCheckBox} checked={isOwnershipComplete} name="isOwnershipComplete" />} label={<Typography variant="caption"><FormattedMessage {...intl.onBoardingStep10ConfirmCheckbox} /></Typography>} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    {/* <Grid container justifyContent="center" > */}

                    <Grid container className={classes.lastMainGrid} >
                        <GoBack onClick={onBackPress} isLoading={isLoading} />
                        <Grid item className={classes.btnDiv} md="3">
                            <Button className={classes.btnDiv} disabled={totalOwnerShip < 0 || !isOwnershipComplete} size="small" type="submit" onClick={() => { setSubmitButton(2) }}>
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep10ContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form >
    </>);
}
const mapStateToProps = createStructuredSelector({

    addBeneficialOwnerState: addBeneficialOwnerState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    deleteBeneficialOwnerState: deleteBeneficialOwnerState(),
    tempRegistrationDataState: tempRegistrationDataState(),
    saveControllingPartyState: saveControllingPartyState(),
    verifyPersonalDetailState: verifyPersonalDetailState(),
});

function mapDispatchToProps(dispatch) {
    return {
        verifyPersonalDetailAction: (data) => dispatch(verifyPersonalDetailAction(data)),
        addBeneficialOwnerAction: (data) => dispatch(addBeneficialOwnerAction(data)),
        deleteBeneficialOwnerAction: (data) => dispatch(deleteBeneficialOwnerAction(data)),
        getCustomerDetailsAction: (data) => dispatch(getCustomerDetailsAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
        saveControllingParty: (data) => dispatch(saveControllingPartyAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(BeneficialOwners);
