import produce from 'immer';
import {
  RESET_TAGS,
  GET_TAGS, GET_TAGS_SET_DATA,
  ADD_TAGS, ADD_TAGS_SET_DATA,
  REMOVE_TAG, REMOVE_TAG_SET_DATA,
} from './tagConstants';

export const initialState = {
  removeTagState: { data: {}, loading: false, error: '' },
  addTagsState: { data: {}, loading: false, error: '' },
  getTagsState: { data: [], loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const tagsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case REMOVE_TAG:
        draft.removeTagState = {
          ...draft.removeTagState,
          loading: true, message: '', error: '',
        };
        break;
      case REMOVE_TAG_SET_DATA:
        draft.removeTagState = {
          ...draft.removeTagState,
          ...action.data, loading: false,
        };
        break;
      case ADD_TAGS:
        draft.addTagsState = {
          ...draft.addTagsState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_TAGS_SET_DATA:
        draft.addTagsState = {
          ...draft.addTagsState,
          ...action.data, loading: false,
        };
        break;
      case GET_TAGS:
        draft.getTagsState = {
          ...draft.getTagsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_TAGS_SET_DATA:
        draft.getTagsState = {
          ...draft.getTagsState,
          ...action.data, loading: false,
        };
        break;
      case RESET_TAGS:
        draft = initialState;
        break;
    }
  });

export default tagsReducer;
