import React, { memo, useState } from 'react';
import { makeStyles, Link } from "@material-ui/core";

import { Typography, Button, TextField } from "components/ui";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Loader } from 'components';
import NewPasswordInput from 'components/NewPasswordInput';
import { createPasswordAction } from 'store/Verification/verificationActions';
import { createPasswordState } from 'store/Verification/verificationSelectors';
// import { ID_VERIFICATION_STEPS } from 'utils/common';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/Verification/Intl/VerificationIntl';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            textAlign: 'center',
        },

        backgroundColor: 'white',
    },
    container: {
        margin: 'auto',
        padding: theme.spacing(3),
    },
    bodyTxt: {
        margin: theme.spacing(8, 0, 2, 0),
    },
    inputDiv: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    formDiv: {
        marginTop: theme.spacing(5)
    },
    submitBtn: {
        marginTop: theme.spacing(3)
    },
    error: {
        color: "#0075E1",
        textAlign: "left",
        fontSize: "12px"
    },
    accountCreatedDiv: {
        padding: `10% ${theme.spacing(2)}px`
    },
    loginButton: {
        width: "200px",
    },
    buttonDiv: {
        padding: theme.spacing(3),
    },
    primaryLight: {
        color: theme.palette.primary.light,
    },
    textCenter: {
        textAlign: 'center',
        marginTop: theme.spacing(3)
    },
    link: {
        color: theme.palette.hyperlink.main,
        textDecoration: 'underline'
    }

}));

function CreatePassword(props = {}) {
    const classes = useStyles();
    const { data, createPasswordState, createPassword, onStepChange } = props;

    const [formData, setFormData] = useState({
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target || {};
        setFormData((formData) => ({ ...formData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault && e.preventDefault();
        createPassword({
            personId: data.entity_person_id,
            token: data.continue_signup_token,
            body: {
                password: formData.password,
            },
            cb: onStepChange
        });
    };


    return (<form onSubmit={handleSubmit}><div className={classes.root}>
        <div className={classes.container}>
            <Typography color="grey700" variant="h5" className={classes.bodyTxt}>
                <FormattedMessage {...intl.started} />
            </Typography>
            <Typography className={classes.primaryLight} variant="titleMobile">
                <FormattedMessage {...intl.createPass} />
            </Typography>
            {createPasswordState.loading && <Loader />}
            <div className={classes.formDiv}>
                <div className={classes.inputDiv}>
                    <TextField type="text"
                        id="email" name="email"
                        value={data.entity_person_email}
                        label={'Email'}
                        required
                        disabled
                    // onChange={handleChange}
                    />
                </div>
                <div className={classes.inputDiv}>
                    <NewPasswordInput
                        name="password"
                        label="Password"
                        value={formData.password || ''}
                        onChange={handleChange}
                    />
                </div>

            </div>
            <Button
                title={"Create account"}
                className={classes.submitBtn}
                type="submit"
                color="primary"
            />
            <Typography color="grey700" variant="body2" className={classes.textCenter} >
                By signing up you are agreeing to our <Link className={classes.link} href="/legal/terms-and-conditions" target="_blank">Terms and Conditions</Link>, and <Link className={classes.link} href="https://trulyfinancial.com/legal/#privacy" target="_blank">Privacy Policy</Link>.
            </Typography>
        </div>
    </div>
    </form>)
}


const mapStateToProps = createStructuredSelector({
    createPasswordState: createPasswordState(),
});

function mapDispatchToProps(dispatch) {
    return {
        createPassword: data => dispatch(createPasswordAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(CreatePassword);
