import { createSelector } from 'reselect';
import GlobalState from '../selectors';

export const globalAccountState = state => state.account || {};

export const accountState = () =>
  createSelector(GlobalState, substate => substate.account);

export const getAccountListState = () =>
  createSelector(globalAccountState, substate => substate.getAccountListState);

export const getAccountDetailsState = () =>
  createSelector(globalAccountState, substate => substate.getAccountDetailsState);

export const accountPaymentTransferState = () =>
  createSelector(globalAccountState, substate => substate.accountPaymentTransferState);

export const createCheckInAccountState = () =>
  createSelector(globalAccountState, substate => substate.createCheckInAccountState);

export const accountPaymentListState = () =>
  createSelector(globalAccountState, substate => substate.accountPaymentListState);

export const accountPaymentDetailsState = () =>
  createSelector(globalAccountState, substate => substate.accountPaymentDetailsState);

export const getAccountCurrenciesState = () =>
  createSelector(globalAccountState, substate => substate.getAccountCurrenciesState);

export const getCheckInAccountListState = () =>
  createSelector(globalAccountState, substate => substate.getCheckInAccountListState);

export const getSmartLinkAccountListState = () =>
  createSelector(globalAccountState, substate => substate.getSmartLinkAccountListState);

export const getSmartLinkCardListState = () =>
  createSelector(globalAccountState, substate => substate.getSmartLinkCardListState);

export const getSmartLinkAccountDetailsState = () =>
  createSelector(globalAccountState, substate => substate.getSmartLinkAccountDetailsState);

export const getSmartLinkAccountPaymentsState = () =>
  createSelector(globalAccountState, substate => substate.getSmartLinkAccountPaymentsState);

export const activatePhysicalCardState = () =>
  createSelector(globalAccountState, substate => substate.activatePhysicalCardState);

export const getCardSecureDetailsState = () =>
  createSelector(globalAccountState, substate => substate.getCardSecureDetailsState);

export const cardChangePinState = () =>
  createSelector(globalAccountState, substate => substate.cardChangePinState);

export const cardLockState = () =>
  createSelector(globalAccountState, substate => substate.cardLockState);

export const smartLinkAccLockState = () =>
  createSelector(globalAccountState, substate => substate.smartLinkAccLockState);

export const getAccountSettingState = () =>
  createSelector(globalAccountState, substate => substate.getAccountSettingState);

export const saveAccountSettingState = () =>
  createSelector(globalAccountState, substate => substate.saveAccountSettingState);

export const getAccountTeamMembersState = () =>
  createSelector(globalAccountState, substate => substate.getAccountTeamMembersState);

export const getTeamMemberDetailState = () =>
  createSelector(globalAccountState, substate => substate.getTeamMemberDetailState);

export const addUpdateTeamMemberState = () =>
  createSelector(globalAccountState, substate => substate.addUpdateTeamMemberState);

export const createSmartLinkAccState = () =>
  createSelector(globalAccountState, substate => substate.createSmartLinkAccState);

export const getSmartLinkAccDetailState = () =>
  createSelector(globalAccountState, substate => substate.getSmartLinkAccDetailState);

export const getCardDetailsState = () =>
  createSelector(globalAccountState, substate => substate.getCardDetailsState);

export const issueSmartlinkCardsState = () =>
  createSelector(globalAccountState, substate => substate.issueSmartlinkCardsState);

export const getCardPaymentDetailState = () =>
  createSelector(globalAccountState, substate => substate.getCardPaymentDetailState);

export const updateCardNickNameState = () =>
  createSelector(globalAccountState, substate => substate.updateCardNickNameState);

export const preQuickTransferState = () =>
  createSelector(globalAccountState, substate => substate.preQuickTransferState);

export const quickTransferState = () =>
  createSelector(globalAccountState, substate => substate.quickTransferState);

export const getLinkAccListState = () =>
  createSelector(globalAccountState, substate => substate.getLinkAccListState);

export const getExternalAccountsState = () =>
  createSelector(globalAccountState, substate => substate.getExternalAccountsState);

export const addExternalAccountState = () =>
  createSelector(globalAccountState, substate => substate.addExternalAccountState);

export const getExternalAccDetailsState = () =>
  createSelector(globalAccountState, substate => substate.getExternalAccDetailsState);

export const removeExternalAccountState = () =>
  createSelector(globalAccountState, substate => substate.removeExternalAccountState);

export const getExternalAccPaymentListState = () =>
  createSelector(globalAccountState, substate => substate.getExternalAccPaymentListState);

export const getExternalAccBankTransactionsState = () =>
  createSelector(globalAccountState, substate => substate.getExternalAccBankTransactionsState);

export const getExternalAccountBalanceState = () =>
  createSelector(globalAccountState, substate => substate.getExternalAccountBalanceState);

export const linkExternalAccountState = () =>
  createSelector(globalAccountState, substate => substate.linkExternalAccountState);

export const unlinkExternalAccountState = () =>
  createSelector(globalAccountState, substate => substate.unlinkExternalAccountState);

export const getGlobalAccountListState = () =>
  createSelector(globalAccountState, substate => substate.getGlobalAccountListState);

export const getGlobalAccountDirectDebitEnableListState = () =>
  createSelector(globalAccountState, substate => substate.getGlobalAccountDirectDebitEnableListState);

export const getGlobalAccountListRefreshState = () =>
  createSelector(globalAccountState, substate => substate.getAccountGlobalListRefresh);

export const csvGetPaymentListState = () =>
  createSelector(globalAccountState, substate => substate.csvGetPaymentListState);

export const resetAccountTransferFormDataState = () =>
  createSelector(globalAccountState, substate => substate.resetAccountTransferFormDataState);

export const quickTransferFormDataState = () =>
  createSelector(globalAccountState, substate => substate.quickTransferFormData);
export const submitPadVerificationState = () =>
  createSelector(globalAccountState, substate => substate.submitPadVerificationState);

export const reAuthorizedExternalAccState = () =>
  createSelector(globalAccountState, substate => substate.reAuthorizedExternalAccState);
