import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontFamily: "Sofia Pro",
    fontSize: theme.spacing(1.75),
    lineHeight: `${theme.spacing(1.5)}px`,
    fontWeight: "600"
  },
  body: {
    fontSize: 14,
  },
  root: {
    borderBottom: "none",

  }
}))(TableCell);

export default function StyledTableHead(props) {
  const {
    classes,
    columns,
    expand,
  } = props;
  // const theme = useTheme();


  const headCells = columns;
  return (
    <TableHead>
      <TableRow>
        {
          expand ? <StyledTableCell /> : <></>
        }
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.filed}
            // align="center"
            padding={headCell.disablePadding ? 'none' : 'default'}
            className={classes}
          >
            {headCell.label}
          </StyledTableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}

StyledTableHead.propTypes = {
  classes: PropTypes.object,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  data: PropTypes.object,
  checkBox: PropTypes.bool,
};
