/* eslint-disable no-unused-vars */

import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getRoleListAction, getRoleListSetDataAction } from 'store/Reference/referenceActions';
import { getRoleListState } from 'store/Reference/referenceSelectors';
import { createFilterOptions } from '@material-ui/lab';

function SelectRole(props = {}) {
    // eslint-disable-next-line no-unused-vars

    const { roleState, getRole, propsRole, propsGetRole, onInputChange = () => null, value } = props;


    var options = propsRole || roleState;
    const getOptions = propsGetRole || getRole;

    const [role, setRole] = useState(value || "");
    const [isTypeOther, setIsTypeOther] = useState(false);
    const [otherVal, setOtherVal] = useState("");

    if (isTypeOther) {
        let filterOther = options.data.filter(i => i.designation_id === 23);
        let filterNonOther = options.data.filter(i => i.designation_id !== 23);
        options = { ...options, data: [...filterOther, ...filterNonOther] };
    }

    useEffect(() => {

        if (isTypeOther) {
            setOtherVal(value);
        }
        valueSetter(value);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);


    // eslint-disable-next-line no-unused-vars
    const valueSetter = (extractValue) => {

        const filterArray = extractValue ? options.data.filter(i => i.designation_name.toLowerCase().startsWith((extractValue).toLowerCase())) : [];
        if (filterArray.length === 0 && value) {
            setOtherVal(value);
            setIsTypeOther(true);
            setRole({ designation_name: value });
        }
        if (filterArray && filterArray.length > 0 && filterArray[0].designation_id === 23) {
            if (!isTypeOther) {
                setIsTypeOther(true);
                setRole(undefined);
            } else {
                setRole({ designation_name: otherVal });
            }
        } else {
            if (filterArray && filterArray.length > 0) {
                const checkExeat = options.data.filter(i => i.designation_name.toLowerCase() === extractValue.toLowerCase());
                if (checkExeat.length > 0) {
                    setIsTypeOther(false);
                    setRole(value);
                }
            }
        }
    };

    const onChangeHandler = (e, val) => {

        let extractValue = val && val.designation_name ? val.designation_name : val;

        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: extractValue
            }
        }, val);
    };

    const onInputChangeHandler = (_e, val, reason) => {
        // reason === 'input' && setInputOnlyString(val);
        // setInputValue(val);
        // setInputReason(reason);
        // onInputChange(_e, val, reason);
        onChangeHandler(_e, val, reason);
    };
    const filterOptions = createFilterOptions({
        ignoreCase: true,
    });

    return (<>
        <Autocomplete
            {...props}
            filterOptions={filterOptions}
            value={role}
            forcePopupIcon={true}
            disableClearable
            freeSolo={isTypeOther}
            placeholder={isTypeOther ? "Type Position/Role" : props.placeholder}
            getOptions={getOptions}
            getOptionDisabled={(option) => isTypeOther && option.designation_id === 23 && !otherVal}
            options={options}
            onChange={onChangeHandler}
            onInputChange={onInputChangeHandler}
            onBlur={props.onBlur}
            selectKey="designation_name"
            labelKey="designation_name"
            makeFocus={props.makeFocus}
            renderOption={(option) => {

                // if (isTypeOther && option.designation_id === 23 && !otherVal) {
                //     return (
                //         <React.Fragment>
                //             <span style={{ color: 'gray' }} >
                //                 Type manually
                //             </span>

                //         </React.Fragment>
                //     )
                // }
                if (isTypeOther && option.designation_id === 23) {
                    return (
                        <React.Fragment>
                            <span style={{ color: 'gray' }} >
                                Type manually
                            </span>

                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        {option.designation_name}
                    </React.Fragment>
                )

            }}
        />

    </>)
};


const mapStateToProps = createStructuredSelector({
    roleState: getRoleListState()
});

function mapDispatchToProps(dispatch) {
    return {
        getRole: data => dispatch(getRoleListAction(data)),
        setRoleOther: data => dispatch(getRoleListSetDataAction({ compId: "contact_person_position", data: [{ fixedOption: true, disabled: true, description: " " }], loading: false, error: '', reset: true })),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectRole,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.role) === JSON.stringify(nextProps.role) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus &&
            prevProps.otherVal === nextProps.otherVal &&
            prevProps.roleState === nextProps.roleState
        )
    )
);