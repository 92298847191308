import React, { memo, useCallback } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Button, Typography } from "components/ui";
import MfaImage from 'assets/images/Mfa.png'
import { AuthState } from '@aws-amplify/ui-components';

const useStyles = makeStyles((theme) => ({
  intro: {
    textAlign: "center",
  },
  linkText: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    textDecoration: "underline",
  },
}));

function MfaIntro(props = {}) {
  const { setStep, setAuthState } = props;
  const classes = useStyles();

  const onBack = useCallback(() => {
    setAuthState(AuthState.SignIn);
  }, [setAuthState]);

  // const onLearnMore = useCallback(() => {
  //   window.open('https://en.wikipedia.org/wiki/Multi-factor_authentication', "_blank")
  // }, []);

  return (
    <Grid
      container
      spacing={3}
      item
      justifyContent="center"
      direction="column"
    >
      <Grid item xs={12}>
        <div className={classes.intro}>
          <img alt="mfa" src={MfaImage} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.intro}>
          <Typography variant="h6">To Continue, Turn On Two-Factor Authentication</Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.intro}>
          <Typography variant="body1" color="grey700">
            We require the turning on of Two-Factor Authentication (2FA) to protect you and your information against risks associated with possible compromised passwords or data breaches.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Button
          color="secondary"
          title="Continue"
          onClick={() => setStep(1)}
        />
      </Grid>
      <Grid container spacing={3} item>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            title="Back"
            onClick={onBack}
          />
        </Grid>
        {/* <Grid item xs={6}>
              <Button
                variant="outlined"
                title="Learn More"
                onClick={onLearnMore}
              />
            </Grid> */}
      </Grid>
    </Grid>
  );
};

export default memo(MfaIntro)