const CONTACT_SCHEDULED_PAYMENTS = {
  code: "CONTACT_SCHEDULED_PAYMENTS",
  name: "Contact Schedule Payment",
  path: "",
  functions: {
    CONTACT_SCHEDULED_PAYMENTS_LIST: {
      code: "CONTACT_SCHEDULED_PAYMENTS_LIST",
      name: "Team Member List",
      isPage: false,
      component: "",
      path: "/:contactId/scheduled-payments",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_LIST.path}`,
      getFullPath: (contactId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_LIST.path}`.replace(
          ":contactId",
          contactId
        ),
    },
    CONTACT_SCHEDULED_PAYMENTS_FILTER: {
      code: "CONTACT_SCHEDULED_PAYMENTS_FILTER",
      name: "Contact Schedule Payments Filter",
      isPage: false,
      component: "",
      path: "/:contactId/scheduled-payments",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_FILTER.path}`,
      getFullPath: (contactId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_FILTER.path}`.replace(
          ":contactId",
          contactId
        ),
    },
    CONTACT_SCHEDULED_PAYMENTS_SEARCH: {
      code: "CONTACT_SCHEDULED_PAYMENTS_SEARCH",
      name: "Contact Schedule Payments Search",
      isPage: false,
      component: "",
      path: "/:contactId/scheduled-payments",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_SEARCH.path}`,
      getFullPath: (contactId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_SEARCH.path}`.replace(
          ":contactId",
          contactId
        ),
    },
    CONTACT_SCHEDULED_PAYMENTS_DETAILS: {
      code: "CONTACT_SCHEDULED_PAYMENTS_DETAILS",
      name: "Contact Scheduled Payments Details",
      isPage: false,
      component: "",
      path: "/:contactId/scheduled-payments/:eventScheduleId",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_DETAILS.path}`,
      getFullPath: (contactId, eventScheduleId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_DETAILS.path}`
          .replace(":contactId", contactId)
          .replace(":eventScheduleId", eventScheduleId),
    },
    CONTACT_SCHEDULED_PAYMENTS_DELETE: {
      code: "CONTACT_SCHEDULED_PAYMENTS_DELETE",
      name: "Contact Schedule Payments Delete",
      isPage: false,
      component: "",
      path: "/:eventScheduleId",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_DELETE.path}`,
      getFullPath: (eventScheduleId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_DELETE.path}`.replace(
          ":eventScheduleId",
          eventScheduleId
        ),
    },
    CONTACT_SCHEDULED_PAYMENTS_UPDATE: {
      code: "CONTACT_SCHEDULED_PAYMENTS_UPDATE",
      name: "Contact Schedule Payments Update",
      isPage: false,
      component: "",
      path: "/:eventScheduleId",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_UPDATE.path}`,
      getFullPath: (eventScheduleId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_UPDATE.path}`.replace(
          ":eventScheduleId",
          eventScheduleId
        ),
    },
    DETAILS: {
      code: "DETAILS",
      name: "Details",
      isPage: false,
      component: "",
      path: "/:contactId/bank-accounts",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.DETAILS.path}`,
      getFullPath: (contactId) =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.DETAILS.path}`.replace(
          ":contactId",
          contactId
        ),
    },
    CONTACT_SCHEDULED_PAYMENTS_SEND_NOW: {
      code: "CONTACT_SCHEDULED_PAYMENTS_SEND_NOW",
      name: "Send Now",
      isPage: false,
      component: "",
      path: "/",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_SEND_NOW.path}`,
      getFullPath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_SEND_NOW.path}`,
    },
    CONTACT_SCHEDULED_PAYMENTS_RESCHEDULE: {
      code: "CONTACT_SCHEDULED_PAYMENTS_RESCHEDULE",
      name: "Reschedule",
      isPage: false,
      component: "",
      path: "/",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_RESCHEDULE.path}`,
      getFullPath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_RESCHEDULE.path}`,
    },
    CONTACT_SCHEDULED_PAYMENTS_EDIT_RECURANCE: {
      code: "CONTACT_SCHEDULED_PAYMENTS_EDIT_RECURANCE",
      name: "Edit Recurrance",
      isPage: false,
      component: "",
      path: "/",
      getRoutePath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_EDIT_RECURANCE.path}`,
      getFullPath: () =>
        `${CONTACT_SCHEDULED_PAYMENTS.path}${CONTACT_SCHEDULED_PAYMENTS.functions.CONTACT_SCHEDULED_PAYMENTS_EDIT_RECURANCE.path}`,
    },
  },
};

export default CONTACT_SCHEDULED_PAYMENTS;
