
import { defineMessages } from 'react-intl';

export const scope = 'src.components.verification';

export default defineMessages({
    using_passport: {
        id: `${scope}.using_passport`,
        defaultMessage: 'Using Your Passport or U.S./Canadian Drivers License',
    },
    verify: {
        id: `${scope}.verify`,
        defaultMessage: ' Let’s Verify Your ID!',
    },
    one: {
        id: `${scope}.one`,
        defaultMessage: '1',
    },
    scanId: {
        id: `${scope}.scanId`,
        defaultMessage: 'Scan ID',
    },
    two: {
        id: `${scope}.two`,
        defaultMessage: '2',
    },
    takeSelfie: {
        id: `${scope}.takeSelfie`,
        defaultMessage: 'Take Selfie',
    },
    three: {
        id: `${scope}.three`,
        defaultMessage: '3',
    },
    addInfo: {
        id: `${scope}.addInfo`,
        defaultMessage: 'Add Info',
    },
    idMust: {
        id: `${scope}.idMust`,
        defaultMessage: 'ID must not be expired',
    },
    pose: {
        id: `${scope}.pose`,
        defaultMessage: 'Pose against a plain background',
    },
    photograph: {
        id: `${scope}.photograph`,
        defaultMessage: 'Photograph with even lighting',
    },
    noShadow: {
        id: `${scope}.noShadow`,
        defaultMessage: 'no shadows/backlight',
    },
    noPhoto: {
        id: `${scope}.noPhoto`,
        defaultMessage: 'No photos of a photo',
    },
    updateAddress: {
        id: `${scope}.updateAddress`,
        defaultMessage: 'Update Address',
    },
    uploadAdd: {
        id: `${scope}.uploadAdd`,
        defaultMessage: 'Upload Proof of This Address',
    },
    ex: {
        id: `${scope}.ex`,
        defaultMessage: 'Ex. Bank Statement, Utility Bill, Tax Receipt, Lease Agreement or Mortgage Statement ',
    },
    notHave: {
        id: `${scope}.notHave`,
        defaultMessage: 'I Don’t Have My Document on Me',
    },
    acceptInvite: {
        id: `${scope}.acceptInvite`,
        defaultMessage: ' has added you to their team, would you like to accept the invite?',
    },
    started: {
        id: `${scope}.started`,
        defaultMessage: 'Get Started',
    },
    continueBtn: {
        id: `${scope}.continueBtn`,
        defaultMessage: 'Continue',
    },
    createPass: {
        id: `${scope}.createPass`,
        defaultMessage: 'Create a password for your account',

    },
    preInstructionOne: {
        id: `${scope}.preInstructionOne`,
        defaultMessage: ' need to verify your ID and gather some information in order to issue your SmartLink expense account.',
    },
    preInstructionTwo: {
        id: `${scope}.preInstructionTwo`,
        defaultMessage: ' need to verify your ID and gather some information in order to give you access to their account and issue your SmartLink expense account.',
    },
    preInstructionThree: {
        id: `${scope}.preInstructionThree`,
        defaultMessage: ' needs to verify your ID quickly before giving you access to their account.',
    },
    mailingAddressSubTitle: {
        id: `${scope}.mailingAddressSubTitle`,
        defaultMessage: ' needs some information in order to assign you a SmartLink account. Please ensure the following information is correct and fill in any missing blanks.',
    },
    residentialAddress: {
        id: `${scope}.residentialAddress`,
        defaultMessage: 'Residential Address',
    },
    physicalCardTxt: {
        id: `${scope}.physicalCardTxt`,
        defaultMessage: 'Should you be sent a physical expense card, would you like to receive it at the address listed above?',
    },
    thankYou: {
        id: `${scope}.thankYou`,
        defaultMessage: 'Thank You!',
    },
    infoReceive: {
        id: `${scope}.infoReceive`,
        defaultMessage: 'Your information has been received and your SmartLink card is being processed. You can find any future updates under Accounts.',
    },
    welcome: {
        id: `${scope}.welcome`,
        defaultMessage: 'Welcome to Truly!',
    },
    infoReview: {
        id: `${scope}.infoReview`,
        defaultMessage: 'Your information has been reviewed, welcome to Truly Financial',
    },
    someQuick: {
        id: `${scope}.someQuick`,
        defaultMessage: 'Some Quick Tips To Help Get You Started:',
    },
    infoReviewed: {
        id: `${scope}.infoReview`,
        defaultMessage: 'Your information has been received, and is now being reviewed',
    },
    learnWait: {
        id: `${scope}.learnWait`,
        defaultMessage: 'Learn While You Wait:',
    },
});
