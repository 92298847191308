
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getCountryListAction } from 'store/Reference/referenceActions';
import { getCountryListState, getDestinationCountryListState } from 'store/Reference/referenceSelectors';
import { flag } from 'utils/flag';


const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        background: '#fafafa',
    },
    flagDiv: {
        // margin: theme.spacing(0.25, 0, 0, 0.5)
        margin: theme.spacing(-0.5, 0, 0, 0.5)
    }
}));

function countryToFlag(isoCode = "") {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : '';
}

function SelectCountry(props = {}) {
    const classes = useStyles();
    const [country, setCountry] = useState({});
    // const { allCountries, destinationCountries, onlyDestination } = props;
    const { countries } = props;
    // const countries = onlyDestination ? destinationCountries : allCountries;

    const setDefaultCountry = useCallback(() => {
        let defaultSelectedCountry = {};
        if (countries && Array.isArray(countries.data) && countries.data.length && props.value) {
            defaultSelectedCountry = countries.data.find(i => i.country_code === props.value) || {};
            onChange({}, defaultSelectedCountry);
        }
        // setCountry(defaultSelectedCountry);
        // onChange({}, defaultSelectedCountry);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries, props.value]);
    useEffect(() => {
        setDefaultCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries.data, setDefaultCountry]);
    useEffect(() => {
        if (props.value !== country.country_code) {
            setDefaultCountry();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const onChange = (e, val) => {
        setCountry({});
        setTimeout(() => {
            setCountry(val || {});
            props.onChange && props.onChange({
                target: {
                    id: props.id,
                    name: props.name,
                    value: val && val.country_code
                }
            }, val);
        }, 0);
    };
    return (<>
        <Autocomplete
            {...props}
            getOptions={props.getCountries}
            options={countries}
            onChange={onChange}
            selectKey="country_code"
            labelKey="country_name"
            makeFocus={props.makeFocus}
            helperText={props.helperText}
            onBlur={props.onBlur}
            startAdornment={(
                <React.Fragment>
                    {/* <span>{countryToFlag(props.value || '')}</span> &nbsp; */}
                    <span className={classes.flagDiv}><Avatar alt="flag" src={flag(country.country_code)} className={classes.small} /></span> &nbsp;
                </React.Fragment>
            )
            }
            renderOption={(option) => (
                <React.Fragment>
                    <span>{countryToFlag(option.country_code)}</span> &nbsp;
                    {option.country_name} ({option.country_code})
                </React.Fragment>
            )}
        />
    </>);
}

const mapStateToProps = (s, p) => {

    return createStructuredSelector({
        // allCountries: getCountryListState(),
        // destinationCountries: getDestinationCountryListState(),
        countries: p.onlyDestination ? getDestinationCountryListState() : getCountryListState(),
    });
};

function mapDispatchToProps(dispatch) {
    return {
        getCountries: data => dispatch(getCountryListAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectCountry,
    (prevProps, nextProps) => (
        // (JSON.stringify(prevProps.allCountries) === JSON.stringify(nextProps.allCountries) &&
        (JSON.stringify(prevProps.countries) === JSON.stringify(nextProps.countries) &&
            prevProps.value === nextProps.value &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus &&
            prevProps.disabled === nextProps.disabled
        )
    )
);