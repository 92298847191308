import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Typography } from 'components/ui';
import { Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/TeamMembers/intl/TeamMembersIntl';
import { Loader } from 'components';
import { getTermAndConditionState } from 'store/Register/registerSelectors';
import { getTermAndConditionAction } from 'store/Register/registerActions';
import { Skeleton } from '@material-ui/lab';
import parse from 'html-react-parser';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { acceptConfidentialityAgreementAction } from 'store/Verification/verificationActions';
import { acceptConfidentialityAgreementState } from 'store/Verification/verificationSelectors';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: theme.spacing(0, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            padding: theme.spacing(0, 2),
        },
        display: 'flex',
        flex: 1,
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: "column",
        flex: 1,
    },
    subContent: {
        display: 'grid',
        // height: '60vh'
    },
    align: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    card: {
        background: '#F2F3F4',
        borderRadius: '8px',
        margin: theme.spacing(0, 0, 3, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(0, 0, 3, 0),
        }
    },
    cardContent: {
        margin: theme.spacing(3, 7.5, 5.5, 7.5),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3),
        }
    },

    mainTitle: {
        margin: theme.spacing(3, 0, 3, 0),
    },
    subTitle: {
        margin: theme.spacing(2, 1, 3, 1),
        textAlign: 'center'

    },
    descTxt: {
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
        lineHeight: `${theme.spacing(2.625)}px`
    },
    descMargin: {
        marginTop: theme.spacing(3)
    },
    skeleton: {
        borderRadius: theme.spacing(1),
        height: theme.spacing(52),
        width: 'auto',
        flex: 1,
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            borderRadius: theme.spacing(1),
            height: theme.spacing(25),
            width: 'auto',
            flex: 1,
        },
    },
    lastMainGrid: {
        marginBottom: theme.spacing(3),
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-end'
        }
    },
    btnDiv: {
        marginBottom: theme.spacing(2),
    }
}));

function ConfidentialAgreement(props = {}) {
    const classes = useStyles();

    const { data, onStepChange, getTermAndConditionState, getTermAndConditionAction, acceptConfidentialityAgreementState, acceptConfidentialityAgreement, isLoading } = props;

    const confidentialAgreementData = getTermAndConditionState?.data?.find(i => i.name === 'CONFIDENTIAL_AGREEMENT') || {};
    const agreement = confidentialAgreementData.description || '';
    const isLoadingData = isLoading || (getTermAndConditionState?.loading) || (acceptConfidentialityAgreementState?.loading)

    useEffect(() => {

        if (!agreement) {
            getTermAndConditionAction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSuccessCallBack = () => {
        onStepChange();
    };

    const onContinueClick = () => {
        acceptConfidentialityAgreement({ personId: data.entity_person_id, token: data.continue_signup_token, cb: onSuccessCallBack });
    };

    return (<div className={classes.root}>

        {
            isLoadingData && <Loader />
        }
        <Grid justifyContent="center" direction='col' className={classes.content}>

            <Typography className={classes.mainTitle} variant={"h6"} color="grey700"><FormattedMessage {...intl.confidentialAgreementTitle} /></Typography>
            {/* <Typography className={classes.subTitle} variant={"subtitle2"} color="grey400"><FormattedMessage {...intl.confidentialAgreementSubTitle} /></Typography> */}

            <Grid container className={classes.card} justifyContent="center">
                {

                    getTermAndConditionState && getTermAndConditionState.loading && getTermAndConditionState.data.length === 0 ?
                        <Skeleton className={classes.skeleton} variant="rect" />
                        : <div className={classes.cardContent}>
                            <Typography className={classes.descTxt} variant="subtitle1" color="grey600">{parse(agreement)}</Typography>
                        </div>

                }

            </Grid>
            <Grid container justifyContent="center" className={classes.lastMainGrid}>

                <Button
                    color="secondary"
                    disabled={isLoadingData}
                    size="small" onClick={onContinueClick}
                    className={classes.btnDiv}
                >
                    <div className={classes.align}>
                        <FormattedMessage {...intl.confidentialAgreementContinue} />
                        {/* <NavigateNextIcon /> */}
                    </div>
                </Button>

            </Grid>
        </Grid>
    </div>);
}

const mapStateToProps = createStructuredSelector({
    getTermAndConditionState: getTermAndConditionState(),
    acceptConfidentialityAgreementState: acceptConfidentialityAgreementState(),

});

function mapDispatchToProps(dispatch) {
    return {
        acceptConfidentialityAgreement: data => dispatch(acceptConfidentialityAgreementAction(data)),
        getTermAndConditionAction: data => dispatch(getTermAndConditionAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(ConfidentialAgreement);
