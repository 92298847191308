import React, { memo, useImperativeHandle, useState, } from "react";
import { makeStyles } from "@material-ui/core";
import StyledDialog from 'components/StyledDialog';
// import { StyledIframe } from "components";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Typography } from "components/ui";
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },

}));


function TermConditionDialog(props = {}, ref) {
    // eslint-disable-next-line no-unused-vars
    const { onClose, agreement = {} } = props;
    const classes = useStyles();

    const [open, setOpen] = useState(false);


    const onClosePopup = () => {
        setOpen(false);
        onClose && onClose();
    };

    const onToggle = () => {
        setOpen(!open);
    };

    useImperativeHandle(ref, () => ({
        onToggle,
    }));


    return (
        <>
            <StyledDialog
                title={"Term & Conditions"}
                header={true}
                backBtn={false}
                open={open}
                type="content"
                noFullScreen={false}
                footer={false}
                disableBackdropClick={true}
                onClose={onClosePopup}
                padding={true}
                closeIcon={true}
                minHeight={true}

            ><>
                    <Typography color="grey600" className={classes.root} variant="body1">{parse(agreement)}</Typography>

                </>
            </StyledDialog>

        </>
    );
}


const mapStateToProps = createStructuredSelector({


});

function mapDispatchToProps(dispatch) {
    return {

    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
);

export default compose(
    withConnect,
    memo,
)(React.forwardRef(TermConditionDialog));