
import React, { memo } from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 40,
    width: 40,
    // marginRight: theme.spacing(2),
  },
}));

function UserProfile() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar
        className={classes.avatar}
        alt="Dp"
        src=""
      />
    </div>
  );
}

UserProfile.propTypes = {};

export default memo(UserProfile);
