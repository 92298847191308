const SMARTLINK_SETTINGS = {
    code: "SMARTLINK_SETTINGS",
    name: "Smartlink Settings",
    path: "",
    functions: {
        TEAM_MEMBERS_SMARTLINK_SETTINGS: {
            code: "TEAM_MEMBERS_SMARTLINK_SETTINGS",
            name: "Smartlink Settings",
            isPage: false,
            component: 'SettingsPage',
            path: "/:entity_person_id/smartlink-accounts",
            getRoutePath: () => `${SMARTLINK_SETTINGS.path}${SMARTLINK_SETTINGS.functions.TEAM_MEMBERS_SMARTLINK_SETTINGS.path}`,
            getFullPath: (entityPersonId) => `${SMARTLINK_SETTINGS.path}${SMARTLINK_SETTINGS.functions.TEAM_MEMBERS_SMARTLINK_SETTINGS.path}`.replace(
                ":entityPersonId",
                entityPersonId
            ),
        },

    },


};

export default SMARTLINK_SETTINGS;
