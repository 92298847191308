import React, { memo, useCallback } from "react";
import { FormControlLabel, Grid, ListItem, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { Button, Typography } from "components/ui";
import { styledSnackbar } from "containers/Common/Snackbar";

const useStyles = makeStyles((theme) => ({
  txt: {
    textAlign: "left",
  },
  recommended: {
    color: theme.palette.successGreen,
  },
  labelHint: {
    // paddingLeft: theme.spacing(3.75),
    // marginTop: theme.spacing(-1),
  },
  mfaOptions: {
    padding: theme.spacing(1.5),
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: "#EFF7FA",
    },
  },
  linkText: {
    margin: theme.spacing(2, 0),
    textAlign: "center",
    textDecoration: "underline",
  },
  methodName: {
    paddingTop: '9px',
  },
  methodItem: {
    borderRadius: theme.spacing(2),
    margin: '1px',
    "&:hover": {
      backgroundColor: "#EFF7FA",
    },
  },
  method: {
    display: "flex",
    alignItems: "flex-start",
  },
  radio: {
    color: theme.palette.secondary.main
  }
}));

function MfaOptions(props = {}) {
  const classes = useStyles();
  const { mfaType, setMfaType, setStep, getQrCode, verifyNoMFA, allowDisableMfa } = props;

  const onBack = useCallback(() => {
    setStep(0)
  }, [setStep]);

  const handleMfaTypeChange = useCallback((event) => {
    setMfaType(event.target.value);
  }, [setMfaType]);

  const handleMfaTypeSubmit = useCallback((e) => {
    e.preventDefault && e.preventDefault();
    if (mfaType === 'TOTP') {
      getQrCode();
    } else if (mfaType === 'SMS') {
      setStep(4);
    } else if (mfaType === 'NOMFA') {
      verifyNoMFA();
    } else {
      styledSnackbar.error('Select MFA type to verify');
    }
  }, [mfaType, getQrCode, setStep, verifyNoMFA]);

  return (<div>
    <form onSubmit={handleMfaTypeSubmit} autoComplete="off">
      <Grid
        container
        spacing={3}
        item
        direction="column"
      >
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.txt} >Select a Security Method</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="grey500" className={classes.txt}>Any time you log in from a phone or computer we do not recognize, we’ll ask you for your password and login code.</Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup className={classes.mfaOptions} required aria-label="mfa" name="mfa1" value={mfaType} onChange={handleMfaTypeChange}>
            <ListItem selected={mfaType === 'TOTP'} className={classes.methodItem} subheader={<li />}>
              <FormControlLabel className={classes.method} value="TOTP" control={<Radio className={classes.radio} />} label={<div>
                <Typography className={classes.methodName} variant="subtitle1">
                  Authentication App -<span className={classes.recommended}> Recommended</span>
                </Typography>
                <Typography variant="body2" color="grey700" className={classes.labelHint}>Use a free app like Google Authenticator or Duo Mobile to generate verification codes for extra protection.</Typography>
              </div>} />
            </ListItem>
            <ListItem selected={mfaType === 'SMS'} className={classes.methodItem}>
              <FormControlLabel className={classes.method} value="SMS" control={<Radio className={classes.radio} />} label={<div>
                <Typography className={classes.methodName} variant="subtitle1">
                  Text Message (SMS)
                </Typography>
                <Typography variant="body2" color="grey700" className={classes.labelHint}>Receive verification codes via SMS.</Typography>
              </div>} />
            </ListItem>
            {allowDisableMfa && <><br /><FormControlLabel value="NOMFA" control={<Radio className={classes.radio} />} label="Disable MFA" /></>}
          </RadioGroup>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              title="Back"
              onClick={onBack}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              title="Continue"
              disabled={!mfaType}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  </div>);
}

export default memo(MfaOptions)