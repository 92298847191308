import { Loader, StyledError, TrulyLoader } from "components";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { getCustomerDetailsAction, resendInvitationLinkAction } from "store/Register/registerActions";
import { getCustomerDetailsState, resendInvitationLinkState } from "store/Register/registerSelectors";
import {
    getAccessToken, getLastAuthUser,
    // setAccessToken, setEntityId, setLastAuthUser,
    setRegistrationLocalData,
    // setRegistrationToken,
} from "utils/auth";
import { useQuery } from "utils/hooks";
import CompanyStatusPending from "./CompanyStatusPending";
import CompanyStatusSubmitted from "./CompanyStatusSubmitted";
import CompanyStatusVerified from "./CompanyStatusVerified";
import { ENTITY_STATUS } from '@truly-financial/common/consts/entity.consts';
import CompanyStatusUserReview from "./CompanyStatusUserReview";
import CompanyStatusEdit from "./CompanyStatusEdit";
import RequestExtraInfo from "./RequestExtraInfo";
// import TeamMemberIdVerification from "containers/IdVerification/Verification/TeamMemberIdVerification";
// import { ENTITY_PERSON_TYPE } from "@truly-financial/common/consts/entity-person.consts";


export function RegistrationComplete(props = {}) {
    const { getCustomerDetails, getCustomerDetailsState,
        resendInvitationLinkAction, resendInvitationLinkState } = props;
    let query = useQuery();
    const t = query.get('token');
    const uid = query.get('uid');
    const eid = query.get('id');
    const history = useHistory();
    // const [isApiCalled, setApiCalled] = useState(false);
    const getCustomerDetailsData = getCustomerDetailsState.data || {};
    const isRegistrationDone = (getCustomerDetailsData.prs_user && getCustomerDetailsData.isAllStepsCompleted) ? true : false;
    if ((!t || !uid) && !getAccessToken()) {
        history.push('/login');
    }
    useEffect(() => {
        getDetailsHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getCustomerDetailsData.prs_user && !isRegistrationDone && [ENTITY_STATUS.INITIATED].includes(getCustomerDetailsData.entity_status) && !getCustomerDetailsData.allowRegistrationDataEdit) {
            const search = window.location.search || '';
            history.push(`/registration-summary${search}`);
        }
        if (getCustomerDetailsData.prs_user && isRegistrationDone && [ENTITY_STATUS.INITIATED, ENTITY_STATUS.REGISTRATION_SUBMITTED].includes(getCustomerDetailsData.entity_status)) {
            const search = window.location.search || '';
            history.push(`/complete-registration${search}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCustomerDetailsData, isRegistrationDone])

    const getDetailsHandler = async () => {
        if (uid && uid !== getLastAuthUser()) {
            const ld = await setRegistrationLocalData({ token: t, uid, eid });
            ld && getCustomerDetails();
            // setApiCalled(true);
        } else {
            getCustomerDetails();
            // setApiCalled(true);
        }
    };

    const onResendLink = (currentItem) => {
        if (currentItem.entity_person_id) {
            resendInvitationLinkAction({ id: currentItem.entity_person_id });
        }
    };

    if (getCustomerDetailsState.loading && getCustomerDetailsState.data && Object.keys(getCustomerDetailsState.data).length === 0) {
        return (<TrulyLoader />);
    }


    return (<>
        {(resendInvitationLinkState && resendInvitationLinkState.loading) && <Loader componentCenter />}
        {getCustomerDetailsData.entity_status && ![ENTITY_STATUS.INITIATED, ENTITY_STATUS.REGISTRATION_SUBMITTED, ENTITY_STATUS.UNDER_EDD_PROCESS, ENTITY_STATUS.UNDER_COMPLIANCE_REVIEW, ENTITY_STATUS.APPROVED, ENTITY_STATUS.APPROVED_NO_TRADING, ENTITY_STATUS.REJECTED].includes(getCustomerDetailsData.entity_status) && <StyledError
            data={{ error: { message: 'Business Profile status is Unknown!' } }}
            retry={() => window.location.reload()}
        />}
        {getCustomerDetailsData.allowRegistrationDataEdit && [ENTITY_STATUS.INITIATED].includes(getCustomerDetailsData.entity_status) && <CompanyStatusEdit
            customerDetails={getCustomerDetailsState}

        />}
        {[ENTITY_STATUS.INITIATED].includes(getCustomerDetailsData.entity_status) && !getCustomerDetailsData.allowRegistrationDataEdit && <CompanyStatusPending
            onClose={() => { getDetailsHandler() }}
            customerDetails={getCustomerDetailsState}
            onResendLinkAction={(currentItem) => { onResendLink(currentItem) }}
        />}
        {[ENTITY_STATUS.REGISTRATION_SUBMITTED].includes(getCustomerDetailsData.entity_status) && !getCustomerDetailsData.allowRegistrationDataEdit && <CompanyStatusUserReview
            customerDetails={getCustomerDetailsState}
            onResendLinkAction={(currentItem) => { onResendLink(currentItem) }}
        />}
        {[ENTITY_STATUS.UNDER_COMPLIANCE_REVIEW, ENTITY_STATUS.APPROVED, ENTITY_STATUS.APPROVED_NO_TRADING].includes(getCustomerDetailsData.entity_status) && getCustomerDetailsData.isRegExtraInfo && <RequestExtraInfo
            customerDetails={getCustomerDetailsState}
            onResendLinkAction={(currentItem) => { onResendLink(currentItem) }}
        />}
        {[ENTITY_STATUS.UNDER_EDD_PROCESS, ENTITY_STATUS.UNDER_COMPLIANCE_REVIEW].includes(getCustomerDetailsData.entity_status) && !getCustomerDetailsData.isRegExtraInfo && <CompanyStatusSubmitted
            customerDetails={getCustomerDetailsState}
            onResendLinkAction={(currentItem) => { onResendLink(currentItem) }}
        />}

        {[ENTITY_STATUS.APPROVED, ENTITY_STATUS.APPROVED_NO_TRADING].includes(getCustomerDetailsData.entity_status) && !getCustomerDetailsData.isRegExtraInfo && <CompanyStatusVerified
            customerDetails={getCustomerDetailsState}
            onResendLinkAction={(currentItem) => { onResendLink(currentItem) }}
        />}

        {getCustomerDetailsData.entity_status === ENTITY_STATUS.REJECTED && <StyledError
            data={{ error: { message: 'Business Profile status is Rejected!' } }}
        />}

    </>);
}

const mapStateToProps = (s, p) => {
    return createStructuredSelector({
        getCustomerDetailsState: getCustomerDetailsState(),
        resendInvitationLinkState: resendInvitationLinkState(),
    });
};

function mapDispatchToProps(dispatch) {
    return {
        getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
        resendInvitationLinkAction: (data) => dispatch(resendInvitationLinkAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(RegistrationComplete);