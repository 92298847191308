/* eslint-disable no-unused-vars */

import React, { createRef, memo, useRef, useState } from 'react';
import { Avatar, Grid, IconButton, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Loader
  // , OptionMenu
} from 'components';
import { FormattedMessage } from 'react-intl';
import intl from './intl/RegistrationCompleteDashboard';
import { Typography, Button } from 'components/ui';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { getAvatarTxt } from 'utils/common';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { GotoTrulyButton } from './GotoTrulyButton';
import completeReg from 'assets/images/completeReg.png';
import { useLocation } from 'react-router';
import { ENTITY_PERSON_TYPE } from '@truly-financial/common/consts/entity-person.consts';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IdVerificationIframeDialog from './IdVerificationIframeDialog';

const idVerificationStatusMap = {
  PASS_PROCESSED: 'Verified',
  FAIL_PROCESSED: 'Failed',
  PENDING_NEW: 'Pending',
  PENDING_PROCESSED: 'ID Verification submitted',
  PENDING_RETRY: "Needing Reverification",
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5.25, 18, 0, 18),
      backgroundColor: theme.palette.background.default,
    },
    backgroundColor: theme.palette.primary.contrastText,
    width: "100%",
    flex: 1,
    minHeight: 'calc(100vh)',
  },
  rootExtraPadding: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 18.875),
      backgroundColor: theme.palette.background.default,
    },
    backgroundColor: theme.palette.primary.contrastText,
    width: "100%",
  },
  main: {
    display: "flex",
    padding: theme.spacing(0, 2),
    flex: 1,
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: theme.spacing(1.5),
      borderTopRightRadius: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      flexFlow: "column",
    },
    backgroundColor: theme.palette.primary.contrastText,
  },

  left: {
    flex: 1,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      overflow: "auto",
    },
  },
  content: {
    textAlign: "center",
  },
  titleTxt: {
    marginTop: theme.spacing(12.25),
  },
  setupTxt: {
    margin: theme.spacing(1, "auto", 1, "auto"),
  },
  progress: {
    height: theme.spacing(1),
    backgroundColor: theme.palette.grey[50],
    alignSelf: "center",
    borderRadius: theme.spacing(1),
  },
  barColorSecondary: {
    background: `linear-gradient(90.6deg, #F3D1B3 0.38%, #8CDFBA 99.37%)`,
    borderRadius: theme.spacing(1),
  },
  statusDiv: {
    height: theme.spacing(1),
    width: theme.spacing(38.375),
    display: "inline-block",
  },
  descriptionTxt: {
    margin: theme.spacing(6.75, 12, 5, 12),
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(4, 1, 4, 1),
    }
  },
  listMain: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey100}`,
    padding: theme.spacing(2, 2, 2, 2),
    maxWidth: theme.spacing(61.25),
    textAlign: "left",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '16px'
  },
  pendingTxt: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  listDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(2, 0, 0, 0),
    flexWrap: 'wrap',
  },
  rightIcon: {
    fontSize: theme.spacing(3),
    color: theme.palette.successGreen,
    width: 24,
    height: 24,
  },
  row: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(0, 0, 0, 1),
  },
  iconButton: {
    fontSize: theme.spacing(2),
    padding: "0px !important",
  },
  dotIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey500,
    fontSize: theme.spacing(2),
  },
  copyIcon: {
    fontSize: theme.spacing(2),
    padding: "0px !important",
    color: theme.palette.grey500,
  },
  nameDiv: {
    margin: theme.spacing(0, 0, 0, 2),
  },
  btnDiv: {
    display: "flex",
    height: theme.spacing(3.8),
    // margin: theme.spacing(.5),
    // flex: 1
    // margin: theme.spacing(4, 'auto', 0, 'auto')
  },
  avatar: {
    width: 24,
    height: 24,
  },
  btn: {
    margin: theme.spacing(3, 3, 0, 0),
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(3, 0),
    }

  },
  centerImg: {
    width: theme.spacing(29.875),
    height: theme.spacing(19.625),
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(3, 0),
    }
  },
  descriptionTxtMrg: {
    marginTop: theme.spacing(2),
  },
  roundTxt: {
    color: theme.palette.primary.main,
  },
  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pendingInfo: {
    marginTop: theme.spacing(17.75),
  },
  rootGridHold: {
    flex: 1,
  },

}));

function CompanyStatusPending(props = {}) {
  const classes = useStyles();
  const { customerDetails, onResendLinkAction, /*onInviteAsTeamAction*/ onClose } = props;
  const customerDetailData = customerDetails.data || {};
  const OptionMenuRef = createRef();
  // eslint-disable-next-line no-unused-vars
  const [currentItem, setCurrentItem] = useState({});
  const location = useLocation();
  const checkPage = location.pathname === "/registration-complete" ? true : false;
  const IdVerificationIframeDialogRef = useRef(null);


  const onResendLink = (item) => {
    if (item) {
      onResendLinkAction(item);
    }

  };

  const copyUrlLink = (item) => {

    if (item.entity_person_invitation_url) {
      navigator.clipboard.writeText(item.entity_person_invitation_url);
      styledSnackbar.success("Copied");

    }
  };

  // const onInviteAsTeam = () => {
  //   if (currentItem) {
  //     onResendLinkAction && onResendLinkAction(currentItem);
  //     OptionMenuRef.current.handleClose();
  //   }

  // };

  const optionMenuRender = (item) => {
    const mResendLink = { label: 'Resend Link', onClick: onResendLink };
    const mSendLink = { label: 'Send Link', onClick: onResendLink };
    const mCopy = { label: 'Copy Link URL', onClick: copyUrlLink };
    // const mInvite = { label: 'Invite as Team Member', onClick: onInviteAsTeam };
    if (item.entity_person_invitation_url) {
      return [
        mResendLink,
        mCopy,
      ];
    } else {
      return [mSendLink];
    }

  };
  const onMenuOpen = (e, item) => {
    setCurrentItem(item);
    OptionMenuRef.current.handleClick(e);
  };
  const onRedirect = (item) => {
    setCurrentItem(item);
    if (IdVerificationIframeDialogRef && IdVerificationIframeDialogRef.current && IdVerificationIframeDialogRef.current.onToggle) {
      IdVerificationIframeDialogRef.current.onToggle();
    }
  };

  const onCloseDialog = () => {
    onClose && onClose();
  };

  // if () {
  //   return (<Loader componentCenter />);
  // }

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.root} >
      {
        (!customerDetailData || customerDetails.loading) && <Loader componentCenter />
      }
      <div className={checkPage ? classes.main : classes.main}>
        <div className={classes.left}>
          <Grid justifyContent="center" className={classes.content} container >
            <Grid item xs={12} md={12}>
              <Grid className={classes.btn} justifyContent="flex-end" >
                <GotoTrulyButton />
              </Grid>
              {checkPage ? <>
                <img src={completeReg} className={classes.centerImg} alt="complete_registration" />
                <Typography color="grey700" variant="h2"  > <FormattedMessage {...intl.welcome} /> </Typography>
              </> :
                <Typography className={classes.pendingInfo} color="grey700" variant="h2"  > <FormattedMessage {...intl.pendingInfo} /> </Typography>}
              <div className={classes.col} >
                <Typography color="grey700" variant="titleMobile" className={classes.setupTxt}>
                  <><FormattedMessage {...intl.setupText} /> 90% <FormattedMessage {...intl.setupTextBreak} /></>
                </Typography>
                <div className={classes.statusDiv}>
                  <LinearProgress classes={{
                    root: classes.progress,
                    barColorSecondary: classes.barColorSecondary
                  }} variant="determinate" color="secondary" value={90} />
                </div>
              </div>

              <div className={classes.descriptionTxt}>
                <Typography color="grey700" variant="body1"> <FormattedMessage {...intl.description} /> </Typography>
                {/* <Typography color="grey700" variant="body1"> <FormattedMessage {...intl.descriptionBreak1} /> </Typography>

                <Typography color="grey700" variant="body1" className={classes.descriptionTxtMrg} > <FormattedMessage {...intl.descriptionBreak3} /> </Typography>
                <Typography color="grey700" variant="body1"> <FormattedMessage {...intl.descriptionBreak4} /> </Typography> */}

              </div>
              {
                <div className={classes.listMain} >
                  <Typography color="grey500" variant="overline" className={classes.pendingTxt} ><FormattedMessage {...intl.pendingDetails} /></Typography>
                  {
                    customerDetailData && customerDetailData.entity_persons && customerDetailData.entity_persons.length > 0 && customerDetailData.entity_persons.map((item, index) => {
                      if (!item.invitation_allowed) {
                        return null;
                      }
                      return (
                        <div key={index} className={classes.listDiv}>
                          <div className={classes.row} >
                            {
                              (item.entity_person_id_verification_status === 'PASS_PROCESSED') ?
                                <CheckCircleOutlineIcon className={classes.rightIcon} />
                                :
                                <Avatar
                                  className={classes.avatar}
                                  classes={{ colorDefault: classes.avatarColor }}
                                  alt="dp"
                                >
                                  <Typography className={classes.roundTxt} variant="caption" >{getAvatarTxt(item, "entity")}</Typography>
                                </Avatar>
                            }
                            <div className={classes.nameDiv} >
                              <Typography color="grey700" variant="body1" >{item.entity_person_first_name} {item.entity_person_last_name}</Typography>
                              {/* <Typography color={!item.entity_person_id_verification_status === 'PASS_PROCESSED' ? "grey300" : "tertiary_one700"} variant="caption" >{(item.entity_person_status === ENTITY_PERSON_STATUS.INVITATION_SENT || item.entity_person_status === ENTITY_PERSON_STATUS.DISABLED) ? <FormattedMessage {...intl.verificationPending} /> : <FormattedMessage {...intl.verified} />}</Typography> */}
                              <Typography color={item.entity_person_id_verification_status !== 'PASS_PROCESSED' ? (item.entity_person_id_verification_status === "FAIL_PROCESSED" || item.entity_person_id_verification_status === "PENDING_RETRY") ? "error" : "grey300" : "tertiary_one700"} variant="caption" >{idVerificationStatusMap[item.entity_person_id_verification_status] || idVerificationStatusMap.PENDING_NEW}</Typography>
                            </div>
                          </div>
                          {!['PASS_PROCESSED', 'PENDING_PROCESSED', "FAIL_PROCESSED"].includes(item.entity_person_id_verification_status) &&
                            item.entity_person_type === ENTITY_PERSON_TYPE.PRIMARY_CONTACT ?
                            <div  >
                              <Button
                                color="secondary"
                                size="small"
                                onClick={() => { onRedirect(item) }}
                                className={classes.btnDiv}
                              >

                                Verify Your ID Now
                              </Button>
                              <IdVerificationIframeDialog
                                ref={IdVerificationIframeDialogRef}
                                url={currentItem.entity_person_invitation_url + "&isDialog=1"}
                                onClose={onCloseDialog}
                                data={item}
                                id={currentItem.entity_person_id}
                                token={currentItem.continue_signup_token}
                              />
                            </div>

                            :

                            !['PASS_PROCESSED', 'PENDING_PROCESSED'].includes(item.entity_person_id_verification_status) &&
                            <div className={classes.row} >
                              <Tooltip title="Resend Link">
                                <IconButton onClick={(e) => { onResendLink(item) }} className={classes.iconButton}>
                                  <EmailOutlinedIcon className={classes.dotIcon} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Copy">
                                <IconButton onClick={(e) => { copyUrlLink(item) }} className={classes.iconButton}>
                                  <FileCopyOutlinedIcon className={classes.copyIcon} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          }
                        </div>
                      );
                    })
                  }

                  {/* <OptionMenu
                    ref={OptionMenuRef}
                    optionMenus={optionMenuRender(currentItem)}
                  /> */}
                </div>
              }
            </Grid>

          </Grid>
        </div>
      </div>

    </Grid >
  );
}


export default memo(CompanyStatusPending);
