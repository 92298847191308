import { withStyles } from "@material-ui/core";
import { Typography } from 'components/ui';
const PageTitle = withStyles((theme) => ({
  root: {
    fontFamily: 'Sabon',
    fontSize: theme.spacing(4),
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: `${theme.spacing(5.625)}`,
    letterSpacing: '0em',
    color: theme.palette.primary.main,
  },
}))(Typography);

export default PageTitle;