/* eslint-disable no-useless-concat */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Typography } from 'components/ui';
import { ArrowForwardIos } from '@material-ui/icons';
// import { flag } from 'utils/flag';
// import SmallAvatar from 'components/withStyledComp/SmallAvatar';
import DisplayPictureWithCountry from 'components/DisplayPictureWithCountry';
// import ContactHandler from './ContactHandler';
import combineClx from 'utils/combineClx';
import { getAvatarTxt } from 'utils/common';
import {
    ENTITY,

} from "@truly-financial/common/consts";

const useStyles = makeStyles(theme => ({
    middleDiv: props => ({
        [theme.breakpoints.up('sm')]: {
            display: !props.mobileListView ? "flex" : 'block',
            flexWrap: "wrap",
        },

        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
    }),
    badge: {
        marginRight: theme.spacing(1.2),
        [theme.breakpoints.down('xs')]: {
            '& .MuiBadge-anchorOriginBottomRightCircle': {
                right: '18%',
                bottom: '50%'
            }
        },

    },
    directionRow: props => ({
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
        },
        alignItems: props.mobileListView ? "flex-start" : 'none',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        paddingTop: !props.mobileListView ? theme.spacing(1) : 'none',
        paddingBottom: !props.mobileListView ? theme.spacing(1.5) : 'none',
    }),
    wrapperDiv: props => ({
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
            borderBottom: !props.mobileListView ? `1px solid ${theme.palette.grey[50]}` : 'none',
        },
        alignItems: props.mobileListView ? "center" : 'none',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        // borderBottom: !props.mobileListView ? `1px solid ${theme.palette.grey[50]}` : 'none',

    }),
    nameTxt: props => ({
        [theme.breakpoints.up('sm')]: {
            // minWidth: theme.spacing(22.5),
        },
        overflow: "hidden",
        textOverflow: "ellipsis",

        whiteSpace: "nowrap"
    }),
    combineTxt: props => ({
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        // maxWidth: '25%',
    }),

    lightTxt: {
        color: theme.palette.grey[300],
    },
    forwardArrow: {
        display: 'flex',
        fontSize: theme.spacing(1.875),
        color: theme.palette.grey[500],
        marginTop: 5,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
    },
    handleDiv: props => ({

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        [theme.breakpoints.up('md')]: {
            justifyContent: !props.mobileListView ? "center" : 'none',
        },
        width: '1vw', // need to fix

    }),
    rightAlign: {
        textAlign: 'right',
    },
    emailDiv: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: '2vw',
            minWidth: theme.spacing(22.5),
        },
        [theme.breakpoints.up('md')]: {
            minWidth: theme.spacing(25),
        },
        justifyContent: "flex-end",
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },

    handlerDivBg: {
        minHeight: theme.spacing(2.875),
        padding: theme.spacing(0, 2, 0, 2),
        borderRadius: theme.spacing(2),
        backgroundImage:
            'linear-gradient(97.05deg, #F3D1B3 2.81%, #A5D8C5 99.34%)',
        alignItems: 'flex-end',
        display: 'flex',
        margin: theme.spacing(0, .5),
        minWidth: 'max-content'
    },
    blackBorder: {
        alignSelf: 'center',
        minHeight: theme.spacing(2.875),
        padding: theme.spacing(0, 2, 0, 2),
        borderRadius: theme.spacing(2),
        border: `1px solid ${theme.palette.primary.main}`,
        alignItems: 'flex-end',
        display: 'flex',
        minWidth: 'max-content',
    },
    nameDiv: {
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '73vw',
        },
        minWidth: theme.spacing(18.75),
        // maxWidth: '35vh',
        maxWidth: '40vh',
    },
    handlerResTxt: {
        fontSize: '0.9vw'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
}));

function ContactRow(props = {}) {
    const classes = useStyles(props);
    const { contact, mobileListView } = props;
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.down('sm'));
    const xsMatches = useMediaQuery(theme.breakpoints.down('xs'));
    const mdMatches = useMediaQuery(theme.breakpoints.up('md'));


    let email = contact.email ? contact.email : "";
    let companyName = contact.company_name ? contact.company_name : "";


    return (<>
        <div className={classes.wrapperDiv}>
            <div className={classes.directionRow}>
                <DisplayPictureWithCountry mobileListView={mobileListView} countryCode={contact.country_code || contact.mobile_country_code} imageUrl={contact.image_url} companyName={getAvatarTxt(contact)} />
                <div className={classes.middleDiv}>
                    <div className={classes.nameDiv} >
                        <div  >
                            <Typography className={classes.nameTxt} color="grey700" variant={!mdMatches || mobileListView ? "titleMobile" : "subtitle1"}>{contact.prs_display_primary_name}</Typography>
                        </div>

                        <div>
                            {
                                !mobileListView && mdMatches ? <Typography className={classes.nameTxt} color="grey300" variant="body2">{contact.prs_display_secondary_name}</Typography> :
                                    <div>
                                        {
                                            contact.entity_type === ENTITY.ENTITY_TYPE.COMPANY ?
                                                <div className={classes.row}>
                                                    <Typography className={classes.combineTxt} variant="body2" color="grey300"> {contact.prs_display_secondary_name}</Typography>
                                                    {contact.prs_display_secondary_name && <Typography variant="body2" color="grey300">&nbsp;|&nbsp;</Typography>}
                                                    <Typography className={classes.combineTxt} variant="body2" color="grey300"> {email}</Typography>
                                                </div>
                                                : <div className={classes.row}>
                                                    <Typography className={classes.combineTxt} variant="body2" color="grey300"> {companyName}</Typography>
                                                    {companyName && <Typography variant="body2" color="grey300">&nbsp;|&nbsp;</Typography>}
                                                    <Typography className={classes.combineTxt} variant="body2" color="grey300"> {email}</Typography>
                                                </div>
                                        }

                                    </div>
                            }

                        </div>
                    </div>
                    {/* Hide this for now its will come in future */}
                    {/* <ContactHandler handler={contact.handle || ""} mobileView={props.mobileListView} roundBorder={true} /> */}

                    {
                        !smMatches && !xsMatches && !mobileListView ?
                            <div className={classes.emailDiv}>
                                <Typography
                                    variant="body1" color="grey300" className={combineClx(classes.nameTxt, classes.rightAlign)}
                                >
                                    {email}
                                </Typography>
                            </div>
                            : <></>
                    }
                </div>
                {
                    !smMatches && !xsMatches && !mobileListView ?
                        <ArrowForwardIos className={classes.forwardArrow} /> : <></>
                }


            </div>
        </div>
    </>);
}
ContactRow.defaultProps = {
    contact: {},
    mobileListView: false
}

ContactRow.propTypes = {
    contact: PropTypes.object,
    mobileListView: PropTypes.bool
};

export default memo(ContactRow);
