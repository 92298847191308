import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Badge, Typography, } from '@material-ui/core';
// import { Typography } from 'components/ui';
// import { ArrowForwardIos } from '@material-ui/icons';
import { flag } from 'utils/flag';
import SmallAvatar from 'components/withStyledComp/SmallAvatar';
import combineClx from 'utils/combineClx';

const useStyles = makeStyles(theme => ({
    badge: {
        marginRight: theme.spacing(2.5),

        [theme.breakpoints.down('xs')]: {
            '& .MuiBadge-anchorOriginBottomRightCircle': {
                right: '18%',
                // bottom: '50%'
                bottom: '18%',
            }
        },
    },
    rootDiv: {
        //        alignSelf: 'flex-start',
        marginTop: theme.spacing(0.25)
    },
    directionRow: {
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
    },
    large: props => ({
        width: props.mobileListView ? theme.spacing(5) : theme.spacing(4),
        height: props.mobileListView ? theme.spacing(5) : theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
        alignSelf: 'flex-start',

    }),
    flagImg: props => ({
        [theme.breakpoints.down('md')]: {
            height: theme.spacing(1.875),
            width: theme.spacing(1.875)
        },
        [theme.breakpoints.up('md')]: {
            height: props.mobileListView ? theme.spacing(1.875) : theme.spacing(1.5),
            width: props.mobileListView ? theme.spacing(1.875) : theme.spacing(1.5),
        },
        filter: `drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))`
    }),
    avatarTxt: {
        fontSize: theme.spacing(1.5),
        lineHeight: `${theme.spacing(1.5)}px`,
        fontWeight: 600,
        marginTop: theme.spacing(0.3)
    },
    avatarColor: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary[200],
    }
}));

function DisplayPictureWithCountry(props = {}) {
    const classes = useStyles(props);
    const { countryCode, imageUrl, companyName = " ", badgeClassName, mainAvatarClassName, subAvatarClassName } = props;

    return (<div className={classes.rootDiv} >
        <Badge
            className={combineClx(classes.badge, badgeClassName)}
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={countryCode && <SmallAvatar className={combineClx(classes.flagImg, subAvatarClassName)} alt={countryCode || 'flag'}
                size={100}
                backgroundColor='rgba(0,0,0,0)'
                style={{ border: 0 }}
                src={flag(countryCode)} />}
        >
            <Avatar
                classes={{ colorDefault: classes.avatarColor }}
                className={combineClx(classes.large, mainAvatarClassName)} src={imageUrl}> <Typography className={classes.avatarTxt} > {companyName} </Typography></Avatar>
        </Badge>
    </div >);
}

DisplayPictureWithCountry.defaultProps = {
    badgeClassName: "",
    mainAvatarClassName: "",
    subAvatarClassName: "",
};

DisplayPictureWithCountry.propTypes = {
    contact: PropTypes.object,
    mobileListView: PropTypes.bool
};

export default memo(DisplayPictureWithCountry);
