/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getEntityListAction, getEntityListSetDataAction } from 'store/User/userActions';
import { setSelectedEntityState } from 'store/User/userSelectors';
import Auth from '@aws-amplify/auth';
// import { Hidden } from '@material-ui/core';
// import { styledSnackbar } from 'containers/Common/Snackbar';
// import BaseSkeleton from './skeletons/BaseSkeleton';
import { useHistory, useLocation } from 'react-router';
import { logout, getEntityId } from 'utils/auth';
// import NoEntityFound from './NoEntityFound';
import { initialState } from 'store/User/userReducer';
// import SetupMfa from './SetupMfa';
import AuthWrapper from './AuthWrapper';
import { TrulyLoader } from 'components';
import AuthScreens from './AuthScreens';
import { styledSnackbar } from 'containers/Common/Snackbar';
import { Helmet } from 'react-helmet-async';
import { getSmartLinkAccountAction } from 'store/Account/accountActions';
import { getSmartLinkAccountListState } from 'store/Account/accountSelectors';

import { aclListAction } from "store/Acl/aclActions";
import { /*loadLiveChat,*/ removeLiveChat } from 'utils/hubspot';
import SelectOrg from './SelectOrg';
// import { ENTITY_STATUS } from '@truly-financial/common/consts/entity.consts';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: theme.palette.background.paper,
    display: 'flex',
  },
  rightDiv: {
    width: '52%',
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: '100%'
    }

  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
  },
  mainRouteDiv: {
    // textAlign: "center",
  },
  welcomeImage: {
    height: theme.spacing(52),
    width: theme.spacing(52),
    marginTop: theme.spacing(6)
  },
  compDiv: {

    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(20.125, 15, 0, 15),
    // padding: theme.spacing(20.125, 6, 0, 14.5),
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(13.125, 3),
    }
  },
  compDivCenter: {

    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    // padding: theme.spacing(20.125, 23.5, 0, 14.5),
    padding: theme.spacing(20.125, 15, 0, 15),
    width: '100%',
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(13.125, 3),
    }
  },
  logoDiv: {
    background: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    // height: theme.spacing(12.5),
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    padding: theme.spacing(3),
  },
  leftDiv: {
    display: 'flex',
    // flex: 1,
    height: '100%',
    flexDirection: 'column',
    width: '48%',
    backgroundColor: theme.palette.primary.main
  },
  welcomeImageDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  },

  logo: {
    margin: theme.spacing(9.375, 7, -3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3, 3, -7.5),
    }
  },
  authRootGrid: {
    backgroundColor: 'white'
  },
  selectOrgRoot: {
    // maxWidth: "576px",
    flex: 1,
    position: "relative"
  },
  selectOrg: {
    // margin: theme.spacing(0, -3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
    }
  }
}));

function Authentication(props = {}) {
  const { selectedEntity,
    resetState,
    getEntities,
    smartLinkAccounts,
    getSmartLinkList,
    aclListAction,
    children,
  } = props;
  const selectedEntityData = selectedEntity.data || {};
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const location = useLocation();
  const isLoginUrl = location.pathname.search("/login") === 0 ? true : false;
  const isSignUpUrl = location.pathname.search("/signup") === 0 ? true : false;
  const [authState, setAuthStateHook] = useState();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const hideWelcomeImage = getEntityId()
  useEffect(() => {
    resetState();
    setTimeout(() => {
      removeLiveChat();
    }, 1000);
  }, []);

  useEffect(() => {
    if (authState === AuthState.SignIn) {
      logout();
    } else if (authState === AuthState.SignedIn) {
      getEntities();
    }
  }, [authState]);

  useEffect(() => {
    if (authState === AuthState.SignedIn) {
      if (selectedEntityData.role_id === 2 && smartLinkAccounts && smartLinkAccounts.data && !Object.keys(smartLinkAccounts.data).length) {
        getSmartLinkList();
      } else if (isLoginUrl || location.pathname === '/') {
        sendToDefaultPage()
      }
    }
  }, [selectedEntityData]);

  useEffect(() => {
    if (selectedEntityData.role_id === 2) {
      sendToDefaultPage();
    }
  }, [smartLinkAccounts, selectedEntityData]);

  const sendToDefaultPage = () => {
    if (selectedEntityData.is_id_verification_passed && selectedEntityData.entity_status === 'APPROVED'){
      if (selectedEntityData.role_id === 2) {
        if (smartLinkAccounts && smartLinkAccounts.data && smartLinkAccounts.data.length === 1) {
          const accountId = smartLinkAccounts.data[0].account_id || null;
          if (accountId) {
            history.push(`/accounts/smartlink/${accountId}/details`);
          } else {
            history.push("/accounts");
          }
        } else {
          history.push("/accounts");
        }
      } else {
        history.push("/accounts");
      }
    } else {
      history.push("/home");
    }
  };

  useEffect(() => {
    const nextState = nextAuthState(user);
    if (!Object.keys(user).length) {
      checkUser();
    } else if (nextState) {
      setAuthState(nextState);
    }
  }, [user, user.preferredMFA, user.signInUserSession, user.challengeName, user.attributes]);



  useEffect(() => {
    if ((authState === AuthState.SignedIn && user && selectedEntityData.entity_id)) {
      aclListAction();
      // loadLiveChat()
    }
  }, [selectedEntityData])

  async function resendConfirmationCode(e) {
    setLoading(true);
    try {
      await Auth.verifyCurrentUserAttribute("email");
    } catch (error) {
      styledSnackbar.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  const nextAuthState = (user) => {
    let stateToReturn;
    if (user.attributes && !user.attributes.email_verified) {
      resendConfirmationCode();
      stateToReturn = AuthState.VerifyingAttributes;
    } else if ((user.preferredMFA === 'NOMFA')) {
      stateToReturn = AuthState.SettingMFA;
    } else if (user.signInUserSession) {
      stateToReturn = AuthState.SignedIn;
    } else if (user.challengeName) {
      switch (user.challengeName) {
        case 'SMS_MFA':
          stateToReturn = AuthState.ConfirmSignIn;
          break;
        case 'SOFTWARE_TOKEN_MFA':
          stateToReturn = AuthState.ConfirmSignIn;
          break;
        case 'SELECT_MFA_TYPE':
          stateToReturn = AuthState.SettingMFA;
          break;
        case 'MFA_SETUP':
          stateToReturn = AuthState.SettingMFA;
          break;
        case 'NEW_PASSWORD_REQUIRED':
          stateToReturn = AuthState.ResetPassword;
          break;
        default:
          stateToReturn = '';
          styledSnackbar.error(`Unknown challenge ${user.challengeName}`);
      }
    }
    return stateToReturn;
  };

  async function checkUser() {
    if (isLoginUrl) {
      setAuthState(AuthState.SignIn);
    } else if (isSignUpUrl) {
      setAuthState(AuthState.SignUp);
    } else {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        setUser(authenticatedUser);
      } catch (error) {
        setAuthState(AuthState.SignIn);
        logout();
      }
    }
  }

  const setAuthState = (as) => {
    setLoading(true);
    setTimeout(() => {
      setAuthStateHook(as);
      setLoading(false);
    }, 300);
  };

  if (!authState) {
    // return (<Loader />);
    return (<TrulyLoader />);
  }

  return (<>
    <Helmet>
      <title>Login</title>
      <meta name="description" content="Truly Financial login" />
    </Helmet>
    {authState === AuthState.SignedIn && user ?
      <>
       { selectedEntityData.entity_id
       ?
        <div className={classes.mainRouteDiv}>
            {children}
        </div>
        :
        <AuthWrapper hideImage={hideWelcomeImage}>
          <div className={classes.mainDiv}>
            <div className={classes.compDiv}>
              <div className={classes.selectOrg}><SelectOrg setLoading={setLoading} /></div>
            </div>
          </div>
        </AuthWrapper>}
      </>
      :
      <AuthWrapper>
         <div className={classes.mainDiv}>
            <div className={classes.compDivCenter}>
            {/* <div className={authState === AuthState.SignedIn && !selectedEntityData.entity_id ? classes.compDiv : classes.compDivCenter}> */}
              <AuthScreens
                authState={authState}
                setAuthState={setAuthState}
                user={user}
                setUser={setUser}
                loading={loading}
                setLoading={setLoading}
                checkUser={checkUser}
              />
            </div>
          </div>
      </AuthWrapper>
      // <div className={classes.root}>
      //   <Hidden smDown>
      //     <div className={classes.leftDiv}>
      //       <AuthWrapper />
      //     </div>
      //   </Hidden>

      //   <div className={classes.rightDiv}>
      //     <Hidden lgUp>
      //       <div className={classes.logo}>
      //         <Logo height={40} width={113} padding={0} />
      //       </div>
      //     </Hidden>
      //     <div className={classes.mainDiv}>
      //       <div className={authState === AuthState.SignedIn && !selectedEntityData.entity_id ? classes.compDiv : classes.compDivCenter}>
      //         <AuthScreens
      //           authState={authState}
      //           setAuthState={setAuthState}
      //           user={user}
      //           setUser={setUser}
      //           loading={loading}
      //           setLoading={setLoading}
      //           checkUser={checkUser}
      //         />
      //       </div>
      //     </div>
      //   </div>
      // </div>
      }
  </>);
}

const mapStateToProps = createStructuredSelector({
  // entities: getEntitiesState(),
  selectedEntity: setSelectedEntityState(),
  smartLinkAccounts: getSmartLinkAccountListState(),
});

function mapDispatchToProps(dispatch) {
  return {
    // dispatch actions and from props;
    resetState: () => dispatch({ type: 'RESET_GLOBAL_STATE' }),
    getEntities: data => dispatch(getEntityListAction(data)),
    resetEntities: () => dispatch(getEntityListSetDataAction(initialState.getEntitiesState)),
    aclListAction: () => dispatch(aclListAction()),
    getSmartLinkList: data => dispatch(getSmartLinkAccountAction(data)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(Authentication);
