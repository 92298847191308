import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { Icon, Typography } from './ui';
import ErrorImage from 'assets/images/errorImage.png'
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function StyledError(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { error = {} } = props.data;
  const { retry } = props;
  const [loader, setLoader] = useState(false);

  const onRetry = () => {
    if (typeof retry === 'function') {
      setLoader(true);
      setTimeout(() => {
        retry();
        setLoader(false);
      }, 1000)
    }
  }

  if (!error.message) {
    return null;
  }

  return (<>
    <div className={classes.root}>
      <div>
        <img alt="Error" src={ErrorImage}></img>
        <Typography color="grey700" variant="h3">Oops...</Typography>
        <Typography color="grey400" variant="body1">{error.message}</Typography>
        {typeof retry === 'function' && !loader && <IconButton disabled={loader} onClick={onRetry}><Icon color="secondary" component={ReplayIcon} /></IconButton>}
        {loader && <CircularProgress color="secondary" />}
      </div>
  </div>
  </>);
}

StyledError.defaultProps = {
  data: {},
  retry: null,
};

StyledError.propTypes = {
  data: PropTypes.object,
};

export default memo(StyledError);
