

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography as MuiTypography } from '@material-ui/core';
import combineClx from 'utils/combineClx';
const colors = [
  'initial',
  'inherit',
  'primary',
  'secondary',
  'textPrimary',
  'textSecondary',
  'error',
  'warning'
];
const useStyles = makeStyles(theme => ({
  titleMobile: {
    ...theme.typography.titleMobile,
  },
  numPad: {
    ...theme.typography.numPad,
  },
  smallHeader: {
    ...theme.typography.smallHeader,
  },
  color: props => ({
    color: colors.includes(props.color) ? props.color : theme.palette[props.color],
  }),
}));
function Typography(props) {
  const classes = useStyles(props);
  const { type, variant, children, className, classes: propsClasses, nowrap, color, style={}, } = props;
  let propColor = colors.includes(color) ? color : 'initial';
  return (
    <>
      <MuiTypography style={style} color={propColor} variant={['titleMobile', 'numPad', 'smallHeader'].includes(variant) ? null : variant} className={combineClx(className, classes[variant], classes.color)} classes={propsClasses} type={type} nowrap={nowrap}>{children}</MuiTypography>
    </>
  );
}

Typography.propTypes = {
  type: PropTypes.string,
};

export default memo(Typography);
