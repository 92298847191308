import { Hidden, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { memo } from 'react';

import AuthCard from 'assets/images/AuthCard.png';

import { Logo } from 'components';

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    height: "100vh",
    // height: "100vw",
    background: `linear-gradient(135deg, #F3CFB3 15.6%, #8CDFBA 95.06%);`,
    // background: `linear-gradient(
    //       to top left,
    //       transparent 50%,
    //       #F3D1B3
    //   ),
    //   linear-gradient(
    //       to top right,
    //       transparent 50%,
    //       #E1CDE4
    //   ),
    //   linear-gradient(
    //       to bottom left,
    //       transparent 30%,
    //       #A7D9C5
    //   ),
    //   linear-gradient(
    //       to bottom right,
    //       transparent 30%,
    //       #AED6E6
    //   )`,
  },
  imageWrapperDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    // paddingTop: theme.spacing(3),
  },
  imageDiv: {
    height: theme.spacing(81.625),
    width: "100%",
    maxWidth: "558px",
    marginTop: theme.spacing(5)
  },
  logoDiv: {
    position: "absolute",
  },
  root: {
    height: '100vh',
    background: theme.palette.background.paper,
    display: 'flex',
  },
  leftDiv: {
    display: 'flex',
    // flex: 1,
    height: '100%',
    flexDirection: 'column',
    width: '48%',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("xl")]: {
      width: '60%',
    }
  },
  rightDiv: {
    width: '52%',
    position: 'relative',
    [theme.breakpoints.up("xl")]: {
      width: '40%',
    },
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: '100%'
    }
  },
  centerDiv: {
    flex: 1,
    width: '100%',
    position: 'relative'
  },
  logo: {
    margin: theme.spacing(9.375, 7, -3),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(3, 3, -7.5),
    }
  },
}));

function AuthWrapper(props = {}) {
  const { children, hideImage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const showImage = useMediaQuery(theme.breakpoints.down('md'));
  if (hideImage) {
    return (<div className={classes.root}>
      <div className={classes.centerDiv}>
          {children}
        </div>
    </div>)
  }
  return (<>
  <div className={classes.root}>
        {<Hidden smDown>
          <div className={classes.leftDiv}>
            <div className={classes.mainDiv}>
              {!showImage && <div>
                <div className={classes.logoDiv}><Logo padding={4} /></div>
                <div className={classes.imageWrapperDiv}>
                  <img alt="Card" className={classes.imageDiv} src={AuthCard} />
                </div>
              </div>}
            </div>
          </div>
        </Hidden>}

        <div className={classes.rightDiv}>
          <Hidden lgUp>
            <div className={classes.logo}>
              <Logo height={40} width={113} padding={0} />
            </div>
          </Hidden>
          {children}
        </div>
      </div>
  </>);
}

export default memo(AuthWrapper);