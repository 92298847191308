export const getEntitiesParser = (r = {}) => {
    const parsedResponse = { ...r };
    const colors = ['#DAEFE7', '#F2EAF4', '#F7E6DB', '#E2F0F6'];
    let colorCount = 0;
    const maxColorCount = colors.length - 1;

    const getColor = () => {
        const color = colors[colorCount];
        colorCount++;
        if (colorCount > maxColorCount) {
            colorCount = 0;
        }
        return color;
    };

    const d = r.data.map(i => {
        const c = getColor();
        return {
            ...i,
            color: 'none',
            backgroundColor:`${c}`,
        };
    });
    parsedResponse.data = d;
    return parsedResponse;
};