import React, { memo, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCountryListState } from 'store/Reference/referenceSelectors';
import { getCountryListAction } from 'store/Reference/referenceActions';
import { Loader } from 'components';

function GetUserCountryName(props) {
    const { data, getCountries, countries } = props;

    const [countryName, setCountryName] = useState('');

    useEffect(() => {
        if (countries && countries.data && !countries.data.length)
            getCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (countries && countries.data && countries.data.length > 0) {
            const country_code = (data && data.company_details && data.company_details.incorporation_country_code) || null;
            if (country_code) {
                let filteredValue = countries.data.filter(i => i.country_code === country_code);
                setCountryName(filteredValue[0].country_name);
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);
    return (
        <>
            {
                countries && countries.isLoading && <Loader />
            }
            {countryName}
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    countries: getCountryListState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getCountries: data => dispatch(getCountryListAction(data)),

    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(GetUserCountryName);

