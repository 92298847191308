
import React, { memo } from 'react';
import FileUpload from 'components/FileUpload';
import { Avatar, makeStyles } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import useCommonStyles from 'styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    borderRadius: "50%",
  },
  uploadIconDiv: {
    left: 0,
    right: 0,
    bottom: -theme.spacing(1.25),
    margin: "auto",
    position: "absolute",
    background: "rgba(0,0,0,0.3)",
    borderBottomLeftRadius: theme.spacing(3.75),
    borderBottomRightRadius: theme.spacing(3.75),
    textAlign: "center",
  },
  avatarColor: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary[200],
  },
  uploadIcon: {
    color: theme.palette.grey[50],
    height: theme.spacing(2.75),
    margin: `${theme.spacing(0.125)} 0`,
    paddingBottom: theme.spacing(0.5)
  },
  centerTxt: {
    marginTop: theme.spacing(0.4)
  }
}));

function ProfilePicture(props = {}) {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { onSelectFile, defaultImage, name, disabled } = props;


  return (<FileUpload
    disabled={disabled}
    onSelectFile={onSelectFile}
    renderComponent={(f) => (<div className={classes.root}>
      <div><Avatar classes={{ root: commonClasses.avatarLarge, colorDefault: classes.avatarColor }} className={commonClasses.avatarLarge} fontSize="large" src={(f && f[0] && f[0].preview) || defaultImage} >
        {name && <Typography className={classes.centerTxt} variant="h5" > {name} </Typography>}
      </Avatar></div>
      {!disabled && <div className={classes.uploadIconDiv}>{f && f[0] && f[0].preview ? <CameraAltIcon className={classes.uploadIcon} /> : <PublishIcon className={classes.uploadIcon} />}</div>}
    </div>)}
  />)
}

ProfilePicture.defaultProps = {
  onSelectFile: () => null,
  multiple: true,
  renderComponent: false,
  disabled: false
}

ProfilePicture.propTypes = {
};

export default memo(ProfilePicture);
