import produce from 'immer';
import { ACL_LIST, ACL_LIST_SET_DATA } from './aclConstants';

export const initialState = {
    aclListState: { data: [], loading: false, error: '', loaded: false }
}

/* eslint-disable default-case, no-param-reassign */
const aclReducer = (state = initialState, action) => produce(state, draft => {

    switch (action.type) {
        case ACL_LIST:
            draft.aclListState = {
              ...draft.aclListState,
              loading: true, error: ''
            };
            break;
        
        case ACL_LIST_SET_DATA:
            draft.aclListState = {
                ...draft.aclListState,
                data: action.data, 
                loading: false,
                loaded: true,
            };
            break;
    }
});

export default aclReducer;
