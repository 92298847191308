import produce from 'immer';
import {
  RESET_REGISTER,
  ADD_CUSTOMER_INTRODUCTION_SET_DATA,
  ADD_CUSTOMER_INTRODUCTION,
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_DETAILS_SET_DATA,
  ADD_CUSTOMER_BUSINESS_BRIEF, ADD_CUSTOMER_BUSINESS_BRIEF_SET_DATA,
  SAVE_BUSINESS_BRIEF, SAVE_BUSINESS_BRIEF_SET_DATA, UPDATE_STEPS,
  SAVE_BUSINESS_STRUCTURE, SAVE_BUSINESS_STRUCTURE_SET_DATA,
  SAVE_TERM_CONDITION, SAVE_TERM_CONDITION_SET_DATA,
  SAVE_BUSINESS_CATEGORIES, SAVE_BUSINESS_CATEGORIES_SET_DATA,
  SAVE_BUSINESS_INDUSTRIES, SAVE_BUSINESS_INDUSTRIES_SET_DATA,
  SAVE_BUSINESS_USE_CASES_SET_DATA, SAVE_BUSINESS_USE_CASES,
  SAVE_BUSINESS_COUNTRIES_SET_DATA, SAVE_BUSINESS_COUNTRIES,
  ADD_CUSTOMER_CONTACT_DETAILS, ADD_CUSTOMER_CONTACT_DETAILS_SET_DATA,
  SAVE_BUSINESS_SOURCE_OF_FUNDING_SET_DATA, SAVE_BUSINESS_SOURCE_OF_FUNDING,
  VERIFY_PERSONAL_DETAIL_SET_DATA, VERIFY_PERSONAL_DETAIL,
  ADD_BENEFICIAL_OWNER, ADD_BENEFICIAL_OWNER_SET_DATA,
  SIGNUP, SIGNUP_SET_DATA,
  GET_TERM_AND_CONDITION, GET_TERM_AND_CONDITION_SET_DATA,
  SAVE_CONFIDENTIALITY_AGREEMENT, SAVE_CONFIDENTIALITY_AGREEMENT_SET_DATA,
  DELETE_BENEFICIAL_OWNER, DELETE_BENEFICIAL_OWNER_SET_DATA,
  ACCEPT_CONFIDENTIAL_AGREEMENT, ACCEPT_CONFIDENTIAL_AGREEMENT_SET_DATA,
  RESEND_INVITATION_LINK_SET_DATA, RESEND_INVITATION_LINK,
  INVITE_AS_TEAM_MEMBER_SET_DATA, INVITE_AS_TEAM_MEMBER,
  SAVE_CONTROLLING_PARTY, SAVE_CONTROLLING_PARTY_SET_DATA,
  ACCEPT_ACCOUNT_AGREEMENT_SET_DATA, ACCEPT_ACCOUNT_AGREEMENT,
  TEMP_SAVE_REGISTRATION_DATA_ACTION,
  SUBMIT_REGISTRATION_EXTRA_INFO,
  SUBMIT_REGISTRATION_EXTRA_INFO_SET_DATA,
  ADD_CUSTOMER_BUSINESS_DETAIL,
  ADD_CUSTOMER_BUSINESS_DETAIL_SET_DATA,
  ADD_BUSINESS_GLOBAL_POSITION,
  ADD_BUSINESS_GLOBAL_POSITION_SET_DATA,
  RE_GENERATE_PDF, RE_GENERATE_PDF_SET_DATA,
  DOCUMENT_UPLOAD, DOCUMENT_UPLOAD_SET_DATA,
  SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER, SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER_SET_DATA,
  MARK_ID_VERIFICATION_STEP, MARK_ID_VERIFICATION_STEP_SET_DATA
} from './registerConstants';

export const initialState = {
  markIdVerificationStepState: { data: {}, loading: false, error: '' },
  saveControllingPartyWithBeneficialOwnerState: { data: {}, loading: false, error: '' },
  reGeneratePdfState: { data: {}, loading: false, error: '' },
  documentUploadState: { data: {}, loading: false, error: '' },
  submitRegistrationExtraInfoState: { data: {}, loading: false, error: '' },
  addBusinessGlobalPositionState: { data: {}, loading: false, error: '' },
  addCustomerBusinessDetailState: { data: {}, loading: false, error: '' },
  acceptAccountAgreementState: { data: {}, loading: false, error: '' },
  saveControllingPartyState: { data: {}, loading: false, error: '' },
  inviteAsTeamMemberState: { data: {}, loading: false, error: '' },
  resendInvitationLinkState: { data: {}, loading: false, error: '' },
  acceptConfidentialAgreementState: { data: {}, loading: false, error: '' },
  signupState: { data: {}, loading: false, error: '' },
  deleteBeneficialOwnerState: { data: {}, loading: false, error: '' },
  saveConfidentialityAgreementState: { data: {}, loading: false, error: '' },
  getTermAndConditionState: { data: [], loading: false, error: '' },
  addBeneficialOwnerState: { data: {}, loading: false, error: '' },
  verifyPersonalDetailState: { data: {}, loading: false, error: '' },
  saveBusinessSourceOfFundingState: { data: {}, loading: false, error: '' },
  saveBusinessCountriesState: { data: {}, loading: false, error: '' },
  saveBusinessUseCasesState: { data: {}, loading: false, error: '' },
  saveBusinessIndustriesState: { data: {}, loading: false, error: '' },
  saveBusinessCategoriesState: { data: {}, loading: false, error: '' },
  saveTermConditionState: { data: {}, loading: false, error: '' },
  saveBusinessStructureState: { data: {}, loading: false, error: '' },
  saveBusinessBriefState: { data: {}, loading: false, error: '' },
  getCustomerDetailsState: { data: {}, loading: false, error: '' },
  addCustomerIntroductionState: { data: {}, loading: false, error: '' },
  addCustomerBusinessBriefState: { data: {}, loading: false, error: '' },
  addCustomerContactDetailsState: { data: {}, loading: false, error: '' },
  tempRegistrationDataState: {},
};

/* eslint-disable default-case, no-param-reassign */
const registerReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case MARK_ID_VERIFICATION_STEP:
        draft.markIdVerificationStepState = {
          ...draft.markIdVerificationStepState,
          loading: true, message: '', error: '',
        };
        break;
      case MARK_ID_VERIFICATION_STEP_SET_DATA:
        draft.markIdVerificationStepState = {
          ...draft.markIdVerificationStepState,
          ...action.data, loading: false,
        }
        break;
      case SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER:
        draft.saveControllingPartyWithBeneficialOwnerState = {
          ...draft.saveControllingPartyWithBeneficialOwnerState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_CONTROLLING_PARTY_WITH_BENEFICIAL_OWNER_SET_DATA:
        draft.saveControllingPartyWithBeneficialOwnerState = {
          ...draft.saveControllingPartyWithBeneficialOwnerState,
          ...action.data, loading: false,
        }
        break;
      case RE_GENERATE_PDF:
        draft.reGeneratePdfState = {
          ...draft.reGeneratePdfState,
          loading: true, message: '', error: '',
        };
        break;
      case RE_GENERATE_PDF_SET_DATA:
        draft.reGeneratePdfState = {
          ...draft.reGeneratePdfState,
          ...action.data, loading: false,
        }
        break;
      case DOCUMENT_UPLOAD:
        draft.documentUploadState = {
          ...draft.documentUploadState,
          loading: true, message: '', error: '',
        };
        break;
      case DOCUMENT_UPLOAD_SET_DATA:
        draft.documentUploadState = {
          ...draft.documentUploadState,
          ...action.data, loading: false,
        };
        break;
      case SUBMIT_REGISTRATION_EXTRA_INFO:
        draft.submitRegistrationExtraInfoState = {
          ...draft.submitRegistrationExtraInfoState,
          loading: true, message: '', error: '',
        };
        break;
      case SUBMIT_REGISTRATION_EXTRA_INFO_SET_DATA:
        draft.submitRegistrationExtraInfoState = {
          ...draft.submitRegistrationExtraInfoState,
          ...action.data, loading: false,
        };
        break;
      case ADD_BUSINESS_GLOBAL_POSITION:
        draft.addBusinessGlobalPositionState = {
          ...draft.addBusinessGlobalPositionState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_BUSINESS_GLOBAL_POSITION_SET_DATA:
        draft.addBusinessGlobalPositionState = {
          ...draft.addBusinessGlobalPositionState,
          ...action.data, loading: false,
        };
        break;
      case ADD_CUSTOMER_BUSINESS_DETAIL:
        draft.addCustomerBusinessDetailState = {
          ...draft.addCustomerBusinessDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_CUSTOMER_BUSINESS_DETAIL_SET_DATA:
        draft.addCustomerBusinessDetailState = {
          ...draft.addCustomerBusinessDetailState,
          ...action.data, loading: false,
        };
        break;
      case ACCEPT_ACCOUNT_AGREEMENT:
        draft.acceptAccountAgreementState = {
          ...draft.acceptAccountAgreementState,
          loading: true, message: '', error: '',
        };
        break;
      case ACCEPT_ACCOUNT_AGREEMENT_SET_DATA:
        draft.acceptAccountAgreementState = {
          ...draft.acceptAccountAgreementState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_CONTROLLING_PARTY:
        draft.saveControllingPartyState = {
          ...draft.saveControllingPartyState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_CONTROLLING_PARTY_SET_DATA:
        draft.saveControllingPartyState = {
          ...draft.saveControllingPartyState,
          ...action.data, loading: false,
        };
        break;
      case INVITE_AS_TEAM_MEMBER:
        draft.inviteAsTeamMemberState = {
          ...draft.inviteAsTeamMemberState,
          loading: true, message: '', error: '',
        };
        break;
      case INVITE_AS_TEAM_MEMBER_SET_DATA:
        draft.inviteAsTeamMemberState = {
          ...draft.inviteAsTeamMemberState,
          ...action.data, loading: false,
        };
        break;
      case RESEND_INVITATION_LINK:
        draft.resendInvitationLinkState = {
          ...draft.resendInvitationLinkState,
          loading: true, message: '', error: '',
        };
        break;
      case RESEND_INVITATION_LINK_SET_DATA:
        draft.resendInvitationLinkState = {
          ...draft.resendInvitationLinkState,
          ...action.data, loading: false,
        };
        break;
      case ACCEPT_CONFIDENTIAL_AGREEMENT:
        draft.acceptConfidentialAgreementState = {
          ...draft.acceptConfidentialAgreementState,
          loading: true, message: '', error: '',
        };
        break;
      case ACCEPT_CONFIDENTIAL_AGREEMENT_SET_DATA:
        draft.acceptConfidentialAgreementState = {
          ...draft.acceptConfidentialAgreementState,
          ...action.data, loading: false,
        };
        break;
      case SIGNUP:
        draft.signupState = {
          ...draft.signupState,
          loading: true, message: '', error: '',
        };
        break;
      case SIGNUP_SET_DATA:
        draft.signupState = {
          ...draft.signupState,
          ...action.data, loading: false,
        };
        break;
      case RESET_REGISTER:
        draft = initialState;
        break;
      case DELETE_BENEFICIAL_OWNER:
        draft.deleteBeneficialOwnerState = {
          ...draft.deleteBeneficialOwnerState,
          loading: true, message: '', error: '',
        };
        break;
      case DELETE_BENEFICIAL_OWNER_SET_DATA:
        draft.deleteBeneficialOwnerState = {
          ...draft.deleteBeneficialOwnerState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_CONFIDENTIALITY_AGREEMENT:
        draft.saveConfidentialityAgreementState = {
          ...draft.saveConfidentialityAgreementState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_CONFIDENTIALITY_AGREEMENT_SET_DATA:
        draft.saveConfidentialityAgreementState = {
          ...draft.saveConfidentialityAgreementState,
          ...action.data, loading: false,
        };
        break;
      case GET_TERM_AND_CONDITION:
        draft.getTermAndConditionState = {
          ...draft.getTermAndConditionState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_TERM_AND_CONDITION_SET_DATA:
        draft.getTermAndConditionState = {
          ...draft.getTermAndConditionState,
          ...action.data, loading: false,
        };
        break;
      case ADD_BENEFICIAL_OWNER:
        draft.addBeneficialOwnerState = {
          ...draft.addBeneficialOwnerState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_BENEFICIAL_OWNER_SET_DATA:
        draft.addBeneficialOwnerState = {
          ...draft.addBeneficialOwnerState,
          ...action.data, loading: false,
        };
        break;
      case VERIFY_PERSONAL_DETAIL:
        draft.verifyPersonalDetailState = {
          ...draft.verifyPersonalDetailState,
          loading: true, message: '', error: '',
        };
        break;
      case VERIFY_PERSONAL_DETAIL_SET_DATA:
        draft.verifyPersonalDetailState = {
          ...draft.verifyPersonalDetailState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_BUSINESS_SOURCE_OF_FUNDING:
        draft.saveBusinessSourceOfFundingState = {
          ...draft.saveBusinessSourceOfFundingState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_BUSINESS_SOURCE_OF_FUNDING_SET_DATA:
        draft.saveBusinessSourceOfFundingState = {
          ...draft.saveBusinessSourceOfFundingState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_BUSINESS_COUNTRIES:
        draft.saveBusinessCountriesState = {
          ...draft.saveBusinessCountriesState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_BUSINESS_COUNTRIES_SET_DATA:
        draft.saveBusinessCountriesState = {
          ...draft.saveBusinessCountriesState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_BUSINESS_USE_CASES:
        draft.saveBusinessUseCasesState = {
          ...draft.saveBusinessUseCasesState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_BUSINESS_USE_CASES_SET_DATA:
        draft.saveBusinessUseCasesState = {
          ...draft.saveBusinessUseCasesState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_BUSINESS_INDUSTRIES:
        draft.saveBusinessIndustriesState = {
          ...draft.saveBusinessIndustriesState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_BUSINESS_INDUSTRIES_SET_DATA:
        draft.saveBusinessIndustriesState = {
          ...draft.saveBusinessIndustriesState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_BUSINESS_CATEGORIES:
        draft.saveBusinessCategoriesState = {
          ...draft.saveBusinessCategoriesState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_BUSINESS_CATEGORIES_SET_DATA:
        draft.saveBusinessCategoriesState = {
          ...draft.saveBusinessCategoriesState,
          ...action.data, loading: false,
        };
        break;
      case ADD_CUSTOMER_INTRODUCTION:
        draft.addCustomerIntroductionState = {
          ...draft.addCustomerIntroductionState,
          loading: true, message: '', error: '',
        };
        break;
      case ADD_CUSTOMER_INTRODUCTION_SET_DATA:
        draft.addCustomerIntroductionState = {
          ...draft.addCustomerIntroductionState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_TERM_CONDITION:
        draft.saveTermConditionState = {
          ...draft.saveTermConditionState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_TERM_CONDITION_SET_DATA:
        draft.saveTermConditionState = {
          ...draft.saveTermConditionState,
          ...action.data, loading: false,
        };
        break;
      case SAVE_BUSINESS_STRUCTURE:
        draft.saveBusinessStructureState = {
          ...draft.saveBusinessStructureState,
          loading: true, message: '', error: '',
        };
        break;
      case SAVE_BUSINESS_STRUCTURE_SET_DATA:
        draft.saveBusinessStructureState = {
          ...draft.saveBusinessStructureState,
          ...action.data, loading: false,
        };
        break;
      case GET_CUSTOMER_DETAILS:
        draft.getCustomerDetailsState = {
          ...draft.getCustomerDetailsState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CUSTOMER_DETAILS_SET_DATA:
        draft.getCustomerDetailsState = {
          ...draft.getCustomerDetailsState,
          ...action.data, loading: false,
        };
        break;
      case UPDATE_STEPS:
        draft.getCustomerDetailsState = {
          ...draft.getCustomerDetailsState,
          ...action.data,
        };
        break;
      case ADD_CUSTOMER_BUSINESS_BRIEF:
        draft.addCustomerBusinessBriefState = {
          ...draft.addCustomerBusinessBriefState,
          loading: true, message: '', error: ''
        };
        break;
      case ADD_CUSTOMER_BUSINESS_BRIEF_SET_DATA:
        draft.addCustomerBusinessBriefState = {
          ...draft.addCustomerBusinessBriefState,
          ...action.data, loading: false
        };
        break;
      case SAVE_BUSINESS_BRIEF:
        draft.saveBusinessBriefState = {
          ...draft.saveBusinessBriefState,
          loading: true, message: '', error: ''
        };
        break;
      case SAVE_BUSINESS_BRIEF_SET_DATA:
        draft.saveBusinessBriefState = {
          ...draft.saveBusinessBriefState,
          ...action.data, loading: false
        };
        break;
      case ADD_CUSTOMER_CONTACT_DETAILS:
        draft.addCustomerContactDetailsState = {
          ...draft.addCustomerContactDetailsState,
          loading: true, message: '', error: ''
        };
        break;
      case ADD_CUSTOMER_CONTACT_DETAILS_SET_DATA:
        draft.addCustomerContactDetailsState = {
          ...draft.addCustomerContactDetailsState,
          ...action.data, loading: false
        };
        break;
      case TEMP_SAVE_REGISTRATION_DATA_ACTION:

        draft.tempRegistrationDataState = {
          ...draft.tempRegistrationDataState,
          ...action.data,
        };
        break;
    }

  });

export default registerReducer;
