import React, { useEffect } from "react";
import VerificationRedirect from "containers/Verification/VerificationRedirect";
import { removeLiveChat } from "utils/hubspot";
import queryString from 'query-string';
import { useLocation } from "react-router-dom";

function VerificationRedirectPage(props = {}) {

  const location = useLocation();
  const { isDialog } = queryString.parse(location.search);
  useEffect(() => {
    if (isDialog) {
      removeLiveChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // This is previous id verification link page
  return (
    <>
      <VerificationRedirect />
    </>
  )
}

export default VerificationRedirectPage;