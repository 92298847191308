import produce from 'immer';
import {
  RESET_OTP,
  SEND_OTP, SEND_OTP_SET_DATA,
  RESEND_OTP, RESEND_OTP_SET_DATA,
} from './otpConstants';

export const initialState = {
  resendOtpState: { data: {}, loading: false, error: '' },
  sendOtpState: { data: {}, loading: false, error: '' },
};

/* eslint-disable default-case, no-param-reassign */
const otpReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESEND_OTP:
        draft.resendOtpState = {
          ...draft.resendOtpState,
          loading: true, message: '', error: '',
        };
        break;
      case RESEND_OTP_SET_DATA:
        draft.resendOtpState = {
          ...draft.resendOtpState,
          ...action.data, loading: false,
        };
        break;
      case SEND_OTP:
        draft.sendOtpState = {
          ...draft.sendOtpState,
          loading: true, message: '', error: '',
        };
        break;
      case SEND_OTP_SET_DATA:
        draft.sendOtpState = {
          ...draft.sendOtpState,
          ...action.data, loading: false,
        };
        break;
      case RESET_OTP:
        draft = initialState;
        break;
    }
  });

export default otpReducer;
