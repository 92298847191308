
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import messages from 'components/intl/TransferRateIntl';
// import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
// import RepeatIcon from '@material-ui/icons/Repeat';
// import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import { currencySymbol } from 'utils/currencySymbols';
// import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
// import { Chip } from './ui';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TimerIcon from '@material-ui/icons/Timer';
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';
// import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
// import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import moment from 'moment';
import { fxRateDisplay } from 'utils/common';
// import Tag from 'containers/Common/Tag';

const useStyles = makeStyles(theme => ({
  rootWithoutPadding: {
    display: 'flex',
    flex: 1,

    flexDirection: 'column'
  },
  root: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(1),
    flexDirection: 'column'
  },
  subItem: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(0, 0, 2, 0),
    }
  },
  subItemNoMargin: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItem: 'center',

  },
  subItemExtraMargin: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItem: 'center',
    margin: theme.spacing(2, 0, 0, 0)
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.grey[300],
    fontSize: theme.spacing(2)
  },
  grayColor: {
    color: 'rgba(0,0,0,0.6)'
  },
  chip: {
    height: theme.spacing(3.2),
    marginLeft: theme.spacing(0.5)
  },
  chipTxt: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'end'
  },
  valueItem: {
    wordBreak: "break-all",
    marginLeft: theme.spacing(2),
    textAlign: 'right'
  },
  breakWord: {
    wordBreak: "break-all",
  },
  swapIcon: {
    color: theme.palette.accent[500],
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(-0.5),
  },
  conversionWrapDiv: {
    display: "flex",
    alignItems: "center",
  },

}));

function TransferFundsQuote(props = {}) {
  const { data,
    // tagConstants={},
    // onTagUpdate=() => null
  } = props;
  const classes = useStyles();

  return (


    <div className={classes.rootWithoutPadding}>
      
          <>
           {<>
              {data.source_currency_code !== data.destination_currency_code && <div className={classes.subItem} >
                <div className={classes.rowDirection} >
                  <Typography variant="body2" color="grey400" ><FormattedMessage {...messages.rate} /></Typography>
                </div>
                <div className={classes.conversionWrapDiv}>
                  <SwapHorizontalCircleIcon className={classes.swapIcon} />
                  <Typography className={classes.valueItem} variant="body2" color="grey600">
                    {fxRateDisplay({
                        source_currency: data.source_currency_code,
                        destination_currency: data.destination_currency_code,
                        fx_rate: data.fx_rate,
                    })}
                  </Typography>
                </div>
              </div>}

             {data.source_currency_code !== data.destination_currency_code && <div className={classes.subItem} >
                <div className={classes.rowDirection} >
                  <Typography variant="body2" color="grey400" >{<FormattedMessage {...messages.debitAmount} />}</Typography>
                </div>
                <Typography className={classes.valueItem} variant="body2" color="grey600">{data.source_amount.toFixed(2) || 0} {data.source_currency_code}</Typography>
              </div>}

              <div className={classes.subItem} >
                <div className={classes.rowDirection} >
                  <Typography variant="body2" color="grey400" >{<FormattedMessage {...messages.transferFundsCreditAmount} />}</Typography>
                </div>
                <Typography className={classes.valueItem} variant="body2" color="grey600">{data.destination_amount.toFixed(2) || 0} {data.destination_currency_code || data.destination_currency}</Typography>
              </div>

            </>}
          
            <div className={classes.subItem} >
              <div className={classes.rowDirection} >
                <EventAvailableIcon className={classes.icon} />
                <Typography variant="body2" color="grey400" ><FormattedMessage {...messages.TransferInitiateOn} /></Typography>
              </div>
              <Typography className={classes.valueItem} variant="body2" color="grey600" >{moment(data.created_at).format('MMM DD, YYYY')}</Typography>
            </div>

            <div className={classes.subItem} >
              <div className={classes.rowDirection} >
                <TimerIcon className={classes.icon} />
                <Typography variant="body2" color="grey400" ><FormattedMessage {...messages.processingTime} /></Typography>
              </div>
              <Typography variant="body2" className={classes.chipTxt} color="grey600" >
                {data.delivery_time || ''}
              </Typography>
            </div>
          </>

    </div>
  );
}
TransferFundsQuote.defaultProps = {
  data: {}
}
TransferFundsQuote.propTypes = {
  data: PropTypes.any,

};

export default memo(TransferFundsQuote);
