
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Button as MuiButton } from '@material-ui/core';
import combineClx from 'utils/combineClx';
import { Typography } from '.';

// const getBorder = (props, theme) => {
//   let border = '';
//   if(props.variant === 'outlined')
//     border = `2.5px solid ${theme.palette.primary.main}`;

//   if(props.color === 'secondary' && props.variant === 'outlined' )
//     border = `2.5px solid ${theme.palette.secondary.main}`;

//   return border;
// }

const isDifferentColor = (props, theme) => {

  if(!theme.palette[props.color]) return {};
  const btnColor = theme.palette[props.color][props.colorShade || 'main'];

  
  let obj = {
    '&:hover': {
      backgroundColor: btnColor,
      borderColor: 'none',
      boxShadow: 'none',
    },
    '&:active': {
      // boxShadow: 'none',
      // backgroundColor: 'transparent !important',
      // borderColor: 'transparent !important',
    },
    '&:focus': {
      // boxShadow: `0 0 0 0.2rem ${theme.palette[props.color].main}`,
    },
  };
  // if (props.variant !== 'outlined' && props.variant !== 'text') {
  if (!['default', 'inherit', 'primary', 'secondary'].includes(props.color) && props.variant === 'contained') {
    obj = {
      backgroundColor: btnColor,
      borderColor: btnColor,
      color: theme.palette[props.color].contrastText,
      '&:hover': {
        backgroundColor: btnColor,
        borderColor: btnColor,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: btnColor,
        borderColor: btnColor,
      },
      '&:focus': {
        // boxShadow: `0 0 0 0.2rem ${btnColor}`,
      },
    };
  } else if (!['default', 'inherit', 'primary', 'secondary'].includes(props.color) && props.variant === 'outlined') {
    obj = {
      backgroundColor: theme.palette[props.color].contrastText,
      borderColor: btnColor,
      color: btnColor,
      '&:hover': {
        backgroundColor: theme.palette[props.color].contrastText,
        borderColor: btnColor,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette[props.color].contrastText,
        borderColor: btnColor,
      },
      '&:focus': {
        // boxShadow: `0 0 0 0.2rem ${btnColor}`,
      },
    }
  } else if (!['default', 'inherit', 'primary', 'secondary'].includes(props.color) && props.variant === 'text') {
    obj = {
      // backgroundColor: theme.palette[props.color].contrastText,
      // borderColor: btnColor,
      color: btnColor,
      '&:hover': {
        // backgroundColor: theme.palette[props.color].contrastText,
        // borderColor: btnColor,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        // backgroundColor: theme.palette[props.color].contrastText,
        // borderColor: btnColor,
      },
      // '&:focus': {
      //   boxShadow: `0 0 0 0.2rem ${btnColor}`,
      // },
    };
  } else if (props.variant === 'outlined') {
    obj = {
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: `${props.borderWidth || '2.5px'} solid ${theme.palette[props.color].main}`
      },
      '&:active': {
      },
      '&:focus': {
      },
    }
  } else if (props.variant === 'text') {
    obj = {
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none'
      },
      '&:active': {
      },
      '&:focus': {
      },
    };
  }
  return obj;
};


const useStyles = makeStyles(theme => ({
  btn: props => ({
    padding: theme.spacing(2),
    width: '100%',
    // maxWidth: '700px',
    borderRadius: theme.spacing(12.5),
    borderColor: theme.palette[props.color] ? theme.palette[props.color].main : undefined,
    height: theme.spacing(5.5),
    boxShadow: 'none',
    // boxShadow: ' 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    // borderWidth: theme.spacing(0.3125),
    borderWidth: props.borderWidth || '2.5px',
    '& .MuiButton-startIcon': {
      display: 'initial',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),

    },

    // '&:focus': {
    //   boxShadow: `0 0 0 0.2rem`,
    // },
    ...isDifferentColor(props, theme),
  }),
}));

function Button(props = {}) {
  const classes = useStyles(props);
  const { innerRef, id, type, variant, disabled, title, color, children, onClick, startIcon, endIcon, className } = props;
  return (
    <MuiButton
      id={id}
      type={type}
      variant={variant}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      className={combineClx(classes.btn, className)}
      color={color}
      ref={innerRef}
    >
      <Typography variant="button">{children || title}</Typography>
    </MuiButton>
  );
}

Button.defaultProps = {
  color: "primary",
  colorShade: "main",
  variant: "contained",
  disabled: false,
  title: 'Button',
  children: null,
  onClick: () => null,
  startIcon: null,
  className: '',
  classes: {},
  innerRef: null,
}

Button.propTypes = {
  title: PropTypes.any,
  onClick: PropTypes.func,
  startIcon: PropTypes.any,
  color: PropTypes.string,
  titleColor: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.any
};

export default memo(Button);
