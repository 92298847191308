import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { request, requestWithErrorFeedback, requestWithFeedback } from 'utils/request';
import {
  // resetAccountAction,
  getAccountListSetDataAction,
  getAccountDetailsSetDataAction,
  accountPaymentTransferSetDataAction,
  createCheckInAccountSetDataAction,
  accountPaymentListSetDataAction,
  getAccountCurrenciesSetDataAction,
  accountPaymentDetailSetDataAction,
  getCheckInAccountListSetDataAction,
  getSmartLinkAccountSetDataAction,
  getSmartLinkCardListSetDataAction,
  getSmartLinkAccountDetailsSetDataAction,
  getSmartLinkAccountPaymentsSetDataAction,
  activatePhysicalCardSetDataAction,
  getCardSecureDetailsSetDataAction,
  cardChangePinSetDataAction,
  cardLockSetDataAction,
  smartLinkAccLockSetDataAction,
  getAccountSettingSetDataAction,
  saveAccountSettingSetDataAction,
  getAccountTeamMembersSetDataAction,
  getTeamMemberDetailSetDataAction,
  addUpdateTeamMemberSetDataAction,
  createSmartLinkAccSetDataAction,
  getCardDetailsSetDataAction,
  getCardDetailsAction,
  issueSmartlinkCardsSetDataAction,
  getCardPaymentDetailSetDataAction,
  updateCardNickNameSetDataAction,
  quickTransferSetDataAction,
  preQuickTransferSetDataAction,
  getLinkAccListSetDataAction,
  // getCardDetailsAction,
  getExternalAccountsSetDataAction,
  addExternalAccountSetDataAction,
  getExternalAccDetailsSetDataAction,
  removeExternalAccountSetDataAction,
  getExternalAccPaymentListSetDataAction,
  getExternalAccBankTransactionsSetDataAction,
  getExternalAccountBalanceSetDataAction,
  unlinkExternalAccountSetDataAction,
  linkExternalAccountSetDataAction,
  getGlobalAccountListSetDataAction,
  csvGetPaymentListSetDataAction,
  submitPadVerificationSetDataAction,
  reAuthorizedExternalAccSetDataAction
} from './accountActions';
import {
  // RESET_ACCOUNTS,
  GET_ACCOUNT_LIST,
  GET_ACCOUNT_DETAILS,
  ACCOUNT_PAYMENT_TRANSFER,
  CREATE_CHECK_IN_ACCOUNT,
  ACCOUNT_PAYMENT_LIST,
  ACCOUNT_PAYMENT_DETAIL,
  GET_ACCOUNT_CURRENCIES,
  GET_CHECK_IN_ACCOUNT_LIST,
  GET_SMART_LINK_ACCOUNT_LIST,
  GET_SMART_LINK_CARD_LIST,
  GET_SMART_LINK_ACCOUNT_DETAILS,
  GET_SMART_LINK_ACCOUNT_PAYMENTS,
  ACTIVATE_PHYSICAL_CARD,
  GET_CARD_SECURE_DETAILS,
  CARD_CHANGE_PIN,
  CARD_LOCK,
  SMART_LINK_ACC_LOCK,
  GET_ACCOUNT_SETTING,
  SAVE_ACCOUNT_SETTING,
  GET_ACCOUNT_TEAM_MEMBERS,
  GET_TEAM_MEMBER_DETAIL,
  ADD_UPDATE_TEAM_MEMBER, CREATE_SMART_LINK_ACC,
  GET_CARD_DETAILS,
  ISSUE_SMARTLINK_CARDS,
  GET_CARD_PAYMENT_DETAIL,
  UPDATE_CARD_NICK_NAME,
  QUICK_TRANSFER,
  PRE_QUICK_TRANSFER,
  GET_LINK_ACC_LIST,
  GET_EXTERNAL_ACCOUNTS,
  ADD_EXTERNAL_ACCOUNT,
  GET_EXTERNAL_ACC_DETAILS,
  REMOVE_EXTERNAL_ACCOUNT,
  GET_EXTERNAL_ACC_PAYMENT_LIST,
  GET_EXTERNAL_ACC_BANK_TRANSACTIONS,
  GET_EXTERNAL_ACCOUNT_BALANCE,
  LINK_EXTERNAL_ACCOUNT,
  UNLINK_EXTERNAL_ACCOUNT,
  GET_GLOBAL_ACCOUNT_LIST,
  CSV_GET_PAYMENT_LIST,
  SUBMIT_PAD_VERIFICATION,
  RE_AUTHORIZED_EXTERNAL_ACC
} from './accountConstants';

import {
  ACCOUNT_BASE_API_URL, CARD_MANAGEMENT_BASE_API_URL, TEAM_MEMBER_BASE_API_URL, ACCOUNT_TRANSFER_BASE_URL, BANK_TRANSFER_BASE_URL
} from 'config/apiUrls';
import { recordPerPageWeb } from 'utils/constant';
import { getCurrencyListState } from 'store/Reference/referenceSelectors';
import { getCustomerDetailsState } from 'store/Register/registerSelectors';
import { setSelectedEntityState } from 'store/User/userSelectors';

const ACCOUNT_URL = `${ACCOUNT_BASE_API_URL}`;
const TEAM_MEMBER_URL = `${TEAM_MEMBER_BASE_API_URL}`;
const CARD_MANAGEMENT_URL = `${CARD_MANAGEMENT_BASE_API_URL}`;
const ACCOUNT_TRANSFER_URL = `${ACCOUNT_TRANSFER_BASE_URL}`;
const BANK_TRANSFER_URL = `${BANK_TRANSFER_BASE_URL}`;
// Individual exports for testing
export default function* accountSaga() {
  // add all sagas here
  yield takeLatest(ADD_EXTERNAL_ACCOUNT, addExternalAccountSaga);
  yield takeLatest(GET_EXTERNAL_ACCOUNTS, getExternalAccountsSaga);
  yield takeLatest(GET_CARD_DETAILS, getCardDetailsSaga);
  yield takeLatest(GET_CARD_SECURE_DETAILS, getCardSecureDetailsSaga);
  yield takeLeading(ACTIVATE_PHYSICAL_CARD, activatePhysicalCardSaga);
  yield takeLatest(GET_SMART_LINK_ACCOUNT_PAYMENTS, getSmartLinkAccountPaymentsSaga);
  yield takeLatest(GET_SMART_LINK_ACCOUNT_DETAILS, getSmartLinkAccountDetailsSaga);
  yield takeLatest(GET_ACCOUNT_LIST, accountListSaga);
  yield takeLatest(GET_ACCOUNT_DETAILS, accountDetailsSaga);
  yield takeLatest(ACCOUNT_PAYMENT_TRANSFER, quickPaymentTransferSaga);
  yield takeLatest(CREATE_CHECK_IN_ACCOUNT, createCheckInAccountSaga);
  yield takeLatest(ACCOUNT_PAYMENT_LIST, accountPaymentListSaga);
  yield takeLatest(ACCOUNT_PAYMENT_DETAIL, accountPaymentDetailSaga);
  yield takeLatest(GET_ACCOUNT_CURRENCIES, getAccountCurrenciesSaga);
  yield takeLatest(GET_CHECK_IN_ACCOUNT_LIST, getCheckInAccountListSaga);
  yield takeLatest(GET_SMART_LINK_ACCOUNT_LIST, getSmartLinkAccountListSaga);
  yield takeLatest(GET_SMART_LINK_CARD_LIST, getSmartLinkCardListSaga);
  yield takeLeading(CARD_CHANGE_PIN, cardChangePinSaga);
  yield takeLeading(CARD_LOCK, cardLockSaga);
  yield takeLeading(SMART_LINK_ACC_LOCK, smartLinkAccLockSaga);
  yield takeLatest(GET_ACCOUNT_SETTING, getAccountSettingSaga);
  yield takeLatest(SAVE_ACCOUNT_SETTING, saveAccountSettingSaga);
  yield takeLatest(GET_ACCOUNT_TEAM_MEMBERS, getAccountTeamMembersSaga);
  yield takeLatest(GET_TEAM_MEMBER_DETAIL, getTeamMemberDetailSaga);
  yield takeLatest(ADD_UPDATE_TEAM_MEMBER, addUpdateTeamMemberSaga);
  yield takeLatest(CREATE_SMART_LINK_ACC, createSmartLinkAccSaga);
  yield takeLatest(ISSUE_SMARTLINK_CARDS, issueSmartlinkCardsSaga);
  yield takeLatest(GET_CARD_PAYMENT_DETAIL, getCardPaymentDetailSaga);
  yield takeLatest(UPDATE_CARD_NICK_NAME, updateCardNickName);
  yield takeLatest(QUICK_TRANSFER, quickTransferSaga);
  yield takeLatest(PRE_QUICK_TRANSFER, preQuickTransferSaga);
  yield takeLatest(GET_LINK_ACC_LIST, getLinkAccListSaga);
  yield takeLatest(GET_EXTERNAL_ACC_DETAILS, getExternalAccDetailsSaga);
  yield takeLatest(REMOVE_EXTERNAL_ACCOUNT, removeExternalAccountSaga);
  yield takeLatest(GET_EXTERNAL_ACC_PAYMENT_LIST, getExternalAccPaymentListSaga);
  yield takeLatest(GET_EXTERNAL_ACC_BANK_TRANSACTIONS, getExternalAccBankTransactionsSaga);
  yield takeLatest(GET_EXTERNAL_ACCOUNT_BALANCE, getExternalAccountBalanceSaga);
  yield takeLatest(LINK_EXTERNAL_ACCOUNT, linkExternalAccountSaga);
  yield takeLatest(UNLINK_EXTERNAL_ACCOUNT, unlinkExternalAccountSaga);
  yield takeLatest(GET_GLOBAL_ACCOUNT_LIST, getGlobalAccountListSaga);
  yield takeLatest(CSV_GET_PAYMENT_LIST, csvGetPaymentListSaga);
  yield takeLatest(SUBMIT_PAD_VERIFICATION, submitPadVerificationSaga);
  yield takeLatest(RE_AUTHORIZED_EXTERNAL_ACC, reAuthorizedExternalAccSaga);
}

export function* accountListSaga(args = {}) {

  let currenciesArray = yield select(getCurrencyListState());
  let customerDetails = yield select(getCustomerDetailsState());
  const { company_details = {} } = customerDetails.data || {};

  const requestUrl = `${ACCOUNT_URL}/accounts`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getAccountListSetDataAction(result, (company_details.incorporation_country_code || ""), (currenciesArray.data || [])));
  } catch (error) {
    console.error(error);
  }
}

export function* accountDetailsSaga(args = {}) {
  const { id } = args.data || {};
  let currenciesArray = yield select(getCurrencyListState());
  let customerDetails = yield select(getCustomerDetailsState());
  const { company_details } = customerDetails.data || {};

  const requestUrl = `${ACCOUNT_URL}/accounts/${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getAccountDetailsSetDataAction(result, (company_details.incorporation_country_code || ""), (currenciesArray.data || [])));
  } catch (error) {
    console.error(error);
  }
}

export function* quickPaymentTransferSaga(args = {}) {
  // const { id } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/accounts`;
  try {
    const result = yield call(request, requestUrl);
    yield put(accountPaymentTransferSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* createCheckInAccountSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/checking-accounts`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(createCheckInAccountSetDataAction(result));
    // if (result && result.data && result.data.account_id) cb();
    if (result.ok) cb();
  } catch (error) {
    console.error(error);
  }
}

export function* accountPaymentListSaga(args = {}) {
  const { id, page, endPoint } = args.data || {};
  const skip = page * recordPerPageWeb;
  const requestUrl = `${ACCOUNT_URL}/accounts/${id}/account-ledgers${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;

  try {
    const result = yield call(request, requestUrl);
    yield put(accountPaymentListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}
export function* accountPaymentDetailSaga(args = {}) {
  const { accountId, accountLedgerId } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/accounts/${accountId}/account-ledgers/${accountLedgerId}`;
  try {
    const result = yield call(request, requestUrl,);
    yield put(accountPaymentDetailSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
export function* getAccountCurrenciesSaga(args = {}) {
  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/accounts/available-currencies`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getAccountCurrenciesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getCheckInAccountListSaga(args = {}) {
  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/accounts`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCheckInAccountListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getSmartLinkAccountListSaga(args = {}) {
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getSmartLinkAccountSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getSmartLinkCardListSaga(args = {}) {
  const { accountId, cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}/cards`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getSmartLinkCardListSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getSmartLinkAccountDetailsSaga(args = {}) {
  const { accountId } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}`;
  let selectedEntity = yield select(setSelectedEntityState());
  try {
    const result = yield call(request, requestUrl);
    yield put(getSmartLinkAccountDetailsSetDataAction(result, selectedEntity));
  } catch (error) {
    console.error(error);
  }
}

export function* getSmartLinkAccountPaymentsSaga(args = {}) {
  const { accountId, page, endPoint } = args.data || {};
  const skip = page * recordPerPageWeb;

  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}/account-ledgers${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getSmartLinkAccountPaymentsSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}

export function* activatePhysicalCardSaga(args = {}) {
  const {
    accountId,
    body = {}, cb = () => null } = args.data || {};
  const requestUrl = `${CARD_MANAGEMENT_BASE_API_URL}/card-management/link-card`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(activatePhysicalCardSetDataAction(result));
    const cardId = result.data.card_issue_queue_id;
    if (cardId) {
      cb();
      yield put(getCardDetailsAction({ accountId, cardId, cb, }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* getCardSecureDetailsSaga(args = {}) {
  const { cardId, cb = () => null } = args.data || {};
  const requestUrl = `${CARD_MANAGEMENT_BASE_API_URL}/card-management/cards/${cardId}/secure-details`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl);
    yield put(getCardSecureDetailsSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* cardChangePinSaga(args = {}) {

  const { body, cardId, cb = () => null } = args.data || {};
  const requestUrl = `${CARD_MANAGEMENT_BASE_API_URL}/card-management/cards/${cardId}/pin`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    yield put(cardChangePinSetDataAction(result));
    // result.data.cardholder_card_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* cardLockSaga(args = {}) {
  const { cardId, locked, cb = () => null } = args.data || {};

  const endpoint = locked ? 'unblock' : 'temporary-block';
  const requestUrl = `${CARD_MANAGEMENT_BASE_API_URL}/card-management/cards/${cardId}/${endpoint}`;
  const options = { method: 'POST' };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(cardLockSetDataAction(result));
    // result.data.cardholder_card_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* smartLinkAccLockSaga(args = {}) {

  const { accountId, locked, cb = () => null } = args.data || {};
  const options = { method: 'POST' };
  const endpoint = locked ? 'activate' : 'deactivate';
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}/${endpoint}`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    yield put(smartLinkAccLockSetDataAction(result));
    // result.data.account_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getAccountSettingSaga(args = {}) {
  const { accountId } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getAccountSettingSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* saveAccountSettingSaga(args = {}) {
  const { teamMemberId, accountId, body, cb = () => null, rename = false } = args.data || {};

  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}`;
  const requestUrl2 = `${TEAM_MEMBER_URL}/team-members/${teamMemberId}`;
  const options = { method: 'PUT', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    // eslint-disable-next-line no-unused-vars
    if (!rename) {
      // eslint-disable-next-line no-unused-vars
      const result2 = yield call(requestWithFeedback, requestUrl2, options);
    }

    yield put(saveAccountSettingSetDataAction(result));
    // result.data.account_id && cb();
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getAccountTeamMembersSaga(args = {}) {
  const { endPoint = "", } = args.data || {};

  // const { _id, _cb = () => null } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/team-members${endPoint ? `${endPoint}` : ''}`;
  //const requestUrl = `${TEAM_MEMBER_URL}/team-members`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getAccountTeamMembersSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getTeamMemberDetailSaga(args = {}) {
  const { id } = args.data || {};
  const requestUrl = `${TEAM_MEMBER_URL}/team-members/${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getTeamMemberDetailSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* addUpdateTeamMemberSaga(args = {}) {
  const { id, body } = args.data || {};
  const options = { method: id ? "PUT" : "POST", body };
  const requestUrl = `${TEAM_MEMBER_URL}/team-members${id ? `/${id}` : " "}`;
  try {
    const result = yield call(request, requestUrl, options);
    yield put(addUpdateTeamMemberSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* createSmartLinkAccSaga(args = {}) {
  const { body } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts`;
  const options = { method: "POST", body };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(createSmartLinkAccSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getCardDetailsSaga(args = {}) {
  const { cardId, cb = () => null } = args.data || {};
  const requestUrl = `${CARD_MANAGEMENT_BASE_API_URL}/card-management/cards/${cardId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCardDetailsSetDataAction(result));
    cb();
  } catch (error) {
    console.error(error);
  }
}

export function* issueSmartlinkCardsSaga(args = {}) {
  const { body, cb } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${CARD_MANAGEMENT_URL}/card-management/requests`;
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    // result && result.data && result.data.card_issue_queue_id && cb();
    result.ok && cb();
    yield put(issueSmartlinkCardsSetDataAction(result));

  } catch (error) {
    console.error(error);
  }
}

export function* getCardPaymentDetailSaga(args = {}) {
  const { accountId, accountLedgerId } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/smartlink-accounts/${accountId}/account-ledgers/${accountLedgerId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCardPaymentDetailSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
export function* updateCardNickName(args = {}) {
  const { body, cb } = args.data || {};
  const options = { method: 'PUT', body };
  const requestUrl = `${CARD_MANAGEMENT_URL}/card-management/rename`;
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(updateCardNickNameSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* preQuickTransferSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_TRANSFER_URL}/account-transfers/pre-account-transfer`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(preQuickTransferSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* quickTransferSaga(args = {}) {
  const { body, cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_TRANSFER_URL}/account-transfers`;
  const options = { method: 'POST', body };
  try {
    const result = yield call(requestWithErrorFeedback, requestUrl, options);
    result.ok && cb();
    yield put(quickTransferSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getLinkAccListSaga(args = {}) {
  const { id } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/linked-accounts/${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getLinkAccListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getExternalAccountsSaga(args = {}) {
  const { cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/external-accounts`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getExternalAccountsSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* addExternalAccountSaga(args = {}) {
  const { cb = () => null, body } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/external-accounts`;
  const options = { method: 'POST', body, };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(addExternalAccountSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getExternalAccDetailsSaga(args = {}) {
  const { accountId, cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/external-accounts/${accountId}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getExternalAccDetailsSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* removeExternalAccountSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const options = { method: 'PUT' };
  const requestUrl = `${ACCOUNT_URL}/external-accounts/${id}/archive`;
  try {
    const result = yield call(request, requestUrl, options);
    yield put(removeExternalAccountSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* getExternalAccPaymentListSaga(args = {}) {
  const { accountId, page = 0, endPoint } = args.data || {};
  const skip = page * recordPerPageWeb;
  const requestUrl = `${ACCOUNT_URL}/external-accounts/truly-transactions/${accountId}${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getExternalAccPaymentListSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getExternalAccBankTransactionsSaga(args = {}) {
  const { id } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/external-accounts/non-truly-transactions/${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getExternalAccBankTransactionsSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getExternalAccountBalanceSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const requestUrl = `${ACCOUNT_URL}/external-accounts/balance/${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getExternalAccountBalanceSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* linkExternalAccountSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const options = { method: 'PUT' };
  const requestUrl = `${ACCOUNT_URL}/external-accounts/${id}/link`;
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    result.ok && cb();
    yield put(linkExternalAccountSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* unlinkExternalAccountSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const options = { method: 'PUT' };
  const requestUrl = `${ACCOUNT_URL}/external-accounts/${id}/unlink`;

  try {
    const result = yield call(request, requestUrl, options);
    result.ok && cb();
    yield put(unlinkExternalAccountSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getGlobalAccountListSaga(args = {}) {

  const requestUrl = `${BANK_TRANSFER_URL}/bank-accounts`;
  let currenciesArray = yield select(getCurrencyListState());
  let customerDetails = yield select(getCustomerDetailsState());
  const { company_details } = customerDetails.data || {};

  try {
    const result = yield call(request, requestUrl);
    yield put(getGlobalAccountListSetDataAction(result, (company_details.incorporation_country_code || ""), (currenciesArray.data || [])));
  } catch (error) {
    console.error(error);
  }
}

export function* csvGetPaymentListSaga(args = {}) {
  const { endPoint, cb = () => null } = args.data || {};
  const requestUrl = `${endPoint}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(csvGetPaymentListSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* submitPadVerificationSaga(args = {}) {
  const { id, body, cb = () => null } = args.data || {};
  const options = { method: 'POST', body };
  const requestUrl = `${ACCOUNT_URL}/external-accounts/${id}/generate-account-agreement`;
  try {
    const result = yield call(request, requestUrl, options);
    yield put(submitPadVerificationSetDataAction(result));
    result.ok && cb();
  } catch (error) {
    console.error(error);
  }
}

export function* reAuthorizedExternalAccSaga(args = {}) {
  const { id, cb = () => null, body } = args.data || {};
  const options = { method: 'PUT', body };
  const requestUrl = `${ACCOUNT_URL}/external-accounts/${id}/reauthorize`;
  try {
    const result = yield call(request, requestUrl, options);
    result.ok && cb();
    yield put(reAuthorizedExternalAccSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
