
import React, { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from 'components/ui';
import message from 'components/intl/SendAmountIntl';
import { FormattedMessage } from 'react-intl';
import NumberPad from 'components/AmountTextFiled';
import Button from 'components/ui/Button';
import PropTypes from 'prop-types';
import DisplayPictureWithCountry from 'components/DisplayPictureWithCountry';
import { Loader } from 'components';
import DialogSubFooter from './DialogSubFooter';
import { useRefHeight } from 'utils/hooks';
import DialogDynamicBody from './DialogDynamicBody';
import { getAvatarTxt } from 'utils/common';
import { ENTITY } from '@truly-financial/common/consts'
const useStyles = makeStyles((theme) => ({
  root: {
    // position: 'relative',
  },
  amountTxt: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  profileDiv: {
    display: "flex",
    padding: theme.spacing(1.2),
    flexDirection: "row",
    justifyContent: "flex-start",
    border: `1px solid ${theme.palette.grey100}`,
    margin: theme.spacing(3, 4, 3, 4),
    borderRadius: theme.spacing(2),
    alignItems: 'center',
    height: '75px'
  },
  avtar: {
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(6.25),
      width: theme.spacing(6.25),
    },
    height: theme.spacing(3.75),
    width: theme.spacing(3.75),
    marginRight: theme.spacing(2),
  },
  rowDireaction: {
    flexDirection: "row",
    display: "flex",
  },
  paymentOptionDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    height: theme.spacing(10),
    paddingLeft: theme.spacing(3),
  },
  paymentOp: {
    marginRight: theme.spacing(4),
  },
  businessNameTxt: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  sendFromTxt: {
    color: theme.palette.text.secondary,
  },
  cardDiv: {
    flexDirection: "row",
    display: "flex",
    width: "fit-content",
    height: theme.spacing(6.25),
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.04);",
    justifyContent: "center",
    flex: 1,
    margin: "auto",
    borderRadius: theme.spacing(1),
  },
  sendFromDiv: {
    backgroundColor: " rgba(33, 33, 33, 0.04)",
    borderRadius: theme.spacing(1, 0, 0, 1),
    display: "flex",
    flex: 1,
    height: "inherit",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  accMainDiv: {
    display: "flex",
    justifyContent: "space-between",
    height: "inherit",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: theme.spacing(0, 1, 1, 0),
  },
  accDetails: {
    padding: theme.spacing(1.5, 1.5, 0, 1.5),
  },
  btnDiv: {
    // width: "60%",
    margin: theme.spacing(0, 4, 1, 4),
    [theme.breakpoints.down('sm')]: {
      position: 'initial',
    },
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // bottom: theme.spacing(2),
  },
  accIconDiv: {
    padding: theme.spacing(1.5, 0, 1.5, 1.5),
  },
  arrowDiv: {
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
  },
  sendTo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2.5),
    marginTop: '-2px'
  },
  sendToTxt: {
    color: theme.palette.grey.dark
  },
  individual: {
    marginTop: '-5px'
  }
}));

function SendAmount(props = {}) {

  const { btnTitleOne, action, contact,
    onSelectCurrency,
    getCurrencies, currencies,
    amount, setAmount, selectedCurrency, setSelectedCurrency, contactBankAccounts,
    payerBank
  } = props;

  const classes = useStyles();
  const [error, setError] = useState("");
  const [footerHeight, footerHeightRef] = useRefHeight();
  // eslint-disable-next-line no-unused-vars
  const [accountList, setAccountList] = useState([]);

  const contactData = contact || {};

  const onNext = () => {
    const amt = Number(amount);
    if (!selectedCurrency.currency_code) {
      setError("Select currency");
      return;
    } else if (!amt || isNaN(amt) || 0 > amt) {
      setError("Enter a minimum amount of $1");
      return;
    }
    onSelectCurrency(selectedCurrency);
    action("callBack from send amount");
  };
  const currencySelect = (currency) => {
    setSelectedCurrency(currency);
  };
  const onNumberPressAction = () => {
    setError("");
  };
  return (<>
    <DialogDynamicBody headerHeight={16} footerHeight={footerHeight} fixed>
      <div className={classes.root}>
        <Typography variant="h6" color="grey700" className={classes.amountTxt}><FormattedMessage {...message.amount} /></Typography>
        <div className={classes.profileDiv} >
          <div className={classes.sendTo}>
            <Typography variant="body1" className={classes.sendToTxt} ><FormattedMessage {...message.sendToLabel} /></Typography>
          </div>
          {(contactData.loading) ? <div className={classes.preSendLoader}><Loader /></div> :
            <DisplayPictureWithCountry mobileListView={true} countryCode={(contactData && contactData.data && contactData.data.country_code) || (contactData && contactData.data && contactData.data.mobile_country_code)} imageUrl={contactData.data.image_url} companyName={getAvatarTxt(contactData.data)} />}
          <div>
            <Typography color="grey600" variant="subtitle1" className={contactData.data.entity_type === 'INDIVIDUAL' && classes.individual} >
              {contactData.data.entity_type === ENTITY.ENTITY_TYPE.COMPANY && contactData.data.company_name}
              {contactData.data.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL && <>{(contactData.data.first_name || " ") + " " + (contactData.data.last_name || " ")} </>}
            </Typography>
            <Typography color="grey400" variant="body2" className={contact && contact.data && classes.brand_name}>
              {contactData.data.entity_type === ENTITY.ENTITY_TYPE.COMPANY && contactData.data.contact_person_name}
              {contactData.data.entity_type === ENTITY.ENTITY_TYPE.INDIVIDUAL && contactData.data.company_name}
            </Typography>
          </div>
        </div>
        <div className={classes.numberTabDiv} >
          <NumberPad
            onCurrencySelect={(currency) => { currencySelect(currency) }}
            getCurrencies={getCurrencies}
            currencies={currencies}
            amount={amount}
            setAmount={setAmount}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            onNumberPressed={onNumberPressAction}
            contactBankAccounts={contactBankAccounts}
            payerBank={payerBank}
            error={error}
          />
        </div>
      </div>

    </DialogDynamicBody>
    <div>
      <DialogSubFooter innerRef={footerHeightRef} fixed>
        <div className={classes.btnDiv}>
          <Button
            title={btnTitleOne}
            onClick={onNext}
            disable={!amount}
          />
        </div>
      </DialogSubFooter>
    </div>
  </>);
}

SendAmount.defaultProps = {
  getCurrencies: () => { },
  onSelectCurrency: () => { },
  btnTitleOne: "Send",
}

SendAmount.propTypes = {
  btnTitleOne: PropTypes.string,
  action: PropTypes.func,
  contact: PropTypes.any,
  onSelectCurrency: PropTypes.func,
  getCurrencies: PropTypes.func,
  currencies: PropTypes.any,
  amount: PropTypes.any,
  setAmount: PropTypes.any,
  selectedCurrency: PropTypes.any,
  setSelectedCurrency: PropTypes.any,
};

export default memo(SendAmount);
