import {
  SHOW_SNACKBAR,
  CLOSE_SNACKBAR,
} from './snackbarConstants';

export function actionShowSnackbar(data={}) {
  return {
    type: SHOW_SNACKBAR,
    data,
  };
}

export function actionCloseSnackbar(data={}) {
  return {
    type: CLOSE_SNACKBAR,
    data,
  };
}

