import produce from 'immer';

import {
  SHOW_SNACKBAR,
  CLOSE_SNACKBAR,
} from './snackbarConstants';

export const initialState = {
  show: false,
  severity: 'info',
  message: '',
};

/* eslint-disable default-case, no-param-reassign */
const snackbarReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SHOW_SNACKBAR:
        draft.show = true;
        draft.message = action.data.message;
        draft.severity = action.data.severity;
        break;
      case CLOSE_SNACKBAR:
        draft.show = false;
        // draft.severity = 'info';
        // draft.message = '';
        break;
    }
  });

export default snackbarReducer;
