/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button } from 'components/ui';
import React, { memo, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core/';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FormattedMessage } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { acceptConfidentialAgreementAction, getTermAndConditionAction } from 'store/Register/registerActions';
import { acceptConfidentialAgreementState, getCustomerDetailsState, getTermAndConditionState, saveConfidentialityAgreementState } from 'store/Register/registerSelectors';
import { Loader } from 'components';
import parse from 'html-react-parser';
import { Skeleton } from '@material-ui/lab';
import GoBack from '../GoBack';
import { useHistory } from 'react-router-dom';
import Auth from "@aws-amplify/auth";
import { restoreAndRemoveBackupEntityId } from 'utils/auth';

// import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
// import { ANALYTICS } from '@truly-financial/common/consts';

// import GoBack from '../GoBack';
// import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
// const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;
// import { getDirtyValues } from 'utils/common';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.primary.contrastText,
        display: 'flex',
        flex: 1
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',

    },
    subContent: {
        // display: 'grid',
        // height: '60vh',
        display: 'flex',
        flexDirection: 'column'
    },
    align: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textCenter: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    card: (props) => ({
        background: '#F2F3F4',
        borderRadius: '8px',
        margin: theme.spacing(5, 0),
        width: 'inherit',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3, 0),
        },
        boxShadow: props.error ? `inset 0px 0px 0px 1px ${theme.palette.error.main}` : 'none',
    }),
    cardContent: {
        margin: theme.spacing(3, 7.5, 5.5, 7.5),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3),
        }
    },

    mainTitle: {

    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),

    },
    descTxt: {
        fontWeight: 400,
        fontSize: theme.spacing(1.75),
        lineHeight: `${theme.spacing(2.625)}px`
    },
    descMargin: {
        marginTop: theme.spacing(3)
    },
    skeletonDiv: {
        borderRadius: theme.spacing(1),
        height: theme.spacing(40),
        width: '100%',
        flex: 1,
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            borderRadius: theme.spacing(1),
            height: theme.spacing(42),
            width: '100%',
            flex: 1,
        },
    },
    skeleton: {
        borderRadius: theme.spacing(1),
        height: theme.spacing(40),
        width: 'auto',
        flex: 1,
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            borderRadius: theme.spacing(1),
            height: theme.spacing(42),
            display: 'flex',
            flex: 1,
        },
    },
    lastMainGrid: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            display: 'flex',
            flex: 1,
            alignItems: 'flex-end'
        },

    },
    btnDiv: {
        flex: 1,
    }
}));

function ConfidentialAgreement(props = {}) {
    const { onContinue = () => null, getCustomerDetailsState, getTermAndConditionState, getTermAndConditionAction, acceptConfidentialAgreementState } = props;

    const classes = useStyles(props);
    const theme = useTheme();
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [formData, setFormData] = useState({});
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const confidentialAgreementData = getTermAndConditionState.data.find(i => i.name === 'CONFIDENTIAL_AGREEMENT') || {};
    // eslint-disable-next-line no-unused-vars
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const agreement = confidentialAgreementData.description || '';
    const isLoading = ((getTermAndConditionState && getTermAndConditionState.loading) || (acceptConfidentialAgreementState && acceptConfidentialAgreementState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading));

    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState('');

    // useEffect(() => {
    //     analyticsTrackApi({ event: ANALYTICS.EVENTS.INTRODUCTION_UNDERSTAND_PAGE_LOAD.event })
    // }, [])

    useEffect(() => {
        if (customerDetail && customerDetail.completed_steps && customerDetail.completed_steps.includes("CONFIDENTIAL_AGREEMENT")) {
            onContinue("onBoarding");
        }
        getLoginUser();
        if (!agreement) {
            getTermAndConditionAction();
        }
    }, []);

    const getLoginUser = () => {

        Auth.currentAuthenticatedUser().then((res) => {
            setIsUserLoggedIn(true);
        }).catch((e) => {
            setIsUserLoggedIn(false);
        });

    };
    const onSuccess = (arg = "onBoarding") => {
        onContinue(arg);
    };
    const onSubmit = () => {
        // acceptConfidentialAgreement({
        //     body: confidentialAgreementData,
        //     cb: onSuccess
        // });
        // if (customerDetail.confidential_agreement_accepted === null && customerDetail.confidential_agreement_timestamp === null) {
        // if (Array.isArray(customerDetail.completed_steps) && !customerDetail.completed_steps.includes(CONFIDENTIAL_AGREEMENT)) {
        //     acceptConfidentialAgreement({
        //         body: confidentialAgreementData,
        //         cb: onSuccess
        //     });
        // } else {
        //     onSuccess("header");
        // }

        // analyticsTrackApi({ event: ANALYTICS.EVENTS.INTRODUCTION_UNDERSTAND_CLICK.event })

        onSuccess("header");
    };

    const onBackPress = () => {
        restoreAndRemoveBackupEntityId();
        setTimeout(() => {
            history.push('/');
        }, 500);
    };

    return (<>
        <div className={classes.root}>
            {
                isLoading && <Loader />
            }
            <Grid container justifyContent="center" className={classes.content}>
                <Grid item xs="11" md="7" className={classes.subContent}>
                    <Grid container justifyContent="center">
                        <Grid item md="8" className={classes.textCenter}>
                            <div>
                                <Typography className={classes.mainTitle} variant={smDownMatches ? "h6" : "h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep1Title} /></Typography>
                            </div>
                            {/* <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep1SubTitle} /></Typography> */}
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center">
                        <Grid item md="8" className={classes.card}>
                            {

                                getTermAndConditionState && getTermAndConditionState.loading && getTermAndConditionState.data.length === 0 ?
                                    <div className={classes.skeletonDiv}>
                                        <Skeleton className={classes.skeleton} variant="rect" />
                                    </div>

                                    : <div className={classes.cardContent}>
                                        <Typography className={classes.descTxt} variant="subtitle1" color="grey600">{parse(agreement)}</Typography>
                                    </div>
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" className={classes.lastMainGrid}>
                        {isUserLoggedIn && <GoBack onClick={onBackPress} isLoading={isLoading} />}
                        <Grid item md="2" className={classes.btnDiv}>
                            <Button
                                color="secondary"
                                disabled={isLoading}
                                size="small" onClick={onSubmit}
                                className={classes.btnDiv}
                            >
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep1ContinueBtn} />
                                    {/* <NavigateNextIcon /> */}
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </>);
}

const mapStateToProps = createStructuredSelector({
    getTermAndConditionState: getTermAndConditionState(),
    saveConfidentialityAgreementState: saveConfidentialityAgreementState(),
    acceptConfidentialAgreementState: acceptConfidentialAgreementState(),
    getCustomerDetailsState: getCustomerDetailsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getTermAndConditionAction: data => dispatch(getTermAndConditionAction(data)),
        acceptConfidentialAgreement: data => dispatch(acceptConfidentialAgreementAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(ConfidentialAgreement);