

import { defineMessages } from 'react-intl';

export const scope = 'src.components.drawer';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Drawer component!',
  },
  account: {
    id: `${scope}.account`,
    defaultMessage: 'Accounts',
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contacts',
  },
  payment: {
    id: `${scope}.contact`,
    defaultMessage: 'Payments',
  },
  setting: {
    id: `${scope}.setting`,
    defaultMessage: 'Settings',
  },
  teamMember: {
    id: `${scope}.teamMember`,
    defaultMessage: 'Team Members',
  },
  dashBoard: {
    id: `${scope}.dashBoard`,
    defaultMessage: 'DashBoard',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  integrations: {
    id: `${scope}.integrations`,
    defaultMessage: 'Integrations',
  }
});
