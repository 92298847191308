/* eslint-disable */
// This is derived from aws-exports.js which auto generate on amplify pull.
// to make it env specific

const {
    REACT_APP_AWS_COGNITO_REGION,
    REACT_APP_AWS_USER_POOL_ID,
    REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_SCOPE,
} = process.env;

const awsConfig = {
    "aws_project_region": REACT_APP_AWS_COGNITO_REGION,
    "aws_cognito_region": REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": REACT_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "oauth": {
        "domain": REACT_APP_AUTH_DOMAIN,
        "scope": typeof REACT_APP_AUTH_SCOPE === 'string' && REACT_APP_AUTH_SCOPE.split(" ") || [],
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
};

export default awsConfig;
