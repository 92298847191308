
import produce from 'immer';
import {
  RESET_REFERENCES,
  GET_COUNTRY_LIST, GET_COUNTRY_LIST_SET_DATA,
  GET_RELATIONSHIP_LIST, GET_RELATIONSHIP_LIST_SET_DATA,
  GET_CURRENCY_LIST, GET_CURRENCY_LIST_SET_DATA,
  GET_PURPOSE_LIST, GET_PURPOSE_LIST_SET_DATA,
  SEARCH_LOCATION, SEARCH_LOCATION_SET_DATA,
  GET_BANK_ACCOUNT_CONFIG, GET_BANK_ACCOUNT_CONFIG_SET_DATA,
  GET_STATE_LIST, GET_STATE_LIST_SET_DATA,
  GET_PAYMENT_STATUS, GET_PAYMENT_STATUS_SET_DATA, GET_ROLE_LIST_SET_DATA, GET_ROLE_LIST,
  GET_JURISDICTION_LIST, GET_JURISDICTION_LIST_SET_DATA,
  GET_BUSINESS_TYPE_LIST, GET_BUSINESS_TYPE_LIST_SET_DATA,
  GET_NUMBER_OF_EMPLOYEES_LIST, GET_NUMBER_OF_EMPLOYEES_LIST_SET_DATA,
  GET_INDUSTRIES_LIST, GET_INDUSTRIES_LIST_SET_DATA,
  GET_BUSINESS_BELONG_LIST_SET_DATA, GET_BUSINESS_BELONG_LIST,
  GET_PRIMARY_USE_CASE_LIST, GET_PRIMARY_USE_CASE_LIST_SET_DATA,
  GET_PRIMARY_SOURCE_OF_FUNDING_LIST, GET_PRIMARY_SOURCE_OF_FUNDING_LIST_SET_DATA,
  GET_DOCUMENT_TYPE_LIST, GET_DOCUMENT_TYPE_LIST_SET_DATA,
  GET_ACCESS_ROLE_LIST_SET_DATA, GET_ACCESS_ROLE_LIST
} from './referenceConstants';

export const initialState = {
  getAccessRoleListState: { data: {}, loading: false, error: '' },
  getDocumentTypeListState: { data: {}, loading: false, error: '' },
  getBusinessBelongListState: { data: [], loading: false, error: '' },
  getRoleListState: { data: [], loading: false, error: '' },
  getPaymentStatusState: { data: [], loading: false, error: '' },
  // getStateListState: { data: [], loading: false, error: '' },
  getStateListState: { 'ALL': { data: [], loading: false, error: '' } },
  getBankAccountConfigState: { data: [], currencies: [], loading: false, error: '' },
  searchLocationState: { 'ALL': { data: [{ fixedOption: true, disabled: true, description: " " }], loading: false, error: '' } },
  // searchLocationState: { data: [{ fixedOption: true, disabled: true, description: " " }], loading: false, error: '' },
  selectGetCurrencyState: { data: [], loading: false, error: '' },
  getPurposeListState: { data: [], loading: false, error: '' },
  getCurrencyListState: { data: [], loading: false, error: '' },
  getRelationshipListState: { data: [], loading: false, error: '' },
  getCountryListState: { data: [], loading: false, error: '' },
  getDestinationCountryListState: { data: [], loading: false, error: '' },
  getJurisdictionListState: { data: [], loading: false, error: '' },
  getBusinessTypeListState: { data: [], loading: false, error: '' },
  getNumberOfEmployeesListState: { data: [], loading: false, error: '' },
  getIndustriesListState: { data: [], loading: false, error: '' },
  getPrimaryUseCaseListState: { data: [], loading: false, error: '' },
  getPrimarySourceOfFundingListState: { data: [], loading: false, error: '' }
};

/* eslint-disable default-case, no-param-reassign */
const referencesReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ACCESS_ROLE_LIST:
        draft.getAccessRoleListState = {
          ...draft.getAccessRoleListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_ACCESS_ROLE_LIST_SET_DATA:
        draft.getAccessRoleListState = {
          ...draft.getAccessRoleListState,
          ...action.data, loading: false,
        };
        break;
      case GET_STATE_LIST:
        const c = action.data.countryCode;
        draft.getStateListState = {
          ...draft.getStateListState,
          [c]: {
            data: [],
            loading: true, message: '', error: '',
          }
        };
        break;
      case GET_STATE_LIST_SET_DATA:
        const cc = action.data.countryCode;
        draft.getStateListState = {
          ...draft.getStateListState,
          [cc]: {
            data: [...action?.data?.data, { state_code: "OTHER", state_name: "Other" }],
            loading: false,
            fetched: true,
          }
        };
        break;
      case GET_BANK_ACCOUNT_CONFIG:
        draft.getBankAccountConfigState = {
          ...draft.getBankAccountConfigState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_BANK_ACCOUNT_CONFIG_SET_DATA:
        draft.getBankAccountConfigState = {
          ...draft.getBankAccountConfigState,
          ...action.data, loading: false,
          currencies: action.data.data.filter((elem, index) => action.data.data.findIndex(obj => obj.currency_code === elem.currency_code) === index),
        };
        break;
      case SEARCH_LOCATION:
        const cId = action.data.compId;
        draft.searchLocationState = {
          ...draft.searchLocationState,
          [cId]: {
            data: [],
            loading: true, message: '', error: '',
          }
          // loading: true, message: '', error: '', data: [],
        };
        break;
      case SEARCH_LOCATION_SET_DATA:
        const ccId = action.data.compId;
        const locations = action.data.data;
        let results = Array.isArray(locations) && locations.map(i => {
          let addressLine1Arr = (i && i.description && i.description.match(/([^,]*),(.*)/)) || [];
          return {
            ...i,
            address_line_1: (addressLine1Arr && addressLine1Arr[1]) || '',
          };
        });
        if (locations.length >= 1 && !action.data.reset) {
          results = [{ fixedOption: true, description: ' ' }, ...results];
        } else if (!locations.length) {
          results = [initialState.searchLocationState['ALL'].data[0], ...results];
        }
        draft.searchLocationState = {
          ...draft.searchLocationState,
          [ccId]: {
            ...action.data,
            loading: false,
            data: results,
          }
          // ...action.data, loading: false,

        };
        break;
      case GET_DOCUMENT_TYPE_LIST:
        draft.getDocumentTypeListState = {
          ...draft.getDocumentTypeListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_DOCUMENT_TYPE_LIST_SET_DATA:
        draft.getDocumentTypeListState = {
          ...draft.getDocumentTypeListState,
          ...action.data, loading: false,
        };
        break;
      case GET_PURPOSE_LIST:
        draft.getPurposeListState = {
          ...draft.getPurposeListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_PURPOSE_LIST_SET_DATA:
        draft.getPurposeListState = {
          ...draft.getPurposeListState,
          ...action.data, loading: false,
        };
        break;
      case GET_ROLE_LIST:
        draft.getRoleListState = {
          ...draft.getRoleListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_ROLE_LIST_SET_DATA:
        draft.getRoleListState = {
          ...draft.getRoleListState,
          ...action.data, loading: false,
        };
        break;
      case GET_CURRENCY_LIST:
        draft.getCurrencyListState = {
          ...draft.getCurrencyListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_CURRENCY_LIST_SET_DATA:
        draft.getCurrencyListState = {
          ...draft.getCurrencyListState,
          ...action.data, loading: false,
        };
        break;
      case GET_RELATIONSHIP_LIST:
        draft.getRelationshipListState = {
          ...draft.getRelationshipListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_RELATIONSHIP_LIST_SET_DATA:
        draft.getRelationshipListState = {
          ...draft.getRelationshipListState,
          ...action.data, loading: false,
        };
        break;
      case GET_COUNTRY_LIST:
        draft.getCountryListState = {
          ...draft.getCountryListState,
          loading: true, message: '', error: '',
        };
        draft.getDestinationCountryListState = {
          ...draft.getDestinationCountryListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_COUNTRY_LIST_SET_DATA:
        const dstCountries = action.data && action.data.data.filter(i => i.is_destination === 'ACTIVE');
        draft.getCountryListState = {
          ...draft.getCountryListState,
          ...action.data, loading: false,
        };
        draft.getDestinationCountryListState = {
          ...draft.getDestinationCountryListState,
          ...action.data, loading: false,
          data: dstCountries,
        };
        break;
      case GET_PAYMENT_STATUS:
        draft.getPaymentStatusState = {
          ...draft.getPaymentStatusState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_PAYMENT_STATUS_SET_DATA:
        draft.getPaymentStatusState = {
          ...draft.getPaymentStatusState,
          ...action.data, loading: false,
        };
        break;
      case GET_JURISDICTION_LIST:
        draft.getJurisdictionListState = {
          ...draft.getJurisdictionListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_JURISDICTION_LIST_SET_DATA:
        draft.getJurisdictionListState = {
          ...draft.getJurisdictionListState,
          ...action.data, loading: false
        };
        break;
      case GET_BUSINESS_TYPE_LIST:
        draft.getBusinessTypeListState = {
          ...draft.getBusinessTypeListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_BUSINESS_TYPE_LIST_SET_DATA:
        draft.getBusinessTypeListState = {
          ...draft.getBusinessTypeListState,
          ...action.data, loading: false
        };
        break;
      case GET_NUMBER_OF_EMPLOYEES_LIST:
        draft.getNumberOfEmployeesListState = {
          ...draft.getNumberOfEmployeesListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_NUMBER_OF_EMPLOYEES_LIST_SET_DATA:
        draft.getNumberOfEmployeesListState = {
          ...draft.getNumberOfEmployeesListState,
          ...action.data, loading: false
        };
        break;
      case GET_INDUSTRIES_LIST:
        draft.getIndustriesListState = {
          ...draft.getIndustriesListState,
          loading: true, message: '', error: ''
        };
        break;
      case GET_INDUSTRIES_LIST_SET_DATA:
        draft.getIndustriesListState = {
          ...draft.getIndustriesListState,
          ...action.data, loading: false
        };
        break;
      case GET_BUSINESS_BELONG_LIST:
        draft.getBusinessBelongListState = {
          ...draft.getBusinessBelongListState,
          loading: true, message: '', error: '',
        };
        break;
      case GET_BUSINESS_BELONG_LIST_SET_DATA:
        draft.getBusinessBelongListState = {
          ...draft.getBusinessBelongListState,
          ...action.data, loading: false
        };
        break;
      case GET_PRIMARY_USE_CASE_LIST:
        draft.getPrimaryUseCaseListState = {
          ...draft.getPrimaryUseCaseListState,
          loading: true, message: '', error: ''
        };
        break;
      case GET_PRIMARY_USE_CASE_LIST_SET_DATA:
        draft.getPrimaryUseCaseListState = {
          ...draft.getPrimaryUseCaseListState,
          ...action.data, loading: false
        };
        break;
      case GET_PRIMARY_SOURCE_OF_FUNDING_LIST:
        draft.getPrimarySourceOfFundingListState = {
          ...draft.getPrimarySourceOfFundingListState,
          loading: true, message: '', error: ''
        };
        break;
      case GET_PRIMARY_SOURCE_OF_FUNDING_LIST_SET_DATA:
        draft.getPrimarySourceOfFundingListState = {
          ...draft.getPrimarySourceOfFundingListState,
          ...action.data, loading: false
        };
        break;

      case RESET_REFERENCES:
        draft = initialState;
        break;
    }
  });

export default referencesReducer;
