import { defineMessages } from 'react-intl';

export const scope = 'src.components.OnBoarding';

export default defineMessages({
    onBoardingStepTitle: {
        id: `${scope}.onBoardingStepTitle`,
        defaultMessage: 'Nice to Meet You!',
        description: 'label of onBoardingStep',
    },
    onBoardingStepSubTitle: {
        id: `${scope}.onBoardingStepSubTitle`,
        defaultMessage: 'Introduce yourself, then tell us about your business',
        description: 'label of onBoardingStep',
    },

    onBoardingStepLabel: {
        id: `${scope}.onBoardingStepLabel`,
        defaultMessage: 'Nice to Meet You!',
        description: 'label of onBoardingStep',
    },

    onBoardingStepMyName: {
        id: `${scope}.onBoardingStepMyName`,
        defaultMessage: 'My name is',
        description: 'label of onBoardingStep',
    },
    onBoardingStepFirstName: {
        id: `${scope}.onBoardingStepFirstName`,
        defaultMessage: 'First Name',
        description: 'label of onBoardingStep',
    },
    onBoardingStepMiddleName: {
        id: `${scope}.onBoardingStepFirstName`,
        defaultMessage: 'Middle Name',
        description: 'label of onBoardingStep',
    },
    onBoardingStepOther: {
        id: `${scope}.onBoardingStepOther`,
        defaultMessage: `Your Business' Industry`,
        description: 'label of onBoardingStep',
    },
    onBoardingStepOtherSourceFund: {
        id: `${scope}.onBoardingStepOther`,
        defaultMessage: `Your Source of Funds`,
        description: 'label of onBoardingStep',
    },
    onBoardingStepOtherUse: {
        id: `${scope}.onBoardingStepOther`,
        defaultMessage: `Your Source Of Funding`,
        description: 'label of onBoardingStep',
    },
    onBoardingStepLastName: {
        id: `${scope}.onBoardingStepLastName`,
        defaultMessage: 'Last Name',
        description: 'label of onBoardingStep',
    },
    onBoardingStepAndI: {
        id: `${scope}.onBoardingStepAnd`,
        defaultMessage: 'and ',
        description: 'label of onBoardingStep',
    },
    onBoardingStepIAm: {
        id: `${scope}.onBoardingStepIAm`,
        defaultMessage: 'I am the',
        description: 'label of onBoardingStep',
    },
    onBoardingStepRole: {
        id: `${scope}.onBoardingStepRole`,
        defaultMessage: 'Position/Role',
        description: 'label of onBoardingStep',
    },
    onBoardingStepAt: {
        id: `${scope}.onBoardingStepAt`,
        defaultMessage: 'at',
        description: 'label of onBoardingStep',
    },
    onBoardingStepRegisterName: {
        id: `${scope}.onBoardingStepRegisterName`,
        defaultMessage: 'Registered Business Name',
        description: 'label of onBoardingStep',
    },
    onBoardingStepAlso: {
        id: `${scope}.onBoardingStepAlso`,
        defaultMessage: ' also ',
        description: 'label of onBoardingStep',
    },
    onBoardingStepKnownAs: {
        id: `${scope}.onBoardingStepAlso`,
        defaultMessage: 'known as',
        description: 'label of onBoardingStep',
    },
    onBoardingStepDoingBusiness: {
        id: `${scope}.onBoardingStepDoingBusiness`,
        defaultMessage: 'Doing Business As',
        description: 'label of onBoardingStep',
    },
    onBoardingStepBaseOut: {
        id: `${scope}.onBoardingStepBaseOut`,
        defaultMessage: ' based out of',
        description: 'label of onBoardingStep',
    },
    onBoardingStepCountry: {
        id: `${scope}.onBoardingStepCountry`,
        defaultMessage: 'Country',
        description: 'label of onBoardingStep',
    },
    onBoardingMobile: {
        id: `${scope}.onBoardingMobile`,
        defaultMessage: 'You can reach me at',
        description: 'label of onBoardingStep',
    },
    onBoardingStepContinueBtn: {
        id: `${scope}.onBoardingStepContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep',
    },
    onBoardingStepOption: {
        id: `${scope}.onBoardingStepOption`,
        defaultMessage: '(optional)',
        description: 'label of onBoardingStep',
    },
    onBoardingStepOptionMust: {
        id: `${scope}.onBoardingStepOptionMust`,
        defaultMessage: '(optional)',
        description: 'label of onBoardingStep',
    },
    onBoardingStepPhoneHelper: {
        id: `${scope}.onBoardingStepPhoneHelper`,
        defaultMessage: 'High Priority Message Only - No Spam',
        description: 'label of onBoardingStep',
    },
    onBoardingStepBusinessNameHint: {
        id: `${scope}.onBoardingStepBusinessNameHint`,
        defaultMessage: 'as appears on legal documents',
        description: 'label of business name hint',
    },
    onBoardingStepCoi: {
        id: `${scope}.onBoardingStepCoi`,
        defaultMessage: 'i.e. Country of Incorporation, etc.',
        description: 'label of onBoardingStep',
    },

    onBoardingStep1Title: {
        id: `${scope}.onBoardingStep1Title`,
        defaultMessage: 'Before We Begin...',
        description: 'label of onBoardingStep1',
    },
    onBoardingStep1SubTitle: {
        id: `${scope}.onBoardingStep1SubTitle`,
        defaultMessage: 'U.S. Federal Law requires all financial institutions to obtain, verify, and record information identifying each person who opens an account.',
        description: 'label of onBoardingStep1',
    },
    onBoardingStep1CardContentTitle1: {
        id: `${scope}.onBoardingStep1CardContentPara1Title`,
        defaultMessage: 'Important information about procedures for opening a new account:',
        description: 'label of onBoardingStep1Card',
    },
    onBoardingStep1CardContentTitle1Body: {
        id: `${scope}.onBoardingStep1CardContentTitle1Body`,
        defaultMessage: 'To help the government fight the funding of terrorism and money laundering activities, financial institutions are required by Federal law to obtain, verify, and record information that identifies each individual or entity that opens an account',
        description: 'body of onBoardingStep1CardContentTitle1',
    },
    onBoardingStep1CardContentTitle2: {
        id: `${scope}.onBoardingStep1CardContentPara1Title`,
        defaultMessage: 'What this means for you:',
        description: 'label of onBoardingStep1CardContentTitle2',
    },
    onBoardingStep1CardContentTitle2Body: {
        id: `${scope}.onBoardingStep1CardContentTitle2Body`,
        defaultMessage: 'If you are opening an account for a legal entity or a business, we will ask for the entity’s name, address, taxpayer identification number, affiliations and ownership, identifying information (name, address, date of birth, social security number as well as identification documents) for each individual that has beneficial ownership and one individual that has significant managerial control of the Legal Entity. We may ask you to provide the appropriate documentation and to certify that this information is true and accurate to the best of your knowledge.',
        description: 'body of onBoardingStep1CardContentTitle1',
    },
    onBoardingStep1ContinueBtn: {
        id: `${scope}.onBoardingStepContinueBtn`,
        defaultMessage: 'Got It',
        description: 'label of onBoardingStep1ContinueBtn',
    },

    onBoardingStep2Title: {
        id: `${scope}.onBoardingStep2Title`,
        defaultMessage: 'Brief Us On Your Business',
        description: 'label of onBoardingStep2',
    },
    onBoardingStep2SubTitle: {
        id: `${scope}.onBoardingStep2SubTitle`,
        defaultMessage: 'Tell us about your company and the business it conducts',
        description: 'label of onBoardingStep2',
    },
    onBoardingStep2BriefSecondTitle: {
        id: `${scope}.onBoardingStep2BriefSecondTitle`,
        defaultMessage: 'What Does Your Business Do?',
        description: 'label of onBoardingStep2',
    },
    onBoardingStep2SubTitleHalf: {
        id: `${scope}.onBoardingStep2SubTitleHalf`,
        defaultMessage: 'Include your website and product/service details',
        description: 'label of onBoardingStep2',
    },
    onBoardingStep2ServiceWebsite: {
        id: `${scope}.onBoardingStep2ServiceWebsite`,
        defaultMessage: 'Website, if you have one',
        description: 'label of onBoardingStep2ServiceWebsite',
    },
    onBoardingStep2ServiceWebsiteHelperTxt: {
        id: `${scope}.onBoardingStep2ServiceWebsiteHelperTxt`,
        defaultMessage: 'E.g., Business Website, Ecommerce Store, Facebook, Etsy, etc.',
        description: 'label of onBoardingStep2ServiceWebsite',
    },
    onBoardingStep2ServiceTitle: {
        id: `${scope}.onBoardingStep2ServiceTitle`,
        defaultMessage: 'Tell us what products / services you sell',
        description: 'label of onBoardingStep2ServiceTitle',
    },
    onBoardingStep2DetailHelper: {
        id: `${scope}.onBoardingStep2ServiceTitle`,
        defaultMessage: 'Please elaborate further',
        description: 'label of onBoardingStep2ServiceTitle',
    },
    onBoardingStep2NoWebsite: {
        id: `${scope}.onBoardingStep2NoWebsite`,
        defaultMessage: 'We don’t have a website yet',
        description: 'label of onBoardingStep2ServiceTitle',
    },
    onBoardingStep2ServiceSubtitle: {
        id: `${scope}.onBoardingStep2ServiceSubtitle`,
        defaultMessage: 'Exactly what products and/or services does',
        description: 'label of onBoardingStep2ServiceSubtitle',
    },
    onBoardingStep2ServiceSubtitleHalf: {
        id: `${scope}.onBoardingStep2ServiceSubtitle`,
        defaultMessage: 'your business sell? Please be specific!',
        description: 'label of onBoardingStep2ServiceSubtitle',
    },
    onBoardingStep2ServiceHelperText: {
        id: `${scope}.onBoardingStep2ServiceHelperText`,
        defaultMessage: '/100 Character (100 Character Minimum)',
        description: 'onBoardingStep2 Service Helper Text',
    },
    onBoardingStep2ServiceHelperTextElse: {
        id: `${scope}.onBoardingStep2ServiceHelperText`,
        defaultMessage: '100 Character Minimum',
        description: 'onBoardingStep2 Service Helper Text',
    },
    onBoardingStep2descriptionPlaceHolder: {
        id: `${scope}.onBoardingStep2descriptionPlaceHolder`,
        defaultMessage: `Here’s an example: “Our company, ABC Fashion, purchases clothing and accessory goods from merchants in China, India, and Taiwan to sell to online customers in the U.S.”`,
        description: 'onBoardingStep2 Service Helper Text',
    },
    onBoardingStep2ContinueBtn: {
        id: `${scope}.onBoardingStep2ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep2ContinueBtn',
    },

    onBoardingStep3Title: {
        id: `${scope}.onBoardingStep3Title`,
        defaultMessage: 'Is Your Business Any of the Following?',
        description: 'label of onBoardingStep3Title',
    },
    onBoardingStep3SubTitle: {
        id: `${scope}.onBoardingStep3SubTitle`,
        defaultMessage: 'Select any of the following that apply to your business.',
        description: 'label of onBoardingStep3SubTitle',
    },
    onBoardingStep3Checkbox1: {
        id: `${scope}.onBoardingStep3Checkbox1`,
        defaultMessage: 'Registered with the SEC',
        description: 'label of onBoardingStep3Checkbox1',
    },
    onBoardingStep3Checkbox2: {
        id: `${scope}.onBoardingStep3Checkbox2`,
        defaultMessage: 'A publicly-traded company',
        description: 'label of onBoardingStep3Checkbox2',
    },
    onBoardingStep3Checkbox3: {
        id: `${scope}.onBoardingStep3Checkbox3`,
        defaultMessage: 'Majority owned by a public company',
        description: 'label of onBoardingStep3Checkbox3',
    },
    onBoardingStep3Checkbox4: {
        id: `${scope}.onBoardingStep3Checkbox4`,
        defaultMessage: 'An internet gambling business',
        description: 'label of onBoardingStep3Checkbox4',
    },
    onBoardingStep3Checkbox5: {
        id: `${scope}.onBoardingStep3Checkbox5`,
        defaultMessage: 'A money services business (e.g. an ATM operator, foreign currency exchange, or check cashing service',
        description: 'label of onBoardingStep3Checkbox5',
    },
    onBoardingStep3Checkbox6: {
        id: `${scope}.onBoardingStep3Checkbox6`,
        defaultMessage: 'A marijuana, CBD, or marijuana products company',
        description: 'label of onBoardingStep3Checkbox6',
    },
    onBoardingStep3Checkbox7: {
        id: `${scope}.onBoardingStep3Checkbox7`,
        defaultMessage: 'A cryptocurrency trading or exchange company',
        description: 'label of onBoardingStep3Checkbox7',
    },
    onBoardingStep3ContinueBtn: {
        id: `${scope}.onBoardingStep3ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep3ContinueBtn',
    },

    onBoardingStep4TitleStart: {
        id: `${scope}.onBoardingStep4TitleStart`,
        defaultMessage: 'Where in the World Do You Operate?	',
        description: 'label of onBoardingStep4Title',
    },
    onBoardingStep4SubTitle: {
        id: `${scope}.onBoardingStep4SubTitle`,
        defaultMessage: `Enter your company’s address, and the countries where you do business`,
        description: 'label of onBoardingStep4SubTitle',
    },
    onBoardingStep4Country: {
        id: `${scope}.onBoardingStep4Country`,
        defaultMessage: 'Country',
        description: 'label of onBoardingStep4Country',
    },
    onBoardingStep4StreetAddress: {
        id: `${scope}.onBoardingStep4StreetAddress`,
        defaultMessage: 'Street Address',
        description: 'label of onBoardingStep4StreetAddress',
    },

    onBoardingStep4Apartment: {
        id: `${scope}.onBoardingStep4Apartment`,
        defaultMessage: 'Unit/Suite/Floor',
        description: 'label of onBoardingStep4Apartment',
    },
    onBoardingStep4Landmark: {
        id: `${scope}.onBoardingStep4Landmark`,
        defaultMessage: 'Landmark',
        description: 'label of onBoardingStep4Landmark',
    },
    onBoardingStep4City: {
        id: `${scope}.onBoardingStep4City`,
        defaultMessage: 'City',
        description: 'label of onBoardingStep4City',
    },
    onBoardingStep4State: {
        id: `${scope}.onBoardingStep4State`,
        defaultMessage: 'State/Province',
        description: 'label of onBoardingStep4State',
    },
    onBoardingStep4Postal: {
        id: `${scope}.onBoardingStep4Postal`,
        defaultMessage: 'ZIP/Postal Code',
        description: 'label of onBoardingStep4Postal',
    },
    onBoardingStep4SameAddressTitle: {
        id: `${scope}.onBoardingStep4SameAddressTitle`,
        defaultMessage: 'Same as address of operation?',
        description: 'label of onBoardingStep4SameAddressTitle',
    },

    onBoardingStep4RadioBtnYes: {
        id: `${scope}.onBoardingStep4RadioBtnYes`,
        defaultMessage: 'Yes',
        description: 'label of onBoardingStep4RadioBtnYes',
    },
    onBoardingStep4RadioBtnNo: {
        id: `${scope}.onBoardingStep4RadioBtnNo`,
        defaultMessage: 'No',
        description: 'label of onBoardingStep4RadioBtnNo',
    },
    onBoardingStep4AddressOfOperation: {
        id: `${scope}.onBoardingStep4AddressOfOperation`,
        defaultMessage: 'Address of Operation/Registration',
        description: 'label of onBoardingStep4AddressOfOperation',
    },
    onBoardingStep4AddressOfIncorporation: {
        id: `${scope}.onBoardingStep4AddressOfIncorporation`,
        defaultMessage: 'Address of Incorporation',
        description: 'label of onBoardingStep4AddressOfIncorporation',
    },
    onBoardingStep8OwnershipRadioTitle: {
        id: `${scope}.onBoardingStep8OwnershipRadioTitle`,
        defaultMessage: 'I hold 25% or more of the shares in this company',
        description: 'label of radio on personal detail',
    },
    onBoardingStep4RegisteredBusinessAddress: {
        id: `${scope}.onBoardingStep4RegisteredBusinessAddress`,
        defaultMessage: 'Registered Business Address',
        description: 'label of onBoardingStep4RegisteredBusinessAddress',
    },
    onBoardingStep4ContinueBtn: {
        id: `${scope}.onBoardingStep4ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep4ContinueBtn',
    },

    onBoardingStep5Title: {
        id: `${scope}.onBoardingStep5Title`,
        defaultMessage: 'What Industry is Your Business in?',
        description: 'label of onBoardingStep5Title',
    },
    onBoardingStep5SubTitle: {
        id: `${scope}.onBoardingStep5SubTitle`,
        defaultMessage: 'Personalize your experience by indicating the industry you’re in. This helps us bring you unique features specific to your business needs.',
        description: 'label of onBoardingStep5SubTitle',
    },
    onBoardingStep5Card1: {
        id: `${scope}.onBoardingStep5Card1`,
        defaultMessage: 'Accomodations and Food Services',
        description: 'label of onBoardingStep5Card1',
    }, onBoardingStep5Card2: {
        id: `${scope}.onBoardingStep5Card2`,
        defaultMessage: 'Adminitrative and Support Services ',
        description: 'label of onBoardingStep5Card2',
    },
    onBoardingStep5Card3: {
        id: `${scope}.onBoardingStep5Card3`,
        defaultMessage: 'Agriculture, Forestry, Fishing and Hunting',
        description: 'label of onBoardingStep5Card3',
    },
    onBoardingStep5Card4: {
        id: `${scope}.onBoardingStep5Card4`,
        defaultMessage: 'Arts, Entertainment and Recreation',
        description: 'label of onBoardingStep5Card4',
    },
    onBoardingStep5Card5: {
        id: `${scope}.onBoardingStep5Card5`,
        defaultMessage: 'Banking, Finance and Insurance',
        description: 'label of onBoardingStep5Card5',
    },
    onBoardingStep5Card6: {
        id: `${scope}.onBoardingStep5Card6`,
        defaultMessage: 'Construction',
        description: 'label of onBoardingStep5Card6',
    },
    onBoardingStep5Card7: {
        id: `${scope}.onBoardingStep5Card7`,
        defaultMessage: 'Educational Services',
        description: 'label of onBoardingStep5Card7',
    },
    onBoardingStep5Card8: {
        id: `${scope}.onBoardingStep5Card8`,
        defaultMessage: 'Healthcare and Social Assistance',
        description: 'label of onBoardingStep5Card8',
    },
    onBoardingStep5Card9: {
        id: `${scope}.onBoardingStep5Card9`,
        defaultMessage: 'Information and Broadcasting',
        description: 'label of onBoardingStep5Card9',
    },
    onBoardingStep5Card10: {
        id: `${scope}.onBoardingStep5Card10`,
        defaultMessage: 'Management of Companies and Enterprises',
        description: 'label of onBoardingStep5Card10',
    },
    onBoardingStep5Card11: {
        id: `${scope}.onBoardingStep5Card11`,
        defaultMessage: 'Manufacturing',
        description: 'label of onBoardingStep5Card11',
    },
    onBoardingStep5Card12: {
        id: `${scope}.onBoardingStep5Card12`,
        defaultMessage: 'Mining',
        description: 'label of onBoardingStep5Card12',
    },
    onBoardingStep5Card13: {
        id: `${scope}.onBoardingStep5Card13`,
        defaultMessage: 'Professional, Scientific and Technical Services ',
        description: 'label of onBoardingStep5Card13',
    },
    onBoardingStep5Card14: {
        id: `${scope}.onBoardingStep5Card14`,
        defaultMessage: 'Public Administration',
        description: 'label of onBoardingStep5Card14',
    },
    onBoardingStep5Card15: {
        id: `${scope}.onBoardingStep5Card15`,
        defaultMessage: 'Real Estate Rental, Leasing and Brokerage',
        description: 'label of onBoardingStep5Card15',
    },
    onBoardingStep5Card16: {
        id: `${scope}.onBoardingStep5Card16`,
        defaultMessage: 'Retail Trade',
        description: 'label of onBoardingStep5Card16',
    },
    onBoardingStep5Card17: {
        id: `${scope}.onBoardingStep5Card17`,
        defaultMessage: 'Transportation and Warehousing',
        description: 'label of onBoardingStep5Card17',
    },
    onBoardingStep5Card18: {
        id: `${scope}.onBoardingStep5Card18`,
        defaultMessage: 'Utilities and Services',
        description: 'label of onBoardingStep5Card18',
    },
    onBoardingStep5Card19: {
        id: `${scope}.onBoardingStep5Card19`,
        defaultMessage: 'Wholesale Trade',
        description: 'label of onBoardingStep5Card19',
    },
    onBoardingStep5Card20: {
        id: `${scope}.onBoardingStep5Card1`,
        defaultMessage: 'Waste Management and Remediation Services',
        description: 'label of onBoardingStep5Card20',
    },
    onBoardingStep5CardOther: {
        id: `${scope}.onBoardingStep5CardOther`,
        defaultMessage: 'Other',
        description: 'label of onBoardingStep5CardOther',
    },
    onBoardingStep5ContinueBtn: {
        id: `${scope}.onBoardingStep5ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep5ContinueBtn',
    },

    onBoardingStep6Title: {
        id: `${scope}.onBoardingStep6Title`,
        defaultMessage: 'What is your primary financial need?',
        description: 'label of onBoardingStep6Title',
    },
    onBoardingStep6Subtitle: {
        id: `${scope}.onBoardingStep6Subtitle`,
        defaultMessage: `Select one or more features to help us understand your business' specific needs`,
        description: 'label of onBoardingStep6Subtitle',
    },
    onBoardingStep6Card1: {
        id: `${scope}.onBoardingStep6Card1`,
        defaultMessage: 'Vendor Payments',
        description: 'label of onBoardingStep6Card1',
    },
    onBoardingStep6Card2: {
        id: `${scope}.onBoardingStep6Card2`,
        defaultMessage: 'Integrating with Payment Processors',
        description: 'label of onBoardingStep6Card2',
    },
    onBoardingStep6Card3: {
        id: `${scope}.onBoardingStep6Card3`,
        defaultMessage: 'Expense Management',
        description: 'label of onBoardingStep6Card3',
    },
    onBoardingStep6Card4: {
        id: `${scope}.onBoardingStep6Card4`,
        defaultMessage: 'Domestic Payments',
        description: 'label of onBoardingStep6Card4',
    },
    onBoardingStep6Card5: {
        id: `${scope}.onBoardingStep6Card5`,
        defaultMessage: 'International Payments',
        description: 'label of onBoardingStep6Card5',
    },
    onBoardingStep6Card6: {
        id: `${scope}.onBoardingStep6Card6`,
        defaultMessage: 'Payroll',
        description: 'label of onBoardingStep6Card6',
    },
    onBoardingStep6ContinueBtn: {
        id: `${scope}.onBoardingStep6ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep6ContinueBtn',
    },

    onBoardingStep7Title: {
        id: `${scope}.onBoardingStep7Title`,
        defaultMessage: 'List the Countries You Do Business With',
        description: 'label of onBoardingStep7Title',
    },
    onBoardingStep7Subtitle: {
        id: `${scope}.onBoardingStep7Subtitle`,
        defaultMessage: 'We’re asking this to curate available currencies for you',
        description: 'label of onBoardingStep7Subtitle',
    },
    onBoardingStepAddress: {
        id: `${scope}.onBoardingStepAddress`,
        defaultMessage: 'We’re looking for where your long-term customers, team members, suppliers, vendors or other partners, etc. are located.',
        description: 'label of onBoardingStep7Subtitle',
    },
    onBoardingStep7TheAmericas: {
        id: `${scope}.onBoardingStep7TheAmericas`,
        defaultMessage: 'The Americas',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasArgentina: {
        id: `${scope}.onBoardingStep7TheAmericasArgentina`,
        defaultMessage: 'Argentina',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasBermuda: {
        id: `${scope}.onBoardingStep7TheAmericasBermuda`,
        defaultMessage: 'Bermuda',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasBrazil: {
        id: `${scope}.onBoardingStep7TheAmericasBrazil`,
        defaultMessage: 'Brazil',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasChile: {
        id: `${scope}.onBoardingStep7TheAmericasChile`,
        defaultMessage: 'Chile',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasCanada: {
        id: `${scope}.onBoardingStep7TheAmericasCanada`,
        defaultMessage: 'Canada',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasCostaRica: {
        id: `${scope}.onBoardingStep7TheAmericasCostaRica`,
        defaultMessage: 'Costa Rica',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasGuatemala: {
        id: `${scope}.onBoardingStep7TheAmericasGuatemala`,
        defaultMessage: 'Guatemala',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasJamaica: {
        id: `${scope}.onBoardingStep7TheAmericasJamaica`,
        defaultMessage: 'Jamaica',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7TheAmericasMexico: {
        id: `${scope}.onBoardingStep7TheAmericasMexico`,
        defaultMessage: 'Mexico',
        description: 'label of onBoardingStep7TheAmericas',
    },
    onBoardingStep7Europe: {
        id: `${scope}.onBoardingStep7Europe`,
        defaultMessage: 'Europe',
        description: 'label of onBoardingStep7Europe',
    },
    onBoardingStep7EuropeText: {
        id: `${scope}.onBoardingStep7EuropeText`,
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus exsit amet blandit leo lobortis eget.',
        description: 'label of onBoardingStep7Europe',
    },
    onBoardingStep7AfricaAndMiddleEast: {
        id: `${scope}.onBoardingStep7AfricaAndMiddleEast`,
        defaultMessage: 'Africa & Middle east',
        description: 'label of onBoardingStep7Europe',
    },
    onBoardingStep7AfricaAndMiddleEastText: {
        id: `${scope}.onBoardingStep7AfricaAndMiddleEastText`,
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus exsit amet blandit leo lobortis eget.',
        description: 'label of onBoardingStep7Europe',
    },
    onBoardingStep7AsiaPacific: {
        id: `${scope}.onBoardingStep7AsiaPacific`,
        defaultMessage: 'Asia-Pacific',
        description: 'label of onBoardingStep7AsiaPacific',
    },
    onBoardingStep7AsiaPacificText: {
        id: `${scope}.onBoardingStep7AsiaPacificText`,
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus exsit amet blandit leo lobortis eget.',
        description: 'label of onBoardingStep7AsiaPacific',
    },

    onBoardingStep7ContinueBtn: {
        id: `${scope}.onBoardingStep7ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep7ContinueBtn',
    },

    onBoardingStep8Title: {
        id: `${scope}.onBoardingStep8Title`,
        defaultMessage: 'What’s Your Primary Source of Funds?',
        description: 'label of onBoardingStep8Title',
    },
    onBoardingStep8Subtitle: {
        id: `${scope}.onBoardingStep8Subtitle`,
        defaultMessage: 'Aside from business revenue, how do you fund your business?',
        description: 'label of onBoardingStep8Subtitle',
    },
    onBoardingStep8Card1: {
        id: `${scope}.onBoardingStep8Card1`,
        defaultMessage: 'Business Income',
        description: 'label of onBoardingStep8Card1',
    },
    onBoardingStep8Card2: {
        id: `${scope}.onBoardingStep8Card2`,
        defaultMessage: 'Business Loan',
        description: 'label of onBoardingStep8Card2',
    },
    onBoardingStep8Card3: {
        id: `${scope}.onBoardingStep8Card3`,
        defaultMessage: 'Grant(s)',
        description: 'label of onBoardingStep8Card3',
    },
    onBoardingStep8Card4: {
        id: `${scope}.onBoardingStep8Card4`,
        defaultMessage: 'Loan from Directors',
        description: 'label of onBoardingStep8Card4',
    },
    onBoardingStep8Card5: {
        id: `${scope}.onBoardingStep8Card5`,
        defaultMessage: 'Other Debts',
        description: 'label of onBoardingStep8Card5',
    },
    onBoardingStep8Card6: {
        id: `${scope}.onBoardingStep8Card6`,
        defaultMessage: 'Shareholder Funds',
        description: 'label of onBoardingStep8Card6',
    },
    onBoardingStep8Card7: {
        id: `${scope}.onBoardingStep8Card7`,
        defaultMessage: 'Venture Capital',
        description: 'label of onBoardingStep8Card7',
    },

    onBoardingStep8ContinueBtn: {
        id: `${scope}.onBoardingStep8ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep8ContinueBtn',
    },

    onBoardingStep9Title: {
        id: `${scope}.onBoardingStep9Title`,
        defaultMessage: 'How is Your Business Structured?',
        description: 'label of onBoardingStep9Title',
    },
    onBoardingStep9Subtitle: {
        id: `${scope}.onBoardingStep9Subtitle`,
        defaultMessage: 'Confirm your business’ legal structure details.',
        description: 'label of onBoardingStep9Subtitle',
    },
    onBoardingStep9JuridictionForUs: {
        id: `${scope}.onBoardingStep9Juridiction`,
        defaultMessage: 'Incorporated State',
        description: 'label of onBoardingStep9Juridiction',
    },
    onBoardingStep9JuridictionForCanada: {
        id: `${scope}.onBoardingStep9Juridiction`,
        defaultMessage: 'Incorporated Province ',
        description: 'label of onBoardingStep9Juridiction',
    },
    onBoardingStep2Industry: {
        id: `${scope}.onBoardingStep2Industry`,
        defaultMessage: 'Industry',
        description: 'label of onBoardingStep9Juridiction',
    },
    onBoardingStep9JuridictionHelperText: {
        id: `${scope}.onBoardingStep9JuridictionHelperText`,
        defaultMessage: 'Incorporated State/Province',
        description: 'label of onBoardingStep9Juridiction',
    },
    onBoardingStep9BusinessType: {
        id: `${scope}.onBoardingStep9BusinessType`,
        defaultMessage: 'Company Type',
        description: 'label of onBoardingStep9BusinessType',
    },
    onBoardingStep9BusinessTypeHelperText: {
        id: `${scope}.onBoardingStep9BusinessTypeHelperText`,
        defaultMessage: 'How business is structured',
        description: 'label of onBoardingStep9BusinessTypeHelperText',
    },
    onBoardingStep9NumberOfEmployees: {
        id: `${scope}.onBoardingStep9NumberOfEmployees`,
        defaultMessage: 'Number of Employees',
        description: 'label of onBoardingStep9NumberOfEmployees',
    },
    onBoardingStep9EmpIdNo: {
        id: `${scope}.onBoardingStep9EmpIdNo`,
        defaultMessage: 'Employer Identification Number',
        description: 'label of onBoardingStep9EmpIdNo',
    },
    onBoardingStep9ContinueBtn: {
        id: `${scope}.onBoardingStep9ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep9ContinueBtn',
    },

    onBoardingStep10Title: {
        id: `${scope}.onBoardingStep10Title`,
        defaultMessage: 'Ownership Structure',
        description: 'label of onBoardingStep10Title',
    },
    onBoardingStep10Subtitle: {
        id: `${scope}.onBoardingStep10Subtitle`,
        defaultMessage: 'Tell us about who makes up the controlling shares of ',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10SubtitleBreak: {
        id: `${scope}.onBoardingStep10SubtitleBreak`,
        defaultMessage: ' to learn more',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10YourDetail: {
        id: `${scope}.onBoardingStep10YourDetail`,
        defaultMessage: 'Your Details',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10BeneficialOwners: {
        id: `${scope}.onBoardingStep10SubtitleHalf`,
        defaultMessage: 'Beneficial Owners',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10FirstName: {
        id: `${scope}.onBoardingStep10FirstName`,
        defaultMessage: 'First Name',
        description: 'label of onBoardingStep10FirstName',
    },
    onBoardingStep10LastName: {
        id: `${scope}.onBoardingStep10LastName`,
        defaultMessage: 'Last Name',
        description: 'label of onBoardingStep10LastName',
    },
    onBoardingStep10Email: {
        id: `${scope}.onBoardingStep10Email`,
        defaultMessage: 'Email',
        description: 'label of onBoardingStep10Email',
    },
    onBoardingStep10JobTitle: {
        id: `${scope}.onBoardingStep10JobTitle`,
        defaultMessage: 'Job Title',
        description: 'label of onBoardingStep10JobTitle',
    },
    onBoardingStep10PhoneLabel: {
        id: `${scope}.onBoardingStep10PhoneLabel`,
        defaultMessage: 'Phone Number',
        description: 'label of onBoardingStep10PhoneLabel',
    },
    onBoardingStep10SharedOwner: {
        id: `${scope}.onBoardingStep10SharedOwner`,
        defaultMessage: ' % Shares Owned',
        description: 'label of onBoardingStep10SharedOwner',
    },
    onBoardingStep10AddOwner: {
        id: `${scope}.onBoardingStep10AddOwner`,
        defaultMessage: 'Add Another Shareholder',
        description: 'label of onBoardingStep10AddOwner',
    },
    onBoardingStep10ConfirmCheckbox: {
        id: `${scope}.onBoardingStep10ConfirmCheckbox`,
        defaultMessage: 'I certify that the information provided regarding my ability to control the business and it’s ownership is complete and correct to the best of my knowledge.',
        description: 'label of onBoardingStep10ConfirmCheckbox',
    },
    onBoardingStep10ContinueBtn: {
        id: `${scope}.onBoardingStep10ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep10ContinueBtn',
    },

    onBoardingStep11Title: {
        id: `${scope}.onBoardingStep11Title`,
        defaultMessage: 'Owner ID Verficiation',
        description: 'label of onBoardingStep11Title',
    },
    onBoardingStep11Subtitle: {
        id: `${scope}.onBoardingStep11Subtitle`,
        defaultMessage: 'Please select a Controlling Party for ID Verification. All beneficial owners and this individual will receive a link to verify their ID and authorize the business’ account.',
        description: 'label of onBoardingStep11Subtitle',
    },
    onBoardingStep11ContinueBtn: {
        id: `${scope}.onBoardingStep11ContinueBtn`,
        defaultMessage: 'Continue',
        description: 'label of onBoardingStep11ContinueBtn',
    },


    onBoardingStep12Title: {
        id: `${scope}.onBoardingStep12Title`,
        defaultMessage: 'Terms & Conditions',
        description: 'label of onBoardingStep12Title',
    },
    onBoardingStep12Subtitle: {
        id: `${scope}.onBoardingStep12Subtitle`,
        defaultMessage: 'Read through and agree to the conditions outlined below',
        description: 'label of onBoardingStep12Subtitle',
    },

    onBoardingStep12Content: {
        id: `${scope}.onBoardingStep12Content`,
        defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus integer ultrices tincidunt pharetra tortor sed urna. Hac vitae, ut vitae enim non ultricies aliquet sollicitudin. Donec dictumst auctor mauris imperdiet donec risus, vel in euismod. Consequat ultrices feugiat pretium mauris parturient placerat consectetur augue. Eu tempor non purus vestibulum. Ac pulvinar consequat turpis consectetur at nisi ut est feugiat. Magna molestie cras amet nisl aliquam cursus augue. Gravida phasellus eget malesuada nunc, nulla. Tortor semper et eget eget. Vulputate felis odio arcu condimentum arcu nisi arcu. Velit platea eleifend adipiscing nulla eu vivamus suspendisse nec.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus integer ultrices tincidunt pharetra tortor sed urna. Hac vitae, ut vitae enim non ultricies aliquet sollicitudin. Donec dictumst auctor mauris imperdiet donec risus, vel in euismod. Consequat ultrices feugiat pretium mauris parturient placerat consectetur augue. Eu tempor non purus vestibulum. Ac pulvinar consequat turpis consectetur at nisi ut est feugiat. Magna molestie cras amet nisl aliquam cursus augue. Gravida phasellus eget malesuada nunc, nulla. Tortor semper et eget eget. Vulputate felis odio arcu condimentum arcu nisi arcu. Velit platea eleifend adipiscing nulla eu vivamus suspendisse nec.',
        description: 'label of onBoardingStep12Content',
    },
    onBoardingStep12SignFullName: {
        id: `${scope}.onBoardingStep12SignFullName`,
        defaultMessage: 'Sign Your Full Name',
        description: 'label of onBoardingStep12SignFullName',
    },
    onBoardingStep12CheckboxLabel: {
        id: `${scope}.onBoardingStep12CheckboxLabel`,
        defaultMessage: 'I agree with the above outlined Terms & Conditions',
        description: 'label of onBoardingStep12CheckboxLabel',
    },
    onBoardingStep12CondirmBtn: {
        id: `${scope}.onBoardingStep12CondirmBtn`,
        defaultMessage: 'Confirm',
        description: 'label of onBoardingStep12CondirmBtn',
    },

    onBoardingFinalTitle: {
        id: `${scope}.onBoardingFinalTitle`,
        defaultMessage: 'Well done, ',
        description: 'label of onBoardingFinal',
    },
    onBoardingFinalSubtitle: {
        id: `${scope}.onBoardingFinalSubtitle`,
        defaultMessage: 'We\'re looking over your information now. In the meantime take a tour around the platform and get aquainted',
        description: 'label of onBoardingFinalSubtitle',
    },
    onBoardingSearch: {
        id: `${scope}.onBoardingSearch`,
        defaultMessage: 'Search Countries',
        description: 'label of onBoardingSearch',
    },
    onBoardingBusinessBelongError: {
        id: `${scope}.onBoardingBusinessBelongError`,
        defaultMessage: 'Select Business Type',
        description: 'label of onBoarding buiness belogn error',
    },
    onBoardingBusinessIndustryError: {
        id: `${scope}.onBoardingBusinessIndustryError`,
        defaultMessage: 'Select Business Industry',
        description: 'label of onBoarding buiness belogn error',
    },
    onBoardingBusinessUseCaseError: {
        id: `${scope}.onBoardingBusinessUseCaseError`,
        defaultMessage: 'Select Business Primary use-cases',
        description: 'label of onBoarding buiness belogn error',
    },
    onBoardingStep13Title: {
        id: `${scope}.onBoardingStep13Title`,
        defaultMessage: 'Your Details',
        description: 'label of onBoardingStep10Title',
    },
    onBoardingStep13Subtitle: {
        id: `${scope}.onBoardingStep13Subtitle`,
        defaultMessage: 'Enter the outstanding fields and disclose your shareholding',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep13YourDetail: {
        id: `${scope}.onBoardingStep13YourDetail`,
        defaultMessage: 'Your Details',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep13YourDetailMobileLabel: {
        id: `${scope}.contactAddUpdateMobileLabel`,
        defaultMessage: 'Mobile Number',
        description: 'Field label on add update contact popup / screen',
    },
    onBoardingStep13YourDetailOptionalLabel: {
        id: `${scope}.contactAddUpdateOptionalLabel`,
        defaultMessage: '(Optional)',
        description: 'Field label on add update contact popup / screen',
    },
    onBoardingStep13YourDetailCountryLabel: {
        id: `${scope}.contactAddUpdateMobileCountryLabel`,
        defaultMessage: 'Country',
        description: 'Field label on add update contact popup / screen',
    },
    onBoardingStep13VerificationLabel: {
        id: `${scope}.onBoardingStep13VerificationLabel`,
        defaultMessage: 'We’ll send them a notification to verify their ID',
        description: 'Field label on add update contact popup / screen',
    },
    onBoardingStep13ConfirmCheckbox: {
        id: `${scope}.onBoardingStep10ConfirmCheckbox`,
        defaultMessage: 'I am a Controlling Party',
        description: 'label of onBoardingStep10ConfirmCheckbox',
    },
    onBoardingStep13OwnerShip: {
        id: `${scope}.onBoardingStep13OwnerShip`,
        defaultMessage: 'Ownership',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10OwnerIndividuals: {
        id: `${scope}.onBoardingStep13OwnerShip`,
        defaultMessage: 'This person is a controlling party',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10OwnerShare: {
        id: `${scope}.onBoardingStep13OwnerShip`,
        defaultMessage: 'Do you hold 25% or more shares in this company?',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10OwnerIsAnyOtherShare: {
        id: `${scope}.onBoardingStep10OwnerIsAnyOtherShare`,
        defaultMessage: 'Are there any other individuals who hold 25% or more of the company’s shares directly or indirectly?',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10OwnerShipError: {
        id: `${scope}.onBoardingStep10OwnerShipError`,
        defaultMessage: 'Total ownership must be equal or less then 100%',
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep10OwnerShipErrorControlling: {
        id: `${scope}.onBoardingStep10OwnerShipError`,
        defaultMessage: `Controlling party's ownership percentage must be more than 0.`,
        description: 'label of onBoardingStep10Subtitle',
    },
    onBoardingStep14Title: {
        id: `${scope}.onBoardingStep14Title`,
        defaultMessage: 'Select a Controlling Party',
        description: 'label of onBoardingStep14Title',
    },
    onBoardingStep14Subtitle: {
        id: `${scope}.onBoardingStep14Subtitle`,
        defaultMessage: 'Please select a single Controlling Party for ID Verification.',
        description: 'label of onBoardingStep14Subtitle',
    },
    onBoardingStep14SubtitleBreak: {
        id: `${scope}.onBoardingStep14Subtitle`,
        defaultMessage: ' The Controlling Party and all other beneficial owners, will receive a link to verify their ID and authorize the business’ account.',
        description: 'label of onBoardingStep14Subtitle',
    },
    onBoardingStepSummaryTitlebtitle: {
        id: `${scope}.onBoardingStepSummaryTitlebtitle`,
        defaultMessage: 'Information Summary',
        description: 'label of onBoardingStepSummaryTitlebtitle',
    },
    onBoardingStepSummarySubTitle: {
        id: `${scope}.onBoardingStepSummarySubTitle`,
        defaultMessage: 'Below is a summary of the information you’ve shared thus far, outstanding information is flagged as necessary.',
        description: 'label of onBoardingStepSummarySubTitletle',
    },
    onBoardingStepSummaryTitleMain: {
        id: `${scope}.onBoardingStepSummaryTitleMain`,
        defaultMessage: 'Introduction',
        description: 'label of onBoardingStepSummaryTitleMainle',
    },
    onBoardingStepSummaryBusinessBrief: {
        id: `${scope}.onBoardingStepSummaryBusinessBrief`,
        defaultMessage: 'Business Details',
        description: 'label of onBoardingStepSummaryBusinessBrief',
    },
    onBoardingStepSummaryTailorProfile: {
        id: `${scope}.onBoardingStepSummaryTailorProfile`,
        defaultMessage: 'Tailor Profile',
        description: 'label of onBoardingStepSummaryTailorProfile',
    },
    onBoardingStepIntroduction: {
        id: `${scope}.onBoardingStepIntroduction`,
        defaultMessage: 'Introduction',
        description: 'label of onBoardingStepIntroductionbtitle',
    },
    onBoardingStepSummarySourceOfFund: {
        id: `${scope}.onBoardingStepSummarySourceOfFund`,
        defaultMessage: 'Source Of Fund',
        description: 'label of onBoardingStepSummarySourceOfFund',
    },
    onBoardingStepSummaryBusinessStructure: {
        id: `${scope}.onBoardingStepSummaryBusinessStructure`,
        defaultMessage: 'Ownership Structure',
        description: 'label of onBoardingStepSummaryBusinessStructure',
    },
    onBoardingStepSummaryShareHolder: {
        id: `${scope}.onBoardingStepSummaryShareHolder`,
        defaultMessage: 'Shareholder',
        description: 'label of onBoardingStepSummaryShareHolder',
    },
    onBoardingStepSummaryNext: {
        id: `${scope}.onBoardingStepSummaryNext`,
        defaultMessage: 'Continue Application',
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStepSummarySignature: {
        id: `${scope}.onBoardingStepSummarySignature`,
        defaultMessage: 'Your Signature',
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStepSummaryIdVerification: {
        id: `${scope}.onBoardingStepSummaryIdVerification`,
        defaultMessage: 'ID Verification',
        description: 'label of onBoardingStepSummaryShareHolder',
    },
    onBoardingStepSummaryVerification: {
        id: `${scope}.onBoardingStepSummaryVerification`,
        defaultMessage: "Your and Your Shareholder's",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingCloseFlowTitle: {
        id: `${scope}.onBoardingCloseFlowTitle`,
        defaultMessage: "Before You Take Off...",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingCloseFlowSubTitle: {
        id: `${scope}.onBoardingCloseFlowSubTitle`,
        defaultMessage: "Are you sure you want to leave before completing your application?",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    discloseBeneficialOwnerTitle: {
        id: `${scope}.discloseBeneficialOwnerTitle`,
        defaultMessage: "Confirm Business Ownership",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    discloseBeneficialOwnerSubTitle: {
        id: `${scope}.discloseBeneficialOwnerSubTitle`,
        defaultMessage: `Based on the information you provided, {message}% of company shares are owned by persons with less than 25% of the total shares each.`,
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    discloseBeneficialOwnerBody1: {
        id: `${scope}.discloseBeneficialOwnerBody1`,
        defaultMessage: "Does this sound correct to you?",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },

    onBoardingStep14TitleNew: {
        id: `${scope}.discloseBeneficialOwnerBody2`,
        defaultMessage: "Select or Add a Controlling Party",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep14SubtitleBreakNew: {
        id: `${scope}.onBoardingStep14SubtitleBreakNew`,
        defaultMessage: "Select an individual that has significant responsibility over managing/directing the company (e.g. CEO, CFO, Managing Member, President)",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep14SubtitleBreakTwo: {
        id: `${scope}.onBoardingStep14SubtitleBreakTwo`,
        defaultMessage: "None of these individuals are a controlling party",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep10TitleNew: {
        id: `${scope}.onBoardingStep10TitleNew`,
        defaultMessage: "Add a Controlling Party",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep10SubTitleNew: {
        id: `${scope}.onBoardingStep10TitleNew`,
        defaultMessage: "We’ll send them a notification to verify their ID",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep16Title: {
        id: `${scope}.onBoardingStep16Title`,
        defaultMessage: "ID Verification",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep16Subtitle: {
        id: `${scope}.onBoardingStep16Subtitle`,
        defaultMessage: "The last thing we need to authorize your business account is ID verification of your Beneficial Owner(s)",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep16pendingDetails: {
        id: `${scope}.onBoardingStep16pendingDetails`,
        defaultMessage: "Pending Verification",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep16LastTxt: {
        id: `${scope}.onBoardingStep16LastTxt`,
        defaultMessage: "A verification link has been emailed to each of those listed above - if necessary, you can resend or copy the link manually using the accompanying icons. ",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep17ApplicationSubmit: {
        id: `${scope}.onBoardingStep17ApplicationSubmit`,
        defaultMessage: "Application Recieved!",
        description: 'label of onBoardingStepSummaryNextubtitle',
    },
    onBoardingStep17body1: {
        id: `${scope}.onBoardingStep17ApplicationSubmit`,
        defaultMessage: "You can expect your account to be approved in 24-48 hour.",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    onBoardingStep17body2: {
        id: `${scope}.onBoardingStep17body2`,
        defaultMessage: "While you wait...",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    onBoardingStep17body3: {
        id: `${scope}.onBoardingStep17body3`,
        defaultMessage: "Log in to the platform and take a look around!",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    onBoardingStep17GotoBtn: {
        id: `${scope}.onBoardingStep17GotoBtn`,
        defaultMessage: "Log in to",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    onBoardingStep17Title: {
        id: `${scope}.onBoardingStep17Title`,
        defaultMessage: "Review Your Details",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    onBoardingStep17Subtitle: {
        id: `${scope}.onBoardingStep17Subtitle`,
        defaultMessage: "Look over your application and if all is correct, click the confirm button to submit. If anything is incorrect you can go back and edit that now.",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    // customerIntroductionOptional: {
    //     id: `${scope}.customerIntroductionOptional`,
    //     defaultMessage: "Must be certified",
    //     description: 'label of onBoardingStepSummaryNextubtitle',
    // },
    accountVeriBottomTxt: {
        id: `${scope}.accountVeriBottomTxt`,
        defaultMessage: "I confirm that the information above is accurate and complete to the best of my knowledge.",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    accountVeriBottomTermConditionTxt: {
        id: `${scope}.accountVeriBottomTermConditionTxt`,
        defaultMessage: "I agree to the",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    accountVeriBottomTermConditionTxtbreak: {
        id: `${scope}.accountVeriBottomTermConditionTxtbreak`,
        defaultMessage: " that Truly Financial has outlined.",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    accountAgreementAcceptButton: {
        id: `${scope}.accountAgreementAcceptButton`,
        defaultMessage: "Confirm",
        description: 'You can expect your account to be approved in 24-48 hour.',
    },
    onBoardingStepSummarySubTitleMain: {
        id: `${scope}.onBoardingStepSummarySubTitleMain`,
        defaultMessage: "First & Last Name, Position, Business Name (& DBA), Country of Operation",
        description: 'onBoardingStepSummarySubTitleMain',
    },
    onBoardingStepSummaryBusinessBriefSubTitle: {
        id: `${scope}.onBoardingStepSummaryBusinessBriefSubTitle`,
        defaultMessage: "Website, Description of Products/Services, Address of Operation, Address of Incorporation",
        description: 'onBoardingStepSummaryBusinessBriefSubTitle',
    },
    businessBrief: {
        id: `${scope}.businessBrief`,
        defaultMessage: "Business Brief",
        description: 'businessBrief',
    },
    businessLocated: {
        id: `${scope}.businessLocated`,
        defaultMessage: "businessLocated",
        description: 'businessLocated',
    },
    onBoardingStepSummaryTailorProfileSubTitle: {
        id: `${scope}.onBoardingStepSummaryTailorProfileSubTitle`,
        defaultMessage: "Interacting Country List, Fund Sourcing",
        description: 'onBoardingStepSummaryTailorProfileSubTitle',
    },
    businessUseCase: {
        id: `${scope}.businessUseCase`,
        defaultMessage: "Business Usecase",
        description: 'businessUseCase',
    },
    businessFunds: {
        id: `${scope}.businessFunds`,
        defaultMessage: "Source of Funds",
        description: 'businessFunds',
    },
    onBoardingStepSummaryOwnerShipSubTitle: {
        id: `${scope}.onBoardingStepSummaryOwnerShipSubTitle`,
        defaultMessage: "Shareholders & Controlling party",
        description: 'onBoardingStepSummaryOwnerShipSubTitle',
    },
    personalDetail: {
        id: `${scope}.personalDetail`,
        defaultMessage: "personalDetail",
        description: 'personalDetail',
    },
    businessOwners: {
        id: `${scope}.businessOwners`,
        defaultMessage: "Business Owners",
        description: 'businessOwners',
    },
    onBoardingStepSummaryTermConditionSubTitle: {
        id: `${scope}.onBoardingStepSummaryTermConditionSubTitle`,
        defaultMessage: "Id verification & Information Review",
        description: 'onBoardingStepSummaryTermConditionSubTitle',
    },
    termAndCondition: {
        id: `${scope}.termAndCondition`,
        defaultMessage: "termAndCondition",
        description: 'termAndCondition',
    },
    idVerification: {
        id: `${scope}.idVerification`,
        defaultMessage: "Id verification",
        description: 'idVerification',
    },
    informationReview: {
        id: `${scope}.informationReview`,
        defaultMessage: "Information Review",
        description: 'informationReview',
    },
    onBoardingStepSummaryTitle: {
        id: `${scope}.onBoardingStepSummaryTitle`,
        defaultMessage: "Summary",
        description: 'onBoardingStepSummaryTitle',
    },
    onBoardingStepSummaryTermsAndConditions: {
        id: `${scope}.onBoardingStepSummaryTermsAndConditions`,
        defaultMessage: "Validation",
        description: 'onBoardingStepSummaryTermsAndConditions',
    },
    selectControlling: {
        id: `${scope}.selectControlling`,
        defaultMessage: "Controlling Party",
        description: 'selectControlling',
    },
    customerIntroductionOptional: {
        id: `${scope}.customerIntroductionOptional`,
        defaultMessage: "As found on incorporation documents",
        description: 'optional',
    },
    onBoardingStep9SolePropHelperText: {
        id: `${scope}.onBoardingStep9SolePropHelperText`,
        defaultMessage: "Employer Identification Number (EIN) - if applicable",
        description: 'optional',
    }

});