/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getTagsAction, addTagsAction, removeTagAction } from 'store/Tag/tagActions';
import { getTagsState, addTagsState } from 'store/Tag/tagSelectors';
import { Chip, Divider } from '@material-ui/core';
import { Typography } from 'components/ui';

const useStyles = makeStyles((theme) => ({
    lastElement: {
        width: '-webkit-fill-available',
    },
}));

function AddTag(props = {}) {
    const classes = useStyles();
    const { getTagsAction, tags, addTagsAction, paymentId, removeTag,
        tagConstants = {}, addTagsState, noLabel,
        contactId, defaultTags = [], onSuccess = () => null
    } = props;
    const [addedTags, setAddedTags] = useState(defaultTags);
    const [customTag, setCustomTag] = useState({ tag_type: 'NEW', tag_color: '#E3D1D1' });
    const multipleTags = false;

    useEffect(() => {
        if(!tags.error && Array.isArray(tags.data) && !tags.data.length){
            getTagsAction();
        }
    }, [tags.data]);

    const onChange = (_e, value) => {
        if(!value.length){
            removeTag({
                tagId: (!isNaN(addTagsState.data) && addTagsState.data) || (addedTags.length ? addedTags[0].tag_id : ''),
                tagAssociateType: tagConstants.tagAssociateType,
                tagAssociateId: tagConstants.tagAssociateId,
            })
        }
        // const selectedTag = value.length ? [value[value.length - 1]] : [];
        let selectedTag = [];
        if (multipleTags) {
            selectedTag = value;
        } else {
            selectedTag = value.length ? [value[value.length - 1]] : [];
        }
        setAddedTags(selectedTag);
        if(selectedTag.length){
            const tagsBody = {
                ...selectedTag[selectedTag.length - 1 ],
                for_payment_id: paymentId,
                for_entity_id: contactId,
                associate_single: multipleTags ? 0 : 1,
            }
            addTagsAction({body: tagsBody, cb: onSuccess });
        }
    };

    const onInputChange = (e) => {
        const { value } = e.target || {};
        setCustomTag((tag) => ({...tag, tag_name: value }));
    };


    return (<>
        <Autocomplete
            // popupIcon={(<ExpandMoreIcon />)}
            popupIcon={null}
            closeIcon={null}
            defaultValue={[]}
            multiple
            // freeSolo
            // autoSelect
            // autoComplete
            id="size-small-filled-multi"
            size="small"
            options={[...tags.data, customTag]}
            onChange={onChange}
            onInputChange={onInputChange}
            value={addedTags}
            getOptionSelected={(option) => option.tag_name}
            getOptionLabel={(option) => (option && option.tag_name) || ""}
            getOptionDisabled={(option) => !option.tag_name}
            filterSelectedOptions
            renderTags={(value, getTagProps) => {
                if(multipleTags){
                    return value.map(val => (<Chip
                        variant="outlined"
                        style={{backgroundColor: val.tag_color, color: 'black'}}
                        label={typeof option === "string" ? value : (val.tag_name || "")}
                        size="small"
                        {...getTagProps({ value: val })}
                    />));
                }else {
                    const elem = value && value.length - 1;
                    return (<Chip
                        variant="outlined"
                        style={{backgroundColor: value && value[elem].tag_color, color: 'black'}}
                        label={typeof option === "string" ? value : ((value[elem] && value[elem].tag_name) || "")}
                        size="small"
                        {...getTagProps({ value: elem })}
                    />)
                }
            }}
            // renderTags={(value, getTagProps) => {
            //     const elem = value && value.length - 1;
            //     return (<Chip
            //         variant="outlined"
            //         style={{backgroundColor: value && value[elem].tag_color, color: 'black'}}
            //         label={typeof option === "string" ? value : ((value[elem] && value[elem].tag_name) || "")}
            //         size="small"
            //         {...getTagProps({ value: elem })}
            //     />);
            // }}

            renderInput={(params) => (
                <TextField {...params} variant="outlined" label={noLabel ? null :"Tag Payment"} placeholder="" />
            )}
            renderOption={(option, state) => {
                return option.tag_type === 'NEW' ?
                <div className={classes.lastElement}>
                    <div>
                        {option.tag_name || ''}
                    </div>
                    <div>
                        <Divider />
                        {customTag.tag_name ? <span><Typography variant="caption">Select to create {customTag.tag_name || ''}</Typography></span> : <Typography variant="caption">Choose or Type to create new one</Typography>}
                    </div>
                </div> : option.tag_name;
            }}
        />
        {/* <Autocomplete
            multiple
            freeSolo
            autoSelect
            id="size-small-filled-multi"
            size="small"
            options={getTagsState.data}
            onChange={onChange}
            getOptionLabel={(option) => (option && option.tag_code) || ""}
            defaultValue={[]}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        variant="outlined"
                        style={{backgroundColor: option && option.tag_color, color: 'black'}}
                        label={typeof option === "string" ? option : ((option && option.tag_code) || "")}
                        size="small"
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Tags" placeholder="" />
            )}
        /> */}
    </>)
};

const mapStateToProps = createStructuredSelector({
    tags: getTagsState(),
    addTagsState: addTagsState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getTagsAction: data => dispatch(getTagsAction(data)),
        addTagsAction: data => dispatch(addTagsAction(data)),
        removeTag: data => dispatch(removeTagAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect, memo)(AddTag);
