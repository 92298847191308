import { combineReducers } from 'redux';
import otpReducer from './Otp/otpReducer';
import contactRequestBankReducer from './ContactRequestBank/contactRequestBankReducer';
import verificationReducer from './Verification/verificationReducer';
import teamMemberReducer from './TeamMember/teamMemberReducer';
import tagsReducer from './Tag/tagReducer';
import referencesReducer from './Reference/referenceReducer';
import userReducer from './User/userReducer';
import paymentsReducer from './Payment/paymentReducer';
import contactsReducer from './Contact/contactReducer';
import snackbarReducer from './Snackbar/snackbarReducer';
import eventSchedulerReducer from './EventScheduler/eventSchedulerReducer';
import accountReducer from './Account/accountReducer';
import languageProvider from './LanguageProvider/languageProviderReducer';
import aclReducer from './Acl/aclReducer';
import registerReducer from './Register/registerReducer';
import sendPaymentReducer from './SendPayment/sendPaymentReducer';

const rootReducer = combineReducers({
    otp: otpReducer,
    contactRequestBank: contactRequestBankReducer,
  verification: verificationReducer,
  teamMember: teamMemberReducer,
  tags: tagsReducer,
  references: referencesReducer,
  user: userReducer,
  payments: paymentsReducer,
  contacts: contactsReducer,
  snackbar: snackbarReducer,
  eventScheduler: eventSchedulerReducer,
  account: accountReducer,
  languageProvider: languageProvider,
  acl: aclReducer,
  register: registerReducer,
  sendPayment: sendPaymentReducer
});

const rootReducerMain = (state, action) => {
  // when a RESET_GLOBAL_STATE action type is dispatched it will reset redux state
  if (action.type === 'RESET_GLOBAL_STATE') {
    state = undefined;
  }

  return rootReducer(state, action);
};

export default rootReducerMain;