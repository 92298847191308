/* eslint-disable eqeqeq */
import {
    ENTITY, PAYMENT,
} from "@truly-financial/common/consts";
import { currencySymbol } from "./currencySymbols";
import moment from 'moment';

export const removeNullKeys = (obj = {}) => {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        if (obj[prop] !== null && obj[prop] !== undefined) { newObj[prop] = obj[prop]; }
    });
    return newObj;
};

export const getDirtyValues = (oldObj = {}, newObj = {}) => {
    let dirtyObj = {};
    Object.keys(newObj).forEach((key) => {
        if (typeof newObj[key] === 'object' && (!Array.isArray(newObj[key]) && newObj[key] !== null)) {
            Object.keys(newObj[key]).forEach((deepKey) => {
                if (newObj[key][deepKey] !== oldObj[key][deepKey]) {
                    dirtyObj[key][deepKey] = newObj[key][deepKey];
                }
            });
        } else if (newObj[key] !== oldObj[key]) {
            dirtyObj[key] = newObj[key];
        }
    });
    return dirtyObj;
};
export const checkArrayOfObjectSame = (oldArray = [], NewArray = []) => {
    var objectsAreSame = true;
    for (var propertyName in oldArray) {
        if (oldArray[propertyName] !== NewArray[propertyName]) {
            objectsAreSame = false;
            break;
        }
    }
    return objectsAreSame;
};
export const getAvatarTxt = (data, from) => {
    if (data) {
        let first_name = from === "PaymentComplete" ? "payee_first_name" : from === "entity" ? "entity_person_first_name" : "first_name";
        let last_name = from === "PaymentComplete" ? "payee_last_name" : from === "entity" ? "entity_person_last_name" : "last_name";
        let company_name = from === "PaymentComplete" ? "payee_company_name" : "company_name";
        let entity_type = from === "PaymentComplete" ? "payee_type" : "entity_type";
        var avatarTxt = "";
        if (data && data[`${entity_type}`] === ENTITY.ENTITY_TYPE.COMPANY && data[company_name]) {
            let spaceRegex = /^\s$/g;
            if (!spaceRegex.test(data[company_name])) {
                let companyName = data[company_name].match(/\b(\w)/g).join('').substring(0, 2).toUpperCase();
                avatarTxt = companyName;
            }
        }
        if (data && (data[`${first_name}`] || data[`${last_name}`])) {
            let name = (data[`${first_name}`] ? data[`${first_name}`].charAt(0) : "") + (data[`${last_name}`] ? data[`${last_name}`].charAt(0) : "");
            avatarTxt = name;

        }

        return avatarTxt.toUpperCase();
    }

};

export const groupDateWise = (arr, key = "payment_at") => {
    const data = arr.reduce((acc, curr) => {
        const dateStr = curr.[key] ? curr.[key].split("T")[0] : "";
        if (curr.payment_status !== PAYMENT.PAYMENT_STATUS.DRAFTED) {
            if (!acc[dateStr]) {
                acc[dateStr] = [curr];
            } else {
                acc[dateStr].push(curr);
            }

        }

        return acc;
    }, {});
    return data;
};

export const groupDraftWise = (arr, type) => {
    const data = arr.reduce((acc, curr) => {
        if (curr.payment_status === PAYMENT.PAYMENT_STATUS.DRAFTED) {
            if (!acc[PAYMENT.PAYMENT_STATUS.DRAFTED]) {
                acc[PAYMENT.PAYMENT_STATUS.DRAFTED] = [curr];
            } else {
                acc[PAYMENT.PAYMENT_STATUS.DRAFTED].push(curr);
            }

        }
        return acc;
    }, {});
    return data;
};

export function getFormattedAmount(amount, currency) {
    let styleObj = { style: 'decimal' };
    let sym = '';
    if (currency) {
        sym = currencySymbol(currency);
    }
    // if (currency) {
    //     styleObj = {style: 'currency',currency};
    // }
    const formattedAmount = new Intl.NumberFormat('en', {
        ...styleObj,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
    return `${sym}${formattedAmount}`;
}
export function ordinal_suffix_of(i) {

    var j = i % 10,
        k = i % 100;

    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";

}
export function downloadCSVFile(arrayData, Headers, filename, callBack) {
    var data = [];
    var rows = arrayData;
    var Header = Headers;
    data.push(Header.map((item) => {
        return item.name;
    }).join(","));
    for (var i = 0; i < rows.length; i++) {
        var row = [];
        let current = rows[i];
        for (var j = 0; j < Header.length; j++) {
            var ex = ""
            if (Header[j].name === "Date") {
                ex = current[Header[j].key] ? moment(current[Header[j].key]).format("DD/MM/YYYY") : "";
            }

            if (Header[j].name === "Destination Amount") {
                ex = current.destination_amount ? Number(current.destination_amount).toFixed(2) : "";
            }
            if (Header[j].name === "Source Amount") {
                ex = current[Header[j].key] ? Number(current[Header[j].key]).toFixed(2) : "";
            }
            if (Header[j].name !== "Date" && Header[j].name !== "Source Amount" && Header[j].name !== "Destination Amount") {
                ex = current[Header[j].key];
            }
            if (Header[j].name === "Tags") {
                const val = current[Header[j].key] ? current[Header[j].key] : "";
                ex = Array.isArray(val) ? val.join(",") : '';
            }
            let newEx = ex && ex.replace ? ex.replace(/,/g, ' ') : (ex || '');
            row.push(newEx);
        }
        data.push(row.join(","));
    }
    importCsv(data.join("\n"), filename, callBack);
}
// export const account_ledger_quickBook_csv_header = [{ key: "created_at", name: "Date" },
// { key: "from_to_display_name", name: "Description" },
// { key: "ledger_operation", name: "Credit" },
// { key: "amount_currency_code", name: "Debit" },
// ];

export function downloadQuickBookCSVFile(arrayData, Headers, filename, callBack) {
    var data = [];
    var rows = arrayData;
    var Header = Headers;
    data.push(Header.map((item) => {
        return item.name;
    }).join(","));

    for (var i = 0; i < rows.length; i++) {
        var row = [];
        let current = rows[i];
        for (var j = 0; j < Header.length; j++) {
            var ex = ""
            if (Header[j].name === "Date") {
                ex = current[Header[j].key] ? moment(current[Header[j].key]).format("DD/MM/YYYY") : ""
            }
            if (Header[j].name === "Credit") {
                ex = current.ledger_operation === "CREDIT" && current.amount ? Number(current.amount).toFixed(2) : "";
            }
            if (Header[j].name === "Debit") {
                ex = current.ledger_operation === "DEBIT" && current.amount ? Number(current.amount).toFixed(2) : "";
            }
            if (Header[j].name !== "Debit" && Header[j].name !== "Credit" && Header[j].name !== "Date") {
                ex = current[Header[j].key];
            }
            let newEx = ex?.replace(/,/g, ' ');
            row.push(newEx)
        }
        data.push(row.join(","));
    }
    importCsv(data.join("\n"), filename, callBack);
}


export function importCsv(csv, filename, callBack = () => null) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
    callBack();

}

export const payment_csv_header = [{ key: "payment_at", name: "Date" },
{ key: "from_to_display_name", name: "Contact Name" },
{ key: "source_currency", name: "Source Currency" },
{ key: "source_amount", name: "Source Amount" },
{ key: "destination_country_code", name: "Destination Country Code" },
{ key: "destination_currency", name: "Destination Currency" },
{ key: "destination_amount", name: "Destination Amount" },
{ key: "payment_short_ref", name: "Payment Reference" },
{ key: "payment_status_description", name: "Payment Status" }
];

export const schedule_payment_csv_header = [{ key: "payment_at", name: "Date" },
{ key: "from_to_display_name", name: "Contact Name" },
{ key: "source_currency", name: "Source Currency" },
{ key: "source_amount", name: "Source Amount" },
{ key: "destination_country_code", name: "Destination Country Code" },
{ key: "destination_currency", name: "Destination Currency" },
{ key: "destination_amount", name: "Destination Amount" },
{ key: "payment_schedule_type", name: "Payment Type" },
{ key: "payment_short_ref", name: "Payment Reference" },
{ key: "payment_status_description", name: "Payment Status" }
];
export const account_ledger_quickBook_csv_header = [{ key: "created_at", name: "Date" },
{ key: "from_to_display_name", name: "Description" },
{ key: "ledger_operation", name: "Credit" },
{ key: "amount_currency_code", name: "Debit" },
];

export const account_ledger_csv_header = [{ key: "created_at", name: "Date" },
{ key: "from_to_display_name", name: "Name" },
{ key: "ledger_operation", name: "Ledger Operation" },
{ key: "amount_currency_code", name: "Amount Currency" },
{ key: "amount", name: "Source Amount" },
{ key: "purpose_name", name: "Purpose of Payment" },
{ key: "tags", name: "Tags" },
{ key: "note", name: "Note to recipient" },
{ key: "payment_status", name: "Payment Status" }];

export const card_csv_header = [{ key: "created_at", name: "Date" },
{ key: "merchant_name", name: "Name" },
{ key: "nick_name", name: "NickName" },
{ key: "transaction_type", name: "Ledger Operation" },
{ key: "transaction_currency_code", name: "Transaction Currency" },
{ key: "transaction_amount", name: "Transaction Amount" },
{ key: "currency_code", name: "Billing Currency" },
{ key: "amount", name: "Billing Amount" },
{ key: "card_transaction_id", name: "Card Transaction id" }];

export const external_account_ledger_csv_header = [{ key: "prs_created_at", name: "Date" },
{ key: "prs_description", name: "Name" },
{ key: "prs_ledger_operation", name: "Ledger Operation" },
{ key: "prs_amount", name: "Source Amount" },
];
export function addSpaceEvery(value, every = 4, separator = " ") {
    let v = value.match(/.{1,4}/g);
    return v.join(separator);
}

export function getKeyValueByStringPath(o, s) {
    try {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, ''); // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    } catch (error) {
        console.error('error: ', error);
        return undefined;
    }
}
export const groupRegionWiseCountry = (arr, type) => {
    const data = arr.reduce((acc, curr) => {
        const dateStr = curr.region_name ? curr.region_name : "";
        if (!acc[dateStr]) {
            acc[dateStr] = [curr];
        } else {
            acc[dateStr].push(curr);
        }
        return acc;
    }, {});

    return data;
};

export const getHeader = (key, fun = () => null) => {
    const header = fun();
    if (key && header && header != null) {
        return {
            [key]: header || '',
        };
    }
    return {};
};

export const getUrlParamByName = (name) => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const c = url.searchParams.get(name);
    return c;
};


export const checkIsObjectKeyEmpty = (object) => {
    var checkValue = false;
    for (var property in object) {
        if (object[property] && typeof object[property] == "string") {
            checkValue = true;
            break;
        }
        if (object[property] && typeof object[property] == "object" && Object.keys([object[property]]).length > 0) {
            checkValue = true;
            break;
        }

    }

    return checkValue;
};

export const checkAddressArrayIsEmpty = (array) => {

    let tempArray = array && array.addresses ? array.addresses : [];
    var checkValue = false;
    loop1:
    for (var i = 0; i < tempArray.length; i++) {
        for (var key in tempArray[i]) {
            if (typeof tempArray[i][key] == "string" && key !== "address_type" && tempArray[i][key]) {
                checkValue = true;
                break loop1;
            }
        }


    }
    return checkValue;
};
export const ID_VERIFICATION_STEPS = {
    CONSENT: "CONSENT",
    CONFIDENTIAL_AGREEMENT: "CONFIDENTIAL_AGREEMENT",
    TERM_AND_CONDITION: "TERM_AND_CONDITION",
    USER_INFORMATION: "USER_INFORMATION",
    ID_VERIFICATION: "ID_VERIFICATION",
    CREATE_PASSWORD: "CREATE_PASSWORD",
    COMPLETE: "COMPLETE",
    CONFIRM_DETAILS: "CONFIRM_DETAILS",
    NOT_HAVE_DOC: "NOT_HAVE_DOC",
    ADD_CURRENT_ADDRESS: "ADD_CURRENT_ADDRESS",
    INVITATION_ACCEPTED: "INVITATION_ACCEPTED",
    TEAM_MEMBER_INITIAL: "TEAM_MEMBER_INITIAL"
};
export const SEND_PAYMENT_STEPS = {
    SELECT_CONTACT: "SELECT_CONTACT",
    CONTACT_ADD: "CONTACT_ADD",
    ENTER_AMOUNT: "ENTER_AMOUNT",
    CHOOSE_RECURRENCE: "CHOOSE_RECURRENCE",
    SELECT_PAYMENT_ACCOUNT: "SELECT_PAYMENT_ACCOUNT",
    PAYMENT_COMPLETE: "PAYMENT_COMPLETE"
}

export async function deleteCookieByName(name) {
    
    const _hsp = window._hsp = window._hsp || []; 
    _hsp.push(['revokeCookieConsent']);

    document.cookie = `${name}=; path=/; domain=.trulyfinancial.com; expires=${new Date(0).toUTCString()}`
    
    // document.cookie = `${name}=; path=/; expires=${new Date(0).toUTCString()}`
}

export function getAccountLabel({
    account_type,
    account_number,
    iban,
    currency_code,
    clear_balance
}) {
    const currLabel = {
        USD: 'Checking',
        CAD: 'Checking',
        GBP: 'Checking',
        EUR: 'Checking',
    }
    const aLabel = currLabel[currency_code] ? currLabel[currency_code] : 'Multi-Currency'
   return `${currency_code || ''} ${account_type === 'CHECKING' ? aLabel : 'Account'} ${account_number ? `(${account_number?.substr(account_number.length - 4)})` : `(${iban?.substr(iban.length - 4)})`} ${clear_balance ? `\n${currencySymbol(currency_code)} ${Number(clear_balance)?.toFixed(2)} ${currency_code}` : ''}`
}
export function getAccountLabelWithoutBalance({
    account_type,
    account_number,
    iban,
    currency_code,
    clear_balance
}) {
    const currLabel = {
        USD: 'Checking',
        CAD: 'Checking',
        GBP: 'Checking',
        EUR: 'Checking',
    }
    const aLabel = currLabel[currency_code] ? currLabel[currency_code] : 'Multi-Currency'
   return `${currency_code} ${account_type === 'CHECKING' ? aLabel : 'Account'} ${account_number ? `(${account_number?.substr(account_number.length - 4)})` : `(${iban?.substr(iban.length - 4)})`}`
}

export function fxRateDisplay({
    source_currency,
    destination_currency,
    fx_rate
}) {
    if (!source_currency || !destination_currency || !fx_rate) {
        return ''
    }
    return `1 ${source_currency} = ${fx_rate} ${destination_currency}`
}

export function getLastFourDigits(str = "") {
    return str.substr(str.length - 4, 4);
}

export function getRequestBankHeaders(obj) {
    if (!obj?.data?.link_token) {
       return null;
    }
    return {
      Authorization : obj?.data?.link_token,
      'X-Entity-Id': obj?.data?.entity_id,
    }
  }

export const DISABLE_ISSUE_SMARTSPEND = true;