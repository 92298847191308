/* eslint-disable jsx-a11y/interactive-supports-focus */

import React, { memo, useState } from 'react';
// import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
// import { Button } from 'components/ui';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import { FormattedMessage } from 'react-intl';
// import messages from './intl/LogoutIntl';
import { logout } from 'utils/auth';
import StyledDialog from 'components/StyledDialog';
import { useHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
// import { styledSnackbar } from 'containers/Common/Snackbar';

const useStyles = makeStyles(theme => ({
  logout: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(6.5),
    marginBottom: 30,
    marginTop: theme.spacing(2)
  },
  logoutIcon: {
    fontSize: theme.spacing(3.125),
    [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
      color: theme.palette.grey[400],
    },
    color: theme.palette.primary.contrastText,

  },
  logoutTxt: {
    marginLeft: theme.spacing(3.2),
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
}));

function Logout() {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const onNoPress = () => {
    onToggle();
  };
  const onYesPress = async () => {
    await logout();
    onToggle();
    // styledSnackbar.info('You have been logged out successfully!');
    history.push("/login");
    // history.push("/logout");
  };

  const onToggle = () => {
    setOpen(!open);
  };
  return (
    <>
      {/* <div
        role="button"
        onKeyDown={onToggle}
        onClick={onToggle}
        className={classes.logout}
      >
        <ExitToAppIcon className={classes.logoutIcon} />
        <Typography className={classes.logoutTxt} noWrap>
          <FormattedMessage {...messages.logout} />
        </Typography>
      </div> */}
      <div>
        <IconButton size="small" onClick={onToggle}><ExitToAppIcon className={classes.logoutIcon} /></IconButton>
        {/* <Button size="small" onClick={onToggle}><ExitToAppIcon className={classes.logoutIcon} /> &nbsp;<FormattedMessage {...messages.logout} /></Button> */}
      </div>
      <StyledDialog
        open={open}
        noFullScreen
        disableBackdropClick={false}
        backBtn={false}
        type="confirm"
        message="Log out of Truly Financial?"
        title="Logout"
        btnOneTitle="Cancel"
        btnOneAction={onNoPress}
        btnTwoTitle="Log out"
        btnTwoAction={onYesPress}
        header={false}
        onClose={onNoPress}
        footer={true}
        closeIcon
      />
    </>
  );
}

Logout.propTypes = {

};

export default memo(Logout);
