import { USER_BASE_API_URL, REGISTER_BASE_API_URL, HUBSPOT_BASE_URL } from "config/apiUrls";
import { styledSnackbar } from "containers/Common/Snackbar";
import { request, requestWithErrorFeedback } from "utils/request";
const USER_URL = `${USER_BASE_API_URL}/users`;
const HUBSPOT_URL = `${HUBSPOT_BASE_URL}/hubspot`;
const RESET_PASSWORD_URL = `${REGISTER_BASE_API_URL}/reset-password`;
export function updateUserPhoneNumber(body) {
    const requestUrl = `${USER_URL}/user-phone`;
    const options = {
        method: 'PUT',
        body,
    }
    return request(requestUrl, options);
}
export function markAttributeVerified(attributeName, body={}) {
    if (!attributeName) {
      styledSnackbar.error('Attribute Name required');
      return  
    }
    const requestUrl = `${USER_URL}/mark-attribute-verified/${attributeName}`;
    const options = {
        method: 'POST',
        body,
    }
    return request(requestUrl, options);
}
export function resetPasswordApi(email) {
    if (!email) {
      styledSnackbar.error('Enter email');
      return  
    }
    const requestUrl = `${RESET_PASSWORD_URL}`;
    const options = {
        method: 'POST',
        body: {email},
    }
    return requestWithErrorFeedback(requestUrl, options);
}

export function getHubspotVisitorToken(body) {
    const requestUrl = `${HUBSPOT_URL}/visitor-identification`;
    const options = {
        method: 'POST',
        body,
    }
    return request(requestUrl, options);
}