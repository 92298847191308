import React, { useState } from "react";
import preval from 'preval.macro';
import { makeStyles } from "@material-ui/core";
import { Typography } from "components/ui";
import GitInfo from 'react-git-info/macro';
import { API_BASE_URL } from "config/apiUrls";
import TrulyLogo from 'assets/images/logo/Truly_Logo_Light.png';
import { Link } from "react-router-dom";
import awsConfig from "config/aws-config";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "10%",
    left: 0,
    right: 0,
    textAlign: "center",
  },
  infoDiv: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  config: {
    padding: theme.spacing(2),
    wordBreak: "break-all",
  },
  home: {
    marginTop: theme.spacing(2),
  },
}));

function BuildInfoPage(props={}){
    const classes = useStyles();
    const gitInfo = GitInfo();
    const [showMore, setShowMore] = useState(false);
    const [showConfig, setShowConfig] = useState(false);

    const onShowMore = () => {
        setShowMore(!showMore);
    }

    const onShowConfig = () => {
        setShowConfig(!showConfig);
    }

    return(<>
    <div className={classes.root}>
        <div className={classes.infoDiv}>
            <div><img alt="truly" width="100" src={TrulyLogo} /></div>
            {/* <div><Typography variant="caption" color="grey400"> Build Date: <strong>{preval`module.exports = new Date().toLocaleString();`}.</strong></Typography></div> */}
            <div><Typography variant="caption" color="grey100"> <span onClick={onShowMore}>Build Date:</span> <strong>{preval`module.exports = new Date().toString().slice(0, 24);`}</strong></Typography></div>
            {showMore && <div>
                <div><Typography variant="caption" color="grey100">Branch: <strong>{gitInfo.branch}</strong></Typography></div>
                <div><Typography variant="caption" color="grey100">Commit: <strong>{gitInfo.commit.shortHash} - {gitInfo.commit.message}</strong></Typography></div>
                <div><Typography variant="caption" color="grey100">Commit Date: <strong>{new Date(gitInfo.commit.date).toString().slice(0, 24)}</strong></Typography></div>
                <div><Typography variant="caption" color="grey100"><span onClick={onShowConfig}>API:</span> <strong>{API_BASE_URL}</strong></Typography></div>
            </div>}
            <div className={classes.home}><Link to="/">Home</Link></div>
        </div>
        {showConfig && <div className={classes.config}>
            <br />
            <div>
                <code>{JSON.stringify(awsConfig)}</code>
            </div>
            <br />
            <div>
                <code>{JSON.stringify(process.env)}</code>
            </div>
        </div>}
    </div>
    </>)
}

export default BuildInfoPage;