
import { memo } from 'react';


function PaymentListDate(props = {}) {
    const { date } = props;

    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];
    let currentYear = new Date().getUTCFullYear();
    const d = new Date(date);
    let _date = d.getUTCDate();
    let _month = monthNames[d.getUTCMonth()];
    let _Year = d.getUTCFullYear();
    let checkYear = currentYear === _Year ? "" : _Year;
    let styledDate = _date + " " + _month + " " + checkYear;

    return styledDate;
}


export default memo(PaymentListDate);
