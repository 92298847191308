/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import StyledDialog from 'components/StyledDialog';
import { compose } from 'redux';
import { getBankAccountConfigState } from 'store/Reference/referenceSelectors';
import { createStructuredSelector } from 'reselect';
import { Loader } from 'components';
import { Button } from 'components/ui';
import AddBankAccount from 'containers/Contacts/AddBankAccount';
import ContactBankDetailsConfirm from './ContactBankDetailsConfirm';
import { updateBankDetailAction } from 'store/ContactRequestBank/contactRequestBankActions';
import { updateBankDetailState } from 'store/ContactRequestBank/contactRequestBankSelectors';
import BankDetailSubmitted from './BankDetailSubmitted';
import { sendOtpAction } from 'store/Otp/otpActions';
import { sendOtpState } from 'store/Otp/otpSelectors';
import { getRequestBankHeaders } from 'utils/common';
import { validateLinkState } from 'store/User/userSelectors';

const CONTACT_STEPS = {
    BANK_DETAILS: 'BANK_DETAILS',
    CONFIRM_INFO_AND_BANK_DETAILS: 'CONFIRM_INFO_AND_BANK_DETAILS',
    BANK_DETAILS_SUBMITTED: 'BANK_DETAILS_SUBMITTED'
}

function ContactAddBankDialog(props = {}, ref) {
    const { onCancelAction, data, onSuccessAction, bankConfig,
        updateBankDetailResp, linkRes,
        updateBankDetail, sendOtpRes, sendOtp
    }  = props;
    const [open, setOpen] = useState(false);
    const [contactObj, setContactObj] = useState({
        address: data.address,
        bank_details: {},
        ...data
    });
    const [step, setStep] = useState(CONTACT_STEPS.BANK_DETAILS);

    useImperativeHandle(ref, () => ({
        onOpen,
    }));
    const onOpen = () => {
        setOpen(true);
    };
    const onClosePopup = () => {
        setOpen(false);
        onCancelAction && onCancelAction();
    };

    const onSuccessSubmit = () => {
        setStep(CONTACT_STEPS.BANK_DETAILS_SUBMITTED);
        setTimeout(() => {
            onSuccessAction();
        }, 2000);
    }

    const onSubmit = (otpObj= {}) => {
        updateBankDetail({
            id: data.bank_account_id,
            body: {...contactObj, ...otpObj},
            cb: onSuccessSubmit,
            // formdata: {
            //     file,
            //     file_name: file.name,
            // },
            bankConfig: bankConfig.data,
        });
    }

    const onContactBankDetailsContinue = (data = {}) => {
        setContactObj((d) => ({ ...d, bank_details: { ...data.bankDetails }, address: { ...data.address }}));
        sendOtp({
            headers: { ...getRequestBankHeaders(linkRes)},
            cb : () => {
                setStep(CONTACT_STEPS.CONFIRM_INFO_AND_BANK_DETAILS);
            }
        })
    }

    const onBackConfirmAllInfo = () => {
        setStep(CONTACT_STEPS.BANK_DETAILS);
    }

    return (<>
        <StyledDialog
            title={step === CONTACT_STEPS.CONFIRM_INFO_AND_BANK_DETAILS ? 'Confirm Bank Details' : ''}
            header={step === CONTACT_STEPS.CONFIRM_INFO_AND_BANK_DETAILS}
            open={open}
            type="content"
            footer={false}
            disableBackdropClick={true}
            onClose={onClosePopup}
            triggerComponent={(onOpen) => (<Button
                title="Submit Deposit Instructions"
                color="secondary"
                onClick={onOpen}
            />)}
        >
            {(updateBankDetailResp.loading || sendOtpRes.loading) && <Loader />}
            {step === CONTACT_STEPS.BANK_DETAILS ? <AddBankAccount addressTittle="Your Address" data={contactObj} onContinue={onContactBankDetailsContinue} /> : null}
            {step === CONTACT_STEPS.CONFIRM_INFO_AND_BANK_DETAILS ? <ContactBankDetailsConfirm data={contactObj} onContinue={onSubmit} onBack = {onBackConfirmAllInfo} /> : null}
            {step === CONTACT_STEPS.BANK_DETAILS_SUBMITTED ? <BankDetailSubmitted /> : null}
        </StyledDialog>
        </>)
};

const mapStateToProps = createStructuredSelector({
    updateBankDetailResp: updateBankDetailState(),
    bankConfig: getBankAccountConfigState(),
    sendOtpRes: sendOtpState(),
    linkRes: validateLinkState(),
});

function mapDispatchToProps(dispatch) {
    return {
        updateBankDetail: data => dispatch(updateBankDetailAction(data)),
        sendOtp: data => dispatch(sendOtpAction(data)),
    }
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
);

export default compose(
    withConnect,
    memo,
)(React.forwardRef(ContactAddBankDialog));