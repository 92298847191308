const CHECKING_ACCOUNT = {
    code: "CHECKING_ACCOUNT",
    name: "Checking Account",
    path: "/",
    functions: {
        ACCOUNT_LIST: {
            code: "ACCOUNT_LIST",
            name: "Account List",
            isPage: false,
            component: 'AccountListPage',
            path: "",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LIST.path}`,
            getFullPath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LIST.path}`,
        },
        ACCOUNT_CREATE: {
            code: "ACCOUNT_CREATE",
            name: "Account Create",
            isPage: false,
            component: 'AccountListPage',
            path: "",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_CREATE.path}`,
            getFullPath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_CREATE.path}`,
        },
        ACCOUNT_DETAILS: {
            code: "ACCOUNT_DETAILS",
            name: "Account Details",
            isPage: true,
            component: 'AccountDetailsPage',
            path: "/:id/details",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_DETAILS.path}`,
            getFullPath: (id) =>
                `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_DETAILS.path}`.replace(
                    ":id",
                    id
                ),
        },
        ACCOUNT_LEDGERS_LIST: {
            code: "ACCOUNT_LEDGERS_LIST",
            name: "Account Ledgers",
            isPage: false,
            component: '',
            path: "/ledgers",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LEDGERS_LIST.path}`,
            getFullPath: (accountId) => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LEDGERS_LIST.path}`.replace(
                ":accountId",
                accountId
            ),
        },
        ACCOUNT_LEDGER_DETAILS: {
            code: "ACCOUNT_LEDGER_DETAILS",
            name: "Account Ledgers Details",
            isPage: false,
            component: '',
            path: "/:accountLedgerId",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LEDGER_DETAILS.path}`,
            getFullPath: (accountId, accountLedgerId) =>
                `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LEDGER_DETAILS.path}`.replace(
                    ":accountId",
                    accountId
                ).replace(
                    ":accountLedgerId",
                    accountLedgerId
                ),
        },
        ACCOUNT_LEDGER_FILTER: {
            code: "ACCOUNT_LEDGER_FILTER",
            name: "Account Ledgers Filter",
            isPage: false,
            component: '',
            path: "/filter",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LEDGER_FILTER.path}`,
            getFullPath: (accountId) => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_LEDGER_FILTER.path}`.replace(
                ":accountId",
                accountId
            ),
        },
        ACCOUNT_SEND_PAYMENT: {
            code: "ACCOUNT_SEND_PAYMENT",
            name: "Account Send Payment",
            isPage: false,
            component: '',
            path: "/",
            getRoutePath: () => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_SEND_PAYMENT.path}`,
            getFullPath:() => `${CHECKING_ACCOUNT.path}${CHECKING_ACCOUNT.functions.ACCOUNT_SEND_PAYMENT.path}`
        },

    },
};

export default CHECKING_ACCOUNT;
