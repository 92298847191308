import {
  RESET_CONTACT_REQUEST_BANK,
  GET_REQUEST_DETAIL, GET_REQUEST_DETAIL_SET_DATA,
  UPDATE_BANK_DETAIL, UPDATE_BANK_DETAIL_SET_DATA,
} from './contactRequestBankConstants';
import { updateBankDetailParser } from './contactRequestBankParser';

export function resetContactRequestBankAction() {
  return { type: RESET_CONTACT_REQUEST_BANK };
}

export function getRequestDetailAction(data) {
  return { type: GET_REQUEST_DETAIL, data };
}
export function getRequestDetailSetDataAction(data) {
  return { type: GET_REQUEST_DETAIL_SET_DATA, data };
}

export function updateBankDetailAction(data) {
  return { type: UPDATE_BANK_DETAIL, data: updateBankDetailParser(data) };
}
export function updateBankDetailSetDataAction(data) {
  return { type: UPDATE_BANK_DETAIL_SET_DATA, data };
}
