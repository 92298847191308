import { Auth } from 'aws-amplify';
import awsConfig from 'config/aws-config';
import { styledSnackbar } from 'containers/Common/Snackbar';
const serviceWithClient = `CognitoIdentityServiceProvider.${awsConfig.aws_user_pools_web_client_id}`;

export function getLastAuthUser() {
  return localStorage.getItem(`${serviceWithClient}.LastAuthUser`);
}

export function setLastAuthUser(id) {
  return new Promise((resolve, reject) => {
    if (id) {
      localStorage.setItem(`${serviceWithClient}.LastAuthUser`, id);
      return resolve(true);
    }
    return resolve(false);
  });
}
// export function setLastAuthUser(id) {
//   return localStorage.setItem(`${serviceWithClient}.LastAuthUser`, id);
// }

export function removeLastAuthUser(id) {
  return localStorage.removeItem(`${serviceWithClient}.LastAuthUser`);
}

export function setAccessToken(token) {
  return new Promise((resolve, reject) => {
    if (token) {
      localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.accessToken`, token);
      return resolve(true);
    }
    return resolve(false);
  });
}
// export function setAccessToken(token) {
//   return localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.accessToken`, token);
// }
export function getAccessToken() {
  return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.accessToken`);
}
export function removeAccessToken() {
  return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.accessToken`);
}

export function getRefreshToken() {
  return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.refreshToken`);
}
export function removeRefreshToken() {
  return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.refreshToken`);
}

export function getEntityId() {
  return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyEntityId`);
}

export function removeDeviceKey() {
  return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.deviceKey`);
}

export function setEntityId(value) {
  return new Promise((resolve, reject) => {
    if (value) {
      localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyEntityId`, value);
      return resolve(true);
    }
    return resolve(false);
  });
}
// export function setEntityId(value) {
//   return value && localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyEntityId`, value);
// }

export function removeEntityId() {
  return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyEntityId`);
}

export function getMfaDeviceTimeStamp() {
  return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.mfaDevice`);
}

export function setMfaDeviceTimeStamp() {
  return localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.mfaDevice`, + new Date());
}

export function removeMfaDeviceTimeStamp() {
  return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.mfaDevice`);
}

export function isMfaDeviceExpired() {
  let isExpired = false;
  const t = Number(getMfaDeviceTimeStamp());
  if (!t || isNaN(t)) {
    isExpired = true;
    return isExpired;
  }
  try {
    const days = Math.floor((new Date().getTime() - new Date(t).getTime())/1000/60/60/60);
    // const hours = Math.floor((new Date().getTime() - new Date(t).getTime())/1000/60/60);
    // const minutes = Math.floor((new Date().getTime() - new Date(t).getTime())/1000/60);
    // if (isNaN(hours) || hours >= 24) {
    if (isNaN(days) || days > 10) {
    // if (isNaN(hours) || hours >= 24) {
    // if (isNaN(minutes) || minutes >= 3) {
      isExpired = true;
    } else {
      isExpired = false;
    }
  } catch(e) {
    isExpired = true;
  }
  return isExpired;
}

// export function getLoggedInStatus() {
//   return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.loggedInStatus`);
// }

// export function setLoggedInStatus() {
//   return localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.loggedInStatus`, getLastAuthUser());
// }

// export function removeLoggedInStatus() {
//   return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.loggedInStatus`);
// }

export function getIdToken() {
  return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.idToken`);
}

export function getUserDataByKey(k) {
  const ud = localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.userData`);
  let v = '';
  try {
    const d = JSON.parse(ud);
    const kObj = d.UserAttributes.find(i => i.Name === k);
    v = kObj.Value;
  } catch (error) {
    console.error('User data key not found')
    return v;
  }
  return v;
}

export const logoutGlobal = async () => {
  clearTimeout(tokenRefreshTimeout);
  try {
    await Auth.signOut({ global: true });
    localStorage.clear();
  } catch (error) {
    // console.error('error signing out: ', error);
  }
};

export const logout = async () => {
  clearTimeout(tokenRefreshTimeout);
  try {
    // removeLoggedInStatus();
    removeAccessToken();
    removeLastAuthUser();
    await Auth.signOut();
    // localStorage.clear();
  } catch (error) {
    // console.error('error signing out: ', error);
  }
};

export const refreshToken = async () => {
  clearTimeout(tokenRefreshTimeout);
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (err) {
        styledSnackbar.error('Session Timed out. Please log in again.');
        logout();
        window.location.reload();
      }
      // do something with the new session
    });
  } catch (e) {
    logout();
    window.location.reload();
  }
};
var tokenRefreshTimeout = null;

export const recurringRefreshToken = () => {
  // refresh token 
  clearTimeout(tokenRefreshTimeout);
  if (!getRefreshToken()) {
    return;
  }
  tokenRefreshTimeout = setTimeout(() => {
    refreshToken();
  }, 1000);
};

export function getPublicId() {
  return localStorage.getItem(`${serviceWithClient}.TrulyPublicId`);
}

export function setPublicId(value) {
  return value && localStorage.setItem(`${serviceWithClient}.TrulyPublicId`, value);
}

export function removePublicId(value) {
  return value && localStorage.removeItem(`${serviceWithClient}.TrulyPublicId`);
}

export function getPublicToken() {
  return localStorage.getItem(`${serviceWithClient}.TrulyPublicToken`);
}

export function setPublicToken(value) {
  return value && localStorage.setItem(`${serviceWithClient}.TrulyPublicToken`, value);
}

export function removePublicToken(value) {
  return value && localStorage.removeItem(`${serviceWithClient}.TrulyPublicToken`);
}

export function setRegistrationToken(t) {
  return localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.registrationToken`, t);
}

export function getRegistrationToken() {
  return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.registrationToken`);
}

export function removeRegistrationToken() {
  return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.registrationToken`);
}

export async function setRegistrationLocalData ({token, uid, eid}) {
  let setAToken;
  try {
    const setuid = await setLastAuthUser(uid);
    await setEntityId(eid);
    if (setuid) {
      setAToken = await setAccessToken(token);
    }
  } catch (error) {
    console.error('error: ', error);
    styledSnackbar.error(error);
  } finally {
    return new Promise((resolve, reject) => {
      if (setAToken) {
        return resolve(true);
      }
      return resolve(new Error('can not set token'));
    });
  }
};

export function backupAndRemoveEntityId() {
    const currentEid = getEntityId();
    localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyBackupEntityId`, currentEid);
    removeEntityId();
}

export function getBackupEntityId() {
    return localStorage.getItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyBackupEntityId`);
}

export function removeBackupEntityId() {
    return localStorage.removeItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyBackupEntityId`);
}

export function restoreAndRemoveBackupEntityId() {
    const backupEid = getBackupEntityId() || '';
    const currentEid = getEntityId();
    const entityId = backupEid.length < 36 ? currentEid : backupEid;
    localStorage.setItem(`${serviceWithClient}.${getLastAuthUser()}.TrulyEntityId`, entityId);
    removeBackupEntityId();
}

export function setTempAccessToken(t) {
  localStorage.setItem(`TempAccessToken`, t);
}

export function removeTempAccessToken() {
  return localStorage.removeItem(`TempAccessToken`);
}

export function getTempAccessToken() {
  return localStorage.getItem(`TempAccessToken`);
}