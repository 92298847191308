
import React, { memo } from 'react';
import { makeStyles, } from '@material-ui/core';
import { Typography } from 'components/ui';
import messages from 'components/intl/CheckInAccOpenInfoIntl';
import { FormattedMessage } from 'react-intl';
import Button from 'components/ui/Button';
import PropType from 'prop-types';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        padding: theme.spacing(2)
    },
    firstTxt: {
        textAlign: 'center',

    },
    secondTxt: {
        textAlign: 'center',
        margin: theme.spacing(1.5, 0, 4, 0)

    },
    secondDiv: {
        marginTop: theme.spacing(1.5)
    },
    btnDiv: {
        // flex: 1,
        margin: theme.spacing(3, 2, 0, 2),
        alignSelf: 'center'
    },
    accIcon: {
        margin: theme.spacing(5, 0, 5, 0),

        fontSize: theme.spacing(10)
    },
    accountDiv: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    detailSubTxt: {
        marginTop: theme.spacing(1)
    },
    detailItemDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    dotIcon: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(1.25)
    }
}));

function CheckInAccOpenInfo(props) {

    const { onClick } = props;
    const classes = useStyles();

    return (
        <>
            <div className={classes.root} >
                <div className={classes.accountDiv} >
                    <AccountBalanceIcon className={classes.accIcon} />
                </div>

                <Typography variant="h5" color="grey700" className={classes.firstTxt} ><FormattedMessage {...messages.txt1} /></Typography>
                <Typography variant="body1" color="grey500" className={classes.secondTxt} ><FormattedMessage {...messages.txt5} /></Typography>
                <Typography variant="subtitle1" color="grey700" ><FormattedMessage {...messages.detail} /></Typography>
                <div className={classes.secondDiv} >
                    <div className={classes.detailItemDiv} >
                        <FiberManualRecordIcon className={classes.dotIcon} />
                        <Typography variant="body1" color="grey700" className={classes.detailSubTxt} ><FormattedMessage {...messages.detailTxt1} /></Typography>
                    </div>
                    <div className={classes.detailItemDiv}>
                        <FiberManualRecordIcon className={classes.dotIcon} />
                        <Typography variant="body1" color="grey700" className={classes.detailSubTxt} ><FormattedMessage {...messages.detailTxt2} /></Typography>
                    </div>
                    <div className={classes.detailItemDiv}>
                        <FiberManualRecordIcon className={classes.dotIcon} />
                        <Typography variant="body1" color="grey700" className={classes.detailSubTxt} ><FormattedMessage {...messages.detailTxt3} /></Typography>
                    </div>

                </div>

                <div className={classes.btnDiv} >
                    <Button onClick={onClick} title="Great! Open my Account" />
                </div>


            </div>
        </>
    );
}
CheckInAccOpenInfo.defaultProps = {
    onClick: () => { },
}

CheckInAccOpenInfo.propTypes = {
    onClick: PropType.func
};

export default memo(CheckInAccOpenInfo);
