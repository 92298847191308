import { ENTITY, PAYMENT } from "@truly-financial/common/consts";
import { LEDGER_IDENTIFICATION_TYPE } from "@truly-financial/common/consts/account-ledger.consts";



export const sendPaymentListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r.data.map(i => ({
        ...i,
        // prs_make_recurring: (!i.event_schedule_id && i.payment_status === PAYMENT.PAYMENT_STATUS.READY_FOR_SETTLEMENT) || (!i.event_schedule_id && i.payment_status === PAYMENT.PAYMENT_STATUS.SETTLED),
        prs_make_recurring: false,
        prs_cancel: [PAYMENT.PAYMENT_STATUS.PENDING, PAYMENT.PAYMENT_STATUS.AWAITING_FUNDS, PAYMENT.PAYMENT_STATUS.QUEUED, PAYMENT.PAYMENT_STATUS.ON_HOLD, PAYMENT.PAYMENT_STATUS.READY_FOR_SETTLEMENT, PAYMENT.PAYMENT_STATUS.PICKED_FOR_SETTLEMENT].includes(i.payment_status),
        ledger_identification_type: LEDGER_IDENTIFICATION_TYPE.PAYMENT_TX
    }));
    parsedResponse.data = d;
    return parsedResponse;
};

export const getSchedulePaymentListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r.data.map(i => ({
        ...i,
        prs_delete: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.SCHEDULED || i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.RECURRING,
        prs_send_now: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.SCHEDULED,
        prs_reschedule: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.SCHEDULED,
        prs_edit_recurrence: i.payment_schedule_type === PAYMENT.PAYMENT_SCHEDULED_TYPE.RECURRING,
        prs_name: i && i.payee_type === ENTITY.ENTITY_TYPE.INDIVIDUAL ? `${i.payee_first_name}  ${i.payee_last_name}` : i.payee_company_name

    }));
    parsedResponse.data = d;
    return parsedResponse;
};
