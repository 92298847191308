/* eslint-disable eqeqeq */
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from './ui';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
    select: {
        background: '#F2F3F4',
        borderRadius: '8px',
        height: theme.spacing(7),
        // padding: theme.spacing(2, 0),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            background: '#D2E8F1',
        },

    },
    selected: {
        backgroundColor: '#D2E8F1',
        borderRadius: '8px',
        height: theme.spacing(7),
        // padding: theme.spacing(2, 0),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        margin: theme.spacing(0, 0, 0, 1.2),
        display: '-webkit-box',
        textAlign: 'left',
        // whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 3,
        boxOrient: 'vertical',
        // maxWidth: theme.spacing(10),
        // height: theme.spacing(6)
    },
    iconImg: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        margin: theme.spacing(0, 0, 0, 2)
    },
    checkIcon: {
        color: theme.palette.accent.main,
        height: theme.spacing(3),
        width: theme.spacing(3),
        margin: theme.spacing(0, 1, 0, 0),
    },
    emptyDiv: {
        height: theme.spacing(3),
        width: theme.spacing(3),
        margin: theme.spacing(0, 1, 0, 0),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));


/* eslint-disable react/prop-types */
function RegisterSquare(props = {}) {

    const { title, id, onPressItem = () => null, checkItem = () => null, img } = props;
    const classes = useStyles();

    return (<>
        <div key={id} onClick={onPressItem} className={checkItem(id) ? classes.selected : classes.select}>

            <div className={classes.row} >
                {img && <img src={img} className={classes.iconImg} alt="img" />}
                <Typography variant="body2" color="grey700" className={classes.title}>{title}
                    {/* {subTitle && id == 1 && `(${subTitle})`} */}
                </Typography>
            </div>

            {checkItem(id) ? <CheckCircleIcon className={classes.checkIcon} /> : <div className={classes.emptyDiv} />}
        </div>
    </>);
}

RegisterSquare.defaultProps = {

};
RegisterSquare.propTypes = {


};


export default memo(RegisterSquare);
