
export const RESET_PAYMENTS = 'Payments/RESET_PAYMENTS';

export const SEND_PAYMENT = 'Payments/SEND_PAYMENT';
export const SEND_PAYMENT_SET_DATA = 'Payments/SEND_PAYMENT_SET_DATA';

export const PAYMENT_LIST = 'Payments/PAYMENT_LIST';
export const SEND_PAYMENT_LIST_SET_DATA = 'Payments/SEND_PAYMENT_LIST_SET_DATA';

export const PRE_SEND_PAYMENT = 'Payments/PRE_SEND_PAYMENT';
export const PRE_SEND_PAYMENT_SET_DATA = 'Payments/PRE_SEND_PAYMENT_SET_DATA';

export const GET_PAYMENT_CURRENCIES = 'Payment/GET_PAYMENT_CURRENCIES';
export const GET_PAYMENT_CURRENCIES_SET_DATA = 'Payment/GET_PAYMENT_CURRENCIES_SET_DATA';

export const CANCEL_PAYMENT = 'Payment/CANCEL_PAYMENT';
export const CANCEL_PAYMENT_SET_DATA = 'Payment/CANCEL_PAYMENT_SET_DATA';

export const GET_SCHEDULED_PAYMENT_LIST = 'Payment/GET_SCHEDULED_PAYMENT_LIST';
export const GET_SCHEDULED_PAYMENT_LIST_SET_DATA = 'Payment/GET_SCHEDULED_PAYMENT_LIST_SET_DATA';

export const DELETE_SCHEDULED_PAYMENT = 'Payment/DELETE_SCHEDULED_PAYMENT';
export const DELETE_SCHEDULED_PAYMENT_SET_DATA = 'Payment/DELETE_SCHEDULED_PAYMENT_SET_DATA';

export const SEND_SCHEDULE_PAYMENT_NOW = 'Payment/SEND_SCHEDULE_PAYMENT_NOW';
export const SEND_SCHEDULE_PAYMENT_NOW_SET_DATA = 'Payment/SEND_SCHEDULE_PAYMENT_NOW_SET_DATA';

export const UPDATE_RECURRING_PAYMENT = 'Payment/UPDATE_RECURRING_PAYMENT';
export const UPDATE_RECURRING_PAYMENT_SET_DATA = 'Payment/UPDATE_RECURRING_PAYMENT_SET_DATA';

export const DELETE_DRAFT_PAYMENT = 'Payment/DELETE_DRAFT_PAYMENT';
export const DELETE_DRAFT_PAYMENT_SET_DATA = 'Payment/DELETE_DRAFT_PAYMENT_SET_DATA';

export const GET_CARD_PAYMENT_LIST = 'Payment/GET_CARD_PAYMENT_LIST';
export const GET_CARD_PAYMENT_LIST_SET_DATA = 'Payment/GET_CARD_PAYMENT_LIST_SET_DATA';

export const GET_CARD_PAYMENT_DETAIL = 'Payment/GET_CARD_PAYMENT_DETAIL';
export const GET_CARD_PAYMENT_DETAIL_SET_DATA = 'Payment/GET_CARD_PAYMENT_DETAIL_SET_DATA';
