import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Button, Typography } from 'components/ui';
import { FormattedMessage } from 'react-intl';
import intl from 'containers/TeamMembers/intl/TeamMembersIntl';
import { useHistory } from 'react-router';
import completeId from 'assets/images/completeId.png';

const useStyles = makeStyles((theme) => ({
    root: {
        background: 'white',
        backgroundColor: 'white',
        height: `calc(100vh - ${theme.spacing(11)}px)`,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    img: {
        width: theme.spacing(30.25),
        height: theme.spacing(30.25)
    },
    title: {
        marginTop: theme.spacing(8)
    },
    secondBtn: {
        marginTop: theme.spacing(5),
    }
}));

function InvitationAccepted(props = {}) {
    const classes = useStyles();
    const { data, showLoginBtn = true } = props;

    const firstName = data?.entity_person_first_name || "";
    const history = useHistory();

    const onGoToTruly = () => {
        history.push('/');
    };

    return (<div className={classes.root}>

        <img src={completeId} alt="img" className={classes.img} />
        <div>
            <Typography variant="titleMobile" color="grey700" className={classes.title} ><FormattedMessage {...intl.submittedVerificationInvitationAccept} />  {firstName}. </Typography>
            <Typography variant="body1" color="grey500"  ><FormattedMessage {...intl.submittedVerificationStackHolderApprovalBreak} /> </Typography>
        </div>
        {
            showLoginBtn &&
            <div>
                <Button
                    title={"Go to Truly Financial"}
                    className={classes.secondBtn}
                    onClick={onGoToTruly}
                />
            </div>
        }

    </div>);
}



export default memo(InvitationAccepted);
