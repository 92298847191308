import React from 'react';
import PropTypes from 'prop-types';
import { Icon as MuiIcon, makeStyles } from '@material-ui/core';
import combineClx from 'utils/combineClx';
// import useCommonStyles from 'styles';

const useStyles = makeStyles(theme => ({
  root: props => ({
    fontSize: props.fontSize === 'xlarge' ? '70px' : '',
    color: props.color ? theme.palette[props.color] : '',
  }),
}));

function Icon(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { children, classes: propClasses, color, component, fontSize, className } = props;
  return (<MuiIcon
    children={children}
    classes={propClasses}
    color={color.includes('grey') ? 'inherit' : color}
    component={component}
    fontSize={['inherit', 'small', 'medium', 'large'].includes(fontSize) ? fontSize : 'default'}
    className={combineClx(className, classes.root, classes.color)}
  />);
}

Icon.defaultProps = {
  children: null,
  classes: {},
  color: 'inherit',
  component: <></>,
  fontSize: 'inherit',
  className: '',
};

Icon.propTypes = {
  test: PropTypes.string,
};

export default Icon;
