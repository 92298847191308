import React, { memo, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import DialogSubFooter from 'components/DialogSubFooter';
import intl from 'containers/Contacts/intl/ContactsIntl';
import Button from 'components/ui/Button';
import { addContactBankAccountAction } from 'store/Contact/contactActions';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { addContactBankAccountState } from 'store/Contact/contactSelectors';
import { useRefHeight } from 'utils/hooks';
import DialogDynamicBody from 'components/DialogDynamicBody';

import { FormattedMessage } from 'react-intl';
import AddUpdateBankDetails from 'containers/Common/AddUpdateBankDetails';
import { AddressInput, Loader } from 'components';
import { getBankAccountConfigState } from 'store/Reference/referenceSelectors';
import { Typography } from 'components/ui';


const useStyles = makeStyles((theme) => ({
  inputDiv: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  root: {
    padding: theme.spacing(3),
  },
  btnDiv: {
    flex: 1,
    margin: theme.spacing(1, 1),
    [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
      margin: theme.spacing(0.4, 1),
    },
  },
  iconDiv: {
    background: "#e7b18d",
    height: theme.spacing(12.25),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.75),
  },
  title: {
    textAlign: "center",
  },
  requestCancelDiv: {
    background: "#EFF7FA",
    borderRadius: "8px",
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0)
  },
}));
function AddBankAccount(props = {}) {
    const { contactId, onSuccess, data = { address: {} }, bankConfig, onBack, onContinue,
        showRequestCancelationWarning, hideAddress,
        addContactBankAccountState,
        addressTittle = `Contact's Address`,
        isParentDialog } = props;
        // eslint-disable-next-line no-unused-vars
    let defaultAccountHolderName = '';
    if (data.entity_type === 'COMPANY' || data.contact_type === 'COMPANY') {
        defaultAccountHolderName = data.company_name;
    } else if (data.entity_type === 'INDIVIDUAL' || data.contact_type === 'INDIVIDUAL') {
        // eslint-disable-next-line no-unused-vars
        defaultAccountHolderName = `${data.entity_person_first_name ? data.entity_person_first_name +' ' : ''}${data.entity_person_middle_name ? data.entity_person_middle_name +' ' : ''}${data.entity_person_last_name ? data.entity_person_last_name : ''}`
    }
    // eslint-disable-next-line no-unused-vars
    const [headerHeight, headerHeightRef] = useRefHeight();
    const [footerHeight, footerHeightRef] = useRefHeight();
    const [inputRefs, setInputRefs] = useState([]);

    const classes = useStyles();
    const [formData, setFormData] = useState({ ...data?.bank_details });
    const [address, setAddress] = useState({ ...data.address });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (onContinue) {
            onContinue({bankDetails: formData, address})
            return;
        } 
        props.addBankAccount({
            id: contactId,
            body: formData,
            cb: onSuccess,
            bankConfig: bankConfig.data,
        });
    };

    const onChange = (e, val) => {
        const { name, value } = e.target || {};
        setFormData(formData => ({ ...formData, [name]: value }));
        if (name === 'bank_country_code' && !address.country_code) {
            setAddress((d) => ({ ...d, country_code: value}))
        }
    };

    const onAddressChange = (address) => {
        setAddress({...address})
    }

    const onRefFocus = async (textFieldName) => {
        if (textFieldName === "clear") {
            setInputRefs([]);
        } else {
            let refArray = [...inputRefs];
            refArray.push(textFieldName);
            setTimeout(() => {
                setInputRefs((ar) => [...new Set([...ar, textFieldName])]);
            }, 100);
        }
    };


    return (
        <>

            <form onSubmit={handleSubmit} className={classes.root} autoComplete="off">
                <DialogDynamicBody headerHeight={headerHeight} footerHeight={footerHeight} fixed={isParentDialog}>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="titleMobile">Add a Bank Account</Typography>
                        </div>
                        {showRequestCancelationWarning ? <div className={classes.requestCancelDiv}>
                            <Typography variant="caption">
                                Adding these details manually will dismiss the previously initiated request and deactivate the attached link.
                            </Typography>
                        </div>: <br />}
                        <Typography variant="subtitle1">Bank Account Details</Typography>
                        <AddUpdateBankDetails
                            defaultBankDetails={{ ...data?.bank_details, ...formData }}
                            onChange={onChange}
                            onFocusRef={onRefFocus}
                            inputRefArray={inputRefs}
                            // defaultAccountHolderName={defaultAccountHolderName}
                        />
                        {hideAddress ? null :<><Typography variant="subtitle1">{addressTittle}</Typography>
                        <AddressInput onChange={onAddressChange} data={address} /></>}
                    </div>
                </DialogDynamicBody>
                <DialogSubFooter innerRef={footerHeightRef} fixed={isParentDialog}>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        alignItems="center"
                    >
                        {onBack && <div className={classes.btnDiv}>
                            <Button
                                variant="outlined"
                                title={'Back'}
                                onClick={onBack}
                            />
                        </div>}
                        <div className={classes.btnDiv}>
                            <Button
                                type="submit"
                                title={'Continue'}
                            />
                        </div>
                    </Grid>
                </DialogSubFooter>
            </form>

            {addContactBankAccountState.loading && <Loader />}
        </>
    );
}


const mapStateToProps = createStructuredSelector({
    addContactBankAccountState: addContactBankAccountState(),
    bankConfig: getBankAccountConfigState(),
});

function mapDispatchToProps(dispatch) {
    return {
        addBankAccount: data => dispatch(addContactBankAccountAction(data)),
    }
}

AddBankAccount.defaultProps = {
    onSuccess: () => null,
    onCancelAction: () => null,
    btnSubmitTitle: <FormattedMessage {...intl.bankAddUpdateSubmitButtonLabel} />,
    isParentDialog: true,
    defaultErrorText: <FormattedMessage {...intl.errorDefaultText} />,
};

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(AddBankAccount);
