import React, { memo, createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, /*useTheme */ } from '@material-ui/core/styles';
import { IconButton, } from '@material-ui/core';
import {/* Chip*/ Typography } from 'components/ui';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import OptionMenu from './OptionMenu';
import StyledAmount from './StyledAmount';
import PaymentStatusDot from './PaymentStatusDot';
import PaymentListDate from './PaymentListDate';
import { ACCOUNT_LEDGER } from '@truly-financial/common/consts';
//import PaymentTags from './PaymentTags';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles(theme => ({
    middleDiv: props => ({
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
        display: props.type === "mobile" ? 'flex' : 'auto',
        flexDirection: 'row',
        alignItems: props.type === "mobile" ? "flex-start" : 'center',
        borderRadius: theme.spacing(3.125),
        padding: theme.spacing(0.5, 1, 0.5, 1),
        margin: theme.spacing(0.25, 0),
    }),
    badge: {
        marginRight: theme.spacing(1.2),
        [theme.breakpoints.down('xs')]: {
            '& .MuiBadge-anchorOriginBottomRightCircle': {
                right: '18%',
                bottom: '50%'
            }
        },

    },
    directionRow: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
        cursor: 'pointer'

    },
    wrapperDiv: {
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
        zIndex: 1


    },
    lightTxt: {
        color: theme.palette.grey[300],
    },

    handleDiv: {

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1
    },


    nameDiv: {
        // flex: 1
    },
    handlerResTxt: {
        fontSize: theme.spacing(2)
    },
    sendDiv: props => ({
        height: theme.spacing(4),
        width: theme.spacing(4),
        // backgroundColor: props.data.ledger_operation !== ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT ? theme.palette.grey[500] : theme.palette.grey[50],
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: props.type === "mobile" ? theme.spacing(0, 0.5, 0, 0) : theme.spacing(0, 0.5, 0, 10),
        flexDirection: 'column'
    }),
    sendIcon: props => ({
        fontSize: theme.spacing(2.5),
        transform: props.data.ledger_operation !== ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT ? 'rotate(140deg) ' : 'rotate(-45deg) ',
        // color: props.data.ledger_operation !== ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT ? theme.palette.primary.contrastText : theme.palette.grey[500]
        color: theme.palette.grey[500]
    }),
    priceDiv: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'end'
    },
    statusDiv: {
        display: 'flex',
        borderRadius: theme.spacing(3.125),
        padding: theme.spacing(0.1, 2, 0.1, 2),
        backgroundColor: '#C3CFFA',
        alignItems: 'center',
        justifyContent: 'center'

    },
    menuIcon: {
        display: 'flex',
        fontSize: theme.spacing(3.125),
        color: theme.palette.grey[500],
    },
    menuDiv: props => ({
        margin: theme.spacing(0, 0, 0, 0),
        padding: props.type === "mobile" ? 0 : theme.spacing(0.625),
        zIndex: 1000

    }),
    row: props => ({
        display: 'flex',
        flex: 1,
        alignItems: props.type === "mobile" ? "flex-start" : 'center'
    }),
    endRow: props => ({
        display: 'flex',
        // flex: props.type === "mobile" ? -1 : 1,
        justifyContent: 'flex-end',
        margin: props.type === "mobile" ? 0 : theme.spacing(0, 2, 0, 0),
        alignItems: 'center',
        alignSelf: props.type === "mobile" ? "flex-start" : "center"
    }),
    amountDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    ArrowIcon: {
        color: theme.palette.grey[300],
        fontSize: 20,
        marginRight: theme.spacing(1)
    },
    dateDiv: {
        width: theme.spacing(5.625)
    },

    sendBtnDiv: {
        '& .MuiIconButton-label': {
            flexDirection: 'column'
        }
    },
    priceRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline'
    },
    priceSpace: {
        marginLeft: theme.spacing(0.625)
    },
    cardIcon: {
        fontSize: theme.spacing(2.5)
    },
    receivedIcon: {
        fontSize: theme.spacing(2.5),
        transform: 'rotate(180deg)'
    }
}));

function LedgerRow(props = {}) {
    const classes = useStyles(props);
    const { data, from, type, onDetailPressAction = () => null, isExternalAcc = false } = props;

    const OptionMenuRef = createRef();
    // const theme = useTheme();

    const onDetailsMenuHandler = (item) => {
        OptionMenuRef.current.handleClose();
        onDetailPressAction(data);
    };
    const onMenuOpen = (e) => {
        // setSelectedNote(item);
        OptionMenuRef.current.handleClick(e);
        // if (from === "Contact") {
        //     OptionMenuRef.current.handleClick(e);
        // }

    };

    const returnSing = () => {

        if (data.ledger_operation === ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT && from === "Account" && data.ledger_identification_type !== "CARD_TX") {
            return "-";
        } else if (data.ledger_operation === ACCOUNT_LEDGER.LEDGER_OPERATION.CREDIT && data.ledger_identification_type === "CARD_TX") {
            return "+";
        } else {
            return "";
        }
    };

    const optionMenuRender = () => {

        let m = [];
        const mDetails = { label: 'Details', onClick: onDetailsMenuHandler };
        m.push(mDetails);

        return m;
    };

    return (<>
        <div className={classes.wrapperDiv}>
            <div onClick={onDetailsMenuHandler} className={classes.directionRow}>
                <div className={classes.row} >
                    {type === "mobile" ? null : <div className={classes.dateDiv} >
                        <Typography variant="overline" color="grey300"><PaymentListDate date={data.created_at} /></Typography>
                    </div>}

                    <div className={classes.row} >
                        <div>
                            <div className={classes.sendDiv} >
                                <IconButton className={classes.sendBtnDiv}>
                                    {
                                        isExternalAcc ?
                                            data.ledger_operation === "DEBIT" ? <ArrowRightAltIcon className={classes.cardIcon} /> : <ArrowRightAltIcon className={classes.receivedIcon} />
                                            : data.ledger_identification_type === "CARD_TX" ? <CreditCardIcon className={classes.cardIcon} /> : <SendIcon className={classes.sendIcon} />
                                    }

                                </IconButton>
                            </div>
                            {data.ledger_identification_type !== "CARD_TX" && <PaymentStatusDot status={data && (data.prs_ledger_operation === ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT ? 'FAILED' : 'SETTLED')} />}
                        </div>

                        <div className={classes.nameDiv} >
                            <Typography color="grey500" variant="subtitle2">{data.prs_from_to_display_name}</Typography>
                            {/* {type === "mobile" ? <PaymentTags tags={data.tags || []} /> : null} */}
                        </div>
                    </div>

                </div>

                <div className={classes.endRow} >
                    {/* {type === "mobile" && from === "Account" ? null :
                        <PaymentTags tags={data.tags || []} />} */}
                    <div className={classes.amountDiv} >
                        <div className={classes.priceDiv} >
                            <div className={classes.priceRow} >{returnSing()}  <StyledAmount status={data.payment_status} type="styled" amount={data.prs_amount || 0} /> <Typography variant="caption" className={classes.priceSpace} >{data.prs_amount_currency}</Typography></div>
                        </div>
                    </div>
                </div>

            </div>
            <IconButton onClick={onMenuOpen} className={classes.menuDiv} >
                <MoreVertIcon className={classes.menuIcon} />
            </IconButton>
            <OptionMenu
                ref={OptionMenuRef}
                optionMenus={optionMenuRender()}
            />
        </div>
    </>);
}
LedgerRow.defaultProps = {
    data: {},
}

LedgerRow.propTypes = {
    data: PropTypes.object,
    onDetailsPress: PropTypes.any,
    from: PropTypes.any
};

export default memo(LedgerRow);
