import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { Typography } from 'components/ui';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const OptionMenu = forwardRef((props, ref) => {
  const { optionMenus = [], menuComponent: MenuComponent } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  useImperativeHandle(ref, () => ({
    handleClick,
    handleClose,
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!optionMenus.length){
    return null;
  }
  return (
    <>
      {!ref && <Typography variant="subtitle1">
        <div>
          <IconButton aria-controls="option-menu" aria-haspopup="true" onClick={handleClick} >
            <MenuComponent />
          </IconButton>
        </div>
      </Typography>}
      {Boolean(anchorEl) && <Menu
        id="option-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableEnforceFocus
      >
        {optionMenus.map(i => (i.component ? i.component : <MenuItem key={i.label} onClick={i.onClick} disabled={i.disabled}><Typography>{i.label}</Typography></MenuItem>))}
      </Menu>}
    </>
  );
})
OptionMenu.defaultProps = {
  optionMenus: [],
  menuComponent: MoreHorizIcon,
}

OptionMenu.propTypes = {
  optionMenus: PropTypes.array,
  menuComponent: PropTypes.elementType,
};

// export default forwardRef(OptionMenu);
export default OptionMenu;

