/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button } from 'components/ui';
import React, { useState, memo, useEffect } from 'react';
import { Grid } from '@material-ui/core/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FormattedMessage } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPrimarySourceOfFundingListAction } from 'store/Reference/referenceActions';
import { getPrimarySourceOfFundingListState } from 'store/Reference/referenceSelectors';
import IndustryListSkeleton from '../Skeleton/IndustryListSkeleton';
import RegisterSquare from 'components/RegisterSquare';
import { saveBusinessSourceOfFundingAction, tempSaveRegistrationDataAction } from 'store/Register/registerActions';
import { getCustomerDetailsState, saveBusinessSourceOfFundingState, tempRegistrationDataState } from 'store/Register/registerSelectors';
import { Loader } from 'components';
import GoBack from '../GoBack';
import { TextField } from 'components/ui';
import { useIntl } from 'react-intl';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#fff',
    },
    content: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'inherit'
    },
    subContent: {
        display: 'grid'
    },
    marginMain: {
        margin: theme.spacing(4, 0),
    },
    margin: {
        margin: theme.spacing(2, 0),
        width: '100%'
    },
    align: {
        display: 'flex',
        alignItems: 'center'
    },
    marginAuto: {
        margin: 'auto'
    },

    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    title: {
        margin: theme.spacing(1)
    },
    backIcon: {
        padding: 0,
        marginRight: theme.spacing(8)
    },
    textFieldMar: {
        margin: theme.spacing(2, 0, 3, 0)
    },
    errorTxt: {
        margin: theme.spacing(0, 0, 2, 0),
        // textAlign: 'left'
    },
    btnDiv: {
        flex: 1
    },
    lastMainGrid: {
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
        }
    },
    dummy: {
        backgroundColor: 'white',
        borderRadius: '8px',
        height: theme.spacing(7),
        // padding: theme.spacing(2, 0),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

function BusinessSourceOfFunds(props = {}) {
    const { onContinue = () => null, onBackPress = () => null, tempRegistrationDataState, tempSaveRegistrationData, getPrimarySourceOfFundingList, getCustomerDetailsState, primarySourceOfFundingList, saveBusinessSourceOfFundingState, saveBusinessSourceOfFundingAction } = props;
    const classes = useStyles();
    const [source, setSource] = useState([]);
    const theme = useTheme();
    const [error, setError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isOtherSelect, setIsOtherSelect] = useState(false);
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const isLoading = ((saveBusinessSourceOfFundingState && saveBusinessSourceOfFundingState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading));
    const [otherTxt, setOtherTxt] = useState("");
    const mdUpMatches = useMediaQuery(theme.breakpoints.up('md'));
    const intlStr = useIntl();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
        if (!primarySourceOfFundingList.data.length) {
            getPrimarySourceOfFundingList();
        }
        analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_FUND_SOURCE_PAGE_LOAD.event })

    }, []);

    useEffect(() => {
        if (source.length > 0) {

            const tempArray = [];
            source.forEach((item) => {
                const localSave = {};
                // eslint-disable-next-line eqeqeq
                if (item == 1) {
                    localSave.entity_fund_source = otherTxt;
                }
                localSave.source_of_fund_id = item;
                tempArray.push(localSave);
            });

            tempSaveRegistrationData({
                prs_business_source_of_funds_data: tempArray,
            });
        }


    }, [source, otherTxt]);

    useEffect(() => {

        if (customerDetail && customerDetail.prs_business_source_of_funds_data && customerDetail.prs_business_source_of_funds_data.source_of_funds && customerDetail.prs_business_source_of_funds_data.source_of_funds.length > 0) {
            let idArray = [];
            customerDetail.prs_business_source_of_funds_data.source_of_funds.forEach((item) => {
                // eslint-disable-next-line eqeqeq
                if (item.source_of_fund_id == 1) {
                    setOtherTxt(item.entity_fund_source);
                    setIsOtherSelect(true);
                }
                idArray.push(item.source_of_fund_id);
            });

            setSource(idArray);
        }
        if ((tempRegistrationDataState && tempRegistrationDataState.prs_business_source_of_funds_data && tempRegistrationDataState.prs_business_source_of_funds_data.length > 0)) {
            let idArray = [];
            tempRegistrationDataState.prs_business_source_of_funds_data.forEach((item) => {
                // eslint-disable-next-line eqeqeq
                if (item.source_of_fund_id == 1) {
                    setOtherTxt(item.entity_fund_source);
                    setIsOtherSelect(true);
                }
                idArray.push(item.source_of_fund_id);
            });
            setSource(idArray);
        }
    }, [customerDetail.prs_business_source_of_funds_data]);


    const pushItem = (e) => {
        setIsDirty(true);
        // eslint-disable-next-line eqeqeq
        if (e == 1) {
            if (source.includes(1)) {
                setIsOtherSelect(false);
            } else {
                setIsOtherSelect(true);
            }

        }
        setError(false);
        if (source.includes(e)) {
            const temp = [...source];
            temp.splice(temp.indexOf(e), 1);

            setSource(temp);
        } else {
            setSource([...source, e]);
        }

    };

    const checkItem = (e) => {
        if (source.includes(e)) {
            return true;
        } else {
            return false;
        }
    };
    const onSuccess = (arg = "onBoarding") => {
        onContinue(arg);
    };
    const onSubmit = () => {

        // if (!source.every(i => customerDetail.prs_business_source_of_funds_data.source_of_funds.find(j => j.source_of_fund_id === i)) && (source.includes(1) && otherTxt !== "")) {
        if (isDirty) {
            if (source.length > 0) {
                let body = [];
                source.forEach((item) => {
                    // eslint-disable-next-line eqeqeq
                    if (item == 1 && otherTxt) {
                        body.push({ "source_of_fund_id": String(item), "entity_fund_source": otherTxt });
                    } else {
                        body.push({ "source_of_fund_id": String(item) });
                    }
                });
                saveBusinessSourceOfFundingAction({
                    body: {
                        source_of_funds: body
                    },
                    cb: onSuccess
                });
            } else {
                setError(true);
            }
        } else {
            onSuccess("header");
        }
        analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_FUND_SOURCE_CONTINUE_CLICK.event })

        let sourceOfFundName = ``;

        primarySourceOfFundingList.data.forEach((primarySourceOfFund) => {
            if (source.includes(primarySourceOfFund.source_of_fund_id)) {
                sourceOfFundName = sourceOfFundName === '' ? primarySourceOfFund.source_of_fund_name : `${sourceOfFundName}, ${primarySourceOfFund.source_of_fund_name}`
            }
        })

        if (source && source.length && sourceOfFundName) {

            let trackingProperties = ANALYTICS.EVENTS.TAILOR_FUND_SOURCE_SELECTION.tracking_properties;

            let tracking_data = {};
            trackingProperties.forEach((tP) => {

                return tracking_data[tP.key] = sourceOfFundName;
            })
            analyticsTrackApi({ event: ANALYTICS.EVENTS.TAILOR_FUND_SOURCE_SELECTION.event, tracking_data })
        }
    };
    const onChange = (e, val) => {
        const { value } = e.target || {};
        setOtherTxt(value);
    };

    return (<>
        <form className={classes.root}>

            <Grid container justifyContent="center" className={classes.content}>
                {
                    isLoading && <Loader />
                }
                <Grid item xs="11" md="8" className={classes.subContent}>
                    <div>
                        <Typography variant={smDownMatches ? "h6" : "h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep8Title} /></Typography>
                        {/* <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep8Subtitle} /></Typography> */}
                    </div>
                    <Grid container justifyContent="center">
                        <Grid item md="12" className={classes.content}>
                            <Grid container justifyContent="center" spacing={2} className={classes.marginMain}>
                                {
                                    primarySourceOfFundingList && !primarySourceOfFundingList.loading && primarySourceOfFundingList.data && primarySourceOfFundingList.data.length > 0 ?
                                        primarySourceOfFundingList.data.map((item) => (
                                            <Grid item xs="12" md="6" key={item.source_of_fund_id}>
                                                <RegisterSquare subTitle={otherTxt} img={item.source_of_fund_image_url} onPressItem={() => { pushItem(item.source_of_fund_id); }} id={item.source_of_fund_id} title={item.source_of_fund_name} checkItem={checkItem} />
                                            </Grid>
                                        )) : <IndustryListSkeleton type={mdUpMatches ? "normal" : "column"} arraySize={8} />
                                }
                                {
                                    isOtherSelect && primarySourceOfFundingList.data.length > 0 &&
                                    <>
                                        {
                                            primarySourceOfFundingList.data.length % 2 === 0 && mdUpMatches &&
                                            <>
                                                <Grid key={"-100"} item xs="12" md="6" >
                                                    {
                                                        <div className={classes.dummy} />
                                                    }

                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type="text"
                                                id="otherTxt"
                                                name="otherTxt"
                                                // label={`${intlStr.formatMessage(intl.onBoardingStepOther)}`}
                                                placeholder={`${intlStr.formatMessage(intl.onBoardingStepOtherSourceFund)}`}
                                                required
                                                value={otherTxt}
                                                errorText=""
                                                onChange={onChange}
                                                customStyle={{ height: theme.spacing(7) }}
                                            />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid >
                        {
                            error && <Typography className={classes.errorTxt} variant="body2" color="error" ><FormattedMessage {...intl.onBoardingBusinessBelongError} /></Typography>
                        }
                    </Grid>

                    <Grid container className={classes.lastMainGrid}>


                        <GoBack onClick={onBackPress} isLoading={isLoading} />
                        <Grid className={classes.btnDiv} md="3">
                            <Button disabled={(source.length > 0 && source.includes(1) && !otherTxt) || isLoading || source.length < 1} className={classes.btnDiv} size="small" onClick={onSubmit}>
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep8ContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </>);
}

// export default BusinessSourceOfFunds;

const mapStateToProps = createStructuredSelector({
    primarySourceOfFundingList: getPrimarySourceOfFundingListState(),
    saveBusinessSourceOfFundingState: saveBusinessSourceOfFundingState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    tempRegistrationDataState: tempRegistrationDataState(),
});

function mapDispatchToProps(dispatch) {
    return {
        getPrimarySourceOfFundingList: (data) => dispatch(getPrimarySourceOfFundingListAction(data)),
        saveBusinessSourceOfFundingAction: (data) => dispatch(saveBusinessSourceOfFundingAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(BusinessSourceOfFunds);