
export const RESET_USER = 'User/RESET_USER';

export const GET_ENTITY_LIST = 'User/GET_ENTITY_LIST';
export const GET_ENTITY_LIST_SET_DATA = 'User/GET_ENTITY_LIST_SET_DATA';

export const SET_SELECTED_ENTITY = 'User/SET_SELECTED_ENTITY';
export const SET_SELECTED_ENTITY_SET_DATA = 'User/SET_SELECTED_ENTITY_SET_DATA';

export const UPDATE_USER_LOCAL_SETTINGS = 'User/UPDATE_USER_LOCAL_SETTINGS';
export const UPDATE_USER_LOCAL_SETTINGS_SET_DATA = 'User/UPDATE_USER_LOCAL_SETTINGS_SET_DATA';

export const VALIDATE_LINK = 'User/VALIDATE_LINK';
export const VALIDATE_LINK_SET_DATA = 'User/VALIDATE_LINK_SET_DATA';
