import React, { memo, useEffect } from 'react';
import { makeStyles, List, ListItem } from '@material-ui/core';
import { Icon, Typography } from 'components/ui';
import messages from './intl/PaymentPurposeIntl';
import { FormattedMessage } from 'react-intl';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import * as Icons from '@material-ui/icons';
import { Loader, StyledError } from 'components';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    titleTxt: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1.5),
        textAlign: 'center'
    },

    itemDiv: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
    },
    leftDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'end'
    },
    itemRoot: {

        marginTop: theme.spacing(4),
        overflowY: 'auto'
    },
    btnDiv: {
        // #need to review
        width: '80%',
        // justifyContent: 'center',
        // margin: theme.spacing('auto', 0, 3, 0),
        // margin: theme.spacing(2, 'auto'),
        margin: theme.spacing(2, 'auto'),
        // alignSelf: 'center'
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    radioIcon: {
        color: theme.palette.accent.main
    }
}));

function PaymentPurpose({ purposes, onPurposePress, getPurposes, onSubmit, selectedPurpose, setSelectedPurpose }) {

    const classes = useStyles();

    useEffect(() => {
        if (!purposes.data || !purposes.data.length) {
            getPurposes();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPressItem = (item) => {
        setSelectedPurpose(item);
        onPurposePress(item);
        onSubmit();
    };

    return (
        <>
            <Typography variant="h6" className={classes.titleTxt} ><FormattedMessage {...messages.title} /></Typography>
            <List className={classes.itemRoot} >
                {
                    Array.isArray(purposes.data) && purposes.data.map((item, index) => {
                        return (
                            <ListItem button onClick={() => { onPressItem(item) }} key={index} className={classes.itemDiv}>
                                <div className={classes.leftDiv} >
                                    {item.purpose_icon && <Icon className={classes.icon} component={Icons[item.purpose_icon] ? Icons[item.purpose_icon] : Icons["Details"]} />}<Typography>{item.purpose_name}</Typography>
                                </div>
                                <div>
                                    {selectedPurpose.purpose_code === item.purpose_code ?
                                        <RadioButtonCheckedIcon className={classes.radioIcon} fontSize="small" />
                                        :
                                        <RadioButtonUncheckedIcon className={classes.radioIcon} fontSize="small" />
                                    }
                                </div>
                            </ListItem>
                        )
                    })
                }

            </List>
            {purposes.loading && <Loader /*id="dialog-content-root"*/ />}
            <StyledError data={purposes} retry={getPurposes} />
            {/* <div className={classes.btnDiv}>
                <Button
                    title="Select"
                    onClick={onSubmit}
                />
            </div> */}
        </>
    );
}
PaymentPurpose.defaultProps = {
    onPurposePress: () => { },
    getPurposes: () => { },
    onSubmit: () => { },
}
PaymentPurpose.propTypes = {
    purposes: PropTypes.any,
    onPurposePress: PropTypes.func,
    getPurposes: PropTypes.func,
    onSubmit: PropTypes.func,
    selectedPurpose: PropTypes.any,
    setSelectedPurpose: PropTypes.any

};

export default memo(PaymentPurpose);
