import { createTheme } from '@material-ui/core/styles';
import {
  sabon,
  sofiaPro,
  // sabonBold,
  // sabonBoldItalic,
  // sabonCeItalic,
  // sabonCeBoldItalic,
  // sabonItalic,
  // sabonRoman,
  // sofiaProBold,
} from 'assets/fonts';

const fontWeight = {
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemi: 600,
  fontWeightBold: 700,
};

const fonts = ['Sabon', 'Sofia Pro'];

const fontsFaceConstants = [
  sabon,
  sofiaPro,
];

function spacing(factor) {
  const width = window.innerWidth;
  let sp = 0;
  if (width <= 960 || width >= 1440) {
    if (width >= 1920) {
      sp = (8.5 * factor);
    }
    sp = (8 * factor);
  } else {
    sp = ((width / 180) * factor);
  }
  return (Number(sp.toFixed(1)));
}

const theme = createTheme({
  appHeaderHeight: `${spacing(10)}px`,
  regHeaderHeight: `${spacing(8.5)}px`,
  drawerWidth: `${spacing(28.875)}px`,
  showDrawerBreakPointUp: 'sm',
  fonts,
  fontWeight,
  spacing,
  palette: {
    // type: 'dark',
    type: 'light',
    primary: {
      light: '#757575',
      main: '#1E1E1E',
      dark: '#424242',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#A7D9C5',
      main: '#8CDFBA',
      dark: '#8CDFBA',

      50: '#E4F7EF',
      100: '#DBF5E9',
      200: '#CFF2E2',
      300: '#BEEDD8',
      400: '#A9E7CB',
      500: '#8CDFBA',
      600: '#6DC89F',
      700: '#51B688',
      800: '#479874',
      900: '#407E62',
      contrastText: '#000000',
    },

    accent: {
      light: "#409EC3",
      main: "#409EC3",
      dark: "#409EC3",
      50: '#D2E8F1',
      100: '#E9F4F8',
      200: '#AED6E6',
      300: '#94C8DD',
      400: '#70B6D2',
      500: '#409EC3',
      600: '#297FA1',
      700: '#1C6C8C',
      800: '#165974',
      900: '#114559',
      // contrastText: '#000000',
      contrastText: '#FFFFFF',
    },
    tertiary_one: {
      light: '#A7D9C5',
      main: '#A7D9C5',
      dark: '#A7D9C5',
      200: "#DAEFE7",
      800: '#45A582',
      contrastText: '#FFFFFF',
    },
    tertiary_two: {
      light: '#E7B18D',
      main: '#E7B18D',
      dark: '#E7B18D',
      200: "#F5DECF",
      700: '#BF7B4E',
      contrastText: '#FFFFFF',
    },
    tertiary_three: {
      light: '#E1CDE4',
      main: '#E1CDE4',
      dark: '#E1CDE4',
      200: "#F2EAF4",
      700: '#AC87B2',
      contrastText: '#FFFFFF',
    },
    tertiary_four: {
      light: '#BADCEA',
      main: '#BADCEA',
      dark: '#BADCEA',
      200: "#E2F0F6",
      700: "#70A9BF",
      contrastText: '#000000',
    },
    smoke: {
      light: "#f5f5f5",
      main: '#f0f0f0',
      dark: "#cfcfcf",
      contrastText: '#000000',
    },
    grey: {
      light: '#757575',
      main: '#1E1E1E',
      dark: '#424242',
      50: '#F2F3F4',
      100: '#CACACA',
      200: '#B8B8B8',
      300: '#A0A0A0',
      400: '#808080',
      500: '#565656',
      600: '#424242',
      700: '#1E1E1E',
      800: '#1A1A1A',
      900: '#161616',
      contrastText: '#FFFFFF',
    },
    solitude: "#eaebec",
    grey50: '#F2F3F4',
    grey100: '#CACACA',
    grey200: '#B8B8B8',
    grey300: '#A0A0A0',
    grey400: '#808080',
    grey500: '#565656',
    grey600: '#424242',
    grey700: '#1E1E1E',
    grey800: '#1A1A1A',
    grey900: '#161616',
    secondary800: "#479874",
    tertiary_one50: '#EAF6F1',
    tertiary_one100: '#E3F3ED',
    tertiary_one200: '#DAEFE7',
    tertiary_one300: '#CEEAED',
    tertiary_one400: '#BDE3D4',
    tertiary_one500: '#A7D9C5',
    tertiary_one600: '#86C0A9',
    tertiary_one700: '#69AC91',
    tertiary_one800: '#58947C',
    tertiary_one900: '#4D7B69',
    secondary700: '#51B688',
    successGreen: '#128916',
    accent500: "#409EC3",
    tertiary_three700: "#AC87B2",
    tertiary_two700: "#BF7B4E",
    error: {
      light: '#E32E21',
      main: '#E32E21',
      dark: '#E32E21',
      contrastText: '#FFFFFF',
    },
    warning: {
      light: '#FFC107',
      main: '#FFC107',
      dark: '#FFC107',
      contrastText: '#FFFFFF',
    },
    info: {
      light: '#2196F3',
      main: '#2196F3',
      dark: '#2196F3',
      contrastText: '#FFFFFF',
    },
    success: {
      light: '#4ADB9A',
      main: '#4ADB9A',
      dark: '#4ADB9A',
      contrastText: '#FFFFFF',
    },
    hyperlink: {
      light: '#0085FF',
      main: '#0085FF',
      dark: '#0085FF',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#fff',
      dialog: '#fafafa',
      default: '#f2f3f4',
      //default: 'white',
    }
  },
  typography: {
    htmlFontSize: spacing(2),
    fontFamily: 'Sabon "Sofia Pro"',
    fontSize: spacing(1.75),
    fontStyle: 'normal',
    fontWeightRegular: fontWeight.fontWeightRegular,
    fontWeightMedium: fontWeight.fontWeightMedium,
    fontWeightSemi: fontWeight.fontWeightSemi,
    fontWeightBold: fontWeight.fontWeightBold,
    h1: {
      fontFamily: fonts[0],
      fontWeight: fontWeight.fontWeightBold,
      fontSize: `${spacing(12)}px`,
      lineHeight: `${spacing(16.25)}px`,
      letterSpacing: `${spacing(0.1875)}px`,
    },
    h2: {
      fontFamily: fonts[0],
      fontWeight: fontWeight.fontWeightBold,
      fontSize: `${spacing(7.5)}px`,
      lineHeight: `${spacing(10.5)}px`,
      letterSpacing: `${spacing(-0.0625)}px`,
    },
    h3: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightBold,
      fontSize: `${spacing(6)}px`,
      lineHeight: `${spacing(8.375)}px`,
    },
    h4: {
      fontFamily: fonts[0],
      fontWeight: fontWeight.fontWeightBold,
      fontSize: `${spacing(5)}px`,
      lineHeight: `${spacing(7)}px`,
    },
    h5: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightBold,
      fontSize: `${spacing(4)}px`,
      lineHeight: `${spacing(4.25)}px`,
      letterSpacing: `${spacing(0.0225)}px`,
    },
    h6: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightSemi,
      fontSize: `${spacing(3)}px`,
      lineHeight: `${spacing(3.5)}px`,
      letterSpacing: `${spacing(0.01875)}px`,
    },
    titleMobile: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightSemi,
      fontSize: `${spacing(2.5)}px`,
      lineHeight: `${spacing(3)}px`,
      letterSpacing: `${spacing(0.01875)}px`,
    },
    numPad: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightRegular,
      fontSize: `${spacing(3)}px`,
      lineHeight: `${spacing(3)}px`,
    },
    smallHeader: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightSemi,
      fontSize: `${spacing(1.5)}px`,
      lineHeight: `${spacing(3)}px`,
      letterSpacing: `${spacing(0.125)}px`,
    },
    subtitle1: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightSemi,
      fontSize: `${spacing(2)}px`,
      lineHeight: `${spacing(2.6)}px`,
      letterSpacing: `${spacing(0.01875)}px`,
    },
    subtitle2: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightSemi,
      fontSize: `${spacing(1.75)}px`,
      lineHeight: `${spacing(2.275)}px`,
      letterSpacing: `${spacing(0.0125)}px`,
    },
    body1: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightRegular,
      fontSize: `${spacing(2)}px`,
      lineHeight: `${spacing(2.6)}px`,
      letterSpacing: `${spacing(0.0625)}px`,
    },
    body2: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightRegular,
      fontSize: `${spacing(1.75)}px`,
      lineHeight: `${spacing(2.275)}px`,
      letterSpacing: `${spacing(0.03125)}px`,
    },
    button: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightBold,
      fontSize: `${spacing(2)}px`,
      lineHeight: `${spacing(2)}px`,
      letterSpacing: `${spacing(0.0375)}px`,
      textTransform: 'none',
    },
    caption: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightRegular,
      fontSize: `${spacing(1.5)}px`,
      lineHeight: `${spacing(1.95)}px`,
      letterSpacing: `${spacing(0.05)}px`,
    },
    overline: {
      fontFamily: fonts[1],
      fontWeight: fontWeight.fontWeightSemi,
      fontSize: `${spacing(1.25)}px`,
      lineHeight: `${spacing(1.625)}px`,
      letterSpacing: `${spacing(0.1875)}px`,
    },
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application 💣!
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontsFaceConstants,
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#8CDFBA',
      }
    }
  },

});

export default theme;
