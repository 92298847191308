import React from "react";
import TeamMemberSignup from "containers/Verification/TeamMemberSignup";

function TeamMemberSignupPage(props = {}) {
    return (
        <>
            <TeamMemberSignup />
        </>

    )
}

export default TeamMemberSignupPage;

