import { PAYMENT } from '@truly-financial/common/consts';


export const paymentStatusParser = (r = {}) => {
    const parsedResponse = { ...r };
    const displayStatus = [PAYMENT.PAYMENT_STATUS.SETTLED, PAYMENT.PAYMENT_STATUS.PENDING, PAYMENT.PAYMENT_STATUS.CANCELLED];
    
    const d = r.data.filter(item => displayStatus.includes(item.payment_status));

    parsedResponse.data = d;
    return parsedResponse;
};

