import { ADDRESS_TYPE } from "@truly-financial/common/consts/address.consts";
// import { ENTITY_PERSON_STATUS, ENTITY_PERSON_TYPE } from "@truly-financial/common/consts/entity-person.consts";
import moment from "moment";
import { removeNullKeys } from "utils/common";
import { ONBOARDING_STEPS } from '@truly-financial/common/consts/entity-person.consts';
const {
    SIGNUP,
    ACCEPT_INVITE,
    // ACCEPT_CONTROLLING_PARTY_CONSENT,
    ACCEPT_CONFIDENTIAL_AGREEMENT,
    ID_VERIFICATION,
    PERSONAL_DETAILS,
    PRIMARY_ADDRESS,
    COMMUNICATION_ADDRESS,
} = ONBOARDING_STEPS;

export const ID_VERIFICATION_STATUS_MAP = {
    PASS: 'PASSED',
    PASS_PROCESSED: 'PASSED',
    FAIL: 'FAILED',
    FAIL_PROCESSED: 'FAILED',
    PENDING_NEW: 'PENDING',
    PENDING_PROCESSED: 'SUBMITTED',
    PENDING_RETRY: "RETRY",
};

export const getPersonalDetailsParser = (r = {}) => {
    const parsedResponse = { ...r };
    const data = r.data || {};
    var completedSteps = data.completed_onboarding_steps;
    const currentStep = getCurrentStepFromRequiredAndCompletedStep(data.required_onboarding_steps, data.completed_onboarding_steps);
    const prs_address_communication = (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.COMMUNICATION)) || {};
    const prs_compliance_status = data?.id_verification_check?.compliance_check_status === 'PENDING' ? `${data.id_verification_check.compliance_check_status}_${data.id_verification_check.event_status}` :  data?.id_verification_check?.compliance_check_status;
    parsedResponse.data = {
        ...data,
        currentStepFromRequiredAndCompletedStep: currentStep,
        primary_address_same_as_berbix: data.primary_address_same_as_berbix,
        primaryPersonCurrentStep: getPrimaryPersonCurrentStep(completedSteps),
        teamMemberSignupStep: getTeamMemberSignupStep(completedSteps),
        verificationStep: currentStep,
        prs_address_residential: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.PRIMARY)) || {},
        entity_person_date_of_birth: data.entity_person_date_of_birth ? moment(data.entity_person_date_of_birth).format("YYYY-MM-DD") : '',
        prs_address_communication,
        prs_address_billing: (Array.isArray(data.addresses) && data.addresses.find(i => i.address_type === ADDRESS_TYPE.BILLING)) || {},
        entity_person_identification_value: data.entity_person_identification_value,
        completed_steps: data.completedSteps || [],
        prs_is_smartlink_user: data.smartlink_account_id ? true : false,
        prs_is_smartlink_only_user: data.role_id === 2 ? true : false,
        // prs_compliance_status: data?.id_verification_check && `${data.id_verification_check.compliance_check_status}_${data.id_verification_check.event_status}`
        prs_compliance_status,
        prs_compliance_passed_communication_address_required: (prs_compliance_status === 'PASS' || prs_compliance_status === 'PENDING_PROCESSED') && data.card_issue_queue_status === 'NEW' && !prs_address_communication.address_type ? true : false,
    };

    return parsedResponse;
};

export const getPersonalDetailsFromIdVerificationParser = (r = {}) => {
    const parsedResponse = { ...r };
    const data = r.data || {};
    const addressData = data?.addresses[0] || {};
    parsedResponse.data = {
        ...data,
        "addresses": [
            addressData
        ],
        prs_address: addressData,
        prs_is_address_correct: (addressData.postal_code && addressData.country_code && addressData.state_code) ? true : false
    }

    return parsedResponse;
};

export const updatePersonalDetailsParser = (data = {}) => {
    const { personId, token, cb, body, birbixData = {} } = data;
    if (!body) {
        return {
            personId,
            body: {},
            cb: cb || (() => null),
        }
    }
    let { mailingAddress = {}, residentialAddress = {} } = body;
    if (body.primary_address_same_as_berbix) {
        residentialAddress = {};
    } else {
        residentialAddress = removeNullKeys({
            address_id: residentialAddress.address_id || null,
            // address_type: residentialAddress.address_id ? null : ADDRESS_TYPE.PRIMARY,
            address_type: ADDRESS_TYPE.PRIMARY,
            address_line_1: residentialAddress.address_line_1 || null,
            address_line_2: residentialAddress.address_line_2 || null,
            landmark: residentialAddress.landmark,
            city: residentialAddress.city,
            state_code: residentialAddress.state_code,
            state_name: residentialAddress.state_name,
            postal_code: residentialAddress.postal_code,
            country_code: residentialAddress.country_code,
            address_verification_ref_type: 'DOCUMENT',
            address_verification_ref_id: residentialAddress.address_verification_ref_id,
        });
    }

    mailingAddress = removeNullKeys({
        address_id: mailingAddress.address_id || null,
        // address_type: mailingAddress.address_id ? null : ADDRESS_TYPE.COMMUNICATION,
        address_type: ADDRESS_TYPE.COMMUNICATION,
        address_line_1: mailingAddress.address_line_1 || null,
        address_line_2: mailingAddress.address_line_2 || null,
        landmark: mailingAddress.landmark || null,
        city: mailingAddress.city || null,
        state_code: mailingAddress.state_code || null,
        state_name: mailingAddress.state_name || null,
        postal_code: mailingAddress.postal_code || null,
        country_code: mailingAddress.country_code || null,
    });

    const primary_address_same_as_berbix = [0, "0", ""].includes(body.primary_address_same_as_berbix) ? 0 : (body.primary_address_same_as_berbix || null);
    const communication_address_same_as_primary = [0, "0", ""].includes(body.communication_address_same_as_primary) ? 0 : (body.communication_address_same_as_primary || null);
    
    let addresses = [];
    if (Object.keys(mailingAddress).length > 2 && !communication_address_same_as_primary) {
        addresses.push(mailingAddress);
    }
    if (Object.keys(residentialAddress).length > 2 && !primary_address_same_as_berbix) {
        addresses.push(residentialAddress);
    }
    if (!addresses.length) {
        addresses = null;
    }
    const entityPersonCitizenshipCountry = birbixData.entity_person_citizenship_country || body.entity_person_citizenship_country || null;
    const entityPersonCountryOfResidence = body.entity_person_country_of_residence || residentialAddress.country_code || birbixData?.prs_address?.country_code || null;
    let parsedData = {
        personId,
        token,
        cb: cb || (() => null),
        body: removeNullKeys({
            // entity_person_first_name: birbixData.entity_person_first_name || body.entity_person_first_name || null,
            // entity_person_middle_name: birbixData.entity_person_middle_name || body.entity_person_middle_name || null,
            // entity_person_last_name: birbixData.entity_person_last_name || body.entity_person_last_name || null,
            // entity_person_mobile_country_code: body.entity_person_mobile_country_code || null,
            // entity_person_mobile: body.entity_person_mobile || null,
            // contact_person_position: body.contact_person_position || null,

            entity_person_date_of_birth: birbixData.entity_person_date_of_birth || body.entity_person_date_of_birth ? moment(birbixData.entity_person_date_of_birth || body.entity_person_date_of_birth).format("YYYY-MM-DD") : null,
            entity_person_gender: birbixData.entity_person_gender || body.entity_person_gender || null,
            entity_person_citizenship_country: entityPersonCitizenshipCountry,

            document_country_of_issuance: birbixData.document_country_of_issuance || null,
            document_expiry_date: birbixData.document_expiry_date || null,
            document_id_number: birbixData.document_id_number || null,
            document_type_id: birbixData.document_type_id || null,

            entity_person_tax_id_type: body.entity_person_tax_id_value ? ([entityPersonCitizenshipCountry, entityPersonCountryOfResidence].includes('US') ? 'SSN' : 'FOREIGN') : null,
            entity_person_tax_id_value: body.entity_person_tax_id_value || null,

            // entity_person_identification_type: body.entity_person_tax_id_value ? 'SOCIAL_SECURITY_NUMBER' : null,
            // entity_person_identification_value: body.entity_person_tax_id_value || null,

            entity_person_country_of_residence: entityPersonCountryOfResidence,
            tnc_and_privacy_accepted: body.tnc_and_privacy_accepted || null,
            primary_address_same_as_berbix,
            communication_address_same_as_primary,
            addresses,
        }),
    };


    return parsedData;
};

export const getPrimaryPersonCurrentStep = (steps = []) => {
    if (!steps.includes(ID_VERIFICATION)) return ID_VERIFICATION;
    if (!steps.includes(PERSONAL_DETAILS)) return PERSONAL_DETAILS;
    if (!steps.includes(PRIMARY_ADDRESS)) return PRIMARY_ADDRESS;
    if (!steps.includes(COMMUNICATION_ADDRESS)) return COMMUNICATION_ADDRESS;
    return null;
};
export const getTeamMemberSignupStep = (steps = []) => {
    if (!steps.includes(ACCEPT_CONFIDENTIAL_AGREEMENT)) return ACCEPT_CONFIDENTIAL_AGREEMENT;
    if (!steps.includes(SIGNUP)) return SIGNUP;
    if (!steps.includes(ACCEPT_INVITE)) return ACCEPT_INVITE;
    return null;
};

export const getCurrentStepFromRequiredAndCompletedStep = (rs = [], cs = []) => {
    // rs = [
    //     // SIGNUP,
    //     // ACCEPT_INVITE,
    //     ACCEPT_CONTROLLING_PARTY_CONSENT,
    //     ACCEPT_CONFIDENTIAL_AGREEMENT,
    //     ID_VERIFICATION,
    //     PERSONAL_DETAILS,
    //     PRIMARY_ADDRESS,
    //     COMMUNICATION_ADDRESS,
    // ];
    // cs = [
    //     // SIGNUP,
    //     // ACCEPT_INVITE,
    //     ACCEPT_CONTROLLING_PARTY_CONSENT,
    //     ACCEPT_CONFIDENTIAL_AGREEMENT,
    //     // ID_VERIFICATION,
    //     // PERSONAL_DETAILS,
    //     // PRIMARY_ADDRESS,
    //     // COMMUNICATION_ADDRESS,
    // ];
    const currentStep = rs.find(i => !cs.includes(i));
    return currentStep;
};



