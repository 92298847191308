import { call, put, takeLatest } from 'redux-saga/effects';
import { request, requestWithFeedback } from 'utils/request';
import {
  // resetPaymentsAction,
  sendPaymentSetDataAction,
  sendPaymentListSetDataAction,
  preSendPaymentSetDataAction,
  getPaymentCurrenciesSetDataAction,
  cancelPaymentSetDataAction,
  getScheduledPaymentListSetDataAction,
  deleteScheduledPaymentSetDataAction,
  sendSchedulePaymentNowSetDataAction,
  updateRecurringPaymentSetDataAction,
  deleteDraftPaymentSetDataAction,
  getCardPaymentListSetDataAction,
  getCardPaymentDetailSetDataAction
} from './paymentActions';
import {
  // RESET_PAYMENTS,
  SEND_PAYMENT,
  PAYMENT_LIST,
  PRE_SEND_PAYMENT,
  GET_PAYMENT_CURRENCIES,
  CANCEL_PAYMENT, GET_SCHEDULED_PAYMENT_LIST, DELETE_SCHEDULED_PAYMENT,
  SEND_SCHEDULE_PAYMENT_NOW,
  UPDATE_RECURRING_PAYMENT,
  DELETE_DRAFT_PAYMENT,
  GET_CARD_PAYMENT_LIST,
  GET_CARD_PAYMENT_DETAIL
} from './paymentConstants';

import {
  PAYMENTS_BASE_API_URL,
  PAYMENT_BASE_API_URL,
  SCHEDULED_PAYMENT_BASE_API_URL,
  CARD_MANAGEMENT_BASE_API_URL
} from 'config/apiUrls';
import { recordPerPageWeb } from 'utils/constant';


const PAYMENTS_URL = `${PAYMENTS_BASE_API_URL}/send-payments`;
const PAYMENT_URL = `${PAYMENT_BASE_API_URL}/payments`;
const SCHEDULED_PAYMENT_URL = `${SCHEDULED_PAYMENT_BASE_API_URL}/scheduled-payments`;
const CARD_MANAGEMENT_URL = `${CARD_MANAGEMENT_BASE_API_URL}/card-management`;

export default function* paymentsSaga() {
  // add all sagas here
  yield takeLatest(GET_PAYMENT_CURRENCIES, getPaymentCurrenciesSaga);
  yield takeLatest(PRE_SEND_PAYMENT, preSendPaymentSaga);
  yield takeLatest(PAYMENT_LIST, sendPaymentListSaga);
  yield takeLatest(SEND_PAYMENT, sendPaymentSaga);
  yield takeLatest(CANCEL_PAYMENT, cancelPaymentSaga);
  yield takeLatest(GET_SCHEDULED_PAYMENT_LIST, getScheduledPaymentListSaga);
  yield takeLatest(DELETE_SCHEDULED_PAYMENT, deleteScheduledPaymentSaga);
  yield takeLatest(SEND_SCHEDULE_PAYMENT_NOW, sendSchedulePaymentNowSaga);
  yield takeLatest(UPDATE_RECURRING_PAYMENT, updateRecurringPaymentSaga);
  yield takeLatest(DELETE_DRAFT_PAYMENT, deleteDraftPaymentSaga);
  yield takeLatest(GET_CARD_PAYMENT_LIST, getCardPaymentListSaga);
  yield takeLatest(GET_CARD_PAYMENT_DETAIL, getCardPaymentDetailSaga);
}

export function* sendPaymentSaga(args = {}) {
  const { body, cb = () => null, type } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(type === "ReSchedule" ? requestWithFeedback : request, requestUrl, options);
    if (result && result.data && result.data.payment_id && !result.data.is_draft) {
      cb();
    }
    // cb();
    yield put(sendPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* sendPaymentListSaga(args = {}) {
  const { endPoint = "", page = 0 } = args.data || {};

  const skip = page * recordPerPageWeb;
  const requestUrl = `${PAYMENT_URL}${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;

  try {
    const result = yield call(request, requestUrl);

    yield put(sendPaymentListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}

export function* preSendPaymentSaga(args = {}) {
  const { body } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}/pre-send-payment`;
  const options = {
    method: 'POST',
    body,
  };
  try {
    const result = yield call(request, requestUrl, options);
    yield put(preSendPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getPaymentCurrenciesSaga(args = {}) {
  const { contactId } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}/currencies?contact_id=${contactId}`;

  try {
    const result = yield call(request, requestUrl);
    yield put(getPaymentCurrenciesSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* cancelPaymentSaga(args = {}) {
  const { id, cb = () => null } = args.data || {};
  const options = {
    method: 'PUT',
  };
  const requestUrl = `${PAYMENT_URL}/${id}/cancel`;
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    result.ok && cb();
    yield put(cancelPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getScheduledPaymentListSaga(args = {}) {
  const { endPoint = "", page = 0 } = args.data || {};
  const skip = page * recordPerPageWeb;

  const requestUrl = `${PAYMENT_URL}/scheduled${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getScheduledPaymentListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteScheduledPaymentSaga(args = {}) {
  const { id } = args.data || {};
  const options = {
    method: 'DELETE',

  };
  const requestUrl = `${SCHEDULED_PAYMENT_URL}/${id}`;
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    yield put(deleteScheduledPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* sendSchedulePaymentNowSaga(args = {}) {
  const { endPoint, cb = () => null, _cbs = () => null, id } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}/${id}/${endPoint}`;
  const options = {
    method: 'POST',
  };
  try {
    const result = yield call(endPoint === "send-now" ? requestWithFeedback : request, requestUrl, options);
    if (result.data && result.data.is_amount_updated) {
      result.data && result.data.is_amount_updated && cb();
    } else {
      result.data && _cbs();
    }

    yield put(sendSchedulePaymentNowSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* updateRecurringPaymentSaga(args = {}) {
  const { id, body, cb = () => null } = args.data || {};
  const requestUrl = `${SCHEDULED_PAYMENT_URL}/${id}`;
  const options = {
    method: 'PUT',
    body
  };
  try {
    const result = yield call(request, requestUrl, options);


    // eslint-disable-next-line eqeqeq
    // result && result.data && result.data.event_schedule_id == id && cb();
    result.ok && cb();
    yield put(updateRecurringPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* deleteDraftPaymentSaga(args = {}) {
  const { id } = args.data || {};
  const requestUrl = `${PAYMENTS_URL}/${id}`;
  const options = {
    method: 'DELETE'
  };
  try {
    const result = yield call(requestWithFeedback, requestUrl, options);
    yield put(deleteDraftPaymentSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}

export function* getCardPaymentListSaga(args = {}) {
  const { endPoint = "", page = 0 } = args.data || {};
  const skip = page * recordPerPageWeb;
  const requestUrl = `${CARD_MANAGEMENT_URL}/card-transactions${endPoint ? `${endPoint}&` : '?'}sort_direction=desc&limit=${recordPerPageWeb}&skip=${skip}&page=${page}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCardPaymentListSetDataAction(result, page));
  } catch (error) {
    console.error(error);
  }
}

export function* getCardPaymentDetailSaga(args = {}) {
  const { id, } = args.data || {};
  const requestUrl = `${CARD_MANAGEMENT_URL}/card-transactions/${id}`;
  try {
    const result = yield call(request, requestUrl);
    yield put(getCardPaymentDetailSetDataAction(result));
  } catch (error) {
    console.error(error);
  }
}
