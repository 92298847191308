import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Typography } from './ui';
import { Helmet } from 'react-helmet-async';
// import useCommonStyles from 'styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1, 5),
  },
}));

function PageTitle(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { title } = props;
  return (<>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="List of truly contacts" />
    </Helmet>
    <div className={classes.root}>
      <Typography color="grey700" variant="h4">
        {title}
      </Typography>
    </div>
  </>);
}

PageTitle.defaultProps = {
  title: "",
};

PageTitle.propTypes = {
  test: PropTypes.string,
};

export default memo(PageTitle);
