/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
// import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { createStructuredSelector } from 'reselect';
import Autocomplete from 'components/ui/Autocomplete';
import { getStateListAction } from 'store/Reference/referenceActions';
import { TextField } from 'components/ui';
import { createFilterOptions } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core';

// import { getStateListState } from 'store/Reference/referenceSelectors';
const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        background: theme.palette.background.default,
        filter: `drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))`
    },
    flagDiv: {
        marginLeft: theme.spacing(.5)
    },
    autoCompleteRoot: {
        '& .MuiFormControl-root': {
            padding: `${theme.spacing(0)} !important`,
            height: '100%',
            '& .MuiAutocomplete-inputRoot': {
                height: '100%',
            }
        },

        borderTopLeftRadius: theme.spacing(0.75),
        borderBottomLeftRadius: theme.spacing(0.75),
        borderRadius: 0,
        height: '100%'

    },
    flagOption: {
        marginRight: theme.spacing(0.5)
    }
}));
function SelectState(props = {}) {
    const classes = useStyles();
    const { states, countryCode, getStates, valueObj, isValidState } = props;
    const prevCountryCode = useRef();
    const theme = useTheme();
    const [countryChangeCount, setCountryChangeCount] = useState(0);
    const [isOtherState, setIsOtherState] = useState(false);
    const [inputValue, setInputValue] = useState(valueObj.state_code === 'OTHER' ? valueObj.state_name : '');
    const ref = useRef(null);
    const [optionListWidth, setOptionListWith] = useState();
    useEffect(() => {
        setOptionListWith(ref && ref.current ? ref.current.offsetWidth : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);
    useEffect(() => {
        if (!countryCode || prevCountryCode?.current === countryCode) {
            return
        }
        setCountryChangeCount((c => c + 1));
        prevCountryCode.current = countryCode;
        countryCode && !states.data.length && getStates({ countryCode });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    useEffect(() => {
        if (countryChangeCount > 1) {
            const obj = { state_code: '', state_name: '' };
            props.onChange && props.onChange({
                target: {
                    id: props.id,
                    name: props.name,
                    value: obj?.state_code
                }
            }, obj);
        }
    }, [countryChangeCount]);

    useEffect(() => {
        if (valueObj.state_code === 'OTHER') {
            setIsOtherState(true);
            if (valueObj.state_name) {
                setInputValue(valueObj.state_name)
            } else {
                setInputValue('');
            }
        } else {
            setIsOtherState(false);
        }
    }, [valueObj])


    useEffect(() => {
        if (!isValidState && states.fetched) {
            setInputValue(valueObj.state_name || '');
            const obj = { state_code: 'OTHER', state_name: valueObj.state_name || '' };
            props.onChange && props.onChange({
                target: {
                    id: props.id,
                    name: props.name,
                    value: obj?.state_code
                }
            }, obj);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [states.fetched, isValidState]);

    const onChange = useCallback((e, val) => {
        const selectedValue = val?.state_code === 'OTHER' ? {...val, state_name: ""} : val
        setInputValue("");
        setTimeout(() => {
            props.onChange && props.onChange({
                target: {
                    id: props.id,
                    name: props.name,
                    value: selectedValue?.state_code
                }
            }, selectedValue);
        }, 0);
    }, [props.onChange]);

    const onInputChange = useCallback((e) => {
        const { value } = e.target;
        setInputValue(value);
        const obj = { state_code: 'OTHER', state_name: value };
        props.onChange && props.onChange({
            target: {
                id: props.id,
                name: props.name,
                value: obj?.state_code
            }
        }, obj);
        return
    }, [states]);

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option.state_code + option.state_name,
      });

    return (<>
    <div ref={ref} style={{ display: 'flex', flex: 1, }}>
        {/* <div style={isOtherState ? { width: theme.spacing(14.375)} : {width: '100%'}}> */}
        <div style={isOtherState ? { width: '80px'} : {width: '100%'}}>
            <Autocomplete
                {...props}
                optionListWidth={optionListWidth}
                getOptions={() => null}
                // label={!states.data.length && props.value && props.value.length <= 2 ? <>{props.label} (2 character code only)</> : props.label}
                label={isOtherState ? null : props.label}
                hiddenLabel={isOtherState}
                errorText={props.errorText}
                // error={props.error || error}
                // freeSolo={!states.data.length ? true : false}
                // freeSolo
                autoComplete
                // autoSelect
                options={states}
                onChange={onChange}
                className={classes.autoCompleteRoot}
                // noOptionsText="Type State/Province manually if it doesn't exist in the list"
                selectKey="state_code"
                labelKey="state_name"
                makeFocus={props.makeFocus}
                onBlur={props.onBlur}
                filterOptions={filterOptions}
                renderOption={(option) => {
                    if (option.state_code === 'OTHER') {
                        return (<React.Fragment>
                            {option.state_name}
                        </React.Fragment>)
                    }
                    return (
                        <React.Fragment>
                            {option.state_name} ({option.state_code})
                        </React.Fragment>
                    );
                }}
                showLoading
                customStyle={isOtherState ? {
                    // width: theme.spacing(15),
                    width: theme.spacing(11.5),
                    borderRadius: theme.spacing(0.75, 0, 0, 0.75),
                    paddingRight: `${theme.spacing(3.125)}px !important`,
                } : {}}
            />
        </div>
        {isOtherState && 
            <div style={{ flex: 3 }}>
                <TextField
                    {...props}
                    type="text"
                    errorText={props.errorText}
                    id="state_name"
                    name="state_name"
                    placeholder="Type State or Province"
                    label={props.label}
                    value={inputValue}
                    helperText={props.helperText}
                    makeFocus
                    customStyle={{
                        borderRadius: theme.spacing(0, 0.75, 0.75, 0),
                    }}
                    // CustomInputProps={{
                    //     inputComponent: TextMaskCustom,
                    // }}
                    onChange={onInputChange}
                    
                />
            </div>}
    </div>
    </>)
};

// const mapStateToProps = createStructuredSelector({
//     states: getStateListState(),
// });

const mapStateToProps = (s, p) => {
    const states = s.references.getStateListState[p.countryCode] || { data: [], loading: false, error: '' };
    // const isValidState = (states.data.length && states.data.some(i => i.state_code === p.valueObj.state_code)) || false;
    const isValidState = p.valueObj.state_code ? states.data.some(i => i.state_code === p.valueObj.state_code) : true;
    
    return { states, isValidState };
}

function mapDispatchToProps(dispatch) {
    return {
        getStates: data => dispatch(getStateListAction(data)),
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(SelectState,
    (prevProps, nextProps) => (
        (JSON.stringify(prevProps.states) === JSON.stringify(nextProps.states) &&
            prevProps.stateCode === nextProps.stateCode &&
            prevProps.countryCode === nextProps.countryCode &&
            prevProps.value === nextProps.value &&
            prevProps.valueObj === nextProps.valueObj &&
            prevProps.error === nextProps.error &&
            prevProps.required === nextProps.required &&
            prevProps.makeFocus === nextProps.makeFocus
        )
    )
);