import CompleteStepsSummary from 'containers/OnBoarding/CompleteStepsSummary';
import React from 'react';
import { Helmet } from 'react-helmet-async';


function OnBoardingSummaryPage(props = {}) {

    return (
        <>
            <Helmet >
                <title>OnBoardingSummary</title>
                <meta name="description" content="Register page" />
            </Helmet>
            <CompleteStepsSummary />
        </>
    )

}

export default OnBoardingSummaryPage;