const EXTERNAL_ACCOUNT = {
    code: "EXTERNAL_ACCOUNT",
    name: "external Account",
    path: "/external",
    functions: {
        EXTERNAL_ACCOUNT_LIST: {
            code: "EXTERNAL_ACCOUNT_LIST",
            name: "List",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_LIST.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_LIST.path}`,
        },
        EXTERNAL_ACCOUNT_DETAILS: {
            code: "EXTERNAL_ACCOUNT_DETAILS",
            name: "Detail",
            isPage: true,
            component: 'ExternalAccountDetailsPage',
            path: "/:id/details",
            getRoutePath: () => `/accounts/external${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_DETAILS.path}`,
            getFullPath: (id) =>
                `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_DETAILS.path}`.replace(
                    ":id",
                    id
                ),
        },
        EXTERNAL_ACCOUNT_CREATE: {
            code: "EXTERNAL_ACCOUNT_CREATE",
            name: "External Account Create",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_CREATE.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_CREATE.path}`,
        },
        EXTERNAL_ACCOUNT_BALANCE: {
            code: "EXTERNAL_ACCOUNT_BALANCE",
            name: "External Account Balance ",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_BALANCE.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_BALANCE.path}`,
        },
        EXTERNAL_ACCOUNT_LINK: {
            code: "EXTERNAL_ACCOUNT_LINK",
            name: "External Account Link ",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_LINK.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_LINK.path}`,
        },
        EXTERNAL_ACCOUNT_UNLINK: {
            code: "EXTERNAL_ACCOUNT_UNLINK",
            name: "External Account Unlink ",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_UNLINK.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_UNLINK.path}`,
        },
        EXTERNAL_ACCOUNT_ARCHIVE: {
            code: "EXTERNAL_ACCOUNT_ARCHIVE",
            name: "External Account Archive",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_ARCHIVE.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.EXTERNAL_ACCOUNT_ARCHIVE.path}`,
        },
        TRULY_TRANSACTIONS_LIST: {
            code: "TRULY_TRANSACTIONS_LIST",
            name: "Truly Transactions List",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.TRULY_TRANSACTIONS_LIST.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.TRULY_TRANSACTIONS_LIST.path}`,
        },
        NON_TRULY_TRANSACTIONS_LIST: {
            code: "NON_TRULY_TRANSACTIONS_LIST",
            name: "Non Truly Transactions List",
            isPage: false,
            component: '',
            path: "",
            getRoutePath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.NON_TRULY_TRANSACTIONS_LIST.path}`,
            getFullPath: () => `${EXTERNAL_ACCOUNT.path}${EXTERNAL_ACCOUNT.functions.NON_TRULY_TRANSACTIONS_LIST.path}`,
        }
    },
};

export default EXTERNAL_ACCOUNT;
