/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Divider, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import { Typography, Button, TextField } from 'components/ui';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core/';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { acceptAccountAgreementState, getCustomerDetailsState, getTermAndConditionState, reGeneratePdfState, saveTermConditionState } from 'store/Register/registerSelectors';
import { Loader, Logo } from 'components';
import successStatus from 'assets/images/successStatus.png';
import { acceptAccountAgreementAction, getCustomerDetailsAction, getTermAndConditionAction, reGeneratePdfAction, saveTermConditionAction } from 'store/Register/registerActions';
import moment from "moment";
import ArrowRightAltOutlinedIcon from '@material-ui/icons/ArrowRightAltOutlined';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { Auth } from 'aws-amplify';
import GoBack from '../GoBack';
import TermConditionDialog from '../TermConditionDialog';
import { getDirtyValues } from 'utils/common';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
import RegistrationAgreementPdf from 'components/RegistrationAgreementPdf';
import { getRemainingStep } from '../../../store/Register/registerParser';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
const ID_VERIFICATION = REGISTRATION_STEP.ID_VERIFICATION;
// import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: '#fff',
        display: 'flex',
        flex: 1,
    },
    textCenter: {
        textAlign: 'center',
        marginBottom: theme.spacing(0)
    },
    content: {
        display: 'flex',
        justifyContent: 'center',

    },
    subContent: {
        // display: 'grid',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            flexWrap: 'nowrap'
        },

    },
    mainTitle: {

    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),

    },
    pdMainView: {
        backgroundColor: theme.palette.grey50,
        borderRadius: theme.spacing(1),
        // width: "100%",
        // height: '40vh',

        margin: theme.spacing(4, 0),
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
        display: "flex",
    },
    padSubView: {
        backgroundColor: "white",
        flex: 1,
        borderRadius: theme.spacing(1, 1, 0, 1),
    },

    btnDiv: {
        flex: 1,
    },
    lastMainGrid: {
        margin: theme.spacing(4, 0, 3, 0),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            display: 'flex',
            flex: 1,
            alignItems: 'flex-end'
        },
    },
    successImg: {
        height: theme.spacing(7),
        width: theme.spacing(7)
    },
    approved: {
        margin: theme.spacing(1, 0, 1.3, 0),
        textAlign: 'center'
    },
    date: {
        margin: theme.spacing(1.2, 0, 3.2, 0),
        textAlign: 'center'
    },
    body1: {
        margin: theme.spacing(3.2, 0, 2, 0),
        textAlign: 'center'
    },
    divider: {
        width: '90%'
    },
    body2: {
        margin: theme.spacing(4, 0, 1, 0),
        textAlign: 'center'
    },
    btnLastDiv: {
        margin: theme.spacing(6.2, 0, 0, 0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    align: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0, 2)
    },
    skeleton: {
        height: '100%',
        width: 'auto',
        borderRadius: theme.spacing(1),
        margin: theme.spacing(4, 0, 0, 0)
    },
    skeletonDiv: {
        height: '80%',
        width: "80%",
        marginBottom: theme.spacing(2)
    },
    underline: {
        color: theme.palette.accent.main,
        textDecoration: 'underline',

    },
    formGroupFirst: {
        alignSelf: 'flex-start',
        // margin: theme.spacing(0, 0, 0, 2)
    },
    formGroup: {
        alignSelf: 'flex-start',

    },
    inputDiv: {
        margin: theme.spacing(2, 0, 6, 0),
        width: '100%'
    },
    ownershipCompleteCheckBox: {
        color: `${theme.palette.grey500}`,
        padding: '2px !important',
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    ownerShipComplete: {
        // alignItems: 'flex-start',
        margin: theme.spacing(2, 0, 0, 0),
        "& .MuiFormControlLabel-root": {
            // alignItems: 'flex-start'
        }
    },
    lastOwnerShip: {
        margin: theme.spacing(2, 0, 0, 0)
    }
}));

const initialState = {
    account_agreement_accepted: 0,
    account_agreement_accepted_by: null,
    account_agreement_timestamp: null,
    terms_and_conditions_accepted: 0,

    // account_agreement_pdf_url: ‘https:blah.pdf’,
};

function InformationReview(props = {}) {
    const { acceptAccountAgreement, acceptAccountAgreementState, getCustomerDetailsState, getCustomerDetails,
        onBackPress = () => null, getTermAndConditionState, getTermAndConditionAction, saveTermConditionState, saveTermConditionAction, onContinue } = props;

    const classes = useStyles();
    const isLoading = ((getCustomerDetailsState && getCustomerDetailsState.loading) || (saveTermConditionState && saveTermConditionState.loading) || (getTermAndConditionState && getTermAndConditionState.loading) || (acceptAccountAgreementState && acceptAccountAgreementState.loading));
    const customerDetailData = getCustomerDetailsState.data || {};
    const intlStr = useIntl();
    const [formData, setFormData] = useState(initialState);
    const acceptedTimeZone = moment(customerDetailData.account_agreement_timestamp).format('MMMM Do YYYY, h:mm:ss a');
    const history = useHistory();
    const [isUserLoggedIn, setIsUserLoggedIn] = React.useState('');
    const termConditionRef = useRef();
    const tncData = getTermAndConditionState.data.find(i => i.name === 'GENERAL_TNC') || {};
    const agreement = tncData.description || '';


    useEffect(() => {
        if(!getCustomerDetailsState.loading && customerDetailData?.prs_user && !customerDetailData?.isIdVerificationContinueBtnDisable) {
            let remainingStep = ID_VERIFICATION;
            onContinue(remainingStep, "header");
        } else if ((!getCustomerDetailsState.loading && customerDetailData?.prs_user && !customerDetailData.isAllowInformationStepRender)) {
            let remainingStep = getRemainingStep(customerDetailData.completed_steps);
            onContinue(remainingStep, "header");
        }

        if (getCustomerDetailsState.data) {
            setFormData({ ...formData, account_agreement_accepted: getCustomerDetailsState.data.account_agreement_accepted, terms_and_conditions_accepted: getCustomerDetailsState.data.terms_and_conditions_accepted })
        }
    }, [getCustomerDetailsState]);

    useEffect(() => {
        getLoginUser();
        if (!agreement) {
            getTermAndConditionAction();
        }
    }, []);

    const getLoginUser = () => {

        Auth.currentAuthenticatedUser().then((res) => {
            setIsUserLoggedIn(true);
        }).catch((e) => {
            setIsUserLoggedIn(false);
        });

    };
    const onCheckBoxPress = (e) => {
        const { name, checked } = e.target;
        setFormData(formData => ({ ...formData, [name]: checked }));
    };

    const onApiCall = () => {
        acceptAccountAgreement({
            body: { "signature": formData.terms_and_conditions_accepted_by },
            cb: getCustomerDetails,
        });
    };

    const onGotoTruly = () => {
        history.push(`/login`);
    };


    const onChange = (e) => {
        const { name, value } = e.target || {};
        setFormData((formData) => ({ ...formData, [name]: value }));
    };

    const onOpenDialog = () => {
        if (termConditionRef.current) {
            termConditionRef.current.onToggle && termConditionRef.current.onToggle();
        }
    };
    const onSuccessTermCondition = () => {
        onApiCall();
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const b = {
            ...formData,
            terms_and_conditions_accepted: formData.terms_and_conditions_accepted ? 1 : 0,
            terms_and_conditions_name: tncData.name,
            terms_and_conditions_version: String(tncData.version_id),
        };

        const isDirty = getDirtyValues(customerDetailData, b) || {};

        if (Object.keys(isDirty).length) {
            saveTermConditionAction({
                body: b,
                cb: onSuccessTermCondition
            });
        }

        analyticsTrackApi({ event: ANALYTICS.EVENTS.TERMS_N_CONDITION_CONTINUE_CLICK.event });
    };

    return (<>
        <form onSubmit={onSubmit} className={classes.root} >
            <Grid container justifyContent="center" className={classes.content}>
                {
                    isLoading && <Loader />
                }
                <Grid item xs="11" md="10" className={classes.subContent}>
                    {
                        customerDetailData.terms_and_conditions_accepted && customerDetailData.account_agreement_accepted && customerDetailData.entity_status === "UNDER_COMPLIANCE_REVIEW" ?
                            <>
                                <img src={successStatus} alt="success" className={classes.successImg} />

                                <Typography variant="h6" className={classes.approved}  ><FormattedMessage {...intl.onBoardingStep17ApplicationSubmit} /></Typography>
                                <Typography variant="body2" color="grey400" className={classes.date}  >{acceptedTimeZone}</Typography>
                                <Typography variant="body" color="grey500" className={classes.body1}  ><FormattedMessage {...intl.onBoardingStep17body1} /></Typography>


                                {
                                    !isUserLoggedIn && <>
                                        <Divider className={classes.divider} />
                                        <Typography variant="body" color="grey600" className={classes.body2}  ><FormattedMessage {...intl.onBoardingStep17body2} /></Typography>
                                        <Typography variant="body" color="grey600"   ><FormattedMessage {...intl.onBoardingStep17body3} /></Typography>

                                        <div className={classes.btnLastDiv} >
                                            {/* <GoBack onClick={onBackPress} isLoading={isLoading} /> */}

                                            <Button size="small"
                                                onClick={onGotoTruly}
                                                id="introBtn"
                                                color="primary"
                                                disabled={isLoading}
                                                variant="outlined"
                                                className={classes.btnDiv}
                                                innerRef={props.innerRef}
                                            >
                                                <div className={classes.align}>
                                                    <FormattedMessage {...intl.onBoardingStep17GotoBtn} />
                                                    <Logo width={68} height={24} />
                                                    <ArrowRightAltOutlinedIcon />
                                                </div>
                                            </Button>
                                        </div>
                                    </>
                                }

                            </>
                            :
                            <>
                                <div className={classes.textCenter}>
                                    <Typography className={classes.mainTitle} variant={"h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep17Title} /></Typography>
                                    <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep17Subtitle} /></Typography>
                                </div>
                                {getCustomerDetailsState.loading ?

                                    <div className={classes.skeletonDiv} >
                                        <Skeleton variant='react' className={classes.skeleton} />
                                    </div>
                                    :
                                    <>
                                        <div className={classes.pdMainView}>
                                            <div className={classes.padSubView}>
                                                <RegistrationAgreementPdf signature={formData.terms_and_conditions_accepted_by} data={customerDetailData} />
                                            </div>
                                        </div>

                                        <FormGroup className={classes.formGroupFirst}>
                                            <FormControlLabel
                                                className={classes.ownerShipComplete}
                                                name="account_agreement_accepted"
                                                onChange={(e) => { onCheckBoxPress(e); }}
                                                control={<Checkbox className={classes.ownershipCompleteCheckBox} checked={formData.account_agreement_accepted || customerDetailData.account_agreement_accepted} name={"account_agreement_accepted"} />} label={(<Typography variant="body2" color="grey400" ><FormattedMessage {...intl.accountVeriBottomTxt} /></Typography>)} />
                                        </FormGroup>

                                        <FormGroup className={classes.formGroup}>
                                            <FormControlLabel
                                                name="terms_and_conditions_accepted"
                                                className={classes.lastOwnerShip}
                                                onChange={(e) => { onCheckBoxPress(e); }}

                                                control={<Checkbox className={classes.ownershipCompleteCheckBox} checked={(formData.terms_and_conditions_accepted || customerDetailData.terms_and_conditions_accepted) ? true : false} name={"terms_and_conditions_accepted"} />} label={(<Typography variant="body2" color="grey400" ><FormattedMessage {...intl.accountVeriBottomTermConditionTxt} /> <span onClick={onOpenDialog} className={classes.underline} >Terms & Conditions</span><FormattedMessage {...intl.accountVeriBottomTermConditionTxtbreak} /> </Typography>)} />
                                        </FormGroup>
                                        <div className={classes.inputDiv}>
                                            <TextField
                                                label={`${intlStr.formatMessage(intl.onBoardingStep12SignFullName)}`}
                                                className={classes.input1}

                                                value={formData.terms_and_conditions_accepted_by}
                                                id="terms_and_conditions_accepted_by"
                                                name="terms_and_conditions_accepted_by"
                                                onChange={onChange}
                                                required
                                            // errorText="Required"
                                            />
                                        </div>
                                        <Grid container justifyContent="center" className={classes.lastMainGrid}>
                                            <GoBack onClick={onBackPress} isLoading={isLoading} />
                                            <Grid item md="3" className={classes.btnDiv}>
                                                <Button size="small"
                                                    className={classes.btnDiv}
                                                    type="submit"
                                                    color="secondary"
                                                    disabled={(!formData.account_agreement_accepted || !formData.terms_and_conditions_accepted)}
                                                >
                                                    <div className={classes.row}>
                                                        <FormattedMessage {...intl.accountAgreementAcceptButton} />
                                                    </div>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </>

                    }

                </Grid>
                <TermConditionDialog
                    ref={termConditionRef}
                    agreement={agreement}

                />
            </Grid>
        </form>
    </>);
}

const mapStateToProps = createStructuredSelector({
    getCustomerDetailsState: getCustomerDetailsState(),
    acceptAccountAgreementState: acceptAccountAgreementState(),
    reGeneratePdfState: reGeneratePdfState(),
    getTermAndConditionState: getTermAndConditionState(),
    saveTermConditionState: saveTermConditionState(),
});

function mapDispatchToProps(dispatch) {
    return {
        acceptAccountAgreement: (data) => dispatch(acceptAccountAgreementAction(data)),
        getCustomerDetails: (data) => dispatch(getCustomerDetailsAction(data)),
        reGeneratePdf: (data) => dispatch(reGeneratePdfAction(data)),
        getTermAndConditionAction: data => dispatch(getTermAndConditionAction(data)),
        saveTermConditionAction: (data) => dispatch(saveTermConditionAction(data)),
    };
}
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(InformationReview);
