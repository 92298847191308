
import { defineMessages } from 'react-intl';

export const scope = 'src.components.selectEntity';

export default defineMessages({
  switchAccount: {
    id: `${scope}.switchAccount`,
    defaultMessage: 'Switch Account',
  },
  addBusiness: {
    id: `${scope}.addBusiness`,
    defaultMessage: 'Add a Business',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log out',
  },
});
