/* eslint-disable react-hooks/exhaustive-deps */
import {
    //IconButton, InputAdornment,
    makeStyles
} from '@material-ui/core';
import { Typography, Button, TextField } from 'components/ui';
import React, { useState, memo, useEffect } from 'react';
import { Grid } from '@material-ui/core/';
import { Loader } from 'components';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { addCustomerBusinessBriefAction, addCustomerBusinessDetailAction, tempSaveRegistrationDataAction } from 'store/Register/registerActions';
import { addCustomerBusinessBriefState, addCustomerBusinessDetailState, getCustomerDetailsState, tempRegistrationDataState } from 'store/Register/registerSelectors';
import { checkIsObjectKeyEmpty, getDirtyValues, removeNullKeys } from 'utils/common';
import GoBack from '../GoBack';
import SelectJurisdiction from 'containers/Common/SelectJurisdiction';
import SelectBusinessType from 'containers/Common/SelectBusinessType';
import SelectNoOfEmployees from 'containers/Common/SelectNoOfEmployees';
import FileUploadWithProgress from 'components/FileUploadWithProgress';
import { REGISTER_BASE_API_URL } from 'config/apiUrls';
import SelectIndustry from 'containers/Common/SelectIndustry';
// import CheckIcon from '@material-ui/icons/Check';
import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
import { ANALYTICS } from '@truly-financial/common/consts';
//import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const REGISTER_URL = `${REGISTER_BASE_API_URL}/registrations`;

const useStyles = makeStyles(theme => ({
    root: {

    },
    inputDiv: {
        margin: theme.spacing(5, 0, 2.5, 0)
    },
    txtCenter: {
        textAlign: 'center',
    },

    textArea: {
        '& .MuiInputBase-root': {
            fontSize: `${theme.spacing(1.75)}px`,
            lineHeight: `${theme.spacing(2.275)}px`,
            letterSpacing: `${theme.spacing(0.03125)}px`,
        },
        '& .MuiFilledInput-multiline.MuiFilledInput-marginDense': {
            padding: theme.spacing(2),
            fontWeight: 'normal !important',
            alignItems: 'flex-start',
            height: theme.spacing(14.25)
        },
        '& .MuiFilledInput-inputMultiline': {
            padding: `${theme.spacing(0)} !important `,
        },
        // padding: theme.spacing(0, 2, 2, 2)
    },
    inputPlaceHolder: {
        // '&::placeholder': {
        //     ...theme.typography.caption
        // }
    },
    subContent: {
        display: 'grid',
    },

    margin: {
        margin: theme.spacing(0.5, 0)
    },
    textAlign: {

    },
    select: {
        width: '90px',
        marginRight: theme.spacing(2),
    },
    align: {
        display: 'flex',
        alignItems: 'center'
    },
    alignSelect: {
        display: 'flex',
        margin: theme.spacing(2, 0)
    },
    mainTitle: {

    },
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),

    },
    contentMargin: {
        margin: theme.spacing(5, 0, 0, 0)
    },
    textAriaDiv: {
        // backgroundColor: theme.palette.grey50,
        margin: theme.spacing(0.5, 0),
        borderRadius: theme.spacing(1),
        flexDirection: 'column',
        display: 'flex',
        '& .input': {
            padding: '0px !important'
        }
    },
    bottomTxt: {
        textAlign: 'left'
    },
    flexContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginTop: theme.spacing(4)
    },
    secondFlexContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },

    btnDiv: {
        flex: 1
    },
    lastMainGrid: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),
        }
    },
    textCenter: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    secondTitle: {
        margin: theme.spacing(3, 0, 3, 0),
        textAlign: 'center',
        justifyContent: 'center'
    },
    blueColor: {
        color: theme.palette.accent.main,
        margin: theme.spacing(0.5, 0, 0, 1.5)
    },
    checkbox: {
        '& .MuiIconButton-root': {
            padding: `${theme.spacing(0, 1, 0, 1.2)} !important`
        },
        margin: theme.spacing(0, 0, 4, 0)
    },
    iconColor: {
        color: `${theme.palette.grey500}`,
        "&.Mui-checked": {
            color: `${theme.palette.accent.main} !important`
        },
    },
    inputDivStructure: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        '& .makeStyles-helperText-88': {
            color: '#565656',
            position: 'relative',
            left: '-12px',
        }
    },
    lastRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1
    },
    checkIconColor: {
        color: theme.palette.successGreen,
        // fontSize: "10px"
    },
    serviceTitle: {
        textAlign: 'left',
        marginBottom: theme.spacing(0.5)
    },
    input: {
        '& .MuiInputBase-root': {
            fontSize: `${theme.spacing(1.75)}px`,
            lineHeight: `${theme.spacing(2.275)}px`,
            letterSpacing: `${theme.spacing(0.03125)}px`,
        }
    },
    infoIcon: {
        color: theme.palette.accent.main,
        padding: '0px !important'
        // '& .MuiInputBase-root': {
        //     fontSize: `${theme.spacing(1.75)}px`,
        //     lineHeight: `${theme.spacing(2.275)}px`,
        //     letterSpacing: `${theme.spacing(0.03125)}px`,
        // }

    },
    uploadDiv: {
        marginTop: theme.spacing(1)
    }
}));
const initialStructureForm = {
    entity_sub_type: "",
    no_of_employee: "",
    business_tax_id_value: "",
    entity_sub_type_document_url: "",
    entity_sub_type_document_name: "",
};

function BusinessBrief(props = {}) {
    const { onContinue = () => null, onBackPress = () => null, addCustomerBusinessBriefState,
        tempSaveRegistrationData, tempRegistrationDataState,
        // addCustomerBusinessBrief, 
        getCustomerDetailsState,
        addCustomerBusinessDetailState, addBusinessDetail } = props;
    const classes = useStyles();
    const intlStr = useIntl();
    const [structureFormData, setStructureFormData] = useState({ ...initialStructureForm });
    const [industryFormData, setIndustryFormData] = useState({});
    const [formData, setFormData] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [isCheckChar, setIsCheckChar] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [haveWebsite, setHaveWebsite] = useState(false);
    const [requiredCompDoc, setRequiredCompDoc] = useState(false);
    const [uploadDocumentName, setUploadDocumentName] = useState('');
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const isLoading = ((addCustomerBusinessDetailState && addCustomerBusinessDetailState.loading) || (addCustomerBusinessBriefState && addCustomerBusinessBriefState.loading) || (getCustomerDetailsState && getCustomerDetailsState.loading));
    const incorporationCountry = (customerDetail && customerDetail.company_details && customerDetail.company_details.incorporation_country_code) || "";

    let businessTaxIdType = 'EIN';
    let EINLabel = 'Tax Payer Id';
    let JUDLabel = 'Incorporated State';
    let EINErrorText = "";
    if (incorporationCountry === 'CA') {
        EINLabel = 'Business number issued by CRA';
        businessTaxIdType = 'EIN';
    } else if (incorporationCountry === 'US') {
        if (structureFormData.entity_sub_type === "FREELANCER") {
            EINLabel = 'Social Security Number (SSN)';
            EINErrorText = "";
            businessTaxIdType = 'SSN';
        } else if (structureFormData.entity_sub_type === "SOLE_PROPRIETORSHIP") {
            EINLabel = 'Tax Payer Id';
            businessTaxIdType = 'EIN';
        } else {
            EINLabel = 'Employer Identification Number (EIN)';
            businessTaxIdType = 'EIN';
            EINErrorText = "Employee identification number must be 9 digits";
        }
    }

    if (incorporationCountry === 'CA') {
        JUDLabel = 'Incorporated Province ';
    } else if (incorporationCountry === 'US') {
        JUDLabel = 'Incorporated State ';
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_BREIF_PAGE_LOAD.event })
    }, []);

    // structure 

    useEffect(() => {
        if (Object.keys(structureFormData).length > 0) {

            tempSaveRegistrationData({
                prs_business_structure_data: { ...structureFormData },
            });
        }
    }, [structureFormData]);


    useEffect(() => {
        if (['PARTNERSHIP', 'LIMITED_LIABILITY_PARTNERSHIP'].includes(structureFormData.entity_sub_type)) {
            setRequiredCompDoc(true);
            setUploadDocumentName("Upload Partnership Agreement");
        } else if (['SOLE_PROPRIETORSHIP'].includes(structureFormData.entity_sub_type)) {
            setRequiredCompDoc(true);
            setUploadDocumentName("Upload Business Registration");
        } else {
            setRequiredCompDoc(false);
            setUploadDocumentName("Upload Business Document");
        }

    }, [structureFormData.entity_sub_type]);

    useEffect(() => {
        let ob = { ...structureFormData, ...customerDetail.prs_business_structure_data };
        if (tempRegistrationDataState && tempRegistrationDataState.prs_business_structure_data && Object.keys(tempRegistrationDataState.prs_business_structure_data).length > 0 && checkIsObjectKeyEmpty(tempRegistrationDataState.prs_business_structure_data)) {
            ob = { ...structureFormData, ...customerDetail.prs_business_structure_data, ...tempRegistrationDataState.prs_business_structure_data };
        }
        setStructureFormData(structureFormData => ({ ...ob }));
    }, [customerDetail.prs_business_structure_data]);

    useEffect(() => {

        if ((customerDetail && customerDetail.prs_business_industries_data && customerDetail.prs_business_industries_data.industries && customerDetail.prs_business_industries_data.industries.length > 0)) {
            customerDetail.prs_business_industries_data.industries.forEach((item) => {
                setIndustryFormData(item);
            });
        }

        if ((tempRegistrationDataState && tempRegistrationDataState.prs_business_industries_data && Object.keys(tempRegistrationDataState.prs_business_industries_data).length > 0)) {
            setIndustryFormData(tempRegistrationDataState.prs_business_industries_data);
        }

    }, [customerDetail.prs_business_industries_data]);
    //industry 

    useEffect(() => {
        if (Object.keys(industryFormData).length > 0) {
            tempSaveRegistrationData({
                prs_business_industries_data: industryFormData,
            });
        }


    }, [industryFormData]);
    // business brief
    useEffect(() => {
        if (Object.keys(formData).length > 0) {
            tempSaveRegistrationData({
                prs_business_brief_data: { ...formData },
            });
        }

    }, [formData]);

    useEffect(() => {

        let ob = { ...formData, ...customerDetail.prs_business_brief_data };

        if (tempRegistrationDataState && tempRegistrationDataState.prs_business_brief_data && checkIsObjectKeyEmpty(tempRegistrationDataState.prs_business_brief_data)) {
            ob = { ...formData, ...customerDetail.prs_business_brief_data, ...tempRegistrationDataState.prs_business_brief_data };
        }

        if (customerDetail && customerDetail.completed_steps && customerDetail.completed_steps.includes('BUSINESS_BRIEF') && customerDetail.prs_business_brief_data && !customerDetail.prs_business_brief_data.website) {
            setHaveWebsite(true);
        }
        setFormData(formData => ({ ...ob }));
        // const brief = (customerDetail && customerDetail.prs_business_brief_data && customerDetail.prs_business_brief_data.business_brief) || '';
        const brief = ob.business_brief || '';
        setIsCheckChar(brief.length > 99);
    }, [customerDetail.prs_business_brief_data]);



    const onSuccess = (arg = "onBoarding") => {

        if (Object.keys(formData).length > 0) {
            tempSaveRegistrationData({
                prs_business_brief_data: { ...formData, isStepComplete: true },
            });
        }
        if (Object.keys(structureFormData).length > 0) {
            tempSaveRegistrationData({
                prs_business_structure_data: { ...structureFormData, isStepComplete: true },
            });
        }

        onContinue(arg);

    };

    const onSubmit = (e) => {
        e.preventDefault();
        const body = {};
        const bodyBrief = { ...formData };
        const bodyStructure = { ...structureFormData, business_tax_id_type: businessTaxIdType };
        // const bodyIndustry = { ...industryFormData };

        const structureOb = getDirtyValues(customerDetail.prs_business_structure_data, structureFormData);
        const briefOb = getDirtyValues(customerDetail.prs_business_brief_data, formData);
        var industryOb = false;
        if ((customerDetail.prs_business_industries_data.industries[0] && customerDetail.prs_business_industries_data.industries[0].industry_id !== industryFormData.industry_id) || (!customerDetail.prs_business_industries_data.industries[0] && industryFormData.industry_id)) {
            industryOb = industryFormData;
        }
        if (Object.keys(briefOb).length > 0) {
            body.briefBody = removeNullKeys({ ...bodyBrief });
        }
        if (Object.keys(structureOb).length > 0) {
            body.structureBody = bodyStructure;
        }

        if (Object.keys(industryOb).length > 0) {
            body.industryBody = {
                industries: [{ "industry_id": industryOb.industry_id }]
            };
        }
        if (structureFormData.entity_sub_type === "FREELANCER" || structureFormData.entity_sub_type === "SOLE_PROPRIETORSHIP") {
            body.primaryBody = (customerDetail.prs_beneficial_owners_data.entity_persons) || [];
        }

        if (Object.keys(briefOb).length > 0 || Object.keys(structureOb).length > 0 || Object.keys(industryOb).length > 0) {
            addBusinessDetail({
                body,
                cb: onSuccess
            });
        } else {
            onSuccess("header");
        }
        analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_BREIF_CONTINUE_CLICK.event })
        if (formData.business_brief) {
            let trackingProperties = ANALYTICS.EVENTS.BUSINESS_BREIF_FIELD.tracking_properties;

            let tracking_data = {};
            trackingProperties.forEach((tP) => {
                return tracking_data[tP.key] = formData.business_brief;
            })
            analyticsTrackApi({ event: ANALYTICS.EVENTS.BUSINESS_BREIF_FIELD.event, tracking_data })
        }

    };


    const onStructureChange = (e) => {
        const { name, value } = e.target || {};
        if (name === "entity_sub_type" && (value === "FREELANCER" || value === "SOLE_PROPRIETORSHIP")) {
            setStructureFormData((structureFormData) => ({ ...structureFormData, [name]: value, no_of_employee: "1-10" }));
        }
        else {
            setStructureFormData((structureFormData) => ({ ...structureFormData, [name]: value }));
        }

    };
    const onIndustryChange = (e) => {
        // eslint-disable-next-line no-unused-vars
        const { name, value } = e.target || {};

        setIndustryFormData((industryFormData) => ({ ...industryFormData, ...value }));
    };
    const onChange = (e) => {
        const { name, value } = e.target || {};
        if (name === "business_brief") {
            if (value.length > 99) {
                setIsCheckChar(true);
            } else {
                setIsCheckChar(false);
            }

        }
        setFormData((formData) => ({ ...formData, [name]: value }));
    };

    return (<>

        <form className={classes.root} onSubmit={onSubmit}>
            {
                isLoading && <Loader />
            }
            <Grid container justifyContent="center" className={classes.content}>
                <Grid item xs="11" md="7" className={classes.subContent}>
                    <Grid container justifyContent="center">
                        <Grid item xs="12" md="11" className={classes.textCenter}>

                            <Typography className={classes.mainTitle} variant={"h5"} color="grey700"><FormattedMessage {...intl.onBoardingStep2Title} /></Typography>
                            {/* <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep2SubTitle} /></Typography> */}
                            {/* <Typography variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStep2SubTitleHalf} /></Typography> */}

                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid className={classes.flexContent} item xs="12" md="9">
                            <div className={classes.inputDivStructure} >
                                <div>
                                    <SelectBusinessType
                                        id="entity_sub_type"
                                        name="entity_sub_type"
                                        value={structureFormData.entity_sub_type}
                                        onChange={onStructureChange}
                                        label={`${intlStr.formatMessage(intl.onBoardingStep9BusinessType)}`}
                                        // helperText={`${intlStr.formatMessage(intl.onBoardingStep9BusinessTypeHelperText)}`}
                                        required
                                        errorText=""
                                        countryCode={incorporationCountry}
                                    />
                                </div>

                                {requiredCompDoc && <div className={classes.uploadDiv} >
                                    <FileUploadWithProgress
                                        key="ex1"
                                        btnName={uploadDocumentName}
                                        method="POST"
                                        url={`${REGISTER_URL}/business-document`}
                                        formCustomizer={form => {
                                            // form.set('file_name', 'test.png');
                                            return form;
                                        }}
                                        beforeSend={request => {
                                            // request.setRequestHeader('x-auth-token', window.localStorage.token);
                                            return request;
                                        }}
                                        onFileError={(e) => {
                                            // const { name, value } = e.target || {};
                                        }}
                                        required
                                        // disabled={disabled || (!(data.incorporation_province === 'UNIN' || (data.business_name && data.corporate_name !== data.business_name)))}
                                        // name="company_registration_certificate"
                                        name="entity_sub_type_document_name"
                                        onChange={onStructureChange}
                                        value={structureFormData.entity_sub_type_document_name}
                                    />
                                    <Typography variant="caption" className={classes.helperTxt} color="grey500" ><FormattedMessage {...intl.onBoardingStep9BusinessTypeHelperText} /> </Typography>
                                </div>}
                            </div>
                            <div className={classes.inputDivStructure} >
                                <SelectJurisdiction
                                    id="incorporation_jurisdiction"
                                    name="incorporation_jurisdiction"
                                    value={structureFormData.incorporation_jurisdiction}
                                    onChange={onStructureChange}
                                    label={JUDLabel}
                                    // helperText={`${intlStr.formatMessage(intl.onBoardingStep9JuridictionHelperText)}`}
                                    required
                                    errorText=""
                                    countryCode={incorporationCountry}
                                    companyType={structureFormData.entity_sub_type}
                                />
                                {/* <Typography variant="caption" className={classes.helperTxt} color="grey500" ><FormattedMessage {...intl.onBoardingStep9JuridictionHelperText} /> </Typography> */}
                            </div>
                            <div className={classes.inputDivStructure} >
                                <TextField
                                    id="business_tax_id_value"
                                    name="business_tax_id_value"
                                    value={structureFormData.business_tax_id_value || ""}
                                    onChange={onStructureChange}
                                    // label={`${intlStr.formatMessage(intl.onBoardingStep9EmpIdNo)}`}
                                    label={EINLabel}

                                    // type="tel"
                                    isNumberOnly={true}
                                    InputProps={{
                                        // endAdornment: (
                                        //     <InputAdornment position="end">
                                        //         <IconButton
                                        //             className={classes.infoIcon}
                                        //             id="tax-payer-info"
                                        //         // onClick={handleClickShowPassword}

                                        //         >
                                        //             <InfoOutlinedIcon />
                                        //         </IconButton>
                                        //     </InputAdornment>
                                        // )
                                    }}
                                    required
                                    inputProps={{
                                        minlength: EINLabel === "Employer Identification Number (EIN)" ? 9 : null,
                                        maxlength: EINLabel === "Employer Identification Number (EIN)" ? 9 : null,
                                    }}
                                    errorText={EINErrorText}
                                />
                                {structureFormData.entity_sub_type === "SOLE_PROPRIETORSHIP" && incorporationCountry === 'US' && <Typography variant="caption" className={classes.helperTxt} color="grey500" ><FormattedMessage {...intl.onBoardingStep9SolePropHelperText} /> </Typography>}
                            </div>
                            {
                                (structureFormData.entity_sub_type === "FREELANCER" || structureFormData.entity_sub_type === "SOLE_PROPRIETORSHIP") ? <></> :
                                    <div className={classes.inputDivStructure} >
                                        <SelectNoOfEmployees
                                            id="no_of_employee"
                                            name="no_of_employee"
                                            value={structureFormData.no_of_employee}
                                            onChange={onStructureChange}
                                            label={`${intlStr.formatMessage(intl.onBoardingStep9NumberOfEmployees)}`}
                                            required
                                            errorText=""
                                        />
                                    </div>
                            }

                            <div className={classes.inputDivStructure} >
                                <TextField
                                    name="website"
                                    type="text"
                                    id="website"
                                    value={formData.website}
                                    label={`${intlStr.formatMessage(intl.onBoardingStep2ServiceWebsite)}`}
                                    // placeholder="Website (optional)"
                                    onChange={onChange}
                                    // disabled={haveWebsite}
                                    // required={!haveWebsite && true}
                                    // onFocus={onFocus}
                                    inputProps={{
                                        // eslint-disable-next-line no-useless-escape
                                        pattern: '((https:\/\/)|(http:\/\/)|(www\.))?[-a-zA-Z0-9@:%._\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
                                    }}
                                // errorText="Enter a website, LinkedIn, Facebook Business Page, or other relevant URL"
                                />
                                <Typography variant="caption" color="grey500" ><FormattedMessage {...intl.onBoardingStep2ServiceWebsiteHelperTxt} /></Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item xs="12" md="11" className={classes.secondTitle}>
                            <Typography className={classes.mainTitle} variant={"h6"} color="grey700"><FormattedMessage {...intl.onBoardingStep2BriefSecondTitle} /></Typography>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center">
                        <Grid className={classes.secondFlexContent} item xs="12" md="9">
                            <div className={classes.inputDivStructure} >
                                <SelectIndustry
                                    id="industry_id"
                                    name="industry_id"
                                    value={industryFormData.industry_id}
                                    onChange={onIndustryChange}
                                    label={`${intlStr.formatMessage(intl.onBoardingStep2Industry)}`}
                                    required
                                    errorText=""
                                    countryCode={incorporationCountry}
                                />

                            </div>
                            <Grid item xs="12" md="11">
                                <Typography className={classes.serviceTitle} variant={"subtitle2"} color="grey700"><FormattedMessage {...intl.onBoardingStep2ServiceTitle} /></Typography>
                            </Grid>
                            <div className={classes.textAriaDiv}>
                                <TextField
                                    type="textarea"
                                    id="business_brief"
                                    name="business_brief"
                                    value={formData.business_brief}
                                    placeholder={`${intlStr.formatMessage(intl.onBoardingStep2descriptionPlaceHolder)}`}
                                    onChange={onChange}
                                    required
                                    errorText={formData.business_brief ? <Typography className={classes.bottomTxt} variant="caption" color={"error"} ><FormattedMessage {...intl.onBoardingStep2DetailHelper} /></Typography> : " "}
                                    inputProps={{
                                        // eslint-disable-next-line no-useless-escape
                                        // pattern: '^.{50,}$',
                                        minlength: 50,
                                    }}
                                    InputProps={{

                                        classes: { input: classes.input },
                                    }}
                                    className={classes.textArea}
                                    CustomInputProps={{ classes: { input: classes.inputPlaceHolder } }}
                                    multiline
                                    // helperText={`${intlStr.formatMessage(intl.onBoardingStep2ServiceHelperText)}`}
                                    rows={4}

                                />
                            </div>

                            {/* <div className={classes.lastRow} >
                                {
                                    isCheckChar && <CheckIcon fontSize='small' className={classes.checkIconColor} />
                                }

                                <Typography className={classes.bottomTxt} variant="caption" color={isCheckChar ? "successGreen" : "grey500"} ><FormattedMessage {...intl.onBoardingStep2ServiceHelperTextElse} /></Typography>
                            </div> */}

                        </Grid>

                    </Grid>
                    <Grid container className={classes.lastMainGrid}>
                        {/* <Grid item xs="5" md="3">
                                    <Button size="small" title="Back" startIcon={<NavigateBeforeIcon />} variant="outlined" onClick={onClick} />
                                </Grid>
                                <Grid item md="6"></Grid> */}
                        <GoBack onClick={onBackPress} isLoading={isLoading} />
                        <Grid item md="3" className={classes.btnDiv}>
                            <Button
                                disabled={isLoading}
                                size="small" type="submit"
                                className={classes.btnDiv}
                            >

                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStep2ContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>

    </>);
}


const mapStateToProps = createStructuredSelector({
    addCustomerBusinessBriefState: addCustomerBusinessBriefState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    tempRegistrationDataState: tempRegistrationDataState(),
    addCustomerBusinessDetailState: addCustomerBusinessDetailState(),
});

function mapDispatchToProps(dispatch) {
    return {
        addCustomerBusinessBrief: data => dispatch(addCustomerBusinessBriefAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
        addBusinessDetail: data => dispatch(addCustomerBusinessDetailAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(BusinessBrief);
