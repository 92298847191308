
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    fixedFooter: props => props.fixed && ({
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        background: theme.palette.background.paper,
        marginRight: 'auto',
        marginLeft: 'auto',
        zIndex: 1000,
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            padding: theme.spacing(1, 1),
        }

    }),
    wrapperDiv: props => !props.fixed && ({
        position: 'relative',
    }),
}));

function DialogSubFooter(props = {}) {
    const classes = useStyles(props);
    return (<div className={classes.wrapperDiv}>
        <div className={classes.fixedFooter}>
            <div ref={props.fixed ? props.innerRef : null} >
                {props.children}
            </div>
        </div>
    </div>);
}
DialogSubFooter.defaultProps = {
    fixed: true
};

DialogSubFooter.propTypes = {
    fixed: PropTypes.bool
};
export default memo(DialogSubFooter);

