import { defineMessages } from 'react-intl';

export const scope = 'src.container.Contacts';

export default defineMessages({

  bankAddUpdateBankAccountHolderNameLabel: {
    id: `${scope}.bankAddUpdateBankAccountHolderNameLabel`,
    defaultMessage: 'Account Holder Name',
    description: 'label of account holder on add bank popup / screen',
  },
  bankAddUpdateCountryOfBankLabel: {
    id: `${scope}.bankAddUpdateCountryOfBankLabel`,
    defaultMessage: 'Country of Bank',
    description: 'label of country on add bank popup / screen',
  },
  bankAddUpdateBankNameLabel: {
    id: `${scope}.bankAddUpdateBankNameLabel`,
    defaultMessage: 'Bank Name',
    description: 'label of Bank Name on add bank popup / screen',
  },
  bankAddUpdateAccountNumberLabel: {
    id: `${scope}.bankAddUpdateAccountNumberLabel`,
    defaultMessage: 'Account Number',
    description: 'label of Account Number on add bank popup / screen',
  },
  bankAddUpdateAccountCurrencyLabel: {
    id: `${scope}.bankAddUpdateAccountCurrencyLabel`,
    defaultMessage: 'Account Currency',
    description: 'label of Account Currency on add bank popup / screen',
  },
  bankAddUpdateBankTypeLabel: {
    id: `${scope}.bankAddUpdateBankTypeLabel`,
    defaultMessage: 'Payment Method',
    description: 'label of Account Type on add bank popup / screen',
  },
  bankAddUpdateBankAddressLabel: {
    id: `${scope}.bankAddUpdateBankAddressLabel`,
    defaultMessage: 'Bank Address',
    description: 'label of Bank Address on add bank popup / screen',
  },
  bankAddUpdateSubmitButtonLabel: {
    id: `${scope}.bankAddUpdateSubmitButtonLabel`,
    defaultMessage: 'Add Account',
    description: 'label of Submit Button on add bank popup / screen',
  },
  contactAddUpdateTitle: {
    id: `${scope}.contactAddUpdateTitle`,
    defaultMessage: 'Add New Contact',
    description: 'Title of the add contact popup / screen',
  },
  contactAddUpdateIndividualButtonLabel: {
    id: `${scope}.contactAddUpdateIndividualButtonLabel`,
    defaultMessage: 'Individual',
    description: 'Individual Button Label on add update contact popup / screen',
  },
  contactAddUpdateBusinessButtonLabel: {
    id: `${scope}.contactAddUpdateBusinessButtonLabel`,
    defaultMessage: 'Business',
    description: 'Business Button Label on add update contact popup / screen',
  },
  contactAddUpdatePhotoLabel: {
    id: `${scope}.contactAddUpdatePhotoLabel`,
    defaultMessage: 'Add a contact photo',
    description: 'Contact Photo Label on add update contact popup / screen',
  },
  contactAddUpdateNoteText: {
    id: `${scope}.contactAddUpdateNoteText`,
    defaultMessage: 'Upload an image to help differentiate between contacts',
    description: 'Contact Photo Note on add update contact popup / screen',
  },
  contactAddUpdateFirstNameLabel: {
    id: `${scope}.contactAddUpdateFirstNameLabel`,
    defaultMessage: 'First Name',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateLastNameLabel: {
    id: `${scope}.contactAddUpdateLastNameLabel`,
    defaultMessage: 'Last Name',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateCountryLabel: {
    id: `${scope}.contactAddUpdateCountryLabel`,
    defaultMessage: 'Country',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateRelationshipLabel: {
    id: `${scope}.contactAddUpdateRelationshipLabel`,
    defaultMessage: 'Relationship',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateMobileCountryLabel: {
    id: `${scope}.contactAddUpdateMobileCountryLabel`,
    defaultMessage: 'Country',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateMobileLabel: {
    id: `${scope}.contactAddUpdateMobileLabel`,
    defaultMessage: 'Mobile',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateOptionalLabel: {
    id: `${scope}.contactAddUpdateOptionalLabel`,
    defaultMessage: '(Optional)',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateEmailLabel: {
    id: `${scope}.contactAddUpdateEmailLabel`,
    defaultMessage: 'Work email',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateBusinessNameLabel: {
    id: `${scope}.contactAddUpdateBusinessNameLabel`,
    defaultMessage: 'Registered Business Name',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdatePositionLabel: {
    id: `${scope}.contactAddUpdatePositionLabel`,
    defaultMessage: 'Position in Company',
    description: 'Field label on add update contact popup / screen',
  },

  contactAddUpdateAddressLine1Label: {
    id: `${scope}.contactAddUpdateAddressLine1Label`,
    defaultMessage: 'Street Address',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateAddressLine2Label: {
    id: `${scope}.contactAddUpdateAddressLine2Label`,
    defaultMessage: 'Unit/Suite/Floor (optional)',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateAddressLine2LabelOptional: {
    id: `${scope}.contactAddUpdateAddressLine2LabelOptional`,
    defaultMessage: '(optional)',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateCityLabel: {
    id: `${scope}.contactAddUpdateCityLabel`,
    defaultMessage: 'City',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateProvinceLabel: {
    id: `${scope}.contactAddUpdateProvinceLabel`,
    defaultMessage: 'State/Province',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateZipLabel: {
    id: `${scope}.contactAddUpdateZipLabel`,
    defaultMessage: 'Zip/Postal Code',
    description: 'Field label on add update contact popup / screen',
  },
  contactAddUpdateContactPersonNameLabel: {
    id: `${scope}.contactAddUpdateContactPersonNameLabel`,
    defaultMessage: 'Contact Person`s Name',
    description: 'Field label on add update contact popup / screen',
  },

  contactAddUpdateBankDetailsHeading: {
    id: `${scope}.contactAddUpdateBankDetailsHeading`,
    defaultMessage: 'Bank Details',
    description: 'bank details heading on add update contact popup / screen',
  },
  addBankButtonLabel: {
    id: `${scope}.addBankButtonLabel`,
    defaultMessage: 'Add',
    description: 'add button on add update contact popup / screen',
  },
  cancelButtonLabel: {
    id: `${scope}.cancelButtonLabel`,
    defaultMessage: 'Cancel',
    description: 'Cancel button on add update contact popup / screen',
  },
  linkContactHeading: {
    id: `${scope}.title`,
    defaultMessage: 'Add Contact',
    description: 'Link contact heading',
  },
  linkContactSubHeading: {
    id: `${scope}.linkContactSubText`,
    defaultMessage: 'Please verify with your contact that the following business $handle is correct before adding.',
    description: 'Link contact sub heading ',
  },
  contactInfoContactSinceLabel: {
    id: `${scope}.contactInfoContactSinceLabel`,
    defaultMessage: 'Contact Since',
    description: 'contact info label',
  },
  contactInfoPositionLabel: {
    id: `${scope}.contactInfoPositionLabel`,
    defaultMessage: 'Position in Company',
    description: 'contact info label',
  },
  contactInfoEmailLabel: {
    id: `${scope}.contactInfoEmailLabel`,
    defaultMessage: 'Email',
    description: 'contact info label',
  },
  contactInfoMobileLabel: {
    id: `${scope}.contactInfoMobileLabel`,
    defaultMessage: 'Mobile',
    description: 'contact info label',
  },
  contactInfoAddressLabel: {
    id: `${scope}.contactInfoAddressLabel`,
    defaultMessage: 'Address',
    description: 'contact info label',
  },
  contactInfoRelationshipLabel: {
    id: `${scope}.contactInfoRelationshipLabel`,
    defaultMessage: 'Relationship',
    description: 'contact info label',
  },

  linkContactSubmitButtonLabel: {
    id: `${scope}.linkContactSubmitButtonLabel`,
    defaultMessage: 'Add and Select',
    description: 'button label on link contact',
  },
  linkContactCancelButtonLabel: {
    id: `${scope}.linkContactCancelButtonLabel`,
    defaultMessage: 'Cancel',
    description: 'button label on link contact',
  },
  contactPreviewSendPaymentButtonLabel: {
    id: `${scope}.contactPreviewSendPaymentButtonLabel`,
    defaultMessage: 'Send a Payment',
    description: 'button label on preview contact',
  },
  contactPreviewRequiestPaymentButtonLabel: {
    id: `${scope}.contactPreviewRequiestPaymentButtonLabel`,
    defaultMessage: 'Request Payment',
    description: 'button label on preview contact',
  },
  contactPreviewExpandButtonLabel: {
    id: `${scope}.contactPreviewExpandButtonLabel`,
    defaultMessage: 'Expand',
    description: 'button label on preview contact',
  },
  addContactButtonLabel: {
    id: `${scope}.addContactButtonLabel`,
    defaultMessage: 'Add Contact',
    description: 'button which opens the add contact popup',
  },
  noContactFoundWithoutSearch: {
    id: `${scope}.noContactFoundWithoutSearch`,
    defaultMessage: 'Let’s Add Your First Contact...',
    description: 'if no contacts in the list',
  },
  noContactFoundStringPatternMatched: {
    id: `${scope}.noContactFoundStringPatternMatched`,
    defaultMessage: 'We can’t find a contact matching that email. Add them manually to send and request payments.',
    description: 'if contact found in the global truly network',
  },
  noContactFoundStringNoPatternMatched: {
    id: `${scope}.noContactFoundStringNoPatternMatched`,
    defaultMessage: 'To add a new contact, type in their $handle or email address in the search bar above.',
    description: 'no contact and no search pattern match with handle',
  },
  noContactFound: {
    id: `${scope}.noContactFound`,
    defaultMessage: 'No Matching Contacts Found',
    description: 'No Matching Contacts Found',
  },
  addBankAccountMenuLabel: {
    id: `${scope}.addBankAccountMenuLabel`,
    defaultMessage: 'Add Bank Account',
    description: 'menu item on add bank and card',
  },
  contactItemsPaymentMethodsHeading: {
    id: `${scope}.contactItemsPaymentMethodsHeading`,
    defaultMessage: 'Accounts',
    description: 'Heading on contact details page ',
  },
  addBankAccountPopupHeading: {
    id: `${scope}.addBankAccountPopupHeading`,
    defaultMessage: 'Add Bank Account',
    description: 'popup heading on add bank',
  },
  AccountDetailPopupHeading: {
    id: `${scope}.AccountDetailPopupHeading`,
    defaultMessage: 'Account Details',
    description: 'popup heading on add bank',
  },
  addCardPopupHeading: {
    id: `${scope}.addCardPopupHeading`,
    defaultMessage: 'Add Card',
    description: 'popup heading on add Card',
  },
  previewBackButtonLabel: {
    id: `${scope}.previewBackButtonLabel`,
    defaultMessage: 'Contacts',
    description: 'Back button on preview',
  },
  noContactSelectedText: {
    id: `${scope}.noContactSelectedText`,
    defaultMessage: 'No Contact Selected',
    description: 'No contact selected heading',
  },
  noContactSelectedSubText: {
    id: `${scope}.noContactSelectedSubText`,
    defaultMessage: 'Select a contact to display their information here.',
    description: 'No contact selected sub heading',
  },
  linkContactButtonLabel: {
    id: `${scope}.linkContactButtonLabel`,
    defaultMessage: 'Add to Contacts',
    description: 'link contact if on truly network',
  },

  editContactPopupTitle: {
    id: `${scope}.editContactPopupTitle`,
    defaultMessage: 'Edit Contact',
    description: 'edit contact popup title',
  },
  editContactMenuLabel: {
    id: `${scope}.editContactMenuLabel`,
    defaultMessage: 'Edit',
    description: 'edit contact submit button label',
  },
  deleteContactMenuLabel: {
    id: `${scope}.deleteContactMenuLabel`,
    defaultMessage: 'Delete',
    description: 'Delete contact submit button label',
  },
  editContactSubmitButtonLabel: {
    id: `${scope}.editContactSubmitButtonLabel`,
    defaultMessage: 'Save',
    description: 'edit contact submit button label',
  },
  myContactLabel: {
    id: `${scope}.myContactLabel`,
    defaultMessage: 'YOUR CONTACTS',
    description: 'after the search if contact found and it is already in the my contacts',
  },
  suggestedContactLabel: {
    id: `${scope}.suggestedContactLabel`,
    defaultMessage: 'SUGGESTED CONTACTS',
    description: 'after the search if contact found and if not in the my contacts',
  },
  selectContactTitle: {
    id: `${scope}.selectContactTitle`,
    defaultMessage: 'Select a Contact',
    description: 'send payment select contact popup title',
  },
  searchContactLabel: {
    id: `${scope}.searchContactLabel`,
    defaultMessage: 'Search by Name or Email',
    description: 'search field placeholder and label',
  },
  searchContactHelperText: {
    id: `${scope}.searchContactHelperText`,
    defaultMessage: 'Search contacts or add by email',
    description: 'search field help text',
  },
  searchFilterHelperText: {
    id: `${scope}.searchFilterHelperText`,
    defaultMessage: 'Search',
    description: 'search field help text',
  },
  recentContactsHeadingText: {
    id: `${scope}.recentContactsHeadingText`,
    defaultMessage: 'Recent Contacts',
    description: 'title on the payment send payment popup',
  },
  contactPageTitle: {
    id: `${scope}.contactPageTitle`,
    defaultMessage: 'Contacts',
    description: 'page Title',
  },
  infoHeading: {
    id: `${scope}.infoHeading`,
    defaultMessage: 'Add a New Contact',
    description: 'i button heading',
  },
  infoSubHeading: {
    id: `${scope}.infoSubHeading`,
    defaultMessage: 'Send and receive instant payments from other Truly users. Search by $handle or email to add an existing or new user.',
    description: 'info info popper',
  },
  errorDefaultText: {
    id: `${scope}.errorDefaultText`,
    defaultMessage: 'Required',
    description: 'default error text',
  },
  paymentsItemHeading: {
    id: `${scope}.paymentsItemHeading`,
    defaultMessage: 'Payments',
    description: 'payment heading on contact details',
  },
  recurringItemHeading: {
    id: `${scope}.recurringItemHeading`,
    defaultMessage: 'Scheduled Payments',
    description: 'recurring heading on contact details',
  },
  ReqPaymentMethodTitle: {
    id: `${scope}.ReqPaymentMethodTitle`,
    defaultMessage: 'Request Payment Method',
    description: 'title of request payment method popup'
  },
  ReqPaymentMethodSubtitle: {
    id: `${scope}.ReqPaymentMethodSubtitle`,
    defaultMessage: 'We will send an email to this contact requesting they provide their preferred method of payment. Ensure that the email below will contact them directly and click Send. ',
    description: 'title of request payment method popup'
  },
  ReqPaymentMethodEmail: {
    id: `${scope}.ReqPaymentMethodEmail`,
    defaultMessage: 'email',
    description: 'text input label for request payment method popup'
  },
  ReqPaymentMethodSendRequestBtn: {
    id: `${scope}.ReqPaymentMethodSendRequestBtn`,
    defaultMessage: 'Send Request',
    description: 'Button label for request payment method popup'
  },
  contactAccountTooltipSubTitle: {
    id: `${scope}.contactAccountTooltipSubTitle`,
    defaultMessage: 'Contact’s Accounts',
    description: 'Contact account tooltip title'
  },
  contactAccountTooltipCaption: {
    id: `${scope}.contactAccountTooltipCaption`,
    defaultMessage: 'Send and receive instant payments from other Truly users. Search by $handle or email to add or invite a contact.',
    description: 'Contact account tooltip caption'
  },
  contactItemAccountLabel: {
    id: `${scope}.contactItemAccountLabel`,
    defaultMessage: 'Add an Account',
    description: 'Contact item account label'
  },
  contactItemAccountBody: {
    id: `${scope}.contactItemAccountBody`,
    defaultMessage: 'Add an Account to Send or Request Payments from this Contact',
    description: 'Contact item account body label'
  },
  contactRequiringPaymentTableUpcoming: {
    id: `${scope}.contactRequiringPaymentTableUpcoming`,
    defaultMessage: 'Upcoming',
    description: 'Contact Requiring payment table head'
  },
  contactRequiringPaymentTablePaymentTo: {
    id: `${scope}.contactRequiringPaymentTablePaymentTo`,
    defaultMessage: 'Payment To',
    description: 'Contact Requiring payment table head'
  },
  contactRequiringPaymentTableRecurrence: {
    id: `${scope}.contactRequiringPaymentTableRecurrence`,
    defaultMessage: 'Recurrence',
    description: 'Contact Requiring payment table head'
  },
  contactRequiringPaymentTableAmount: {
    id: `${scope}.contactRequiringPaymentTableAmount`,
    defaultMessage: 'Amount',
    description: 'Contact Requiring payment table head'
  },

  contactPaymentTableDate: {
    id: `${scope}.contactPaymentTableDate`,
    defaultMessage: 'Date',
    description: 'Contact payment table head'
  },
  contactPaymentTableTag: {
    id: `${scope}.contactPaymentTableTag`,
    defaultMessage: 'Tag',
    description: 'Contact payment table head'
  },
  contactPaymentTableFrom: {
    id: `${scope}.contactPaymentTableFrom`,
    defaultMessage: 'Payment To | From',
    description: 'Contact Requiring payment table head'
  },
  contactPaymentsNoRecords: {
    id: `${scope}.contactPaymentsNoRecords`,
    defaultMessage: 'Payment to or from this contact will show up here',
    description: 'Contact payments records not found'
  },
  contactAddUpdateEntityType: {
    id: `${scope}.contactAddUpdateEntityType`,
    defaultMessage: 'Type',
    description: 'Field label on add update contact popup / screen',
  },
});
