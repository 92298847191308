import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import Logo from 'assets/images/logo/Truly_Logo_Light.png';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        width: '100%',
        height: '100vh',
        textAlign: 'center'
    },
    logoDiv: {
        width: '100%',
    },
    logo: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        textAlign: 'left',
        borderRadius: '0px 0px 10px 10px',
    },
    rootGrid: {
        backgroundColor: '#f2f3f4'
    },
    childDiv: {
        textAlign: 'left',
        padding: theme.spacing(2),
        backgroundColor: `#f2f3f4`,
        height: '100vh'

    }
}));

function PublicPageLayout(props = {}) {
    const classes = useStyles();

    return (<>
        <Paper elevation={0} className={classes.root}>
            <Grid container className={classes.rootGrid} spacing={3} justifyContent="center" alignItems="center">
                <Grid className={classes.logoDiv} item sm={12}>
                    <div className={classes.logo}>
                        <img alt="Truly" src={Logo} width="120" />
                    </div>
                </Grid>
                <Grid item sm={12} md={8} lg={6}>
                    <div className={classes.childDiv}>
                        {props.children}
                    </div>

                </Grid>
            </Grid>
        </Paper>
    </>)
};

export default PublicPageLayout;