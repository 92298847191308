import CONTACT_ACCOUNTS from "./contact-accounts";
import CONTACT_NOTES from "./contact-notes";
import PAYMENT from "./payments";
import CONTACT_PAYMENTS from "./contact-payments";
import CONTACT_SCHEDULE_PAYMENTS from "./contact-schedule-payments";
import ACCOUNT_ACCESS from "./account-access";
import CARD_PAYMENTS from "./card-payments";
import CHECKING_ACCOUNT from "./checking-account";
import SCHEDULED_PAYMENTS from "./schedule-payments";
import SMARTLINK_ACCOUNT from "./smartlink-account";
import SMARTLINK_SETTINGS from "./smartlink-settings";
import ACCOUNT_AVAILABLE_CURRENCY from "./account";
import CONTACT from "./contact";
import ACCOUNT from "./account";
import TEAM_MEMBER from "./team-member";
import DASHBOARD from "./dashboard";
import EXTERNAL_ACCOUNT from "./external-accounts";
import ACCOUNT_TRANSFER from "./account-transfer";
import EXTERNAL_ACCOUNT_PAD from "./external-account-pad";
import HOME from "./home";

export const TRULY_ACL = {
  ACCOUNT,
  CONTACT,
  CONTACT_ACCOUNTS,
  CONTACT_NOTES,
  PAYMENT,
  CONTACT_PAYMENTS,
  CONTACT_SCHEDULE_PAYMENTS,
  ACCOUNT_ACCESS,
  CARD_PAYMENTS,
  CHECKING_ACCOUNT,
  SCHEDULED_PAYMENTS,
  SMARTLINK_ACCOUNT,
  SMARTLINK_SETTINGS,
  ACCOUNT_AVAILABLE_CURRENCY,
  TEAM_MEMBER,
  DASHBOARD,
  EXTERNAL_ACCOUNT,
  ACCOUNT_TRANSFER,
  EXTERNAL_ACCOUNT_PAD,
  HOME
};