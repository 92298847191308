import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, makeStyles } from '@material-ui/core';
import { Loader, StyledDialog } from 'components';
import WarningIcon from '@material-ui/icons/Warning';
import { Button, Icon, Typography } from 'components/ui';
// import useCommonStyles from 'styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    margin: theme.spacing(0, 3),
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    alignSelf: 'center',
    marginBottom: theme.spacing(3)
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  subTitle: {
    paddingBottom: theme.spacing(3.5),
  },
  subTitleWithSpace: {
    paddingBottom: theme.spacing(5.5),
  },
  warningText: {
    paddingTop: theme.spacing(3.5),
    paddingBottom: theme.spacing(3.5),
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  btn: {
    width: "45%",
  },
  // submitBtn: {
  //   backgroundColor: theme.palette.error.main,
  //   color: theme.palette.error.contrastText,
  // }
}));

function StyledConfirmDialog(props = {}) {
  // const commonClasses = useCommonStyles(props);
  const classes = useStyles(props);
  const { triggerComponent,
    title,
    subTitle,
    warningText,
    iconComponent,
    submitButtonText,
    cancelButtonText,
    onCancel,
    onSubmit,
    submitBtnColor,
    submitBtnVariant,
    cancelBtnColor,
    cancelBtnVariant,
    open,
    currentState = {}
  } = props;
  useEffect(() => {
  }, [open]);

  const onCancelHandler = (onClose = () => null) => {
    onCancel();
    onClose();
  };

  const onSubmitHandler = (onClose) => {
    onSubmit(onClose);
  };

  const RenderBody = (props) => (<>
    <div className={classes.root}>
      <Icon fontSize="xlarge" className={classes.icon} component={iconComponent} />
      <Typography className={classes.title} color="grey700" variant="titleMobile">{title}</Typography>
      <Typography className={warningText ? classes.subTitle : classes.subTitleWithSpace} color="grey600" variant="body2">{subTitle}</Typography>
      {
        warningText && <>  <Divider />
          <Typography className={classes.warningText} color="grey600" variant="body1">{warningText}</Typography>
        </>
      }

      <div className={classes.btnDiv}>
        <div className={classes.btn}>
          <Button color={cancelBtnColor} variant={cancelBtnVariant} onClick={() => onCancelHandler(props.onClose)}>{cancelButtonText}</Button>
        </div>
        <div className={classes.btn}>
          <Button color={submitBtnColor} variant={submitBtnVariant} onClick={() => onSubmitHandler(props.onClose)}>{submitButtonText}</Button>
        </div>
      </div>
    </div>
    {
      currentState && currentState.loading && <Loader />
    }
  </>)

  return (<>
    <div>
      <StyledDialog
        open={open}
        noFullScreen
        triggerComponent={(onOpen) => triggerComponent ? triggerComponent(onOpen) : null}
        closeIcon={false}
        onClose={onCancelHandler}
        childComponent={(onClose) => <RenderBody onClose={onClose} />}
      />
    </div>
  </>);
}

StyledConfirmDialog.defaultProps = {
  title: 'Delete Contact',
  subTitle: 'Removing this contact will cancel all future scheduled or recurring payments. Any initiated payments will still be completed.',
  warningText: 'Are you sure you would like to delete this contact?',
  iconComponent: WarningIcon,
  submitButtonText: 'Yes, Do it',
  cancelButtonText: 'Cancel',
  onCancel: () => null,
  onSubmit: () => null,
  submitBtnColor: 'secondary',
  submitBtnVariant: 'contained',
  cancelBtnColor: 'primary',
  cancelBtnVariant: 'outlined',
  open: false,
};

StyledConfirmDialog.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  warningText: PropTypes.string,
  iconComponent: PropTypes.elementType,
};

export default StyledConfirmDialog;
