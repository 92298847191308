
import React, { memo, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import TakePhotoWebCame from 'components/TakePhotoWebCame';
import { IconButton, makeStyles } from '@material-ui/core';
import FileUploadProgress from 'react-fileupload-progress/lib/components/FileUploadProgress';
import { getAccessToken, getEntityId, getTempAccessToken } from 'utils/auth';
import { Typography } from 'components/ui';
import theme from 'theme';
import PublishIcon from '@material-ui/icons/Publish';
import { EllipsisLoader } from 'components';
import ImageIcon from '@material-ui/icons/Image';
import ClearIcon from '@material-ui/icons/Clear';
import Zoom from '@material-ui/core/Zoom';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import combineClx from 'utils/combineClx';
import { getUrlParamByName } from 'utils/common';

const styles = {
    progressWrapper: {
        height: '40px',
        // width: '245px',
        width: '100%',
        float: 'left',
        overflow: 'hidden',
        // backgroundColor: 'rgb(245, 245, 245)',
        // color: '#fff',
        // fontFamily: 'Avenir',
        cursor: 'pointer',
        textAlign: 'center',
        border: '1px solid #D2E8F1',
        borderRadius: '100px',
    },
    progressBar: {
        // float: 'left',
        width: '0',
        height: '100%',
        // color: '#fff',
        textAlign: 'center',
        backgroundColor: '#D2E8F1',
        // WebkitBoxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
        // boxShadow: 'inset 0 -1px 0 rgba(0,0,0,.15)',
        WebkitTransition: 'width .6s ease',
        Otransition: 'width .6s ease',
        transition: 'width .6s ease',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cancelButton: {
        marginTop: '-5px',
        WebkitAppearance: 'none',
        cursor: 'pointer',
        background: '0 0',
        border: 0,
        // float: 'left',
        fontSize: '21px',
        fontWeight: 700,
        lineHeight: 1,
        color: '#000',
        textShadow: '0 1px 0 #fff',
        filter: 'alpha(opacity=20)',
        opacity: '.2',
        padding: '8px',
    },
    centerFix: {
        paddingTop: theme.spacing(0.625)
    }
};

const useStyles = makeStyles((theme) => ({
    imgDiv: {
        height: theme.spacing(36.875),
        width: theme.spacing(39.875),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "#EFF7FA",
        borderRadius: theme.spacing(2.2),
        border: `1px solid ${theme.palette.accent.main} `,
        margin: theme.spacing(3, 0, 3, 0),
    },

    document_img: {
        height: theme.spacing(29.875),
        width: theme.spacing(35.375),

    },
    space: {
        width: theme.spacing(1),
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            height: theme.spacing(1),
        }
    },
    columnWiseSpace: {
        height: theme.spacing(1),
    },
    uploadDivColumnWise: {
        margin: theme.spacing(3, 0, 6, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {

            alignItems: 'initial',
            justifyContent: 'initial',
        }
    },
    uploadDiv: {
        margin: theme.spacing(3, 0, 6, 0),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            flexDirection: 'column',
            alignItems: 'initial',
            justifyContent: 'initial',
        }
    },
    previewDiv: {
        display: 'flex',
        margin: theme.spacing(2),
        flexDirection: 'column'
    },
    fileName: {
        color: theme.palette.accent.main
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    imgIcon: {
        color: theme.palette.accent.main,
        fontSize: theme.spacing(3),
        marginRight: theme.spacing(1.2)
    },
    rootDiv: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },

    closeIcon: {
        marginLeft: theme.spacing(1),
        padding: '0px !important'
    },
    crossIcon: {
        padding: theme.spacing(0, 0, 0, 1)
    },
    selectedFileStyle: (props) => ({
        flex: 1, display: 'flex', alignItems: 'center',
        width: props && props.props.columnWise ? '70%' : 'auto',
        '& div': {
            flex: 1,
        }

    }),
    unSelectedFileColumnWise: {
        flex: 1, width: '70%'
    },
    unSelectedFile: { flex: 1 },
    removeFlex: {
        flex: '0 !important',
    },
    iremoveFlex: {
        flex: 'none !important',
        color: theme.palette.accent.main,
    },
    fileIcon: {
        color: theme.palette.accent.main,
        marginRight: theme.spacing(1)
    },
    uploadDocTxt: {
        flex: 'auto !important',
        marginLeft: theme.spacing(1)
    }
}));

function PictureUpload(props = {}) {
    const { index, onChange = () => null, value, id, showPreview = false, previewUrl, url, name, required,
        formCustomizer = () => null, disabled, fileName, key, method, onFileError = () => null,
        documentUploadState, documentUpload, resetDocumentUpload, className = {}, columnWise = false
    } = props;

    const [takenPhoto, setTakenPhoto] = useState({});
    const [selectedFile, setSelectedFile] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles({ props });
    const inputRef = useRef(null);
    const [progressCount, setProgressCount] = useState(value && Object.keys(takenPhoto).length === 0 ? 100 : 0);
    // eslint-disable-next-line no-unused-vars
    const [errorText, setErrorText] = useState('Required');
    // eslint-disable-next-line no-unused-vars

    useEffect(() => {
        if (value && Object.keys(takenPhoto).length === 0) {
            setProgressCount(100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onInvalid = (e) => {
        e.preventDefault && e.preventDefault();
        setError(false);
        setTimeout(() => {
            setError(true);
            if (props && props.onFocusRef) {
                props.onFocusRef(props.name, props.id);
            }

        }, 300);
    };

    const onFileChange = (e, uploadHandler) => {
        setLoading(true);
        setError(false);
        const { files } = e.target || {};

        const file = (files && files[0]) || {};
        onChange({ target: {} }); // to clear the error;
        setSelectedFile(file);

        if (file.name) {
            uploadHandler(e);
            if (inputRef && inputRef.current) {
                inputRef.current.value = '';
            }
        } else {

            setProgressCount(0);
        }
    };

    const onFileChangeTakePhoto = (e, id) => {
        // eslint-disable-next-line no-unused-vars
        const { value, name, eId, response = {} } = e.target || {};
        setError(false);
        id === eId && onChange && onChange(e, index, id);
        setTakenPhoto(response);
    };

    const onCancelImg = () => {
        onFileChangeTakePhoto({ target: {} });
    };

    const onClearImg = () => {
        setProgressCount(0);
        setSelectedFile({});
        onFileChange({}, () => null);
    };

    const disabledStyle = props.disabled ? {
        backgroundColor: 'rgb(242, 242, 243)',
        border: '1px solid rgb(203, 203, 203)',
        color: '#545454',
        cursor: 'no-drop',
    } : {};
    const style = {
        // height: '28px',
        // width: '245px',
        // fontFamily: 'Avenir',
        cursor: 'pointer',
        textAlign: 'center',

    };

    return (
        <>
            {
                Object.keys(takenPhoto).length > 0 && <>
                    {
                        showPreview && loading && !previewUrl ?
                            <div className={classes.previewDiv} >
                                <EllipsisLoader />
                            </div>
                            :
                            <Zoom in={previewUrl ? true : false} >
                                {
                                    previewUrl ? <div className={classes.rootDiv} >
                                        <div className={classes.imgDiv} >
                                            <div className={classes.previewDiv} >
                                                <img alt="document" className={classes.document_img} src={previewUrl} />
                                                <div className={classes.row} >
                                                    <ImageIcon className={classes.imgIcon} />
                                                    <Typography variant="body1" className={classes.fileName} >{fileName}</Typography>
                                                </div>
                                            </div>

                                        </div>
                                        <IconButton className={classes.closeIcon} onClick={onCancelImg} >
                                            <ClearIcon />
                                        </IconButton>
                                    </div> : <div></div>
                                }
                            </Zoom>
                    }
                </>

            }

            {
                previewUrl && Object.keys(takenPhoto).length > 0 ? <></> : < div className={columnWise ? combineClx(classes.uploadDivColumnWise, className) : combineClx(classes.uploadDiv, className)} >
                    <div className={selectedFile && selectedFile.name ? classes.selectedFileStyle : columnWise ? classes.unSelectedFileColumnWise : classes.unSelectedFile} >
                        <FileUploadProgress
                            id={id}
                            style={{ flex: 1 }}
                            key={key}
                            method={method || 'POST'}
                            url={url}
                            onProgress={(e, request, progress) => {
                                setProgressCount(progress);
                            }}
                            onLoad={(e, request) => {
                                if (request.status === 200) {
                                    const resp = JSON.parse(request.response);
                                    // const documentId = resp.res && resp.res.insertId || '';
                                    const documentId = (resp.data && resp.data.document_id) || '';
                                    onChange({ target: { name, value: selectedFile.name || '', documentId, eId: id, response: resp.data } });
                                }
                            }}
                            onError={() => {
                                setProgressCount(value ? 100 : 0);
                                setError(true);
                                setErrorText(`${selectedFile.name} - Failed to upload, Try again`);
                                onFileError(`${selectedFile.name} - Failed to upload, Try again`);
                                onChange({ target: { name, value } });
                            }}
                            onAbort={() => {
                                setProgressCount(value ? 100 : 0);
                            }}
                            formRenderer={onSubmit => (
                                <div style={{ flex: 1 }} >
                                    <input
                                        id={`input_file_${id}`}
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={e => {
                                            onFileChange(e, onSubmit);
                                        }}
                                        name={name}
                                        required={!value && required}
                                        // required={required}
                                        disabled={disabled}
                                        onInvalid={onInvalid}
                                        ref={inputRef}
                                    />
                                    {



                                        (progressCount === 0) || (progressCount === 100 && !value) ? <div style={{ flex: 1 }}><div><label
                                            style={{
                                                ...style,
                                                ...{
                                                    cursor: 'pointer',
                                                    textAlign: 'center',
                                                    color: '#1e1e1e',
                                                    padding: error ? '5px' : '3px',
                                                    borderRadius: '100px',
                                                    border: error ? '1px solid #e32e21' : '2px solid #1e1e1e',
                                                    display: 'flex',
                                                    height: theme.spacing(5.5),
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                },
                                                ...disabledStyle,
                                            }}
                                            htmlFor={`input_file_${id}`}
                                        >
                                            <Typography variant="button">
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div><PublishIcon /></div>
                                                    <div className={classes.uploadDocTxt} >{"Upload a Document"}</div>
                                                </div>
                                            </Typography>
                                        </label></div>
                                            {/* {error && <div style={{ color: '#e32e21', textAlign: 'left', margin: '10px 14px 0' }}>{errorText}</div>} */}
                                        </div> : null}
                                    {(progressCount === 100 && value) ? <div style={{ flex: 1 }}><div><label
                                        style={{
                                            ...style,
                                            ...{

                                                backgroundColor: '#D2E8F1',
                                                padding: '4px',
                                                // border: '1px solid #3B99FB',
                                                borderRadius: '100px',
                                                display: 'flex',
                                                flex: 1,
                                                height: theme.spacing(5.5),
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            },
                                            ...disabledStyle,
                                        }}
                                        htmlFor={`input_file_${id}`}
                                    >
                                        <div style={
                                            selectedFile.name ? { display: 'flex', alignItems: 'center', flex: 'inherit' } :
                                                { display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 'inherit' }}>
                                            <div className={classes.removeFlex} ><Typography variant="button"><DescriptionOutlinedIcon style={{ ...styles.centerFix, color: theme.palette.accent.main, marginLeft: theme.spacing(1) }} /></Typography></div>
                                            <div className={classes.iremoveFlex}> <Typography variant="body2"><span>&nbsp;{value}</span></Typography></div>



                                        </div>

                                    </label>
                                    </div>
                                    </div>
                                        : null
                                    }
                                </div>)}

                            formGetter={() => new FormData()}

                            progressRenderer={(progress, hasError, cancelHandler) => {
                                if (hasError) {
                                    setError(true);
                                }

                                if ((!error && progress > -1)) {
                                    const barStyle = { ...styles.progressBar };
                                    barStyle.width = `${progress}%`;
                                    return (<div style={{ flex: 1 }} >
                                        {(progress > 1 && progress < 100) ? <div style={{
                                            border: '2px solid #1e1e1e',
                                            borderRadius: '100px'
                                        }}>
                                            <div className="_react_fileupload_progress_content">
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <div style={styles.progressWrapper}>
                                                        <div className="_react_fileupload_progress_bar" style={barStyle}><Typography variant="body2">Uploading...</Typography></div>
                                                    </div>
                                                    {/* <div>
                                                        <IconButton className="_react_fileupload_progress_cancel" onClick={cancelHandler} size="small">
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </div> */}
                                                </div>
                                            </div></div> : null}

                                    </div>);
                                }
                                return '';
                            }}

                            // formCustomizer={formCustomizer}
                            formCustomizer={form => {
                                form.set('file', selectedFile);
                                return formCustomizer(form);
                            }}
                            beforeSend={request => {
                                const a = getUrlParamByName('token') || getTempAccessToken() || getAccessToken();
                                const e = !getTempAccessToken() && getEntityId();
                                a && request.setRequestHeader('Authorization', a);
                                e && request.setRequestHeader('X-Entity-Id', e);
                                return request;
                            }}
                        />
                        {
                            selectedFile && selectedFile.name &&
                            <IconButton className={classes.crossIcon} onClick={onClearImg} >
                                <div style={{
                                    alignSelf: 'center',
                                    textAlign: 'right',
                                    justifySelf: 'right',
                                    flex: 1,
                                }}><CloseIcon style={{ ...styles.centerFix, paddingRight: '6px' }} /></div>

                            </IconButton>

                        }
                    </div>
                    {
                        selectedFile && selectedFile.name ? <></> :
                            <>
                                <div className={columnWise ? classes.columnWiseSpace : classes.space} />
                                <TakePhotoWebCame
                                    columnWise={columnWise}
                                    id={id}
                                    error={error}
                                    btnName={"Take a photo"}
                                    required
                                    name="field_value"
                                    onChange={(e) => { onFileChangeTakePhoto(e, id) }}
                                    documentUploadState={documentUploadState}
                                    documentUpload={documentUpload}
                                    resetDocumentUpload={resetDocumentUpload}
                                />
                            </>


                    }

                </div>

            }

        </>
    )
};


const mapStateToProps = createStructuredSelector({

});

function mapDispatchToProps(dispatch) {
    return {

    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(PictureUpload);