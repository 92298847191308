import { BANK_ACCOUNT, CARDHOLDER_CARD, CARD_SETTING, ENTITY_PERSON } from "@truly-financial/common/consts";
import { ADDRESS_TYPE } from "@truly-financial/common/consts/address.consts";
import { getFormattedAmount, removeNullKeys } from "utils/common";
import { currencySymbol } from "utils/currencySymbols";
import { mask } from "utils/mask";
import { ACCOUNT_LEDGER } from '@truly-financial/common/consts';
import { ACCOUNT_IDENTIFICATION_TYPE } from "@truly-financial/common/consts/account.consts";
import { ACCOUNT_STATUS } from "@truly-financial/common/consts/external-account.consts";
// import { ACCOUNT_IDENTIFICATION_TYPE } from "@truly-financial/common/consts/account.consts";

const accType = {
    CHECKING: "Checking Account"
};

const CHECKING_ACC_CURRENCIES = ["USD", "CAD", "EUR", "GBP"];

export const checkingAccountParser = (r = {}, countryCode = "", currenciesArray = []) => {

    const parsedResponse = { ...r };
    const checkingAcc = r.data.filter(item => CHECKING_ACC_CURRENCIES.includes(item.currency_code));
    const multiCurrencyAcc = r.data.filter(item => !CHECKING_ACC_CURRENCIES.includes(item.currency_code));

    const d = r.data.map(i => {
        const currencyObj = currenciesArray.length > 0 && currenciesArray.filter(item => item.currency_code === i.currency_code)[0];
        const listName = multiCurrencyAcc.some(item => item.account_id === i.account_id) && currencyObj && currencyObj.currency_name ? currencyObj.currency_name : `${i.currency_code} Checking`;
        const prs_acc_masked_no = i.account_number && i.account_number.length ? "*".repeat(i.account_number.length - 4) + i.account_number.substring(i.account_number.length - 4) : "";
        let lastAccDigit = i.account_number ? i.account_number.substring(i.account_number.length - 4, i.account_number.length) : "";
        return (
            {
                ...i,
                prs_acc_no_last_digit: lastAccDigit,
                prs_secure_acc_no: i.account_number && i.account_number.replace(/./g, '*'),
                prs_acc_masked_no: prs_acc_masked_no,
                prs_checkingAcc_list_name: listName,
                prs_custom_account_number: listName + " " + mask(i.account_number || i.iban),
                prs_custom_account_number_with_bal: `${listName}  ${i.account_number ? `(${i.account_number.substr(i.account_number.length - 4)})` : `(${i.iban.substr(i.iban.length - 4)})`} \n${currencySymbol(i.currency_code)} ${Number(i.clear_balance).toFixed(2)} ${i.currency_code}`
            }
        );
    });
    const c = checkingAcc.map(i => {

        const listName = `${i.currency_code} Checking`;
        const prs_acc_masked_no = i.account_number && i.account_number.length ? "*".repeat(i.account_number.length - 4) + i.account_number.substring(i.account_number.length - 4) : "";

        let lastAccDigit = i.account_number ? i.account_number.substring(i.account_number.length - 4, i.account_number.length) : "";
        return (
            {
                ...i,
                prs_secure_acc_no: i.account_number && i.account_number.replace(/./g, '*'),
                prs_acc_no_last_digit: lastAccDigit,
                prs_acc_masked_no: prs_acc_masked_no,
                prs_checkingAcc_list_name: listName + `${i.account_number ? ` (${i.account_number.substr(i.account_number.length - 4)})` : ` (${i.iban.substr(i.iban.length - 4)})`}`,
                prs_custom_account_number: i.account_type + " " + mask(i.account_number || i.iban),
                prs_custom_account_number_with_bal: `${i.account_type && accType[i.account_type]}  ${i.account_number ? `(${i.account_number.substr(i.account_number.length - 4)})` : `(${i.iban.substr(i.iban.length - 4)})`} \n${currencySymbol(i.currency_code)} ${Number(i.clear_balance).toFixed(2)} ${i.currency_code}`
            }
        );
    });
    const m = multiCurrencyAcc.map(i => {
        const currencyObj = currenciesArray.length > 0 && currenciesArray.filter(item => item.currency_code === i.currency_code)[0];
        const listName = currencyObj && currencyObj.currency_name ? currencyObj.currency_name : currencyObj.currency_code;
        const prs_acc_masked_no = i.account_number && i.account_number.length ? "*".repeat(i.account_number.length - 4) + i.account_number.substring(i.account_number.length - 4) : "";
        let lastAccDigit = i.account_number ? i.account_number.substring(i.account_number.length - 4, i.account_number.length) : "";
        return (
            {
                ...i,
                prs_secure_acc_no: i.account_number && i.account_number.replace(/./g, '*'),
                prs_acc_no_last_digit: lastAccDigit,
                prs_checkingAcc_list_name: listName + `${i.account_number ? ` (${i.account_number.substr(i.account_number.length - 4)})` : ` (${i.iban.substr(i.iban.length - 4)})`}`,
                prs_acc_masked_no: prs_acc_masked_no,
                prs_custom_account_number: i.account_type + " " + mask(i.account_number || i.iban),
                prs_custom_account_number_with_bal: `${i.account_type && accType[i.account_type]}  ${i.account_number ? `(${i.account_number.substr(i.account_number.length - 4)})` : `(${i.iban.substr(i.iban.length - 4)})`} \n${currencySymbol(i.currency_code)} ${Number(i.clear_balance).toFixed(2)} ${i.currency_code}`
            }
        );
    });

    let sortedAccounts = d.sort((a, b) => (a.prs_checkingAcc_list_name).toLowerCase() < (b.prs_checkingAcc_list_name).toLowerCase() ? -1 : 1);
    let sortedCheckingAcc = c.sort((a, b) => (a.prs_checkingAcc_list_name).toLowerCase() < (b.prs_checkingAcc_list_name).toLowerCase() ? -1 : 1);
    let sortedMultiCurrencyAcc = m.sort((a, b) => (a.prs_checkingAcc_list_name).toLowerCase() < (b.prs_checkingAcc_list_name).toLowerCase() ? -1 : 1);

    parsedResponse.data = sortedAccounts;
    parsedResponse.checkingAcc = sortedCheckingAcc;
    parsedResponse.multiCurrencyAcc = sortedMultiCurrencyAcc;
    return parsedResponse;
};

export const checkingAccountDetailParser = (r = {}, countryCode = "", currenciesArray = []) => {

    const parsedResponse = { ...r };
    const currencyObj = currenciesArray.length > 0 && currenciesArray.filter(item => item.currency_code === r.data.currency_code)[0];

    const acc_name = CHECKING_ACC_CURRENCIES.includes(r.data.currency_code) ? `${r.data.currency_code} Checking` : currencyObj?.currency_name;

    parsedResponse.data = {
        ...r.data,
        prs_acc_name: acc_name,
        prs_acc_type: CHECKING_ACC_CURRENCIES.includes(r.data.currency_code) ? "checking" : "multi-currency"
    };

    return parsedResponse;
};
export const smartLinkAccountParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r.data.map(i => {

        let parentAccountBal = (i.parent_account && i.parent_account.clear_balance) || "0";
        let smartlinkAccBal = (i.monthly_available_limit) || "0";
        return (
            {
                ...i,
                prs_linked_card_types: getCardTypes(i.linked_card_types),
                prs_insufficientFund: i.parent_account && Number(parentAccountBal) < Number(smartlinkAccBal) && i.account_status === BANK_ACCOUNT.BANK_ACCOUNT_STATUS.ACTIVE ? true : false,
            }
        );
    });

    let lockedAcc = d.filter((item) => item.account_status === BANK_ACCOUNT.BANK_ACCOUNT_STATUS.INACTIVE && item.entity_person_status === ENTITY_PERSON.ENTITY_PERSON_STATUS.ENABLED);

    let unLockedAcc = d.filter((item) => !(item.account_status === BANK_ACCOUNT.BANK_ACCOUNT_STATUS.INACTIVE && item.entity_person_status === ENTITY_PERSON.ENTITY_PERSON_STATUS.ENABLED));

    Array.isArray(unLockedAcc) && unLockedAcc.length > 0 && unLockedAcc.sort(function (a, b) {
        if ((a.account_holder_name).toLowerCase() < (b.account_holder_name).toLowerCase()) { return -1; }
        if ((a.account_holder_name).toLowerCase() > (b.account_holder_name).toLowerCase()) { return 1; }
        return 0;
    });


    parsedResponse.data = [...unLockedAcc, ...lockedAcc];
    return parsedResponse;
};

export const smartLinkAccountsCardListParser = (r = {}) => {
    const parsedResponse = { ...r };
    if (Array.isArray(r.data)) {
        const d = r && r.data && r.data.map(i => {
            let name = i.nick_name || (i.preferred_name || (`${i.entity_person_first_name || ''} ${i.entity_person_middle_name || ''} ${i.entity_person_last_name || ''}`))
            let lastAccDigit = i.masked_card_number.substring(i.masked_card_number.length - 4, i.masked_card_number.length);
            return (
                {
                    ...i,
                    cardholder_name: name.replace(/\s\s+/g, ' '),
                    prs_acc_no_last_digit: lastAccDigit,
                    prs_card_type: i.card_type === CARDHOLDER_CARD.CARD_TYPE.VIRTUAL_PHYSICAL ? CARDHOLDER_CARD.CARD_TYPE.VIRTUAL : i.card_type,
                    prs_masked_card_number: i.masked_card_number ? "**** **** **** " + lastAccDigit : "**** **** ****",
                    prs_card_locked: [CARD_SETTING.ACTIVATION_STATUS.TEMPORARY_BLOCKED].includes(i.card_status),
                    prs_card_locked_permanent: [CARD_SETTING.ACTIVATION_STATUS.PERMANENTLY_BLOCKED].includes(i.card_status) || i.card_issue_queue_status === 'CANCELLED',
                    prs_filtered_name: `${name} (${lastAccDigit})-${i.card_type}`,
                }
            );

        });
        let virtualCards = [...d.filter(item => item.card_type !== CARDHOLDER_CARD.CARD_TYPE.PHYSICAL)];
        let sortedVisualCards = virtualCards.sort((a, b) => ((a.cardholder_name).toLowerCase()) !== ((b.cardholder_name).toLowerCase()) ? ((a.cardholder_name).toLowerCase()) < ((b.cardholder_name).toLowerCase()) ? -1 : 1 : (a.prs_acc_no_last_digit) ? a.prs_acc_no_last_digit - b.prs_acc_no_last_digit : 0);
        const sortData = [...d.filter(item => item.card_type === CARDHOLDER_CARD.CARD_TYPE.PHYSICAL), ...sortedVisualCards];
        parsedResponse.data = sortData;
    }
    else {
        parsedResponse.data = {
            ...r.data,
            cardholder_name: r.data.preferred_name || (`${r.data.entity_person_first_name || ''} ${r.data.entity_person_middle_name || ''} ${r.data.entity_person_last_name || ''}`),
            prs_card_type: r.data.card_type === CARDHOLDER_CARD.CARD_TYPE.VIRTUAL_PHYSICAL ? CARDHOLDER_CARD.CARD_TYPE.VIRTUAL : r.data.card_type,
            prs_masked_card_number: r.data && r.data.masked_card_number ? "**** **** **** " + r.data.masked_card_number.substring(15, 19) : "**** **** ****"
        };
    }

    return parsedResponse;
};
export const smartLinkAccountDetailsParser = (r = {}, selectedEntity = {}) => {
    const parsedResponse = { ...r };
    let selectedEntityData = selectedEntity.data || {};
    let parent_acc = r.data && r.data.parent_account;
    let parentAccountBal = (r.data && r.data.parent_account && r.data.parent_account.clear_balance) || "0";
    let parentAccountCurrency = (r.data && r.data.parent_account && r.data.parent_account.currency_code);
    let smartlinkAccBal = (r.data && r.data.monthly_available_limit) || "0";

    parsedResponse.data = {
        ...r.data,
        prs_nick_name: r.data.nick_name || 'SmartSpend Account',
        prs_full_name: r.data.entity_person_first_name + r.data.entity_person_last_name || '',
        prs_linked_card_types: getCardTypes(r.data && r.data.linked_card_types),
        prs_account_identification_type_name: r.data.account_identification_type_name || r.data.account_identification_type,
        prs_mailing_address: getMailingAddressString(r.data.addresses),
        prs_debit_acc_str: `${(r && r.data && r.data.parent_account && ((r.data.parent_account.nick_name && r.data.parent_account.nick_name + ', ') || '')) || ''}${(r && r.data && r.data.parent_account && r.data.parent_account.account_identification_type_name) || (r && r.data && r.data.parent_account && r.data.parent_account.account_identification_type) || ''}`,
        prs_secondary_debit_acc_str: r.data.secondary_parent_account_id ? `${(r.data.secondary_parent_account.nick_name && r.data.secondary_parent_account.nick_name + ', ') || ''}${r.data.secondary_parent_account.account_identification_type_name || r.data.secondary_parent_account.account_identification_type || ''}` : '',
        prs_monthly_limit: getFormattedAmount(r.data.monthly_limit, r.data.currency_code),
        prs_entity_person_name: `${(r.data.entity_person_first_name && r.data.entity_person_first_name + ' ') || ''}${(r.data.entity_person_middle_name && r.data.entity_person_middle_name + ' ') || ''}${(r.data.entity_person_last_name && r.data.entity_person_last_name + '') || ''}`,
        prs_address_communication: (Array.isArray(r.data.addresses) && r.data.addresses.find(i => i.address_type === ADDRESS_TYPE.COMMUNICATION)) || {},
        prs_address_billing: (Array.isArray(r.data.addresses) && r.data.addresses.find(i => i.address_type === ADDRESS_TYPE.BILLING)) || {},
        prs_insufficientFund: Number(parentAccountBal) < Number(smartlinkAccBal) ? selectedEntityData?.role_id : false,
        prs_parent_acc_bal: parentAccountBal,
        prs_parent_acc_currency: parentAccountCurrency,
        prs_parent_acc_name: `(${parentAccountCurrency} Checking${parent_acc.account_number ? parent_acc.account_number.substring(parent_acc.account_number.length - 4, parent_acc.account_number.length) : ""})`
    };
    return parsedResponse;
};

function getMailingAddressString(addresses = []) {
    const m = addresses.find(i => i.address_type === 'COMMUNICATION');
    const s = (m && `${(m.address_line_1 && m.address_line_1 + ', ') || ''}${(m.address_line_2 && m.address_line_2 + ', ') || ''}${(m.city && m.city + ', ') || ''}${(m.state_name && m.state_name + ', ') || ''}${(m.postal_code && m.postal_code + ', ') || ''}${(m.country_code && m.country_code + '') || ''}`) || '';
    return s;
}

export const cardSecureDetailsParser = (r = {}) => {
    const parsedResponse = { ...r };
    parsedResponse.data = {
        ...r.data,
        card_expiry: r.data.card_expiry ? r.data.card_expiry.replaceAll(" ", '') : '',
        card_number: r.data.card_number ? r.data.card_number.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4") : '',
        cardholder_name: `${r.data.entity_person_first_name || ''} ${r.data.entity_person_middle_name || ''} ${r.data.entity_person_last_name || ''}`,
    };
    return parsedResponse;
};

function getCardTypes(linked_card_types = []) {
    const linkedCardTypes = [];
    if (Array.isArray(linked_card_types) && (linked_card_types.includes(CARDHOLDER_CARD.CARD_TYPE.PHYSICAL))) {
        linkedCardTypes.push(CARDHOLDER_CARD.CARD_TYPE.PHYSICAL);
    }
    if (Array.isArray(linked_card_types) && (linked_card_types.includes(CARDHOLDER_CARD.CARD_TYPE.VIRTUAL_PHYSICAL) || linked_card_types.includes(CARDHOLDER_CARD.CARD_TYPE.VIRTUAL))) {
        linkedCardTypes.push(CARDHOLDER_CARD.CARD_TYPE.VIRTUAL);
    }
    return linkedCardTypes;
}

export const getAccTeamMemberListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r && r.data && r.data.map(i => ({
        ...i,
        prs_display_primary_name: i.entity_person_first_name + " " + i.entity_person_last_name,
        first_name: i.entity_person_first_name,
        last_name: i.entity_person_last_name,

    }));

    parsedResponse.data = d;
    return parsedResponse;
};
export const getTeamMemberDetailParser = (r = {}) => {
    const parsedResponse = { ...r };
    parsedResponse.data = {
        ...r.data,
        prs_display_primary_name: r && r.data && r.data.entity_person_first_name ? r.data.entity_person_first_name + " " + r.data.entity_person_last_name : "",
        prs_country: r && r.data && r.data.addresses && r.data.addresses[0] ? r.data.addresses[0].country_code : "",
        prs_address: r && r.data && r.data.addresses && r.data.addresses[0] ? r.data.addresses[0].address_line_1 : ""
    };

    return parsedResponse;
};
export const getSmartLinkDetailParser = (r = {}) => {
    const parsedResponse = { ...r };

    parsedResponse.data = {
        ...r.data,

        first_name: r.data && r.data.entity_person_first_name,
        last_name: r.data && r.data.entity_person_last_name,
    };

    return parsedResponse;
};

export const updateSmartlinkAccountSettingsParser_old = (data = {}) => {
    const { accountId, cb, body = {}, } = data;
    let parsedData = {
        accountId,
        cb: cb || (() => null),
        body: removeNullKeys({
            nick_name: body.nick_name,
            monthly_limit: body.monthly_limit,
            parent_account_id: body.parent_account_id,
            account_holder_name: body.account_holder_name,
            mail_physical_card: body.mail_physical_card,
            enable_send_payment: body.enable_send_payment,
            daily_limit: body.daily_limit,
            enable_e_wallet_capability: body.enable_e_wallet_capability,
            per_transaction_limit: body.per_transaction_limit

            // secondary_parent_account_id: body.secondary_parent_account_id,
        }),
    };
    return parsedData;
};
export const getCardPaymentDetailParser = (r = {}) => {
    const parsedResponse = { ...r };
    parsedResponse.data = {
        ...r.data,
        payment_id: (r.data && r.data.card_transaction_id),
        payment_status: 'SETTLED',
        prs_payment_to: (r.data && r.data.merchant_name),
        prs_from_name: r.data && r.data.preferred_name ? r.data.preferred_name : `${r.data.entity_person_first_name} ${r.data.entity_person_last_name}`,
        prs_smartlink_acc_no: (r.data && r.data.masked_card_number),
        prs_account_name: (r.data && r.data.parent_account_type_name),
        prs_account_no: (r.data && r.data.parent_account_number),
        payment_short_ref: (r.data && (r.card_transaction_short_ref || r.data.card_transaction_id)),
    };

    return parsedResponse;
};

export const getCardPaymentListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r && r.data && r.data.map(i => ({
        ...i,
        prs_ledger_operation: i.transaction_type,
        prs_from_to_display_name: i.merchant_name,
        prs_amount: i.amount,
        prs_amount_currency: i.currency_code,
    }));

    parsedResponse.data = d;
    return parsedResponse;
};
export const getSmartLinkCardPaymentListParser = (r = {}) => {
    const parsedResponse = { ...r };

    const d = r && r.data && r.data.map(i => ({
        ...i,
        prs_ledger_operation: i.ledger_operation,
        prs_from_to_display_name: i.from_to_display_name,
        prs_amount: i.transaction_amount,
        prs_amount_currency: i.transaction_currency_code,
    }));

    parsedResponse.data = d;
    return parsedResponse;
};


export const updateSmartlinkAccountSettingsParser = (data = {}) => {
    const { accountId, teamMemberId, cb, body = {}, rename } = data;
    let { mailingAddress = {}, billingAddress = {} } = body;
    mailingAddress = removeNullKeys({
        address_id: mailingAddress.address_id || null,
        address_type: mailingAddress.address_id ? null : ADDRESS_TYPE.COMMUNICATION,
        address_line_1: mailingAddress.address_line_1 || null,
        address_line_2: mailingAddress.address_line_2 || null,
        landmark: mailingAddress.landmark || null,
        city: mailingAddress.city || null,
        state_code: mailingAddress.state_code || null,
        state_name: mailingAddress.state_name || null,
        postal_code: mailingAddress.postal_code || null,
        country_code: mailingAddress.country_code || null,
    });
    billingAddress = removeNullKeys({
        address_id: billingAddress.address_id || null,
        address_type: billingAddress.address_id ? null : ADDRESS_TYPE.BILLING,
        address_line_1: billingAddress.address_line_1 || null,
        address_line_2: billingAddress.address_line_2 || null,
        landmark: mailingAddress.landmark || null,
        city: billingAddress.city || null,
        state_code: billingAddress.state_code || null,
        state_name: billingAddress.state_name || null,
        postal_code: billingAddress.postal_code || null,
        country_code: billingAddress.country_code || null,
    });
    let addresses = [];
    if (Object.keys(mailingAddress).length > 1) {
        addresses.push(mailingAddress);
    }
    if (Object.keys(billingAddress).length > 1) {
        addresses.push(billingAddress);
    }
    if (!addresses.length) {
        addresses = null;
    }
    let parsedData = {
        accountId,
        teamMemberId,
        cb: cb || (() => null),
        rename,
        body: removeNullKeys({
            nick_name: body.nick_name,
            parent_account_id: body.parent_account_id || null,
            mail_physical_card: body.mail_physical_card,
            monthly_limit: body.monthly_limit || null,
            daily_limit: body.daily_limit || null,
            per_transaction_limit: body.per_transaction_limit || null,
            secondary_parent_account_id: body.secondary_parent_account_id || null,
            addresses,
        }),
    };

    return parsedData;
};

export const addExternalAccount = (data = {}) => {
    const { cb, body = {}, id } = data;
    let parsedData = {
        cb: cb || (() => null),
        body: removeNullKeys({
            partner_code: 'FLINKS',
            partner_account_id: body.accountId,
            institution: body.institution,
            login_id: body.loginId,
            partner_request_id: body.requestId,
            step: body.step,
            url: body.url,
        }),
        id
    };
    return parsedData;
};

export const externalAccountListParser = (r = {}) => {
    const parsedResponse = { ...r };
    const d = r && r.data && r.data.map(i => ({
        ...i,
        prs_list_name: i.bank_name + ` ${i.account_number ? `(${i.account_number.substr(i.account_number.length - 4)})` : ""}`,
        prs_masked_account_number: i.account_number ? "**** **** **** " + i.account_number.substring(i.account_number.length - 4) : "",
        prs_account_identification_type: "EXTERNAL_ACCOUNT",
        prs_custom_account_number: i.bank_name + " " + mask(i.account_number),
        prs_custom_account_number_with_bal: `${i.bank_name && i.bank_name}  ${i.account_number ? `(${i.account_number.substr(i.account_number.length - 4)})` : ""} \n${currencySymbol(i.currency_code)} ${Number(i.clear_balance).toFixed(2)} ${i.currency_code}`
    }));

    parsedResponse.data = d;
    return parsedResponse;
};
export const externalAccBankTransitionParser = (r = {}) => {


    const parsedResponse = { ...r };
    const d = r.data.map(i => ({
        ...i,
        prs_created_at: i.Date,
        prs_description: i.Description,
        prs_ledger_operation: i.Credit ? ACCOUNT_LEDGER.LEDGER_OPERATION.CREDIT : ACCOUNT_LEDGER.LEDGER_OPERATION.DEBIT,
        prs_amount: i.Credit ? i.Credit : i.Debit,
    }));
    parsedResponse.data = d;

    return parsedResponse;
};
export const preQuickTransferParser = (r = {}) => {
    const parsedResponse = { ...r };
    if (r.data.payee_bank_account && r.data.payer_bank_account) {
        parsedResponse.data = {
            ...r.data,
            payee_bank_account: {
                ...r.data.payee_bank_account,
                prs_custom_account_number_with_bal: `${r.data.payee_bank_account.account_type && accType[r.data.payee_bank_account.account_type]}  ${r.data.payee_bank_account.account_number ? `(${r.data.payee_bank_account.account_number.substr(r.data.payee_bank_account.account_number.length - 4)})` : ""} \n${currencySymbol(r.data.payee_bank_account.currency_code)} ${Number(r.data.payee_bank_account.clear_balance).toFixed(2)} ${r.data.payee_bank_account.currency_code}`
            },
            payer_bank_account: {
                ...r.data.payer_bank_account,
                prs_custom_account_number_with_bal: `${r.data.payer_bank_account.account_type && accType[r.data.payer_bank_account.account_type]}  ${r.data.payer_bank_account.account_number ? `(${r.data.payer_bank_account.account_number.substr(r.data.payer_bank_account.account_number.length - 4)})` : ""} \n${currencySymbol(r.data.payer_bank_account.currency_code)} ${Number(r.data.payer_bank_account.clear_balance).toFixed(2)} ${r.data.payer_bank_account.currency_code}`
            }
        };
    }



    return parsedResponse;
};
export const getExternalAccDetailsParser = (r = {}) => {
    const parsedResponse = { ...r };
    parsedResponse.data = {
        ...r.data,
        prs_masked_account_number: r.data && r.data.account_number ? "**** **** **** " + r.data.account_number.substring(r.data.account_number.length - 4) : "",
    };

    return parsedResponse;
};
export const globalAccountListParser = (r = {}, countryCode = "", currenciesArray = []) => {

    const parsedResponse = { ...r };
    let incorporation_currency_code = countryCode === "CA" ? ["CAD", "USD", "EUR", "GBP"] : ["USD"];

    const multiCurrencyAcc = r.data.filter(item => !incorporation_currency_code.includes(item.currency_code));

    const d = r && r.data && r.data.map(i => {
        const currencyObj = currenciesArray.length > 0 && currenciesArray.filter(item => item.currency_code === i.currency_code)[0];
        const listName = multiCurrencyAcc.some(item => item.account_id === i.account_id) && currencyObj && currencyObj.currency_name ? currencyObj.currency_name : `${i.currency_code} Checking`;

        return (
            {
                ...i,
                prs_name: listName,
                prs_custom_account_number: (i.bank_account_type === BANK_ACCOUNT.BANK_ACCOUNT_TYPE.TRULY_MANAGED) ? listName : (i.bank_name || "") + " " + mask(i.account_number),
                prs_custom_account_number_with_bal: `${(i.bank_account_type === BANK_ACCOUNT.BANK_ACCOUNT_TYPE.TRULY_MANAGED) ? listName : (i.bank_name || "")}  ${i.account_number ? `(${i.account_number.substr(i.account_number.length - 4)})` : ""} \n${currencySymbol(i.currency_code)} ${Number(i.clear_balance).toFixed(2)} ${i.currency_code || ''}`
            }
        );
    });

    /// filter flinks account where details not loaded yet and sortingAcc
    const filterAcc = d.filter(i => (i.account_identification_type === ACCOUNT_IDENTIFICATION_TYPE.BANK_ACCOUNT && i.bank_account_type === BANK_ACCOUNT.BANK_ACCOUNT_TYPE.TRULY_MANAGED) || (i.account_identification_type === ACCOUNT_IDENTIFICATION_TYPE.BANK_ACCOUNT && i.bank_account_type === "EXTERNAL_LINKED" && i.account_details && Object.keys(i.account_details).length > 0 && [ACCOUNT_STATUS.LINKED, ACCOUNT_STATUS.SESSION_EXPIRED].includes(i.account_status)));

    parsedResponse.data = filterAcc.sort((a, b) => (a.prs_custom_account_number_with_bal).toLowerCase() < (b.prs_custom_account_number_with_bal).toLowerCase() ? -1 : 1);
    return parsedResponse;
};