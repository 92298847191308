/* eslint-disable react-hooks/exhaustive-deps */

import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components/ui';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Avatar, List, ListItem, Menu } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Loader } from 'components';
import { flag } from 'utils/flag';
import { currencySymbol } from 'utils/currencySymbols';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  currencyDiv: props => ({
    display: "flex",
    flexDirection: "row",
    borderRadius: theme.spacing(3.75),
    border: props.error ? `1px solid ${theme.palette.error.main}` : `1px solid ${theme.palette.grey100}`,
    // paddingLeft: theme.spacing(0.8),
    // paddingRight: theme.spacing(0.8),
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    // backgroundColor: theme.palette.grey[50],
    height: theme.spacing(3.625),
    width: theme.spacing(13.5),
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 0.8, 0, 1.9875)
  }),
  currencyTxt: {

  },
  dailogRoot: {
    height: "100vh",
    borderRadius: "24px !important",
  },
  closeDiv: {
    alignSelf: "flex-end",
  },
  titleDiv: {
    alignSelf: "center",
  },
  titleTxt: {
    fontSize: theme.spacing(3),
  },
  rootItem: {
    display: "flex",
    padding: theme.spacing(0),

    flexDirection: "column",
  },
  itemDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

  },
  avatar: {
    height: theme.spacing(3.625),
    width: theme.spacing(3.625),
    marginRight: theme.spacing(1.5),
    filter: `drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))`
  },
  leftDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: theme.spacing(8),
  },
  contentTitletxt: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  btnDiv: {
    width: "80%",
    margin: theme.spacing(2, 'auto'),
  },
  heading: {
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  radioIcon: {
    color: theme.palette.accent.main
  },
  icon: {
    marginTop: theme.spacing(-0.5),
    color: theme.palette.grey300,
    fontSize: theme.spacing(2.3)
  },
  symbol: {
    // margin: theme.spacing(0, 1.1875, 0, 1.1875)
  },
  currencyCode: {
    // margin: theme.spacing(0, 1.875, 0, 0)
  }
}));

function CurrencySelector(props) {

  const { currencies, getCurrencies, onSelect,
    // payerBank,
    selectedCurrency, setSelectedCurrency,
    // contactBankAccounts
  } = props;
  const classes = useStyles(props);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const symbol = currencySymbol(selectedCurrency && selectedCurrency.currency_code);

  // const contactBankAccountsData = contactBankAccounts.data || [];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!currencies.data || !currencies.data.length) {
      getCurrencies();
    }
  }, []);
  useEffect(() => {

    // if (currencies && currencies.data && currencies.data.length && payerBank) {
    //   let filterContactCurrencies = currencies.data.filter(i => i.currency_code === payerBank.currency_code)[0];
    //   onPressItem(filterContactCurrencies);
    // }

    // if (currencies && currencies.data && currencies.data.length && contactBankAccountsData.length > 0 && !payerBank && !selectedCurrency.currency_code) {
    //   let filterContactCurrencies = currencies.data.filter(i => i.currency_code === contactBankAccountsData[0]?.currency_code)[0];
    //   onPressItem(filterContactCurrencies);
    // }
    // else if (currencies && currencies.data && currencies.data.length && !selectedCurrency.currency_code && !payerBank) {
    //   onPressItem(currencies.data[0]);
    // }
    if (selectedCurrency && selectedCurrency.currency_code) {
      onPressItem(selectedCurrency);
    } else if (currencies?.data?.length === 1) {
      onPressItem(currencies.data[0]);
    }
  }, [currencies.data]);




  const onPressItem = (item) => {
    setSelectedCurrency && setSelectedCurrency(item);
    onSelect && onSelect(item);
    handleClose();
  };


  return (
    <>

      <div className={classes.root} >
        <div aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={classes.currencyDiv}>
          {
            symbol ? <Typography color="grey300" className={classes.symbol} > {symbol}</Typography> :
              <MonetizationOnOutlinedIcon className={classes.icon} />
          }

          <Typography variant="subtitle1" className={classes.currencyCode} color="grey700" >{(selectedCurrency && selectedCurrency.currency_code) || '-'}</Typography>
          <ExpandMoreIcon className={classes.icon} />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          disableEnforceFocus
        >
          <div>

            <List className={classes.rootItem} >
              {
                Array.isArray(currencies.data) && currencies.data.map((item, index) => {

                  let url = item.country_code ? item.country_code : item.currency_code.substring(0, 2)
                  let flagUrl = flag(url)

                  return (
                    <ListItem button onClick={() => { onPressItem(item) }} key={index} className={classes.itemDiv}>
                      <div className={classes.leftDiv} >
                        <Avatar
                          className={classes.avatar}
                          alt="Flag"
                          src={flagUrl ? flagUrl : "/static/images/avatar/1.jpg"}
                        />
                        <div>
                          <Typography variant="subtitle1" color="grey700" >{item.currency_code || ""}</Typography>
                          <Typography variant="body2" color="grey300"  >{currencySymbol(item.currency_code)} {item.currency_name || ""}</Typography>
                        </div>
                      </div>
                      <div>
                        {
                          selectedCurrency && selectedCurrency.currency_code === item.currency_code ? <RadioButtonCheckedIcon className={classes.radioIcon} fontSize="small" /> : <RadioButtonUncheckedIcon className={classes.radioIcon} fontSize="small" />
                        }
                      </div>
                    </ListItem>
                  )
                })
              }

            </List>
            {currencies.loading && <Loader />}

          </div>
        </Menu>
      </div>
    </>
  );

}

CurrencySelector.defaultProps = {
  getCurrencies: () => { }
};

CurrencySelector.propTypes = {
  currencies: PropTypes.any,
  getCurrencies: PropTypes.func,
  onSelect: PropTypes.func,
  selectedCurrency: PropTypes.any,
  setSelectedCurrency: PropTypes.any
};

export default memo(CurrencySelector);
