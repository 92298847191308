
import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dynamicBody: props => props.fixed && ({
        paddingTop: `${props.headerHeight - theme.spacing(2)}px`,
        paddingBottom: `${props.footerHeight + theme.spacing(2)}px`,
    }),
}));

function DialogDynamicBody(props = {}) {
    const classes = useStyles(props);
    return (<div className={classes.dynamicBody}>
        {props.children}
    </div>);
}
DialogDynamicBody.defaultProps = {

};

DialogDynamicBody.propTypes = {

};

export default memo(DialogDynamicBody);

