/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Typography, Button, TextField } from 'components/ui';
import React, { memo, useEffect, useState } from 'react';
import { Grid, Hidden } from '@material-ui/core/';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FormattedMessage, useIntl } from 'react-intl';
import intl from '../intl/OnBoardingIntl';
import SelectRole from 'containers/Common/SelectRole';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { addCustomerIntroductionAction, tempSaveRegistrationDataAction } from 'store/Register/registerActions';
import { addCustomerIntroductionState, getCustomerDetailsState, tempRegistrationDataState } from 'store/Register/registerSelectors';
import { Loader } from 'components';
import { getDirtyValues } from 'utils/common';
import GoBack from '../GoBack';
import { REGISTRATION_STEP } from '@truly-financial/common/consts/signup.consts';
import SelectIncorporateCountry from 'containers/Common/SelectIncorporateCountry';
import PhoneInput from 'containers/Common/PhoneInput';
// import { analyticsTrackApi } from 'store/Analytics/analyticsApi';
// import { ANALYTICS } from '@truly-financial/common/consts';
const CUSTOMER_INTRODUCTION = REGISTRATION_STEP.CUSTOMER_INTRODUCTION;
const CONFIDENTIAL_AGREEMENT = REGISTRATION_STEP.CONFIDENTIAL_AGREEMENT;

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.primary.contrastText,
        //    height: `calc(100vh - ${theme.appHeaderHeight} - ${theme.spacing(5)}px)`,
    },

    content: {
        textAlign: "center",
    },
    subContent: {
        display: "grid",
        //  height: '60vh'
    },
    firstEachRow: {
        margin: theme.spacing(3.5, 0, 0, 0),
    },
    eachRow: {
        [theme.breakpoints.up(theme.showDrawerBreakPointUp)]: {
            margin: theme.spacing(3.5, 0, 0, 0),
        }

    },
    align: {
        display: "flex",
        alignItems: "center",
    },
    mainTitle: {},
    subTitle: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    centerContent: {
        textAlign: "left",
        margin: theme.spacing(7, 0, 7, 0),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(3, 0, 7, 0),
        }
    },
    txtCenter: {
        margin: theme.spacing(0, 1, 0, 1),
    },
    textLeft: {
        margin: theme.spacing(0, 1, 0, 0),
    },
    textRight: {
        margin: theme.spacing(0, 0, 0, 1),
    },
    textInputMarginTop: {
        margin: theme.spacing(2.5, 0, 0, 0),
    },
    fieldWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        flex: 1,
    },
    optionalText: {
        textAlign: 'left',
        paddingTop: theme.spacing(0.5),
    },
    btnDiv: {
        flex: 1
    },
    lastMainGrid: {

        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down(theme.showDrawerBreakPointUp)]: {
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),
        }
    },
    companyPosition: {
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2.5, 0),
        }

    },

    firstInput: {
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0, 2.5, 0),
        },
        '& .MuiInputBase-input': {
            textTransform: 'capitalize'
        }
    },
    input: {
        '& .MuiInputBase-input': {
            textTransform: 'capitalize'
        }
    },
    helperTextMargin: {
        margin: theme.spacing(0, 0, 0, 0)
    },
    dot: {
        height: '3px',
        width: '3px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        margin: theme.spacing(0, 0, 1, 0.5)
    },
    lastRow: {
        display: "flex",
        flex: 1,
        alignItems: 'flex-end'
    },
    comma: {
        margin: theme.spacing(0, 0, 1, 0.5)
    }
}));
const initialForm = {
    entity_person_first_name: "",
    entity_person_middle_name: "",
    entity_person_last_name: "",
    contact_person_position: "",
    company_name: "",
    brand_name: "",
    incorporation_country_code: ''
};
function CustomerIntroduction(props = {}) {
    const { onContinue = () => null, onBackPress = () => null, tempRegistrationDataState, addCustomerIntroductionState, addCustomerIntroduction, getCustomerDetailsState, tempSaveRegistrationData } = props;
    const classes = useStyles();
    const intlStr = useIntl();
    const theme = useTheme();
    const [formData, setFormData] = useState({ ...initialForm });
    const customerDetail = (getCustomerDetailsState && getCustomerDetailsState.data) || {};
    const smDownMatches = useMediaQuery(theme.breakpoints.down(theme.showDrawerBreakPointUp));
    const isLoading = ((getCustomerDetailsState && getCustomerDetailsState.loading) || (addCustomerIntroductionState && addCustomerIntroductionState.loading))
    const requiredStepsCompleted = Array.isArray(customerDetail.completed_steps) && customerDetail.completed_steps.includes(CONFIDENTIAL_AGREEMENT) && customerDetail.completed_steps.includes(CUSTOMER_INTRODUCTION) ? true : false;
    const isFieldDisable = (customerDetail && customerDetail.allowRegistrationDataEdit) || (customerDetail.prs_personal_details_data?.entity_person_first_name && customerDetail.prs_personal_details_data?.entity_person_id_verification_status === 'PASS_PROCESSED')

    // useEffect(() => {
    //     analyticsTrackApi({ event: ANALYTICS.EVENTS.INTRODUCTION_CONTINUE_PAGE_LOAD.event })
    // }, [])

    useEffect(() => {
        if (Object.keys(formData).length > 0) {

            tempSaveRegistrationData({
                prs_customer_introduction_data: { ...formData },
            });
        }
    }, [formData]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.zoom = "100%";
    }, []);

    useEffect(() => {
        let ob = { ...formData, ...customerDetail.prs_customer_introduction_data };

        if (tempRegistrationDataState && tempRegistrationDataState.prs_customer_introduction_data && Object.keys(tempRegistrationDataState.prs_customer_introduction_data).length > 0 &&
            Object.keys(tempRegistrationDataState.prs_customer_introduction_data).every((k) => tempRegistrationDataState.prs_customer_introduction_data[k] || tempRegistrationDataState.prs_customer_introduction_data[k] === false)) {
            ob = { ...formData, ...customerDetail.prs_customer_introduction_data, ...tempRegistrationDataState.prs_customer_introduction_data };
        }

        setFormData(formData => ({ ...ob }));
    }, [customerDetail.prs_customer_introduction_data]);


    const onSuccess = (arg = "onBoarding") => {
        if (Object.keys(formData).length > 0) {
            tempSaveRegistrationData({
                prs_customer_introduction_data: { ...formData, isStepComplete: true },
            });
        }
        onContinue(arg);

    };
    const onSubmit = (e) => {
        e?.preventDefault();
        // add check if both the steps are completed
        const ob = getDirtyValues(customerDetail.prs_customer_introduction_data, formData);

        if (Object.keys(ob).length > 0 || !requiredStepsCompleted) {
            addCustomerIntroduction({ body: formData, cb: onSuccess });
        } else {
            onSuccess("header");
        }
        // analyticsTrackApi({ event: ANALYTICS.EVENTS.INTRODUCTION_CONTINUE_CLICK.event })

        // if (formData.brand_name) {
        //    
        //     let trackingProperties = ANALYTICS.EVENTS.INTRODUCTION_CONTINUE_OPTIONAL_FIELD.tracking_properties;

        //     let tracking_data = {};
        //     trackingProperties.forEach((tP) => {
        //         return tracking_data[tP.key] = formData.brand_name;
        //     })
        //     analyticsTrackApi({ event: ANALYTICS.EVENTS.INTRODUCTION_CONTINUE_OPTIONAL_FIELD.event, tracking_data })
        // }

        // if (formData.incorporation_country_code) {
        //     let trackingProperties = ANALYTICS.EVENTS.INTRODUCTION_CONTINUE_COUNTRY_FIELD.tracking_properties;

        //     let tracking_data = {};
        //     trackingProperties.forEach((tP) => {
        //         return tracking_data[tP.key] = formData.incorporation_country_code;
        //     })
        //     analyticsTrackApi({ event: ANALYTICS.EVENTS.INTRODUCTION_CONTINUE_COUNTRY_FIELD.event, tracking_data })
        // }
    };
    const onChange = (e, val) => {
        const { name, value } = e.target || {};
        if (name === "incorporation_country_code" && !formData.entity_person_mobile_country_code) {
            setFormData(formData => ({ ...formData, entity_person_mobile_country_code: value }));
        }

        setFormData(formData => ({ ...formData, [name]: value }));
    };

    return (<>
        <form onSubmit={onSubmit} className={classes.root}>
            {
                isLoading && <Loader />
            }
            <Grid container justifyContent="center" className={classes.content}>
                <Grid item xs="11" md="12" className={classes.subContent}>
                    <Grid container alignItems="baseline" justifyContent="center" >
                        <Grid xs="12" md="9">
                            <Typography className={classes.mainTitle} variant={smDownMatches ? "h6" : "h5"} color="grey700"><FormattedMessage {...intl.onBoardingStepTitle} /></Typography>
                            <Typography className={classes.subTitle} variant="subtitle1" color="grey400"><FormattedMessage {...intl.onBoardingStepSubTitle} /></Typography>

                        </Grid>
                    </Grid>
                    <Grid className={classes.centerContent}>
                        <Grid container spacing={smDownMatches ? 0 : 1} justifyContent='center' alignItems="baseline" >
                            <Grid xs="12" md="auto" >
                                <Typography className={classes.textLeft} color="grey700" variant="subtitle1"><FormattedMessage {...intl.onBoardingStepMyName} /></Typography>
                            </Grid>
                            <Grid item xs="12" md="3">
                                <TextField
                                    type="text"
                                    id="entity_person_first_name"
                                    name="entity_person_first_name"
                                    placeholder={`${intlStr.formatMessage(intl.onBoardingStepFirstName)}`}
                                    required
                                    isCharacterOnly
                                    value={formData.entity_person_first_name}
                                    errorText=""
                                    className={classes.firstInput}
                                    onChange={onChange}
                                    disabled={isFieldDisable}

                                />
                            </Grid>
                            <Grid item xs="12" md="3">
                                <TextField
                                    type="text"
                                    id="entity_person_middle_name"
                                    name="entity_person_middle_name"
                                    placeholder={`${intlStr.formatMessage(intl.onBoardingStepMiddleName)}`}
                                    isCharacterOnly
                                    value={formData.entity_person_middle_name}
                                    errorText=""
                                    className={classes.input}
                                    onChange={onChange}
                                    disabled={isFieldDisable}

                                />
                                <div className={classes.optionalText}>
                                    <Typography className={classes.helperTextMargin} variant="caption" color="grey500">{`${intlStr.formatMessage(intl.onBoardingStepOption)}`}</Typography>
                                </div>
                            </Grid>
                            <Grid container alignItems='flex-end' direction='row' item xs="12" md="3">
                                <TextField
                                    type="text"
                                    id="entity_person_last_name"
                                    name="entity_person_last_name"
                                    placeholder={`${intlStr.formatMessage(intl.onBoardingStepLastName)}`}
                                    required
                                    isCharacterOnly
                                    value={formData.entity_person_last_name}
                                    errorText=""
                                    className={classes.input}
                                    onChange={onChange}
                                    disabled={isFieldDisable}
                                />
                                <div className={classes.dot} />
                            </Grid>

                            <Hidden mdUp>
                                <Grid item xs="12" md="2"></Grid>
                            </Hidden>


                        </Grid>
                        <Grid container alignItems="baseline" justifyContent='center' className={classes.firstEachRow}>
                            <Hidden smDown>
                                <Grid xs="12" md="auto" >
                                    <Typography className={classes.textLeft} color="grey700" variant="subtitle1"><FormattedMessage {...intl.onBoardingStepAndI} /></Typography>
                                </Grid>
                            </Hidden>
                            <Grid xs="12" md="auto">
                                <Typography className={classes.textLeft} color="grey700" variant="subtitle1"><Hidden mdUp> <FormattedMessage {...intl.onBoardingStepAndI} /></Hidden><FormattedMessage {...intl.onBoardingStepIAm} /></Typography>
                            </Grid>
                            <Grid item xs="12" md="3" className={classes.companyPosition}>
                                <SelectRole
                                    placeholder={`${intlStr.formatMessage(intl.onBoardingStepRole)}`}
                                    otherPlaceholder={`${intlStr.formatMessage(intl.onBoardingStepRole)}`}
                                    required
                                    id="contact_person_position"
                                    name="contact_person_position"
                                    errorText=""

                                    value={formData.contact_person_position}
                                    onChange={onChange}
                                    hiddenLabel
                                />

                            </Grid>
                            <Grid xs="12" md="auto">
                                <Hidden smDown>
                                    <Typography className={classes.txtCenter} variant="subtitle1"><FormattedMessage {...intl.onBoardingStepAt} /></Typography>
                                </Hidden>
                                <Hidden mdUp>
                                    <Typography className={classes.textLeft} variant="subtitle1"><FormattedMessage {...intl.onBoardingStepAt} /></Typography>
                                </Hidden>
                            </Grid>
                            <Grid item xs="12" md="4" className={classes.firstInput}>
                                <div className={classes.fieldWrapper}>
                                    <div className={classes.lastRow} >
                                        <TextField
                                            type="text"
                                            id="company_name"
                                            name="company_name"

                                            // isCharacterOnly
                                            value={formData.company_name}
                                            placeholder={`${intlStr.formatMessage(intl.onBoardingStepRegisterName)}`}
                                            required
                                            errorText=""
                                            onChange={onChange}
                                        />
                                        <Typography className={classes.comma} variant="subTitle1" color="grey700">,</Typography>
                                    </div>
                                    <div className={classes.optionalText}>
                                        <Typography className={classes.helperTextMargin} variant="caption" color="grey500">{`${intlStr.formatMessage(intl.customerIntroductionOptional)}`}</Typography>
                                    </div>
                                </div>
                            </Grid>

                            <Hidden mdUp>
                                <Grid item xs="12" md="2"></Grid>
                            </Hidden>
                            <Hidden smDown>
                                <Grid xs="12" md="auto">
                                    <Typography className={classes.textRight} variant="subtitle1"><FormattedMessage {...intl.onBoardingStepAlso} /></Typography>
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Grid container alignItems="baseline" justifyContent='center' className={classes.eachRow}>
                            <Grid xs="12" md="auto">
                                <Typography className={classes.textLeft} variant="subtitle1"><Hidden mdUp><FormattedMessage {...intl.onBoardingStepAlso} /></Hidden><FormattedMessage {...intl.onBoardingStepKnownAs} /></Typography>
                            </Grid>
                            <Grid item xs="12" md="3" className={classes.firstInput}>
                                <div className={classes.fieldWrapper}>
                                    <div className={classes.lastRow} >
                                        <TextField
                                            type="text"
                                            id="brand_name"
                                            name="brand_name"
                                            placeholder={`${intlStr.formatMessage(intl.onBoardingStepDoingBusiness)}`}
                                            // helperText={`${intlStr.formatMessage(intl.onBoardingStepOption)}`}
                                            errorText=""
                                            value={formData.brand_name}
                                            onChange={onChange}
                                        />
                                        <Typography className={classes.comma} variant="subTitle1" color="grey700">,</Typography>
                                    </div>
                                    <div className={classes.optionalText}>
                                        <Typography className={classes.helperTextMargin} variant="caption" color="grey500">{`${intlStr.formatMessage(intl.onBoardingStepOptionMust)}`}</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs="12" md="auto">
                                <Hidden mdUp>
                                    <Typography className={classes.textLeft} variant="subtitle1"><FormattedMessage {...intl.onBoardingStepBaseOut} /></Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <Typography className={classes.txtCenter} variant="subtitle1"><FormattedMessage {...intl.onBoardingStepBaseOut} /></Typography>
                                </Hidden>
                            </Grid>
                            <Grid container alignItems='flex-end' direction='row' xs="12" md="4" className={classes.firstInput}>
                                <SelectIncorporateCountry
                                    id="incorporation_country_code"
                                    name="incorporation_country_code"
                                    value={formData.incorporation_country_code}
                                    placeholder={`${intlStr.formatMessage(intl.onBoardingStepCountry)}`}
                                    required
                                    error={`${intlStr.formatMessage(intl.onBoardingStepCoi)}`}
                                    onChange={onChange}
                                    errorText=""
                                    // helperText={`${intlStr.formatMessage(intl.onBoardingStepCoi)}`}
                                    hiddenLabel
                                />

                                <div className={classes.dot} />
                            </Grid>
                        </Grid>
                        <Grid container alignItems="baseline" justifyContent='center' className={classes.eachRow}>
                            <Grid xs="12" md="auto">
                                <Typography className={classes.textLeft} variant="subtitle1"><FormattedMessage {...intl.onBoardingMobile} /></Typography>
                            </Grid>
                            <Grid item xs="12" md="4">
                                <div className={classes.fieldWrapper}>
                                    <div className={classes.lastRow} >
                                        <PhoneInput
                                            mobileCountryId="entity_person_mobile_country_code"
                                            mobileCountryName="entity_person_mobile_country_code"
                                            mobileCountryValue={formData.entity_person_mobile_country_code}
                                            mobileCountryLabel={<FormattedMessage {...intl.onBoardingStep13YourDetailCountryLabel} />}
                                            mobileDialingCodeId=""
                                            mobileDialingCodeName="entity_person_mobile_dialing_code"
                                            mobileId=""
                                            hiddenLabel
                                            mobileName="entity_person_mobile"
                                            mobilePlaceholder={`${intlStr.formatMessage(intl.onBoardingStep13YourDetailMobileLabel)}`}
                                            mobileValue={formData.entity_person_mobile}
                                            phoneNumberId=""
                                            phoneNumberName="entity_person_phone_number"
                                            onChange={onChange}
                                            // helperTextReg={`${textIntl.formatMessage(intl.onBoardingStep13VerificationLabel)}`}
                                            required

                                            disabled={customerDetail.prs_personal_details_data?.entity_person_mobile && customerDetail.prs_personal_details_data?.entity_person_mobile_country_code && customerDetail.prs_personal_details_data?.entity_person_mobile_verified}
                                        />
                                        <div className={classes.dot} />
                                    </div>

                                    <div className={classes.optionalText}>
                                        <Typography className={classes.helperTextMargin} variant="caption" color="grey500">{`${intlStr.formatMessage(intl.onBoardingStepPhoneHelper)}`}</Typography>
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.lastMainGrid}>
                        {
                            customerDetail && customerDetail.completed_steps && !customerDetail.completed_steps.includes('CONFIDENTIAL_AGREEMENT') &&
                            <GoBack onClick={onBackPress} isLoading={isLoading} />
                        }
                        <Grid item md="2" className={classes.btnDiv}>
                            <Button size="small"
                                id="introBtn"
                                disabled={isLoading}
                                type="submit"
                                className={classes.btnDiv}
                                innerRef={props.innerRef}
                            >
                                <div className={classes.align}>
                                    <FormattedMessage {...intl.onBoardingStepContinueBtn} />
                                    <NavigateNextIcon />
                                </div>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </>);
}

const mapStateToProps = createStructuredSelector({

    addCustomerIntroductionState: addCustomerIntroductionState(),
    getCustomerDetailsState: getCustomerDetailsState(),
    tempRegistrationDataState: tempRegistrationDataState(),
});

function mapDispatchToProps(dispatch) {
    return {
        addCustomerIntroduction: data => dispatch(addCustomerIntroductionAction(data)),
        tempSaveRegistrationData: data => dispatch(tempSaveRegistrationDataAction(data)),
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default compose(
    withConnect,
    memo,
)(CustomerIntroduction);

